import React, { useState, useEffect, useRef } from 'react';
import useStateRef from "react-usestateref";
import { useNavigate, useParams } from 'react-router-dom';
import i18next from "i18next";

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent, MultiSelectComponent, highlightSearch } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder } from '@syncfusion/ej2-react-grids';
import { Query } from '@syncfusion/ej2-data';
import CircularProgress from '@material-ui/core/CircularProgress';

import directus from 'src/services/directus';
import moment from 'moment';
import EventEmitter from 'src/utils/EventEmitter';
import * as permission from 'src/utils/Permission';

let cancelFlag = false;
const TrainingParticipants = () => {
  /* eslint-disable no-unused-vars*/
  const { training_id } = useParams();
  const navigate = useNavigate();

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  const [Select_Teacher] = useState(i18next.t('Pages.data.Select_Teacher'));
  const [Note] = useState(i18next.t('Pages.data.Note'));
  // const [Action] = useState(i18next.t('Pages.data.Action'));
  const [All_Activities] = useState(i18next.t('Pages.data.All_Activities'));
  const [All_Teachers] = useState(i18next.t('Pages.data.All_Teachers'));

  const [Teacher] = useState(i18next.t('Pages.teacher_data.Teacher'));
  const [Training] = useState(i18next.t('Pages.teacher_data.Training'));
  const [Participants] = useState(i18next.t('Pages.teacher_data.Participants'));
  const [Training_Participants] = useState(i18next.t('Pages.teacher_data.Training_Participants'));
  const [Registration_Date] = useState(i18next.t('Pages.teacher_data.Registration_Date'));
  const [Attended] = useState(i18next.t('Pages.teacher_data.Attended'));
  const [Present] = useState(i18next.t('Pages.teacher_data.Present'));
  const [Are_you_sure_you_want_to_delete_the_data_of_training_participant] = useState(i18next.t('Pages.teacher_data.Are_you_sure_you_want_to_delete_the_data_of_training_participant'));
  const [Training_participant_created_successfully] = useState(i18next.t('Pages.teacher_data.Training_participant_created_successfully'));
  const [Training_participant_deleted_successfully] = useState(i18next.t('Pages.teacher_data.Training_participant_deleted_successfully'));
  const [Select_Training] = useState(i18next.t('Pages.teacher_data.Select_Training'));

  const [data, setData] = useState(null);
  const [dataTeacher, setDataTeacher] = useState(null);
  const [dataTeacher2, setDataTeacher2] = useState(null);
  const [dataTraining, setDataTraining] = useState(null);
  const [dataTraining2, setDataTraining2] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  const editSettings = { allowEditing: permission.AdminPermission() ? true : false, allowAdding: true, allowDeleting: true, mode: 'Normal' };

  let dialogAddParticipant = useRef(false);
  let dialogDeleteParticipant = useRef(false);
  let mt_teacher = useRef(false);

  const grid = useRef();
  const filterOptions = { type: 'Menu' };
  const setting = { mode: 'Both' };

  let queryStringTraining = null;
  let queryStringTeacher = null;

  const [state, setState] = useState({
    teachers: null,
    training: training_id ? parseInt(training_id) : 0,
    registration_date: new Date(),
    attended: 0,
    note: null,
    status: "published"
  });

  const [error, setError, errorRef] = useStateRef({
    teachers: false,
    training: false,
    registration_date: false
  });

  const [dataFilter, setDataFilter, dataFilterRef] = useStateRef({
    training: training_id ? parseInt(training_id) : 0,
    teacher: 0
  })

  /* eslint-enable no-unused-vars*/

  const getInitialValue = () => {
    setState({
      teachers: null,
      training: training_id ? parseInt(training_id) : 0,
      registration_date: new Date(),
      attended: 0,
      note: null,
      status: "published"
    });
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getTeacher();
    }
  })


  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "add_participant") {
        dialogAddParticipant.current.visible = false;
        setState({ teachers: null, training: null, registration_date: new Date(), attended: 0, note: null, status: "published" });
        mt_teacher.current.value = null;
        EventEmitter.emit('alert_toast', { content: Training_participant_created_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_participant") {
        dialogDeleteParticipant.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Training_participant_deleted_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }

  const getTeacher = async () => {
    try {
      var result = await directus.getItems('teachers',
        {
          fields: '*',
          sort: "name",
          limit: 10,
          page: 1
        }
      );

      var newData = result.data;
      setDataTeacher(newData);

      if (result.data.length > 0) {
        var oldData = [{ id: 0, nickname: All_Teachers }];
        newData = oldData.concat(newData);
      }

      setDataTeacher2(newData);
      getTraining();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const getTraining = async () => {
    try {
      var result = await directus.getItems('trainings',
        {
          fields: '*.*',
          sort: "training_name",
          limit: 10,
          page: 1
        }
      );

      var newData = result.data;
      setDataTraining(newData);

      if (result.data.length > 0) {
        var oldData = [{ id: 0, training_name: All_Activities }];
        newData = oldData.concat(newData);
      }

      setDataTraining2(newData);
      getData();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const getData = async () => {
    try {
      var teacher = { "nnull": null };
      var training = { "nnull": null };

      if (dataFilterRef.current.teacher !== 0) {
        teacher = { "in": dataFilterRef.current.teacher };
      }

      if (dataFilterRef.current.training !== 0) {
        training = { "in": dataFilterRef.current.training };
      }

      var result = await directus.getItems('training_participants',
        {
          fields: "*.*",
          sort: "trainings_id",
          filter: {
            status: "published",
            "teachers_id.id": teacher,
            "trainings_id.id": training
          }
        });

      setData(result.data);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Data Grid Template ========================================== */ }

  const dateTemplate = props => {
    return (
      <div>{props.registration_date ? moment(props.registration_date).format('DD-MMM-yyyy') : null}</div>
    )
  };

  const attendedTemplate = props => {
    return (
      <div style={{ color: parseInt(props.attended) === 1 ? "black" : "lightgray", cursor: "pointer" }}
        onClick={() => checkAttended(props)}
        className="e-icons icon-check-mark-01">
      </div>
    )
  };

  const checkAttended = async (props) => {
    var status = props.attended;
    if (parseInt(status) === 1) { status = 0; } else { status = 1; }

    var result = await directus.updateItem('training_participants', props.id, { attended: status });
    if (!result.error) {
      EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
      getData();
    }
  }

  const buttonTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center", justifyContent: "center" }}>
        {permission.AdminPermission() ?
          <div className="action_hover" style={{ display: "flex" }}>
            <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px", }} >
              <span className="e-icons icon-delete-05"
                style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => handleOpenDialogDeleteParticipant(props)}
              />
            </TooltipComponent>
          </div>
          : null}
      </div>
    )
  }

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const buttonEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter" >
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const actionGrid = async (event) => {
    if (event.action === "edit" && event.requestType === "save") {
      if (cancelFlag === false) {
        const data = event.data;
        const body = {
          note: data.note,
          registration_date: data.registration_date ? moment(data.registration_date).format("YYYY-MM-DD") : null,
        }
        try {
          setLoadingButton(true);
          var result = await directus.updateItem('training_participants', data.id, body);
          if (!result.error) {
            getData();
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          setLoadingButton(false);
        }
      } else {
        grid.current.editModule.closeEdit()
      }
      cancelFlag = false;

    } else if (event.requestType === "beginEdit") {
      cancelFlag = true;
    }
  }

  // {/* ================== Handle Add Participant ========================================== */ }

  const addParticipant = async (event) => {
    event.preventDefault();
    try {
      setError({ teachers: false, training: false, registration_date: false });
      let new_id = mt_teacher.current.value;

      var select_teacher = new_id ? new_id.length > 0 ? true : false : false;

      if (select_teacher && state.training && state.registration_date) {
        setLoadingButton(true);

        let participant_data = [];
        //eslint-disable-next-line array-callback-return
        new_id.map((item) => {
          participant_data.push({
            teachers_id: item,
            trainings_id: state.training,
            attended: state.attended,
            note: state.note,
            registration_date: state.registration_date ? moment(state.registration_date).format("YYYY-MM-DD") : null,
            status: "published"
          })
        })

        var result = await directus.createItems('training_participants', participant_data);
        if (!result.error) {
          getData();
          setDataButton("add_participant")
        }

      } else {
        if (!select_teacher) { setError({ ...errorRef.current, teachers: true }) }
        if (!state.training) { setError({ ...errorRef.current, training: true }) }
        if (!state.registration_date) { setError({ ...errorRef.current, registration_date: true }) }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  const handleCloseDialogAddParticipant = () => {
    setError({ teachers: false, training: false, registration_date: false });
    dialogAddParticipant.current.visible = false;
    mt_teacher.current.value = null;
    getInitialValue();
  }

  const handleOpenDialogAddParticipant = () => {
    dialogAddParticipant.current.visible = true;
  }

  const handleChange = (event, type) => {
    var value = null;
    if (type === "training") {
      if (event.itemData.id) {
        value = event.itemData.id;
        setError({ ...errorRef.current, [type]: false })
      }
    } else if (type === "registration_date") {
      if (event.value) {
        value = event.value;
        setError({ ...errorRef.current, [type]: false })
      }
    } else if (type === "attended") {
      value = event.checked === true ? 1 : 0
    } else {
      value = event.value;
    }
    setState({ ...state, [type]: value })
  }

  // {/* ================== Handle Dialog Delete Participant ========================================== */ }

  const handleOpenDialogDeleteParticipant = (props) => {
    dialogDeleteParticipant.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteParticipant = () => {
    dialogDeleteParticipant.current.visible = false;
  }

  const deleteParticipant = async (event) => {
    event.preventDefault();
    //set participant status from published to draft
    try {
      setLoadingButton(true);
      const body = {
        status: 'deleted'
      }
      var result = await directus.updateItem('training_participants', selectedData.id, body);

      if (!result.error) {
        getData();
        setDataButton("delete_participant")
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }

  }

  const onFilteringTeacher = async (e) => {
    let query = new Query();
    // //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('nickname', 'contains', e.text, true) : query;
    // e.updateData(dataStudentRef.current, query);
    //pass the filter data source, filter query to updateData method.
    queryStringTeacher = e.text;
    try {
      if (e.text !== '') {
        window.setTimeout(async () => {
          var result = await directus.getItems('teachers',
            {
              fields: '*',
              filter: {
                nickname: { "contains": e.text }
              },
              limit: 10,
              page: 1
            }
          );

          e.updateData(result.data, query);
        }, 500)
      }
      else {
        window.setTimeout(async () => {
          var result = await directus.getItems('teachers',
            {
              fields: '*',
              limit: 10,
              page: 1
            }
          );
          e.updateData(result.data, query);
        }, 500)
      }


    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const onFilteringTraining = async (e) => {
    let query = new Query();
    // //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('training_name', 'contains', e.text, true) : query;
    queryStringTraining = e.text;
    // e.updateData(dataStudentRef.current, query);
    //pass the filter data source, filter query to updateData method.
    try {
      if (e.text !== '') {
        window.setTimeout(async () => {
          var result = await directus.getItems('trainings',
            {
              fields: '*.*',
              filter: {
                training_name: { "contains": e.text }
              },
              limit: 10,
              page: 1
            }
          );

          e.updateData(result.data, query);
        }, 500)
      }
      else {
        window.setTimeout(async () => {
          var result = await directus.getItems('trainings',
            {
              fields: '*.*',
              limit: 10,
              page: 1
            }
          );
          e.updateData(result.data, query);
        }, 500)
      }


    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const handleFilter = async (e, type) => {
    var value = null;
    if (type === "training" || type === "teacher") {
      value = e.itemData.id;

    }

    setDataFilter({ ...dataFilterRef.current, [type]: value });
    getData();

    if (training_id && training_id !== value) {
      return navigate('/training_participant/list');
    }
  }

  const footerTemplateAddParticipant = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-success e-flat" data-ripple="true" onClick={(e) => addParticipant(e)}>
            <span className="e-btn-icon e-icons icon-check-mark-01 e-icon-left"></span>{Update}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogAddParticipant()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  const footerTemplateDeleteParticipant = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-warning e-flat" data-ripple="true" onClick={(e) => deleteParticipant(e)}>
            <span className="e-btn-icon e-icons icon-delete-05 e-icon-left"></span>{Delete}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogDeleteParticipant()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  const onItemCreatedTraining = (e) => {
    highlightSearch(e.item, queryStringTraining, true, 'Contains');
  }

  const onItemCreatedTeacher = (e) => {
    highlightSearch(e.item, queryStringTeacher, true, 'Contains');
  }

  // {/* ================== Main Content ========================================== */ }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Training_Participants}</div>
            <div className="content-searching hide-content">
              <div style={{ display: 'flex', width: "100%" }}>
                <div className="ddl_custom_header" style={{ marginRight: "10px" }}>
                  <DropDownListComponent
                    id="ddl_custom"
                    dataSource={dataTraining2}
                    name="training"
                    cssClass="e-outline"
                    placeholder={All_Activities}
                    fields={{ text: 'training_name', value: 'id', itemCreated: onItemCreatedTraining }}
                    select={(e) => handleFilter(e, "training")}
                    value={dataFilterRef.current.training}
                    filtering={onFilteringTraining}
                    allowFiltering={true}
                  />
                </div>
                <div className="ddl_custom_header" style={{ marginRight: "10px" }}>
                  <DropDownListComponent
                    id="ddl_custom"
                    dataSource={dataTeacher2}
                    name="teacher"
                    cssClass="e-outline"
                    placeholder={All_Teachers}
                    fields={{ text: 'nickname', value: 'id', itemCreated: onItemCreatedTeacher }}
                    select={(e) => handleFilter(e, "teacher")}
                    value={dataFilterRef.current.teacher}
                    filtering={onFilteringTeacher}
                    allowFiltering={true}
                    className="form-control"
                  />
                </div>
                {permission.AdminPermission() ?
                  <div>
                    <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddParticipant}>
                      <span className="btn-text-custom">{Participants}</span>
                    </ButtonComponent>
                  </div>
                  : null}
              </div>

              {/* <br />
              {data ? data.length > 0 ? 'Total : ' + data.length : <br />
                : ''} */}
            </div>
          </div>
          <div className="content-main">
            {data ? data.length > 0 ?
              <div style={{ marginTop: "30px", paddingBottom: "20px", marginRight: "-5px" }}>
                <GridComponent
                  width="100%"
                  dataSource={data}
                  editSettings={editSettings}
                  allowSorting={true}
                  filterSettings={filterOptions}
                  ref={grid}
                  allowTextWrap={true}
                  allowKeyboard={false}
                  textWrapSettings={{ wrapMode: "Content" }}
                  allowResizing={true}
                  gridLines='Both'
                  actionBegin={actionGrid}
                  selectionSettings={setting}
                  allowReordering={true}
                  clipMode="EllipsisWithTooltip"
                >
                  <ColumnsDirective>
                    <ColumnDirective customAttributes={{ id: 'first' }} width='12%' field='teachers_id.nickname' headerText={Teacher} allowEditing={false}></ColumnDirective>
                    <ColumnDirective width='33%' field='trainings_id.training_name' headerText={Training} allowEditing={false}></ColumnDirective>
                    <ColumnDirective width='15%' field='registration_date' headerText={Registration_Date} editType='datepickeredit' edit={{ params: { format: 'dd-MMM-yyyy' } }} template={dateTemplate}></ColumnDirective>
                    <ColumnDirective width='20%' field='note' headerText={Note}></ColumnDirective>
                    <ColumnDirective width='12%' textAlign="Center" field='attended' headerText={Attended} template={attendedTemplate} editTemplate={attendedTemplate}></ColumnDirective>
                    <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="8%" template={buttonTemplate} editTemplate={buttonEditTemplate}></ColumnDirective>
                  </ColumnsDirective>
                  <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder]} />
                </GridComponent>
              </div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
            }

            <DialogComponent
              id="dialogAddParticipant"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogAddParticipant}
              header={Training_Participants}
              isModal={true}
              close={handleCloseDialogAddParticipant}
              footerTemplate={footerTemplateAddParticipant}
            >
              <div>
                <div>
                  <b>{Teacher}</b>
                  <MultiSelectComponent id="mt_teacher"
                    ref={mt_teacher}
                    cssClass="e-outline"
                    placeholder={Select_Teacher}
                    dataSource={dataTeacher}
                    fields={{ text: 'nickname', value: 'id' }}
                    mode="Box"
                    filtering={onFilteringTeacher}
                    allowFiltering={true}
                    value={state.teachers}
                  />
                  {error.teachers === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <b>{Training}</b>
                  <DropDownListComponent
                    dataSource={dataTraining}
                    name="training"
                    cssClass="e-outline"
                    placeholder={Select_Training}
                    fields={{ text: 'training_name', value: 'id' }}
                    select={(e) => handleChange(e, "training")}
                    value={state.training}
                    filtering={onFilteringTraining}
                    allowFiltering={true}
                    className="form-control"
                  />
                  {error.training === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <b>{Registration_Date}</b>
                  <DatePickerComponent
                    name="registration_date"
                    cssClass="e-outline"
                    format='dd-MMM-yyyy'
                    change={(e) => handleChange(e, "registration_date")}
                    value={state.registration_date}
                  />
                  {error.registration_date === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <CheckBoxComponent
                    checked={parseInt(state.attended) === 1 ? true : false}
                    label={Present}
                    change={(e) => handleChange(e, "attended")} />
                  <br /><br />
                  <b>{Note}</b>
                  <TextBoxComponent
                    name="note"
                    cssClass='e-outline'
                    change={(e) => handleChange(e, "note")}
                    multiline={true}
                    value={state.note}
                  />
                </div>
              </div>
            </DialogComponent>


            <DialogComponent
              id="dialogDeleteParticipant"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogDeleteParticipant}
              header={Delete_Confirmation}
              isModal={true}
              close={handleCloseDialogDeleteParticipant}
              footerTemplate={footerTemplateDeleteParticipant}
            >
              <div>
                {selectedData ?
                  <div style={{ fontSize: "14px" }}>
                    {Are_you_sure_you_want_to_delete_the_data_of_training_participant}?
                    <br /><br />

                  </div>
                  : ''}
              </div>
            </DialogComponent>
          </div>
        </div>
      }
    </div>
  );
}

export default TrainingParticipants;