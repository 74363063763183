import React, { useState, useEffect, useRef } from 'react';
import useStateRef from "react-usestateref";
import { Link } from 'react-router-dom';
import i18next from "i18next";

import { TextBoxComponent, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import directus from 'src/services/directus';
import moment from 'moment';
import * as link from 'src/constants/link';
import EventEmitter from 'src/utils/EventEmitter';
import linq from "linq";
import * as permission from 'src/utils/Permission';

let cancelFlag = false;
const Training = () => {
  /* eslint-disable no-unused-vars*/
  // const navigate = useNavigate();
  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));
  const [Training_Participant_List] = useState(i18next.t('Pages.menu.Training_Participant_List'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  // const [Photo] = useState(i18next.t('Pages.data.Photo'));
  const [Date_2] = useState(i18next.t('Pages.data.Date'));
  // const [Action] = useState(i18next.t('Pages.data.Action'));
  const [Please_upload_the_photo] = useState(i18next.t('Pages.data.Please_upload_the_photo'));

  const [Training] = useState(i18next.t('Pages.teacher_data.Training'));
  const [Activities] = useState(i18next.t('Pages.teacher_data.Activities'));
  const [Contact] = useState(i18next.t('Pages.teacher_data.Contact'));
  const [Training_Name] = useState(i18next.t('Pages.teacher_data.Training_Name'));
  const [Contact_Name] = useState(i18next.t('Pages.teacher_data.Contact_Name'));
  const [Description] = useState(i18next.t('Pages.teacher_data.Description'));
  const [Are_you_sure_you_want_to_delete_the_data_of_training] = useState(i18next.t('Pages.teacher_data.Are_you_sure_you_want_to_delete_the_data_of_training'));
  const [Enter_activity_name] = useState(i18next.t('Pages.teacher_data.Enter_activity_name'));
  const [Training_created_successfully] = useState(i18next.t('Pages.teacher_data.Training_created_successfully'));
  const [Training_deleted_successfully] = useState(i18next.t('Pages.teacher_data.Training_deleted_successfully'));
  const [Photo_updated_successfully] = useState(i18next.t('Pages.teacher_data.Photo_updated_successfully'));
  const [Change_Photo] = useState(i18next.t('Pages.teacher_data.Change_Photo'));

  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [oldPhoto, setOldPhoto] = useState(null);
  const [newPhoto, setNewPhoto] = useState(null);

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  const editSettings = { allowEditing: permission.AdminPermission() ? true : false, allowAdding: true, allowDeleting: true, mode: 'Normal' };

  const validationRule = { required: true };

  let dialogAddTraining = useRef(false);
  let dialogDeleteTraining = useRef(false);
  let dialogChangePhoto = useRef(false);
  let my_form2 = useRef(null);
  let uploadObj = useRef(null);

  const asyncSettings = {
    saveUrl: link.saveUrl,
    removeUrl: link.removeUrl
  };

  const grid = useRef();
  const setting = { mode: 'Both' };

  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    training_name: null
  });

  const [state, setState] = useState({
    training_name: null,
    contact_name: null,
    date: null,
    description: null,
    photo: null,
    status: "published"
  });

  const [error, setError, errorRef] = useStateRef({
    training_name: false,
    date: false,
    photo: false
  });
  /* eslint-enable no-unused-vars*/

  const getInitialValue = () => {
    setState({
      training_name: null,
      contact_name: null,
      date: null,
      description: null,
      photo: null,
      status: "published"
    });
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "add_training") {
        dialogAddTraining.current.visible = false;
        // setState({ training_name: null, contact_name: null, date: null, description: null, photo: null, status: "published" });
        EventEmitter.emit('alert_toast', { content: Training_created_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_training") {
        dialogDeleteTraining.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Training_deleted_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "change_photo") {
        dialogChangePhoto.current.visible = false;
        setNewPhoto(null);
        uploadObj.current.clearAll();
        EventEmitter.emit('alert_toast', { content: Photo_updated_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }


  const getData = async () => {
    try {
      var training_name = { "nnull": null };

      if (stateFiltering.training_name !== null && stateFiltering.training_name !== "") {
        training_name = { "contains": stateFilteringRef.current.training_name };
      }

      var result = await directus.getItems('trainings',
        {
          fields: '*.*',
          filter: {
            training_name: training_name,
            status: "published"
          },
          sort: "-date"
        });

      setData(result.data);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Data Grid Template ========================================== */ }

  const buttonTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_hover" style={{ display: "flex" }}>
          <TooltipComponent content={Training_Participant_List} position="BottomCenter" >
            <span>
              <Link to={{ pathname: `/training_participant/list/${props.id}` }} style={{ textDecoration: "none" }}>
                <span className="e-icons icon-MT_List"
                  style={{ cursor: "pointer", fontWeight: "1000", color: '#3c8dbc', lineHeight: "40px" }}
                />
              </Link>
            </span>
          </TooltipComponent>

          {permission.AdminPermission() ?
            < TooltipComponent content={Change_Photo} position="BottomCenter" style={{ marginLeft: "10px", }} >
              <span className="e-icons icon-MT_Image"
                style={{ marginLeft: "15px", cursor: "pointer", fontWeight: "1000", color: '#3c8dbc', lineHeight: "40px" }}
                onClick={() => handleOpenDialogChangePhoto(props)}
              />
            </TooltipComponent>
            : null}

          {permission.AdminPermission() ?
            <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px", }} >
              <span className="e-icons icon-delete-05"
                style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => handleOpenDialogDeleteTraining(props)}
              />
            </TooltipComponent>
            : null}
        </div>
      </div >
    )
  }

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const buttonEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter" >
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const nameTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <span className="image" style={{ width: "25px", height: "25px" }}>
          <img
            style={{
              maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
              objectFit: "cover", borderRadius: "50%", position: "center",
            }}
            className="image"
            src={props.photo ? props.photo.data.full_url : link.no_activity_image}
            alt={props.name}
          />
        </span>
        <span style={{ paddingLeft: "20px", textAlign: "left" }}>{props.training_name}</span>
      </div>
    )
  };

  const dateTemplate = props => {
    return (
      <div>{props.date ? moment(props.date).format('DD-MMM-yyyy') : null}</div>
    )
  };

  const actionGrid = async (event) => {
    if (event.action === "edit" && event.requestType === "save") {
      if (cancelFlag === false) {
        const data = event.data;
        try {
          const body = {
            training_name: data.training_name,
            contact_name: data.contact_name,
            date: data.date ? moment(data.date).format("YYYY-MM-DD") : null,
            description: data.description,
          }
          var result = await directus.updateItem('trainings', data.id, body);

          var description = data.training_name;
          if (data.training_name !== event.previousData.training_name) {
            description = event.previousData.training_name + " --> " + data.training_name;
          }

          const body_activity = {
            action: "Update",
            category: 6,
            description: description,
            status: "published"
          }
          await directus.createItem('activity_log', body_activity);

          if (!result.error) {
            getData();
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        }
      } else {
        grid.current.editModule.closeEdit()
      }
      cancelFlag = false;

    } else if (event.requestType === "beginEdit") {
      cancelFlag = true;
    }
  }

  // {/* ================== Filtering ========================================== */ }

  const handleChangeFilter = (event, type) => {
    var value = "";
    if (type === "training_name") {
      value = event.value
    }

    setStateFiltering({ ...stateFiltering, [type]: value })

    if (event.syntheticEvent.type === 'change') { getData(); }

  }

  const searchTraining = () => { getData(); }

  // {/* ================== Handle Add Trainings ========================================== */ }

  const addTraining = async () => {
    try {
      setError({ ...errorRef.current, training_name: false, date: false });
      if (state.training_name && state.date) {
        setLoadingButton(true);
        state.date = state.date ? moment(state.date).format("YYYY-MM-DD") : null;
        var result = await directus.createItem('trainings', state);

        const body_activity = {
          action: "Add",
          category: 6,
          description: state.training_name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);


        if (!result.error) {
          getData();
          setDataButton("add_training")
        }

      } else {
        if (!state.training_name) { setError({ ...errorRef.current, training_name: true }) }
        if (!state.date) { setError({ ...errorRef.current, date: true }) }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  const handleCloseDialogAddTraining = () => {
    setError({ ...errorRef.current, training_name: false, date: false });
    dialogAddTraining.current.visible = false;

  }

  const handleOpenDialogAddTraining = () => {
    dialogAddTraining.current.visible = true;
    getInitialValue();
  }

  const handleChange = (event, type) => {
    var value = null;
    if (type === "training_name" || type === "date") {
      if (event.value) {
        value = event.value;
        setError({ ...errorRef.current, [type]: false })
      }
    } else {
      value = event.value;
    }

    setState({ ...state, [type]: value })
  }

  // {/* ================== Handle Dialog Remove Activity Type ========================================== */ }

  const handleOpenDialogDeleteTraining = (props) => {
    dialogDeleteTraining.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteTraining = () => {
    dialogDeleteTraining.current.visible = false;
  }

  const deleteTraining = async () => {
    //set teacher status from published to draft
    try {
      setLoadingButton(true);

      var result = await directus.getItems('training_participants',
        {
          filter: {
            trainings_id: selectedData.id
          }
        });

      var training_participants = result.data;

      if (training_participants.length > 0) {
        training_participants = linq.from(training_participants)
          .select(x => x.id)
          .toArray();

        await directus.deleteItems('training_participants', training_participants);
      }

      var result_training = await directus.deleteItem('trainings', selectedData.id);

      const body_activity = {
        action: "Delete",
        category: 6,
        description: selectedData.training_name,
        status: "published"
      }
      await directus.createItem('activity_log', body_activity);

      if (!result_training.error) {
        getData();
        setDataButton("delete_training")
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }

  }

  // {/* ================== Handle Change Photo Dialog ========================================== */ }

  const handleOpenDialogChangePhoto = (props) => {
    var photo_activity = props.photo ? props.photo.data.full_url : link.no_activity_image;
    setPhoto(photo_activity);
    setOldPhoto(photo_activity);
    setSelectedData(props);
    dialogChangePhoto.current.visible = true;
  }

  const handleCloseDialogChangePhoto = () => {
    dialogChangePhoto.current.visible = false;
    uploadObj.current.clearAll();
    cancelFile();
  }

  const changePhoto = async () => {
    setError({ ...errorRef.current, photo: false })
    if (newPhoto !== null) {
      try {
        setLoadingButton(true);
        var result = await directus.updateItem('trainings', selectedData.id, { photo: newPhoto });

        const body_activity = {
          action: "Update",
          category: 6,
          description: selectedData.training_name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          getData();
          setDataButton("change_photo");
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        setLoadingButton(false);
      }
    } else {
      setError({ ...errorRef.current, photo: true })
    }
  }

  // {/* ================== Handle Upload File ========================================== */ }

  const uploadFile = async () => {
    try {
      const data = new FormData(my_form2.current);
      var result = await directus.uploadFiles(data, onUploadProgress);
      const photoId = result.data.id;
      setPhoto(result.data.data.full_url);
      setNewPhoto(photoId);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }


  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + "% Done");
  }

  const cancelFile = () => {
    setSelectedData(null);
    setNewPhoto(null);
    setPhoto(oldPhoto);
    setError({ ...errorRef.current, photo: false });
  }


  const footerTemplateAddTraining = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-success e-flat" data-ripple="true" onClick={(e) => addTraining(e)}>
            <span className="e-btn-icon e-icons icon-check-mark-01 e-icon-left"></span>{Update}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogAddTraining()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  const footerTemplateDeleteTraining = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-warning e-flat" data-ripple="true" onClick={(e) => deleteTraining(e)}>
            <span className="e-btn-icon e-icons icon-delete-05 e-icon-left"></span>{Delete}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogDeleteTraining()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  const footerTemplateChangePhoto = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-success e-flat" data-ripple="true" onClick={(e) => changePhoto(e)}>
            <span className="e-btn-icon e-icons icon-check-mark-01 e-icon-left"></span>{Update}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogChangePhoto()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */}

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Training}</div>
            <div className="content-searching hide-content">
              <div style={{ display: 'flex', width: "100%" }}>
                <div style={{ width: '250px', marginRight: "10px" }}>
                  <TextBoxComponent
                    cssClass="e-outline searching-text"
                    className="searching-input"
                    id="searching-input"
                    placeholder={Enter_activity_name}
                    onChange={(e) => handleChangeFilter(e, "training_name")}
                    onBlur={(e) => searchTraining(e)}
                    showClearButton="true"
                    value={stateFiltering.training_name}
                  />
                </div>
                <div>
                  <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddTraining}>
                    <span className="btn-text-custom">{Training}</span>
                  </ButtonComponent>
                </div>
                {/* <br />
              {data ? data.length > 0 ? 'Total : ' + data.length : <br />
                : ''} */}
              </div>
            </div>
          </div>
          <div className="content-main">
            {data ? data.length > 0 ?
              <div style={{ marginTop: "25px", paddingBottom: "20px", marginRight: "-5px" }}>
                <GridComponent
                  width="100%"
                  dataSource={data}
                  editSettings={editSettings}
                  allowSorting={true}
                  ref={grid}
                  allowTextWrap={true}
                  textWrapSettings={{ wrapMode: "Content" }}
                  allowResizing={true}
                  gridLines='Vertical'
                  actionBegin={actionGrid}
                  selectionSettings={setting}
                  allowReordering={true}
                  clipMode="EllipsisWithTooltip"
                >
                  <ColumnsDirective>
                    <ColumnDirective customAttributes={{ id: 'first' }} width="35%" field='training_name' headerText={Activities} template={nameTemplate} validationRules={{ required: true }}></ColumnDirective>
                    {/* <ColumnDirective customAttributes={{ id: 'first' }} width="10%" headerText={Photo} textAlign='Center' editTemplate={photoEditTemplate} template={photoEditTemplate}></ColumnDirective>
                    <ColumnDirective width="30%" field='training_name' headerText={Activities} validationRules={validationRule}></ColumnDirective> */}
                    <ColumnDirective width="15%" field='contact_name' headerText={Contact}></ColumnDirective>
                    <ColumnDirective width="22%" field='date' headerText={Date_2} validationRules={validationRule} editType='datepickeredit' edit={{ params: { format: 'dd-MMM-yyyy' } }} template={dateTemplate}></ColumnDirective>
                    <ColumnDirective width="16%" field='description' headerText={Description}></ColumnDirective>
                    <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="12%" template={buttonTemplate} editTemplate={buttonEditTemplate}></ColumnDirective>
                  </ColumnsDirective>
                  <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                </GridComponent>
              </div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
            }

            <DialogComponent
              id="dialogAddTraining"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogAddTraining}
              header={Training}
              isModal={true}
              close={handleCloseDialogAddTraining}
              footerTemplate={footerTemplateAddTraining}
            >
              <div>
                <div>
                  <b>*{Training_Name}</b>
                  <TextBoxComponent
                    name="training_name"
                    cssClass='e-outline'
                    change={(e) => handleChange(e, "training_name")}
                    value={state.training_name}
                  />
                  {error.training_name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <b>{Contact_Name}</b>
                  <TextBoxComponent
                    name="contact_name"
                    cssClass='e-outline'
                    change={(e) => handleChange(e, "contact_name")}
                    value={state.contact_name}
                  />
                  <br /><br />
                  <b>*{Date_2}</b>
                  <DatePickerComponent
                    name="date"
                    cssClass="e-outline"
                    format='dd-MMM-yyyy'
                    change={(e) => handleChange(e, "date")}
                    value={state.date}
                  >
                  </DatePickerComponent>
                  {error.date === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <b>{Description}</b>
                  <TextBoxComponent
                    name="description"
                    cssClass='e-outline'
                    change={(e) => handleChange(e, "description")}
                    multiline={true}
                    value={state.description}
                  />
                </div>
              </div>
            </DialogComponent>


            <DialogComponent
              id="dialogDeleteTraining"
              width="35%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogDeleteTraining}
              header={Delete_Confirmation}
              isModal={true}
              close={handleCloseDialogDeleteTraining}
              footerTemplate={footerTemplateDeleteTraining}
            >
              <div>
                {selectedData ?
                  <div style={{ fontSize: "14px" }}>
                    {Are_you_sure_you_want_to_delete_the_data_of_training} :"{selectedData.training_name}"?
                    <br /><br />

                  </div>
                  : ''}
              </div>
            </DialogComponent>

            <DialogComponent
              id="dialogChangePhoto"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogChangePhoto}
              header={Change_Photo}
              isModal={true}
              footerTemplate={footerTemplateChangePhoto}
              close={handleCloseDialogChangePhoto}
            >
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="image" style={{ width: "150px", height: "150px" }}>
                    <img
                      style={{
                        maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                        objectFit: "cover", position: "center",
                      }}
                      src={photo ? photo : link.no_activity_image}
                      alt="change_photo"
                    />
                  </div>
                </div>
                <br />
                <form ref={my_form2}>
                  <UploaderComponent
                    id='previewfileupload'
                    type='file'
                    ref={uploadObj}
                    asyncSettings={asyncSettings}
                    success={uploadFile}
                    multiple={false}
                    autoUpload={false}
                    clearing={cancelFile}
                  // selected={onSelect}
                  >
                  </UploaderComponent>
                  {error.photo === true ? <span style={{ color: 'red' }}>{Please_upload_the_photo}</span> : ''}
                </form>
              </div>
            </DialogComponent>
          </div>
        </div>
      }
    </div>
  );
}

export default Training;