import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { SidebarComponent, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
// import { select, Browser } from '@syncfusion/ej2-base';
import { makeStyles } from '@material-ui/core';
// import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

import i18next from "i18next";
import linq from "linq";

import EventEmitter from 'src/utils/EventEmitter';
import Logo from 'src/components/Logo';
import LogoIcon from 'src/components/LogoIcon';
import * as link from 'src/constants/link';
import directus, { changeProject } from 'src/services/directus';
// import * as sessions from 'src/utils/sessions';
// import * as permission from 'src/utils/Permission';

import "../sidebar.css";

const useStyles = makeStyles(({
  logo: {
    width: "175px",
    height: "40px",
    objectFit: "cover",
    marginTop: "30px",
    marginLeft: "50px"
  },
  logo_icon: {
    width: "40px",
    height: "40px",
    objectFit: "cover",
    marginTop: "30px",
    marginLeft: "30px"
  }
}));


const NavBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const path = window.location.pathname;
  
  var user = JSON.parse(window.localStorage.getItem('directus_data'));
  const firstName = user ? user.user.first_name : null;
  const lastName = user ? user.user.last_name : null;

  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));

  // const [Home] = useState(i18next.t('Pages.menu.Home'));
  const [Student_Data] = useState(i18next.t('Pages.menu.Student_Data'));
  const [Student_List] = useState(i18next.t('Pages.menu.Student_List'));
  const [Parent_Guardian] = useState(i18next.t('Pages.menu.Parent_Guardian'));
  const [Monthly_Report] = useState(i18next.t('Pages.menu.Monthly_Report'));
  const [Class_Data] = useState(i18next.t('Pages.menu.Class_Data'));
  const [Class_Summary] = useState(i18next.t('Pages.menu.Class_Summary'));
  const [Weekly_Schedule] = useState(i18next.t('Pages.menu.Weekly_Schedule'));
  const [Attendance] = useState(i18next.t('Pages.menu.Attendance'));
  const [Class_Attendance] = useState(i18next.t('Pages.menu.Class_Attendance'));
  const [Activities] = useState(i18next.t('Pages.menu.Activities'));
  const [Activity_List] = useState(i18next.t('Pages.menu.Activity_List'));
  const [Activity_Type] = useState(i18next.t('Pages.menu.Activity_Type'));
  const [Teacher_Data] = useState(i18next.t('Pages.menu.Teacher_Data'));
  const [Teacher_List] = useState(i18next.t('Pages.menu.Teacher_List'));
  const [Meeting_Summary] = useState(i18next.t('Pages.menu.Meeting_Summary'));
  const [Training_List] = useState(i18next.t('Pages.menu.Training_List'));
  const [Training_Participants] = useState(i18next.t('Pages.menu.Training_Participants'));
  const [Config_System] = useState(i18next.t('Pages.menu.Config_System'));
  const [Subject] = useState(i18next.t('Pages.menu.Subject'));
  const [Subject_Group] = useState(i18next.t('Pages.menu.Subject_Group'));
  const [Rooms] = useState(i18next.t('Pages.menu.Rooms'));
  const [Student_Profile] = useState(i18next.t('Pages.menu.Student_Profile'));
  const [Parent_Guardian_Profile] = useState(i18next.t('Pages.menu.Parent_Guardian_Profile'));
  const [Activity] = useState(i18next.t('Pages.menu.Activity'));
  const [Activity_Summary] = useState(i18next.t('Pages.menu.Activity_Summary'));
  const [Teacher_Profile] = useState(i18next.t('Pages.menu.Teacher_Profile'));
  const [Profile] = useState(i18next.t('Pages.menu.Profile'));
  const [Activity_Log] = useState(i18next.t('Pages.menu.Activity_Log'));
  const [Public_Holiday] = useState(i18next.t('Pages.menu.Public_Holiday'));
  const [Sign_Out] = useState(i18next.t('Pages.menu.Sign_Out'));
  const [Select_Organization] = useState(i18next.t('Pages.menu.Select_Organization'));
  // const [Staff_Data] = useState(i18next.t('Pages.menu.Staff_Data'));
  // const [Staff_List] = useState(i18next.t('Pages.menu.Staff_List'));
  const [Staff] = useState(i18next.t('Pages.staff_data.Staff'));



  let data_admin = [
    // {
    //   nodeId: '01', nodeText: Home, iconCss: 'e2-home icon', url: '/dashboard',
    // },
    {
      nodeId: '02', nodeText: Student_Data, iconCss: 'e2-table icon', expanded: false,
      nodeChild: [
        { nodeId: '02-01', nodeText: Student_List, url: '/student/list' },
        { nodeId: '02-02', nodeText: Parent_Guardian, url: '/contact/list' },
        { nodeId: '02-03', nodeText: Monthly_Report, url: '/class_student/monthly_report' },
      ]
    },
    {
      nodeId: '03', nodeText: Class_Data, iconCss: 'e2-file-text-02 icon', expanded: false,
      nodeChild: [
        { nodeId: '03-01', nodeText: Class_Summary, url: '/subject_class/list' },
        { nodeId: '03-02', nodeText: Weekly_Schedule, url: '/schedule/weekly' },
      ]
    },
    {
      nodeId: '04', nodeText: Attendance, iconCss: 'e2-to-do-list icon', expanded: false,
      nodeChild: [
        { nodeId: '04-01', nodeText: Class_Attendance, url: '/schedule/list/all' },
      ]
    },
    {
      nodeId: '05', nodeText: Activities, iconCss: 'icon e2-date-time-01', expanded: false,
      nodeChild: [
        { nodeId: '05-01', nodeText: Activity_List, url: '/activity/list' },
        { nodeId: '05-02', nodeText: Activity_Type, url: '/activity_type/list' },
      ]
    },
    {
      nodeId: '06', nodeText: Teacher_Data, iconCss: 'e2-table icon', expanded: false,
      nodeChild: [
        { nodeId: '06-01', nodeText: Teacher_List, url: '/teacher/list' },
        { nodeId: '06-02', nodeText: Meeting_Summary, url: '/schedule/teacher_summary' },
        { nodeId: '06-03', nodeText: Training_List, url: '/training/list' },
        { nodeId: '06-04', nodeText: Training_Participants, url: '/training_participant/list' },
      ]
    },
    {
      nodeId: '07', nodeText: Config_System, iconCss: 'e2-file-settings icon', expanded: false,
      nodeChild: [
        { nodeId: '07-06', nodeText: Staff, url: '/staff/list' },
        { nodeId: '07-01', nodeText: Subject, url: '/subject/list' },
        { nodeId: '07-02', nodeText: Subject_Group, url: '/subject_group/list' },
        { nodeId: '07-03', nodeText: Rooms, url: '/room/list' },
        { nodeId: '07-04', nodeText: Activity_Log, url: '/activity_log/list' },
        { nodeId: '07-05', nodeText: Public_Holiday, url: '/public_holiday/list' },
      ]
    }
  ];

  let data_teacher = [
    // {
    //   nodeId: '01', nodeText: Home, iconCss: 'e2-home icon', url: '/dashboard',
    // },
    {
      nodeId: '02', nodeText: Student_Data, iconCss: 'e2-table icon', expanded: false,
      nodeChild: [
        { nodeId: '02-01', nodeText: Student_List, url: '/student/list' },
      ]
    },
    {
      nodeId: '03', nodeText: Class_Data, iconCss: 'e2-file-text-02 icon', expanded: false,
      nodeChild: [
        { nodeId: '03-01', nodeText: Class_Summary, url: '/subject_class/list' },
        { nodeId: '03-02', nodeText: Weekly_Schedule, url: '/schedule/weekly' },
      ]
    },
    {
      nodeId: '04', nodeText: Attendance, iconCss: 'e2-to-do-list icon', expanded: false,
      nodeChild: [
        { nodeId: '04-01', nodeText: Class_Attendance, url: '/schedule/list/all' },
      ]
    },
    {
      nodeId: '05', nodeText: Activities, iconCss: 'icon e2-date-time-01', expanded: false,
      nodeChild: [
        { nodeId: '05-01', nodeText: Activity_List, url: '/activity/list' },
      ]
    },
    {
      nodeId: '06', nodeText: Teacher_Data, iconCss: 'e2-table icon', expanded: false,
      nodeChild: [
        { nodeId: '06-01', nodeText: Teacher_List, url: '/teacher/list' },
        { nodeId: '06-03', nodeText: Training_List, url: '/training/list' },
        { nodeId: '06-04', nodeText: Training_Participants, url: '/training_participant/list' },
      ]
    },
  ];


  const [selectedData, setSelectedData] = useState(null);
  const [initialized, setInitialized] = useState(false);


  const organization = localStorage.getItem("school_list") ? JSON.parse(localStorage.getItem("school_list")) : [];
  
  var school = localStorage.getItem("school") ? JSON.parse(localStorage.getItem("school")) : '';
  var selectedOrganization;
  if (school) {
    selectedOrganization = linq.from(organization)
      .where(w => w.school.domain === school)
      .toArray();
  } else {
    window.localStorage.clear();
    window.location.reload();
  }

  /* eslint-disable no-unused-vars*/
  const [viewOrganization, setViewOrganization, viewOrganizationRef] = useStateRef(false);
  const [viewProfile, setViewProfile, viewProfileRef] = useStateRef(path === "/user/profile" ? true : false);
  const [fields, setFields, fieldsRef] = useStateRef({ dataSource: [], id: 'nodeId', text: 'nodeText', child: 'nodeChild' });
  const userSetting = localStorage.user_setting ? JSON.parse(localStorage.user_setting) : null;
  var sidebarobj = useRef(null);
  var treeviewobj = useRef(null);
  var menuObj = useRef(null);
  /* eslint-enable no-unused-vars*/

  //sidebar style
  const width = '275px';
  const target = '.main-content';
  const mediaQuery = '(min-width: 1440px)';
  const dockSize = "95px";

  useEffect(() => {
    const openSidebar = () => { toggleClick(); }

    const listener = EventEmitter.addListener('openSidebar', openSidebar);
    return () => {
      listener.remove();
    }
  })

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      if (userSetting) {
        if (userSetting.account_type === "administrator" || userSetting.account_type === "staff") {
          setFields({ ...fieldsRef.current, dataSource: data_admin })
        } else if (userSetting.account_type === "teacher") {
          setFields({ ...fieldsRef.current, dataSource: data_teacher })
        } else {

        }
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  //sidebar oncreate 
  const onCreate = () => {
    sidebarobj.current.element.style.visibility = '';
    var id = null;
    var idChild = null;
    var new_title = '';

    //check url if it is in the array data and return the id
    // if (path === "/") { id = "01" }
    if (path === "/") { id = "02"; idChild = "02-01"; }

    if (path.match(/\d/)) {
      if (path.startsWith("/student/profile/")) { id = "02"; idChild = "02-01"; new_title = Student_Profile; }
      else if (path.startsWith("/contact/profile/")) { id = "02"; idChild = "02-02"; new_title = Parent_Guardian_Profile; }
      else if (path.startsWith("/subject_class/detail/")) { id = "03"; idChild = "03-01"; new_title = Class_Summary; }
      else if (path.startsWith("/activity/detail/")) { id = "05"; idChild = "05-01"; new_title = Activity; }
      else if (path.startsWith("/activity_type/summary/")) { id = "05"; idChild = "05-02"; new_title = Activity_Summary; }
      else if (path.startsWith("/teacher/profile/")) { id = "06"; idChild = "06-01"; new_title = Teacher_Profile; }
      else if (path.startsWith("/subject/view/")) { id = "07"; idChild = "07-01"; new_title = Subject; }
      else if (path.startsWith("/training_participant/list/")) { id = "06"; idChild = "06-04"; new_title = Training_Participants }
    } else {
      /* eslint-disable array-callback-return*/
      fieldsRef.current.dataSource.map((value) => {
        if (value.url === path) {
          id = value.nodeId;
          new_title = value.nodeText;
        } else {
          if (value.nodeChild) {
            value.nodeChild.map((valueChild) => {
              if (valueChild.url === path) {
                id = value.nodeId;
                idChild = valueChild.nodeId;
                new_title = valueChild.nodeText;
              }
            })
          }
        }
      })
      /* eslint-enable array-callback-return*/
    }

    if (path.startsWith("/user/profile")) { new_title = Profile }

    if (new_title) {
      document.title = new_title + " - Akademix";
    } else {
      document.title = "Akademix";
    }

    //set the menu active
    const parentEle = setActive(id, 1);
    if (parentEle) {
      toggleSidebar([parentEle], "oncreate");
    }
    setActive(idChild, 2)
  }

  //collapse all menu
  const onClose = () => {
    // treeviewobj.current.collapseAll();
  }

  //show/hide menu onclick
  const toggleClick = () => {
    if (sidebarobj.current.isOpen) {
      sidebarobj.current.hide();
      // treeviewobj.current.collapseAll();
    }
    else {
      sidebarobj.current.show();
      if (selectedData) {
        treeviewobj.current.expandAll(selectedData);
      }

    }
  }

  //show/hide menu automatically
  const toggleSidebar = (props, type) => {
    //eslint-disable-next-line no-unused-vars
    var status = false;
    var classList = props[0].classList;
    for (var index = 0; index < classList.length; index++) {
      if (classList[index] === "e-node-collapsed") {
        status = true;
      }
    }

    // setViewSidebar(status);
    treeviewobj.current.collapseAll();
    if (type === "oncreate") {
      treeviewobj.current.expandAll(props);
      treeviewobj.current.expandOn = 'None';
      setSelectedData(props);
    } else {

      treeviewobj.current.expandAll(props);
      treeviewobj.current.expandOn = 'None';
      setSelectedData(props);
    }
  }

  const nodeClicked = (args) => {
    setViewProfile(false);
    setViewOrganization(false);
    if (args.event.which === 3) {
      treeviewobj.current.selectedNodes = [args.node.getAttribute('data-uid')];
    }
    if (args.node.classList.contains('e-level-1')) {
      toggleSidebar([args.node], "nodeclicked");
    }

    const new_id = args.node.dataset.uid;
    var rootData = treeviewobj.current.rootData;
    var id = null;
    var new_url = null;
    var new_title = null;

    if (new_id) {
      if (rootData.length > 0) {
        for (var i = 0; i < rootData.length; i++) {
          if (rootData[i].nodeId) {
            if (rootData[i].nodeId === new_id) {
              id = rootData[i].nodeId;
              if (rootData[i].nodeId) {
                new_url = rootData[i].url;
                new_title = rootData[i].nodeText;
              }
              break;
            } else {
              var nodeChild = rootData[i].nodeChild;
              if (nodeChild) {
                if (nodeChild.length > 0) {
                  for (var j = 0; j < nodeChild.length; j++) {
                    if (nodeChild[j].nodeId === new_id) {
                      if (nodeChild[j].url !== path) {
                        new_url = nodeChild[j].url;
                        id = rootData[i].nodeId;
                        new_title = nodeChild[j].nodeText;
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      try {

        if (new_url) {
          if (args.event.button === 0) {
            setActive(id, 1);
            document.title = new_title + " - Akademix";
            return navigate(new_url);
          } else if (args.event.button === 1) {
            window.open(new_url, "_blank")
          }
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      }
    }

  }

  //set active menu
  const setActive = (id, level) => {
    if (id) {
      var parent = document.getElementsByClassName('e-level-' + level);
      if (parent.length > 0) {
        for (var i = 0; i < parent.length; i++) {
          if (parent[i].dataset.uid === id) {
            parent[i].classList.add("e-active");
            return parent[i]
          }
        }
      }
    }
  }

  //ContextMenu items definition
  const menuItems = [
    { text: 'Open link in new tab' },
    { text: 'Open link in new window' },
    { separator: true },
    { text: 'Copy link address' },
  ];

  const menuClick = (args) => {
    let targetNodeId = treeviewobj.current.selectedNodes[0];

    var new_url = null;
    /* eslint-disable array-callback-return*/
    fieldsRef.current.dataSource.map((value) => {
      if (value.nodeId === targetNodeId) {
        new_url = value.url;
      } else {
        if (value.nodeChild) {
          value.nodeChild.map((valueChild) => {
            if (valueChild.nodeId === targetNodeId) {
              new_url = valueChild.url;
            }
          })
        }
      }
    })
    /* eslint-enable array-callback-return*/

    if (!new_url) { new_url = path; }

    if (args.item.text === "Open link in new tab") {
      window.open(new_url, "_blank")
    }

    if (args.item.text === "Open link in new window") {
      window.open(new_url, '_blank', 'location=0');
    }

    if (args.item.text === "Copy link address") {
      copy(new_url)
    }
  }

  function copy(new_url) {
    const el = document.createElement('input');
    var copylink = window.location.origin + "" + new_url;
    el.value = copylink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  const expandProfile = (view) => {
    setViewProfile(view);
    setViewOrganization(false);
  }

  const expandOrganization = (view) => {
    setViewOrganization(view);
    setViewProfile(false);
  }

  const changeOrganization = async (props) => {
    if (props.school.domain === JSON.parse(localStorage.getItem("school"))) {
      setViewOrganization(false);
    } else {
      localStorage.setItem("directus_data", JSON.stringify(props));
      changeProject(props.school.domain);

      var lang = "id";
      if (props.user.locale === "en-US") { lang = "en" }
      window.localStorage.setItem("lang", lang);

      //set user setting
      var user = props;
      var setting = null;
      if (user.account_type === "administrator") {
        setting = {
          account_type: "administrator",
          user_id: user.user.id,
          data_id: null,
          email: user.user.email,
          name: user.user.first_name + " " + user.user.last_name,
          mobile_number: null,
          lang: lang
        }
      } else {
        var user_setting = null;
        if (user.account_type === "staff") { user_setting = "staff" }
        else if (user.account_type === "student") { user_setting = "students" }
        else if (user.account_type === "teacher") { user_setting = "teachers" }
        else if (user.account_type === "contact") { user_setting = "contacts" }

        var result = await directus.getItems(user_setting, {
          fields: "*.*",
          filter: {
            "user": user.user.id
          }
        });

        var new_data = result.data[0];
        setting = {
          account_type: user.account_type,
          user_id: user.user.id,
          data_id: new_data.id,
          email: user.user.email,
          name: user.user.first_name + " " + user.user.last_name,
          mobile_number: new_data.mobile_number,
          lang: window.localStorage.lang ? window.localStorage.lang : "id"
        }
      }

      localStorage.setItem('user_setting', JSON.stringify(setting));

      setTimeout(function () { window.location.href = "/"; }, 1000);
    }
  }

  const clickProfile = () => {
    document.title = Profile + " - Akademix";
  }

  const signout = () => {
    window.localStorage.clear();
    window.location.reload();
  }

  const openSidebar = () => {
    EventEmitter.emit('openSidebar', { value: "click" });
  }

  const getImage = (props) => {
    var school_images = JSON.parse(localStorage.getItem("school_images"));
    var result = linq.from(school_images)
      .where(w => w.name === props)
      .toArray();

    return result[0].image ? result[0].image.full_url : "/static/images/image_not_available.png";
  }

  return fieldsRef.current.dataSource.length > 0 ? (
    <SidebarComponent
      id="sidebar-treeview"
      ref={sidebarobj}
      width={width}
      target={target}
      mediaQuery={mediaQuery}
      style={{ visibility: "hidden" }}
      created={onCreate}
      close={onClose}
      dockSize={dockSize}
      enableGestures={false}
      enableDock={true}
      animate={false}
    >
      <div className='main-menu' style={{ height: "100%" }}>
        <div className="close-sidebar" onClick={() => openSidebar()}>
          <span className="e-icons icon-chevron-left-03"
            style={{ fontSize: "10px", color: '#516573', marginRight: "20px", fontWeight: "800" }}
          />
        </div>
        <Link className="logo-open" to={{ pathname: `/` }} style={{ textDecoration: "none" }}><Logo className={classes.logo} /></Link>
        <Link className="logo-close" to={{ pathname: `/` }} style={{ textDecoration: "none" }}><LogoIcon className={classes.logo_icon} /></Link>

        <div id="tree" className={`treeview ${viewProfileRef.current === true ? "resize_profile" : viewOrganizationRef.current === true ? "resize_organization" : ""}`}>
          <TreeViewComponent id='main-treeview' ref={treeviewobj} fields={fieldsRef.current} expandOn='Click'
            nodeClicked={(e) => nodeClicked(e)}
          />
        </div>
        <div className='main-menu-bottom' style={{ left: "20px", right: "20px", position: "fixed", bottom: 0 }}>
          {/* <div className='main-menu-bottom' style={{ left: "20px", right: "20px", position: "fixed", marginTop: "250px" }}> */}
          {viewProfileRef.current === false ?
            <div className="hide-background" style={{ cursor: "pointer", width: "235px", height: "40px", borderRadius: "40px", marginBottom: "10px" }}
              onClick={() => expandProfile(true)}
            >
              <div style={{ display: 'flex', width: "100%" }}>
                <img
                  style={{
                    maxWidth: "25px", minWidth: "25px", maxHeight: "25px", minHeight: "25px",
                    objectFit: "cover", position: "center", marginTop: "7.5px", marginLeft: "18px",
                    borderRadius: "50%",
                  }}
                  src={link.default_image}
                  alt="user_image"
                />
                <span className="hide-text sidebar_text" style={{ fontWeight: "700" }}>{firstName} {lastName}</span>
                <span className="e-icons icon-chevron-right-03 hide-text"
                  style={{ position: "absolute", right: "16.77px", paddingTop: "15px", fontSize: "10px", color: '#B5C2CC' }}
                />
              </div>
            </div>
            :
            <div style={{ marginBottom: "20px" }}>
              <div className="hide-background" style={{ width: "235px", height: "40px", borderRadius: "20px 20px 0px 0px", cursor: "pointer" }} onClick={() => expandProfile(false)}>
                <div style={{ display: 'flex', width: "100%" }}>
                  <img
                    style={{
                      maxWidth: "25px", minWidth: "25px", maxHeight: "25px", minHeight: "25px",
                      objectFit: "cover", position: "center", marginTop: "7.5px", marginLeft: "18px",
                      borderRadius: "50%",
                    }}
                    src={link.default_image}
                    alt="user_image"
                  />
                  <div className="hide-text sidebar_text" style={{ fontWeight: "700" }}>{firstName} {lastName}</div>
                  <div className="e-icons icon-up-arrow-1 hide-text"
                    style={{ position: "absolute", right: "16.77px", paddingTop: "15px", fontSize: "10px", color: '#374355' }}
                  />
                </div>
              </div>
              <Link to={{ pathname: `/user/profile` }} style={{ textDecoration: "none" }} onClick={() => clickProfile()}>
                <div style={{ width: "235px", height: "auto" }}>
                  <div className={`${path === "/user/profile" ? "profile_menu active" : "profile_menu"} hide-background`} style={{ display: 'flex', width: "100%", height: "40px", color: "#516573" }}>
                    <div className="e-icons icon-MT_Resource profile_icon"
                      style={{ fontSize: "20.83px", marginTop: "7.5px", marginLeft: "20.07px" }}
                    />
                    <div className="profile_text hide-text" style={{ paddingLeft: "12.09px", paddingTop: "10.4px" }}>{Profile}</div>
                  </div>
                </div>
              </Link>
              <div style={{ width: "235px", height: "auto", cursor: "pointer" }} onClick={() => signout()}>
                <div className="sign_out_menu hide-background" style={{ display: 'flex', width: "100%", height: "40px", color: "#516573", borderRadius: "0 0 20px 20px" }}>
                  <div className="e-icons e2-exit sign_out_icon"
                    style={{ fontSize: "18.75px", marginTop: "7.5px", marginLeft: "20.07px" }}
                  />
                  <div className="sign_out_text hide-text" style={{ paddingLeft: "13.12px", paddingTop: "10.4px" }}>{Sign_Out}</div>
                </div>
              </div>
            </div>
          }

          {viewOrganizationRef.current === false ?
            <div className="hide-background" style={{ cursor: "pointer", width: "235px", height: "auto", borderRadius: "40px", marginBottom: "20px" }}
              onClick={() => expandOrganization(true)}>
              <div style={{ display: 'flex', width: "100%", height: "40px" }}>
                <img
                  style={{
                    maxWidth: "25px", minWidth: "25px", maxHeight: "25px", minHeight: "25px",
                    objectFit: "cover", position: "center", marginTop: "7.5px", marginLeft: "18px",
                    borderRadius: "50%",
                  }}
                  src={getImage(selectedOrganization[0].school.domain)}
                  alt={selectedOrganization[0].school.name}
                />
                <span className="hide-text sidebar_text" style={{ fontWeight: "700" }}>{selectedOrganization[0].school.name}</span>
                <span className="e-icons icon-chevron-right-03 hide-text"
                  style={{ position: "absolute", right: "16.77px", paddingTop: "15px", fontSize: "10px", color: '#B5C2CC' }}
                />
              </div>
            </div>

            :

            <div>
              <div style={{ width: "235px", height: "auto", cursor: "pointer" }} onClick={() => expandOrganization(false)}>
                <div className="hide-background" style={{ display: 'flex', width: "100%", height: "40px", borderRadius: "20px 20px 0px 0px" }}>
                  <div>
                    <img
                      style={{
                        maxWidth: "19px", minWidth: "19px", maxHeight: "21px", minHeight: "21px",
                        objectFit: "cover", position: "center", marginTop: "8.04px", marginLeft: "21.08px"
                      }}
                      src="/static/images/organization.png"
                      alt="organization"
                    />
                  </div>
                  <div className="hide-text sidebar_text" style={{ marginLeft: "12.92px", fontWeight: "700", marginTop: "10.4px" }}>{Select_Organization}</div>
                  <div className="e-icons icon-up-arrow-1 hide-text"
                    style={{ position: "absolute", right: "16.77px", marginTop: "15px", fontSize: "10px", color: '#374355' }}
                  />
                </div>
              </div>
              <div className="organization_custom">
                {organization.map((value, index) => (
                  <div style={{ width: "235px", height: "auto" }} key={index}>
                    <div className="hide-background" style={{ display: 'flex', width: "100%", height: "40px", color: selectedOrganization[0].domain === value.school.domain ? "#5F99E9" : "#516573", borderRadius: index === organization.length - 1 ? "0px 0px 20px 20px" : "0px", position: "relative" }}>
                      <div>
                        <img
                          style={{
                            maxWidth: "25px", minWidth: "25px", maxHeight: "25px", minHeight: "25px",
                            objectFit: "cover", position: "center", marginTop: "7.5px", marginLeft: "18px",
                            borderRadius: "50%",
                          }}
                          src={getImage(value.school.domain)}
                          alt={value.school.name}
                        />
                      </div>
                      <div className="hide-text sidebar_text" style={{ fontWeight: selectedOrganization[0].school.domain === value.school.domain ? "700" : "500", color: selectedOrganization[0].school.domain === value.school.domain ? "#5F99E9" : "#516573" }}>{value.school.name}</div>
                      <div className="hide-text" style={{ position: "absolute", right: "13px", marginTop: "15px" }}>
                        <div className={selectedOrganization[0].school.domain === value.school.domain ? "checkbox_custom_active" : "checkbox_custom"}
                          style={{ width: "15px", height: "15px", borderRadius: "30%", cursor: "pointer" }}
                          onClick={() => changeOrganization(organization[index])}>
                          {selectedOrganization[0].school.domain === value.school.domain ?
                            <span className="e-icons icon-check-mark-01" style={{ color: "white", marginLeft: "1.5px", marginRight: "1.5px" }} />
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
        </div>
        <ContextMenuComponent id="contentmenutree" target='#main-treeview' items={menuItems} select={menuClick} ref={(contextmenu) => { menuObj = contextmenu; }} />
      </div>
    </SidebarComponent>
  ) : ''
    ;
};

export default NavBar;
