import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { MultiSelectComponent, CheckBoxSelection, Inject as Inject2 } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { InPlaceEditorComponent } from '@syncfusion/ej2-react-inplace-editor';
import { Query } from '@syncfusion/ej2-data';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";
import Enumerable from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

import './index.css';

const Biodata = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();

  var searchTimeout = null;

  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [Name] = useState(i18next.t('Pages.data.Name'));
  const [Nickname] = useState(i18next.t('Pages.data.Nickname'));
  const [Gender] = useState(i18next.t('Pages.data.Gender'));
  const [Place_of_Birth] = useState(i18next.t('Pages.data.Place_of_Birth'));
  const [Date_of_Birth] = useState(i18next.t('Pages.data.Date_of_Birth'));
  const [Mobile_Number] = useState(i18next.t('Pages.data.Mobile_Number'));
  const [Male] = useState(i18next.t('Pages.data.Male'));
  const [Female] = useState(i18next.t('Pages.data.Female'));
  const [Religion] = useState(i18next.t('Pages.data.Religion'));
  const [Islam] = useState(i18next.t('Pages.data.Islam'));
  const [Christian_Protestant] = useState(i18next.t('Pages.data.Christian_Protestant'));
  const [Catholic] = useState(i18next.t('Pages.data.Catholic'));
  const [Buddha] = useState(i18next.t('Pages.data.Buddha'));
  const [Hindu] = useState(i18next.t('Pages.data.Hindu'));
  const [Confucianism] = useState(i18next.t('Pages.data.Confucianism'));
  const [Others] = useState(i18next.t('Pages.data.Others'));
  const [Notes] = useState(i18next.t('Pages.data.Notes'));
  const [Created_by] = useState(i18next.t('Pages.data.Created_by'));
  const [Modified_by] = useState(i18next.t('Pages.data.Modified_by'));
  const [Last_Modified] = useState(i18next.t('Pages.data.Last_Modified'));
  const [Select_Student] = useState(i18next.t('Pages.data.Select_Student'));

  const [Age_must_be_3_or_older] = useState(i18next.t('Pages.student_data.Age_must_be_3_or_older'));
  const [Hobby] = useState(i18next.t('Pages.student_data.Hobby'));
  const [Sibling_Names] = useState(i18next.t('Pages.student_data.Sibling_Names'));
  const [Address] = useState(i18next.t('Pages.student_data.Address'));
  const [School] = useState(i18next.t('Pages.student_data.School'));
  const [Current_School] = useState(i18next.t('Pages.student_data.Current_School'));
  const [Current_School_Level] = useState(i18next.t('Pages.student_data.Current_School_Level'));
  const [Last_Course] = useState(i18next.t('Pages.student_data.Last_Course'));
  const [Previous_School_Name] = useState(i18next.t('Pages.student_data.Previous_School_Name'));
  const [Previous_School_Level] = useState(i18next.t('Pages.student_data.Previous_School_Level'));
  const [Previous_School_Address] = useState(i18next.t('Pages.student_data.Previous_School_Address'));
  const [Previous_School_Notes] = useState(i18next.t('Pages.student_data.Previous_School_Notes'));
  const [Start_Course] = useState(i18next.t('Pages.student_data.Start_Course'));
  const [Start_Class] = useState(i18next.t('Pages.student_data.Start_Class'));
  const [Start_Date] = useState(i18next.t('Pages.student_data.Start_Date'));
  const [Where_did_you_find_the_course] = useState(i18next.t('Pages.student_data.Where_did_you_find_the_course'));
  const [Reason_for_registering] = useState(i18next.t('Pages.student_data.Reason_for_registering'));
  const [Exit_Course] = useState(i18next.t('Pages.student_data.Exit_Course'));
  const [Exit_Date] = useState(i18next.t('Pages.student_data.Exit_Date'));
  const [Exit_Reason] = useState(i18next.t('Pages.student_data.Exit_Reason'));
  const [Accounting_Link] = useState(i18next.t('Pages.student_data.Accounting_Link'));
  // const [Siblings] = useState(i18next.t('Pages.student_data.Siblings'));
  const [Related_Student] = useState(i18next.t('Pages.student_data.Related_Student'));

  //data
  const [data, setData, dataRef] = useStateRef(null);
  const [dataSibling, setDataSibling, dataSiblingRef] = useStateRef(null);
  const [dataStudent, setDataStudent, dataStudentRef] = useStateRef(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loadingBiodata, setLoadingBiodata] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);

  //updatesibling 
  let dialogUpdateSiblings = useRef(false);
  let mulObj = useRef(false);

  //error
  const [error, setError, errorRef] = useStateRef({ date_of_birth: false });

  const religion = [
    { id: 'Islam', text: Islam },
    { id: 'Christian/Protestant', text: Christian_Protestant },
    { id: 'Catholic', text: Catholic },
    { id: 'Buddha', text: Buddha },
    { id: 'Hindu', text: Hindu },
    { id: 'Confucianism', text: Confucianism },
    { id: 'Others', text: Others },
  ];
  const gender = [{ id: "Male", name: Male }, { id: "Female", name: Female }]
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoadingBiodata(true);
      getStudent();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  useEffect(() => {
    const reloadData = () => {
      getStudent();
    }

    EventEmitter.addListener('updateSibling', reloadData);
  })

  // useEffect(() => {
  //   if (loadingButtonRef.current === false) {
  //     if (dataButtonRef.current === "add_sibling") {
  //       dialogAddSibling.current.visible = false;
  //       setStateSibling(null);
  //       EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
  //       EventEmitter.emit('reloadRelationshipData', { text: "click" });
  //       setDataButton(null);
  //     }
  //   }
  // })

  // {/* ================== Get All Data ========================================== */ }
  //get student data
  const getStudent = async () => {
    try {
      var result = await directus.getItems('students',
        {
          fields: '*',
          filter: {
            id: id
          }
        }
      );

  
      setData(result.data[0]);
      getSiblings();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get student siilng
  const getSiblings = async () => {
    try {
      var result = await directus.getItems('siblings',
        {
          fields: '*,student_2.id,student_2.name,student_2.nickname',
          filter: {
            "student_1.id": { "in": id }
          }
        }
      );

      console.log("student", result)

      setDataSibling(result.data);
      var sibling_data = linq.from(result.data)
        .select(x => x.student_2)
        .toArray()
      var sibling_id = linq.from(result.data)
        .select(x => x.student_2.id)
        .toArray()
      getStudents(sibling_id, sibling_data);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const getStudents = async (sibling_id, sibling_data) => {
    try {
      var sibling = { "logical": "and", "nin": id };
      if (sibling_id.length > 0) {
        sibling_id.push(id);
        sibling = { "logical": "and", "nin": sibling_id }
      }
      var result = await directus.getItems('students',
        {
          fields: 'id,name,nickname',
          filter: {
            id: sibling,
          },
          limit: 10,
          page: 1,
          sort: "nickname"
        }
      );

      var newData = sibling_data;
      if (result.data.length > 0) {
        newData = sibling_data.concat(result.data);
      }

      setDataStudent(newData)
      setLoadingBiodata(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }


  // {/* ================== Inplace Editor Component ========================================== */ }
  const actionSuccess = async (type, event) => {
    var value = '';
    try {
      var error2 = false;
      if (type === "date_of_birth") {
        value = event.value ? moment(new Date(event.value)).format("YYYY-MM-DD") : null;
        const birthdate = moment(new Date(value), 'MM-YYYY');
        const now = moment();
        var date = moment.duration(now.diff(birthdate));
        if (date.years() < 3) {
          error2 = true;
        }
      } else if (type === "start_date" || type === "exit_date") {
        value = event.value ? moment(new Date(event.value)).format("YYYY-MM-DD") : null;
      } else if (type === "gender") {
        value = getGender(event.value, "id");
      } else if (type === "religion") {
        value = getReligion(event.value, "id");
      } else {
        value = event.value;
      }

      if (!error2) {
        var result = await directus.updateItem('students', id,
          { [type]: value }
        );

        var description = data.name;
        if (type === "name" && data.name !== value) {
          description = data.name + " --> " + value;
        }

        if (result.data) {
          const body_activity = {
            action: "Update",
            category: 2,
            description: description,
            status: "published"
          }
          await directus.createItem('activity_log', body_activity);

          EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          if (type === "date_of_birth" || type === "name" || type === "nickname" || type === "mobile_number") {
            EventEmitter.emit('reloadStudentData', { text: "click" });
          }
        }
        setError({ date_of_birth: false })
      } else {
        setError({ date_of_birth: true })
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Update Sibling ========================================== */ }

  const handleOpenDialogUpdateSiblings = () => {
    var sibling_id = linq.from(dataSiblingRef.current)
      .select(data => data.student_2.id)
      .toArray();

    mulObj.current.value = sibling_id;
    dialogUpdateSiblings.current.visible = true;
  }

  const handleCloseDialogUpdateSiblings = () => {
    mulObj.current.value = [];
    dialogUpdateSiblings.current.visible = false;
  }

  //add new contact to database
  const updateSiblings = async () => {
    try {
      setLoadingButton(true);
      var mul_value = mulObj.current.value;

      var old_sibling_id = Enumerable.from(dataSiblingRef.current)
        .where(w =>
          Enumerable.from(mul_value).contains(w.student_2.id))
        .select(data => data.student_2.id)
        .toArray();

      if (mul_value.length > 0) {
        var new_sibling_id = [];
        //eslint-disable-next-line array-callback-return
        mul_value.map((value) => {
          if (old_sibling_id.indexOf(value) === -1) {
            new_sibling_id.push(value);
          }
        })

        if (old_sibling_id.length < dataSibling.length) {
          var delete_sibling = [];
          var delete_sibling_id = [];
          //eslint-disable-next-line array-callback-return
          dataSibling.map((value) => {
            if (old_sibling_id.indexOf(value.student_2.id) === -1) {
              delete_sibling.push(value.id);
              delete_sibling_id.push(value.student_2.id);
            }
          })

          var result = await directus.getItems('siblings',
            {
              fields: 'id',
              filter: {
                student_1: { "in": delete_sibling_id }
              }
            }
          );

          //eslint-disable-next-line array-callback-return
          result.data.map((value) => {
            delete_sibling.push(value.id);
          })

          await directus.deleteItems('siblings', delete_sibling);
        }

        if (new_sibling_id.length > 0) {
          var new_sibling = [];
          //eslint-disable-next-line array-callback-return
          new_sibling_id.map((value) => {
            new_sibling.push({ student_1: id, student_2: value, status: "published" })

            new_sibling.push({ student_1: value, student_2: id, status: "published" })
          })

          await directus.createItems('siblings', new_sibling);
        }

        getSiblings();
        EventEmitter.emit('reloadRelationshipData', { text: "click" })
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        handleCloseDialogUpdateSiblings();
      } else {
        var delete_sibling2 = linq.from(dataSiblingRef.current)
          .select(data => data.id)
          .toArray();


        var delete_sibling_id2 = linq.from(dataSiblingRef.current)
          .select(data => data.student_2.id)
          .toArray();

        var result_2 = await directus.getItems('siblings',
          {
            fields: 'id',
            filter: {
              student_1: { "in": delete_sibling_id2 }
            }
          }
        );
        
        //eslint-disable-next-line array-callback-return
        result_2.data.map((value) => {
          delete_sibling2.push(value.id);
        })

        await directus.deleteItems('siblings', delete_sibling2);
        EventEmitter.emit('reloadRelationshipData', { text: "click" })
        getSiblings();
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });

        handleCloseDialogUpdateSiblings();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  const onFiltering = async (e) => {
    let query = new Query();
    // //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('nickname', 'contains', e.text, true) : query;
    // e.updateData(dataStudentRef.current, query);
    //pass the filter data source, filter query to updateData method.
    clearTimeout(searchTimeout);
    try {
      if (e.text !== '') {
        searchTimeout = window.setTimeout(async () => {
          var result = await directus.getItems('students',
            {
              fields: 'id,name,nickname',
              filter: {
                nickname: { "contains": e.text },
                // id: { "nin": mulObj.current.value },
                id: { "logical": "and", "nin": id }
              },
              limit: 5,
              page: 1
            }
          );

          e.updateData(result.data, query);
        }, 200)
      }
      else {
        searchTimeout = window.setTimeout(async () => {
          var result = await directus.getItems('students',
            {
              fields: 'id,name,nickname',
              filter: {
                id: { "nin": id }
              },
              limit: 5,
              page: 1
            }
          );
          e.updateData(result.data, query);
        }, 200)
      }


    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }

    // 
  }


  // {/* ================== Translate Gender, Religion Data ========================================== */ }

  const getGender = (props, type) => {
    var gender2 = props ? props : null;
    if (gender) {
      gender.forEach((value) => {
        if (type === "name") {
          if (value.id === gender2) { gender2 = value.name }
        }
        if (type === "id") {
          if (value.name === gender2) { gender2 = value.id }
        }
      })
    }

    return "" + gender2
  }

  const getReligion = (props, type) => {
    var religion2 = props ? props : null;
    if (religion) {
      religion.forEach((value) => {
        if (type === "name") {
          if (value.id === religion2) { religion2 = value.text }
        }
        if (type === "id") {
          if (value.text === religion2) { religion2 = value.id }
        }
      })
    }

    return "" + religion2
  }

  // {/* ================== Handle Update Sibling ========================================== */ }
  const getSiblingName = () => {
    var sibling_name = '';

    dataSibling.map((value, index) => {
      sibling_name += value.student_2.nickname;
      if (index !== dataSibling.length - 1) {
        return sibling_name += ", ";
      }
      return sibling_name;
    })
    return (<span>{sibling_name}</span>)
  }

  const footerTemplateUpdateSiblings = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-success e-flat" data-ripple="true" onClick={(e) => updateSiblings(e)}>
            <span className="e-btn-icon e-icons icon-check-mark-01 e-icon-left"></span>{Update}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogUpdateSiblings()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }

  return (
    <div id="biodata" style={{ alignContent: "center", color: "#333", minHeight: "600px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }}>
      {loadingBiodata ? <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} /> :
        <div>
          {data ?
            <table id="table_biodata" style={{ marginLeft: "auto", marginRight: "auto", marginTop: "20px", border: "1px solid #ccc", borderStyle: "1px solid #ccc" }}>
              <tbody>
                <tr>
                  <td style={{ width: "25%" }}>{Name}</td>
                  <td>
                    <InPlaceEditorComponent id='name' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' name='name' data-underline='false' editableOn="DblClick" value={data.name} model={{ placeholder: Name }} actionSuccess={(e) => actionSuccess("name", e)} validationRules={{ name: { required: true } }} />
                  </td>
                </tr>
                <tr>
                  <td>{Nickname}</td>
                  <td>
                    <InPlaceEditorComponent id='nickname' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.nickname} model={{ placeholder: Nickname }} actionSuccess={(e) => actionSuccess("nickname", e)} validationRules={{ nickname: { required: true } }} />
                  </td>
                </tr>
                <tr>
                  <td>{Gender}</td>
                  <td>
                    <InPlaceEditorComponent id='gender' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" type='DropDownList' value={data.gender ? getGender(data.gender, "name") : null} model={{ dataSource: gender, placeholder: Gender, fields: { text: 'name', value: 'name' } }} actionSuccess={(e) => actionSuccess("gender", e)} validationRules={{ gender: { required: true } }} />
                  </td>
                </tr>
                <tr>
                  <td>{Place_of_Birth}</td>
                  <td>
                    <InPlaceEditorComponent id='place_of_birth' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.place_of_birth} model={{ placeholder: Place_of_Birth }} actionSuccess={(e) => actionSuccess("place_of_birth", e)} />
                  </td>
                </tr>
                <tr>
                  <td>{Date_of_Birth}</td>
                  <td>
                    <InPlaceEditorComponent id='date_of_birth' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' type="Date" editableOn="DblClick" value={dataRef.current.date_of_birth ? dataRef.current.date_of_birth : null} model={{ placeholder: Date_of_Birth, format: "dd-MMM-yyyy" }} actionSuccess={(e) => actionSuccess("date_of_birth", e)} />
                    {errorRef.current.date_of_birth === true ? <span style={{ color: 'red' }}>{Age_must_be_3_or_older}</span> : ''}
                  </td>
                </tr>
                <tr>
                  <td>{Religion}</td>
                  <td>
                    <InPlaceEditorComponent id='religion' emptyText={'-'} actionOnBlur="Cancel" type='DropDownList' mode='Inline' name='religion' data-underline='false' editableOn="DblClick" value={data.religion ? getReligion(data.religion, "name") : null} model={{ placeholder: Religion, dataSource: religion, fields: { text: 'text', value: 'text' } }} actionSuccess={(e) => actionSuccess("religion", e)} />
                  </td>
                </tr>
                <tr>
                  <td>{Hobby}</td>
                  <td>
                    <InPlaceEditorComponent id='hobby' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.hobby} model={{ placeholder: Hobby }} actionSuccess={(e) => actionSuccess("hobby", e)} />
                  </td>
                </tr>
                <tr>
                  <td>{Sibling_Names}</td>
                  <td>
                    <InPlaceEditorComponent id='sibling_names' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.sibling_names} model={{ placeholder: Sibling_Names }} actionSuccess={(e) => actionSuccess("sibling_names", e)} />
                  </td>
                </tr>
                <tr>
                  <td>{Related_Student}</td>
                  <td>
                    <span>{dataSibling ? dataSibling.length > 0 ? getSiblingName() : "-" : "-"}</span>
                    <span className="e-icons icon-edit-icon1"
                      style={{ color: "rgb(60, 141, 188)", paddingLeft: "10px", verticalAlign: "middle", cursor: "pointer" }}
                      onClick={() => handleOpenDialogUpdateSiblings()}
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>{Address}</td>
                  <td>
                    <InPlaceEditorComponent id='address' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.address} model={{ placeholder: Address }} actionSuccess={(e) => actionSuccess("address", e)} />
                  </td>
                </tr>
                <tr>
                  <td>{Mobile_Number}</td>
                  <td>
                    <InPlaceEditorComponent id='mobile_number' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.mobile_number} model={{ placeholder: Mobile_Number }} actionSuccess={(e) => actionSuccess("mobile_number", e)} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ paddingTop: "10px" }} >{School}</div>
                    <table id="table_desc" style={{ border: "0px solid white" }}>
                      <thead>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}>&nbsp;&nbsp;&nbsp;a. {Current_School}</td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}>&nbsp;&nbsp;&nbsp;b. {Current_School_Level}</td></tr>
                      </thead>
                    </table>
                  </td>
                  <td>
                    <div style={{ paddingTop: "10px" }} ><br /></div>
                    <table id="table_desc2" style={{ border: "0px solid white" }}>
                      <thead>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}><InPlaceEditorComponent id='current_school' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.current_school} model={{ placeholder: Current_School }} actionSuccess={(e) => actionSuccess("current_school", e)} /></td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}><InPlaceEditorComponent id='current_school_level' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.current_school_level} model={{ placeholder: Current_School_Level }} actionSuccess={(e) => actionSuccess("current_school_level", e)} /></td></tr>
                      </thead>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ paddingTop: "10px" }} >{Last_Course}</div>
                    <table id="table_desc" style={{ border: "0px solid white" }}>
                      <thead>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}>&nbsp;&nbsp;&nbsp;a. {Previous_School_Name}</td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}>&nbsp;&nbsp;&nbsp;b. {Previous_School_Level}</td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}>&nbsp;&nbsp;&nbsp;c. {Previous_School_Address}</td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}>&nbsp;&nbsp;&nbsp;d. {Previous_School_Notes}</td></tr>
                      </thead>
                    </table>
                  </td>
                  <td>
                    <div style={{ paddingTop: "10px" }} ><br /></div>
                    <table id="table_desc2" style={{ border: "0px solid white" }}>
                      <thead>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}><InPlaceEditorComponent id='previous_school_name' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.previous_school_name} model={{ placeholder: Previous_School_Name }} actionSuccess={(e) => actionSuccess("previous_school_name", e)} /></td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}><InPlaceEditorComponent id='previous_school_level' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.previous_school_level} model={{ placeholder: Previous_School_Level }} actionSuccess={(e) => actionSuccess("previous_school_level", e)} /></td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}><InPlaceEditorComponent id='previous_school_address' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.previous_school_address} model={{ placeholder: Previous_School_Address }} actionSuccess={(e) => actionSuccess("previous_school_address", e)} /></td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}><InPlaceEditorComponent id='previous_school_notes' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.previous_school_notes} model={{ placeholder: Previous_School_Notes }} actionSuccess={(e) => actionSuccess("previous_school_notes", e)} /></td></tr>
                      </thead>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ paddingTop: "10px" }} >{Start_Course}</div>
                    <table id="table_desc" style={{ border: "0px solid white" }}>
                      <thead>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}>&nbsp;&nbsp;&nbsp;a. {Start_Class}</td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}>&nbsp;&nbsp;&nbsp;b. {Start_Date}</td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}>&nbsp;&nbsp;&nbsp;c. {Where_did_you_find_the_course}</td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}>&nbsp;&nbsp;&nbsp;d. {Reason_for_registering}</td></tr>
                      </thead>
                    </table>
                  </td>
                  <td>
                    <div style={{ paddingTop: "10px" }} ><br /></div>
                    <table id="table_desc2" style={{ border: "0px solid white" }}>
                      <thead>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}><InPlaceEditorComponent id='start_class' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.start_class} model={{ placeholder: Start_Class }} actionSuccess={(e) => actionSuccess("start_class", e)} /></td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}><InPlaceEditorComponent id='start_date' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' type="Date" editableOn="DblClick" value={data.start_date ? data.start_date : null} model={{ placeholder: Start_Date, format: "dd-MMM-yyyy" }} actionSuccess={(e) => actionSuccess("start_date", e)} /></td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}><InPlaceEditorComponent id='start_connection' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.start_connection} model={{ placeholder: '' }} actionSuccess={(e) => actionSuccess("start_connection", e)} /></td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}><InPlaceEditorComponent id='start_reason' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.start_reason} model={{ placeholder: '' }} actionSuccess={(e) => actionSuccess("start_reason", e)} /></td></tr>
                      </thead>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ paddingTop: "10px" }} >{Exit_Course}</div>
                    <table id="table_desc" style={{ border: "0px solid white" }}>
                      <thead>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}>&nbsp;&nbsp;&nbsp;a. {Exit_Date}</td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}>&nbsp;&nbsp;&nbsp;b. {Exit_Reason}</td></tr>
                      </thead>
                    </table>
                  </td>
                  <td>
                    <div style={{ paddingTop: "10px" }} ><br /></div>
                    <table id="table_desc2" style={{ border: "0px solid white" }}>
                      <thead>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}><InPlaceEditorComponent id='exit_date' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' type="Date" editableOn="DblClick" value={data.exit_date ? data.exit_date : null} model={{ placeholder: Exit_Date, format: "dd-MMM-yyyy" }} actionSuccess={(e) => actionSuccess("exit_date", e)} /></td></tr>
                        <tr><td style={{ border: "0px solid white", padding: "0px", height: "35px" }}><InPlaceEditorComponent id='exit_reason' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.exit_reason} model={{ placeholder: Exit_Reason }} actionSuccess={(e) => actionSuccess("exit_reason", e)} /></td></tr>
                      </thead>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>{Accounting_Link}</td>
                  <td>
                    <InPlaceEditorComponent id='accounting_link' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.address} model={{ placeholder: Accounting_Link }} actionSuccess={(e) => actionSuccess("accounting_link", e)} />
                  </td>
                </tr>
                <tr>
                  <td>{Notes}</td>
                  <td>
                    <InPlaceEditorComponent id='notes' emptyText={'-'} mode='Inline' actionOnBlur="Cancel" data-underline='false' editableOn="DblClick" value={data.notes} model={{ placeholder: Notes }} actionSuccess={(e) => actionSuccess("notes", e)} />
                  </td>
                </tr>
              </tbody>
            </table>
            : ''}
          <br />
          <br />
          <div style={{ textAlign: "right", padding: "10px" }}>{data ?
            <div>{Created_by} : {data.owner.first_name} {data.owner.last_name}<br />
              {moment(data.created_on).format("DD-MMM-YYYY")}<br />
              {data.modified_by ? <span>{Modified_by} : {data.modified_by.first_name} {data.modified_by.last_name}<br /></span> : ''}
              {Last_Modified} : {moment(data.modified_on).format("DD-MMM-YYYY")}<br />
            </div>

            : null}
          </div>

          <DialogComponent
            id="dialogUpdateSiblings"
            width="35%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogUpdateSiblings}
            header={Related_Student}
            isModal={true}
            close={handleCloseDialogUpdateSiblings}
            footerTemplate={footerTemplateUpdateSiblings}
          >
            <div>
              {Select_Student}
              <MultiSelectComponent id="checkbox"
                ref={mulObj}
                cssClass="e-outline"
                dataSource={dataStudent}
                fields={{ text: "nickname", value: "id" }}
                mode="Box"
                showDropDownIcon={true}
                popupHeight="350px"
                filtering={onFiltering}
                allowFiltering={true}
              >
                <Inject2 services={[CheckBoxSelection]} />
              </MultiSelectComponent>
            </div>
          </DialogComponent>
        </div>
      }
    </div>
  )
}

export default Biodata;