import React, { useState, useEffect, useRef } from 'react';
import useStateRef from "react-usestateref";
import { useNavigate, useParams } from 'react-router-dom';

import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as link from 'src/constants/link';

import './index.css';

import Biodata from './biodata';
import SubjectClass from './subject_class';
import AvailableTime from './available_time';

const TeacherProfile = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();
  const navigate = useNavigate();

  const [Profile_updated_successfully] = useState(i18next.t('Pages.profile.Profile_updated_successfully'));
  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));
  const [Edit] = useState(i18next.t('Pages.button.Edit'));

  const [Please_match_the_requested_format] = useState(i18next.t('Pages.general.Please_match_the_requested_format'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  const [Change_Profile_Photo] = useState(i18next.t('Pages.data.Change_Profile_Photo'));
  const [Please_upload_the_photo] = useState(i18next.t('Pages.data.Please_upload_the_photo'));
  const [Notes] = useState(i18next.t('Pages.data.Notes'));

  const [Subject] = useState(i18next.t('Pages.schedule_data.Subject'));

  const [Address] = useState(i18next.t('Pages.teacher_data.Address'));
  const [Teacher_Profile] = useState(i18next.t('Pages.teacher_data.Teacher_Profile'));
  const [Teacher_Recap] = useState(i18next.t('Pages.teacher_data.Teacher_Recap'));
  const [Contact] = useState(i18next.t('Pages.teacher_data.Contact'));
  const [Archieve_Teacher] = useState(i18next.t('Pages.teacher_data.Archieve_Teacher'));
  const [Delete_Teacher] = useState(i18next.t('Pages.teacher_data.Delete_Teacher'));
  const [Available_Time] = useState(i18next.t('Pages.teacher_data.Available_Time'));
  const [Phone] = useState(i18next.t('Pages.teacher_data.Phone'));
  const [Teacher_deleted_successfully] = useState(i18next.t('Pages.teacher_data.Teacher_deleted_successfully'));
  const [Are_you_sure_you_want_to_delete_the_data_of_teacher] = useState(i18next.t('Pages.teacher_data.Are_you_sure_you_want_to_delete_the_data_of_teacher'));
  const [If_you_want_to_delete_the_teacher_fill_in_the_name_below] = useState(i18next.t('Pages.teacher_data.If_you_want_to_delete_the_teacher_fill_in_the_name_below'));

  //data
  const [data, setData] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogChangeProfile = useRef(false);
  let dialogDeleteTeacher = useRef(false);

  //delete teacher setting
  const [deleteWord, setDeleteWord] = useState(null);
  const [error, setError, errorRef] = useStateRef({ image: false });

  //upload image
  let my_form2 = useRef(null);
  let uploadObj = useRef(null);
  const [image, setImage] = useState(null);
  const [oldImage, setOldImage] = useState(null);
  const asyncSettings = { saveUrl: link.saveUrl, removeUrl: link.removeUrl };

  //other component setting
  const headerText = [{ text: Subject }, { text: Available_Time }, { text: "Biodata" }];

  //set state change profile
  const [state, setState] = useState({ image: null });
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true)
      getTeacher();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })

  useEffect(() => {
    const reloadData = () => {
      getTeacher();
    }

    const listener = EventEmitter.addListener('reloadTeacherData', reloadData);

    return () => {
      listener.remove();
    }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "change_profile") {
        dialogChangeProfile.current.visible = false;
        setState({ image: null });
        uploadObj.current.clearAll();
        EventEmitter.emit('alert_toast', { content: Profile_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_teacher") {
        dialogDeleteTeacher.current.visible = false;
        setDeleteWord('');
        EventEmitter.emit('alert_toast', { content: Teacher_deleted_successfully, type: "success" });
        setDataButton(null);
        return navigate('/teacher/list');
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get teacher data
  const getTeacher = async () => {
    try {
      var result = await directus.getItems('teachers',
        {
          fields: '*.*.*',
          filter: {
            id: id
          }
        }
      );

      setData(result.data[0]);
      var img_teacher = result.data[0].image ? result.data[0].image.data.full_url : link.default_image;
      setImage(img_teacher);
      setOldImage(img_teacher);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Handle  Delete Teachers ========================================== */ }

  const handleOpenDialogDeleteTeacher = () => {
    dialogDeleteTeacher.current.visible = true;
  }

  const handleCloseDialogDeleteTeacher = () => {
    dialogDeleteTeacher.current.visible = false;
    setDeleteWord('');
  }

  const handleChangeWord = (e) => {
    setDeleteWord(e.value);
  }

  //remove student
  const deleteTeacher = async () => {

    if (deleteWord === data.name) {
      setLoadingButton(true);
      var result = await directus.updateItem('teachers', id, { status: "draft" });

      const body_activity = {
        action: "Delete",
        category: 5,
        description: data.name,
        status: "published"
      }

      await directus.createItem('activity_log', body_activity);

      if (!result.error) {
        getTeacher();
        setDataButton("delete_teacher")
      }

    } else if (deleteWord !== data.name) {
      EventEmitter.emit('alert_toast', { content: Please_match_the_requested_format, type: "danger" });
      setLoadingButton(false);
    }
  }


  // {/* ================== Handle Change Profile Dialog ========================================== */ }

  const handleOpenDialogChangeProfile = () => {
    dialogChangeProfile.current.visible = true;
  }

  const handleCloseDialogChangeProfile = () => {
    dialogChangeProfile.current.visible = false;
    uploadObj.current.clearAll();
    cancelFile();
  }

  const changeProfile = async () => {
    setError({ image: false })
    if (state.image !== null) {
      try {
        setLoadingButton(true);
        var result = await directus.updateItem('teachers', id, state);

        const body_activity = {
          action: "Update",
          category: 5,
          description: data.name,
          status: "published"
        }

        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          EventEmitter.emit('alert_toast', { content: Profile_updated_successfully, type: "success" });
          dialogChangeProfile.current.visible = false;
          setState({ image: null });
          getTeacher();
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      }
    } else {
      setError({ ...errorRef.current, image: true })
    }
  }

  // {/* ================== Handle Upload File ========================================== */ }

  const uploadFile = async () => {
    try {
      const data = new FormData(my_form2.current);
      var result = await directus.uploadFiles(data, onUploadProgress);
      const imageId = result.data.id;
      setImage(result.data.data.full_url);
      setState({ ...state, image: imageId });
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }


  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + "% Done");
  }

  const cancelFile = () => {
    setState({ ...state, image: null })
    setImage(oldImage);
    setError({ image: false })
  }

  // {/* ================== Tab Component Display ========================================== */ }

  const contentAvailable = () => {
    return (<AvailableTime />)
  }

  const contentSubjectClass = () => {
    return (<SubjectClass />)
  }

  const contentBiodata = () => {
    return (<Biodata />)
  }

  // {/* ================== Footer Template ========================================== */ }
  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }

  return loading ?
    <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
    :
    data ?
      <div>
        <div className="header">
          <div className="content-header">{Teacher_Profile}</div>
        </div>
        <div className="content-main" style={{ marginTop: "30px" }}>
          <div className="col-md-3">
            <div id="teacher_profile" style={{
              color: "#333", backgroundColor: "#fff",
              boxShadow: "0 2px 2px rgb(0 0 0 / 10%)",
              padding: "10px",
              borderTop: "5px solid #3c8dbc",
              borderRadius: "3px"
            }}>
              <div id="teacher_profile_info" style={{ paddingTop: "20px" }}>
                <div id="teacher_biodata_1" style={{ textAlign: "center" }}>
                  <div id="teacher_image" >
                    <div className="container2" style={{ display: "flex", justifyContent: "center" }}>
                      <div className="image" style={{ width: "150px", height: "150px" }}>
                        <img
                          style={{
                            maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                            objectFit: "cover", border: "3px solid #d2d6de", borderRadius: "50%", position: "center",
                          }}
                          src={data.image ? data.image.data.full_url : link.default_image}
                          alt={data.name}
                        />
                      </div>
                      <div className="edit-middle">
                        <ButtonComponent
                          iconCss="e-icons icon-edit-icon1"
                          onClick={() => handleOpenDialogChangeProfile()}
                          style={{ fontSize: "12px" }}
                        >{Edit}</ButtonComponent>
                      </div>
                    </div>
                    <h4>{data.name}{data.degree ? ", " + data.degree : null}</h4>
                    <p>{data.nickname ? data.nickname : '-'}</p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div id="teacher_recap" style={{
              color: "#333", backgroundColor: "#fff",
              boxShadow: "0 2px 2px rgb(0 0 0 / 10%)",
              padding: "10px",
              borderTop: "5px solid #3c8dbc",
              borderRadius: "3px"
            }}>
              <h4>{Teacher_Recap}</h4>
              <div id="teacher_contact_table" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <div>
                  <span className="e-icons icon-MT_Resource" />
                  <span style={{ fontSize: "15px", paddingLeft: "5px" }}><b>{Contact}</b></span>
                  <br />
                  {data.mobile_number ? <div>{Phone} : {data.mobile_number} <br /> </div> : ''}
                  {data.email ? <div>Email : {data.email} <br /> </div> : ''}
                </div>
                <hr />
                <div>
                  <span className="e-icons icon-MT_Location" />
                  <span style={{ fontSize: "15px", paddingLeft: "5px" }}><b>{Address}</b></span>
                  <br />
                  {data.address ? <div>{data.address} <br /> </div> : ''}
                </div>
                <hr />
                <div style={{ fontSize: "15px" }}>
                  <span className="e-icons icon-MT_Description" />
                  <span style={{ fontSize: "15px", paddingLeft: "5px" }}><b>{Notes}</b></span>
                  <br />
                  {data.notes ? <div>{data.notes} <br /> </div> : ''}
                </div>
                <hr />
                <div style={{ padding: "5px" }}>
                  <ButtonComponent id="dialogBtn" style={{ width: "100%", backgroundColor: "#f39c12", borderColor: "#e08e0b", color: "white" }} fullWidth iconCss='e-icons icon-MT_Save'
                  // onClick={() => handleRemoveTeacher()}
                  >
                    {Archieve_Teacher}
                  </ButtonComponent>
                </div>
                <div style={{ padding: "5px" }}>
                  <ButtonComponent id="dialogBtn" style={{ width: "100%", backgroundColor: "#dd4b39", borderColor: "#d73925", color: "white" }} iconCss='e-icons icon-delete-05'
                    onClick={() => handleOpenDialogDeleteTeacher()}
                  >
                    {Delete_Teacher}
                  </ButtonComponent>

                  <br />
                </div>
              </div>
            </div>

            <br />

            <DialogComponent
              id="dialogChangeProfile"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogChangeProfile}
              header={Change_Profile_Photo}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Update, func: changeProfile, icon: "icon-check-mark-01", status: "e-success" },
                { name: Cancel, func: handleCloseDialogChangeProfile, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogChangeProfile}
            >
              <div>
                <div style={{ textAlign: 'center' }}>
                  <div className="image" style={{ width: "150px", height: "150px" }}>
                    <img
                      style={{
                        maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                        objectFit: "cover", position: "center",
                      }}
                      src={image}
                      alt="change_profile"
                    />
                  </div>
                </div>
                <br />
                <form ref={my_form2}>
                  <UploaderComponent
                    id='previewfileupload'
                    type='file'
                    ref={uploadObj}
                    asyncSettings={asyncSettings}
                    success={uploadFile}
                    multiple={false}
                    autoUpload={false}
                    clearing={cancelFile}
                  >
                  </UploaderComponent>
                  {error.image === true ? <span style={{ color: 'red' }}>{Please_upload_the_photo}</span> : ''}
                </form>
              </div>
            </DialogComponent>


          </div>

          <div className="col-md-9" style={{ paddingLeft: "30px" }}>
            <div className='control-section' style={{ minHeight: "600px", display: "block", boxShadow: "rgb(0 0 0 / 10%) 0px 2px 2px" }}>
              {data ?
                <TabComponent className="e-fill" heightAdjustMode='None' enablePersistence={true}>
                  <TabItemsDirective>
                    <TabItemDirective header={headerText[0]} content={contentSubjectClass} />
                    <TabItemDirective header={headerText[1]} content={contentAvailable} />
                    <TabItemDirective header={headerText[2]} content={contentBiodata} />
                  </TabItemsDirective>
                </TabComponent>
                : <div></div>}
            </div>
          </div>
        </div>

        <DialogComponent
          id="dialogDeleteTeacher"
          width='35%'
          showCloseIcon={!loadingButton ? true : false}
          visible={false}
          ref={dialogDeleteTeacher}
          header={Delete_Confirmation}
          isModal={true}
          footerTemplate={() => footerTemplate([
            { name: Delete, func: deleteTeacher, icon: "icon-delete-05", status: "e-warning" },
            { name: Cancel, func: handleCloseDialogDeleteTeacher, icon: "icon-treeview", status: "" }
          ])}
          close={handleCloseDialogDeleteTeacher}
        >
          <div id="contentDialogTeacher" style={{ fontSize: "14px" }}>
            {Are_you_sure_you_want_to_delete_the_data_of_teacher} ({data.name ? data.name : ''})?
            <br /><br />
            <p style={{ color: "red" }}>
              {If_you_want_to_delete_the_teacher_fill_in_the_name_below}
            </p>
            <TextBoxComponent
              cssClass="e-outline"
              name="delete_teacher"
              change={(e) => handleChangeWord(e)}
              value={deleteWord}
            >
            </TextBoxComponent>
          </div>

        </DialogComponent>
      </div >
      : ''
}

export default TeacherProfile;