import * as enTranslations from "../Translation/en";
import * as idTranslations from "../Translation/id";
import {initReactI18next} from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";

export function i18n(lang) {
	const resources = {
		en: {messages: enTranslations},
		id: {messages: idTranslations}
	};

	const i18n = i18next.use(LanguageDetector).use(initReactI18next);
	i18n.init({
		react: {
			wait: true,
		},
		resources: resources,
		lng: lang,
		fallbackLng: lang,
		keySeparator: '.',
		interpolation: {
				escapeValue: false,
		},
		ns: ['messages'],
		defaultNS: 'messages',
		fallbackNS: [],
	});
}