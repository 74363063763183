import React, { useState, useEffect, useRef } from 'react';
import useStateRef from "react-usestateref";
import { Link } from 'react-router-dom';

import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";

import EventEmitter from 'src/utils/EventEmitter';
import directus from 'src/services/directus';

let cancelFlag = false;
let editFlag = false;
const Subject = () => {
  /* eslint-disable no-unused-vars*/
  var searchTimeout = null;

  var numOfPage = JSON.parse(window.localStorage.getItem('number_of_page'));
  var numSubject = numOfPage ? numOfPage.subject ? numOfPage.subject : 15 : 15;
  if (!numOfPage) {
    window.localStorage.setItem('number_of_page', JSON.stringify({ subject: 15 }));
  } else {
    if (!numOfPage.subject) {
      numOfPage.subject = 15;
      window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
    }
  }

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));
  const [Preview] = useState(i18next.t('Pages.button.Preview'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));
  const [Items_per_page] = useState(i18next.t('Pages.general.Items_per_page'));
  const [of] = useState(i18next.t('Pages.general.of'));
  const [pages] = useState(i18next.t('Pages.general.pages'));

  const [Name] = useState(i18next.t('Pages.data.Name'));
  const [Sort_2] = useState(i18next.t('Pages.data.Sort'));
  // const [Action] = useState(i18next.t('Pages.data.Action'));
  const [Select_Group] = useState(i18next.t('Pages.data.Select_Group'));
  const [Please_select_group] = useState(i18next.t('Pages.data.Please_select_group'));
  // const [Select_Level] = useState(i18next.t('Pages.data.Select_Level'));

  const [Subject] = useState(i18next.t('Pages.subject_class_data.Subject'));
  const [Group] = useState(i18next.t('Pages.subject_class_data.Group'));
  const [Code] = useState(i18next.t('Pages.subject_class_data.Code'));
  const [Short_Name] = useState(i18next.t('Pages.subject_class_data.Short_Name'));
  const [Enter_subject_name] = useState(i18next.t('Pages.subject_class_data.Enter_subject_name'));
  const [Meeting_Duration] = useState(i18next.t('Pages.subject_class_data.Meeting_Duration'));
  const [Registration_Fee] = useState(i18next.t('Pages.subject_class_data.Registration_Fee'));
  const [Monthly_Fee] = useState(i18next.t('Pages.subject_class_data.Monthly_Fee'));
  const [Lesson_Fee] = useState(i18next.t('Pages.subject_class_data.Lesson_Fee'));
  const [Subject_Group] = useState(i18next.t('Pages.subject_class_data.Subject_Group'));
  const [Are_you_sure_you_want_to_delete_the_data_of_subject] = useState(i18next.t('Pages.subject_class_data.Are_you_sure_you_want_to_delete_the_data_of_subject'));
  const [Subject_created_successfully] = useState(i18next.t('Pages.subject_class_data.Subject_created_successfully'));
  const [Subject_deleted_successfully] = useState(i18next.t('Pages.subject_class_data.Subject_deleted_successfully'));

  //data
  const [data, setData] = useState(null);
  const [dataSubjectGroup, setDataSubjectGroup] = useState(null);
  const [dataMeta, setDataMeta] = useState(null);
  let [numberOfPage, setNumberOfPage, numberOfPageRef] = useStateRef(numSubject);
  const [selectedData, setSelectedData] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  let dropdownRef = useRef();
  let groupObj = useRef();
  let groupElem = useRef();

  //dialog
  let dialogAddSubject = useRef(false);
  let dialogDeleteSubject = useRef(false);

  //grid component setting
  const grid = useRef();
  const editSettings = {
    allowEditing: true, allowAdding: true, allowDeleting: true,
    mode: 'Normal'
  };
  const filterOptions = { type: 'Menu' };
  const setting = { mode: 'Both' };

  //set state add subject
  const [state, setState, stateRef] = useStateRef({
    // level: null,
    group: null,
    sort: null,
    name: null,
    short_name: null,
    code: null,
    meeting_duration: 60,
    registration_fee: 0,
    monthly_fee: 0,
    lesson_fee: 0,
    status: "published"
  });

  //set state group
  const [stateGroup, setStateGroup, stateGroupRef] = useStateRef({
    group: null,
    // level: null
  });

  //set state filtering
  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    name: null,
    page: 1
  });

  //set error
  const [error, setError, errorRef] = useStateRef({
    name: false,
    group: false
  });

  const [selectedRow, setSelectedRow, selectedRowRef] = useStateRef(null);
  /* eslint-enable no-unused-vars*/

  const getInitialValue = () => {
    setState({
      // level: null,
      group: null,
      sort: null,
      name: null,
      short_name: null,
      code: null,
      meeting_duration: 60,
      registration_fee: 0,
      monthly_fee: 0,
      lesson_fee: 0,
      status: "published"
    });
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "add_subject") {
        dialogAddSubject.current.visible = false;
        // setState({ level: null, group: null, sort: null, name: null, short_name: null, code: null, meeting_duration: 60, registration_fee: 0, monthly_fee: 0, lesson_fee: 0, status: "published" });
        setState({ group: null, sort: null, name: null, short_name: null, code: null, meeting_duration: 60, registration_fee: 0, monthly_fee: 0, lesson_fee: 0, status: "published" });
        EventEmitter.emit('alert_toast', { content: Subject_created_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_subject") {
        dialogDeleteSubject.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Subject_deleted_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get all subject data
  const getData = async () => {
    try {
      var name = { "nnull": null };

      if (stateFiltering.name !== null && stateFiltering.name !== "") {
        name = { "contains": stateFilteringRef.current.name };
      }

      var result = await directus.getItems('subjects',
        {
          fields: "*.*",
          filter: {
            name: name,
            status: "published"
          },
          // sort: "name",
          sort: "group,sort,name",
          limit: numberOfPageRef.current,
          meta: "*",
          page: stateFilteringRef.current.page ? stateFilteringRef.current.page : 1
        });

      setData(result.data);
      setDataMeta(result.meta);
      getSubjectGroup();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get all subject group
  const getSubjectGroup = async () => {
    try {
      var result = await directus.getItems('subject_group',
        {
          fields: "*.*",
          filter: {
            status: "published"
          },
          sort: "name"
        });

      setDataSubjectGroup(result.data);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Data Grid Template ========================================== */ }
  const buttonTemplate = props => {
    
    var editData = false;
    if (editFlag && selectedRowRef.current !== null) {
      if (selectedRowRef.current === props.index) {
        editData = true;
        return (
          <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
            <div className="action_edit" style={{ display: "flex" }}>
              <TooltipComponent content={Update} position="BottomCenter" >
                <span className="e-icons icon-MT_Save"
                  style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
                  onClick={() => saveGridData()}
                />
              </TooltipComponent>

              <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
                <span className="e-icons icon-circle-close-2"
                  style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
                  onClick={() => grid.current.editModule.closeEdit()}
                />
              </TooltipComponent>
            </div>
          </div>

        )
      }
    }

    if (!editData) {
      return (
        <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
          <div className="action_hover" style={{ display: "flex" }}>
            <TooltipComponent content={Preview} position="BottomCenter" >
              <span>
                <Link to={{ pathname: `/subject/view/${props.id}` }} style={{ textDecoration: "none" }}>
                  <span className="e-icons icon-MT_Preview"
                    style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
                  />
                </Link>
              </span>
            </TooltipComponent>

            <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px" }} >
              <span className="e-icons icon-delete-05"
                style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => handleOpenDialogDeleteSubject(props)}
              />
            </TooltipComponent>
          </div>
        </div>
      )
    }
  }

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const buttonEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter" >
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const groupParams = {
    create: () => {
      groupElem = document.createElement("input");
      return groupElem;
    },
    destroy: () => {
      // groupObj.destroy();
    },
    read: (args) => {
      return args.value;
    },
    write: (args) => {
      groupObj = new DropDownList({
        dataSource: dataSubjectGroup,
        fields: { text: 'name', value: 'id' },
        text: args.rowData[args.column.field],
        floatLabelType: "Never",
        change: (e) => {
          setSelectedRow(args.row.rowIndex);
          setStateGroup({ ...stateGroupRef.current, group: e.itemData.id })
        },
        value: args.rowData.group ? args.rowData.group.id : null
      });
      groupObj.appendTo(groupElem);
    }
  }

  const actionGrid = async (event) => {
    if (event.action === "edit" && event.requestType === "save") {
      if (cancelFlag === false) {
        try {
          const data = event.data;
          const body = {
            // level: stateGroup.level ? stateGroup.level : data.level,
            group: stateGroupRef.current.group ? stateGroupRef.current.group : data.group.id,
            sort: data.sort,
            name: data.name,
            short_name: data.short_name,
            code: data.code,
            registration_fee: data.registration_fee ? data.registration_fee : 0,
            lesson_fee: data.lesson_fee ? data.lesson_fee : 0,
            monthly_fee: data.monthly_fee ? data.monthly_fee : 0
          }

          var result = await directus.updateItem('subjects', data.id, body);

          var description = data.name;
          if (data.name !== event.previousData.name) {
            description = event.previousData.name + " --> " + data.name;
          }

          const body_activity = {
            action: "Update",
            category: 7,
            description: description,
            status: "published"
          }
          await directus.createItem('activity_log', body_activity);

          if (!result.error) {
            getData();
            setSelectedRow(null);
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        }
      } else {
        grid.current.editModule.closeEdit()
      }
      cancelFlag = false;

    } else if (event.requestType === "beginEdit") {
      cancelFlag = true;
      editFlag = true;
    }
  }

  // {/* ================== Filtering ========================================== */ }

  const handleChangeFilter = (event, type) => {
    var value = "";
    if (type === "name") {
      value = event.value
    }

    setStateFiltering({ ...stateFiltering, [type]: value })

    if (event.syntheticEvent.type === 'change') {
      getData();
    }

  }

  const searchSubject = () => {
    getData();
  }

  // {/* ================== Handle Add Subject ========================================== */ }

  const addSubject = async () => {
    setError({ name: false, group: false });

    if (state.name && state.group) {
      try {
        setLoadingButton(true);
        var result = await directus.createItem('subjects', state);

        const body_activity = {
          action: "Add",
          category: 7,
          description: state.name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          getData();
          setDataButton("add_subject")
        }
      } catch (error) {
        setLoadingButton(false);
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      }

    } else {
      if (!state.name) { setError({ ...errorRef.current, name: true }) }
      if (!state.group) { setError({ ...errorRef.current, group: true }) }
    }

    return function cleanup() {
      setInitialized(true);
    }
  }

  const handleCloseDialogAddSubject = () => {
    setError({ name: false, group: false });
    dialogAddSubject.current.visible = false;
  }

  const handleOpenDialogAddSubject = () => {
    dialogAddSubject.current.visible = true;
    getInitialValue();
  }

  const handleChange = (event, type) => {
    var value = null;
    if (type === "group") {
      value = event.itemData.id;
      setState({ ...stateRef.current, name: event.itemData.name })
      // } else if (type === "level") {
      //   value = event.itemData.value;
    } else {
      value = event.value;
    }

    setState({ ...stateRef.current, [type]: value });
  }

  // {/* ================== Handle Dialog Remove Activity Type ========================================== */ }

  const handleOpenDialogDeleteSubject = (props) => {
    dialogDeleteSubject.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteSubject = () => {
    dialogDeleteSubject.current.visible = false;
  }

  const deleteSubject = async () => {
    try {
      const body = {
        status: 'draft'
      }
      setLoadingButton(true);
      var result = await directus.updateItem('subjects', selectedData.id, body);

      const body_activity = {
        action: "Delete",
        category: 7,
        description: selectedData.name,
        status: "published"
      }
      await directus.createItem('activity_log', body_activity);

      if (!result.error) {
        getData();
        setDataButton("delete_subject");
      }
    } catch (error) {
      setLoadingButton(false);
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }

    return function cleanup() {
      setInitialized(true);
    }
  }

  const changeNumOfData = (e) => {
    var value = e.value ? e.value : 1;
    setNumberOfPage(value);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      numOfPage.subject = value;
      window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
      getData();
    }, 1000)
  }

  // {/* ================== Pagination ========================================== */ }

  const pageTemplate = () => {
    var list = [];
    if (dataMeta) {
      for (var i = 0; i < dataMeta.page_count; i++) {
        const page = i + 1;
        var class_name = "e-link e-numericitem e-spacing e-pager-default";
        if (dataMeta.page === page) {
          class_name = "e-link e-numericitem e-spacing e-currentitem e-active"
        }
        var display = "none";
        if (page <= 3) { display = ""; }
        list.push(<div key={"page-" + page}><span className={class_name} id={"page-" + page} role="link" tabIndex="-1" aria-label={'Goto Page ' + page} onClick={() => clickPage(page, "num")} name={'Goto Page ' + page} aria-owns="grid_940258773_0_content_table" style={{ display: display }} >{page}</span></div>)
      }
    }

    //check page 
    var check_page = [];
    if (dataMeta.page_count <= 3) {
      for (var j = 0; j < dataMeta.page_count; j++) { check_page.push(j + 1) }
    } else {
      if (dataMeta.page <= 2) {
        for (var k = 0; k < 3; k++) { check_page.push(k + 1) }
      } else if (dataMeta.page >= dataMeta.page_count) {
        for (var l = dataMeta.page_count; l > dataMeta.page_count - 3; l--) { check_page.push(l) }
      } else {
        var list_page = [dataMeta.page - 1, dataMeta.page, dataMeta.page + 1];
        list_page.forEach((value) => {
          check_page.push(value)
        })
      }
    }

    var select_page2 = document.querySelectorAll('.e-numericitem');
    if (select_page2.length > 0) {
      if (select_page2.length > 0) {
        for (var index = 0; index < select_page2.length; index++) {
          select_page2[index].style.display = "none";
        }
      }

      if (check_page.length > 0) {
        //eslint-disable-next-line array-callback-return
        check_page.map((value) => {
          var select_page = document.querySelectorAll(`[id=page-${value}]`);
          if (select_page.length > 0) {
            for (var index2 = 0; index2 < select_page.length; index2++) {
              select_page[index2].style.display = "";
            }
          }
        })
      }
    }

    return (
      <div style={{ height: "35px", textAlign: "center" }}>
        <div className="e-gridpager e-control e-pager e-lib" style={{ display: "flex", width: "100%" }} >
          <div style={{ width: "32.5%", background: "#E1E7EB", borderRadius: "17.5px 5px 5px 17.5px" }}>
            <div className="e-pagercontainer" role="navigation" >
              <div className={dataMeta.page === 1 ? "e-first e-icons e-icon-first e-firstpagedisabled e-disable" : "e-first e-icons e-icon-first e-firstpage e-pager-default"} onClick={() => clickPage(1, "first")} title="Go to first page" aria-label="Go to first page" tabIndex="-1" index="1" aria-owns="grid_940258773_0_content_table"></div>
              <div className={dataMeta.page === 1 ? "e-prev e-icons e-icon-prev e-prevpagedisabled e-disable" : "e-prev e-icons e-icon-prev e-prevpage e-pager-default"} onClick={() => clickPage(dataMeta.page - 1, "prev")} title="Go to previous page" aria-label="Go to previous page" tabIndex="-1" index="0" aria-owns="grid_940258773_0_content_table"></div>
              <div className="e-numericcontainer">{list}</div>
              <div className={dataMeta.page === dataMeta.page_count ? "e-next e-icons e-icon-next e-nextpagedisabled e-disable" : "e-next e-icons e-icon-next e-nextpage e-pager-default"} onClick={() => clickPage(dataMeta.page + 1, "next")} title="Go to next page" aria-label="Go to next page" tabIndex="-1" index="2" aria-owns="grid_940258773_0_content_table"></div>
              <div className={dataMeta.page === dataMeta.page_count ? "e-last e-icons e-icon-last e-lastpagedisabled e-disable" : "e-last e-icons e-icon-last e-lastpage e-pager-default"} onClick={() => clickPage(dataMeta.page_count, "last")} title="Go to last page" aria-label="Go to last page" tabIndex="-1" index="2" aria-owns="grid_940258773_0_content_table"></div>
            </div>
          </div>
          <div style={{ width: "35%", background: "#E1E7EB", marginLeft: "2px", marginRight: "2px", borderRadius: "5px" }}>
            <div className="e-pagerconstant">
              <span className="e-constant" style={{ display: "flex" }}>
                <span style={{ marginRight: "6px" }}>
                  <span style={{ display: "flex", flexDirection: "column", position: "absolute", marginTop: "7px" }}>
                    <span className="e-icons icon-arrowhead-up"
                      style={{ cursor: "pointer", color: '#516573', fontSize: "10px" }}
                      onClick={() => handleNumberOfPage("+")}
                    />
                    <span className="e-icons icon-arrowhead-down"
                      style={{ cursor: "pointer", color: '#516573', fontSize: "10px" }}
                      onClick={() => handleNumberOfPage("-")}
                    />
                  </span>
                </span>
                <span className="nt_custom" style={{ fontSize: "17px", fontWeight: "800", color: "#516573", width: "35px", marginRight: "6px", marginLeft: "6px" }}>
                  <NumericTextBoxComponent style={{ textAlign: "center", padding: "0px" }} step={5} format='####' value={numberOfPageRef.current} showSpinButton={false} max={999} min={1} onChange={(e) => changeNumOfData(e)} />
                </span>
                <span style={{ color: "#516573" }}>
                  {Items_per_page} (total {dataMeta ? dataMeta.filter_count : 0} records)
                </span>
              </span>
            </div>
          </div>
          <div className="e-parentmsgbar" style={{ width: "32.5%", background: "#E1E7EB", borderRadius: "5px 17.5px 17.5px 5px" }}>
            <span className="e-pagenomsg">{dataMeta ? <span>{dataMeta.page} {of} {dataMeta.page_count} {pages} </span> : null}</span>
          </div>
        </div>
      </div>
    )
  }

  const handleNumberOfPage = (props) => {
    if (numberOfPageRef.current - 1 > 0) {
      var value = numberOfPageRef.current;
      if (props === "+") {
        value = numberOfPageRef.current + 5;
      } else {
        if (numberOfPageRef.current > 5) {
          value = numberOfPageRef.current - 5;
        } else {
          value = 1;
        }
      }
      setNumberOfPage(value);
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        numOfPage.subject = value;
        window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
        getData();
      }, 1000)
    }
  }

  const clickPage = (page, type) => {
    var status = false;
    if (type === "num") {
      if (dataMeta.page !== page) { status = true; }
    } else if (type === "first" || type === "prev") {
      if (dataMeta.page !== 1) { status = true; }
    } else if (type === "next" || type === "last") {
      if (dataMeta.page !== dataMeta.page_count) { status = true; }
    }

    if (status) {
      setStateFiltering({
        ...stateFilteringRef.current,
        page: page
      })
      getData();
    }
  }

  const onColumnMenuOpen = (args) => {
    for (const item of args.items) {
      if (item.text === 'Autofit all columns' || item.text === 'Autofit this column') {
        item.hide = true;
      }
    }
  }

  const footerTemplateAddSubject = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-success e-flat" data-ripple="true" onClick={(e) => addSubject(e)}>
            <span className="e-btn-icon e-icons icon-check-mark-01 e-icon-left"></span>{Update}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogAddSubject()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  const footerTemplateDeleteSubject = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-warning e-flat" data-ripple="true" onClick={(e) => deleteSubject(e)}>
            <span className="e-btn-icon e-icons icon-delete-05 e-icon-left"></span>{Delete}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogDeleteSubject()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      cancelFlag = false;
      grid.current.endEdit();
    }
  }

  // {/* ================== Main Content ========================================== */}
  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Subject}</div>
            <div className="content-searching hide-content">
              <div style={{ display: 'flex', width: "100%" }}>
                <div style={{ width: "250px", marginRight: "10px" }}>
                  <TextBoxComponent
                    cssClass="e-outline searching-text"
                    className="searching-input"
                    id="searching-input"
                    placeholder={Enter_subject_name}
                    onChange={(e) => handleChangeFilter(e, "name")}
                    onBlur={(e) => searchSubject(e)}
                    // close={()=>alert("clear")}
                    showClearButton="true"
                    value={stateFiltering.name}
                  />
                </div>
                <div>
                  <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddSubject}>
                    <span className="btn-text-custom">{Subject}</span>
                  </ButtonComponent>
                </div>
                {/* <br />
              {dataMeta ? 'Total : ' + dataMeta.filter_count : ''}
              {dataMeta ? dataMeta.filter_count > 15 ? pageTemplate() : null : null} */}
              </div>
            </div>
          </div>

          <div className="content-main">
            {data ? data.length > 0 ?
              <div style={{ marginTop: "25px", marginBottom: "15px", position: "relative" }}>
                {/* // <div className="control-menu" style={{ marginTop: "25px", paddingBottom: "15px", marginRight: "-5px", marginLeft: "30px", width:"100%" }}> */}
                <div className="grid-container">
                  <GridComponent
                    className="grid-list"
                    dataSource={data ? data.length > 0 ? data : null : null}
                    editSettings={editSettings}
                    allowSorting={true}
                    filterSettings={filterOptions}
                    allowFiltering={true}
                    ref={grid}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: "Content" }}
                    allowResizing={true}
                    gridLines='Both'
                    showColumnMenu={true}
                    columnMenuOpen={onColumnMenuOpen}
                    actionBegin={actionGrid}
                    selectionSettings={setting}
                    // allowReordering={true}
                    clipMode="EllipsisWithTooltip"
                    keyPressed={onKeyPressed}
                  >
                    <ColumnsDirective>
                      {/* <ColumnDirective customAttributes={{ id: 'first' }} width="116px" field='level' allowFiltering={false} allowSorting={false} showColumnMenu={false} headerText='Level' editTemplate={levelEditTemplate}></ColumnDirective> */}
                      {/* <ColumnDirective customAttributes={{ id: 'first' }} idth="150px" field='group.name' allowFiltering={false} headerText={Group} editTemplate={groupEditTemplate}></ColumnDirective> */}
                      <ColumnDirective customAttributes={{ id: 'first' }} width="150px" field='group.name' allowFiltering={false} headerText={Group} editType="dropdownedit" edit={groupParams}></ColumnDirective>
                      <ColumnDirective width="100px" field='sort' headerText={Sort_2}></ColumnDirective>
                      <ColumnDirective width="148px" field='name' headerText={Name}></ColumnDirective>
                      <ColumnDirective width="148px" field='short_name' headerText={Short_Name}></ColumnDirective>
                      <ColumnDirective width="146px" field='registration_fee' headerText={Registration_Fee}></ColumnDirective>
                      <ColumnDirective width="146px" field='lesson_fee' headerText={Lesson_Fee}></ColumnDirective>
                      <ColumnDirective width="146px" field='monthly_fee' headerText={Monthly_Fee}></ColumnDirective>
                      <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="90px" template={buttonTemplate} editTemplate={buttonEditTemplate}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                  </GridComponent>
                </div>
              </div>
              : <div style={{ textAlign: "center", paddingTop: "10px" }}>{No_data_found}</div>
              : <div style={{ textAlign: "center", paddingTop: "10px" }}>{No_data_found}</div>}


            <DialogComponent
              id="dialogAddSubject"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogAddSubject}
              header={Subject}
              isModal={true}
              close={handleCloseDialogAddSubject}
              footerTemplate={footerTemplateAddSubject}
            >
              <div>
                <div>
                  {/* <b>Level</b>
                  <DropDownListComponent
                    cssClass='e-outline'
                    placeholder={`--${Select_Level}--`}
                    dataSource={["TK", "SD", "SMP", "SMA"]}
                    name="level"
                    value={state.level}
                    select={(e) => handleChange(e, "level")}
                  ></DropDownListComponent>
                  <br /><br /> */}
                  <b>*{Subject_Group}</b>
                  <DropDownListComponent
                    cssClass='e-outline'
                    placeholder={`--${Select_Group}--`}
                    dataSource={dataSubjectGroup}
                    fields={{ text: 'name', value: 'id' }}
                    name="group"
                    value={state.group}
                    select={(e) => handleChange(e, "group")}
                  ></DropDownListComponent>
                  {error.group === true ? <span style={{ color: 'red' }}>{Please_select_group}</span> : ''}
                  <br /><br />
                  <b>{Sort_2}</b>
                  <NumericTextBoxComponent
                    cssClass="e-outline"
                    name="sort"
                    format='#########'
                    change={(e) => handleChange(e, "sort")}
                    value={state.sort}
                  />
                  <br /><br />
                  <b>*{Name}</b>
                  <TextBoxComponent
                    name="name"
                    cssClass='e-outline'
                    change={(e) => handleChange(e, "name")}
                    value={state.name}
                  />
                  {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <b>{Short_Name}</b>
                  <TextBoxComponent
                    name="short_name"
                    cssClass='e-outline'
                    change={(e) => handleChange(e, "short_name")}
                    value={state.short_name}
                  />
                  <br /><br />
                  <b>{Code}</b>
                  <TextBoxComponent
                    name="short_name"
                    cssClass='e-outline'
                    change={(e) => handleChange(e, "code")}
                    value={state.code}
                  />
                  <br /><br />
                  <b>{Meeting_Duration}</b>
                  <NumericTextBoxComponent
                    cssClass="e-outline"
                    name="meeting_duration"
                    format='#########'
                    min={0}
                    change={(e) => handleChange(e, "meeting_duration")}
                    value={state.meeting_duration}
                  />
                  <br /><br />
                  <b>{Registration_Fee}</b>
                  <NumericTextBoxComponent
                    cssClass="e-outline"
                    name="registration_fee"
                    placeholder='IDR 0'
                    format='IDR #########'
                    min={0}
                    change={(e) => handleChange(e, "registration_fee")}
                    value={state.registration_fee}
                  />
                  <br /><br />
                  <b>{Monthly_Fee}</b>
                  <NumericTextBoxComponent
                    cssClass="e-outline"
                    name="monthly_fee"
                    placeholder='IDR 0'
                    format='IDR #########'
                    min={0}
                    change={(e) => handleChange(e, "monthly_fee")}
                    value={state.monthly_fee}
                  />
                  <br /><br />
                  <b>{Lesson_Fee}</b>
                  <NumericTextBoxComponent
                    cssClass="e-outline"
                    name="lesson_fee"
                    placeholder='IDR 0'
                    format='IDR #########'
                    min={0}
                    change={(e) => handleChange(e, "lesson_fee")}
                    value={state.lesson_fee}
                  />
                </div>
              </div>
            </DialogComponent>


            <DialogComponent
              id="dialogDeleteSubject"
              width="35%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogDeleteSubject}
              header={Delete_Confirmation}
              isModal={true}
              close={handleCloseDialogDeleteSubject}
              footerTemplate={footerTemplateDeleteSubject}
            >
              <div>
                {selectedData ?
                  <div style={{ fontSize: "14px" }}>
                    {Are_you_sure_you_want_to_delete_the_data_of_subject} ({selectedData.name})?
                    <br /><br />

                  </div>
                  : ''}
              </div>
            </DialogComponent>
          </div>
          <div className="content-pagination">
            <div className="content-pagination-inside">
              {dataMeta ? dataMeta.filter_count > 15 ? pageTemplate() : null : null}
            </div>
          </div>
        </div>

      }
    </div >
  );
}

export default Subject;