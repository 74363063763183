import React, { useState, useRef } from 'react';
import { Link, BrowserRouter } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as permission from 'src/utils/Permission';
import './style.css';

import * as subject_class from 'src/CRUD/subject_classes';

// {/* ================== Subject Class Card Display ========================================== */ }

const CardRender = (props) => {
	const [classID, setClassID] = useState(null);

	const [Delete] = useState(i18next.t('Pages.button.Delete'));
	const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

	const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
	const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

	const [Sunday] = useState(i18next.t('Pages.data.Sunday'));
	const [Monday] = useState(i18next.t('Pages.data.Monday'));
	const [Tuesday] = useState(i18next.t('Pages.data.Tuesday'));
	const [Wednesday] = useState(i18next.t('Pages.data.Wednesday'));
	const [Thursday] = useState(i18next.t('Pages.data.Thursday'));
	const [Friday] = useState(i18next.t('Pages.data.Friday'));
	const [Saturday] = useState(i18next.t('Pages.data.Saturday'));

	const [to] = useState(i18next.t('Pages.subject_class_data.to'));
	const [Are_you_sure_you_want_to_delete_the_data_of_class] = useState(i18next.t('Pages.subject_class_data.Are_you_sure_you_want_to_delete_the_data_of_class'));
	const [Class_deleted_successfully] = useState(i18next.t('Pages.subject_class_data.Class_deleted_successfully'));

	//loading
	const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);

	//dialog
	let dialogDeleteClass = useRef(false);

	//other component setting
	const weeks = [{ id: "Sunday", name: Sunday }, { id: "Monday", name: Monday }, { id: "Tuesday", name: Tuesday }, { id: "Wednesday", name: Wednesday }, { id: "Thursday", name: Thursday }, { id: "Friday", name: Friday }, { id: "Saturday", name: Saturday }];

	// {/* ================== Card Data ========================================== */ }
	//display all meetings data
	function getMeetings(props) {
		const list = [];

		props.forEach((value, index) => {
			var data = value.meetings_id;
			var time = moment(data.time, "hh:mm:ss").format("HH:mm");
			list.push(<div key={index}>{getWeek(data.weekday)} @ {time} - {data.teacher.nickname ? data.teacher.nickname : data.teacher.name} ({data.room ? data.room.name : ''}) </div>);
		})

		return (<div style={{ fontSise: "12px", fontWeight: "700", color: "#5F99E9" }}>{list}</div>)
	}

	//display all students data with specific subject class
	function getStudents(props, nav) {
		const list = [];

		props.forEach((value, index) => {
			var data = value.students_id;
			list.push(<div key={"students_" + index} style={{ flex: "1", flexBasis: "50%" }}>
				<BrowserRouter>
					<Link to={{ pathname: `/student/profile/${value.students_id.id}` }} style={{ textDecoration: "none" }}
						onClick={(e) => btnClickStudent(e, value.students_id, nav)}
					>
						<span style={{ height: "20px", lineHeight: "20px" }}>
							{index + 1}. <span style={{ paddingLeft: "5px", cursor: "pointer", textTransform: "uppercase", color: "#516573" }} >{data.nickname}</span>
						</span>
					</Link>
				</BrowserRouter>


			</div>);
		})
		return (<div style={{ display: "flex", flexWrap: "wrap", margin: "5px 0 5px 0" }}>{list}</div>)
	}

	//display start date and end date
	function getDate(props) {
		const start_date = moment(props.start_date).format("DD MMM YYYY");
		const end_date = moment(props.end_date).format("DD MMM YYYY");

		return (
			<div style={{ lineHeight: "30px" }}>{start_date} {to} {end_date}</div>
		)
	}

	//get week translation
	const getWeek = (props) => {
		var week_2 = props ? props : null;
		if (weeks) {
			weeks.forEach((value) => {
				if (value.id === week_2) {
					week_2 = value.name
				}
			})
		}
		return "" + week_2
	}

	// {/* ================== Navigation ========================================== */ }

	//navigate to the subject class detail with specific id
	function btnClickSubjectClass(e, props) {
		e.preventDefault();
		const id = props.data.id;
		return props.navigate('/subject_class/detail/' + id);
	}

	//navigate to the student profile with specific id
	function btnClickStudent(e, props, nav) {
		e.preventDefault();
		const id = props.id;
		return nav.navigate('/student/profile/' + id);
	}


	// {/* ================== Handle Dialog Delete Class ========================================== */ }

	const handleOpenDialogDeleteClass = (id) => {
		setClassID(id);
		dialogDeleteClass.current.visible = true;
	}

	const handleCloseDialogDeleteClass = () => {
		setClassID(null);
		dialogDeleteClass.current.visible = false;
	}

	const deleteClass = async () => {
		try {
			setLoadingButton(true);
			await subject_class.deleteSubjectClass(classID);

			const body_activity = {
				action: "Delete",
				category: 8,
				description: props.data.name,
				status: "published"
			}
			await directus.createItem('activity_log', body_activity);

			dialogDeleteClass.current.visible = false;
			EventEmitter.emit('alert_toast', { content: Class_deleted_successfully, type: "success" });
			props.getData();
		} catch (error) {
			EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
		}
	}

	// {/* ================== Footer Template ========================================== */}

	const footerTemplate = (props) => {
		return (
			loadingButtonRef.current === false ?
				props.map((value, index) =>
					<button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
						<span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
					</button>
				)
				:
				<CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
		)
	}

	//subject class card content
	var status = null;
	if (moment(new Date(props.data.end_date)).add(1, 'days').format() >= moment().format()) {
		status = 'active';
	} else {
		status = 'passive';
	}
	// {/* ================== Main Content Card ========================================== */ }

	return (
		<div className='e-card'
			style=
			{{
				lineHeight: "0.5", borderRadius: '20px', width: "300px",
				backgroundColor: status === 'active' ? 'white' : '#eee',
				display: "flex", flexFlow: "column nowrap", height: "100%"

			}}>

			{/* display subject class name with link button that navigate to subject class detail*/}
			<div className="header_subject_class"
				style={{
					backgroundColor: status === 'active' ? '#E1EBF2' : '#E1EBF2',
					paddingLeft: "25px",
					height: "50px"
				}}>
				<span>
					<BrowserRouter>
						<Link to={{ pathname: `/subject_class/detail/${props.data.id}` }} style={{ textDecoration: "none" }}
							onClick={(e) => btnClickSubjectClass(e, props)}
						>
							{/* <ButtonComponent
								cssClass='e-link'
							// onClick={(e) => e.preventDefault()}
							>
								<h4><b>{props.data.name ? props.data.name : '-'}</b></h4> */}

							<span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", color: "#516573", fontSize: "16px", fontWeight: "800", cursor: "pointer", lineHeight: "50px" }} onClick={(e) => e.preventDefault()}>
								{props.data.name}
							</span>
							{/* </ButtonComponent> */}
						</Link>
					</BrowserRouter>
				</span>
				<div style={{ marginTop: "15.31px", float: "right", marginRight: "14.31px" }} >
					{props.numberOfMeetings ?
						<div>
							{/* <span className='e-icons icon-close' onClick={() => removeSubjectClass(props.data.id)} style={{ fontSize: "20px", paddingLeft: "5px", color: "#3c8dbc", }} ></span> */}
							{permission.AdminPermission() ?
								<div style={{ float: "right", cursor: "pointer" }}>
									<div style={{ background: "#B5C2CC", padding: "4.69px 4.44px 4.69px 4.44px", borderRadius: "50%" }}>
										<span className='e-icons icon-MT_Clear' onClick={() => handleOpenDialogDeleteClass(props.data.id)} style={{ fontSize: "10.5px", fontWeight: "500", color: "#FFFFFF", }} ></span>
									</div>
								</div>
								: ""}

							<DialogComponent
								id="dialogDeleteClass"
								width='25%'
								showCloseIcon={!loadingButton ? true : false}
								visible={false}
								ref={dialogDeleteClass}
								header={Delete_Confirmation}
								isModal={true}
								footerTemplate={() => footerTemplate([
									{ name: Delete, func: deleteClass, icon: "icon-delete-05", status: "e-warning" },
									{ name: Cancel, func: handleCloseDialogDeleteClass, icon: "icon-treeview", status: "" }
								])}
								close={handleCloseDialogDeleteClass}
							>
								<div>{Are_you_sure_you_want_to_delete_the_data_of_class} ({props.data.name})?</div>
							</DialogComponent>
						</div>
						: ''
					}
				</div>
			</div>



			{/* display start date and end date  */}
			<div className='e-card-content'
				style={{
					// minHeight: "auto", maxHeight: "auto",
					minHeight: "170px", maxHeight: "100%", height: "100%",
					backgroundColor: status === 'active' ? ' #F8FAFD' : '#E1EBF2'
				}}>
				{props.data.meetings.length > 0 ? getMeetings(props.data.meetings) : " -"}
				<div style={{ maxHeight: "100%" }} >
					{props.data.students.length > 0 ? getStudents(props.data.students, props) : " -"}
				</div>
			</div>

			<div className='e-card-content' style={{ minHeight: "30px", fontSize: "12px", lineHeight: "1.5", background: "#E1EBF2", height: "30px", position: "relative" }}>
				<div style={{ color: "#5F99E9", position: "absolute", top: "0px", display: "flex" }}>
					<div style={{ marginLeft: "0.31px" }}><span style={{ background: "#5F99E9", borderRadius: "50%", padding: "1px 4.5px 1px 4.5px" }} ><span className="e-icons icon-MT_DayView" style={{ fontSize: "10px", color: "#FFFFFF", lineHeight: "30px" }}></span></span></div>
					<div style={{ marginLeft: "5.31px", fontSize: "16px", fontWeight: "700", lineHeight: "30px" }}>{props.numberOfMeetings}</div>
				</div>
				<span style={{ right: "25px", height: "30px", fontSize: "10px", position: "absolute", bottom: "0" }}>
					{props.data.start_date && props.data.end_date ? getDate(props.data) : <div>-</div>}
				</span>
			</div>
		</div>
	)
}

export default CardRender;