import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { InPlaceEditorComponent } from '@syncfusion/ej2-react-inplace-editor';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

const Biodata = () => {
  const { id } = useParams();

  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));

  const [Name] = useState(i18next.t('Pages.data.Name'));
  const [Nickname] = useState(i18next.t('Pages.data.Nickname'));
  const [Gender] = useState(i18next.t('Pages.data.Gender'));
  const [Place_of_Birth] = useState(i18next.t('Pages.data.Place_of_Birth'));
  const [Date_of_Birth] = useState(i18next.t('Pages.data.Date_of_Birth'));
  const [Male] = useState(i18next.t('Pages.data.Male'));
  const [Female] = useState(i18next.t('Pages.data.Female'));
  const [Religion] = useState(i18next.t('Pages.data.Religion'));
  const [Islam] = useState(i18next.t('Pages.data.Islam'));
  const [Christian_Protestant] = useState(i18next.t('Pages.data.Christian_Protestant'));
  const [Catholic] = useState(i18next.t('Pages.data.Catholic'));
  const [Buddha] = useState(i18next.t('Pages.data.Buddha'));
  const [Hindu] = useState(i18next.t('Pages.data.Hindu'));
  const [Confucianism] = useState(i18next.t('Pages.data.Confucianism'));
  const [Others] = useState(i18next.t('Pages.data.Others'));
  const [Single] = useState(i18next.t('Pages.data.Single'));
  const [Married] = useState(i18next.t('Pages.data.Married'));
  const [Widow_Widower] = useState(i18next.t('Pages.data.Widow_Widower'));

  const [Mobile_Number] = useState(i18next.t('Pages.data.Mobile_Number'));
  const [Address] = useState(i18next.t('Pages.teacher_data.Address'));
  const [Degree] = useState(i18next.t('Pages.teacher_data.Degree'));
  const [KTP_Number] = useState(i18next.t('Pages.teacher_data.KTP_Number'));
  const [KTP_Expiry_Date] = useState(i18next.t('Pages.teacher_data.KTP_Expiry_Date'));
  const [Position] = useState(i18next.t('Pages.teacher_data.Position'));
  const [Field_of_Study] = useState(i18next.t('Pages.teacher_data.Field_of_Study'));
  const [Marital_Status] = useState(i18next.t('Pages.teacher_data.Marital_Status'));
  const [Name_of_Wife_Husband] = useState(i18next.t('Pages.teacher_data.Name_of_Wife_Husband'));
  const [Number_of_Children] = useState(i18next.t('Pages.teacher_data.Number_of_Children'));

  //data
  const [data, setData] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loadingBiodata, setLoadingBiodata] = useState(false);

  //other component setting
  const gender = [{ id: "Male", name: Male }, { id: "Female", name: Female }]
  const religion = [
    { id: 'Islam', text: Islam },
    { id: 'Christian/Protestant', text: Christian_Protestant },
    { id: 'Catholic', text: Catholic },
    { id: 'Buddha', text: Buddha },
    { id: 'Hindu', text: Hindu },
    { id: 'Confucianism', text: Confucianism },
    { id: 'Others', text: Others },
  ];
  const marital_status = [
    { id: 'Single', text: Single },
    { id: 'Married', text: Married },
    { id: 'Widow/Widower', text: Widow_Widower }
  ];

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoadingBiodata(true);
      getTeacher();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get teacher data
  const getTeacher = async () => {
    try {
      var result = await directus.getItems('teachers',
        {
          fields: '*.*.*',
          filter: {
            id: id
          }
        }
      );

      setData(result.data[0]);
      setLoadingBiodata(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Inplace Editor Component ========================================== */ }
  const actionSuccess = async (type, event) => {
    try {
      var value = '';
      if (type === "dob" || type === "ktp_expiry_date") {
        value = event.value ? moment(new Date(event.value)).format("YYYY-MM-DD") : null;
      } else if (type === "gender") {
        value = getGender(event.value, "id");
      } else if (type === "religion") {
        value = getTranslation(event.value, "id", religion);
      } else if (type === "marital_status") {
        value = getTranslation(event.value, "id", marital_status);
      } else {
        value = event.value;
      }

      if (type === "number_of_children") {
        if (value === "") { value = null }
      }
      var result = await directus.updateItem('teachers', id,
        { [type]: value }
      );

      var description = data.name;
      if (type === "name" && data.name !== value) {
        description = data.name + " --> " + value;
      }

      const body_activity = {
        action: "Update",
        category: 5,
        description: description,
        status: "published"
      }

      await directus.createItem('activity_log', body_activity);

      if (!result.error) {
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        if (type === "mobile_number" || type === "name" || type === "nickname" || type === "address" || type === "degree") {
          EventEmitter.emit('reloadTeacherData', { text: "click" });
        }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Translate Gender, Religion, Marital Status Data ========================================== */ }

  const getGender = (props, type) => {
    var gender2 = props ? props : null;
    if (gender) {
      gender.forEach((value) => {
        if (type === "name") {
          if (value.id === gender2) {
            gender2 = value.name
          }
        }
        if (type === "id") {
          if (value.name === gender2) {
            gender2 = value.id
          }
        }
      })
    }
    return "" + gender2
  }

  const getTranslation = (props, type, name) => {
    var oldData = props ? props : null;

    if (name) {
      name.forEach((value) => {
        if (type === "name") {
          if (value.id === oldData) {
            oldData = value.text
          }
        }
        if (type === "id") {
          if (value.text === oldData) {
            oldData = value.id
          }
        }
      })
    }

    return "" + oldData
  }

  // {/* ================== Main Content ========================================== */ }

  return <div id="biodata" style={{ alignContent: "center", color: "#333", minHeight: "600px", padding: "10px 30px 40px" }}>
    {loadingBiodata ? <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} /> :
      <div>
        {data ?
          <table id="table_biodata" style={{ marginLeft: "20px", marginTop: "20px", borderColor: "grey" }}>
            <tbody>
              <tr>
                <td style={{ width: "150px" }}>NIK</td>
                <td><InPlaceEditorComponent id='name' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.nik} model={{ placeholder: "NIK" }} actionSuccess={(e) => actionSuccess("nik", e)} /></td>
              </tr>
              <tr>
                <td>{Name}</td>
                <td><InPlaceEditorComponent id='name' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.name} model={{ placeholder: Name }} actionSuccess={(e) => actionSuccess("name", e)} /></td>
              </tr>
              <tr>
                <td>{Degree}</td>
                <td><InPlaceEditorComponent id='degree' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.degree} model={{ placeholder: Degree }} actionSuccess={(e) => actionSuccess("degree", e)} /></td>
              </tr>
              <tr>
                <td>{Nickname}</td>
                <td><InPlaceEditorComponent id='nickname' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.nickname} model={{ placeholder: Nickname }} actionSuccess={(e) => actionSuccess("nickname", e)} /></td>
              </tr>
              <tr>
                <td>Email</td>
                <td><InPlaceEditorComponent id='email' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.email} model={{ placeholder: 'Email' }} actionSuccess={(e) => actionSuccess("email", e)} /></td>
              </tr>
              <tr>
                <td>{Place_of_Birth}</td>
                <td><InPlaceEditorComponent id='pob' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.pob} model={{ placeholder: Place_of_Birth }} actionSuccess={(e) => actionSuccess("pob", e)} /></td>
              </tr>
              <tr>
                <td>{Date_of_Birth}</td>
                <td><InPlaceEditorComponent id='dob' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' type="Date" editableOn="DblClick" value={data.dob} model={{ placeholder: Date_of_Birth, format: "d-MMM-y" }} actionSuccess={(e) => actionSuccess("dob", e)} /></td>
              </tr>
              <tr>
                <td>{Gender}</td>
                <td><InPlaceEditorComponent id='gender' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" type='DropDownList' value={data.gender ? getGender(data.gender, "name") : null} model={{ placeholder: Gender, dataSource: gender, fields: { text: 'name', value: 'name' } }} actionSuccess={(e) => actionSuccess("gender", e)} /></td>
              </tr>
              <tr>
                <td>{Religion}</td>
                <td><InPlaceEditorComponent id='religion' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" type='DropDownList' value={data.religion ? getTranslation(data.religion, "name", religion) : null} model={{ placeholder: Religion, dataSource: religion, fields: { text: 'text', value: 'text' } }} actionSuccess={(e) => actionSuccess("religion", e)} /></td>
              </tr>
              <tr>
                <td>{KTP_Number}</td>
                <td><InPlaceEditorComponent id='ktp_number' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.ktp_number} model={{ placeholder: KTP_Number }} actionSuccess={(e) => actionSuccess("ktp_number", e)} /></td>
              </tr>
              <tr>
                <td>{KTP_Expiry_Date}</td>
                <td><InPlaceEditorComponent id='ktp_expiry_date' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' type="Date" editableOn="DblClick" value={data.ktp_expiry_date} model={{ placeholder: KTP_Expiry_Date, format: "d-MMM-y" }} actionSuccess={(e) => actionSuccess("ktp_expiry_date", e)} /></td>
              </tr>
              <tr>
                <td>{Address}</td>
                <td><InPlaceEditorComponent id='address' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.address} model={{ placeholder: Address }} actionSuccess={(e) => actionSuccess("address", e)} /></td>
              </tr>
              <tr>
                <td>{Position}</td>
                <td><InPlaceEditorComponent id='job_role' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.job_role} model={{ placeholder: Position }} actionSuccess={(e) => actionSuccess("job_role", e)} /></td>
              </tr>
              <tr>
                <td>{Field_of_Study}</td>
                <td><InPlaceEditorComponent id='department' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.department} model={{ placeholder: Field_of_Study }} actionSuccess={(e) => actionSuccess("department", e)} /></td>
              </tr>
              <tr>
                <td>{Marital_Status}</td>
                <td><InPlaceEditorComponent id='marital_status' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" type='DropDownList' value={data.marital_status ? getTranslation(data.marital_status, "name", marital_status) : null} model={{ placeholder: Marital_Status, dataSource: marital_status, fields: { text: 'text', value: 'text' } }} actionSuccess={(e) => actionSuccess("marital_status", e)} /></td>
              </tr>
              <tr>
                <td>{Name_of_Wife_Husband}</td>
                <td><InPlaceEditorComponent id='marriage_partner_name' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.marriage_partner_name} model={{ placeholder: Name_of_Wife_Husband }} actionSuccess={(e) => actionSuccess("marriage_partner_name", e)} /></td>
              </tr>
              <tr>
                <td>{Number_of_Children}</td>
                <td><InPlaceEditorComponent id='number_of_children' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" type='Numeric' value={data.number_of_children} model={{ placeholder: Number_of_Children, format: '#####' }} actionSuccess={(e) => actionSuccess("number_of_children", e)} /></td>
              </tr>
              <tr>
                <td>{Mobile_Number}</td>
                <td><InPlaceEditorComponent id='mobile_number' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.mobile_number} model={{ placeholder: Mobile_Number }} actionSuccess={(e) => actionSuccess("mobile_number", e)} /></td>
              </tr>
            </tbody>
          </table>
          : ''}
      </div>
    }
  </div>;
}

export default Biodata;