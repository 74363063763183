import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import CircularProgress from '@material-ui/core/CircularProgress';
import { FaFemale, FaMale } from 'react-icons/fa';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as link from 'src/constants/link';

const Student = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();
  // const navigate = useNavigate();

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));

  const [Grade] = useState(i18next.t('Pages.data.Grade'));
  const [Age] = useState(i18next.t('Pages.data.Age'));
  // const [Gender] = useState(i18next.t('Pages.data.Gender'));
  const [Mobile_Number] = useState(i18next.t('Pages.data.Mobile_Number'));
  const [Mothers_Mobile_Number] = useState(i18next.t('Pages.data.Mothers_Mobile_Number'));
  const [Fathers_Mobile_Number] = useState(i18next.t('Pages.data.Fathers_Mobile_Number'));
  const [Created_on] = useState(i18next.t('Pages.data.Created_on'));
  const [years] = useState(i18next.t('Pages.student_data.years'));
  const [months] = useState(i18next.t('Pages.student_data.months'));

  //data
  const [dataStudentContact, setDataStudentContact, dataStudentContactRef] = useStateRef(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoadingContact(true);
      getStudentContact();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  useEffect(() => {
    const reloadData = () => {
      getStudentContact();
    }

    EventEmitter.addListener('reloadStudentData', reloadData);
  })

  // {/* ================== Get All Data ========================================== */ }
  //get list of student contact
  const getStudentContact = async () => {
    try {
      var result = await directus.getItems('students_contacts', {
        fields: '*.*.*.*.*', filter: {
          "contacts_id": id
        }
      });

      setDataStudentContact(result.data);
      setLoadingContact(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Handle Student ========================================== */ }

  // function btnClick(value) {
  //   const id = value.students_id.id;
  //   return navigate('/student/profile/' + id);
  // }

  function getAge(date_of_birth) {
    var age = "-";
    if (date_of_birth) {
      const birthdate = moment(new Date(date_of_birth), 'MM-YYYY');
      const now = moment();
      var date = moment.duration(now.diff(birthdate));
      age = date.years() + " " + years + " ";
      if (date.months() > 0) {
        age += date.months() + " " + months;
      }
    }

    return age;
  }

  function getNumber(props, relation) {
    var list = [];
    var contact = linq.from(props)
      .where(w => w.relation === relation)
      .toArray();
    list.push(<span key="contact">{contact.length > 0 ? contact[0].contacts_id.mobile_number : null}</span>)

    return (
      <span>{list}</span>
    )
  }

  // {/* ================== Main Content ========================================== */ }
  return (
    <div id="contacts" style={{ color: "#333", minHeight: "600px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }}>
      {loadingContact ? <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} /> :
        <div>
          <div style={{ padding: "20px" }}>
            <div className='control-section card-control-section tile_layout' style={{ marginLeft: "-20px" }}>
              <div className='row e-card-layout'>
                <div className='card-list' style={{ display: "flex", flexWrap: "wrap", alignItems: "flex-start", alignContent: "flex-start" }}>
                  {dataStudentContactRef.current ? dataStudentContactRef.current.length > 0 ? dataStudentContactRef.current.map((value, index) => (
                    <div style={{ width: "auto", marginLeft: "20px", height: "220px", marginBottom: "30px" }} key={index}>
                      <div id={'card_sample_' + (index + 1)} className=''>
                        <div className='e-card' style={{ borderRadius: '15px', width: "300px" }}>
                          <div className="header-subject_class"
                            style={{ backgroundColor: "#E1EBF2", paddingLeft: "25px", height: "50px" }}>
                            <div style={{ float: "right", paddingRight: "10px", paddingTop: "10px" }}>
                              <div style={{ width: "30px", height: "30px" }}>
                                <img
                                  style={{
                                    maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                                    objectFit: "cover", borderRadius: "50%", position: "center",
                                  }}
                                  className="image"
                                  src={value.students_id.image ? value.students_id.image.data.full_url : link.default_image}
                                  alt={value.students_id.name}
                                />
                              </div>
                            </div>
                            {/* display subject class name with link button that navigate to subject class detail*/}
                            <div style={{ verticalAlign: "middle" }}>
                              <Link to={{ pathname: `/student/profile/${value.students_id.id}` }} style={{ textDecoration: "none" }}>
                                <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", color: "#516573", fontSize: "16px", fontWeight: "800", cursor: "pointer", lineHeight: "50px" }}>
                                  {value.students_id.name}
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div className='e-card-content' style={{ minHeight: "auto", fontSize: "12px", lineHeight: "1.5", background: "#F1F4FA", height: "150px", position: "relative" }}>
                            <span className='e-card-text' style={{ float: "right", fontSize: "20px", marginRight: "-10px" }}>{value.students_id.gender === "Female" ? <FaFemale style={{ color: "hotpink" }} /> : value.students_id.gender === "Male" ? <FaMale style={{ color: "dodgerblue" }} /> : ""}</span>
                            {value.students_id.date_of_birth ? <div><span className='e-card-text'>{Age} : {getAge(value.students_id.date_of_birth)} </span></div> : ''}
                            {value.students_id.current_school_level ? <div><span className='e-card-text'>{Grade} : {value.students_id.current_school_level} </span></div> : ''}
                            {value.students_id.mobile_number ? <div><span className='e-card-text'>{Mobile_Number} : {value.students_id.mobile_number} </span></div> : ''}
                            {value.students_id.contact ? value.students_id.contact.length > 0 ? getNumber(value.students_id.contact, "Mother") ? <div><span className='e-card-text'>{Mothers_Mobile_Number} : {getNumber(value.students_id.contact, "Mother")} </span></div> : '' : '' : ''}
                            {value.students_id.contact ? value.students_id.contact.length > 0 ? getNumber(value.students_id.contact, "Father") ? <div><span className='e-card-text'>{Fathers_Mobile_Number} : {getNumber(value.students_id.contact, "Father")} </span></div> : '' : '' : ''}
                          </div>
                          <div className='e-card-content' style={{ minHeight: "auto", fontSize: "12px", lineHeight: "1.5", background: "#E1EBF2", height: "30px", position: "relative" }}>
                            <span style={{ right: "25px", height: "20px", fontSize: "10px", position: "absolute", bottom: "0" }}>
                              {Created_on} : {moment(new Date(value.students_id.created_on)).format("DD-MMM-yyyy")}
                            </span>
                          </div>

                        </div>
                      </div>
                    </div>
                  ))
                    : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
                    : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
      }
    </div >
  )
}

export default Student;