import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Group, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

const AvailableTime = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  const [Sunday] = useState(i18next.t('Pages.data.Sunday'));
  const [Monday] = useState(i18next.t('Pages.data.Monday'));
  const [Tuesday] = useState(i18next.t('Pages.data.Tuesday'));
  const [Wednesday] = useState(i18next.t('Pages.data.Wednesday'));
  const [Thursday] = useState(i18next.t('Pages.data.Thursday'));
  const [Friday] = useState(i18next.t('Pages.data.Friday'));
  const [Saturday] = useState(i18next.t('Pages.data.Saturday'));

  const [Select] = useState(i18next.t('Pages.data.Select'));

  const [Start_Time] = useState(i18next.t('Pages.schedule_data.Start_Time'));
  const [End_Time] = useState(i18next.t('Pages.schedule_data.End_Time'));
  const [Weekday] = useState(i18next.t('Pages.schedule_data.Weekday'));
  const [Note] = useState(i18next.t('Pages.schedule_data.Note'));

  const [Available_Teacher_Time] = useState(i18next.t('Pages.teacher_data.Available_Teacher_Time'));
  const [Are_you_sure_you_want_to_delete_the_data_of_available_teacher_time] = useState(i18next.t('Pages.teacher_data.Are_you_sure_you_want_to_delete_the_data_of_available_teacher_time'));
  const [Available_teacher_time_created_successfully] = useState(i18next.t('Pages.teacher_data.Available_teacher_time_created_successfully'));
  const [Available_teacher_time_deleted_successfully] = useState(i18next.t('Pages.teacher_data.Available_teacher_time_deleted_successfully'));

  //data
  const [data, setData, dataRef] = useStateRef(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loadingBiodata, setLoadingBiodata] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogAddAvailableTime = useRef(false);
  let dialogEditAvailableTime = useRef(false);
  let dialogDeleteAvailableTime = useRef(false);

  //grid setting
  const grid = useRef();

  //other component setting
  const weeks = [{ id: "Sunday", name: Sunday }, { id: "Monday", name: Monday }, { id: "Tuesday", name: Tuesday }, { id: "Wednesday", name: Wednesday }, { id: "Thursday", name: Thursday }, { id: "Friday", name: Friday }, { id: "Saturday", name: Saturday }];

  //set state add available time
  const [state, setState] = useState({
    teacher: id,
    weekday: null,
    end_time: null,
    start_time: null,
    note: null
  });

  //set error
  const [error, setError, errorRef] = useStateRef({
    weekday: false,
    start_time: false,
    end_time: false
  });
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoadingBiodata(true);
      getData();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
 
  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "add_available_time") {
        dialogAddAvailableTime.current.visible = false;
        setError({ weekday: false, start_time: false, end_time: false });
        EventEmitter.emit('alert_toast', { content: Available_teacher_time_created_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "edit_available_time") {
        dialogEditAvailableTime.current.visible = false;
        setError({ weekday: false, start_time: false, end_time: false });
        setState({ ...state, weekday: null, start_time: null, end_time: null, note: null });
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_available_time") {
        dialogDeleteAvailableTime.current.visible = false;

        if (dialogDeleteAvailableTime.current.visible === false) {
          setState({ teacher: id, weekday: null, start_time: null, end_time: null, note: null });
          EventEmitter.emit('alert_toast', { content: Available_teacher_time_deleted_successfully, type: "success" });
          setDataButton(null);
        }
      }
    }
  })
   /* eslint-enable react-hooks/exhaustive-deps*/


  // {/* ================== Get All Data ========================================== */ }
  //get list of available time
  const getData = async () => {
    try {
      var result = await directus.getItems('available_time',
        {
          filter: {
            'teacher': id
          }
        });

      var data = [];
      if (result.data.length > 0) {
        //eslint-disable-next-line array-callback-return
        result.data.map((value2) => {
          //eslint-disable-next-line array-callback-return
          weeks.map((value, index) => {
            if (value2.weekday === value.id) {
              value2.sort = index;
              data.push(value2);
            }
          })
        })
      }

      data = linq.from(data)
        .orderBy(x => x.start_time)
        .orderBy(x => x.sort)
        .toArray();

      setData(data);
      setLoadingBiodata(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Handle Add Available Time ========================================== */ }
  const handleOpenDialogAddAvailableTime = () => {
    setState({ ...state, weekday: null, start_time: null, end_time: null, note: null });
    setError({ weekday: false, start_time: false, end_time: false });
    dialogAddAvailableTime.current.visible = true;
  }

  const handleCloseDialogAddAvailableTime = () => {
    setError({ weekday: false, start_time: false, end_time: false });
    setState({ ...state, weekday: null, start_time: null, end_time: null, note: null });
    dialogAddAvailableTime.current.visible = false;
  }

  const handleChangeAvailableTime = (e, type) => {
    var value = null;
    if (type === "weekday") {
      if (e.itemData.id) {
        value = e.itemData.id;
      }
    } else {
      value = e.value;
    }

    if (type === "weekday" || type === "start_time" || type === "end_time") {
      if (value) {
        setError({ ...errorRef.current, [type]: false })
      } else {
        setError({ ...errorRef.current, [type]: true })
      }
    }
    setState({ ...state, [type]: value })

  }

  const addAvailableTime = async () => {
    if (!error.weekday && !error.start_time && !error.end_time) {
      setError({ weekday: false, start_time: false, end_time: false });
      if (state.weekday && state.start_time && state.end_time) {
        try {
          setLoadingButton(true);
          var body = {
            teacher: id,
            weekday: state.weekday,
            start_time: state.start_time ? moment(state.start_time).format('HH:mm') + ':00' : null,
            end_time: state.end_time ? moment(state.end_time).format('HH:mm') + ':00' : null,
            note: state.note,
            status: "published"
          }
          var result = await directus.createItem('available_time', body);
          if (!result.error) {
            getData();
            setDataButton("add_available_time");
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          setLoadingButton(false);
        }
      } else {
        if (!state.weekday) { setError({ ...errorRef.current, weekday: true }) }
        if (!state.start_time) { setError({ ...errorRef.current, start_time: true }) }
        if (!state.end_time) { setError({ ...errorRef.current, end_time: true }) }
      }
    }
  }

  // {/* ================== Handle Edit Available Time ========================================== */ }
  const handleOpenDialogEditAvailableTime = (props) => {
    setState({ ...state, weekday: null, start_time: null, end_time: null, note: null });
    setState(props)
    setError({ weekday: false, start_time: false, end_time: false });
    dialogEditAvailableTime.current.visible = true;
  }

  const handleCloseDialogEditAvailableTime = () => {
    setState({ teacher: id, weekday: null, start_time: null, end_time: null, note: null });
    dialogEditAvailableTime.current.visible = false;
  }

  const updateAvailableTime = async () => {
    if (!error.weekday && !error.start_time && !error.end_time) {
      setError({ weekday: false, start_time: false, end_time: false });
      if (state.weekday && state.start_time && state.end_time) {
        try {
          setLoadingButton(true);
          var body = {
            teacher: state.teacher,
            weekday: state.weekday,
            start_time: moment(state.start_time).format() === "Invalid date" ? state.start_time : moment(state.start_time).format('HH:mm') + ':00',
            end_time: moment(state.end_time).format() === "Invalid date" ? state.end_time : moment(state.end_time).format('HH:mm') + ':00',
            note: state.note
          }

          var result = await directus.updateItem('available_time', state.id, body);
          if (!result.error) {
            getData();
            setDataButton("edit_available_time");
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          setLoadingButton(false);
        }
      } else {
        if (!state.weekday) { setError({ ...errorRef.current, weekday: true }) }
        if (!state.start_time) { setError({ ...errorRef.current, start_time: true }) }
        if (!state.end_time) { setError({ ...errorRef.current, end_time: true }) }
      }
    }
  }

  // {/* ================== Handle Delete Available Time ========================================== */ }
  const handleOpenDialogDeleteAvailableTime = (props) => {
    setState(props)
    dialogDeleteAvailableTime.current.visible = true;
  }

  const handleCloseDialogDeleteAvailableTime = () => {
    setState({ teacher: id, weekday: null, start_time: null, end_time: null, note: null });
    dialogDeleteAvailableTime.current.visible = false;
  }

  const deleteAvailableTime = async () => {
    try {
      setLoadingButton(true);
      var result = await directus.deleteItem('available_time', state.id);

      if (!result.error) {
        getData();
        setDataButton("delete_available_time");
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Data Grid Template ========================================== */ }
  const startTimeTemplate = (props) => {
    return (<div>{props.start_time ? moment(props.start_time, "HH:mm:ss").format("HH:mm") : null}</div>)
  }

  const endTimeTemplate = (props) => {
    return (<div>{props.end_time ? moment(props.end_time, "HH:mm:ss").format("HH:mm") : null}</div>)
  }

  const weekEndTemplate = (props) => {
    return (<div>{props.weekday ? getWeek(props.weekday, "id") : ''}</div>)
  }

  const buttonTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_hover" style={{ display: "flex" }}>
          <TooltipComponent content="Edit" position="BottomCenter" >
            <span className='e-icons icon-edit-icon1'
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", verticalAlign: "middle", lineHeight: "40px" }}
              onClick={() => handleOpenDialogEditAvailableTime(props)} />
          </TooltipComponent>

          <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px", }} >
            <span className="e-icons icon-delete-05"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => handleOpenDialogDeleteAvailableTime(props)}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  // {/* ================== Translate Week ========================================== */ }
  const getWeek = (props) => {
    var week2 = props ? props : null;
    if (weeks) {
      weeks.forEach((value) => {
        if (value.id === week2) {
          week2 = value.name
        }
      })
    }
    return "" + week2
  }

  // {/* ================== Footer Template ========================================== */}

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }

  return <div id="available_time" style={{ alignContent: "center", color: "#333", minHeight: "600px", paddingTop: "20px", paddingBottom: "20px", paddingLeft: "30px", paddingRight: "30px" }}>
    {loadingBiodata ? <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} /> :
      <div>
        {data ?
          <div>
            <div style={{ float: "right" }}>
              <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddAvailableTime}>
                <span className="btn-text-custom">{Available_Teacher_Time}</span>
              </ButtonComponent>
            </div>
            <br /><br /><br />

            {dataRef.current ? dataRef.current.length > 0 ?
              <div>
                <GridComponent
                  dataSource={dataRef.current}
                  ref={grid}
                  allowTextWrap={true}
                  textWrapSettings={{ wrapMode: "Content" }}
                  allowResizing={true}
                  gridLines='Both'
                  allowEditing={false}
                  width="100%"
                  selectionSettings={{ mode: 'Both' }}
                  clipMode="EllipsisWithTooltip"
                >
                  <ColumnsDirective>
                    <ColumnDirective customAttributes={{ id: 'first' }} width="17%" headerText={Start_Time} template={startTimeTemplate} ></ColumnDirective>
                    <ColumnDirective width="17%" headerText={End_Time} template={endTimeTemplate} ></ColumnDirective>
                    <ColumnDirective width="17%" headerText={Weekday} template={weekEndTemplate}></ColumnDirective>
                    <ColumnDirective width="35%" field="note" headerText={Note} ></ColumnDirective>
                    <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="12%" template={buttonTemplate} editTemplate={buttonTemplate}></ColumnDirective>
                  </ColumnsDirective>
                  <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Group, Resize]} />
                </GridComponent>
              </div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
            }

            <DialogComponent
              id="dialogAddAvailableTime"
              width="40%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogAddAvailableTime}
              header={Available_Teacher_Time}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Update, func: addAvailableTime, icon: "icon-check-mark-01", status: "e-success" },
                { name: Cancel, func: handleCloseDialogAddAvailableTime, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogAddAvailableTime}
            >
              <div>
                <b>{Weekday}</b>
                <DropDownListComponent
                  dataSource={weeks}
                  name="weekday"
                  cssClass="e-outline"
                  placeholder={`--${Select}--`}
                  select={(e) => handleChangeAvailableTime(e, "weekday")}
                  fields={{ text: 'name', value: 'id' }}
                  className="form-control"
                  value={state.weekday}
                />
                {error.weekday === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                <br /><br />
                <b>{Start_Time}</b>
                <TimePickerComponent
                  name="start_time"
                  cssClass="e-outline"
                  step={30}
                  format={'HH:mm'}
                  change={(e) => handleChangeAvailableTime(e, "start_time")}
                  className="form-control"
                  value={state.start_time ? state.start_time : null}
                />
                {error.start_time === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                <br /><br />
                <b>{End_Time}</b>
                <TimePickerComponent
                  name="end_time"
                  cssClass="e-outline"
                  step={30}
                  format={'HH:mm'}
                  change={(e) => handleChangeAvailableTime(e, "end_time")}
                  className="form-control"
                  value={state.end_time ? state.end_time : null}
                />
                {error.end_time === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                <br /><br />
                <b>{Note}</b>
                <TextBoxComponent
                  multiline={true}
                  name="note"
                  cssClass="e-outline"
                  change={(e) => handleChangeAvailableTime(e, "note")}
                  className="form-control"
                  value={state.note}
                />
              </div>

            </DialogComponent>


            <DialogComponent
              id="dialogEditAvailableTime"
              width="40%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogEditAvailableTime}
              header={Available_Teacher_Time}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Update, func: updateAvailableTime, icon: "icon-check-mark-01", status: "e-success" },
                { name: Cancel, func: handleCloseDialogEditAvailableTime, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogEditAvailableTime}
            >
              <div>
                <b>{Weekday}</b>
                <DropDownListComponent
                  dataSource={weeks}
                  name="weekday"
                  cssClass="e-outline"
                  placeholder={`--${Select}--`}
                  select={(e) => handleChangeAvailableTime(e, "weekday")}
                  fields={{ text: 'name', value: 'id' }}
                  className="form-control"
                  value={state.weekday ? state.weekday : null}
                />
                {error.weekday === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                <br /><br />
                <b>{Start_Time}</b>
                <TimePickerComponent
                  name="start_time"
                  cssClass="e-outline"
                  step={30}
                  format={'HH:mm'}
                  change={(e) => handleChangeAvailableTime(e, "start_time")}
                  className="form-control"
                  value={state.start_time ? moment(state.start_time, "HH:mm:ss").format("HH:mm") : null}
                />
                {error.start_time === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                <br /><br />
                <b>{End_Time}</b>
                <TimePickerComponent
                  name="end_time"
                  cssClass="e-outline"
                  step={30}
                  format={'HH:mm'}
                  change={(e) => handleChangeAvailableTime(e, "end_time")}
                  className="form-control"
                  value={state.end_time ? moment(state.end_time, "HH:mm:ss").format("HH:mm") : null}
                />
                {error.end_time === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                <br /><br />
                <b>{Note}</b>
                <TextBoxComponent
                  multiline={true}
                  name="note"
                  cssClass="e-outline"
                  change={(e) => handleChangeAvailableTime(e, "note")}
                  className="form-control"
                  value={state ? state.note : null}
                />
              </div>
            </DialogComponent>


            <DialogComponent
              id="dialogDeleteAvailableTime"
              width="20%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogDeleteAvailableTime}
              header={Delete_Confirmation}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Delete, func: deleteAvailableTime, icon: "icon-delete-05", status: "e-warning" },
                { name: Cancel, func: handleCloseDialogDeleteAvailableTime, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogDeleteAvailableTime}
            >
              <div id="contentDialogAvailableTime">
                {Are_you_sure_you_want_to_delete_the_data_of_available_teacher_time} {state ?
                  <span style={{ color: "#3c8dbc" }}>({state.weekday ? getWeek(state.weekday) : null} {state.start_time ? moment(state.start_time, "HH:mm:ss").format("HH:mm") : null}-{state.end_time ? moment(state.end_time, "HH:mm:ss").format("HH:mm") : null})
                  </span>
                  : ''}
              </div>
            </DialogComponent>

          </div>
          : ''}
      </div>
    }
  </div>;
}

export default AvailableTime;