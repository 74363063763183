import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Group, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

const SubjectClass = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));

  const [Start_Date] = useState(i18next.t('Pages.schedule_data.Start_Date'));
  const [End_Date] = useState(i18next.t('Pages.schedule_data.End_Date'));
  const [Class_Name] = useState(i18next.t('Pages.schedule_data.Class_Name'));

  //data
  const [data, setData, dataRef] = useStateRef(null);

  //set initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loadingBiodata, setLoadingBiodata] = useState(false);

  //grid component setting
  const grid = useRef();
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoadingBiodata(true);
      getData();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get all subject class data with selected teacher
  const getData = async () => {
    try {
      var result = await directus.getItems('subject_classes',
        {
          fields: '*.*.*',
          filter: {
            'meetings.meetings_id.teacher': id
          }
        }
      );
      setData(result.data);
      setLoadingBiodata(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Data Grid Template ========================================== */ }

  const startDateTemplate = (props) => {
    return (<div>{props.start_date ? moment(props.start_date).format("DD-MMM-YYYY") : null}</div>)
  }

  const endDateTemplate = (props) => {
    return (<div>{props.end_date ? moment(props.end_date).format("DD-MMM-YYYY") : null}</div>)
  }

  const classNameTemplate = (props) => {
    return (
      <div>
        <Link to={{ pathname: `/subject_class/detail/${props.id}` }} style={{ textDecoration: "none" }}>
          <span style={{ cursor: "pointer", color: '#3c8dbc' }}>
            {props.name}
          </span>
        </Link>
      </div>
    )
  }

  // {/* ================== Main Content ========================================== */ }
  return <div id="biodata" style={{ alignContent: "center", color: "#333", minHeight: "600px", paddingTop: "20px", paddingBottom: "20px", paddingLeft: "30px", paddingRight: "30px" }}>
    {loadingBiodata ? <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} /> :
      <div>
        {dataRef.current ? dataRef.current.length > 0 ?
          <div>
            <GridComponent
              dataSource={dataRef.current}
              ref={grid}
              allowTextWrap={true}
              textWrapSettings={{ wrapMode: "Content" }}
              allowResizing={true}
              gridLines='Both'
              allowEditing={false}
              width="90%"
              selectionSettings={{ mode: 'Both' }}
              clipMode="EllipsisWithTooltip"
            >
              <ColumnsDirective>
                <ColumnDirective customAttributes={{ id: 'first' }} width="25%" headerText={Start_Date} template={startDateTemplate} ></ColumnDirective>
                <ColumnDirective width="25%" headerText={End_Date} template={endDateTemplate} ></ColumnDirective>
                <ColumnDirective customAttributes={{ id: 'last' }} width="50%" headerText={Class_Name} template={classNameTemplate} ></ColumnDirective>
              </ColumnsDirective>
              <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Group, Resize]} />
            </GridComponent>
          </div>
          : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
          : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
        }
      </div>
    }
  </div>;
}

export default SubjectClass;