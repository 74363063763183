import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Query } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Group, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as permission from 'src/utils/Permission';

const Schedule = (props) => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams()
  // const navigate = useNavigate();

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));

  const [Sunday] = useState(i18next.t('Pages.data.Sunday'));
  const [Monday] = useState(i18next.t('Pages.data.Monday'));
  const [Tuesday] = useState(i18next.t('Pages.data.Tuesday'));
  const [Wednesday] = useState(i18next.t('Pages.data.Wednesday'));
  const [Thursday] = useState(i18next.t('Pages.data.Thursday'));
  const [Friday] = useState(i18next.t('Pages.data.Friday'));
  const [Saturday] = useState(i18next.t('Pages.data.Saturday'));
  const [Select_Teacher] = useState(i18next.t('Pages.data.Select_Teacher'));
  const [Select_Student] = useState(i18next.t('Pages.data.Select_Student'));
  const [Select_Room] = useState(i18next.t('Pages.data.Select_Room'));
  const [Please_select_student] = useState(i18next.t('Pages.data.Please_select_student'));
  const [Student] = useState(i18next.t('Pages.data.Student'));

  const [Date_2] = useState(i18next.t('Pages.schedule_data.Date'));
  const [Teacher] = useState(i18next.t('Pages.schedule_data.Teacher'));
  const [Time] = useState(i18next.t('Pages.schedule_data.Time'));
  const [Duration] = useState(i18next.t('Pages.schedule_data.Duration'));

  const [Class] = useState(i18next.t('Pages.subject_class_data.Class'));
  const [Students] = useState(i18next.t('Pages.subject_class_data.Students'));
  const [Schedule] = useState(i18next.t('Pages.subject_class_data.Schedule'));
  const [Topic] = useState(i18next.t('Pages.subject_class_data.Topic'));
  const [Note] = useState(i18next.t('Pages.subject_class_data.Note'));
  const [Student_Class_Enrollment] = useState(i18next.t('Pages.subject_class_data.Student_Class_Enrollment'));
  const [Registration_Fee] = useState(i18next.t('Pages.subject_class_data.Registration_Fee'));
  const [Monthly_Fee] = useState(i18next.t('Pages.subject_class_data.Monthly_Fee'));
  const [Lesson_Fee] = useState(i18next.t('Pages.subject_class_data.Lesson_Fee'));
  const [Registration_Discount] = useState(i18next.t('Pages.subject_class_data.Registration_Discount'));
  const [Monthly_Discount] = useState(i18next.t('Pages.subject_class_data.Monthly_Discount'));
  const [Edit_Schedule] = useState(i18next.t('Pages.subject_class_data.Edit_Schedule'));
  const [Early_Teacher] = useState(i18next.t('Pages.subject_class_data.Early_Teacher'));
  const [Are_you_sure_you_want_to_delete_this_schedule] = useState(i18next.t('Pages.subject_class_data.Are_you_sure_you_want_to_delete_this_schedule'));
  const [Date_Change_Confirmation] = useState(i18next.t('Pages.subject_class_data.Date_Change_Confirmation'));
  const [Are_you_sure_you_want_to_change_the_schedule] = useState(i18next.t('Pages.subject_class_data.Are_you_sure_you_want_to_change_the_schedule'));
  const [Are_you_sure_you_want_to_remove_this_student] = useState(i18next.t('Pages.subject_class_data.Are_you_sure_you_want_to_remove_this_student'));
  const [from_the_class] = useState(i18next.t('Pages.subject_class_data.from_the_class'));
  const [Student_Class_List] = useState(i18next.t('Pages.subject_class_data.Student_Class_List'));
  const [Room] = useState(i18next.t('Pages.subject_class_data.Room'));
  const [Get_Topic_from_List] = useState(i18next.t('Pages.subject_class_data.Get_Topic_from_List'));
  const [Topic_List] = useState(i18next.t('Pages.subject_class_data.Topic_List'));
  const [Select_Topic] = useState(i18next.t('Pages.subject_class_data.Select_Topic'));
  const [Test] = useState(i18next.t('Pages.subject_class_data.Test'));
  const [Student_successfully_removed_from_class] = useState(i18next.t('Pages.subject_class_data.Student_successfully_removed_from_class'));
  const [Student_successfully_enrolled_into_class] = useState(i18next.t('Pages.subject_class_data.Student_successfully_enrolled_into_class'));
  const [Schedule_deleted_successfully] = useState(i18next.t('Pages.subject_class_data.Schedule_deleted_successfully'));

  const [Push_Back] = useState(i18next.t('Pages.schedule_data.Push_Back'));
  const [Schedule2] = useState(i18next.t('Pages.schedule_data.Schedule'));

  //set data
  const [data, setData] = useState(props.data ? props.data : null);
  const [dataStudentNoClass, setDataStudentNoClass] = useState(null);
  const [dataTeacher, setDataTeacher, dataTeacherRef] = useStateRef(props.dataTeacher ? props.dataTeacher : null);
  const [dataSubject, setDataSubject] = useState(null);
  const [dataRoom, setDataRoom] = useState(null);
  const [dataStudentSubjectClass, setDataStudentSubjectClass, dataStudentSubjectClassRef] = useStateRef(null);
  const [dataSchedule, setDataSchedule, dataScheduleRef] = useStateRef(props.dataSchedule ? props.dataSchedule : null);
  const [dataPublicHoliday, setDataPublicHoliday, dataPublicHolidayRef] = useStateRef(null);

  //set initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogAddStudent = useRef(false);
  let dialogEditSchedule = useRef(false);
  let dialogDeleteStudent = useRef(false);
  let dialogDeleteSchedule = useRef(false);
  let dialogDateChangeSchedule = useRef(false);
  let dialogStudentClassList = useRef(false);

  //other component setting
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const weeks = [{ id: "Sunday", name: Sunday }, { id: "Monday", name: Monday }, { id: "Tuesday", name: Tuesday }, { id: "Wednesday", name: Wednesday }, { id: "Thursday", name: Thursday }, { id: "Friday", name: Friday }, { id: "Saturday", name: Saturday }];
  let topic_dd = useRef(false);
  const grid = useRef();

  //set state add student
  const [state, setState, stateRef] = useStateRef({
    students_id: null,
    subject_classes_id: id
  });

  //set state edit student
  const [stateEditStudent, setStateEditStudent, stateEditStudentRef] = useStateRef({
    students_id: null,
    name: null,
    subject_classes_id: id
  })

  //set state edit schedule
  const [stateEditSchedule, setStateEditSchedule, stateEditScheduleRef] = useStateRef(null);
  const [selectedData, setSelectedData] = useState(null);
  const [error, setError, errorRef] = useStateRef({
    student: false, date_change: false, time_change: false, duration: false
  });

  const [stateTopic, setStateTopic, stateTopicRef] = useStateRef({
    list: 0
  });

  const fields = { text: 'name', value: 'id' };
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoadingSchedule(true);
      setData(props.data);
      getPublicHoliday(props.data, props.data);
      setDataTeacher(props.dataTeacher);
      setDataSubject(props.dataSubject);
      setDataRoom(props.dataRoom)
    }

    return function cleanup() {
      setInitialized(true);
    }
  })

  useEffect(() => {
    const updateData = (props) => {
      if (props.update === true) { getData(); }
    }
    const listener = EventEmitter.addListener('update_schedule', updateData);
    return () => { listener.remove(); }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "edit_schedule") {
        dialogEditSchedule.current.visible = false;
        setStateEditSchedule(null);
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_schedule") {
        dialogDeleteSchedule.current.visible = false;
        setSelectedData(null);
        EventEmitter.emit('alert_toast', { content: Schedule_deleted_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "date_change_schedule") {
        dialogDateChangeSchedule.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        EventEmitter.emit('update_schedule', { update: true });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_student") {
        dialogDeleteStudent.current.visible = false;
        setStateEditStudent({ students_id: null, name: null, subject_classes_id: id })
        EventEmitter.emit('alert_toast', { content: Student_successfully_removed_from_class, type: "success" });
        EventEmitter.emit('update_student', { update: true });
        EventEmitter.emit('update_schedule', { update: true });
        setDataButton(null);
      }

      if (dataButtonRef.current === "add_student") {
        dialogAddStudent.current.visible = false;
        setState({ ...state, students_id: null });
        EventEmitter.emit('alert_toast', { content: Student_successfully_enrolled_into_class, type: "success" });
        EventEmitter.emit('update_student', { update: true });
        EventEmitter.emit('update_schedule', { update: true });
        setDataButton(null);
      }

      if (dataButtonRef.current === "student_class_list") {
        dialogStudentClassList.current.visible = false;
        setStateEditStudent({ students_id: null, name: null, subject_classes_id: id })
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/


  // {/* ================== Get All Data ========================================== */ }
  //get all subject class data by specific id
  const getData = async () => {
    try {
      var result = await directus.getItems('subject_classes',
        {
          fields: '*, subjects.*.*.*, meetings.meetings_id.*.*, students.students_id.*',
          filter: { id: id }
        }
      );

      if (!result.error) {
        var result2 = result.data[0];

        var meetings = [];
        //eslint-disable-next-line array-callback-return
        result2.meetings.map((value) => {
          var meetingData = {
            weekday: value.meetings_id.weekday,
            time: moment(value.meetings_id.time, "HH:mm:ss").format('HH:mm'),
            teacher: value.meetings_id.teacher.id,
            room: value.meetings_id.room.id
          }
          meetings.push(meetingData);
        })

        setData(result2);

        getPublicHoliday(result2)

      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }

  }

  const getPublicHoliday = async (result2) => {
    var result = await directus.getItems('public_holiday',
      {
        fields: "id,date",
        filter: {
          date: {
            'between': [moment(result2.start_date).clone().startOf('month').format("YYYY-MM-DD"), moment(result2.end_date).clone().endOf('month').format("YYYY-MM-DD")]
          }
        },
        sort: "date",
      });

    var result_holiday = linq.from(result.data)
      .select(data => data.date)
      .toArray();

    setDataPublicHoliday(result_holiday);
    getStudentNoClass(result2);
  }

  //get list of students who don't enroll the class for add new student
  const getStudentNoClass = async (props) => {
    let id_students = [];

    //get list of students id who enroll the class
    //eslint-disable-next-line array-callback-return
    props.students.map((item) => {
      id_students.push(item.students_id.id);
    })

    try {
      //get students data exclude the students who enroll the class
      var result = await directus.getItems('students',
        {
          sort: "nickname",
          filter: {
            id: { "nin": id_students },
            status: "published"
          },
          fields: "id,name,nickname"
        }
      )
      if (!result.error) {
        setDataStudentNoClass(result.data);
        getSchedule();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }

  }

  //get list of schedules of the subject class sort by date
  const getSchedule = async () => {
    try {
      var result = await directus.getItems('schedules', {
        fields: '*, subject_classes_id.subjects.*,attendances.*',
        filter: {
          subject_classes_id: id
        },
        sort: 'date'
      });

      var result_schedule = [];
      if (result.data.length > 0) {
        //eslint-disable-next-line array-callback-return
        result.data.map((value) => {
          if (dataPublicHolidayRef.current.length > 0) {
            if (dataPublicHolidayRef.current.includes(value.date)) {
              value.holiday = true;
            } else {
              value.holiday = false;
            }
          }
          result_schedule.push(value)
        })
      }

      if (!result.error) {
        setDataSchedule(result.data);
        getStudentSubjectClass();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of students who enroll class
  const getStudentSubjectClass = async () => {
    try {
      var result = await directus.getItems('students_subject_classes', {
        filter: {
          subject_classes_id: id
        },
        fields: "*.*"
      });

      var result_data = result.data;

      if (!result.error) {
        setDataStudentSubjectClass(result_data);
        setLoadingSchedule(false);
        setLoadingButton(false);
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }




  // {/* ================== Filtering ========================================== */ }

  //search student data who don't enroll the class
  const onFiltering = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataStudentNoClass, query);
  };

  //search teacher by name
  const onFilteringTeacher = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('nickname', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataTeacher, query);
  };

  //search room by name
  const onFilteringRoom = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataRoom, query);
  };


  // {/* ================== Handle Add Student ========================================== */ }
  //open new student dialog
  const handleOpenDialogAddStudent = () => {
    dialogAddStudent.current.visible = true;
  }

  const handleCloseDialogAddStudent = () => {
    setState({ ...state, students_id: null });
    setError({ ...error, student: false })
    dialogAddStudent.current.visible = false;
  }

  //handle the state of student who enroll the class
  const handleAddStudent = (event) => {
    const value = event.itemData.id;
    if (value) {
      setState({ ...state, students_id: value });
      setError({ ...error, student: false });
    } else {
      setError({ ...error, student: true });
    }
  }

  //update new student
  const addStudent = async () => {
    if (!error.student) {
      setError({ ...error, student: false });
      setLoadingButton(true);
      if (stateRef.current.students_id) {
        try {
          var body = {
            students_id: stateRef.current.students_id,
            subject_classes_id: id,
            registration_fee: data.registration_fee,
            monthly_fee: data.monthly_fee,
            lesson_fee: data.lesson_fee,
            status: "published"
          }

          //enroll students in class
          var result = await directus.createItem('students_subject_classes', body);

          if (!result.error) {
            var attendances = [];
            //eslint-disable-next-line array-callback-return
            dataScheduleRef.current.map((value) => {
              var body = {
                schedules_id: value.id,
                students_subject_classes_id: result.data.id,
                status: "published"
              }
              attendances.push(body);
            })

            //create all student attendances
            await directus.createItems('attendances', attendances);

            var student_result = {
              student: stateRef.current.students_id,
              subject_class: id,
              status: "published"
            }

            //create student results
            var result3 = await directus.createItems('results', student_result);

            if (!result3.error) {
              getData();
              setError({ ...error, student: false });
              setDataButton("add_student");
            }
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          setLoadingButton(false);
        }
      } else {
        setError({ ...error, student: true });
        setLoadingButton(false);
      }
    }
  }

  // {/* ================== Handle Delete Student ========================================== */ }
  const handleCloseDialogDeleteStudent = () => {
    dialogDeleteStudent.current.visible = false;
    setStateEditStudent({ ...stateEditStudentRef.current, students_id: null, name: null, subject_classes_id: id })
  }


  //set selected student to delete
  const handleOpenDialogDeleteStudent = (props) => {
    setStateEditStudent({
      ...stateEditStudentRef.current,
      students_id: props.students_id.id,
      name: props.students_id.name
    })
    dialogDeleteStudent.current.visible = true;
  }

  const deleteStudent = async () => {
    setLoadingButton(true);
    try {
      //get students subject classes id
      var result = await directus.getItems('students_subject_classes', {
        filter: {
          subject_classes_id: id,
          students_id: stateEditStudentRef.current.students_id
        }
      })

      if (!result.error) {
        var students_subject_classes_id = result.data[0].id;

        //get all attendances id from selected student
        var attendances = await directus.getItems('attendances', {
          filter: {
            students_subject_classes_id: students_subject_classes_id
          }
        })

        var attendances_id = linq.from(attendances.data)
          .select(data => data.id)
          .toArray();

        //delete all attendances data from selected student
        await directus.deleteItems('attendances', attendances_id);


        //get all results id from selected student
        var student_results = await directus.getItems('results', {
          filter: {
            student: stateEditStudentRef.current.students_id,
            subject_class: id
          }
        })

        var student_results_id = linq.from(student_results.data)
          .select(data => data.id)
          .toArray();

        //delete all results id from selected student
        await directus.deleteItems('results', student_results_id);


        //remove student from the class
        var result_student = await directus.updateItem('students_subject_classes', students_subject_classes_id, { status: "deleted" });

        if (!result_student.error) {
          getData();
          setDataButton("delete_student");
        }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Navigation ========================================== */ }

  //navigate to specific student profile
  // function clickStudent(id) {
  //   return navigate('/student/profile/' + id);
  // }

  // {/* ================== Update student class list ========================================== */ }

  //open new student dialog
  const handleOpenDialogStudentClassList = (props) => {
    setStateEditStudent(props)
    dialogStudentClassList.current.visible = true;
  }

  const handleCloseDialogStudentClassList = () => {
    setStateEditStudent({ students_id: null, name: null, subject_classes_id: id })
    // setError({ ...error, student: false })
    dialogStudentClassList.current.visible = false;
  }

  const handleChangeStudentClassList = (event, type) => {
    setStateEditStudent({ ...stateEditStudentRef.current, [type]: event.value });
  }

  //update student class list fee
  const updateStudentClassList = async () => {
    setLoadingButton(true);
    if (stateEditStudentRef.current.id) {
      try {

        var body = {
          registration_fee: stateEditStudentRef.current.registration_fee ? stateEditStudentRef.current.registration_fee : 0,
          registration_discount: stateEditStudentRef.current.registration_discount ? stateEditStudentRef.current.registration_discount : 0,
          monthly_fee: stateEditStudentRef.current.monthly_fee ? stateEditStudentRef.current.monthly_fee : 0,
          monthly_discount: stateEditStudentRef.current.monthly_discount ? stateEditStudentRef.current.monthly_discount : 0,
          lesson_fee: stateEditStudentRef.current.lesson_fee ? stateEditStudentRef.current.lesson_fee : 0,
        }
        var result = await directus.updateItem('students_subject_classes', stateEditStudentRef.current.id, body);

        if (!result.error) {
          getData();
          setDataButton("student_class_list");
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        setLoadingButton(false);
      }
    }
  }

  // {/* ================== Edit Schedule ========================================== */ }

  //open dialog edit schedule
  const handleOpenDialogEditSchedule = (props) => {
    props.schedule.date = props.schedule.date ? new Date(props.schedule.date) : null;
    props.schedule.time = props.schedule.time ? moment(props.schedule.time, "hh:mm:ss").format("HH:mm") : null;
    setStateEditSchedule(props.schedule);
    setStateTopic({ ...stateTopicRef.current, list: 0 });
    dialogEditSchedule.current.visible = true;
  }

  const handleCloseDialogEditSchedule = () => {
    dialogEditSchedule.current.visible = false;
    setError({ ...error, date_change: false, time_change: false, duration: false });
  }

  const handleChangeEditSchedule = (event, type) => {
    var value = null;
    //get schedule data value and put into state
    if (type === "teachers_id" || type === "teacher_change" || type === "room") {
      value = event.itemData.id;
    } else if (type === "test" || type === "review") {
      value = event.checked;
    } else if (type === "time_change") {
      if (event.value) {
        value = moment(event.value).format('HH:mm');
      }
    } else {
      value = event.value
    }

    if (type === "date_change" || type === "duration" || type === "time_change") {
      if (value) {
        setError({ ...errorRef.current, [type]: false })
      } else {
        setError({ ...errorRef.current, [type]: true })
      }
    }
    setStateEditSchedule({ ...stateEditSchedule, [type]: value })
  }

  const editSchedule = async () => {
    if (!error.date_change && !error.time_change && !error.duration) {
      setError({ ...error, date_change: false, time_change: false, duration: false });
      setLoadingButton(true);

      try {
        var dataUpdate = stateEditScheduleRef.current;
        if (dataUpdate.time_change !== "Invalid date" && dataUpdate.duration) {
          //date = check if there is a date change, if there is a change then change date to date_change

          //previous_date = check if there is a date change and check if there is a previous_date
          //if there is a previous_date then the value does not change
          //if there is not a previous_date then the old date is inserted into the previous_date
          //if there is not a date change and there is not a previous_date then the value is null
          //same with time change and teacher change

          const body = {
            id: dataUpdate.id,
            date: dataUpdate.date_change ? moment(dataUpdate.date_change).format("YYYY-MM-DD") : moment(dataUpdate.date).format("YYYY-MM-DD"),
            previous_date: dataUpdate.date_change ? dataUpdate.previous_date ? dataUpdate.previous_date : moment(dataUpdate.date).format("YYYY-MM-DD") : dataUpdate.previous_date ? dataUpdate.previous_date : null,
            time: dataUpdate.time_change ? (dataUpdate.time_change + ":00") : (dataUpdate.time + ":00"),
            previous_time: dataUpdate.time_change ? dataUpdate.previous_time ? dataUpdate.previous_time : (dataUpdate.time + ":00") : null,
            duration: dataUpdate.duration,
            room: dataUpdate.room.id ? dataUpdate.room.id : dataUpdate.room,
            teachers_id: dataUpdate.teacher_change ? dataUpdate.teacher_change : dataUpdate.teachers_id.id,
            previous_teacher: dataUpdate.teacher_change ? dataUpdate.previous_teacher ? dataUpdate.previous_teacher.id : dataUpdate.teachers_id.id : dataUpdate.previous_teacher ? dataUpdate.previous_teacher.id : null,
            topic: dataUpdate.topic,
            note: dataUpdate.note,
            test: dataUpdate.test,
            review: dataUpdate.review
          }

          // update schedule data to database
          var result = await directus.updateItem('schedules', body.id, body);

          //get min date
          var min_date = linq.from(dataScheduleRef.current)
            .minBy(data => new Date(data.date));

          //check if the new date changed earlier than the earliest date
          if (moment(new Date(body.date)).isBefore(new Date(min_date.date)) === true) {
            //if true then the class start date will change
            await directus.updateItem('subject_classes', id, { start_date: moment(new Date(body.date)).format("YYYY-MM-DD") });
            EventEmitter.emit('update_schedule', { update: true });
          }

          //get max date
          var max_date = linq.from(dataScheduleRef.current)
            .maxBy(data => data.date);


          //check if the new date changed later than the latest date
          if (moment(new Date(body.date)).isAfter(new Date(max_date.date)) === true) {
            //if true then the class end date will change into new data
            await directus.updateItem('subject_classes', id, { end_date: moment(body.date).format("YYYY-MM-DD") });
            EventEmitter.emit('update_schedule', { update: true });
          }

          //check if the new date changed is same with the latest date
          if (dataUpdate.id === max_date.id) {
            //check if the new date changed is earlier than the last two
            if (moment(new Date(body.date)).isBefore(new Date(dataScheduleRef.current[dataScheduleRef.current.length - 2].date)) === true) {
              //if true than the class end date will change into the last two date
              await directus.updateItem('subject_classes', id, { end_date: moment(new Date(dataScheduleRef.current[dataScheduleRef.current.length - 2].date)).format("YYYY-MM-DD") });
              EventEmitter.emit('update_schedule', { update: true });
            } else {
              //if false than the class end date will change into new data
              await directus.updateItem('subject_classes', id, { end_date: moment(new Date(body.date)).format("YYYY-MM-DD") });
              EventEmitter.emit('update_schedule', { update: true });
            }
          }

          if (!result.error) {
            //if there is a date change then re-generate the topic
            if (dataUpdate.date_change) {
              generateTopic();
            } else {
              getData();
              setError({ ...error, date_change: false, time_change: false, duration: false });
              setDataButton("edit_schedule");
            }
          }

        } else {
          if (dataUpdate.time_change === "Invalid date" || error.time_change) { setError({ ...errorRef.current, time_change: true }) }
          if (!dataUpdate.duration || error.duration) { setError({ ...errorRef.current, duration: true }) }
          setLoadingButton(false);
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        setLoadingButton(false);
      }
    }
  }

  // {/* ================== Date Change Schedule ========================================== */ }

  //set selected schedule to remove
  const handleOpenDialogDateChangeSchedule = (props) => {
    if (props) {
      setSelectedData({ ...selectedData, id: props.schedule.id, date: props.schedule.date })
    }
    dialogDateChangeSchedule.current.visible = true;
  }

  const handleCloseDialogDateChangeSchedule = () => {
    dialogDateChangeSchedule.current.visible = false;
  }

  //remove schedule
  const dateChangeSchedule = async () => {
    setLoadingButton(true);
    try {
      let selectedSchedule = [];

      //get schedule data from selected schedule
      var status = false;
      //eslint-disable-next-line array-callback-return
      dataScheduleRef.current.map((value) => {
        if (value.id === selectedData.id) {
          status = true;
        }
        if (status === true) {
          selectedSchedule.push(value);
        }
      })

      let newSchedule = [];
      //change the schedule
      //eslint-disable-next-line array-callback-return
      selectedSchedule.map((value, index) => {
        //check if it is not the last schedule
        if (index !== selectedSchedule.length - 1) {
          //if it is not then change the schedule with the schedule after
          var newData = selectedSchedule[index + 1];
          let body = {
            id: value.id,
            teachers_id: newData.teachers_id.id,
            date: moment(newData.date).format("YYYY-MM-DD"),
            time: newData.time,
            duration: newData.duration,
            room: newData.room.id
          }
          newSchedule.push(body);
          //if it is the last schedule
        } else if (index === selectedSchedule.length - 1) {
          let weekName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

          //get the day from selected date (Sunday = 0, Monday = 1, ..., Saturday = 6)
          var num = new Date(value.date).getDay();

          var data_meetings = [];
          //order the meetings data based on weekname
          var orderMeetings = linq.from(data.meetings)
            .orderBy(o => weekName.indexOf(o.meetings_id.weekday))
            .toArray();

          //eslint-disable-next-line array-callback-return
          orderMeetings.map((value, index) => {
            //get meeting weekname
            var week = value.meetings_id.weekday;

            //check if the number of meetings is only one
            if (orderMeetings.length === 1) {
              //if true then get this meeting data
              data_meetings.push(value);
              //if the number of meetings in more than one
            } else {
              //check if the meeting weekname same with the day from selected date
              if (weekName.indexOf(week) === num) {
                //if true then check if the selected meeting is not in the last data
                if (index !== orderMeetings.length - 1) {
                  //if true then get the next meeting data
                  data_meetings.push(orderMeetings[index + 1]);
                } else {
                  //if false then get the earlist meeting data
                  data_meetings.push(orderMeetings[0]);
                }
                //if the meeting weekname different with the day from selected date
              } else {
                //check if the selected meeting is not in the last data
                if (index !== orderMeetings.length - 1) {
                  //if true then check if the selected date is between two meetings (this meeting and next meeting data)
                  if (weekName.indexOf(orderMeetings[index].meetings_id.weekday) < num && num < weekName.indexOf(orderMeetings[index + 1].meetings_id.weekday)) {
                    //if true then get the next meeting data
                    data_meetings.push(orderMeetings[index + 1]);
                  }
                  //if the selected meeting is in the last data
                } else if (index === orderMeetings.length - 1) {
                  //if true then get the earlist meeting data
                  data_meetings.push(orderMeetings[0]);
                }
              }
            }
          })

          //get the day of week of selected meeting weekname and adjust it with the weekname of the available meeting
          var dayOfWeek = weekName.indexOf(data_meetings[0].meetings_id.weekday);
          var newDate = new Date(value.date);
          var addDay = (dayOfWeek + 7 - newDate.getDay()) % 7;
          if (addDay === 0) { addDay = 7; }
          //set the new date for the last data
          newDate.setDate(newDate.getDate() + addDay);

          let body = {
            id: value.id,
            teachers_id: data_meetings[0].meetings_id.teacher.id,
            date: moment(newDate).format("YYYY-MM-DD"),
            time: data_meetings[0].meetings_id.time,
            duration: data_meetings[0].meetings_id.duration,
            room: data_meetings[0].meetings_id.room.id,
          }
          newSchedule.push(body);
        }
      })

      //update all schedule
      await directus.updateItems('schedules', newSchedule);

      //update the last date into subject class end date
      var body2 = {
        end_date: moment(new Date(newSchedule[newSchedule.length - 1].date)).format("YYYY-MM-DD")
      }
      var result_subject_class = await directus.updateItem('subject_classes', id, body2);
      if (!result_subject_class.error) {
        getData();
        setDataButton("date_change_schedule")
      }

    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }


  // {/* ================== Delete Schedule ========================================== */ }
  //set selected schedule to delete
  const handleRemoveSchedule = (props) => {
    if (props) {
      setSelectedData({ ...selectedData, id: props.schedule.id, date: props.schedule.date })
    }
    dialogDeleteSchedule.current.visible = true;
  }

  const handleCloseDialogDeleteSchedule = () => {
    dialogDeleteSchedule.current.visible = false;
  }

  const deleteSchedule = async () => {
    setLoadingButton(true);
    try {
      //get all attendance with selected schedule
      var result = await directus.getItems('attendances', {
        filter: {
          schedules_id: selectedData.id
        }
      })

      var attendances_id = linq.from(result.data)
        .select(data => data.id)
        .toArray();

      if (attendances_id.length > 0) {
        //delete attendances
        await directus.deleteItems('attendances', attendances_id);
      }

      //delete schedule
      var result2 = await directus.deleteItem('schedules', selectedData.id)

      //get max date
      var max_date = linq.from(dataScheduleRef.current)
        .maxBy(data => data.date);

      //check if the selected date is in the last data
      if (selectedData.id === max_date.id) {
        var body = {
          end_date: moment(new Date(dataScheduleRef.current[dataScheduleRef.current.length - 2].date)).format("YYYY-MM-DD")
        }
        await directus.updateItem('subject_classes', id, body);
        EventEmitter.emit('update_schedule', { update: true });
      }

      if (!result2.error) {
        getData();
        setDataButton("delete_schedule")
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Attendance Status ========================================== */ }
  //make student attendance into present
  const studentPresent = async (item, status) => {
    try {
      const id = item.id;
      var result = await directus.updateItem('attendances', id, { student_attd: status });
      if (!result.error) {
        getData();
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Student Schedule Display ========================================== */ }

  //display all the dates based on specific subject class into table
  function getStudentsSchedules(props) {
    const list = [];

    var new_data = [];
    var group = [];


    //get all dates and months
    props.forEach((value) => {
      var newDate = new Date(value.date);

      //get the date and month from all the dates in the schedule and put them into array object
      group.push({
        date: newDate.getDate(),
        month: newDate.getMonth()
      })

      //get all the dates and id of the schedule and put them into array object
      new_data.push({
        newDate: newDate,
        schedule: value
      });
    })

    // count how many date in the month
    group = linq.from(group)
      .groupBy(x => x.month)
      .select(s => ({
        month: s.key(),
        count: s.count()
      }))
      .toArray();

    var elem = document.getElementById("class_summary");
    var number_of_table = 1;
    var listOfTable = [0];
    var addColumn = [];
    var total_of_data = new_data.length;
    var number_of_column = 0;
    var table_width = 0;

    var widthStudent = 250;
    if (window.devicePixelRatio >= 1.25) {
      widthStudent = (1 / window.devicePixelRatio) * 250;
    }
    var widthStudentPx = (window.devicePixelRatio / 1) * widthStudent;
    var widthSchedulePx = (window.devicePixelRatio / 1) * 40;

    // console.log(widthStudentPx)
    if (new_data) {
      if (elem) {
        table_width = widthStudentPx + (total_of_data * widthSchedulePx);
        var remaining_screen_size = 0;

        // console.log(elem.getBoundingClientRect())
        var div_width = elem.getBoundingClientRect().width;
        // console.log("div_width", div_width)
        if (div_width > widthStudentPx) {
          remaining_screen_size = div_width - widthStudentPx;
        }

        // console.log("remaining_screen_size", remaining_screen_size)

        if (table_width < remaining_screen_size || remaining_screen_size === 0) {
          //if table width is smaller than the remaining screen size or remaining screen size is 0
          number_of_table = 1;
          listOfTable = [0];
          addColumn.push(total_of_data);
        } else {

          number_of_column = parseInt(remaining_screen_size / widthSchedulePx);


          if (number_of_column > 0) {
            var number_of_row = total_of_data / number_of_column;

            if (total_of_data % number_of_column > 0) {
              number_of_table = parseInt(number_of_row) + 1;
            } else {
              number_of_table = parseInt(number_of_row);
            }
            // console.log("number_of_row", number_of_row)

            // console.log("number_of_column", number_of_column)
            // console.log("number_of_table", number_of_table)

            if (number_of_table > 1) {
              var remaining_number = total_of_data;

              listOfTable = [0];

              for (var i = 0; i < number_of_table - 1; i++) {
                if (remaining_number > number_of_column) {
                  remaining_number -= number_of_column;
                  listOfTable.push(number_of_column * (i + 1));
                  addColumn.push(number_of_column);
                } else {
                  listOfTable.push(total_of_data);
                  addColumn.push(remaining_number);
                }
              }
            }
          }
        }
      }
    }

    var new_group = [];
    var group2 = [];

    // console.log("list of table", listOfTable)
    // console.log("new data", new_data)

    if (listOfTable.length > 1) {
      /* eslint-disable array-callback-return*/
      listOfTable.map(() => {
        new_group.push([]);
        group2.push([]);
      })

      listOfTable.map((valueList, indexList) => {
        new_data.map((new_value, new_index) => {
          if (listOfTable.length - 1 !== indexList) {
            if (indexList === 0) {
              if (new_index < listOfTable[indexList + 1]) {
                group2[indexList].push({
                  date: new_value.newDate.getDate(),
                  month: new_value.newDate.getMonth()
                })
                new_group[indexList].push(new_value);
              }
            } else if (indexList > 0) {
              if (new_index > listOfTable[indexList] - 1 && new_index < listOfTable[indexList + 1]) {
                group2[indexList].push({
                  date: new_value.newDate.getDate(),
                  month: new_value.newDate.getMonth()
                })
                new_group[indexList].push(new_value);
              }
            }
          } else {
            if (new_index > listOfTable[indexList] - 1) {
              group2[indexList].push({
                date: new_value.newDate.getDate(),
                month: new_value.newDate.getMonth()
              })
              new_group[indexList].push(new_value);
            }
          }
        })
      })

      listOfTable.map((valueList, indexList) => {
        group2[indexList] = linq.from(group2[indexList])
          .groupBy(x => x.month)
          .select(s => ({
            month: s.key(),
            count: s.count()
          }))
          .toArray();
      })
    } else {
      new_group[0] = new_data;
      group2[0] = group;
    }

    new_group.map((valueTable, indexTable) => {

      var new_width = 0;
      if (new_group.length - 1 === indexTable) {
        new_width = widthStudentPx + (valueTable.length * widthSchedulePx);
      } else {
        new_width = widthStudentPx + (new_group[0].length * widthSchedulePx)
      }
      /* eslint-enable array-callback-return*/

      list.push(
        <div key={`schedule_` + indexTable}>
          <table className="table_subject_class_schedule" id={`schedule_` + indexTable}
            width={`${new_width}px`}
            style={{ textAlign: "center", border: "1px solid #f4f4f4" }}
          >
            <thead>
              <tr>
                <td rowSpan="2" colSpan="3" style={{ verticalAlign: "top", padding: "5px" }}><span style={{ padding: "3px", fontWeight: "800" }}>No.</span></td>
                {/* the number of columns for dates */}
                {valueTable.map((item, index) => (
                  <td key={index} width="40px" style={{ padding: "5px" }}>
                    <span style={{ wordBreak: "break-word", padding: "3px", fontWeight: "800" }}>
                      {listOfTable[indexTable] + index + 1}
                    </span>
                  </td>
                )
                )}
              </tr>
              <tr>
                {/* display all the months */}
                {group2[indexTable].map((item, index) => (
                  <td key={index} colSpan={item.count} style={{ padding: "5px" }}><span style={{ wordBreak: "break-word", fontWeight: "800" }}>{monthNames[item.month]}</span></td>
                ))}
              </tr>
              <tr >
                <td colSpan="3" width={`${widthStudent}px`} style={{ padding: "5px" }}><span style={{ fontWeight: "800" }}>{Students}</span></td>

                {valueTable.map((item) => (
                  <td key={item.schedule.id} style={{
                    backgroundColor: item.schedule.holiday === true ? "red" : parseInt(item.schedule.test) === 1 ? "#78efff" : parseInt(item.schedule.review) === 1 ? "#97f7c6" : null,
                    textAlign: "center"
                  }}>
                    <TooltipComponent content={permission.AdminPermission() ? Edit_Schedule : Schedule} position="TopCenter">
                      <div
                        style={{ width: "100%", cursor: "pointer", color: item.schedule.holiday === true ? "white" : "#3c8dbc" }}
                        onClick={() => handleOpenDialogEditSchedule(item)}>
                        <b>{item.newDate.getDate()}</b>
                      </div>
                    </TooltipComponent>
                  </td>
                ))}
              </tr>
              {/* display all the students */}
              {dataStudentSubjectClassRef.current.map((item, index) => (
                <tr key={index}>
                  {/* <td width={`${widthStudent * 0.12}px`} width="30px"> */}
                  <td width={`${widthStudent * 0.12}px`} style={{ fontWeight: "800", padding: "5px" }}>
                    <span style={{ wordBreak: "break-word", padding: "3px" }}>{index + 1}</span>
                  </td>
                  {permission.AdminPermission() ?
                    <td width={`${widthStudent * 0.6}px`} style={{ textAlign: "left" }}>
                      <Link to={{ pathname: `/student/profile/${item.students_id.id}` }} style={{ textDecoration: "none" }}>
                        <span
                          style={{ padding: "3px", wordBreak: "break-word", cursor: "pointer", color: "#3c8dbc", fontWeight: "550", textTransform: "uppercase" }}
                        >
                          {item.students_id.nickname ? item.students_id.nickname : item.students_id.name}
                        </span>
                      </Link>
                    </td>
                    : null}

                  {permission.AdminPermission() ?
                    <td width={`${widthStudent * 0.28}px`}>
                      <div style={{ padding: "3px", wordBreak: "break-word", display: "flex" }}>
                        {/* <ButtonComponent cssClass='e-link' style={{ color: "#3c8dbc" }} onClick={() => alert("update later")}><span className='e-icons icon-edit-icon1'></span></ButtonComponent> */}
                        <div className="e-icons icon-edit-icon1"
                          style={{ cursor: "pointer", color: "#3c8dbc", width: "50%" }}
                          onClick={() => handleOpenDialogStudentClassList(item)}
                        ></div>
                        <div className="e-icons icon-close"
                          style={{ cursor: "pointer", color: "#3c8dbc", width: "50%" }}
                          onClick={() => handleOpenDialogDeleteStudent(item)}
                        ></div>
                      </div>
                    </td>
                    : null}

                  {!permission.AdminPermission() ?
                    <td width={`${widthStudent * 0.34}px`} style={{ textAlign: "left" }} colSpan="2">
                      <Link to={{ pathname: `/student/profile/${item.students_id.id}` }} style={{ textDecoration: "none" }}>
                        <span
                          style={{ padding: "3px", wordBreak: "break-word", cursor: "pointer", color: "#3c8dbc", fontWeight: "550", textTransform: "uppercase" }}
                        >
                          {item.students_id.nickname ? item.students_id.nickname : item.students_id.name}
                        </span>
                      </Link>
                    </td>
                    : null}

                  {/* display all the attendances of every student based on the date  */}
                  {valueTable.map((item2, index2) => (
                    <td key={index2} style={{ wordBreak: "break-word" }}>
                      {permission.AdminPermission() ?
                        <div style={{ minWidth: "30px" }}>
                          {item2.schedule.attendances[index] ? item2.schedule.attendances[index].student_attd ?
                            item2.schedule.attendances[index].student_attd === 'Present' ?
                              <div style={{ color: "green", fontSize: "15px", cursor: "pointer" }} onClick={() => studentPresent(item2.schedule.attendances[index], null)} className="e-icons icon-check-mark-01"></div>
                              :
                              item2.schedule.attendances[index].student_attd === 'Excused' || item2.schedule.attendances[index].student_attd === 'Personal Leave' || item2.schedule.attendances[index].student_attd === 'Absent'
                                ?
                                <div style={{ color: "red", fontSize: "15px", cursor: "not-allowed" }} className="e-icons icon-close"></div>
                                : <div style={{ color: "lightgray", fontSize: "15px", cursor: "pointer" }} onClick={() => studentPresent(item2.schedule.attendances[index], "Present")} className="e-icons icon-check-mark-01"></div>
                            : <div style={{ color: "lightgray", fontSize: "15px", cursor: "pointer" }} onClick={() => studentPresent(item2.schedule.attendances[index], "Present")} className="e-icons icon-check-mark-01"></div> : ''}
                        </div>
                        :
                        <div style={{ minWidth: "30px" }}>
                          {item2.schedule.attendances[index] ? item2.schedule.attendances[index].student_attd ?
                            item2.schedule.attendances[index].student_attd === 'Present' ?
                              <div style={{ color: "green", fontSize: "15px" }} className="e-icons icon-check-mark-01"></div>
                              :
                              item2.schedule.attendances[index].student_attd === 'Excused' || item2.schedule.attendances[index].student_attd === 'Personal Leave' || item2.schedule.attendances[index].student_attd === 'Absent'
                                ?
                                <div style={{ color: "red", fontSize: "15px", cursor: "not-allowed" }} className="e-icons icon-close"></div>
                                : <div style={{ color: "lightgray", fontSize: "15px" }} className="e-icons icon-check-mark-01"></div>
                            : <div style={{ color: "lightgray", fontSize: "15px" }} className="e-icons icon-check-mark-01"></div> : ''}
                        </div>
                      }
                    </td>
                  ))}
                </tr>
              ))}

              {dataStudentSubjectClassRef.current.length === 0 ?
                <tr key="no_data">
                  {/* <td width={`${widthStudent * 0.12}px`} width="30px"></td> */}
                  <td width={`${widthStudent * 0.12}px`}></td>
                  <td width={`${widthStudent * 0.6}px`} style={{ textAlign: "left" }}></td>
                  <td width={`${widthStudent * 0.28}px`}></td>
                  {valueTable.map((item2, index) => (
                    <td key={index}></td>
                  ))}
                </tr>
                : null}

            </thead>
          </table>
          {permission.AdminPermission() ? indexTable === new_group.length - 1 ? <div style={{ marginTop: "10px" }}><ButtonComponent iconCss='e-icons icon-plus' className='e-link' style={{ color: "#3c8dbc" }} onClick={() => handleOpenDialogAddStudent()}><b>{Student}</b></ButtonComponent></div> : <br /> : <br />}
        </div >
      )
    }
    )

    // list.push(
    //   <span>
    //     <ButtonComponent iconCss='e-icons icon-plus' className='e-link' style={{ color: "#3c8dbc" }} onClick={() => handleOpenDialogAddStudent()}><b>{Student}</b></ButtonComponent>
    //   </span>
    // )


    return (<div>{list}</div>)
  }

  const onCreated = () => {
    // console.log(document.getElementById("table_subject_class_schedule"))
    // var elem = document.getElementById("table_subject_class_schedule");
    // console.log(window.innerWidth)
    // console.log(window)
    // console.log(elem.getBoundingClientRect())
    // if (elem.getBoundingClientRect().width > window.innerWidth) {
    //   console.log(elem.style)
    //   elem.style.background = "red"
    // }
  }

  // {/* ================== List of Schedule Display ========================================== */ }
  //display all the dates based on specific subject class into table
  function getListSchedule() {
    const list = [];
    var data_schedule = [];

    //get all dates and months
    if (dataTeacherRef.current) {
      dataScheduleRef.current.forEach((value) => {
        var teacher = linq.from(dataTeacherRef.current)
          .where(data => data.id === value.teachers_id.id)
          .toArray();

        data_schedule.push({
          schedule: value,
          teacher: teacher[0]
        })
      })
    }

    list.push(
      <div key="schedule-list">
        <GridComponent
          width="100%"
          dataSource={data_schedule}
          ref={grid}
          created={onCreated}
          allowTextWrap={true}
          textWrapSettings={{ wrapMode: "Content" }}
          allowResizing={true}
          gridLines='Both'
          allowEditing={false}
          selectionSettings={{ mode: 'Both' }}
          clipMode="Ellipsis"
        >
          <ColumnsDirective>
            <ColumnDirective customAttributes={{ id: 'first' }} width="9%" textAlign="center" headerText='No' template={numTemplate}  ></ColumnDirective>
            <ColumnDirective width="25%" template={dateTemplate} editTemplate={dateTemplate} allowEditing={false} headerText={Date_2}></ColumnDirective>
            <ColumnDirective width="10%" template={teacherTemplate} editTemplate={teacherTemplate} allowEditing={false} headerText={Teacher}></ColumnDirective>
            <ColumnDirective width="31%" field='schedule.topic' headerText={Topic}></ColumnDirective>
            <ColumnDirective customAttributes={{ id: 'last' }} width="25%" field='schedule.note' headerText={Note}></ColumnDirective>
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Group, Resize]} />
        </GridComponent>
        <br />
      </div >
    )

    return (<div>{list}</div>)
  }

  // {/* ================== Data Grid Template ========================================== */ }

  const pushBackTooltipContent = () => {
    return (<div style={{ textAlign: "center" }}>{Push_Back}<br />{Schedule2}</div>)
  }

  //num template and add date change schedule symbol
  const numTemplate = (props) => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center", justifyContent: "center" }}>
        <div>{parseInt(props.index) + 1}</div>
        {permission.AdminPermission() ?
          <TooltipComponent content={pushBackTooltipContent} position="BottomCenter" style={{ marginLeft: "10px" }}>
            <div className="e-icons icon-MT_DownArrow"
              style={{ cursor: "pointer", color: "#3c8dbc" }}
              onClick={() => handleOpenDialogDateChangeSchedule(props)}
            />
          </TooltipComponent>
          : null}
      </div>
    )
  }

  const dateTemplate = (props) => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center", backgroundColor: parseInt(props.schedule.test) === "1" ? "#78efff" : parseInt(props.schedule.review) === 1 ? "#97f7c6" : null }}>
        {permission.AdminPermission() ?
          <TooltipComponent content={Delete} position="BottomCenter">
            <div className="e-icons icon-close"
              style={{ padding: "10px", cursor: "pointer", color: "#3c8dbc" }}
              onClick={() => handleRemoveSchedule(props)}
            />
          </TooltipComponent>
          : null}
        <TooltipComponent content={permission.AdminPermission() ? Edit_Schedule : Schedule} position="BottomCenter" style={{ marginLeft: permission.AdminPermission() ? "10px" : "0px" }}>
          <div style={{ cursor: "pointer", color: "#3c8dbc" }}
            onClick={() => handleOpenDialogEditSchedule(props)}>
            {getWeek(moment(props.schedule.date).format("dddd"))}, {moment(props.schedule.date).format("DD-MMM-YYYY")}
          </div>
        </TooltipComponent>
      </div>
    )
  }

  const teacherTemplate = (props) => {
    return (
      <span>
        {props.schedule.teacher_attd === "Present" ?
          <span style={{ padding: "5px", color: "green" }}><b>{props.teacher.nickname}</b></span>
          :
          <span style={{ padding: "5px", color: "gray" }}>{props.teacher.nickname}</span>
        }
      </span>
    )
  }

  //translate the week name
  const getWeek = (props) => {
    var week2 = props ? props : null;
    if (weeks) {
      weeks.forEach((value) => {
        if (value.id === week2) {
          week2 = value.name
        }
      })
    }
    return "" + week2
  }


  // {/* ================== Handle Topic Dropdown ========================================== */ } 

  const handleTopic = (e, type) => {
    //check if get the topic from list is checked
    if (type === "list") {
      setStateTopic({ ...stateTopicRef.current, list: e.checked === true ? 1 : 0 })
    } else if (type === "topic") {
      var value = e.itemData.subject_schedule_id.topic_description;
      setStateEditSchedule({
        ...stateEditScheduleRef.current, topic: value
      })
    }
  }

  //open topic list dropdown
  const onOpen = (args) => {
    const elem = args.popup.element.parentElement;
    elem.style.top = topic_dd.current.element.getBoundingClientRect().top - elem.offsetHeight + 'px';
  }

  // {/* ================== Generate Topic ========================================== */ } 

  //generate topic from topic list in subject schedule
  const generateTopic = async () => {
    try {
      //get all schedule
      var result_schedule = await directus.getItems('schedules', {
        fields: '*.*, subject_classes_id.subjects.*.*.*',
        filter: {
          subject_classes_id: id
        },
        sort: 'date'
      });

      //get all subject schedule (topic)
      var topic = result_schedule.data[0].subject_classes_id.subjects.subject_schedule;
      let dataSchedule = [];

      //insert the topic into the selected schedule based on the date order
      //eslint-disable-next-line array-callback-return
      result_schedule.data.map((date, index_date) => {
        var select_topic = null
        if (topic.length > 0) {
          select_topic = linq.from(topic)
            .where(w => w.subject_schedule_id.sort === index_date + 1)
            .toArray();
        }

        let body = {
          id: date.id,
          topic: select_topic ? select_topic.length > 0 ? select_topic[0].subject_schedule_id.topic_description : null : null,
          test: select_topic ? select_topic.length > 0 ? select_topic[0].subject_schedule_id.test : false : false,
          review: select_topic ? select_topic.length > 0 ? select_topic[0].subject_schedule_id.review : false : false,
        }
        dataSchedule.push(body)
      })

      var result = await directus.updateItems('schedules', dataSchedule);

      if (!result.error) {
        getData();
        setDataButton("edit_schedule");
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Footer Template ========================================== */}

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }

  return (
    <div style={{ margin: "20px 10px 10px" }}>
      {loadingSchedule ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", marginLeft: "50%" }} />
        :
        <div>
          {dataStudentSubjectClassRef.current ?
            <div>
              <div id="student_schedule" style={{ alignContent: "center", color: "#333", paddingBottom: "10px" }}>
                {/* display all dates data  */}
                {getStudentsSchedules(dataScheduleRef.current)}

                <hr style={{ marginTop: "15px", marginBottom: "10px", borderTop: "2px solid #eee" }} />
                <div>
                  <div className="row" style={{ display: 'flex', width: "100%", margin: "0 0 10px 0", height: "30px", lineHeight: "30PX" }}>
                    <div className="column" style={{ width: '50%' }}><span style={{ fontSize: "18px", fontWeight: "600" }}>{Schedule}</span></div>
                    <div className="column" style={{ width: '50%', textAlign: "right" }}>{props.class_name}</div>
                  </div>

                  {/* display list for schedule  */}
                  {getListSchedule()}

                </div>
              </div>

              <DialogComponent
                id="dialogEditSchedule"
                width="40%"
                showCloseIcon={!loadingButton ? true : false}
                visible={false}
                ref={dialogEditSchedule}
                header={permission.AdminPermission() ? Edit_Schedule : Schedule}
                isModal={true}
                footerTemplate={() => permission.AdminPermission() ? footerTemplate([
                  { name: Update, func: editSchedule, icon: "icon-check-mark-01", status: "e-success" },
                  { name: Cancel, func: handleCloseDialogEditSchedule, icon: "icon-treeview", status: "" }
                ])
                  :
                  null
                }
                close={handleCloseDialogEditSchedule}
              >
                <div>
                  {stateEditScheduleRef.current ?
                    <div>
                      <b>{Class}</b>
                      <DropDownListComponent
                        dataSource={dataSubject}
                        name="class"
                        cssClass="e-outline"
                        enabled={false}
                        fields={fields}
                        value={stateEditScheduleRef.current.subject_classes_id.subjects.id}
                      />
                      <br /><br />
                      <b>{Early_Teacher}</b>
                      <DropDownListComponent
                        dataSource={dataTeacher}
                        name="teacher"
                        cssClass="e-outline"
                        placeholder={`--${Select_Teacher}--`}
                        filtering={onFilteringTeacher}
                        allowFiltering={true}
                        fields={{ text: 'nickname', value: 'id' }}
                        enabled={false}
                        select={(e) => handleChangeEditSchedule(e, "teachers_id")}
                        value={stateEditScheduleRef.current.previous_teacher ? stateEditScheduleRef.current.previous_teacher.id : stateEditScheduleRef.current.teachers_id.id}
                      />
                      <br /><br />
                      <b>{Teacher}</b>
                      <DropDownListComponent
                        dataSource={dataTeacher}
                        name="teacher"
                        cssClass="e-outline"
                        placeholder={`--${Select_Teacher}--`}
                        filtering={onFilteringTeacher}
                        allowFiltering={true}
                        enabled={permission.AdminPermission() ? true : false}
                        fields={{ text: 'nickname', value: 'id' }}
                        select={(e) => handleChangeEditSchedule(e, "teacher_change")}
                        value={stateEditScheduleRef.current.teachers_id ?? stateEditScheduleRef.current.teachers_id.id}
                      />
                      <br /><br />

                      <b>{Room}</b>
                      <DropDownListComponent
                        dataSource={dataRoom}
                        name="room"
                        cssClass="e-outline"
                        placeholder={`--${Select_Room}--`}
                        filtering={onFilteringRoom}
                        allowFiltering={true}
                        enabled={permission.AdminPermission() ? true : false}
                        fields={fields}
                        select={(e) => handleChangeEditSchedule(e, "room")}
                        value={stateEditScheduleRef.current.room ? stateEditScheduleRef.current.room.id : stateEditScheduleRef.current.room}
                      />
                      <br /><br />
                      <b>*{Date_2}</b>
                      <DatePickerComponent
                        cssClass="e-outline"
                        name="date"
                        // enabled={permission.AdminPermission() ? true : false}
                        format="dd-MMM-yyyy"
                        change={(e) => handleChangeEditSchedule(e, "date_change")}
                        value={stateEditScheduleRef.current.date}
                      >
                      </DatePickerComponent>
                      {error.date_change === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                      <br /><br />
                      <b>*{Time}</b>
                      <TimePickerComponent
                        name="time"
                        cssClass="e-outline"
                        enabled={permission.AdminPermission() ? true : false}
                        step={30}
                        format={'HH:mm'}
                        change={(e) => handleChangeEditSchedule(e, "time_change")}
                        value={stateEditScheduleRef.current.time}
                      />
                      {error.time_change === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                      <br /><br />
                      <b>*{Duration}</b>
                      <NumericTextBoxComponent
                        cssClass="e-outline"
                        name="duration"
                        enabled={permission.AdminPermission() ? true : false}
                        placeholder='0'
                        format='####'
                        min={0}
                        change={(e) => handleChangeEditSchedule(e, "duration")}
                        value={stateEditScheduleRef.current.duration ? stateEditScheduleRef.current.duration : 60}
                      />
                      {error.duration === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                      <br /><br />
                      <b>{Topic}</b>
                      <TextBoxComponent
                        name="topic"
                        cssClass="e-outline"
                        enabled={permission.AdminPermission() ? true : false}
                        multiline={true}
                        change={(e) => handleChangeEditSchedule(e, "topic")}
                        value={stateEditScheduleRef.current.topic}
                      />
                      {permission.AdminPermission() ?
                        <div>
                          <br />
                          <CheckBoxComponent
                            checked={parseInt(stateTopicRef.current.list) === 1 ? true : false}
                            label={Get_Topic_from_List}
                            change={(e) => handleTopic(e, "list")} />
                        </div>
                        : null}
                      {parseInt(stateTopicRef.current.list) === 1 ?
                        <div>
                          <b>{Topic_List}</b>
                          <DropDownListComponent
                            ref={topic_dd}
                            dataSource={dataSchedule[0].subject_classes_id.subjects.subject_schedule}
                            name="list"
                            cssClass="e-outline"
                            placeholder={`--${Select_Topic}--`}
                            open={onOpen}
                            // filtering={onFilteringRoom}
                            allowFiltering={true}
                            fields={{ text: 'subject_schedule_id.topic_description', value: 'id' }}
                            select={(e) => handleTopic(e, "topic")}
                          />

                        </div>
                        : null}
                      <br /><br />
                      <CheckBoxComponent
                        checked={parseInt(stateEditScheduleRef.current.test) === 1 ? true : false}
                        label={Test}
                        enabled={permission.AdminPermission() ? true : false}
                        change={(e) => handleChangeEditSchedule(e, "test")} />
                      <br /><br />
                      <CheckBoxComponent
                        checked={parseInt(stateEditScheduleRef.current.review) === 1 ? true : false}
                        label='Review'
                        enabled={permission.AdminPermission() ? true : false}
                        change={(e) => handleChangeEditSchedule(e, "review")} />
                      <br /><br />
                      <b>{Note}</b>
                      <TextBoxComponent
                        name="topic"
                        cssClass="e-outline"
                        enabled={permission.AdminPermission() ? true : false}
                        multiline={true}
                        change={(e) => handleChangeEditSchedule(e, "note")}
                        value={stateEditScheduleRef.current.note}
                      />
                    </div>
                    : ''}
                </div>
              </DialogComponent>

              {/* Delete Schedule  */}
              <DialogComponent
                id="dialogDeleteSchedule"
                width='20%'
                showCloseIcon={!loadingButton ? true : false}
                visible={false}
                ref={dialogDeleteSchedule}
                isModal={true}
                header={Delete_Confirmation}
                footerTemplate={() => footerTemplate([
                  { name: Delete, func: deleteSchedule, icon: "icon-delete-05", status: "e-warning" },
                  { name: Cancel, func: handleCloseDialogDeleteSchedule, icon: "icon-treeview", status: "" }
                ])}
                close={handleCloseDialogDeleteSchedule}
              >
                <div>{Are_you_sure_you_want_to_delete_this_schedule}?</div>
              </DialogComponent>


              {/* ================== Date Change Schedule Dialog ========================================== */}

              <DialogComponent
                id="dialogDateChangeSchedule"
                width='25%'
                showCloseIcon={!loadingButton ? true : false}
                visible={false}
                ref={dialogDateChangeSchedule}
                isModal={true}
                header={Date_Change_Confirmation}
                footerTemplate={() => footerTemplate([
                  { name: Update, func: dateChangeSchedule, icon: "icon-check-mark-01", status: "e-success" },
                  { name: Cancel, func: handleCloseDialogDateChangeSchedule, icon: "icon-treeview", status: "" }
                ])}
                close={handleCloseDialogDateChangeSchedule}
              >
                <div>{Are_you_sure_you_want_to_change_the_schedule}?</div>
              </DialogComponent>

              {/* ================== New Student Enrollment Dialog ========================================== */}

              <DialogComponent
                id="dialogAddStudent"
                width="40%"
                showCloseIcon={!loadingButton ? true : false}
                visible={false}
                ref={dialogAddStudent}
                isModal={true}
                header={Student_Class_Enrollment}
                footerTemplate={() => footerTemplate([
                  { name: Update, func: addStudent, icon: "icon-check-mark-01", status: "e-success" },
                  { name: Cancel, func: handleCloseDialogAddStudent, icon: "icon-treeview", status: "" }
                ])}
                close={handleCloseDialogAddStudent}>
                <div>
                  <div>
                    <b>{Class}</b>
                    <DropDownListComponent
                      dataSource={dataSubject}
                      name="class"
                      cssClass="e-outline"
                      enabled={false}
                      fields={fields}
                      value={data.subjects.id}
                    />
                    <br /><br />
                    <b>*{Student}</b>
                    <DropDownListComponent
                      dataSource={dataStudentNoClass}
                      name="student"
                      cssClass="e-outline"
                      placeholder={`--${Select_Student}--`}
                      filtering={onFiltering}
                      allowFiltering={true}
                      fields={{ text: 'nickname', value: 'id' }}
                      select={(e) => handleAddStudent(e)}
                      value={state ? state.students_id : null}
                    />
                    {error.student === true ? <span style={{ color: 'red' }}>{Please_select_student}</span> : ''}
                  </div>
                </div>
              </DialogComponent>

              {/* ================== Edit Student Class List Dialog ========================================== */}
              <DialogComponent
                id="dialogStudentClassList"
                width="40%"
                showCloseIcon={!loadingButton ? true : false}
                visible={false}
                ref={dialogStudentClassList}
                isModal={true}
                header={Student_Class_List}
                footerTemplate={() => footerTemplate([
                  { name: Update, func: updateStudentClassList, icon: "icon-check-mark-01", status: "e-success" },
                  { name: Cancel, func: handleCloseDialogStudentClassList, icon: "icon-treeview", status: "" }
                ])}
                close={handleCloseDialogStudentClassList}
              >
                <div>
                  <div>
                    <b>{Class}</b>
                    <DropDownListComponent
                      dataSource={dataSubject}
                      name="class"
                      cssClass="e-outline"
                      enabled={false}
                      fields={fields}
                      value={data.subjects.id}
                    />
                    <br /><br />
                    <b>{Student}</b>
                    <TextBoxComponent
                      name="name"
                      cssClass="e-outline"
                      enabled={false}
                      value={stateEditStudentRef.current.students_id ? stateEditStudentRef.current.students_id.name : null}
                    />
                    <br /><br />
                    <b>{Registration_Fee}</b>
                    <NumericTextBoxComponent
                      cssClass="e-outline"
                      name="registration_fee"
                      placeholder='IDR 0'
                      format='IDR #########'
                      min={0}
                      change={(e) => handleChangeStudentClassList(e, "registration_fee")}
                      value={stateEditStudentRef.current ? stateEditStudentRef.current.registration_fee : 0}
                    />
                    <br /><br />
                    <b>{Registration_Discount}</b>
                    <NumericTextBoxComponent
                      cssClass="e-outline"
                      name="registration_discount"
                      placeholder='IDR 0'
                      format='IDR #########'
                      min={0}
                      change={(e) => handleChangeStudentClassList(e, "registration_discount")}
                      value={stateEditStudentRef.current ? stateEditStudentRef.current.registration_discount : 0}
                    />
                    <br /><br />
                    <b>{Monthly_Fee}</b>
                    <NumericTextBoxComponent
                      cssClass="e-outline"
                      name="monthly_fee"
                      placeholder='IDR 0'
                      format='IDR #########'
                      min={0}
                      change={(e) => handleChangeStudentClassList(e, "monthly_fee")}
                      value={stateEditStudentRef.current ? stateEditStudentRef.current.monthly_fee : 0}
                    />
                    <br /><br />
                    <b>{Monthly_Discount}</b>
                    <NumericTextBoxComponent
                      cssClass="e-outline"
                      name="monthly_discount"
                      placeholder='IDR 0'
                      format='IDR #########'
                      min={0}
                      change={(e) => handleChangeStudentClassList(e, "monthly_discount")}
                      value={stateEditStudentRef.current ? stateEditStudentRef.current.monthly_discount : 0}
                    />
                    <br /><br />
                    <b>{Lesson_Fee}</b>
                    <NumericTextBoxComponent
                      cssClass="e-outline"
                      name="lesson_fee"
                      placeholder='IDR 0'
                      format='IDR #########'
                      min={0}
                      change={(e) => handleChangeStudentClassList(e, "lesson_fee")}
                      value={stateEditStudentRef.current ? stateEditStudentRef.current.lesson_fee : 0}
                    />
                  </div>
                </div>
              </DialogComponent>

              {/* ================== Delete Student Dialog ========================================== */}

              <DialogComponent
                id="dialogDeleteStudent"
                width='30%'
                showCloseIcon={!loadingButton ? true : false}
                visible={false}
                ref={dialogDeleteStudent}
                isModal={true}
                header={Delete_Confirmation}
                footerTemplate={() => footerTemplate([
                  { name: Delete, func: deleteStudent, icon: "icon-delete-05", status: "e-warning" },
                  { name: Cancel, func: handleCloseDialogDeleteStudent, icon: "icon-treeview", status: "" }
                ])}
                close={handleCloseDialogDeleteStudent}
              >
                <div>{Are_you_sure_you_want_to_remove_this_student} ({stateEditStudentRef.current ? stateEditStudentRef.current.name : null}) {from_the_class}?</div>
              </DialogComponent>
            </div>
            : null}
        </div>
      }
    </div >
  )
}

export default Schedule;