import React, { useState, useEffect, useRef } from 'react';
import useStateRef from "react-usestateref";
import i18next from "i18next";

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  ColumnMenu,
  Edit,
  Toolbar,
  Page,
  Inject,
  Sort,
  Filter,
  Reorder
}
  from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

let cancelFlag = false;
const RoomList = () => {
  /* eslint-disable no-unused-vars*/
  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  const [Name] = useState(i18next.t('Pages.data.Name'));
  // const [Action] = useState(i18next.t('Pages.data.Action'));

  const [Enter_room_name] = useState(i18next.t('Pages.subject_class_data.Enter_room_name'));
  const [Rooms] = useState(i18next.t('Pages.subject_class_data.Rooms'));
  const [Room_2] = useState(i18next.t('Pages.subject_class_data.Room'));
  const [Are_you_sure_you_want_to_delete_the_data_of_room] = useState(i18next.t('Pages.subject_class_data.Are_you_sure_you_want_to_delete_the_data_of_room'));
  const [Room_created_successfully] = useState(i18next.t('Pages.subject_class_data.Room_created_successfully'));
  const [Room_deleted_successfully] = useState(i18next.t('Pages.subject_class_data.Room_deleted_successfully'));

  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  const editSettings = {
    allowEditing: true, allowAdding: true, allowDeleting: true,
    mode: 'Normal'
  };

  let dialogAddRoom = useRef(false);
  let dialogDeleteRoom = useRef(false);

  const grid = useRef();
  const validationRule = { required: true };
  const filterOptions = { type: 'Menu' };
  const setting = { mode: 'Both' };

  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    name: null
  });

  const [state, setState] = useState({
    name: null,
    status: "published"
  });

  const [error, setError] = useState({
    name: false
  });
  /* eslint-enable no-unused-vars*/

  const getInitialValue = () => {
    setState({
      name: null,
      status: "published"
    });
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "add_room") {
        dialogAddRoom.current.visible = false;
        setState({ name: null, status: "published" });
        EventEmitter.emit('alert_toast', { content: Room_created_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_room") {
        dialogDeleteRoom.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Room_deleted_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }


  const getData = async () => {
    try {
      var name = { "nnull": null };

      if (stateFiltering.name !== null && stateFiltering.name !== "") {
        name = { "contains": stateFilteringRef.current.name };
      }

      var result = await directus.getItems('rooms',
        {
          filter: {
            name: name,
            status: "published"
          },
          sort: "name"
        });
      setData(result.data);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Data Grid Template ========================================== */ }
  const buttonTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_hover" style={{ display: "flex" }}>
          <TooltipComponent content={Delete} position="BottomCenter">
            <span className="e-icons icon-delete-05"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => handleOpenDialogDeleteRoom(props)}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const buttonEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter" >
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const actionGrid = async (event) => {
    if (event.action === "edit" && event.requestType === "save") {
      if (cancelFlag === false) {
        const data = event.data;
        try {
          setLoadingButton(true);
          var result = await directus.updateItem('rooms', data.id, { name: data.name });

          var description = data.name;
          if (data.name !== event.previousData.name) {
            description = event.previousData.name + " --> " + data.name;
          }

          const body_activity = {
            action: "Update",
            category: 9,
            description: description,
            status: "published"
          }

          await directus.createItem('activity_log', body_activity);

          if (!result.error) {
            getData();
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          setLoadingButton(false);
        }
      } else {
        grid.current.editModule.closeEdit()
      }
      cancelFlag = false;

    } else if (event.requestType === "beginEdit") {
      cancelFlag = true;
    }
  }

  // {/* ================== Filtering ========================================== */ }

  const handleChangeFilter = (event, type) => {
    var value = "";
    if (type === "name") {
      value = event.value
    }

    setStateFiltering({ ...stateFiltering, [type]: value })

    if (event.syntheticEvent.type === 'change') {
      getData();
    }

  }

  const searchRoom = () => {
    getData();
  }

  // {/* ================== Handle Add Rooms ========================================== */ }

  const addRoom = async () => {
    try {
      setError({ name: false });
      if (state.name) {
        setLoadingButton(true);
        var result = await directus.createItem('rooms', state);

        const body_activity = {
          action: "Add",
          category: 9,
          description: state.name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          getData();
          setDataButton("add_room")
        }

      } else {
        setError({ name: true })
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  const handleCloseDialogAddRoom = () => {
    setError({ name: false });
    dialogAddRoom.current.visible = false;
    getInitialValue();
  }

  const handleOpenDialogAddRoom = () => {
    dialogAddRoom.current.visible = true;
  }

  const handleChange = (event, type) => {
    var value = event.value;
    setState({ ...state, [type]: value })
  }

  // {/* ================== Handle Dialog Remove Activity Type ========================================== */ }

  const handleOpenDialogDeleteRoom = (props) => {
    dialogDeleteRoom.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteRoom = () => {
    dialogDeleteRoom.current.visible = false;
  }

  const deleteRoom = async () => {
    //set teacher status from published to draft
    try {
      setLoadingButton(true);
      const body = {
        status: 'draft'
      }
      var result = await directus.updateItem('rooms', selectedData.id, body);

      const body_activity = {
        action: "Delete",
        category: 9,
        description: selectedData.name,
        status: "published"
      }
      await directus.createItem('activity_log', body_activity);

      if (!result.error) {
        getData();
        setDataButton("delete_room")
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }

  }

  const onColumnMenuOpen = (args) => {
    for (const item of args.items) {
      if (item.text === 'Autofit all columns' || item.text === 'Autofit this column') {
        item.hide = true;
      }
    }
  }

  const footerTemplateAddRoom = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-success e-flat" data-ripple="true" onClick={(e) => addRoom(e)}>
            <span className="e-btn-icon e-icons icon-check-mark-01 e-icon-left"></span>{Update}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogAddRoom()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  const footerTemplateDeleteRoom = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-warning e-flat" data-ripple="true" onClick={(e) => deleteRoom(e)}>
            <span className="e-btn-icon e-icons icon-delete-05 e-icon-left"></span>{Delete}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogDeleteRoom()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }
  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      cancelFlag = false;
      grid.current.endEdit();
    }
  }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Rooms}</div>
            <div className="content-searching hide-content">
              <div style={{ display: 'flex', width: "100%" }}>
                <div style={{ width: "300px", marginRight: "10px" }}>
                  <TextBoxComponent
                    cssClass="e-outline searching-text"
                    className="searching-input"
                    id="searching-input"
                    placeholder={Enter_room_name}
                    onChange={(e) => handleChangeFilter(e, "name")}
                    onBlur={(e) => searchRoom(e)}
                    showClearButton="true"
                    value={stateFiltering.name}
                  />
                </div>
                <div>
                  <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddRoom}>
                    <span className="btn-text-custom">{Room_2}</span>
                  </ButtonComponent>
                </div>
                {/* <br />
              {data ? data.length > 0 ? 'Total : ' + data.length : <br />
                : ''} */}
              </div>
            </div>
          </div>

          <div className="content-main">
            {data ? data.length > 0 ?
              <div style={{ marginTop: "25px", marginBottom: "20px" }}>
                <div className="grid-container">
                  <GridComponent
                    width="100%"
                    className="grid-list"
                    dataSource={data}
                    editSettings={editSettings}
                    allowSorting={true}
                    // sortSettings={sortingOptions}
                    filterSettings={filterOptions}
                    allowFiltering={true}
                    ref={grid}
                    allowTextWrap={true}
                    gridLines='Horizontal'
                    showColumnMenu={true}
                    columnMenuOpen={onColumnMenuOpen}
                    actionBegin={actionGrid}
                    selectionSettings={setting}
                    allowReordering={true}
                    keyPressed={onKeyPressed}
                  >
                    <ColumnsDirective>
                      <ColumnDirective customAttributes={{ id: 'first' }} width="20%" field='name' headerText={Name} validationRules={validationRule}></ColumnDirective>
                      <ColumnDirective customAttributes={{ id: 'last' }} width="10%" template={buttonTemplate} editTemplate={buttonEditTemplate}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder]} />
                  </GridComponent>
                </div>
              </div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
            }

            <DialogComponent
              id="dialogAddRoom"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogAddRoom}
              header={Room_2}
              isModal={true}
              close={handleCloseDialogAddRoom}
              footerTemplate={footerTemplateAddRoom}
            >
              <div>
                <div>
                  <b>*{Name}</b>
                  <TextBoxComponent
                    name="name"
                    cssClass='e-outline'
                    change={(e) => handleChange(e, "name")}
                    value={state.name}
                  />
                  {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                </div>
              </div>
            </DialogComponent>


            <DialogComponent
              id="dialogDeleteRoom"
              width="35%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogDeleteRoom}
              header={Delete_Confirmation}
              isModal={true}
              close={handleCloseDialogDeleteRoom}
              footerTemplate={footerTemplateDeleteRoom}
            >
              <div>
                {selectedData ?
                  <div style={{ fontSize: "14px" }}>
                    {Are_you_sure_you_want_to_delete_the_data_of_room} ({selectedData.name})?
                    <br /><br />

                  </div>
                  : ''}
              </div>
            </DialogComponent>
          </div>
        </div>
      }
    </div>
  );
}

export default RoomList;