import React, { useState, useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Query } from '@syncfusion/ej2-data';
import { RecurrenceEditorComponent } from '@syncfusion/ej2-react-schedule';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as permission from 'src/utils/Permission';
import './style.css';

import CardRender from './classCardRender';

const SubjectClassList = () => {
	/* eslint-disable no-unused-vars*/
	const navigate = useNavigate();

	const [Update] = useState(i18next.t('Pages.button.Update'));
	const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

	const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
	const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
	const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));

	const [Sunday] = useState(i18next.t('Pages.data.Sunday'));
	const [Monday] = useState(i18next.t('Pages.data.Monday'));
	const [Tuesday] = useState(i18next.t('Pages.data.Tuesday'));
	const [Wednesday] = useState(i18next.t('Pages.data.Wednesday'));
	const [Thursday] = useState(i18next.t('Pages.data.Thursday'));
	const [Friday] = useState(i18next.t('Pages.data.Friday'));
	const [Saturday] = useState(i18next.t('Pages.data.Saturday'));

	const [Class_Summary] = useState(i18next.t('Pages.menu.Class_Summary'));

	const [All_Teachers] = useState(i18next.t('Pages.data.All_Teachers'));
	const [All_Classes] = useState(i18next.t('Pages.data.All_Classes'));
	const [All_Groups] = useState(i18next.t('Pages.data.All_Groups'));
	const [All_Days] = useState(i18next.t('Pages.data.All_Days'));
	const [Name] = useState(i18next.t('Pages.data.Name'));
	const [Select_Subject] = useState(i18next.t('Pages.data.Select_Subject'));
	const [Select_Teacher] = useState(i18next.t('Pages.data.Select_Teacher'));
	const [Select_Day] = useState(i18next.t('Pages.data.Select_Day'));
	const [Select_Room] = useState(i18next.t('Pages.data.Select_Room'));

	const [Start_Date] = useState(i18next.t('Pages.schedule_data.Start_Date'));

	const [Only_Active_Classes] = useState(i18next.t('Pages.subject_class_data.Only_Active_Classes'));
	const [Close_to_Finishing] = useState(i18next.t('Pages.subject_class_data.Close_to_Finishing'));
	const [Class] = useState(i18next.t('Pages.subject_class_data.Class'));
	const [Classes] = useState(i18next.t('Pages.subject_class_data.Classes'));
	const [Students] = useState(i18next.t('Pages.data.Students'));
	const [Add_Class] = useState(i18next.t('Pages.subject_class_data.Add_Class'));
	const [Subject] = useState(i18next.t('Pages.subject_class_data.Subject'));
	const [Meeting_List] = useState(i18next.t('Pages.subject_class_data.Meeting_List'));
	const [Day] = useState(i18next.t('Pages.subject_class_data.Day'));
	const [Time] = useState(i18next.t('Pages.subject_class_data.Time'));
	const [Teacher] = useState(i18next.t('Pages.subject_class_data.Teacher'));
	const [Room] = useState(i18next.t('Pages.subject_class_data.Room'));
	const [Class_created_successfully] = useState(i18next.t('Pages.subject_class_data.Class_created_successfully'));
	const [Number_of_Meetings] = useState(i18next.t('Pages.subject_class_data.Number_of_Meetings'));
	const [The_number_of_meetings_cannot_be_empty] = useState(i18next.t('Pages.subject_class_data.The_number_of_meetings_cannot_be_empty'));

	//data
	let cardEle;
	let [data, setData] = useState(null);
	const [dataSubject, setDataSubject] = useState(null);
	const [dataSubject2, setDataSubject2] = useState(null);
	const [dataTeacher, setDataTeacher] = useState(null);
	const [dataTeacher2, setDataTeacher2] = useState(null);
	const [dataSubjectGroup, setDataSubjectGroup] = useState(null);
	let [subjectGroup, setSubjectGroup] = useState(null);
	const [dataRoom, setDataRoom] = useState(null);
	const [dataNumberOfMeetings, setDataNumberOfMeetings] = useState(false);

	//initialized and loading
	const [initialized, setInitialized] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);

	//dialog
	const [open, setOpen, openRef] = useStateRef(false);

	//set recurrence editor component
	let recObject = useRef(false);

	//other component setting
	const [totalStudent, setTotalStudent] = useState(0);
	const fields = { text: 'name', value: 'id' };
	const weeks = [{ id: "Sunday", name: Sunday }, { id: "Monday", name: Monday }, { id: "Tuesday", name: Tuesday }, { id: "Wednesday", name: Wednesday }, { id: "Thursday", name: Thursday }, { id: "Friday", name: Friday }, { id: "Saturday", name: Saturday }];
	const weeks2 = [{ id: -1, name: All_Days }, { id: 0, name: Sunday }, { id: 1, name: Monday }, { id: 2, name: Tuesday }, { id: 3, name: Wednesday }, { id: 4, name: Thursday }, { id: 5, name: Friday }, { id: 6, name: Saturday }];
	const user_setting = JSON.parse(localStorage.user_setting);

	//set state add class
	const [state, setState, stateRef] = useStateRef({
		name: null,
		start_date: null,
		end_date: null,
		subjects: null,
		meetings: null,
		number_of_meetings: 24
	});

	//set state add meeting row
	const [stateRow, setStateRow, stateRowRef] = useStateRef({ rows: [{}] })

	//state filter data
	const [dataFilter, setDataFilter, dataFilterRef] = useStateRef({
		subject_group: 0,
		subject_class: 0,
		teacher: 0,
		weekday: -1,
		ck_active: true,
		ck_close_to_finishing: false
	});

	//set error
	const [error, setError, errorRef] = useStateRef({
		subject: false, name: false, start_date: false,
		meetings: false, weekday: false, time: false, teacher: false, room: false,
		number_of_meetings: false
	});
	/* eslint-enable no-unused-vars*/

	const getInitialValue = () => {
		setState({
			name: null,
			start_date: null,
			end_date: null,
			subjects: null,
			meetings: null
		});

		setStateRow({ rows: [{}] })
	}

	/* eslint-disable react-hooks/exhaustive-deps*/
	useEffect(() => {
		if (!initialized) {
			setInitialized(true);
			setLoading(true);
			getData();
		}

		return function cleanup() {
			setInitialized(true);
		}
	})
	/* eslint-enable react-hooks/exhaustive-deps*/

	// {/* ================== Get All Data ========================================== */ }
	//get all subject class data
	/* eslint-disable array-callback-return*/
	const getData = async () => {

		var subject_group = { "nnull": null };
		var subject_class = { "nnull": null };
		var teacher = { "nnull": null };
		var weekday = { "nnull": null };

		if (dataFilterRef.current.subject_group !== 0) { subject_group = { "in": dataFilterRef.current.subject_group }; }
		if (dataFilterRef.current.subject_class !== 0) { subject_class = { "in": dataFilterRef.current.subject_class }; }
		if (dataFilterRef.current.teacher !== 0) { teacher = { "in": dataFilterRef.current.teacher }; }
		if (dataFilterRef.current.weekday.id !== -1 && dataFilterRef.current.weekday !== -1) { weekday = { "in": getWeek(dataFilterRef.current.weekday.name) }; }

		//active (still have class from last day)
		var end_date_active = {};
		if (dataFilterRef.current.ck_active === true) {
			end_date_active = {
				'logical': 'and',
				'gte': moment().subtract(1, 'days').format()
			}
		}

		//close to finishing (7 days before class ends)
		var end_date_close_to_finishing = end_date_active;
		if (dataFilterRef.current.ck_close_to_finishing === true) {
			end_date_close_to_finishing = {
				'logical': 'and',
				'between': [moment().format(), moment().add(7, 'days').format()]
			}
		}

		try {
			//get all subject classes data by filter
			var result = await directus.getItems('subject_classes', {
				fields: '*.*, subjects.*.*, meetings.meetings_id.*.*, students.students_id.*',
				filter: {
					'subjects.group.id': subject_group,
					'subjects.id': subject_class,
					'meetings.meetings_id.teacher.id': permission.AdminPermission() ? teacher : permission.TeacherPermission ? user_setting.data_id : null,
					'meetings.meetings_id.weekday': weekday,
					'end_date': end_date_active,
					'end_date': end_date_close_to_finishing //eslint-disable-line no-dupe-keys
				}
			});

			if (!result.error) {
				//order the data by subject group and end date
				let data = result.data;
				data = linq.from(data)
					.orderByDescending(x => x.end_date)
					.orderBy(x => x.subjects.id)
					.toArray();
				setData(data);

				var student_data = linq.from(data)
					.select(data => data.students
					).toArray();

				//total students
				var students = [];
				student_data.map((value) => {
					value.map((value) => {
						if (students.indexOf(value.students_id.id) === -1) {
							students.push(value.students_id.id);
						}
					})
				})
				setTotalStudent(students.length);

				//get subject group data
				const data_subject = [];
				data.map((value) => {
					const id = value.subjects.group.id;
					if (!data_subject.some(data_subject => data_subject.id === id)) {
						data_subject.push(value.subjects.group);
					}
				})

				setSubjectGroup(data_subject)
				// render the card;
				cardRendering(data);
				getAllSubjectGroup();
			}

		} catch (error) {
			EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
		}
	}

	//get all subject group data
	const getAllSubjectGroup = async () => {
		try {
			var result = await directus.getItems('subject_group', { fields: '*.*', filter: { status: 'published' }, sort: "sort,name" });
			setDataSubject(result.data);
			var newData = result.data;
			if (result.data.length > 0) {
				var oldData = [{ id: 0, name: All_Groups }]
				newData = oldData.concat(newData);
			}
			setDataSubjectGroup(newData);
			getSubject();
		} catch (error) {
			EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
		}
	}

	//get list of subjects
	const getSubject = async () => {
		try {
			var result = await directus.getItems('subjects', { fields: '*.*', filter: { status: 'published' } });
			if (!result.error) {
				setDataSubject(result.data);

				var newData = result.data;
				if (result.data.length > 0) {
					var oldData = [{ id: 0, name: All_Classes }]
					newData = oldData.concat(newData);
				}
				setDataSubject2(newData);
				getTeacher();
			}
		} catch (error) {
			EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
		}
	}

	//get list of teachers
	const getTeacher = async () => {
		try {
			var result = await directus.getItems('teachers', {
				filter: {
					status: 'published',
				},
				sort: 'nickname'
			});

			setDataTeacher(result.data);

			var newData = result.data;
			if (result.data.length > 0) {
				var oldData = [{ id: 0, nickname: All_Teachers }];
				newData = oldData.concat(newData);
			}
			setDataTeacher2(newData);
			getRoom();
		} catch (error) {
			EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
		}
	}

	//get list of rooms
	const getRoom = async () => {
		try {
			var result = await directus.getItems('rooms', { sort: 'name' });
			setDataRoom(result.data);
			setLoadingButton(false);
		} catch (error) {
			EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
		}
	}

	// {/* ================== Translate Week Data ========================================== */ }
	const getWeek = (props) => {
		var week_2 = props ? props : null;
		if (weeks) {
			weeks.forEach((value) => {
				if (value.name === week_2) {
					week_2 = value.id
				}
			})
		}
		return "" + week_2
	}


	// {/* ================== Card Rendering ========================================== */ }
	// Render the card 
	const cardRendering = async (data) => {
		if (data.length > 0) {
			var numberOfMeetings = 0;
			data.forEach((data) => {
				getNumberOfMeetings(data.id).then(
					result => {
						numberOfMeetings = result;
						//setup class interface
						cardEle = document.getElementById('card_subject_' + (data.id));

						if (cardEle && numberOfMeetings) {
							ReactDOM.render(<CardRender data={data} numberOfMeetings={numberOfMeetings} navigate={navigate} getData={getData} />, cardEle);
						}
					}
				);
			}
			);
		}

		setLoading(false);
	}

	//get number of meetings for each class
	const getNumberOfMeetings = async (schedule_id) => {
		try {
			var result = await directus.getItems('schedules', {
				filter: {
					subject_classes_id: schedule_id
				}
			})

			return result.data.length

		} catch (error) {
			EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
		}
	}


	// {/* ================== Group Display ========================================== */ }

	//render all the subject group that have classes
	function subjectGroupRender() {
		const list = [];

		if (subjectGroup && data) {
			subjectGroup.map((value, index) => {

				list.push(
					<div key={index} id={'subject_group_' + value.id} style={{}}>

						{/* display all the subject group that have classes and display it with specific background color*/}
						<div style={{ backgroundColor: value.color, color: "white", borderRadius: "5px", height: "40px", position: "sticky", top: "0", zIndex: "99" }}>
							<span style={{ fontSize: "16px", fontWeight: "800", lineHeight: "40px", marginLeft: "25px" }}>{value.name}</span>
						</div>

						{/* display all the subject classes based on the subject group  */}
						<div className='row e-card-layout' id={'group_' + value.id} style={{ marginTop: "19px" }}>
							{/* <div> */}
							<div className='e-card-content' style={{ marginRight: "-30px" }}>
								{subjectClassRender(value.id)}
							</div>
							{/* </div> */}
						</div>
					</div>
				)
			})

		}

		return (<div>{list}</div>)
	}

	// {/* ================== Subject Class Display ========================================== */ }

	//render the subject classes by subject group id
	function subjectClassRender(group_id) {
		const list = [];
		// var data_subject_class = data;

		//get all subject classes by subject group id
		var data_subject_class = linq.from(data)
			.where(x => x.subjects.group.id === group_id).toArray();

		if (data_subject_class) {
			data_subject_class.map((value) => {
				list.push(
					<div key={value.id} style={{ width: "300px", minHeight: "250px", height: "auto", marginLeft: "30px", display: "flex", flexDirection: 'column', marginBottom: "20px" }} id={'card_subject_' + value.id} className='card_sample'></div>
				);
			})
		}

		return (
			list.length > 0 ?
				<div style={{ marginLeft: "-30px", display: "flex", flexWrap: "wrap" }}>{list}</div>
				: ''
		)
	}

	// {/* ================== Handle Class ========================================== */ }

	//set open close dialog
	const handleToggleAddClassDialog = () => {
		setOpen(!openRef.current);
		if (openRef.current === true) {
			getInitialValue();
		} else {
			setDataNumberOfMeetings(false);
		}

		setError({
			subject: false, name: false, start_date: false, meetings: false
		});
	};

	//handle change add class dialog
	const handleChange = (event, type) => {
		var value = "";
		if (type === "subject") {
			if (event.itemData.id) {
				value = event.itemData.id;
				setState({ ...state, name: event.itemData.name })
			}
		} else {
			value = event.value;
		}

		if (type === "subject" || type === "start_date" || type === "name" || type === "number_of_meetings") {
			if (value) {
				setError({ ...errorRef.current, [type]: false })
			} else {
				setError({ ...errorRef.current, [type]: true })
			}
		}

		if (type === "subject") {
			setDataNumberOfMeetings(true);
			var numOfMeetings = event.itemData.group.default_meetings ? event.itemData.group.default_meetings : 24;
			if (event.itemData.subject_schedule.length > 0) {
				numOfMeetings = event.itemData.subject_schedule.length;
			}
			setState({ ...stateRef.current, number_of_meetings: numOfMeetings })
		}

		//set all the data into the state
		setState({ ...stateRef.current, [type]: value })
	}


	// {/* ================== Handle Meetings Table ========================================== */ }

	//handle onclick add row button
	const handleAddRow = () => {
		const item = {
			weekday: null,
			time: null,
			teacher: null,
			room: null
		};

		setStateRow({
			rows: [...stateRow.rows, item]
		});
	};

	//handle row changed
	const handleChangeRow = (idx, type) => (e) => {
		var value = "";
		if (type === "weekday") {
			value = e.itemData.id;
		} else if (type === "teacher" || type === "room") {
			value = e.itemData.id
		} else if (type === "time") {
			value = moment(e.value).format('HH:mm')
		}

		//get current row state
		const rows = [...stateRowRef.current.rows];

		//set new row changed data to row state
		rows[idx] = { ...stateRowRef.current.rows[idx], [type]: value };
		setStateRow({ rows });

	};


	//handle remove specific row by id
	function handleRemoveSpecificRow(idx) {
		const rows = [...stateRowRef.current.rows];
		rows.splice(idx, 1);
		setStateRow({ rows });
	}

	// {/* ================== Handle Subject Classes, Meetings, Schedules ========================================== */ }

	const addClass = async () => {
		if (!stateRow.rows.length > 0) { setError({ ...errorRef.current, meetings: true }); }
		if (!error.subject && !error.name && !error.start_date && !error.number_of_meetings) {
			try {
				setError({ subject: false, name: false, start_date: false, meetings: false, number_of_meetings: false });
				setLoadingButton(true);
				let data_meeting = [];
				var weekdays = [];

				var status = false;
				var data_subject = null;
				if (state.subject) {
					//get duration, fee from subjects
					data_subject = await directus.getItems('subjects',
						{
							fields: '*.*.*',
							filter: {
								id: state.subject
							}
						}
					);

					//get meeting data and check if there is an empty data
					if (stateRow.rows.length > 0) {
						//get all row state and save into array with formatted time
						stateRow.rows.map((value) => {
							let time = null;
							if (value.weekday && value.time && value.time !== "Invalid date" && value.teacher && value.room) {
								if (value.time) {
									time = value.time + ':00'
								}

								const result = {
									weekday: value.weekday,
									time: time,
									teacher: value.teacher,
									room: value.room,
									duration: data_subject.data[0].meeting_duration,
									status: "published"
								}

								data_meeting.push(result);
								weekdays.push(result.weekday);

							} else {
								status = true;
								setError({ ...errorRef.current, meetings: true });
							}
						})
					}
				}

				//get number of meetings
				var numberOfMeetings = state.number_of_meetings;

				if (status === false && data_meeting.length > 0 && state.subject && state.name && state.start_date && numberOfMeetings !== 0) {

					var meetings_id = null;
					var subject_classes_id = null;

					//save meeting data to database
					var result = await directus.createItems('meetings', data_meeting);
					meetings_id = result.data.map(item => item['id']);

					if (meetings_id) {
						//get recurrence date with abbreviated days
						let weekName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
						let weekName_2 = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

						//combine list of week in meeting data
						var week = '';
						weekdays.map((value, index) => {
							week += weekName_2[weekName.indexOf(value)];
							if (index + 1 !== weekdays.length) {
								week += ',';
							}
						});

						//add the week list and number of meetings into recurrence editor filter
						let filter = 'FREQ=WEEKLY;BYDAY=' + week + ';INTERVAL=1;COUNT=' + numberOfMeetings + ';';

						//get all recurrence dates
						let dates = recObject.current.getRecurrenceDates(new Date(state.start_date), filter, null, null, null);
						let new_dates = [];
						for (let index = 0; index < dates.length; index++) {
							new_dates.push(new Date(dates[index]));
						}

						var maxDate = moment(new Date(Math.max.apply(null, new_dates))).format("YYYY-MM-DD");

						//get new subject class data and save into object with formatted date
						const dataSubjectClass = {
							subjects: state.subject,
							name: state.name,
							start_date: moment(state.start_date).format("YYYY-MM-DD"),
							end_date: maxDate,
							duration: data_subject.data[0].meeting_duration,
							registration_fee: data_subject.data[0].registration_fee,
							monthly_fee: data_subject.data[0].monthly_fee,
							lesson_fee: data_subject.data[0].lesson_fee,
							status: "published"
						}

						//save subject class data into database and get the subject classes id id
						var result_subject_class = await directus.createItem('subject_classes', dataSubjectClass);
						subject_classes_id = result_subject_class.data.id;

						var dataSubjectMeeting = [];
						//get subject classes id and all of the meetings id and save into array
						meetings_id.map((value) => {
							var body = {
								meetings_id: value,
								subject_classes_id: subject_classes_id
							}

							dataSubjectMeeting.push(body);
						})

						//save subject class data into database
						await directus.createItems('subject_classes_meetings', dataSubjectMeeting);

						//save all reccurence date into schedule
						let dataSchedule = [];
						if (data_subject.data[0].group.id) {
							//get all topic in subject class
							var topic = data_subject.data[0].subject_schedule ? data_subject.data[0].subject_schedule : null;

							new_dates.map((date, index_date) => {
								var num = new Date(date).getDay();
								var select_meeting = linq.from(data_meeting)
									.where(w => w.weekday === weeks[num].id)
									.toArray();

								//check if there is a topic, add the topic into the schedule
								var select_topic = null
								if (topic.length > 0) {
									select_topic = linq.from(topic)
										.where(w => w.subject_schedule_id.sort === index_date + 1)
										.toArray();
								}

								let body = {
									teachers_id: select_meeting[0].teacher,
									subject_classes_id: subject_classes_id,
									date: moment(date).format("YYYY-MM-DD"),
									time: select_meeting[0].time,
									duration: data_subject.data[0].meeting_duration,
									room: select_meeting[0].room,
									status: "published",
									topic: select_topic ? select_topic.length > 0 ? select_topic[0].subject_schedule_id.topic_description : null : null,
									test: select_topic ? select_topic.length > 0 ? select_topic[0].subject_schedule_id.test : false : false,
									review: select_topic ? select_topic.length > 0 ? select_topic[0].subject_schedule_id.review : false : false,
									// sort: index_date + 1
								}
								dataSchedule.push(body)

							})
						}
						//save schedule data into database and re-render the data
						var result_schedule = await directus.createItems('schedules', dataSchedule);

						const body_activity = {
							action: "Add",
							category: 8,
							description: state.name,
							status: "published"
						}
						await directus.createItem('activity_log', body_activity);


						if (!result_schedule.error) {
							handleToggleAddClassDialog();
							setState({ name: null, start_date: null, end_date: null, subjects: null, meetings: null });
							setStateRow({ rows: [{}] })
							EventEmitter.emit('alert_toast', { content: Class_created_successfully, type: "success" });
							return navigate('/subject_class/detail/' + subject_classes_id);
						}
					}

				} else {
					if (state.subject && numberOfMeetings === 0) { setError({ ...errorRef.current, number_of_meetings: true }); }
					if (!state.subject) { setError({ ...errorRef.current, subject: true }) }
					if (!state.name) { setError({ ...errorRef.current, name: true }) }
					if (!state.start_date) { setError({ ...errorRef.current, start_date: true }) }
					if (data_meeting.length === 0) { setError({ ...errorRef.current, meetings: true }) }
					setLoadingButton(false);
				}

			} catch (error) {
				EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
				setLoadingButton(false);
			}
		}
	}

	// {/* ================== Add Meeting Display ========================================== */ }

	//render meeting table
	function renderMeetingTable() {
		const list = [];

		list.push(
			<div key={'meeting-table'}>
				<table>
					<thead>
						<tr>
							<th className="text-center"> *{Day} </th>
							<th className="text-center"> *{Time} </th>
							<th className="text-center"> *{Teacher} </th>
							<th className="text-center"> *{Room} </th>
							<th />
						</tr>
					</thead>

					<tbody>
						{/* get all the current row state */}
						{stateRowRef.current.rows.map((item, idx) => (
							<tr id="meeting-row" key={idx}>
								<td>
									{/* get list of weekdays  */}
									<DropDownListComponent
										dataSource={weeks}
										name="weekday"
										cssClass="e-outline"
										placeholder={`--${Select_Day}--`}
										fields={fields}
										select={handleChangeRow(idx, "weekday")}
										value={item.weekday}
									/>
								</td>

								<td>
									{/* display time with format HH:mm ex: 14:30 */}
									<TimePickerComponent
										name="time"
										cssClass="e-outline"
										step={30}
										format={'HH:mm'}
										onChange={handleChangeRow(idx, "time")}
										value={item.time}
									/>
								</td>
								<td>
									{/* get list of teachers  */}
									<DropDownListComponent
										dataSource={dataTeacher}
										name="teacher"
										cssClass="e-outline"
										placeholder={`--${Select_Teacher}--`}
										fields={{ text: 'nickname', value: 'id' }}
										select={handleChangeRow(idx, "teacher")}
										value={item.teacher}
									/>
								</td>
								<td>
									{/* get list of rooms  */}
									<DropDownListComponent
										dataSource={dataRoom}
										name="room"
										cssClass="e-outline"
										placeholder={`--${Select_Room}--`}
										fields={fields}
										select={handleChangeRow(idx, "room")}
										value={stateRowRef.current.rows[idx].room}
									/>
								</td>
								<td>
									<span
										style={{ padding: "10px", cursor: "pointer", color: "#3c8dbc" }}
										onClick={() => handleRemoveSpecificRow(idx)}
										className="e-icons icon-delete-05">
										<input type="hidden" value={idx} />
									</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				{error.meetings === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
			</div>

		)
		return (
			<div>
				{list}
			</div>
		)
	}



	// {/* ================== Filtering ========================================== */ }

	const onFilteringClass = (e) => {
		let query = new Query();
		//frame the query based on search string with filter type.
		query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
		//pass the filter data source, filter query to updateData method.
		e.updateData(dataSubject, query);
	};

	const onFilteringSubjectGroup = (e) => {
		let query = new Query();
		//frame the query based on search string with filter type.
		query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
		//pass the filter data source, filter query to updateData method.
		e.updateData(dataSubjectGroup, query);
	};

	// {/* ================== Handle Filtering in Database ========================================== */ }

	const handleFilter = async (e, type) => {
		var value = "";
		if (type === "subject_group" || type === "subject_class" || type === "teacher") {
			value = e.itemData.id;
		} else if (type === "start_date" || type === "end_date") {
			value = e.value;
		}

		if (type === "weekday") {
			value = {
				id: e.itemData.id,
				name: e.itemData.name
			};
		}

		if (type === "ck_active" || type === "ck_close_to_finishing") { value = e.checked; }
		setDataFilter({ ...dataFilterRef.current, [type]: value })

		getData()
	}
	/* eslint-enable array-callback-return*/

	// {/* ================== Main Content ========================================== */ }

	return (
		<div>
			{loading ?
				<CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
				:
				<div>
					<div className="header">
						<div className="content-header">{Class_Summary}</div>
						<div className="content-searching">
							<div style={{ display: 'flex', width: "100%" }}>
								<div>
									{data ?
										<div style={{ fontSize: "16px" }} >
											<span style={{ fontWeight: "700" }}>{moment().format('DD MMMM yyyy')}</span> - <span style={{ color: "#5F99E9" }}>{Classes}: {data.length}, {Students}:  {totalStudent}
											</span>
										</div>
										: ''
									}
								</div>
								{permission.AdminPermission() ?
									<div className="hide-content" style={{ marginLeft: "30px" }}>
										<ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleToggleAddClassDialog}>
											<span className="btn-text-custom">{Class}</span>
										</ButtonComponent>
									</div>
									: ""}
							</div>
						</div>
					</div>

					<div className="content-main" style={{ marginTop: "30px" }}>
						<div className="hide-content">
							<div style={{ display: 'flex', width: "100%", height: "30px" }}>
								{/* <div className="ddl-container" style={{ width: "175px", marginRight: "4px" }}> */}
								<div className="ddl-container" style={{ width: "200px", marginRight: "4px" }}>
									<DropDownListComponent
										id="ddl_custom"
										dataSource={dataSubjectGroup}
										name="subject_group"
										cssClass="e-outline"
										placeholder={All_Groups}
										fields={fields}
										select={(e) => handleFilter(e, "subject_group")}
										value={dataFilterRef.current.subject_group}
										// search teacher by name 
										filtering={onFilteringSubjectGroup}
										allowFiltering={true}
									/>
								</div>
								{/* <div className="ddl-container" style={{ width: "175px", marginRight: "4px" }}> */}
								<div className="ddl-container" style={{ width: "200px", marginRight: "4px" }}>
									<DropDownListComponent
										id="ddl_custom"
										dataSource={dataSubject2}
										name="subject_class"
										cssClass="e-outline"
										placeholder={All_Classes}
										fields={fields}
										select={(e) => handleFilter(e, "subject_class")}
										value={dataFilterRef.current.subject_class}
										// search teacher by name 
										filtering={onFilteringClass}
										allowFiltering={true}
									/>
								</div>
								{permission.AdminPermission() ?
									<div className="ddl-container" style={{ width: "175px", marginRight: "4px" }}>
										<DropDownListComponent
											id="ddl_custom"
											dataSource={dataTeacher2}
											name="teacher"
											cssClass="e-outline"
											placeholder={All_Teachers}
											fields={{ text: 'nickname', value: 'id' }}
											select={(e) => handleFilter(e, "teacher")}
											value={dataFilterRef.current.teacher}
											// search teacher by name 
											filtering={onFilteringClass}
											allowFiltering={true}
										/>
									</div>
									: ""}
								{/* <div className="ddl-container" style={{ width: "175px", marginRight: "12px" }}> */}
								<div className="ddl-container" style={{ width: "120px", marginRight: "12px" }}>
									<DropDownListComponent
										id="ddl_custom"
										dataSource={weeks2}
										name="weekdays"
										cssClass="e-outline"
										placeholder={All_Days}
										fields={fields}
										select={(e) => handleFilter(e, "weekday")}
										value={dataFilterRef.current.weekday}
									/>
								</div>
								<div style={{ marginRight: "12px", lineHeight: "30px" }}>
									<CheckBoxComponent
										// key="ck-filled" 
										disabled={false}
										checked={true}
										label={Only_Active_Classes}
										change={(e) => handleFilter(e, "ck_active")}
									/>
								</div>
								<div style={{ lineHeight: "30px" }}>
									<CheckBoxComponent
										// key="ck-filled" 
										disabled={false}
										checked={dataFilterRef.current.ck_close_to_finishing}
										label={Close_to_Finishing}
										change={(e) => handleFilter(e, "ck_close_to_finishing")}
									/>
								</div>
							</div>

						</div>


						{/* display list of subject class  */}
						<div className="control-section card-control-section tile_layout" style={{ marginTop: "30px" }}>
							<div className='row e-card-layout class-card' >
								{/* <div className="row" > */}

								{/* {data ? data.length > 0 ? '' :
										<div style={{ textAlign: "center", paddingTop: "10px" }}>{No_data_found}</div>
										: ''} */}

								{/* render all the subject group  */}
								{data ? data.length > 0 ?
									<div>
										{subjectGroupRender()}
									</div>
									: <div style={{ textAlign: "center", paddingTop: "10px" }}>{No_data_found}</div>
									: <div style={{ textAlign: "center", paddingTop: "10px" }}>{No_data_found}</div>}
								{/* </div> */}
							</div>
						</div>

						{/* add class dialog  */}
						<Dialog id="dialogAddClass" open={open}>
							<DialogTitle><div style={{ fontSize: '18px' }}>{Add_Class}</div></DialogTitle>
							<DialogContent>
								<div  >
									<div>
										<b>*{Subject}</b>
										<DropDownListComponent
											dataSource={dataSubject}
											name="subject"
											cssClass='e-outline'
											placeholder={`--${Select_Subject}--`}
											fields={fields}
											select={(e) => handleChange(e, "subject")}
											value={state.subject}
										/>
										{error.subject === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
										<br /><br />
										<b>*{Name}</b>
										<TextBoxComponent
											name="name"
											cssClass='e-outline'
											change={(e) => handleChange(e, "name")}
											value={stateRef.current.name}
										/>
										{error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
										<br /><br />
										<b>*{Start_Date}</b>
										<DatePickerComponent
											cssClass="e-outline"
											name="start_date"
											format="dd-MMM-yyyy"
											change={(e) => handleChange(e, "start_date")}
										/>
										{error.start_date === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
										{dataNumberOfMeetings ?
											<span>
												<br /><br />
												<b>*{Number_of_Meetings}</b>
												<NumericTextBoxComponent
													cssClass="e-outline"
													name="number_of_meetings"
													format='#########'
													min={0}
													change={(e) => handleChange(e, "number_of_meetings")}
													value={stateRef.current.number_of_meetings ? stateRef.current.number_of_meetings : 0}
												/>
												{error.number_of_meetings === true ? <span style={{ color: 'red' }}>{The_number_of_meetings_cannot_be_empty}</span> : ''}
											</span>
											: null
										}
										<br /><br />
										<b>{Meeting_List}</b>
										<br /><br />
										{renderMeetingTable()}
										<br /><br />
									</div>
									<ButtonComponent iconCss='e-icons icon-plus' onClick={handleAddRow} className='e-link' id='btnMeeting' ><b>Meetings</b></ButtonComponent>
									<br />
									<br />
								</div>
							</DialogContent>
							<DialogActions>
								{loadingButtonRef.current === false ?
									<div>
										<ButtonComponent
											id="btn-update"
											iconCss='e-icons icon-check-mark-01'
											style={{ color: '#4d841d', boxShadow: 'none' }}
											onClick={addClass}
										>
											<b>{Update}</b>
										</ButtonComponent>

										<ButtonComponent
											id='btn-cancel'
											iconCss='e-icons icon-treeview'
											style={{ color: '#474747', boxShadow: 'none' }}
											onClick={handleToggleAddClassDialog}
										>
											<b>{Cancel}</b>
										</ButtonComponent>
									</div>
									:
									<CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
								}
							</DialogActions>
						</Dialog>
					</div>

					<div className='RecurrenceEditor' style={{ display: 'none' }}>
						<RecurrenceEditorComponent id='RecurrenceEditor' ref={recObject}></RecurrenceEditorComponent>
					</div>

				</div>
			}

		</div>
	);
}

export default SubjectClassList;