import React, { useRef, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { ToastComponent } from '@syncfusion/ej2-react-notifications';

import EventEmitter from 'src/utils/EventEmitter';

import NavBar from './NavBar';

import "./index.css";
import "./iconStyle.css";
import "./grid.css";

const DashboardLayout = () => {
  //show alert
  let toastInstance = useRef(false);
  useEffect(() => {
    const openToast = (props) => {
      if (props.content && toastInstance.current) {
        toastInstance.current.content = props.content;
        //type (success, warning, info, danger)
        toastInstance.current.cssClass = 'e-toast-' + props.type;
        toastInstance.current.show();
      }
    }

    const listener = EventEmitter.addListener('alert_toast', openToast);
    return () => {
      listener.remove();
    }
  })

  const onClickToast = (e) => {
    e.clickToClose = true;
  }

  const openSidebar = () => {
    EventEmitter.emit('openSidebar', { value: "click" });
  }

  //main content
  return (
    <div id="dahsboard-layout">
      <NavBar />
      <div className="main-content" id="main-text" style={{ backgroundColor: "white" }}>
        <div className="sidebar-content">
          <div className="open-sidebar" onClick={() => openSidebar()} >
            <span className="e-icons icon-chevron-right-03"
              style={{ fontSize: "10px", color: '#516573', marginLeft: "20px", fontWeight: "800" }}

            />
          </div>
          <ToastComponent
            ref={toastInstance}
            position={{ X: 'Right', Y: "Top" }}
            showCloseButton={true}
            click={(e) => onClickToast(e)}
          />
          <div style={{ minHeight: "100vh" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div >
  );
};

export default DashboardLayout;