import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import Enumerable from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

const ActivitySummary = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));

  const [Name] = useState(i18next.t('Pages.activity_data.Name'));
  const [Date_2] = useState(i18next.t('Pages.activity_data.Date'));
  const [Activity_Type] = useState(i18next.t('Pages.activity_data.Activity_Type'));
  const [Summary] = useState(i18next.t('Pages.activity_data.Summary'));
  const [Number_of_activities] = useState(i18next.t('Pages.activity_data.Number_of_activities'));
  const [Avg_number_of_participants] = useState(i18next.t('Pages.activity_data.Avg_number_of_participants'));
  const [Max_number_of_participants] = useState(i18next.t('Pages.activity_data.Max_number_of_participants'));
  const [Min_number_of_participants] = useState(i18next.t('Pages.activity_data.Min_number_of_participants'));
  const [Total_Teacher_Attended] = useState(i18next.t('Pages.activity_data.Total_Teacher_Attended'));
  const [Total_Student_Attended] = useState(i18next.t('Pages.activity_data.Total_Student_Attended'));
  const [Activity_Summary] = useState(i18next.t('Pages.activity_data.Activity_Summary'));
  const [List] = useState(i18next.t('Pages.activity_data.List'));

  //data
  let [data, setData] = useState(null);
  let [dataActivityType, setDataActivityType] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogEditActivity = useRef(false);

  //grid component setting
  const grid = useRef();

  //set state edit activity
  const [state, setState] = useState({ name: null });

  //set state filtering
  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    start_date: null,
    end_date: null
  });

  //set error
  const [error, setError, errorRef] = useStateRef({
    name: false
  });
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getActivityType();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "edit_activity") {
        dialogEditActivity.current.visible = false;
        // setState({ name: null });
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })

  /* eslint-disable array-callback-return*/
  // {/* ================== Get All Data ========================================== */ }
  //get list of activities type
  const getActivityType = async () => {
    try {
      var result = await directus.getItems('activity_type',
        {
          filter: {
            status: "published",
            id: id
          }
        });
      setDataActivityType(result.data[0]);
      getData();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of activities
  const getData = async () => {
    try {
      var date = { "nnull": null };
      if (stateFilteringRef.current.start_date && stateFilteringRef.current.end_date) {
        var range = [moment(stateFilteringRef.current.start_date).format("YYYY-MM-DD"), moment(stateFilteringRef.current.end_date).format("YYYY-MM-DD")];
        date = { 'between': range }
      }


      var result = await directus.getItems('activities',
        {
          fields: "*.*",
          sort: "date",
          filter: {
            status: "published",
            type: id,
            date: date
          }
        });

      setData(result.data);

      if (result.data.length > 0) {
        var min = Enumerable.from(result.data)
          .minBy(s => s.date);

        var max = Enumerable.from(result.data)
          .maxBy(s => s.date);

        setStateFiltering({
          ...stateFiltering,
          start_date: stateFilteringRef.current.start_date ? stateFilteringRef.current.start_date : new Date(min.date),
          end_date: stateFilteringRef.current.end_date ? stateFilteringRef.current.end_date : new Date(max.date)
        })
      }
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }

  }

  // {/* ================== Filtering ========================================== */ }

  const handleFilter = async (e, type) => {

    var value = "";
    if (type === "start_date" || type === "end_date") {
      value = e.value;
    }

    setStateFiltering({ ...stateFiltering, [type]: value })

    getData();
  }

  // {/* ================== Summary Template ========================================== */ }

  const totalTeacherTemplate = (props) => {
    var total = 0;
    if (props.teachers) {
      props.teachers.map((value) => {
        if (parseInt(value.attended) === 1) {
          total++;
        }
      })
    }
    return (<div>{total}</div>)
  }

  const totalStudentTemplate = (props) => {
    var total = 0;
    if (props.students) {
      props.students.map((value) => {
        if (parseInt(value.attended) === 1) {
          total++;
        }
      })
    }
    return (<div>{total}</div>)
  }

  const getAverage = (props) => {
    var total = 0;
    var count = 0;
    var avg = 0;
    props.map((value) => {
      if (value.students) {
        value.students.map((value2) => {
          if (parseInt(value2.attended) === 1) { total++; }
        })
        count++;
      }
    })
    if (count > 0) { avg = total / count; }
    return (<div>{avg}</div>)
  }

  const getMax = (props) => {
    var max = 0;
    var list = [];

    props.map((value) => {
      if (value.students) {
        var total = 0;
        value.students.map((value2) => {
          if (parseInt(value2.attended) === 1) { total++; }
        })
        list.push(total);
      }
    })

    if (list.length > 0) { max = Math.max(...list); }
    return (<div>{max}</div>)
  }

  const getMin = (props) => {
    var min = 0;
    var list = [];
    props.map((value) => {
      if (value.students) {
        var total = 0;
        value.students.map((value2) => {
          if (parseInt(value2.attended) === 1) { total++; }
        })
        list.push(total);
      }
    })
    if (list.length > 0) { min = Math.min(...list); }
    return (<div>{min}</div>)
  }

  // {/* ================== Data Grid Template ========================================== */ }

  const dateTemplate = props => {
    return (
      <div>{props.date ? moment(props.date).format('DD-MMM-yyyy') : ''}</div>
    )
  };

  const onDataBound = () => {
    const empty = document.getElementsByClassName("e-emptyrow");
    if (empty.length > 0) {
      for (var i = 0; i < empty.length; i++) {
        empty[i].style.display = "none";
      }
    }
  }

  // {/* ================== Handle Update Acitivity ========================================== */ }

  const handleOpenDialogEditActivity = () => {
    setState({ name: dataActivityType.name })
    dialogEditActivity.current.visible = true;
  }

  const handleCloseDialogEditActivity = () => {
    dialogEditActivity.current.visible = false;
    setError({ name: false });
  }

  const handleChange = (event, type) => {
    var value = event.value;
    setState({ ...state, [type]: value })
    if (value) {
      setError({ name: false })
    }
  }

  const editActivity = async () => {
    try {
      setError({ name: false });
      if (state.name) {
        setLoadingButton(true);
        await directus.updateItem('activity_type', id, state);

        var description = state.name;
        if (dataActivityType.name !== state.name) {
          description = dataActivityType.name + " --> " + state.name;
        }
        const body_activity = {
          action: "Update",
          category: 13,
          description: description,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        getActivityType();
        setDataButton("edit_activity");
      } else {
        if (!state.name) { setError({ ...errorRef.current, name: true }) }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }


  // {/* ================== Footer Template ========================================== */}

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }
  /* eslint-enable array-callback-return*/

  // {/* ================== Main Content ========================================== */ }
  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Activity_Summary}</div>
          </div>
          <div className="content-main">
            {data ?
              <div style={{ marginTop: "30px" }}>
                <table>
                  <thead>
                    <tr>
                      <td style={{ padding: "5px", width: "200px" }}>
                        <DatePickerComponent
                          id="ddl_custom"
                          showClearButton={false}
                          cssClass="e-outline"
                          name="start_date"
                          format='dd-MMM-yyyy'
                          value={stateFilteringRef.current.start_date ? stateFilteringRef.current.start_date : new Date()}
                          onChange={(e) => handleFilter(e, "start_date")}
                        />
                      </td>
                      <td style={{ padding: "5px", width: "200px" }}>
                        <DatePickerComponent
                          id="ddl_custom"
                          showClearButton={false}
                          cssClass="e-outline"
                          name="end_date"
                          format='dd-MMM-yyyy'
                          value={stateFilteringRef.current.end_date ? stateFilteringRef.current.end_date : new Date()}
                          onChange={(e) => handleFilter(e, "end_date")}
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
                <span style={{ fontSize: "20px" }}>{dataActivityType.name}</span>
                <span className="e-icons icon-edit-icon1 hide-content"
                  style={{ width: "5px", padding: "10px", cursor: "pointer", color: "#3c8dbc" }}
                  onClick={() => handleOpenDialogEditActivity()}
                ></span>
                <hr />
                <div id="activity_summary">
                  <h4>{Summary}</h4>
                  <table style={{ border: "1px solid #f4f4f4" }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: "5px", paddingRight: "20px" }}><b>{Number_of_activities}</b></td>
                        <td style={{ padding: "5px" }}>{data.length}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "5px", paddingRight: "20px" }}><b>{Avg_number_of_participants}</b></td>
                        <td style={{ padding: "5px" }}>{getAverage(data)}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "5px", paddingRight: "20px" }}><b>{Max_number_of_participants}</b></td>
                        <td style={{ padding: "5px" }}>{getMax(data)}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: "5px", paddingRight: "20px" }}><b>{Min_number_of_participants}</b></td>
                        <td style={{ padding: "5px" }}>{getMin(data)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />
                <div id="activity_list">
                  <h4>{List}</h4>
                  <GridComponent
                    width="100%"
                    dataSource={data}
                    ref={grid}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: "Content" }}
                    allowResizing={true}
                    gridLines='Vertical'
                    dataBound={onDataBound}
                    clipMode="EllipsisWithTooltip"
                  >
                    <ColumnsDirective>
                      <ColumnDirective customAttributes={{ id: 'first' }} width="200px" field='name' headerText={Name}></ColumnDirective>
                      <ColumnDirective width="200px" field='date' headerText={Date_2} template={dateTemplate}></ColumnDirective>
                      <ColumnDirective width="200px" headerText={Total_Teacher_Attended} template={totalTeacherTemplate}></ColumnDirective>
                      <ColumnDirective customAttributes={{ id: 'last' }} width="200px" headerText={Total_Student_Attended} template={totalStudentTemplate}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                  </GridComponent>
                </div>

                <DialogComponent
                  id="dialogEditActivity"
                  width="30%"
                  showCloseIcon={!loadingButton ? true : false}
                  visible={false}
                  ref={dialogEditActivity}
                  header={Activity_Type}
                  isModal={true}
                  footerTemplate={() => footerTemplate([
                    { name: Update, func: editActivity, icon: "icon-check-mark-01", status: "e-success" },
                    { name: Cancel, func: handleCloseDialogEditActivity, icon: "icon-treeview", status: "" }
                  ])}
                  close={handleCloseDialogEditActivity}
                >
                  <div>
                    <b>{Name}</b>
                    <TextBoxComponent
                      name="name"
                      cssClass='e-outline'
                      change={(e) => handleChange(e, "name")}
                      value={state.name}
                    />
                    {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  </div>
                </DialogComponent>
              </div>
              : ''}
          </div>

        </div>
      }
    </div>
  );
}

export default ActivitySummary;