import React, { useState } from 'react';
import { Link, BrowserRouter } from 'react-router-dom';

import { FaFemale, FaMale } from 'react-icons/fa';

import i18next from "i18next";
import moment from 'moment';

import * as link from 'src/constants/link';
import * as permission from 'src/utils/Permission';

// {/* ================== Student Card Display ========================================== */}

const CardRender = (props) => {
  const [Created_on] = useState(i18next.t('Pages.data.Created_on'));
  const [Nickname] = useState(i18next.t('Pages.data.Nickname'));
  const [Email] = useState(i18next.t('Pages.teacher_data.Email'));
  const [Mobile_Number] = useState(i18next.t('Pages.data.Mobile_Number'));

  function btnClick(props) {
    const id = props.data.id;
    return props.navigate('/teacher/profile/' + id);
  }

  return (
    <div className='e-card' style={{ borderRadius: '20px' }}>
      <div className="header-subject_class"
        style={{ backgroundColor: "#E1EBF2", paddingLeft: "25px", height: "50px" }}>
        <div style={{ float: "right", paddingRight: "10px", paddingTop: "10px" }}>
          <div style={{ width: "30px", height: "30px" }}>
            <img
              style={{
                maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                objectFit: "cover", borderRadius: "50%", position: "center",
              }}
              className="image"
              src={props.data.image ? props.data.image.data.full_url : link.default_image}
              alt={props.data.name}
            />
          </div>
        </div>
        {/* display teacher name with link button that navigate to subject class detail*/}
        {permission.AdminPermission() ?
          <BrowserRouter>
            <Link to={{ pathname: `/teacher/profile/${props.data.id}` }} style={{ textDecoration: "none" }}
              onClick={() => btnClick(props)}
            >
              <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", color: "#516573", fontSize: "16px", fontWeight: "800", cursor: "pointer", lineHeight: "50px" }} onClick={(e) => e.preventDefault()}>
                {props.data.name}{props.data.degree ? ", " + props.data.degree : null}
              </div>
            </Link>
          </BrowserRouter>
          :
          <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", color: "#516573", fontSize: "16px", fontWeight: "800", cursor: "pointer", lineHeight: "50px" }}>
            {props.data.name}{props.data.degree ? ", " + props.data.degree : null}
          </div>
        }
      </div>

      <div className='e-card-content' style={{ minHeight: "auto", fontSize: "12px", lineHeight: "1.5", background: "#F1F4FA", height: "140px", position: "relative" }}>
        <span className='e-card-text' style={{ float: "right", fontSize: "20px", marginRight: "-10px" }}>{props.data.gender === "Female" ? <FaFemale style={{ color: "hotpink" }} /> : props.data.gender === "Male" ? <FaMale style={{ color: "dodgerblue" }} /> : ""}</span>
        {props.data.nickname ? <div><span className='e-card-text'>{Nickname} : {props.data.nickname} </span></div> : ''}
        {props.data.email ? <div><span className='e-card-text'>{Email} : {props.data.email} </span></div> : ''}
        {props.data.mobile_number ? <div><span className='e-card-text'>{Mobile_Number} : {props.data.mobile_number} </span></div> : ''}
      </div>

      <div className='e-card-content' style={{ minHeight: "auto", fontSize: "12px", lineHeight: "1.5", background: "#E1EBF2", height: "30px", position: "relative" }}>
        <span style={{ right: "25px", height: "20px", fontSize: "10px", position: "absolute", bottom: "0" }}>
          {Created_on} : {moment(new Date(props.data.created_on)).format("DD MMM yyyy")}
        </span>
      </div>
    </div>
  )
}


export default CardRender;