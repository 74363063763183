import React, { useState, useRef, useEffect } from 'react';
import useStateRef from "react-usestateref";

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

const StaffList = () => {
  /* eslint-disable no-unused-vars*/
  // const navigate = useNavigate();

  var searchTimeout = null;

  var numOfPage = JSON.parse(window.localStorage.getItem('number_of_page'));
  var numStaff = numOfPage ? numOfPage.staff ? numOfPage.staff : 15 : 15;
  if (!numOfPage) {
    window.localStorage.setItem('number_of_page', JSON.stringify({ staff: 15 }));
  } else {
    if (!numOfPage.staff) {
      numOfPage.staff = 15;
      window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
    }
  }

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));
  // const [Preview] = useState(i18next.t('Pages.button.Preview'));
  const [Edit2] = useState(i18next.t('Pages.button.Edit'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [Please_match_the_requested_format] = useState(i18next.t('Pages.general.Please_match_the_requested_format'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));
  const [Items_per_page] = useState(i18next.t('Pages.general.Items_per_page'));
  const [of] = useState(i18next.t('Pages.general.of'));
  const [pages] = useState(i18next.t('Pages.general.pages'));

  // const [Photo] = useState(i18next.t('Pages.data.Photo'));
  const [Name] = useState(i18next.t('Pages.data.Name'));
  // const [Action] = useState(i18next.t('Pages.data.Action'));
  // const [Please_upload_the_photo] = useState(i18next.t('Pages.data.Please_upload_the_photo'));
  const [Mobile_Number] = useState(i18next.t('Pages.data.Mobile_Number'));
  // const [Created_by] = useState(i18next.t('Pages.data.Created_by'));
  const [Modified_by] = useState(i18next.t('Pages.data.Modified_by'));

  const [Staff_List] = useState(i18next.t('Pages.staff_data.Staff_List'));
  const [Enter_staff_name] = useState(i18next.t('Pages.staff_data.Enter_staff_name'));
  const [Staff] = useState(i18next.t('Pages.staff_data.Staff'));
  const [Staff_Biodata] = useState(i18next.t('Pages.staff_data.Staff_Biodata'));
  const [Email] = useState(i18next.t('Pages.staff_data.Email'));
  const [Staff_created_successfully] = useState(i18next.t('Pages.staff_data.Staff_created_successfully'));
  const [Staff_deleted_successfully] = useState(i18next.t('Pages.staff_data.Staff_deleted_successfully'));
  const [Language] = useState(i18next.t('Pages.staff_data.Language'));

  const [Are_you_sure_you_want_to_delete_the_data_of_staff] = useState(i18next.t('Pages.staff_data.Are_you_sure_you_want_to_delete_the_data_of_staff'));
  const [If_you_want_to_delete_the_staff_fill_in_the_name_below] = useState(i18next.t('Pages.staff_data.If_you_want_to_delete_the_staff_fill_in_the_name_below'));
  const [Email_has_already_been_taken] = useState(i18next.t('Pages.staff_data.Email_has_already_been_taken'));

  //data
  let [data, setData] = useState(null);
  let [dataMeta, setDataMeta] = useState(null);
  let [numberOfPage, setNumberOfPage, numberOfPageRef] = useStateRef(numStaff);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogAddStaff = useRef(false);
  let dialogDeleteStaff = useRef(false);
  let dialogEditStaff = useRef(false);

  //grid setting
  const grid = useRef();
  const editSettings = { allowEditing: false, allowAdding: true, allowDeleting: false, mode: 'Normal' };
  const filterOptions = { type: 'Menu' };
  const setting = { mode: 'Both' };

  //delete student setting
  const [selectedData, setSelectedData] = useState(null);
  const [deleteWord, setDeleteWord] = useState(null);

  //upload image
  // let my_form2 = useRef(null);
  // let uploadObj = useRef(null);
  // const [image, setImage] = useState(link.default_image);
  // const asyncSettings = { saveUrl: link.saveUrl, removeUrl: link.removeUrl };

  //set state add staff
  const [state, setState] = useState({
    id: null,
    name: null,
    email: null,
    password: null,
    mobile_number: null,
    lang: "id",
    user: null
  });

  //set state filter
  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    name: null,
    page: 1
  });

  //set error in add staff dialog
  const [error, setError, errorRef] = useStateRef({
    name: false,
    email: false,
    password: false
  });

  const [errorEmail, setErrorEmail] = useState(false);
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "delete_staff") {
        dialogDeleteStaff.current.visible = false;
        setDeleteWord('');
        EventEmitter.emit('alert_toast', { content: Staff_deleted_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get list of staffs
  const getData = async () => {
    try {
      var name = { "nnull": null };

      if (stateFiltering.name !== null && stateFiltering.name !== "") {
        name = { "contains": stateFilteringRef.current.name };
      }

      var result = await directus.getItems('staff',
        {
          fields: "*.*",
          sort: "name",
          filter: {
            name: name,
            status: 'published'
          },
          limit: numberOfPageRef.current,
          meta: "*",
          page: stateFilteringRef.current.page ? stateFilteringRef.current.page : 1
        });

      setData(result.data);
      setDataMeta(result.meta);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Filtering ========================================== */ }

  const handleChangeFilter = (event, type) => {
    var value = "";
    if (type === "name") { value = event.value }
    setStateFiltering({ ...stateFiltering, [type]: value })
    if (event.syntheticEvent.type === 'change') {
      getData();
    }
  }

  const searchStaff = () => {
    getData();
  }

  // {/* ================== Handle Add Staff ========================================== */ }
  const handleOpenDialogAddStaff = () => {
    dialogAddStaff.current.visible = true;
  }

  const handleCloseDialogAddStaff = () => {
    setState({ name: null, email: null, password: null, mobile_number: null, lang: "id" })
    dialogAddStaff.current.visible = false;
    setError({ name: false, email: false, password: false })
    setErrorEmail(false);
  }

  const handleChangeAddStaff = (event, type) => {
    var value = "";
    if (type === "lang") {
      value = event.itemData.id;
    } else {
      value = event.value;
    }

    if (type === "name" || type === "email" || type === "password") {
      if (value) {
        setError({ ...errorRef.current, [type]: false })
        setErrorEmail(false);
      } else {
        setError({ ...errorRef.current, [type]: true })
      }
    }
    setState({ ...state, [type]: value })
  }

  const addStaff = async () => {
    if (!error.name && !error.email && !error.password && !errorEmail) {
      setLoadingButton(true);
      setError({ name: false, email: false, password: false })
      setErrorEmail(false);
      if (state.name) {
        try {
          setLoadingButton(true);
          var split_name = state.name.split(" ");
          var first_name = '';
          var last_name = '';
          first_name = split_name[0];
          if (split_name.length > 1) {
            for (let i = 1; i < split_name.length; i++) {
              last_name += split_name[i];
              if (i !== split_name.length - 1) {
                last_name += " ";
              }
            }
          }

          var body_user = {
            first_name: first_name,
            last_name: last_name,
            locale: state.lang === "id" ? "id-ID" : "en-US",
            email: state.email,
            role: 1,
            status: "active"
          }

          if (state.password) {
            body_user.password = state.password
          }

          var result = await directus.createItem('directus_users', body_user);

          if (result.data) {
            const body_data = {
              name: state.name,
              email: state.email,
              mobile_number: state.mobile_number,
              user: result.data.id,
              status: "published"
            }

            var result_data = await directus.createItem('staff', body_data);

            const body_activity = {
              action: "Add",
              category: 14,
              description: state.name,
              status: "published"
            }
            await directus.createItem('activity_log', body_activity);

            if (!result_data.error) {
              setLoadingButton(false);
              EventEmitter.emit('alert_toast', { content: Staff_created_successfully, type: "success" });
              dialogAddStaff.current.visible = false;
              getData();
            }
          }

        } catch (error) {
          if (error.message.includes("Duplicate")) {
            setErrorEmail(true);
          } else {
            EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          }
          setLoadingButton(false);
        }
      } else {
        if (!state.name) { setError({ ...errorRef.current, name: true }) }
        if (!state.email) { setError({ ...errorRef.current, email: true }) }
        if (!state.password) { setError({ ...errorRef.current, password: true }) }
        setLoadingButton(false);
      }
    }
  }


  // {/* ================== Handle Edit Staff ========================================== */ }
  const handleOpenDialogEditStaff = (props) => {
    setState({ id: props.id, name: props.name, email: props.email, password: null, mobile_number: props.mobile_number, lang: props.lang, user: props.user })
    dialogEditStaff.current.visible = true;
  }

  const handleCloseDialogEditStaff = () => {
    // setState({ name: null, email: null, password: null, mobile_number: null, lang: "id" })
    dialogEditStaff.current.visible = false;
    setError({ name: false, email: false, password: false })
    setErrorEmail(false);
  }

  const handleChangeEditStaff = (event, type) => {
    var value = "";
    if (type === "lang") {
      value = event.itemData.id;
    } else {
      value = event.value;
    }

    if (type === "name" || type === "email" || type === "password") {
      if (value) {
        setError({ ...errorRef.current, [type]: false })
        setErrorEmail(false);
      } else {
        setError({ ...errorRef.current, [type]: true })
      }
    }
    setState({ ...state, [type]: value })
  }

  const editStaff = async () => {
    if (!error.name && !error.email && !errorEmail) {
      setLoadingButton(true);
      setError({ name: false, email: false, password: false })
      setErrorEmail(false);
      if (state.name) {
        try {
          setLoadingButton(true);
          var split_name = state.name.split(" ");
          var first_name = '';
          var last_name = '';
          first_name = split_name[0];
          if (split_name.length > 1) {
            for (let i = 1; i < split_name.length; i++) {
              last_name += split_name[i];
              if (i !== split_name.length - 1) {
                last_name += " ";
              }
            }
          }

          var body_user = {
            id: state.user.id,
            first_name: first_name,
            last_name: last_name,
            locale: state.lang === "id" ? "id-ID" : "en-US",
            email: state.email,
            role: 1,
            status: "active",
          }

          if (state.password) {
            body_user.password = state.password
          }

          var result = await directus.updateItem('directus_users', body_user.id, body_user);

          if (result.data) {
            const body_data = {
              id: state.id,
              name: state.name,
              email: state.email,
              mobile_number: state.mobile_number,
              user: state.user.id,
              status: "published",
            }

            var result_data = await directus.updateItem('staff', body_data.id, body_data);

            const body_activity = {
              action: "Update",
              category: 14,
              description: state.name,
              status: "published"
            }
            await directus.createItem('activity_log', body_activity);

            if (!result_data.error) {
              setLoadingButton(false);
              EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
              dialogEditStaff.current.visible = false;
              getData();
            }
          }

        } catch (error) {
          if (error.message.includes("Failed generating the SQL query")) {
            setErrorEmail(true);
          } else {
            EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          }
          setLoadingButton(false);
        }
      } else {
        if (!state.name) { setError({ ...errorRef.current, name: true }) }
        if (!state.email) { setError({ ...errorRef.current, email: true }) }
        setLoadingButton(false);
      }
    }
  }


  // {/* ================== Handle Dialog Delete Staff ========================================== */ }

  const handleOpenDialogDeleteStaff = (props) => {
    dialogDeleteStaff.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteStaff = () => {
    dialogDeleteStaff.current.visible = false;
    setDeleteWord('');
  }

  const handleChangeWord = (e) => {
    setDeleteWord(e.value);
  }

  const deleteStaff = async () => {
    try {
      if (deleteWord === selectedData.name) {
        setLoadingButton(true);
        //set staff status from published to draft
        await directus.updateItem('staff', selectedData.id, { status: "draft" });
        var result = await directus.updateItem('directus_users', selectedData.user.id, { status: "draft" });

        const body_activity = {
          action: "Delete",
          category: 14,
          description: selectedData.name,
          status: "published"
        }

        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          getData();
          setDataButton("delete_staff");
        }
      } else if (deleteWord !== selectedData.name) {
        EventEmitter.emit('alert_toast', { content: Please_match_the_requested_format, type: "danger" });
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }


  // {/* ================== Handle Upload File ========================================== */ }

  // const uploadFile = async (args) => {
  //   if (args.operation === "upload") {
  //     try {
  //       const data = new FormData(my_form2.current);
  //       var result = await directus.uploadFiles(data, onUploadProgress);
  //       const imageId = result.data.id;
  //       setImage(result.data.data.full_url);
  //       setState({ ...state, image: imageId })
  //     } catch (error) {
  //       EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
  //     }
  //   } else if (args.operation === "remove") {
  //     cancelFile();
  //   }
  // }

  // function onUploadProgress(progressEvent) {
  //   const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //   // console.log(percentCompleted + "% Done");
  // }

  // const cancelFile = (args) => {
  //   setState({ ...state, image: null })
  //   setImage(link.default_image);
  //   setError({ ...error, image: false })
  // }

  // {/* ================== Data Grid Template ========================================== */ }

  const nameTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        {/* <span className="image" style={{ width: "25px", height: "25px" }}>
          <img
            style={{
              maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
              objectFit: "cover", borderRadius: "50%", position: "center",
            }}
            className="image"
            src={props.image ? props.image.data.full_url : link.default_image}
            alt={props.name}
          />
        </span>
        <span style={{ paddingLeft: "20px", textAlign: "left" }}>{props.name}</span> */}
        <span style={{ textAlign: "left" }}>{props.name}</span>
      </div>
    )
  };

  // const imageEditTemplate = props => {
  //   return (
  //     <div className="image" style={{ width: "50px", height: "50px" }}>
  //       <img
  //         style={{
  //           maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
  //           objectFit: "cover", border: "3px solid #d2d6de", borderRadius: "50%", position: "center",
  //         }}
  //         className="image"
  //         src={props.image ? props.image.data.full_url : link.default_image}
  //         alt={props.name}
  //       />
  //     </div>
  //   )
  // };

  const buttonTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_hover" style={{ display: "flex" }}>
          <TooltipComponent content={Edit2} position="BottomCenter">
            <span className="e-icons icon-edit-icon1"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => handleOpenDialogEditStaff(props)}
            />
          </TooltipComponent>

          <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px", }} >
            <span className="e-icons icon-delete-05"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => handleOpenDialogDeleteStaff(props)}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const changeNumOfData = (e) => {
    var value = e.value ? e.value : 1;
    setNumberOfPage(value);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      numOfPage.staff = value;
      window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
      getData();
    }, 1000)
  }

  // {/* ================== Pagination ========================================== */ }

  const pageTemplate = () => {
    var list = [];
    if (dataMeta) {
      for (var i = 0; i < dataMeta.page_count; i++) {
        const page = i + 1;
        var class_name = "e-link e-numericitem e-spacing e-pager-default";
        if (dataMeta.page === page) {
          class_name = "e-link e-numericitem e-spacing e-currentitem e-active"
        }
        var display = "none";
        if (page <= 5) { display = ""; }
        list.push(<div key={"page-" + page}><span className={class_name} id={"page-" + page} role="link" tabIndex="-1" aria-label={'Goto Page ' + page} onClick={() => clickPage(page, "num")} name={'Goto Page ' + page} aria-owns="grid_940258773_0_content_table" style={{ display: display }} >{page}</span></div>)
      }
    }

    //check page 
    var check_page = [];
    if (dataMeta.page_count <= 5) {
      for (var j = 0; j < dataMeta.page_count; j++) { check_page.push(j + 1) }
    } else {
      if (dataMeta.page <= 3) {
        for (var k = 0; k < 5; k++) { check_page.push(k + 1) }
      } else if (dataMeta.page >= dataMeta.page_count - 2) {
        for (var l = dataMeta.page_count; l > dataMeta.page_count - 5; l--) { check_page.push(l) }
      } else {
        var list_page = [dataMeta.page - 2, dataMeta.page - 1, dataMeta.page, dataMeta.page + 1, dataMeta.page + 2];
        list_page.forEach((value) => {
          check_page.push(value)
        })
      }
    }

    var select_page2 = document.querySelectorAll('.e-numericitem');
    if (select_page2.length > 0) {
      if (select_page2.length > 0) {
        for (var index = 0; index < select_page2.length; index++) {
          select_page2[index].style.display = "none";
        }
      }

      if (check_page.length > 0) {
        //eslint-disable-next-line array-callback-return
        check_page.map((value) => {
          var select_page = document.querySelectorAll(`[id=page-${value}]`);
          if (select_page.length > 0) {
            for (var index2 = 0; index2 < select_page.length; index2++) {
              select_page[index2].style.display = "";
            }
          }
        })
      }
    }

    return (
      <div style={{ height: "35px", textAlign: "center" }}>
        <div className="e-gridpager e-control e-pager e-lib" style={{ display: "flex", width: "100%" }} >
          <div style={{ width: "32.5%", background: "#E1E7EB", borderRadius: "17.5px 5px 5px 17.5px" }}>
            <div className="e-pagercontainer" role="navigation" >
              <div className={dataMeta.page === 1 ? "e-first e-icons e-icon-first e-firstpagedisabled e-disable" : "e-first e-icons e-icon-first e-firstpage e-pager-default"} onClick={() => clickPage(1, "first")} title="Go to first page" aria-label="Go to first page" tabIndex="-1" index="1" aria-owns="grid_940258773_0_content_table"></div>
              <div className={dataMeta.page === 1 ? "e-prev e-icons e-icon-prev e-prevpagedisabled e-disable" : "e-prev e-icons e-icon-prev e-prevpage e-pager-default"} onClick={() => clickPage(dataMeta.page - 1, "prev")} title="Go to previous page" aria-label="Go to previous page" tabIndex="-1" index="0" aria-owns="grid_940258773_0_content_table"></div>
              <div className="e-numericcontainer">{list}</div>
              <div className={dataMeta.page === dataMeta.page_count ? "e-next e-icons e-icon-next e-nextpagedisabled e-disable" : "e-next e-icons e-icon-next e-nextpage e-pager-default"} onClick={() => clickPage(dataMeta.page + 1, "next")} title="Go to next page" aria-label="Go to next page" tabIndex="-1" index="2" aria-owns="grid_940258773_0_content_table"></div>
              <div className={dataMeta.page === dataMeta.page_count ? "e-last e-icons e-icon-last e-lastpagedisabled e-disable" : "e-last e-icons e-icon-last e-lastpage e-pager-default"} onClick={() => clickPage(dataMeta.page_count, "last")} title="Go to last page" aria-label="Go to last page" tabIndex="-1" index="2" aria-owns="grid_940258773_0_content_table"></div>
            </div>
          </div>
          <div style={{ width: "35%", background: "#E1E7EB", marginLeft: "2px", marginRight: "2px", borderRadius: "5px" }}>
            <div className="e-pagerconstant">
              <span className="e-constant" style={{ display: "flex" }}>
                <span style={{ marginRight: "6px" }}>
                  <span style={{ display: "flex", flexDirection: "column", position: "absolute", marginTop: "7px" }}>
                    <span className="e-icons icon-arrowhead-up"
                      style={{ cursor: "pointer", color: '#516573', fontSize: "10px" }}
                      onClick={() => handleNumberOfPage("+")}
                    />
                    <span className="e-icons icon-arrowhead-down"
                      style={{ cursor: "pointer", color: '#516573', fontSize: "10px" }}
                      onClick={() => handleNumberOfPage("-")}
                    />
                  </span>
                </span>
                <span className="nt_custom" style={{ fontSize: "17px", fontWeight: "800", color: "#516573", width: "35px", marginRight: "6px", marginLeft: "6px" }}>
                  <NumericTextBoxComponent style={{ textAlign: "center", padding: "0px" }} step={5} format='####' value={numberOfPageRef.current} showSpinButton={false} max={999} min={1} onChange={(e) => changeNumOfData(e)} />
                </span>
                <span style={{ color: "#516573" }}>
                  {Items_per_page} (total {dataMeta ? dataMeta.filter_count : 0} records)
                </span>
              </span>
            </div>
          </div>
          <div className="e-parentmsgbar" style={{ width: "32.5%", background: "#E1E7EB", borderRadius: "5px 17.5px 17.5px 5px" }}>
            <span className="e-pagenomsg">{dataMeta ? <span>{dataMeta.page} {of} {dataMeta.page_count} {pages} </span> : null}</span>
          </div>
        </div>
      </div>
    )
  }

  const handleNumberOfPage = (props) => {
    if (numberOfPageRef.current - 1 > 0) {
      var value = numberOfPageRef.current;
      if (props === "+") {
        value = numberOfPageRef.current + 5;
      } else {
        if (numberOfPageRef.current > 5) {
          value = numberOfPageRef.current - 5;
        } else {
          value = 1;
        }
      }
      setNumberOfPage(value);
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        numOfPage.staff = value;
        window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
        getData();
      }, 1000)
    }
  }

  const clickPage = (page, type) => {
    var status = false;
    if (type === "num") {
      if (dataMeta.page !== page) { status = true; }
    } else if (type === "first" || type === "prev") {
      if (dataMeta.page !== 1) { status = true; }
    } else if (type === "next" || type === "last") {
      if (dataMeta.page !== dataMeta.page_count) { status = true; }
    }

    if (status) {
      setStateFiltering({ ...stateFilteringRef.current, page: page })
      getData();
    }
  }

  const onColumnMenuOpen = (args) => {
    for (const item of args.items) {
      if (item.text === 'Autofit all columns' || item.text === 'Autofit this column') {
        item.hide = true;
      }
    }
  }

  // {/* ================== Footer Template ========================================== */}

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div>
            <div className="header">
              <div className="content-header">{Staff_List}</div>
              <div className="content-searching hide-content">
                <div style={{ display: 'flex', width: "100%" }}>
                  <div style={{ width: "250px", marginRight: "10px" }}>
                    <TextBoxComponent
                      cssClass="e-outline searching-text"
                      className="searching-input"
                      id="searching-input"
                      placeholder={Enter_staff_name}
                      onChange={(e) => handleChangeFilter(e, "name")}
                      onBlur={(e) => searchStaff(e)}
                      showClearButton="true"
                      value={stateFiltering.name}
                    />
                  </div>
                  <div>
                    <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddStaff}>
                      <span className="btn-text-custom">{Staff}</span>
                    </ButtonComponent>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-main">
              {data ? data.length > 0 ?
                <div className="control-menu">
                  {/* ================== Grid View ========================================== */}

                  <div style={{ marginTop: "25px", marginBottom: "20px", marginLeft: "30px", marginRight: "0px" }}>
                    <div className="grid-container">
                      <div>
                        <GridComponent
                          width="100%"
                          className="grid-list"
                          dataSource={data}
                          editSettings={editSettings}
                          allowSorting={true}
                          filterSettings={filterOptions}
                          allowFiltering={true}
                          ref={grid}
                          allowTextWrap={true}
                          textWrapSettings={{ wrapMode: "Content" }}
                          allowResizing={true}
                          allowEditing={false}
                          gridLines='Vertical'
                          showColumnMenu={true}
                          columnMenuOpen={onColumnMenuOpen}
                          selectionSettings={setting}
                          clipMode="EllipsisWithTooltip"
                        >
                          <ColumnsDirective>
                            {/* <ColumnDirective customAttributes={{ id: 'first' }} width="10%" headerText={Photo} textAlign='Center' editTemplate={imageEditTemplate} template={imageEditTemplate}></ColumnDirective> */}
                            <ColumnDirective customAttributes={{ id: 'first' }} width="28%" field='name' headerText={Name} template={nameTemplate} validationRules={{ required: true }}></ColumnDirective>
                            <ColumnDirective width="20%" field='email' headerText={Email}></ColumnDirective>
                            <ColumnDirective width="15%" field='mobile_number' headerText={Mobile_Number} ></ColumnDirective>
                            <ColumnDirective width="15%" field='modified_by.first_name' headerText={Modified_by} ></ColumnDirective>
                            <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="12%" template={buttonTemplate} editTemplate={buttonTemplate}></ColumnDirective>
                          </ColumnsDirective>
                          <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                        </GridComponent>
                      </div>
                    </div>
                  </div>
                </div>

                : <div style={{ textAlign: "center", marginTop: "30px" }}>{No_data_found}</div>
                : <div style={{ textAlign: "center", marginTop: "30px" }}>{No_data_found}</div>
              }
            </div>

            {/* {dataMeta ? dataMeta.filter_count > 15 ? pageTemplate() : null : null} */}
            <div className="content-pagination">
              <div className="content-pagination-inside">
                {dataMeta ? dataMeta.filter_count > 0 ? pageTemplate() : null : null}
              </div>
            </div>
            {/* ================== Dialog ========================================== */}

            <DialogComponent
              id="dialogAddStaff"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogAddStaff}
              header={Staff_Biodata}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Update, func: addStaff, icon: "icon-check-mark-01", status: "e-success" },
                { name: Cancel, func: handleCloseDialogAddStaff, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogAddStaff}
            >
              <div>
                <div>
                  <b>*{Name}</b>
                  <TextBoxComponent
                    name="name"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddStaff(e, "name")}
                    value={state.name}
                  />
                  {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <b>*{Email}</b>
                  <TextBoxComponent
                    name="email"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddStaff(e, "email")}
                    value={state.email}
                  />
                  {error.email === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  {errorEmail === true ? <span style={{ color: 'red' }}>{Email_has_already_been_taken}</span> : ''}
                  <br /><br />
                  <b>*Password</b>
                  <TextBoxComponent
                    type="password"
                    name="password"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddStaff(e, "password")}
                    value={state.password}
                  />
                  {error.password === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <b>{Mobile_Number}</b>
                  <TextBoxComponent
                    name="mobile_number"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddStaff(e, "mobile_number")}
                    value={state.mobile_number}
                  />
                  <br /><br />
                  <b>{Language}</b>
                  <DropDownListComponent
                    name="lang"
                    cssClass="e-outline"
                    dataSource={[{ id: "id", name: "Indonesia" }, { id: "en", name: "English" }]}
                    fields={{ text: 'name', value: 'id' }}
                    value={state.lang}
                    select={(e) => handleChangeAddStaff(e, "lang")}
                  ></DropDownListComponent>
                </div>
              </div>
            </DialogComponent>

            <DialogComponent
              id="dialogEditStaff"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogEditStaff}
              header={Staff_Biodata}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Update, func: editStaff, icon: "icon-check-mark-01", status: "e-success" },
                { name: Cancel, func: handleCloseDialogEditStaff, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogEditStaff}
            >
              <div>
                <div>
                  <b>*{Name}</b>
                  <TextBoxComponent
                    name="name"
                    cssClass='e-outline'
                    change={(e) => handleChangeEditStaff(e, "name")}
                    value={state.name}
                  />
                  {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <b>*{Email}</b>
                  <TextBoxComponent
                    name="email"
                    cssClass='e-outline'
                    change={(e) => handleChangeEditStaff(e, "email")}
                    value={state.email}
                  />
                  {error.email === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  {errorEmail === true ? <span style={{ color: 'red' }}>{Email_has_already_been_taken}</span> : ''}
                  <br /><br />
                  <b>*Password</b>
                  <TextBoxComponent
                    type="password"
                    name="password"
                    cssClass='e-outline'
                    change={(e) => handleChangeEditStaff(e, "password")}
                    value={state.password}
                  />
                  <br /><br />
                  <b>{Mobile_Number}</b>
                  <TextBoxComponent
                    name="mobile_number"
                    cssClass='e-outline'
                    change={(e) => handleChangeEditStaff(e, "mobile_number")}
                    value={state.mobile_number}
                  />
                  <br /><br />
                  <b>{Language}</b>
                  <DropDownListComponent
                    name="lang"
                    cssClass="e-outline"
                    dataSource={[{ id: "id", name: "Indonesia" }, { id: "en", name: "English" }]}
                    fields={{ text: 'name', value: 'id' }}
                    value={state.lang}
                    select={(e) => handleChangeEditStaff(e, "lang")}
                  ></DropDownListComponent>
                </div>
              </div>
            </DialogComponent>

            <DialogComponent
              id="dialogDeleteStaff"
              width='35%'
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogDeleteStaff}
              header={Delete_Confirmation}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Delete, func: deleteStaff, icon: "icon-delete-05", status: "e-warning" },
                { name: Cancel, func: handleCloseDialogDeleteStaff, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogDeleteStaff}
            >
              <div>
                {selectedData ?
                  <div style={{ fontSize: "14px" }}>
                    {Are_you_sure_you_want_to_delete_the_data_of_staff} ({selectedData.name})?
                    <br /><br />
                    <p style={{ color: "red" }}>
                      {If_you_want_to_delete_the_staff_fill_in_the_name_below}:
                    </p>
                    <TextBoxComponent
                      cssClass="e-outline"
                      name="delete_staff"
                      change={(e) => handleChangeWord(e)}
                      value={deleteWord}
                    ></TextBoxComponent>
                  </div>
                  : ''}
              </div>
            </DialogComponent>
          </div>
        </div>
      }
    </div>
  );
}



export default StaffList;