import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { InPlaceEditorComponent } from '@syncfusion/ej2-react-inplace-editor';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

import './index.css';


const Biodata = () => {
  const { id } = useParams();

  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));

  const [Name] = useState(i18next.t('Pages.data.Name'));
  const [Nickname] = useState(i18next.t('Pages.data.Nickname'));
  const [Gender] = useState(i18next.t('Pages.data.Gender'));
  const [Place_of_Birth] = useState(i18next.t('Pages.data.Place_of_Birth'));
  const [Date_of_Birth] = useState(i18next.t('Pages.data.Date_of_Birth'));
  const [Male] = useState(i18next.t('Pages.data.Male'));
  const [Female] = useState(i18next.t('Pages.data.Female'));
  const [Religion] = useState(i18next.t('Pages.data.Religion'));
  const [Islam] = useState(i18next.t('Pages.data.Islam'));
  const [Christian_Protestant] = useState(i18next.t('Pages.data.Christian_Protestant'));
  const [Catholic] = useState(i18next.t('Pages.data.Catholic'));
  const [Buddha] = useState(i18next.t('Pages.data.Buddha'));
  const [Hindu] = useState(i18next.t('Pages.data.Hindu'));
  const [Confucianism] = useState(i18next.t('Pages.data.Confucianism'));
  const [Others] = useState(i18next.t('Pages.data.Others'));
  const [Single] = useState(i18next.t('Pages.data.Single'));
  const [Married] = useState(i18next.t('Pages.data.Married'));
  const [Widow_Widower] = useState(i18next.t('Pages.data.Widow_Widower'));
  const [Notes] = useState(i18next.t('Pages.data.Notes'));

  const [Phone_Number] = useState(i18next.t('Pages.data.Phone_Number'));
  const [Mobile_Number] = useState(i18next.t('Pages.data.Mobile_Number'));

  const [Email] = useState(i18next.t('Pages.contact_data.Email'));
  const [Address] = useState(i18next.t('Pages.contact_data.Address'));
  const [Regency_City] = useState(i18next.t('Pages.contact_data.Regency_City'));
  const [Province] = useState(i18next.t('Pages.contact_data.Province'));
  const [Postal_Code] = useState(i18next.t('Pages.contact_data.Postal_Code'));
  const [Suburb_Village] = useState(i18next.t('Pages.contact_data.Suburb_Village'));
  const [District] = useState(i18next.t('Pages.contact_data.District'));
  const [Marital_Status] = useState(i18next.t('Pages.contact_data.Marital_Status'));
  const [Nationality] = useState(i18next.t('Pages.contact_data.Nationality'));
  const [Everyday_Language] = useState(i18next.t('Pages.contact_data.Everyday_Language'));
  const [Education_Level] = useState(i18next.t('Pages.contact_data.Education_Level'));
  const [Occupation] = useState(i18next.t('Pages.contact_data.Occupation'));
  const [Company_Name] = useState(i18next.t('Pages.contact_data.Company_Name'));
  const [Field_of_Business] = useState(i18next.t('Pages.contact_data.Field_of_Business'));
  const [Position] = useState(i18next.t('Pages.contact_data.Position'));
  const [Monthly_Income] = useState(i18next.t('Pages.contact_data.Monthly_Income'));
  const [Special_Needs] = useState(i18next.t('Pages.contact_data.Special_Needs'));

  //data
  const [data, setData] = useState(null);

  //set initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loadingBiodata, setLoadingBiodata] = useState(false);

  //other component setting
  const gender = [{ id: "Male", name: Male }, { id: "Female", name: Female }]
  const religion = [
    { id: 'Islam', text: Islam },
    { id: 'Christian/Protestant', text: Christian_Protestant },
    { id: 'Catholic', text: Catholic },
    { id: 'Buddha', text: Buddha },
    { id: 'Hindu', text: Hindu },
    { id: 'Confucianism', text: Confucianism },
    { id: 'Others', text: Others },
  ];
  const marital_status = [
    { id: 'Single', text: Single },
    { id: 'Married', text: Married },
    { id: 'Widow/Widower', text: Widow_Widower }
  ];

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoadingBiodata(true);
      getContact();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get contact data
  const getContact = async () => {
    var result = await directus.getItems('contacts',
      {
        fields: '*.*.*',
        filter: {
          id: id
        }
      }
    );

    setData(result.data[0]);
    setLoadingBiodata(false);
  }

  // {/* ================== Inplace Editor Component ========================================== */ }
  const actionSuccess = async (type, event) => {
    try {
      var value = '';
      if (type === "dob") {
        value = event.value ? moment(new Date(event.value)).format("YYYY-MM-DD") : null;
      } else if (type === "gender") {
        value = getGender(event.value, "id");
      } else if (type === "religion") {
        value = getTranslation(event.value, "id", religion);
      } else if (type === "marital_status") {
        value = getTranslation(event.value, "id", marital_status);
      } else {
        value = event.value;
      }

      if (type === "income") {
        if (value === "") { value = null }
      }

      var result = await directus.updateItem('contacts', id,
        { [type]: value }
      );

      var description = data.name;
      if (type === "name" && data.name !== value) {
        description = data.name + " --> " + value;
      }

      const body_activity = {
        action: "Update",
        category: 10,
        description: description,
        status: "published"
      }
      await directus.createItem('activity_log', body_activity);

      if (!result.error) {
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        if (type === "name" || type === "nickname" || type === "phone_number" || type === "mobile_number" || type === "address" || type === "notes") {
          EventEmitter.emit('reloadContactData', { text: "click" });
        }
        if (type === "mobile_number") {
          EventEmitter.emit('reloadStudentData', { text: "click" });
        }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Translate Gender, Religion, Marital Status Data ========================================== */ }
  const getGender = (props, type) => {
    var gender2 = props ? props : null;
    if (gender) {
      gender.forEach((value) => {
        if (type === "name") {
          if (value.id === gender2) {
            gender2 = value.name
          }
        }
        if (type === "id") {
          if (value.name === gender2) {
            gender2 = value.id
          }
        }
      })
    }
    return "" + gender2
  }

  const getTranslation = (props, type, name) => {
    var oldData = props ? props : null;

    if (name) {
      name.forEach((value) => {
        if (type === "name") {
          if (value.id === oldData) {
            oldData = value.text
          }
        }
        if (type === "id") {
          if (value.text === oldData) {
            oldData = value.id
          }
        }
      })
    }

    return "" + oldData
  }

  // {/* ================== Main Content ========================================== */ }

  return (
    <div id="biodata" style={{ alignContent: "center", color: "#333", minHeight: "600px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }}>
      {loadingBiodata ? <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} /> :
        <div>
          {data ?
            <table id="table_biodata" style={{ marginLeft: "auto", marginRight: "auto", marginTop: "20px", border: "1px solid #ccc", borderStyle: "1px solid #ccc" }}>
              <tbody>
                <tr>
                  <td style={{ width: "20%" }}>{Name}</td>
                  <td><InPlaceEditorComponent id='name' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' name='name' data-underline='false' editableOn="DblClick" value={data.name} model={{ placeholder: Name }} actionSuccess={(e) => actionSuccess("name", e)} validationRules={{ name: { required: true } }} /></td>
                </tr>
                <tr>
                  <td>{Nickname}</td>
                  <td><InPlaceEditorComponent id='nickname' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.nickname} model={{ placeholder: Nickname }} actionSuccess={(e) => actionSuccess("nickname", e)} /></td>
                </tr>
                <tr>
                  <td>{Mobile_Number}</td>
                  <td><InPlaceEditorComponent id='mobile_number' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.mobile_number} model={{ placeholder: Mobile_Number }} actionSuccess={(e) => actionSuccess("mobile_number", e)} /></td>
                </tr>
                <tr>
                  <td>{Phone_Number}</td>
                  <td><InPlaceEditorComponent id='phone_number' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.phone_number} model={{ placeholder: Phone_Number }} actionSuccess={(e) => actionSuccess("phone_number", e)} /></td>
                </tr>
                <tr>
                  <td>{Email}</td>
                  <td><InPlaceEditorComponent id='email' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.email} model={{ placeholder: Email }} actionSuccess={(e) => actionSuccess("email", e)} /></td>
                </tr>
                <tr>
                  <td>{Address}</td>
                  <td><InPlaceEditorComponent id='address' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.address} model={{ placeholder: Address }} actionSuccess={(e) => actionSuccess("address", e)} /></td>
                </tr>
                <tr>
                  <td>RT</td>
                  <td><InPlaceEditorComponent id='address_rt' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.address_rt} model={{ placeholder: 'RT' }} actionSuccess={(e) => actionSuccess("address_rt", e)} /></td>
                </tr>
                <tr>
                  <td>RW</td>
                  <td><InPlaceEditorComponent id='address_rw' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.address_rw} model={{ placeholder: 'RW' }} actionSuccess={(e) => actionSuccess("address_rw", e)} /></td>
                </tr>
                <tr>
                  <td>{Regency_City}</td>
                  <td><InPlaceEditorComponent id='address_city' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.address_city} model={{ placeholder: Regency_City }} actionSuccess={(e) => actionSuccess("address_city", e)} /></td>
                </tr>
                <tr>
                  <td>{Province}</td>
                  <td><InPlaceEditorComponent id='address_state' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.address_state} model={{ placeholder: Province }} actionSuccess={(e) => actionSuccess("address_state", e)} /></td>
                </tr>
                <tr>
                  <td>{Postal_Code}</td>
                  <td><InPlaceEditorComponent id='address_post_code' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.address_post_code} model={{ placeholder: Postal_Code }} actionSuccess={(e) => actionSuccess("address_post_code", e)} /></td>
                </tr>
                <tr>
                  <td>{Suburb_Village}</td>
                  <td><InPlaceEditorComponent id='address_town' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.address_town} model={{ placeholder: Suburb_Village }} actionSuccess={(e) => actionSuccess("address_town", e)} /></td>
                </tr>
                <tr>
                  <td>{District}</td>
                  <td><InPlaceEditorComponent id='address_district' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.address_district} model={{ placeholder: District }} actionSuccess={(e) => actionSuccess("address_district", e)} /></td>
                </tr>
                <tr>
                  <td>{Gender}</td>
                  <td><InPlaceEditorComponent id='gender' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" type='DropDownList' value={data.gender ? getGender(data.gender, "name") : null} model={{ placeholder: Gender, dataSource: gender, fields: { text: 'name', value: 'name' } }} actionSuccess={(e) => actionSuccess("gender", e)} /></td>
                </tr>
                <tr>
                  <td>{Marital_Status}</td>
                  <td><InPlaceEditorComponent id='marital_status' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" type='DropDownList' value={data.marital_status ? getTranslation(data.marital_status, "name", marital_status) : null} model={{ placeholder: Marital_Status, dataSource: marital_status, fields: { text: 'text', value: 'text' } }} actionSuccess={(e) => actionSuccess("marital_status", e)} /></td>
                </tr>
                <tr>
                  <td>{Place_of_Birth}</td>
                  <td><InPlaceEditorComponent id='pob' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.pob} model={{ placeholder: Place_of_Birth }} actionSuccess={(e) => actionSuccess("pob", e)} /></td>
                </tr>
                <tr>
                  <td>{Date_of_Birth}</td>
                  <td><InPlaceEditorComponent id='dob' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' type="Date" editableOn="DblClick" value={data.dob ? data.dob : null} model={{ placeholder: Date_of_Birth, format: "dd-MMM-yyyy" }} actionSuccess={(e) => actionSuccess("dob", e)} /></td>
                </tr>
                <tr>
                  <td>NIK</td>
                  <td><InPlaceEditorComponent id='id_number' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.id_number} model={{ placeholder: 'NIK' }} actionSuccess={(e) => actionSuccess("id_number", e)} /></td>
                </tr>
                <tr>
                  <td>{Nationality}</td>
                  <td><InPlaceEditorComponent id='nationality' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.nationality} model={{ placeholder: Nationality }} actionSuccess={(e) => actionSuccess("nationality", e)} /></td>
                </tr>
                <tr>
                  <td>{Religion}</td>
                  <td><InPlaceEditorComponent id='religion' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" type='DropDownList' value={data.religion ? getTranslation(data.religion, "name", religion) : null} model={{ placeholder: Gender, dataSource: religion, fields: { text: 'text', value: 'text' } }} actionSuccess={(e) => actionSuccess("religion", e)} /></td>
                </tr>
                <tr>
                  <td>{Everyday_Language}</td>
                  <td><InPlaceEditorComponent id='home_language' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.home_language} model={{ placeholder: Everyday_Language }} actionSuccess={(e) => actionSuccess("home_language", e)} /></td>
                </tr>
                <tr>
                  <td>{Education_Level}</td>
                  <td><InPlaceEditorComponent id='education_level' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.education_level} model={{ placeholder: Education_Level }} actionSuccess={(e) => actionSuccess("education_level", e)} /></td>
                </tr>
                <tr>
                  <td>{Occupation}</td>
                  <td><InPlaceEditorComponent id='occupation' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.occupation} model={{ placeholder: Occupation }} actionSuccess={(e) => actionSuccess("occupation", e)} /></td>
                </tr>
                <tr>
                  <td>{Company_Name}</td>
                  <td><InPlaceEditorComponent id='workplace_name' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.workplace_name} model={{ placeholder: Company_Name }} actionSuccess={(e) => actionSuccess("workplace_name", e)} /></td>
                </tr>
                <tr>
                  <td>{Field_of_Business}</td>
                  <td><InPlaceEditorComponent id='workplace_type' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.workplace_type} model={{ placeholder: Field_of_Business }} actionSuccess={(e) => actionSuccess("workplace_type", e)} /></td>
                </tr>
                <tr>
                  <td>{Position}</td>
                  <td><InPlaceEditorComponent id='workplace_role' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.workplace_role} model={{ placeholder: Position }} actionSuccess={(e) => actionSuccess("workplace_role", e)} /></td>
                </tr>
                <tr>
                  <td>{Monthly_Income}</td>
                  <td><InPlaceEditorComponent id='income' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" type='Numeric' value={data.income} model={{ placeholder: Monthly_Income, format: "IDR ###############" }} actionSuccess={(e) => actionSuccess("income", e)} /></td>
                </tr>
                <tr>
                  <td>{Special_Needs}</td>
                  <td><InPlaceEditorComponent id='special_needs' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.special_needs} model={{ placeholder: Special_Needs }} actionSuccess={(e) => actionSuccess("special_needs", e)} /></td>
                </tr>
                <tr>
                  <td>{Notes}</td>
                  <td><InPlaceEditorComponent id='notes' emptyText={'-'} actionOnBlur="Cancel" mode='Inline' data-underline='false' editableOn="DblClick" value={data.notes} model={{ placeholder: Notes }} actionSuccess={(e) => actionSuccess("notes", e)} /></td>
                </tr>
              </tbody>
            </table>
            : ''}
          <br />
        </div>
      }
    </div>
  )
}

export default Biodata;