import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { Query } from '@syncfusion/ej2-data'
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as link from 'src/constants/link';
import * as permission from 'src/utils/Permission';

const ActivityDetail = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Edit_2] = useState(i18next.t('Pages.button.Edit'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  const [Select_Type] = useState(i18next.t('Pages.data.Select_Type'));
  const [Select_Teacher] = useState(i18next.t('Pages.data.Select_Teacher'));
  const [Select_Student] = useState(i18next.t('Pages.data.Select_Student'));
  const [Select_Room] = useState(i18next.t('Pages.data.Select_Room'));
  const [Please_select_activity_type] = useState(i18next.t('Pages.data.Please_select_activity_type'));
  const [Please_select_teacher] = useState(i18next.t('Pages.data.Please_select_teacher'));
  const [Please_select_student] = useState(i18next.t('Pages.data.Please_select_student'));
  const [Please_select_room] = useState(i18next.t('Pages.data.Please_select_room'));
  const [Please_upload_the_photo] = useState(i18next.t('Pages.data.Please_upload_the_photo'));

  const [Activities] = useState(i18next.t('Pages.activity_data.Activities'));
  // const [Photo] = useState(i18next.t('Pages.activity_data.Photo'));
  const [Name] = useState(i18next.t('Pages.activity_data.Name'));
  const [Date_2] = useState(i18next.t('Pages.activity_data.Date'));
  const [Start_Time] = useState(i18next.t('Pages.activity_data.Start_Time'));
  const [End_Time] = useState(i18next.t('Pages.activity_data.End_Time'));
  const [Student_Participants] = useState(i18next.t('Pages.activity_data.Student_Participants'));
  const [Teacher_Participants] = useState(i18next.t('Pages.activity_data.Teacher_Participants'));
  // const [Action] = useState(i18next.t('Pages.activity_data.Action'));
  const [Activity_Type] = useState(i18next.t('Pages.activity_data.Activity_Type'));
  const [Activity_Name] = useState(i18next.t('Pages.activity_data.Activity_Name'));
  const [Description] = useState(i18next.t('Pages.activity_data.Description'));
  const [Transportation_Fee] = useState(i18next.t('Pages.activity_data.Transportation_Fee'));
  const [Parent_involvement] = useState(i18next.t('Pages.activity_data.Parent_involvement'));
  const [Student_Registered] = useState(i18next.t('Pages.activity_data.Student_Registered'));
  const [Students_Attended] = useState(i18next.t('Pages.activity_data.Students_Attended'));
  const [Total_Paid] = useState(i18next.t('Pages.activity_data.Total_Paid'));
  const [Registration_Date] = useState(i18next.t('Pages.activity_data.Registration_Date'));
  const [Coordinator] = useState(i18next.t('Pages.activity_data.Coordinator'));
  const [Attended] = useState(i18next.t('Pages.activity_data.Attended'));
  const [Parent_Attended] = useState(i18next.t('Pages.activity_data.Parent_Attended'));
  const [List] = useState(i18next.t('Pages.activity_data.List'));
  const [Paid] = useState(i18next.t('Pages.activity_data.Paid'));
  const [Achievement] = useState(i18next.t('Pages.activity_data.Achievement'));
  const [Room_Used] = useState(i18next.t('Pages.activity_data.Room_Used'));
  const [Teacher_Group] = useState(i18next.t('Pages.activity_data.Teacher_Group'));
  const [Payment_Date] = useState(i18next.t('Pages.activity_data.Payment_Date'));
  const [Payment_Notes] = useState(i18next.t('Pages.activity_data.Payment_Notes'));
  const [Student_Group] = useState(i18next.t('Pages.activity_data.Student_Group'));
  const [Amount_Paid] = useState(i18next.t('Pages.activity_data.Amount_Paid'));
  const [Students] = useState(i18next.t('Pages.activity_data.Students'));
  const [Student] = useState(i18next.t('Pages.activity_data.Student'));
  const [Teachers] = useState(i18next.t('Pages.activity_data.Teachers'));
  const [Teacher] = useState(i18next.t('Pages.activity_data.Teacher'));
  const [Rooms] = useState(i18next.t('Pages.activity_data.Rooms'));
  const [Are_you_sure_you_want_to_delete_the_data_of_teacher_participants] = useState(i18next.t('Pages.activity_data.Are_you_sure_you_want_to_delete_the_data_of_teacher_participants'));
  const [Are_you_sure_you_want_to_delete_the_data_of_student_participants] = useState(i18next.t('Pages.activity_data.Are_you_sure_you_want_to_delete_the_data_of_student_participants'));
  const [Are_you_sure_you_want_to_delete_the_data_of_room_used] = useState(i18next.t('Pages.activity_data.Are_you_sure_you_want_to_delete_the_data_of_room_used'));
  const [All_Students_Present] = useState(i18next.t('Pages.activity_data.All_Students_Present'));
  const [All_Parents_Present] = useState(i18next.t('Pages.activity_data.All_Parents_Present'));
  const [Edit_Activity] = useState(i18next.t('Pages.activity_data.Edit_Activity'));
  const [Change_Photo] = useState(i18next.t('Pages.activity_data.Change_Photo'));
  const [Confirmation] = useState(i18next.t('Pages.activity_data.Confirmation'));
  const [Are_you_sure_you_want_to_change_all_attendance_status_of_parents_attend] = useState(i18next.t('Pages.activity_data.Are_you_sure_you_want_to_change_all_attendance_status_of_parents_attend'));
  const [Are_you_sure_you_want_to_change_all_attendance_status_of_students_attend] = useState(i18next.t('Pages.activity_data.Are_you_sure_you_want_to_change_all_attendance_status_of_students_attend'));
  const [All_Teachers] = useState(i18next.t('Pages.activity_data.All_Teachers'));
  const [Homeroom_Teacher_Form] = useState(i18next.t('Pages.activity_data.Homeroom_Teacher_Form'));
  const [All_Students] = useState(i18next.t('Pages.activity_data.All_Students'));

  //data
  let [data, setData] = useState(null);
  let [dataActivityType, setDataActivityType] = useState(null);
  let [dataActivityTeacher, setDataActivityTeacher] = useState(null);
  let [dataTeacher, setDataTeacher] = useState(null);
  let [dataActivityStudent, setDataActivityStudent] = useState(null);
  let [dataStudent, setDataStudent] = useState(null);
  let [dataActivityRoom, setDataActivityRoom] = useState(null);
  let [dataRoom, setDataRoom] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  let tooltip = useRef(false);

  //dialog activity
  let dialogEditActivity = useRef(false);

  //dialog teacher
  let dialogDeleteTeacher = useRef(false);
  let dialogAddTeacher = useRef(false);
  let dialogEditTeacher = useRef(false);
  let mt_teacher = useRef(false);

  //dialog teacher
  let dialogDeleteStudent = useRef(false);
  let dialogAddStudent = useRef(false);
  let dialogEditStudent = useRef(false);
  let mt_student = useRef(false);

  //dialog room
  let dialogDeleteRoom = useRef(false);
  let dialogAddRoom = useRef(false);
  let mt_room = useRef(false);

  //dialog student present
  let dialogStudentPresent = useRef(false);
  let dialogParentPresent = useRef(false);

  //upload image
  let my_form2 = useRef(null);
  let uploadObj = useRef(null);
  let dialogChangePhoto = useRef(false);
  const [image, setImage] = useState(null);
  const [oldImage, setOldImage] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const asyncSettings = { saveUrl: link.saveUrl, removeUrl: link.removeUrl };

  //other component setting
  const items = [{ id: 1, text: All_Teachers }, { id: 2, text: Homeroom_Teacher_Form }]
  const itemsStudent = [{ id: 1, text: All_Students }]

  //grid component setting
  const grid = useRef();
  const setting = { mode: 'Both' };
  const editSettings = {
    allowEditing: false,
    allowAdding: true,
    allowDeleting: true,
    // mode: 'Batch' 
    mode: 'Normal'
  };

  //set state teacher
  const [stateTeacher, setStateTeacher, stateTeacherRef] = useStateRef({
    id: null,
    teachers_id: null,
    attended: 0,
    coordinator: 0,
    registration_date: new Date(),
    payment_date: null,
    payment_notes: null
  });

  //set state student
  const [stateStudent, setStateStudent, stateStudentRef] = useStateRef({
    id: null,
    students_id: null,
    registration_date: new Date(),
    payment_date: null,
    amount_paid: 0,
    payment_notes: null,
    attended: 0,
    description: null,
    achievement: null,
    parent_attended: 0
  });

  //set state activity
  const [stateActivity, setStateActivity] = useState({
    status: "published",
    name: null,
    type: null,
    date: null,
    start_time: null,
    end_time: null,
    description: null,
    transportation_fee: 0,
    parent_involvement: 0,
    image: null
  });

  //set state filtering
  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({ name: null, teachers: [], students: [], rooms: [] });

  //set error
  const [error, setError, errorRef] = useStateRef({ type: false, name: false, date: false, start_time: false, end_time: false, teacher: false, student: false, room: false, image: false });
  /* eslint-enable no-unused-vars*/

  const getInitialValue = () => {
    setStateActivity({
      status: "published",
      name: null,
      type: null,
      date: null,
      start_time: null,
      end_time: null,
      description: null,
      transportation_fee: 0,
      parent_involvement: 0
    });

    setStateFiltering({
      name: null,
      teachers: [],
      students: [],
      rooms: []
    })

    setStateTeacher({
      teachers_id: null,
      attended: 0,
      coordinator: 0,
      registration_date: new Date(),
      payment_date: null,
      payment_notes: null
    })

    setStateStudent({
      id: null,
      students_id: null,
      attended: 0,
      achievement: null,
      registration_date: new Date(),
      payment_date: null,
      amount_paid: 0,
      payment_notes: null,
      description: null,
      parent_attended: 0
    })
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  useEffect(() => {
    if (loadingButtonRef.current === false) {

      if (dataButtonRef.current === "edit_activity") {
        dialogEditActivity.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }
      if (dataButtonRef.current === "change_photo") {
        dialogChangePhoto.current.visible = false;
        uploadObj.current.clearAll();
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "add_teacher") {
        dialogAddTeacher.current.visible = false;
        mt_teacher.current.value = [];
        setStateTeacher({ teachers_id: null, attended: 0, coordinator: 0, registration_date: new Date(), payment_date: null, payment_notes: null });
        setStateFiltering({ ...stateFilteringRef.current, teachers: [] })
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "edit_teacher") {
        dialogAddTeacher.current.visible = false;
        setStateTeacher({ teachers_id: null, attended: 0, coordinator: 0, registration_date: new Date(), payment_date: null, payment_notes: null });
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_teacher") {
        dialogDeleteTeacher.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "add_student") {
        dialogAddStudent.current.visible = false;
        mt_student.current.value = [];
        setStateStudent({ id: null, students_id: null, attended: 0, achievement: null, registration_date: new Date(), payment_date: null, amount_paid: 0, payment_notes: null, description: null, parent_attended: 0 });
        setStateFiltering({ ...stateFilteringRef.current, students: [] })
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "edit_student") {
        dialogEditStudent.current.visible = false;
        setStateStudent({ id: null, students_id: null, attended: 0, achievement: null, registration_date: new Date(), payment_date: null, amount_paid: 0, payment_notes: null, description: null, parent_attended: 0 });
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_student") {
        dialogDeleteStudent.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "add_room") {
        dialogAddRoom.current.visible = false;
        mt_room.current.value = [];
        setStateFiltering({ ...stateFilteringRef.current, rooms: [] })
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_room") {
        dialogDeleteRoom.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "student_present") {
        dialogStudentPresent.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "parent_present") {
        dialogParentPresent.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

    }
  })

  // {/* ================== Get All Data ========================================== */ }
  //get list of activities
  const getData = async () => {
    try {
      var name = { "nnull": null };
      if (stateFiltering.name !== null && stateFiltering.name !== "") {
        name = { "contains": stateFilteringRef.current.name };
      }

      var result = await directus.getItems('activities',
        {
          fields: "*.*.*",
          // sort: "name",
          filter: {
            name: name,
            status: "published",
            id: id
          }
        });

      setData(result.data[0]);
      var img_activity = result.data[0].image ? result.data[0].image.data.full_url : link.no_activity_image;
      setImage(img_activity);

      getType();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }
  //get list of types
  const getType = async () => {
    try {
      var result = await directus.getItems('activity_type',
        {
          fields: "*.*",
          sort: "name",
          filter: {
            status: "published"
          }
        });
      setDataActivityType(result.data);
      getDataActivityTeacher();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of activities teachers
  const getDataActivityTeacher = async () => {
    try {
      var result = await directus.getItems('activities_teachers',
        {
          fields: "*.*.*",
          filter: {
            activities_id: id,
            status: "published"
          }
        });

      setDataActivityTeacher(result.data);
      let id_teachers = [];
      result.data.map((item) => {
        return id_teachers.push(item.teachers_id.id);
      })
      getTeacher(id_teachers);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of teachers
  const getTeacher = async (id_teachers) => {
    try {
      var result = await directus.getItems('teachers',
        {
          filter: {
            id: {
              "nin": id_teachers
            }
          }
        });

      setDataTeacher(result.data);
      getDataActivityStudent();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of activities students
  const getDataActivityStudent = async () => {
    try {
      var result = await directus.getItems('activities_students',
        {
          fields: "*.*.*",
          filter: {
            activities_id: id,
            status: "published"
          }
        });

      setDataActivityStudent(result.data);
      let id_students = [];
      result.data.map((item) => {
        return id_students.push(item.students_id.id);
      })
      getStudent(id_students);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of students
  const getStudent = async (id_students) => {
    try {
      var result = await directus.getItems('students',
        {
          filter: {
            id: {
              "nin": id_students
            }
          }
        });
      setDataStudent(result.data);
      getDataActivityRoom();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of activities rooms
  const getDataActivityRoom = async () => {
    try {
      var result = await directus.getItems('activities_rooms',
        {
          fields: "*.*.*",
          filter: {
            activities_id: id,
            status: "published"
          }
        });
      setDataActivityRoom(result.data);

      let id_rooms = [];
      result.data.map((value) => {
        return id_rooms.push(value.rooms_id.id);
      })
      getRooms(id_rooms);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of rooms
  const getRooms = async (id_rooms) => {
    try {
      var result = await directus.getItems('rooms',
        {
          filter: {
            id: {
              "nin": id_rooms
            }
          }
        });
      setDataRoom(result.data);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Filtering ========================================== */ }

  //search activity type by name
  const onFilteringActivityType = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataActivityType, query);
  };

  //search teacher by name
  const onFilteringTeacher = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataTeacher, query);
  };

  //search student by name
  const onFilteringStudent = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataStudent, query);
  };

  //search room by name
  const onFilteringRoom = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataRoom, query);
  };


  // {/* ================== Select Dropdown Button ========================================== */ }

  const onSelectTeacherGroup = (e) => {
    if (e.item.id === 1) {
      alert("all teachers, update later");
    } else if (e.item.id === 2) {
      alert("homeroom teacher form, update later");
    }
  }

  const onSelectStudentGroup = (e) => {
    if (e.item.id === 1) {
      alert("all students, update later");
    }
  }

  // {/* ================== ACTIVITY ========================================== */ }

  // {/* ================== Handle Edit Activity ========================================== */ }

  function handleOpenDialogEditActivity() {
    dialogEditActivity.current.visible = true;
    setStateActivity({
      name: data.name,
      type: data.type.id,
      date: data.date ? new Date(data.date) : null,
      start_time: data.start_time ? moment(data.start_time, "HH:mm:ss").format("HH:mm") : null,
      end_time: data.end_time ? moment(data.end_time, "HH:mm:ss").format("HH:mm") : null,
      description: data.description,
      transportation_fee: data.transportation_fee,
      parent_involvement: data.parent_involvement
    });
  }

  const handleChange = (event, type) => {
    var value = null;
    if (type === "type") {
      value = event.itemData.id;
    } else if (type === "transportation_fee" || type === "parent_involvement") {
      value = event.checked;
    } else {
      value = event.value;
    }

    if (type === "type" || type === "name" || type === "date" || type === "start_time" || type === "end_time") {
      if (value) {
        setError({ ...errorRef.current, [type]: false })
      }
    }

    setStateActivity({ ...stateActivity, [type]: value })
  }

  const handleCloseDialogEditActivity = () => {
    dialogEditActivity.current.visible = false;
    getInitialValue();
  }

  const editActivity = async () => {
    setError({ ...errorRef.current, type: false, name: false, date: false, start_time: false, end_time: false })
    try {
      if (stateActivity.type && stateActivity.name && stateActivity.date && stateActivity.start_time && stateActivity.end_time) {
        setLoadingButton(true);
        const body = {
          type: stateActivity.type,
          date: moment(stateActivity.date).format("YYYY-MM-DD"),
          start_time: moment(stateActivity.start_time).format("HH:mm") === "Invalid date" ? (stateActivity.start_time + ":00") : (moment(stateActivity.start_time).format("HH:mm") + ":00"),
          end_time: moment(stateActivity.end_time).format("HH:mm") === "Invalid date" ? (stateActivity.end_time + ":00") : (moment(stateActivity.end_time).format("HH:mm") + ":00"),
          name: stateActivity.name,
          description: stateActivity.description,
          transportation_fee: stateActivity.transportation_fee,
          parent_involvement: stateActivity.parent_involvement
        }

        var result = await directus.updateItem('activities', id, body);

        var description = stateActivity.name;
        if (description !== data.name) {
          description = data.name + " --> " + stateActivity.name;
        }
        const body_activity = {
          action: "Update",
          category: 4,
          description: description,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          getData();
          setDataButton("edit_activity")
        }

      } else {
        if (!stateActivity.type) { setError({ ...errorRef.current, type: true }) }
        if (!stateActivity.name) { setError({ ...errorRef.current, name: true }) }
        if (!stateActivity.date) { setError({ ...errorRef.current, date: true }) }
        if (!stateActivity.start_time) { setError({ ...errorRef.current, start_time: true }) }
        if (!stateActivity.end_time) { setError({ ...errorRef.current, end_time: true }) }
        setLoadingButton(false);
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }


  // {/* ================== Handle Change Profile Dialog ========================================== */ }

  const handleOpenDialogChangePhoto = () => {
    var photo_activity = data.image ? data.image.data.full_url : link.no_activity_image;
    setImage(photo_activity);
    setOldImage(photo_activity);
    dialogChangePhoto.current.visible = true;
  }

  const handleCloseDialogChangePhoto = () => {
    dialogChangePhoto.current.visible = false;
    uploadObj.current.clearAll();
    cancelFile();
  }

  const changePhoto = async () => {
    setError({ ...errorRef.current, image: null });
    if (newImage !== null) {
      try {
        setLoadingButton(true);
        var result = await directus.updateItem('activities', id, { image: newImage });

        const body_activity = {
          action: "Update",
          category: 4,
          description: data.name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          getData();
          setDataButton("change_photo");
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        setLoadingButton(false);
      }
    } else {
      setError({ ...errorRef.current, image: true });
    }

  }

  // {/* ================== Handle Upload File ========================================== */ }

  const uploadFile = async () => {
    try {
      const data = new FormData(my_form2.current);
      var result = await directus.uploadFiles(data, onUploadProgress);
      const imageId = result.data.id;
      setImage(result.data.data.full_url);
      setNewImage(imageId);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }


  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + "% Done");
  }

  const cancelFile = () => {
    setNewImage(null);
    setImage(oldImage);
    setError({ ...errorRef.current, image: false });
  }


  // {/* ================== Total Number  ========================================== */ }
  const countStudentsAttended = () => {
    const list = [];
    var count = 0;
    data.students.map((value) => {
      if (parseInt(value.attended) === 1) {
        return count++;
      }
      return count;
    })

    list.push(<span key="count_student">{count}</span>);

    return (<span>{list}</span>)
  }

  const amountPaidTotal = () => {
    const list = [];
    var total = 0;
    data.students.map((value) => {
      if (value.amount_paid) {
        return total += value.amount_paid;
      }

      return total
    })

    list.push(<span key="total_paid">{total}</span>);

    return (<span>{list}</span>)
  }


  // {/* ================== On Data Bound ========================================== */}
  //if there is no data then create empty space
  const onDataBound = () => {
    const empty = document.getElementsByClassName("e-emptyrow");
    if (empty.length > 0) {
      for (var i = 0; i < empty.length; i++) {
        // empty[i].firstChild.innerHTML = "<span><br/><span>";
        empty[i].style.display = "none";
      }
    }
  }

  // {/* ================== TEACHER ========================================== */ }

  // {/* ================== Teacher Data Grid Template ========================================== */ }

  const nameTeacherTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <span className="image" style={{ width: "25px", height: "25px" }}>
          <img
            style={{
              maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
              objectFit: "cover", borderRadius: "50%", position: "center",
            }}
            className="image"
            src={props.teachers_id.image ? props.teachers_id.image.data.full_url : link.default_image}
            alt={props.teachers_id.name}
          />
        </span>
        <span style={{ paddingLeft: "20px", textAlign: "left" }}>{props.teachers_id.nickname}</span>
      </div>
    )
  };

  const registrationDateTemplate = props => {
    return (
      <div>{props.registration_date ? moment(props.registration_date).format('DD-MMM-yyyy') : ''}</div>
    )
  };

  const coordinatorTemplate = props => {
    return permission.AdminPermission() ? (
      <span
        style={{ color: parseInt(props.coordinator) === 1 ? "black" : "lightgray", cursor: "pointer" }}
        onClick={() => checkCoordinator(props)}
        className="e-icons icon-check-mark-01">
      </span>
    )
      : <span style={{ color: parseInt(props.coordinator) === 1 ? "black" : "lightgray" }} className="e-icons icon-check-mark-01"> </span>
  };

  const teacherAttendedTemplate = props => {
    return permission.AdminPermission() ? (
      <span style={{ color: parseInt(props.attended) === 1 ? "black" : "lightgray", cursor: "pointer" }}
        onClick={() => checkAttended(props)}
        className="e-icons icon-check-mark-01">
      </span>
    )
      : <span style={{ color: parseInt(props.attended) === 1 ? "black" : "lightgray" }} className="e-icons icon-check-mark-01"> </span>
  };

  const buttonTeacherTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center", justifyContent: "center" }}>
        {permission.AdminPermission() ?
          <div className="action_hover" style={{ display: "flex" }}>
            <TooltipComponent content={Edit_2} position="BottomCenter" >
              <span className="e-icons icon-edit-icon1"
                style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => handleOpenDialogEditTeacher(props)}
              />
            </TooltipComponent>

            <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px" }} >
              <span className="e-icons icon-delete-05"
                style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => handleOpenDialogDeleteTeacher(props)}
              />
            </TooltipComponent>
          </div>
          : null}
      </div>
    )
  }

  const checkCoordinator = async (props) => {
    var status = parseInt(props.coordinator);
    if (status === 1) { status = 0; } else { status = 1; }

    var result = await directus.updateItem('activities_teachers', props.id, { coordinator: status });

    if (!result.error) {
      EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
      getData();
    }

  }

  const checkAttended = async (props) => {
    var status = parseInt(props.attended);
    if (status === 1) { status = 0; } else { status = 1; }

    var result = await directus.updateItem('activities_teachers', props.id, { attended: status });
    if (!result.error) {
      EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
      getData();
    }
  }

  // {/* ================== Data Grid Action Teacher (add, edit) ========================================== */ }

  const actionGrid = async (event) => {
    if (event.action === "edit" && event.requestType === "save") {
      const data = event.data;
      const body = {
        registration_date: data.registration_date ? moment(data.registration_date).format("YYYY-MM-DD") : null
      }

      var result = await directus.updateItem('activities_teachers', data.id, body);

      if (!result.error) {
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        getData();
      }
    }
  }

  // {/* ================== Handle Add Teacher Activity ========================================== */ }
  const handleOpenDialogAddTeacher = () => {
    dialogAddTeacher.current.visible = true;
    getInitialValue();
  }

  const handleCloseDialogAddTeacher = () => {
    setError({ ...errorRef.current, teacher: false })
    dialogAddTeacher.current.visible = false;
    mt_teacher.current.value = null;
  }

  const addTeacher = async () => {
    let new_id = mt_teacher.current.value;
    setError({ ...errorRef.current, teacher: false });
    try {
      if (new_id.length > 0) {
        setLoadingButton(true);
        let activity_teacher_data = [];
        new_id.map((item) => {
          return activity_teacher_data.push({
            teachers_id: item,
            activities_id: id,
            attended: stateTeacher.attended,
            coordinator: stateTeacher.coordniator,
            registration_date: stateTeacher.registration_date ? moment(stateTeacher.registration_date).format("YYYY-MM-DD") : null,
            payment_date: stateTeacher.payment_date ? moment(stateTeacher.payment_date).format("YYYY-MM-DD") : null,
            payment_notes: stateTeacher.payment_notes,
            status: "published"
          })
        })

        //save teachers_id and activities id into activities teachers
        var result = await directus.createItems('activities_teachers', activity_teacher_data);
        if (!result.error) {
          getData();
          setDataButton("add_teacher");
        }
      } else {
        setError({ ...errorRef.current, teacher: true });
        setLoadingButton(false);
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }

  }

  const handleChangeTeacher = (event, type) => {
    var value = null;
    if (type === "attended") {
      value = event.checked;
    } else {
      value = event.value
    }
    setStateTeacher({ ...stateTeacher, [type]: value })
  }


  // {/* ================== Handle Edit Teacher Activity ========================================== */ }

  function handleOpenDialogEditTeacher(props) {
    dialogEditTeacher.current.visible = true;
    setStateTeacher({
      id: props.id,
      teachers_id: props.teachers_id.id,
      teachers_name: props.teachers_id.nickname,
      attended: props.attended,
      coordinator: props.coordinator,
      registration_date: props.registration_date ? new Date(props.registration_date) : null,
      payment_date: props.payment_date ? new Date(props.payment_date) : null,
      payment_notes: props.payment_notes
    })
  }

  const handleCloseDialogEditTeacher = () => {
    dialogEditTeacher.current.visible = false;
  }

  const editTeacher = async () => {
    try {
      setLoadingButton(true);
      const body = {
        id: stateTeacher.id,
        teachers_id: stateTeacher.teachers_id.id,
        attended: stateTeacher.attended,
        coordinator: stateTeacher.coordniator,
        registration_date: stateTeacher.registration_date ? moment(stateTeacher.registration_date).format("YYYY-MM-DD") : null,
        payment_date: stateTeacher.payment_date ? moment(stateTeacher.payment_date).format("YYYY-MM-DD") : null,
        payment_notes: stateTeacher.payment_notes
      }

      var result = await directus.updateItem('activities_teachers', stateTeacher.id, body);
      if (!result.error) {
        getData();
        setDataButton("edit_teacher");
      }

    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Handle Delete Teacher Activity ========================================== */ }

  const handleOpenDialogDeleteTeacher = (props) => {
    dialogDeleteTeacher.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteTeacher = () => {
    dialogDeleteTeacher.current.visible = false;
  }

  const deleteTeacher = async () => {
    try {
      setLoadingButton(true);
      var result = await directus.deleteItem('activities_teachers', selectedData.id);
      if (!result.error) {
        getData();
        setDataButton("delete_teacher")
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== STUDENT ========================================== */ }

  // {/* ================== Student Data Grid Template ========================================== */ }

  // const imageStudentEditTemplate = props => {
  //   return (
  //     <div className="image" style={{ width: "50px", height: "50px" }}>
  //       <img
  //         style={{
  //           maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
  //           objectFit: "cover", border: "3px solid #d2d6de", borderRadius: "50%", position: "center",
  //         }}
  //         src={props.students_id.image ? props.students_id.image.data.full_url : link.default_image}
  //         alt={props.students_id.namee}
  //       />
  //     </div>
  //   )
  // };

  const nameStudentTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <span className="image" style={{ width: "25px", height: "25px" }}>
          <img
            style={{
              maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
              objectFit: "cover", borderRadius: "50%", position: "center",
            }}
            className="image"
            src={props.students_id.image ? props.students_id.image.data.full_url : link.default_image}
            alt={props.students_id.name}
          />
        </span>
        <span style={{ paddingLeft: "20px", textAlign: "left" }}>{props.students_id.nickname}</span>
      </div>
    )
  };

  const paymentDateTemplate = props => {
    return (
      <div>{props.payment_date ? moment(props.payment_date).format('DD-MMM-yyyy') : ''}</div>
    )
  };

  const studentAttendedTemplate = props => {
    return permission.AdminPermission() ? (
      <span
        style={{ color: parseInt(props.attended) === 1 ? "black" : "lightgray", cursor: "pointer" }}
        onClick={() => checkStudentAttended(props)}
        className="e-icons icon-check-mark-01">
      </span>
    )
      : <span style={{ color: parseInt(props.attended) === 1 ? "black" : "lightgray" }} className="e-icons icon-check-mark-01"> </span>
  };

  const parentAttendedTemplate = props => {
    return permission.AdminPermission() ? (
      <span
        style={{ color: parseInt(props.parent_attended) === 1 ? "black" : "lightgray", cursor: "pointer" }}
        onClick={() => checkParentAttended(props)}
        className="e-icons icon-check-mark-01">
      </span>
    )
      : <span style={{ color: parseInt(props.parent_attended) === 1 ? "black" : "lightgray" }} className="e-icons icon-check-mark-01"> </span>
  };

  const buttonStudentTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        {permission.AdminPermission() ?
          <div className="action_hover" style={{ display: "flex" }}>
            <TooltipComponent content={Edit_2} position="BottomCenter" >
              <span className="e-icons icon-edit-icon1"
                style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => handleOpenDialogEditStudent(props)}
              />
            </TooltipComponent>

            <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px" }} >
              <span className="e-icons icon-delete-05"
                style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => handleOpenDialogDeleteStudent(props)}
              />
            </TooltipComponent>
          </div>
          : null}
      </div>
    )
  }

  const checkStudentAttended = async (props) => {
    try {
      var status = parseInt(props.attended);
      if (status === 1) { status = 0; } else { status = 1; }

      var result = await directus.updateItem('activities_students', props.id, { attended: status });
      if (!result.error) {
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        getData();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const checkParentAttended = async (props) => {
    try {
      var status = parseInt(props.parent_attended);
      if (status === 1) { status = 0; } else { status = 1; }

      var result = await directus.updateItem('activities_students', props.id, { parent_attended: status });
      if (!result.error) {
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        getData();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Data Grid Action Student (add, edit) ========================================== */ }

  const actionGridStudent = async (event) => {
    if (event.action === "edit") {
      const data = event.data;
      const body = {
        registration_date: data.registration_date ? moment(data.registration_date).format("YYYY-MM-DD") : null,
        payment_date: data.payment_date ? moment(data.payment_date).format("YYYY-MM-DD") : null,
        achievement: data.achievement,
        description: data.description
      }

      var result = await directus.updateItem('activities_students', data.id, body);
      if (!result.error) {
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        getData();
      }
    }
  }

  // {/* ================== Handle Add Student Activity ========================================== */ }
  const handleOpenDialogAddStudent = () => {
    dialogAddStudent.current.visible = true;
    getInitialValue();
  }

  const handleCloseDialogAddStudent = () => {
    setError({ ...errorRef.current, student: false });
    dialogAddStudent.current.visible = false;
    mt_student.current.value = [];
  }

  //select student template
  const itemTemplate = (props) => {
    var age = '';
    if (props.date_of_birth) {
      const birthdate = moment(new Date(props.date_of_birth), 'MM-YYYY');
      const now = moment();
      var date = moment.duration(now.diff(birthdate));
      age = date.years();
    }
    return (
      <span><span className='name'>{props.nickname ? props.nickname : props.name}</span>{age ? <span style={{ right: "15px", position: "absolute" }} className='age'>{age} y/o</span> : null}</span>
    )
  }

  const handleChangeStudent = (event, type) => {
    var value = null;
    if (type === "attended" || type === "parent_attended") {
      value = event.checked;
    } else {
      value = event.value
    }

    setStateStudent({ ...stateStudent, [type]: value })
  }

  const addStudent = async () => {
    try {
      setError({ ...errorRef.current, student: false });
      let new_id = mt_student.current.value;
      if (new_id.length > 0) {
        setLoadingButton(true);
        let activity_student_data = [];

        new_id.map((item) => {
          return activity_student_data.push({
            students_id: item,
            activities_id: id,
            attended: stateStudent.attended,
            parent_attended: stateStudent.parent_attended,
            registration_date: stateStudent.registration_date ? moment(stateStudent.registration_date).format("YYYY-MM-DD") : null,
            payment_date: stateStudent.payment_date ? moment(stateStudent.payment_date).format("YYYY-MM-DD") : null,
            payment_notes: stateStudent.payment_notes,
            achievement: stateStudent.achievement,
            amount_paid: stateStudent.amount_paid,
            description: stateStudent.description,
            status: "published"
          })
        })

        //save students_id and activities id into activities students
        var result = await directus.createItems('activities_students', activity_student_data);

        if (!result.error) {
          getData();
          setDataButton("add_student")
        }

      } else {
        setError({ ...errorRef.current, student: true });
      }

    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Handle Edit Student Activity ========================================== */ }

  function handleOpenDialogEditStudent(props) {
    dialogEditStudent.current.visible = true;
    setStateStudent({
      id: props.id,
      students_id: props.students_id,
      students_name: props.students_id.name,
      attended: props.attended,
      achievement: props.achievement,
      registration_date: props.registration_date ? new Date(props.registration_date) : null,
      payment_date: props.payment_date ? new Date(props.payment_date) : null,
      amount_paid: props.amount_paid,
      payment_notes: props.payment_notes,
      description: props.description,
      parent_attended: props.parent_attended
    })
  }

  const handleCloseDialogEditStudent = () => {
    dialogEditStudent.current.visible = false;
  }

  const editStudent = async () => {
    try {
      setLoadingButton(true);
      const body = {
        id: stateStudent.id,
        students_id: stateStudent.students_id.id,
        activities_id: id,
        attended: stateStudent.attended,
        parent_attended: stateStudent.parent_attended,
        registration_date: stateStudent.registration_date ? moment(stateStudent.registration_date).format("YYYY-MM-DD") : null,
        payment_date: stateStudent.payment_date ? moment(stateStudent.payment_date).format("YYYY-MM-DD") : null,
        payment_notes: stateStudent.payment_notes,
        achievement: stateStudent.achievement,
        amount_paid: stateStudent.amount_paid,
        description: stateStudent.description
      }

      var result = await directus.updateItem('activities_students', stateStudent.id, body);
      if (!result.error) {
        getData();
        setDataButton("edit_student");
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Handle Delete Student Activity========================================== */ }

  const handleOpenDialogDeleteStudent = (props) => {
    dialogDeleteStudent.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteStudent = () => {
    dialogDeleteStudent.current.visible = false;
  }

  const deleteStudent = async () => {
    try {
      setLoadingButton(true);
      var result = await directus.deleteItem('activities_students', selectedData.id);

      if (!result.error) {
        getData();
        setDataButton("delete_student");
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== ROOM ========================================== */ }

  // {/* ================== Room Data Grid Template ========================================== */ }
  const buttonRoomTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        {permission.AdminPermission() ?
          <div className="action_hover" style={{ display: "flex" }}>
            <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "15px", }} >
              <span className="e-icons icon-delete-05"
                style={{ cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => handleOpenDialogDeleteRoom(props)}
              />
            </TooltipComponent>
          </div>
          : null}
      </div>
    )
  }

  // {/* ================== Handle Add Room Activity ========================================== */ }
  const handleOpenDialogAddRoom = () => {
    dialogAddRoom.current.visible = true;
  }

  const handleCloseDialogAddRoom = () => {
    setError({ ...errorRef.current, room: false });
    dialogAddRoom.current.visible = false;
    mt_room.current.value = [];
  }

  const addRoom = async () => {
    try {
      setError({ ...errorRef.current, room: false });
      let new_id = mt_room.current.value;
      if (new_id.length > 0) {
        setLoadingButton(true);
        let activity_room_data = [];

        new_id.map((item) => {
          return activity_room_data.push({
            rooms_id: item,
            activities_id: id,
            status: "published"
          })
        })

        //save rooms_id and activities id into activities rooms
        var result = await directus.createItems('activities_rooms', activity_room_data);

        if (!result.error) {
          getData();
          setDataButton("add_room")
        }
      } else {
        setError({ ...errorRef.current, room: true });
        setLoadingButton(false);
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Handle Dialog Delete Room ========================================== */ }

  const handleOpenDialogDeleteRoom = (props) => {
    dialogDeleteRoom.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteRoom = () => {
    dialogDeleteRoom.current.visible = false;
  }

  const deleteRoom = async () => {
    try {
      var result = await directus.deleteItem('activities_rooms', selectedData.id);
      if (!result.error) {
        getData();
        setDataButton("delete_room");
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Handle Student Present ========================================== */ }

  const checkStudentStatus = (item) => {
    const list = [];
    var status = false;

    if (item) {
      item.map((value) => {
        if (parseInt(value.attended) !== 1) {
          return status = true;
        }
        return status;
      })

      if (status === true) {
        list.push(
          <CheckBoxComponent
            key="ck-false"
            checked={false}
            label={All_Students_Present}
            change={() => handleOpenDialogStudentPresent()}
          />)
      } else {
        list.push(
          <CheckBoxComponent
            key="ck-true"
            checked={true}
            disabled={true}
            label={All_Students_Present}
          // change={(e) => handleOpenDialogStudentPresent(e, item)}
          />)
      }
    }
    return (
      <span>{list}</span>
    )
  }

  const handleOpenDialogStudentPresent = () => {
    dialogStudentPresent.current.visible = true;
  }

  const handleCloseDialogStudentPresent = () => {
    dialogStudentPresent.current.visible = false;
  }

  const updateStudentPresent = async () => {
    try {
      if (data) {
        setLoadingButton(true);
        var listStudent = linq.from(data.students)
          .select(data => ({ id: data.id, attended: 1 }))
          .toArray();

        var result = await directus.updateItems('activities_students', listStudent);

        if (!result.error) {
          getData();
          setDataButton("student_present");
        }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Handle Parent Present ========================================== */ }

  const checkParentStatus = (item) => {
    const list = [];
    let status = false;

    if (item) {
      item.map((value) => {
        if (parseInt(value.parent_attended) !== 1) {
          return status = true;
        }
        return status;
      })

      if (status === true) {
        list.push(
          <CheckBoxComponent
            key="ck-false"
            enablePersistence={false}
            checked={false}
            label={All_Parents_Present}
            change={(e) => handleOpenDialogParentPresent(e, item)}
          />)
      } else {
        list.push(
          <CheckBoxComponent
            key="ck-true"
            checked={true}
            disabled={true}
            label={All_Parents_Present}
          />)
      }
    }
    return (
      <span>{list}</span>
    )
  }

  const handleOpenDialogParentPresent = () => {
    dialogParentPresent.current.visible = true;
  }

  const handleCloseDialogParentPresent = () => {
    dialogParentPresent.current.visible = false;
  }

  const updateParentPresent = async () => {
    try {
      if (data) {
        setLoadingButton(true);
        var listParent = linq.from(data.students)
          .select(data => ({ id: data.id, parent_attended: 1 }))
          .toArray();

        var result = await directus.updateItems('activities_students', listParent);
        if (!result.error) {
          getData();
          setDataButton("parent_present")
        }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Footer Template ========================================== */}

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // const beforeRender = (args) => {
  //   tooltip.content = args.target.closest("th").innerText;
  // }
  // {/* ================== Main Content ========================================== */ }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Activities}</div>
          </div>
          <div className="content-main" style={{ marginTop: "15px" }}>
            {data ?
              <div style={{ paddingBottom: "30px" }}>
                <div>
                  <div className="container2" style={{ float: "right" }}>
                    <div className="image" style={{ width: "100px", height: "100px" }}>
                      <img
                        style={{
                          maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                          objectFit: "fill", position: "center",
                        }}
                        src={data.image ? data.image.data.full_url : link.no_activity_image}
                        alt={data.name}
                      />
                    </div>
                    {permission.AdminPermission() ?
                      <div className="edit-middle hide-content">
                        <ButtonComponent
                          iconCss="e-icons icon-edit-icon1"
                          onClick={() => handleOpenDialogChangePhoto(data)}
                          style={{ fontSize: "12px" }}
                        >{Edit_2}</ButtonComponent>
                      </div>
                      : null}
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <span style={{ fontSize: "20px" }}>{data.type.name} ({data.name})</span>
                    {permission.AdminPermission() ?
                      <span className="e-icons icon-edit-icon1"
                        style={{ width: "5px", padding: "10px", cursor: "pointer", color: "#3c8dbc" }}
                        onClick={() => handleOpenDialogEditActivity()}
                      />
                      : null}
                    <br />
                    {data.date ? moment(data.date).format('DD-MMM-yyyy') : '-'} <br />
                    {data.start_time ? moment(data.start_time, 'HH:mm:ss').format('HH:mm') : '-'} to {data.end_time ? moment(data.end_time, 'HH:mm:ss').format('HH:mm') : '-'}
                  </div>
                </div>

                <hr />
                <div>
                  {Teacher_Participants}: {data.teachers.length}, {Student_Registered}: {data.students.length}, {Students_Attended}: {countStudentsAttended()}{data.transportation_fee ? <span>, {Total_Paid}: {amountPaidTotal()}</span> : ''}
                </div>
                <hr />
                <div>

                  {/* ================== Teacher Participants ========================================== */}

                  <div id="teacher_participants">
                    <b>{Teacher_Participants}</b>
                    {permission.AdminPermission() ?
                      <div className="hide-content" style={{ float: "right" }}>
                        <DropDownButtonComponent
                          className="btn-custom"
                          iconCss='e-icons icon-plus'
                          select={(e) => onSelectTeacherGroup(e)}
                          items={items}>
                          <span className="btn-text-custom">
                            {Teacher_Group}
                          </span>
                        </DropDownButtonComponent>
                        <ButtonComponent
                          className="btn-custom"
                          iconCss='e-icons icon-plus'
                          onClick={handleOpenDialogAddTeacher}>
                          <span className="btn-text-custom">
                            {Teacher_Participants}
                          </span>
                        </ButtonComponent>
                      </div>
                      : null}
                    <br /><br />
                    {/* <TooltipComponent ref={t => tooltip = t} target='.e-headercell' beforeRender={beforeRender} position="TopCenter"> */}
                    <GridComponent
                      width="100%"
                      dataSource={dataActivityTeacher}
                      editSettings={editSettings}
                      dataBound={onDataBound}
                      ref={grid}
                      allowTextWrap={true}
                      textWrapSettings={{ wrapMode: "Content" }}
                      allowResizing={true}
                      gridLines='Vertical'
                      actionBegin={actionGrid}
                      selectionSettings={setting}
                      allowReordering={true}
                      clipMode="EllipsisWithTooltip"
                    >
                      <ColumnsDirective>
                        <ColumnDirective customAttributes={{ id: 'first' }} width="25%" field='nickname' headerText={Name} template={nameTeacherTemplate} validationRules={{ required: true }}></ColumnDirective>
                        {/* <ColumnDirective headerText={Photo} textAlign='Center' editTemplate={imageTeacherEditTemplate} template={imageTeacherEditTemplate}></ColumnDirective>
                        <ColumnDirective field='teachers_id.nickname' allowEditing={false} headerText={Name}></ColumnDirective> */}
                        <ColumnDirective field='registration_date' width="25%" headerText={Registration_Date} editType='datepickeredit' template={registrationDateTemplate}></ColumnDirective>
                        <ColumnDirective field='coordinator' width="20%" textAlign='center' headerText={Coordinator} allowFiltering={false} template={coordinatorTemplate} editTemplate={coordinatorTemplate}></ColumnDirective>
                        <ColumnDirective field='attended' width="20%" textAlign='center' headerText={Attended} allowFiltering={false} template={teacherAttendedTemplate} editTemplate={teacherAttendedTemplate}></ColumnDirective>
                        <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="10%" template={buttonTeacherTemplate} editTemplate={buttonTeacherTemplate}></ColumnDirective>
                      </ColumnsDirective>
                      <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                    </GridComponent>
                    {/* </TooltipComponent> */}
                  </div>
                  <br />
                  <hr />

                  {/* ================== Student Participants ========================================== */}

                  <div id="student_participants">
                    <b>{Student_Participants}</b>
                    {permission.AdminPermission() ?
                      <div>
                        <span style={{ paddingLeft: "20px" }}>
                          {checkStudentStatus(data.students)}
                        </span>

                        {parseInt(data.parent_involvement) === 1 ?
                          <span style={{ paddingLeft: "20px" }}>
                            {checkParentStatus(data.students)}
                          </span>
                          : ''}
                      </div>
                      : null}
                    {permission.AdminPermission() ?
                      <div className="hide-content" style={{ float: "right" }}>
                        <DropDownButtonComponent
                          className="btn-custom"
                          iconCss='e-icons icon-plus'
                          select={(e) => onSelectStudentGroup(e)}
                          items={itemsStudent}>
                          <span className="btn-text-custom">
                            {Student_Group}
                          </span>
                        </DropDownButtonComponent>
                        <ButtonComponent
                          className="btn-custom"
                          iconCss='e-icons icon-plus'
                          onClick={handleOpenDialogAddStudent} >
                          <span className="btn-text-custom">
                            {Student_Participants}
                          </span>
                        </ButtonComponent>
                      </div>
                      : null}
                    <br /><br />
                    {/* <TooltipComponent ref={t => tooltip = t} target='.e-headercell' beforeRender={beforeRender} position="TopCenter"> */}
                    <GridComponent
                      width="100%"
                      dataSource={dataActivityStudent}
                      editSettings={editSettings}
                      dataBound={onDataBound}
                      ref={grid}
                      allowTextWrap={true}
                      textWrapSettings={{ wrapMode: "Content" }}
                      allowResizing={true}
                      gridLines='Vertical'
                      actionBegin={actionGridStudent}
                      selectionSettings={setting}
                      clipMode="EllipsisWithTooltip"
                    >
                      <ColumnsDirective>
                        <ColumnDirective customAttributes={{ id: 'first' }} width="15%" field='nickname' headerText={Name} template={nameStudentTemplate} validationRules={{ required: true }}></ColumnDirective>
                        {/* <ColumnDirective field='students_id.nickname' allowEditing={false} headerText={Name}></ColumnDirective> */}
                        <ColumnDirective width="15%" field='registration_date' headerText={List} editType='datepickeredit' template={registrationDateTemplate}></ColumnDirective>
                        <ColumnDirective width="15%" field='payment_date' headerText={Paid} editType='datepickeredit' template={paymentDateTemplate}></ColumnDirective>
                        {/* <ColumnDirective field='coordinator' textAlign='center' headerText='Coordinator' allowFiltering={false} template={coordinatorTemplate} editTemplate={coordinatorTemplate}></ColumnDirective> */}
                        <ColumnDirective width="10%" field='attended' textAlign='center' headerText={Attended} allowFiltering={false} template={studentAttendedTemplate} editTemplate={studentAttendedTemplate}></ColumnDirective>
                        {parseInt(data.parent_involvement) === 1 ?
                          <ColumnDirective width="10%" field='parent_attended' textAlign='center' headerText={Parent_Attended} allowFiltering={false} template={parentAttendedTemplate} editTemplate={parentAttendedTemplate}></ColumnDirective>
                          : ''}
                        <ColumnDirective width="10%" field='achievement' headerText={Achievement}></ColumnDirective>
                        <ColumnDirective width="15%" field='description' headerText={Description}></ColumnDirective>
                        <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="10%" template={buttonStudentTemplate} editTemplate={buttonStudentTemplate}></ColumnDirective>
                      </ColumnsDirective>
                      <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                    </GridComponent>
                    {/* </TooltipComponent> */}
                  </div>
                  <br />
                  <hr />

                  {/* ================== Room Used ========================================== */}

                  <div id="room_used" style={{ marginBottom: "10px" }}>
                    <b>{Room_Used}</b>
                    {permission.AdminPermission() ?
                      <div className="hide-content" style={{ float: "right" }}>
                        <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus'
                          onClick={handleOpenDialogAddRoom}>
                          <span className="btn-text-custom">
                            {Rooms}
                          </span>
                        </ButtonComponent>
                      </div>
                      : null}
                    <br /><br />
                    <GridComponent
                      width="50%"
                      dataSource={dataActivityRoom}
                      editSettings={editSettings}
                      ref={grid}
                      dataBound={onDataBound}
                      allowTextWrap={true}
                      textWrapSettings={{ wrapMode: "Content" }}
                      allowResizing={true}
                      gridLines='Vertical'
                      selectionSettings={setting}
                      clipMode="EllipsisWithTooltip"
                    >
                      <ColumnsDirective>
                        <ColumnDirective customAttributes={{ id: 'first' }} field='rooms_id.name' allowEditing={false} headerText={Name}></ColumnDirective>
                        <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width='20%' template={buttonRoomTemplate} editTemplate={buttonRoomTemplate}></ColumnDirective>
                      </ColumnsDirective>
                      <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                    </GridComponent>
                  </div>

                  {/* ================== Change Profile Dialog ========================================== */}

                  <DialogComponent
                    id="dialogChangePhoto"
                    width="30%"
                    showCloseIcon={!loadingButton ? true : false}
                    visible={false}
                    ref={dialogChangePhoto}
                    header={Change_Photo}
                    isModal={true}
                    footerTemplate={() => footerTemplate([
                      { name: Update, func: changePhoto, icon: "icon-check-mark-01", status: "e-success" },
                      { name: Cancel, func: handleCloseDialogChangePhoto, icon: "icon-treeview", status: "" }
                    ])}
                    close={handleCloseDialogChangePhoto}
                  >
                    <div>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="image" style={{ width: "150px", height: "150px" }}>
                          <img
                            style={{
                              maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                              objectFit: "cover", position: "center",
                            }}
                            src={image}
                            alt="activity_photo"
                          />
                        </div>
                      </div>
                      <br />
                      <form ref={my_form2}>
                        <UploaderComponent
                          id='previewfileupload'
                          type='file'
                          ref={uploadObj}
                          asyncSettings={asyncSettings}
                          success={uploadFile}
                          multiple={false}
                          autoUpload={false}
                          clearing={cancelFile}
                        >
                        </UploaderComponent>
                        {error.image === true ? <span style={{ color: 'red' }}>{Please_upload_the_photo}</span> : ''}
                      </form>
                    </div>
                  </DialogComponent>

                  {/* ================== Activity Dialog ========================================== */}
                  <DialogComponent
                    id="dialogEditActivity"
                    width="30%"
                    showCloseIcon={!loadingButton ? true : false}
                    visible={false}
                    ref={dialogEditActivity}
                    header={Edit_Activity}
                    isModal={true}
                    footerTemplate={() => footerTemplate([
                      { name: Update, func: editActivity, icon: "icon-check-mark-01", status: "e-success" },
                      { name: Cancel, func: handleCloseDialogEditActivity, icon: "icon-treeview", status: "" }
                    ])}
                    close={handleCloseDialogEditActivity}
                  >
                    <div>
                      <b>{Activity_Type}</b>
                      <DropDownListComponent
                        dataSource={dataActivityType}
                        query={new Query()}
                        name="type"
                        cssClass="e-outline"
                        placeholder={`--${Select_Type}--`}
                        fields={{ text: 'name', value: 'id' }}
                        select={(e) => handleChange(e, "type")}
                        value={stateActivity.type}
                        filtering={onFilteringActivityType}
                        allowFiltering={true}
                      />
                      {error.type === true ? <span style={{ color: 'red' }}>{Please_select_activity_type}</span> : ''}
                      <br /><br />
                      <b>{Activity_Name}</b>
                      <TextBoxComponent
                        name="name"
                        cssClass='e-outline'
                        change={(e) => handleChange(e, "name")}
                        value={stateActivity.name}
                      />
                      {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                      <br /><br />
                      <b>{Date_2}</b>
                      <DatePickerComponent
                        name="date"
                        cssClass="e-outline"
                        format='dd-MMM-yyyy'
                        change={(e) => handleChange(e, "date")}
                        value={stateActivity.date ? new Date(stateActivity.date) : null}
                      />
                      {error.date === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                      <br /><br />
                      <b>{Start_Time}</b>
                      <TimePickerComponent
                        name="start_time"
                        cssClass="e-outline"
                        step={30}
                        format={'HH:mm'}
                        onChange={(e) => handleChange(e, "start_time")}
                        value={stateActivity.start_time ? moment(stateActivity.start_time, "HH:mm:ss").format("HH:mm") : null}
                      />
                      {error.start_time === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                      <br /><br />
                      <b>{End_Time}</b>
                      <TimePickerComponent
                        name="start_time"
                        cssClass="e-outline"
                        step={30}
                        format={'HH:mm'}
                        onChange={(e) => handleChange(e, "end_time")}
                        value={stateActivity.end_time ? moment(stateActivity.end_time, "HH:mm:ss").format("HH:mm") : null}
                      />
                      {error.end_time === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                      <br /><br />
                      <b>{Description}</b>
                      <TextBoxComponent
                        name="description"
                        cssClass='e-outline'
                        multiline={true}
                        change={(e) => handleChange(e, "description")}
                        value={stateActivity.description}
                      />
                      <br /><br />
                      <CheckBoxComponent
                        checked={parseInt(stateActivity.transportation_fee) === 1 ? true : false}
                        label={Transportation_Fee}
                        change={(e) => handleChange(e, "transportation_fee")} />
                      <br /><br />
                      <CheckBoxComponent
                        checked={parseInt(stateActivity.parent_involvement) === 1 ? true : false}
                        label={Parent_involvement}
                        change={(e) => handleChange(e, "parent_involvement")} />
                    </div>
                  </DialogComponent>


                  {/* ================== Teachers Dialog ========================================== */}
                  <DialogComponent
                    id="dialogAddTeacher"
                    width="30%"
                    showCloseIcon={!loadingButton ? true : false}
                    visible={false}
                    ref={dialogAddTeacher}
                    header={Teacher_Participants}
                    isModal={true}
                    footerTemplate={() => footerTemplate([
                      { name: Update, func: addTeacher, icon: "icon-check-mark-01", status: "e-success" },
                      { name: Cancel, func: handleCloseDialogAddTeacher, icon: "icon-treeview", status: "" }
                    ])}
                    close={handleCloseDialogAddTeacher}
                  >
                    <div>
                      <b>{Activities}</b>
                      <TextBoxComponent
                        name="name"
                        cssClass='e-outline'
                        enabled={false}
                        value={data.name}
                      />
                      <br /><br />
                      <b>{Teachers}</b>
                      <MultiSelectComponent id="mt_teacher"
                        ref={mt_teacher}
                        cssClass="e-outline"
                        placeholder={`--${Select_Teacher}--`}
                        dataSource={dataTeacher}
                        fields={{ text: 'nickname', value: 'id' }}
                        filtering={onFilteringTeacher}
                        allowFiltering={true}
                        value={stateFiltering.teachers}
                      />
                      {error.teacher === true ? <span style={{ color: 'red' }}>{Please_select_teacher}</span> : ''}
                      <br /><br />
                      <b>{Registration_Date}</b>
                      <DatePickerComponent
                        cssClass="e-outline"
                        name="date"
                        format='dd-MMM-yyyy'
                        change={(e) => handleChangeTeacher(e, "registration_date")}
                        value={stateTeacherRef.current.registration_date}
                      />
                      <br /><br />
                      <b>{Payment_Date}</b>
                      <DatePickerComponent
                        cssClass="e-outline"
                        name="payment_date"
                        format='dd-MMM-yyyy'
                        change={(e) => handleChangeTeacher(e, "payment_date")}
                        value={stateTeacherRef.current.payment_date}
                      />
                      <br /><br />
                      <b>{Payment_Notes}</b>
                      <TextBoxComponent
                        name="payment_notes"
                        cssClass='e-outline'
                        change={(e) => handleChangeTeacher(e, "payment_notes")}
                        value={stateTeacherRef.current.payment_notes}
                      />
                      <br /><br />
                      <CheckBoxComponent
                        checked={parseInt(stateTeacherRef.current.attended) === 1 ? true : false}
                        label={Attended}
                        change={(e) => handleChangeTeacher(e, "attended")} />
                    </div>
                  </DialogComponent>


                  <DialogComponent
                    id="dialogEditTeacher"
                    width="30%"
                    showCloseIcon={!loadingButton ? true : false}
                    visible={false}
                    ref={dialogEditTeacher}
                    header={Student_Participants}
                    isModal={true}
                    footerTemplate={() => footerTemplate([
                      { name: Update, func: editTeacher, icon: "icon-check-mark-01", status: "e-success" },
                      { name: Cancel, func: handleCloseDialogEditTeacher, icon: "icon-treeview", status: "" }
                    ])}
                    close={handleCloseDialogEditTeacher}
                  >
                    <div>
                      <b>{Activities}</b>
                      <TextBoxComponent
                        name="name"
                        cssClass='e-outline'
                        enabled={false}
                        value={data.name}
                      />
                      <br /><br />
                      <b>{Teacher}</b>
                      <TextBoxComponent
                        name="name"
                        cssClass='e-outline'
                        enabled={false}
                        value={stateTeacherRef.current.teachers_name}
                      />
                      <br /><br />
                      <b>{Registration_Date}</b>
                      <DatePickerComponent
                        cssClass="e-outline"
                        name="date"
                        format='dd-MMM-yyyy'
                        change={(e) => handleChangeTeacher(e, "registration_date")}
                        value={stateTeacherRef.current.registration_date}
                      />
                      <br /><br />
                      <b>{Payment_Date}</b>
                      <DatePickerComponent
                        cssClass="e-outline"
                        name="payment_date"
                        format='dd-MMM-yyyy'
                        change={(e) => handleChangeTeacher(e, "payment_date")}
                        value={stateTeacherRef.current.payment_date}
                      />
                      <br /><br />
                      <b>{Payment_Notes}</b>
                      <TextBoxComponent
                        name="name"
                        cssClass='e-outline'
                        change={(e) => handleChangeTeacher(e, "payment_notes")}
                        value={stateTeacherRef.current.payment_notes}
                      />
                      <br /><br />
                      <CheckBoxComponent
                        checked={parseInt(stateTeacherRef.current.attended) === 1 ? true : false}
                        label={Attended}
                        change={(e) => handleChangeTeacher(e, "attended")} />
                    </div>
                  </DialogComponent>


                  <DialogComponent
                    id="dialogDeleteTeacher"
                    width="35%"
                    showCloseIcon={!loadingButton ? true : false}
                    visible={false}
                    ref={dialogDeleteTeacher}
                    header={Delete_Confirmation}
                    isModal={true}
                    footerTemplate={() => footerTemplate([
                      { name: Delete, func: deleteTeacher, icon: "icon-delete-05", status: "e-warning" },
                      { name: Cancel, func: handleCloseDialogDeleteTeacher, icon: "icon-treeview", status: "" }
                    ])}
                    close={handleCloseDialogDeleteTeacher}
                  >
                    <div>
                      {selectedData ? selectedData.teachers_id ?
                        <div style={{ fontSize: "14px" }}>
                          {Are_you_sure_you_want_to_delete_the_data_of_teacher_participants} ({selectedData.teachers_id.name})?
                        </div>
                        : '' : ''}
                    </div>
                  </DialogComponent>



                  {/* ================== Students Dialog ========================================== */}
                  <DialogComponent
                    id="dialogAddStudent"
                    width="30%"
                    showCloseIcon={!loadingButton ? true : false}
                    visible={false}
                    ref={dialogAddStudent}
                    header={Student_Participants}
                    isModal={true}
                    footerTemplate={() => footerTemplate([
                      { name: Update, func: addStudent, icon: "icon-check-mark-01", status: "e-success" },
                      { name: Cancel, func: handleCloseDialogAddStudent, icon: "icon-treeview", status: "" }
                    ])}
                    close={handleCloseDialogAddStudent}
                  >
                    <div>
                      <b>{Activities}</b>
                      <TextBoxComponent
                        name="activities"
                        cssClass='e-outline'
                        enabled={false}
                        value={data.name}
                      />
                      <br /><br />
                      <b>{Students}</b>
                      <MultiSelectComponent id="mt_student"
                        ref={mt_student}
                        cssClass='e-outline'
                        placeholder={`--${Select_Student}--`}
                        dataSource={dataStudent}
                        fields={{ text: 'nickname', value: 'id' }}
                        filtering={onFilteringStudent}
                        allowFiltering={true}
                        value={stateFiltering.students}
                        itemTemplate={itemTemplate}
                      />
                      {error.student === true ? <span style={{ color: 'red' }}>{Please_select_student}</span> : ''}
                      <br /><br />
                      <b>{Registration_Date}</b>
                      <DatePickerComponent
                        cssClass="e-outline"
                        name="date"
                        format='dd-MMM-yyyy'
                        change={(e) => handleChangeStudent(e, "registration_date")}
                        value={stateStudentRef.current.registration_date}
                      />
                      <br /><br />
                      <b>{Payment_Date}</b>
                      <DatePickerComponent
                        cssClass="e-outline"
                        name="payment_date"
                        format='dd-MMM-yyyy'
                        change={(e) => handleChangeStudent(e, "payment_date")}
                        value={stateStudentRef.current.payment_date}
                      />
                      <br /><br />
                      <b>{Amount_Paid}</b>
                      <NumericTextBoxComponent
                        cssClass="e-outline"
                        name="amount_paid"
                        placeholder='IDR 0'
                        format='IDR #########'
                        min={0}
                        change={(e) => handleChangeStudent(e, "amount_paid")}
                        value={stateStudentRef.current.amount_paid}
                      />
                      <br /><br />
                      <b>{Payment_Notes}</b>
                      <TextBoxComponent
                        name="payment_notes"
                        cssClass='e-outline'
                        change={(e) => handleChangeStudent(e, "payment_notes")}
                        value={stateStudentRef.current.payment_notes}
                      />
                      <br /><br />

                      <span style={{ paddingRight: "20px" }}>
                        <CheckBoxComponent
                          checked={parseInt(stateStudentRef.current.attended) === 1 ? true : false}
                          label={Attended}
                          change={(e) => handleChangeStudent(e, "attended")} />
                      </span>
                      {parseInt(data.parent_involvement) === 1 ?
                        <CheckBoxComponent
                          checked={parseInt(stateStudentRef.current.parent_attended) === 1 ? true : false}
                          label={Parent_Attended}
                          change={(e) => handleChangeStudent(e, "parent_attended")} />
                        : ''
                      }
                      <br /><br />
                      <b>{Achievement}</b>
                      <TextBoxComponent
                        name="achievement"
                        cssClass='e-outline'
                        change={(e) => handleChangeStudent(e, "achievement")}
                        value={stateStudentRef.current.achievement}
                      />
                      <br /><br />
                      <b>{Description}</b>
                      <TextBoxComponent
                        name="description"
                        cssClass='e-outline'
                        multiline={true}
                        change={(e) => handleChangeStudent(e, "description")}
                        value={stateStudentRef.current.description}
                      />
                    </div>
                  </DialogComponent>

                  <DialogComponent
                    id="dialogEditStudent"
                    width="30%"
                    showCloseIcon={!loadingButton ? true : false}
                    visible={false}
                    ref={dialogEditStudent}
                    header={Student_Participants}
                    isModal={true}
                    footerTemplate={() => footerTemplate([
                      { name: Update, func: editStudent, icon: "icon-check-mark-01", status: "e-success" },
                      { name: Cancel, func: handleCloseDialogEditStudent, icon: "icon-treeview", status: "" }
                    ])}
                    close={handleCloseDialogEditStudent}
                  >
                    <div>
                      <b>{Activities}</b>
                      <TextBoxComponent
                        name="activities"
                        cssClass='e-outline'
                        enabled={false}
                        value={data.name}
                      />
                      <br /><br />
                      <b>{Student}</b>
                      <TextBoxComponent
                        name="student"
                        cssClass='e-outline'
                        enabled={false}
                        value={stateStudentRef.current.students_name}
                      />
                      <br /><br />
                      <b>{Registration_Date}</b>
                      <DatePickerComponent
                        cssClass="e-outline"
                        name="date"
                        format='dd-MMM-yyyy'
                        change={(e) => handleChangeStudent(e, "registration_date")}
                        value={stateStudentRef.current.registration_date}
                      />
                      <br /><br />
                      <b>{Payment_Date}</b>
                      <DatePickerComponent
                        cssClass="e-outline"
                        name="payment_date"
                        format='dd-MMM-yyyy'
                        change={(e) => handleChangeStudent(e, "payment_date")}
                        value={stateStudentRef.current.payment_date}
                      />
                      <br /><br />
                      <b>{Amount_Paid}</b>
                      <NumericTextBoxComponent
                        cssClass="e-outline"
                        name="amount_paid"
                        placeholder='IDR 0'
                        format='IDR #########'
                        min={0}
                        change={(e) => handleChangeStudent(e, "amount_paid")}
                        value={stateStudentRef.current.amount_paid}
                      />
                      <br /><br />
                      <b>{Payment_Notes}</b>
                      <TextBoxComponent
                        name="payment_notes"
                        cssClass='e-outline'
                        change={(e) => handleChangeStudent(e, "payment_notes")}
                        value={stateStudentRef.current.payment_notes}
                      />
                      <br /><br />

                      <span style={{ paddingRight: "20px" }}>
                        <CheckBoxComponent
                          checked={parseInt(stateStudentRef.current.attended) === 1 ? true : false}
                          label={Attended}
                          change={(e) => handleChangeStudent(e, "attended")} />
                      </span>

                      {parseInt(data.parent_involvement) === 1 ?
                        <CheckBoxComponent
                          checked={parseInt(stateStudentRef.current.parent_attended) === 1 ? true : false}
                          label={Parent_Attended}
                          change={(e) => handleChangeStudent(e, "parent_attended")}
                        />
                        : ''
                      }
                      <br /><br />
                      <b>{Achievement}</b>
                      <TextBoxComponent
                        name="achievement"
                        cssClass='e-outline'
                        change={(e) => handleChangeStudent(e, "achievement")}
                        value={stateStudent.achievement}
                      />
                      <br /><br />
                      <b>{Description}</b>
                      <TextBoxComponent
                        name="description"
                        cssClass='e-outline'
                        multiline={true}
                        change={(e) => handleChangeStudent(e, "description")}
                        value={stateStudent.description}
                      />
                    </div>
                  </DialogComponent>


                  <DialogComponent
                    id="dialogDeleteStudent"
                    width="35%"
                    showCloseIcon={!loadingButton ? true : false}
                    visible={false}
                    ref={dialogDeleteStudent}
                    header={Delete_Confirmation}
                    isModal={true}
                    footerTemplate={() => footerTemplate([
                      { name: Delete, func: deleteStudent, icon: "icon-delete-05", status: "e-warning" },
                      { name: Cancel, func: handleCloseDialogDeleteStudent, icon: "icon-treeview", status: "" }
                    ])}
                    close={handleCloseDialogDeleteStudent}
                  >
                    <div>
                      {selectedData ? selectedData.students_id ?
                        <div style={{ fontSize: "14px" }}>
                          {Are_you_sure_you_want_to_delete_the_data_of_student_participants} ({selectedData.students_id.name})?
                        </div>
                        : '' : ''}
                    </div>
                  </DialogComponent>

                  {/* ================== Rooms Dialog ========================================== */}
                  <DialogComponent
                    id="dialogAddRoom"
                    width="30%"
                    showCloseIcon={!loadingButton ? true : false}
                    visible={false}
                    ref={dialogAddRoom}
                    header={Rooms}
                    isModal={true}
                    footerTemplate={() => footerTemplate([
                      { name: Update, func: addRoom, icon: "icon-check-mark-01", status: "e-success" },
                      { name: Cancel, func: handleCloseDialogAddRoom, icon: "icon-treeview", status: "" }
                    ])}
                    close={handleCloseDialogAddRoom}
                  >
                    <div>
                      <b>{Activities}</b>
                      <TextBoxComponent
                        name="name"
                        cssClass='e-outline'
                        enabled={false}
                        value={data.name}
                      />
                      <br /><br />
                      <b>{Rooms}</b>
                      <MultiSelectComponent
                        id="mt_room"
                        ref={mt_room}
                        cssClass='e-outline'
                        placeholder={`--${Select_Room}--`}
                        dataSource={dataRoom}
                        fields={{ text: 'name', value: 'id' }}
                        filtering={onFilteringRoom}
                        allowFiltering={true}
                        value={stateFiltering.rooms}
                      />
                      {error.room === true ? <span style={{ color: 'red' }}>{Please_select_room}</span> : ''}
                    </div>
                  </DialogComponent>

                  <DialogComponent
                    id="dialogDeleteRoom"
                    width="35%"
                    showCloseIcon={!loadingButton ? true : false}
                    visible={false}
                    ref={dialogDeleteRoom}
                    header={Delete_Confirmation}
                    isModal={true}
                    footerTemplate={() => footerTemplate([
                      { name: Delete, func: deleteRoom, icon: "icon-delete-05", status: "e-warning" },
                      { name: Cancel, func: handleCloseDialogDeleteRoom, icon: "icon-treeview", status: "" }
                    ])}
                    close={handleCloseDialogDeleteRoom}
                  >
                    <div>
                      {selectedData ? selectedData.rooms_id ?
                        <div style={{ fontSize: "14px" }}>
                          {Are_you_sure_you_want_to_delete_the_data_of_room_used} ({selectedData.rooms_id.name})?
                        </div>
                        : '' : ''}
                    </div>
                  </DialogComponent>


                  <DialogComponent
                    id="dialogStudentPresent"
                    width="35%"
                    showCloseIcon={!loadingButton ? true : false}
                    visible={false}
                    ref={dialogStudentPresent}
                    header={Confirmation}
                    isModal={true}
                    footerTemplate={() => footerTemplate([
                      { name: Update, func: updateStudentPresent, icon: "icon-check-mark-01", status: "e-success" },
                      { name: Cancel, func: handleCloseDialogStudentPresent, icon: "icon-treeview", status: "" }
                    ])}
                    close={handleCloseDialogStudentPresent}
                  >
                    <div style={{ fontSize: "14px" }}>
                      {Are_you_sure_you_want_to_change_all_attendance_status_of_students_attend}?
                    </div>
                  </DialogComponent>

                  <DialogComponent
                    id="dialogParentPresent"
                    width="35%"
                    showCloseIcon={!loadingButton ? true : false}
                    visible={false}
                    ref={dialogParentPresent}
                    header={Confirmation}
                    isModal={true}
                    footerTemplate={() => footerTemplate([
                      { name: Update, func: updateParentPresent, icon: "icon-check-mark-01", status: "e-success" },
                      { name: Cancel, func: handleCloseDialogParentPresent, icon: "icon-treeview", status: "" }
                    ])}
                    close={handleCloseDialogParentPresent}
                  >
                    <div style={{ fontSize: "14px" }}>
                      {Are_you_sure_you_want_to_change_all_attendance_status_of_parents_attend}?
                    </div>
                  </DialogComponent>
                </div>
              </div>
              : ''}
          </div>
        </div>
      }
    </div>
  );
}

export default ActivityDetail;