import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EventEmitter from 'src/utils/EventEmitter';
import i18next from "i18next";
import useStateRef from "react-usestateref";

import { InPlaceEditorComponent } from '@syncfusion/ej2-react-inplace-editor';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import CircularProgress from '@material-ui/core/CircularProgress';

import directus from 'src/services/directus';

const Configure = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();

  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));

  const [Name] = useState(i18next.t('Pages.data.Name'));

  const [Group] = useState(i18next.t('Pages.subject_class_data.Group'));
  const [Code] = useState(i18next.t('Pages.subject_class_data.Code'));
  const [Short_Name] = useState(i18next.t('Pages.subject_class_data.Short_Name'));
  const [Meeting_Duration] = useState(i18next.t('Pages.subject_class_data.Meeting_Duration'));
  const [Registration_Fee] = useState(i18next.t('Pages.subject_class_data.Registration_Fee'));
  const [Monthly_Fee] = useState(i18next.t('Pages.subject_class_data.Monthly_Fee'));
  const [Lesson_Fee] = useState(i18next.t('Pages.subject_class_data.Lesson_Fee'));

  const [data, setData, dataRef] = useStateRef(null);
  const [dataSubjectGroup, setDataSubjectGroup] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [loadingBiodata, setLoadingBiodata] = useState(false);

  const [error, setError] = useState({ name: false });
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoadingBiodata(true);
      getSubjectGroup();
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  const getSubjectGroup = async () => {
    try {
      var result = await directus.getItems('subject_group');

      setDataSubjectGroup(result.data);
      getSubject();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const getSubject = async () => {
    try {
      var result = await directus.getItems('subjects',
        {
          fields: '*.*.*',
          filter: {
            id: id
          }
        }
      );

      setData(result.data[0]);
      setLoadingBiodata(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const actionSuccess = async (type, e) => {
    setError({ name: false });
    var value = null;

    if (type === "group") {
      value = e.itemData.id
      setData({...dataRef.current, group: e.itemData})
    } else {
      value = e.value;
      if (type === "sort" || type === "meeting_duration" || type === "registration_fee" || type === "monthly_fee" || type === "lesson_fee") {
        if (!value) { value = null; }
      }
    }

    if (type === "name" && value === "" && value === null) {
      setError({ name: true });
    } else {
      try {
        var result = await directus.updateItem('subjects', id, { [type]: value });

        var description = dataRef.current.name;
        if (type === "name" && dataRef.current.name !== value) {
          description = dataRef.current.name + " --> " + value;
        } 
        
        const body_activity = {
          action: "Update",
          category: 7,
          description: description,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          getSubject();
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      }
    }


  }

  return <div id="biodata" style={{ alignContent: "center", color: "#333", paddingTop: "10px", paddingBottom: "30px" }}>
    {loadingBiodata ? <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "5%", marginLeft: "50%" }} /> :
      dataRef.current ?
        <div>

          <table id="table_subject" style={{ marginLeft: "20px", marginTop: "20px" }}>
            <tbody>
              <tr>
                <td style={{ width: "150px" }}>{Group}</td>
                <td>
                  <div style={{ width: "20%" }}>
                    <DropDownListComponent
                      dataSource={dataSubjectGroup}
                      fields={{ text: 'name', value: 'id' }}
                      name="group"
                      value={dataRef.current.group.id ? dataRef.current.group.id : null}
                      select={(e) => actionSuccess("group", e)}
                      allowFiltering={true}
                    ></DropDownListComponent>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Sort</td>
                <td>
                  <InPlaceEditorComponent emptyText={'-'} mode='Inline' name='sort' data-underline='false' editableOn="DblClick" value={data.sort} model={{ placeholder: 'Sort' }} actionSuccess={(e) => actionSuccess("sort", e)} />
                </td>
              </tr>
              <tr>
                <td>{Name}</td>
                <td>
                  <InPlaceEditorComponent emptyText={'-'} mode='Inline' name='name' data-underline='false' editableOn="DblClick" value={data.name} model={{ placeholder: 'Name' }} actionSuccess={(e) => actionSuccess("name", e)} />
                  {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                </td>
              </tr>
              <tr>
                <td>{Short_Name}</td>
                <td>
                  <InPlaceEditorComponent emptyText={'-'} mode='Inline' name='short_name' data-underline='false' editableOn="DblClick" value={data.short_name} model={{ placeholder: 'Short Name' }} actionSuccess={(e) => actionSuccess("short_name", e)} />
                </td>
              </tr>
              <tr>
                <td>{Code}</td>
                <td>
                  <InPlaceEditorComponent emptyText={'-'} mode='Inline' name='code' data-underline='false' editableOn="DblClick" value={data.code} model={{ placeholder: 'Code' }} actionSuccess={(e) => actionSuccess("code", e)} />
                </td>
              </tr>
              <tr>
                <td>{Meeting_Duration}</td>
                <td>
                  <InPlaceEditorComponent emptyText={0} mode='Inline' name='meeting_duration' data-underline='false' editableOn="DblClick" value={data.meeting_duration} model={{ placeholder: 'Meeting Duration' }} actionSuccess={(e) => actionSuccess("meeting_duration", e)} />
                </td>
              </tr>
              <tr>
                <td>{Registration_Fee}</td>
                <td>IDR. &nbsp;
                  <InPlaceEditorComponent emptyText={0} mode='Inline' type="Numeric" name='registration_fee' data-underline='false' editableOn="DblClick" value={data.registration_fee} model={{ placeholder: 'Registration Fee' }} actionSuccess={(e) => actionSuccess("registration_fee", e)} />
                </td>
              </tr>
              <tr>
                <td>{Monthly_Fee}</td>
                <td>IDR. &nbsp;
                  <InPlaceEditorComponent emptyText={0} mode='Inline' type="Numeric" name='monthly_fee' data-underline='false' editableOn="DblClick" value={data.monthly_fee} model={{ placeholder: 'Monthly Fee' }} actionSuccess={(e) => actionSuccess("monthly_fee", e)} />
                </td>
              </tr>
              <tr>
                <td>{Lesson_Fee}</td>
                <td>IDR. &nbsp;
                  <InPlaceEditorComponent emptyText={0} mode='Inline' type="Numeric" name='lesson_fee' data-underline='false' editableOn="DblClick" value={data.lesson_fee} model={{ placeholder: 'Lesson Fee' }} actionSuccess={(e) => actionSuccess("lesson_fee", e)} />
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        : ''
    }
  </div>;
}

export default Configure;