import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import useStateRef from "react-usestateref";

import LoginPassword from './LoginPassword';
import LoginSMSEmail from './LoginSMSEmail';

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import './index.css';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  title: {

  },
  button1: {
    width: '100%',
    padding: '2%',
    borderRadius: '4px'
  }
}));


const LoginView = () => {
  const classes = useStyles();
  const [mode, setMode, modeRef] = useStateRef(1);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);
  const [username, setUsername] = React.useState(null);
  const [errorUsername, setErrorUsername] = React.useState(false);

  const handleChangeUsername = (e) => {
    setErrorUsername(false);
    setUsername(e.target.value)
  }

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    }
  }, [isInitialized, isBusy]);

  const sendOTP = (company, email, modeValue) => {
    setIsBusy(true);

    if (modeValue !== 4) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: email, channel: modeValue === 2 ? 'sms' : 'email' })
      };

      fetch(`${process.env.REACT_APP_URL}${company}console/custom/auth/otp`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.error) {
            window.localStorage.clear();
            window.alert(result.error.message);
            setIsBusy(false);
          }
          else {
            setMode(modeValue)
            setIsBusy(false);
          }
        })
    }
    else {
      setMode(modeValue);
      setIsBusy(false);
    }
  }

  return (
    <Page className={classes.root} title="Login">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">

          <Formik
            initialValues={{
              company: '',
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid Value')
                .max(255),
            })}
            onSubmit={() => {
              setIsBusy(true);
            }}
          >
            {({ values }) => (
              <div>

                <div style={{ fontSize: "29px", fontWeight: "600" }}>Sign in</div>
                <br />
                <div className="textbox_login">
                  <TextBoxComponent
                    id="username_textbox"
                    placeholder="Username"
                    cssClass={`e-outline ${errorUsername ? "e-error" : ""}`}
                    className="textbox_custom"
                    floatLabelType="Auto"
                    disabled={modeRef.current === 2 || modeRef.current === 3}
                    fullWidth
                    label="Username"
                    margin="normal"
                    name="username"
                    onChange={(e) => handleChangeUsername(e)}
                    value={username}
                  />
                </div>
                {errorUsername ? <div style={{ color: "red" }}>*Username is Required</div> : ''}
                <Box my={2}>
                  {modeRef.current === 1 ?
                    <div>
                      <ButtonComponent
                        disabled={isBusy}
                        cssClass='e-info'
                        iconCss='e-icons e2-key'
                        className="btn_login"
                        onClick={() => sendOTP(values.company, username, 2)}>
                        Send PIN to SMS
                      </ButtonComponent>

                      <ButtonComponent
                        disabled={isBusy}
                        cssClass='e-info'
                        iconCss='e-icons e2-mail-message'
                        className="btn_login"
                        onClick={() => sendOTP(values.company, username, 3)}>
                        Sign in with Email
                      </ButtonComponent>

                      <ButtonComponent
                        disabled={isBusy}
                        id="signin_button"
                        cssClass='e-info'
                        iconCss='e-icons icon-password'
                        className="btn_login"
                        onClick={() => sendOTP(values.company, username, 4)}>
                        Sign in with Password
                      </ButtonComponent>
                      <br /><br />
                    </div>
                    :
                    modeRef.current === 2 || modeRef.current === 3 ?
                      <LoginSMSEmail
                        setMode={setMode}
                        company={values.company}
                        username={username}
                        mode={mode === 2 ? 'sms' : 'email'}
                        setErrorUsername={setErrorUsername}
                      />
                      :
                      <LoginPassword
                        setMode={setMode}
                        company={values.company}
                        username={username}
                        setErrorUsername={setErrorUsername}
                      />
                  }
                </Box>
              </div>

            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
