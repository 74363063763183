const user = JSON.parse(window.localStorage.getItem('directus_user'));

export function AdminPermission() {
  if (user) {
    return (user.role === "1" || user.role.id === 1);
  }
  else {
    return false;
  }
}

export function TeacherPermission() {
  if (user) {
    return (user.role === "6" || user.role.id === 6 || AdminPermission());
  }
  else {
    return false;
  }
}