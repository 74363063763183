import React, { useState, useEffect } from 'react';
import useStateRef from "react-usestateref";

import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { Query } from '@syncfusion/ej2-data';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import Enumerable from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

import './index.css';

const MeetingSummary = () => {
  /* eslint-disable no-unused-vars*/
  const [Search] = useState(i18next.t('Pages.button.Search'));

  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));

  const [All_Teachers] = useState(i18next.t('Pages.data.All_Teachers'));
  const [Sunday] = useState(i18next.t('Pages.data.Sunday'));
  const [Monday] = useState(i18next.t('Pages.data.Monday'));
  const [Tuesday] = useState(i18next.t('Pages.data.Tuesday'));
  const [Wednesday] = useState(i18next.t('Pages.data.Wednesday'));
  const [Thursday] = useState(i18next.t('Pages.data.Thursday'));
  const [Friday] = useState(i18next.t('Pages.data.Friday'));
  const [Saturday] = useState(i18next.t('Pages.data.Saturday'));

  const [Number_of_Meetings] = useState(i18next.t('Pages.subject_class_data.Number_of_Meetings'));
  const [Class] = useState(i18next.t('Pages.subject_class_data.Class'));
  const [Teacher_Meeting_Summary] = useState(i18next.t('Pages.subject_class_data.Teacher_Meeting_Summary'));
  const [Note] = useState(i18next.t('Pages.subject_class_data.Note'));
  const [Total] = useState(i18next.t('Pages.subject_class_data.Total'));
  const [Reason] = useState(i18next.t('Pages.subject_class_data.Reason'));
  const [Change_Schedule] = useState(i18next.t('Pages.subject_class_data.Change_Schedule'));
  const [Changed_Schedule] = useState(i18next.t('Pages.subject_class_data.Changed_Schedule'));

  const [Date_Time] = useState(i18next.t('Pages.schedule_data.Date_Time'));

  //data
  const [data, setData] = useState(null);
  const [scheduleChangeData, setScheduleChangeData] = useState([]);
  const [scheduleChangeData2, setScheduleChangeData2] = useState([]);
  const [dataTeacher, setDataTeacher] = useState(null);

  const [dataTeacherSchedule, setDataTeacherSchedule, dataTeacherScheduleRef] = useStateRef([]);
  const [loadingSearching, setLoadingSearching, loadingSearchingRef] = useStateRef(false);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);

  //set data filter
  const [dataFilter, setDataFilter, dataFilterRef] = useStateRef({
    start_date: moment().clone().startOf('month').format(),
    end_date: moment().clone().endOf('month').format(),
    teacher: 0,
  })

  //other component setting
  const weeks = [{ id: "Sunday", name: Sunday }, { id: "Monday", name: Monday }, { id: "Tuesday", name: Tuesday }, { id: "Wednesday", name: Wednesday }, { id: "Thursday", name: Thursday }, { id: "Friday", name: Friday }, { id: "Saturday", name: Saturday }]
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getTeacher();
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }

  //get list of teachers
  const getTeacher = async () => {
    try {
      var result = await directus.getItems('teachers', { fields: "id,name,nickname" });
      var newData = result.data;

      if (result.data.length > 0) {
        var oldData = [{ id: 0, nickname: All_Teachers }];
        newData = oldData.concat(newData);
      }

      setDataTeacher(newData);
      getData();

    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const getData = async () => {
    try {
      var teacher = { "nnull": null };

      if (dataFilterRef.current.teacher !== 0 && dataFilterRef.current.teacher !== null) {
        teacher = { "in": dataFilterRef.current.teacher };
      }

      var start_date = moment(dataFilterRef.current.start_date).format("YYYY-MM-DD");
      var end_date = moment(dataFilterRef.current.end_date).format("YYYY-MM-DD");

      var result = await directus.getItems('schedules',
        {
          fields: "id,date,time,teachers_id.*,subject_classes_id.name,subject_classes_id.id,subject_classes_id.subjects.id,subject_classes_id.subjects.name",
          sort: "teachers_id",
          filter: {
            'date': {
              'between': [start_date, end_date]
            },
            'teachers_id.id': teacher,
            'subject_classes_id.status': "published",
            'status': "published",
            'teacher_attd': 'Present'
          }
        });

      if (dataFilterRef.current.teacher !== 0 && dataFilterRef.current.teacher !== null) {
        var teacher_schedule = result.data;
        teacher_schedule = Enumerable.from(teacher_schedule)
          .orderBy(o => o.date)
          .thenBy(o => o.time)
          .toArray();
        setDataTeacherSchedule(teacher_schedule);
      } else {
        setDataTeacherSchedule([]);
      }

      let data2 = result.data;

      data2 = Enumerable.from(data2)
        .groupBy(x => x.teachers_id.id,
          element => element,
          (teachers_id, data) => ({ teachers_id, data: data.toArray() })
        )
        .toArray();

      var data_teacher_meeting = [];

      //eslint-disable-next-line array-callback-return
      data2.map((value) => {
        var data_meeting = value.data;

        data_meeting = Enumerable.from(data_meeting)
          .groupBy(x => x.subject_classes_id.subjects.id,
            element => element,
            (subject_classes_id, data) => ({ subject_classes_id, data: data.toArray() })
          )
          .toArray();

        data_teacher_meeting.push(
          {
            teacher: value.teachers_id,
            data: data_meeting
          }
        )
      })

      setData(data_teacher_meeting)
      getScheduleChange();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }


  const getScheduleChange = async () => {
    var teacher = { "nnull": null };

    if (dataFilterRef.current.teacher !== 0 || dataFilterRef.current.teacher !== "") {
      teacher = { "in": dataFilterRef.current.teacher };
    }

    var result = await directus.getItems('schedules',
      {
        fields: "*.*.*",
        sort: "teachers_id",
        filter: {
          'date': {
            'between': [moment(dataFilterRef.current.start_date).format("YYYY-MM-DD"), moment(dataFilterRef.current.end_date).format("YYYY-MM-DD")]
          },
          'previous_teacher.id': teacher
        }
      });
    let data2 = result.data;

    setScheduleChangeData(data2);

    var teacher2 = { "nnull": null };

    if (dataFilterRef.current.teacher !== 0 || dataFilterRef.current.teacher !== null) {
      teacher2 = { "in": dataFilterRef.current.teacher };
    }

    var result2 = await directus.getItems('schedules',
      {
        fields: "*.*.*",
        sort: "teachers_id",
        filter: {
          'date': {
            'between': [moment(dataFilterRef.current.start_date).format("YYYY-MM-DD"), moment(dataFilterRef.current.end_date).format("YYYY-MM-DD")]
          },
          'previous_teacher.id': { "nnull": null },
          'teachers_id.id': teacher2
        }
      });

    let data3 = result2.data;
    setScheduleChangeData2(data3);
    setLoading(false);
    setLoadingSearching(false);
  }

  function getTotal(props) {
    var list = [];
    var total = 0;
    props.data.forEach(value => {
      total += value.data.length
    })

    list.push(total);

    return (
      <div>{list}</div>
    )
  }

  const getWeek = (props) => {
    var week2 = props ? props : null;
    if (weeks) {
      weeks.forEach((value) => {
        if (value.id === week2) {
          week2 = value.name
        }
      })
    }
    return "" + week2
  }

  function getTeacherChangeInfo(props) {
    var list = [];
    var teacherId = props.data[0].data[0].teachers_id.id;

    if (scheduleChangeData.length > 0) {
      var result_previous = Enumerable.from(scheduleChangeData)
        .where(w =>
          Enumerable.from(teacherId).contains(w.previous_teacher.id))
        .orderBy(o => o.date)
        .toArray();

      if (result_previous.length > 0) {
        //eslint-disable-next-line array-callback-return
        result_previous.map((value, index) => {
          list.push(
            <div key={index}>
              {index + 1}) {getWeek(moment(value.date).format("dddd"))}, {moment(value.date).format("DD-MMM-YYYY")} ({value.subject_classes_id.name}) : {value.previous_teacher.nickname} --{'>'} {value.teachers_id.nickname} <br />
              <span style={{ paddingLeft: "18px" }}>{Reason} : {value.teacher_reason}</span>
            </div>
          )
        })
      }
    }

    return (<div>{list.length > 0 ? <span style={{ color: "#d58d52" }}><b>{Change_Schedule} :</b></span> : null}{list}</div>)
  }

  function getTeacherChangeInfo2(props) {
    var list = [];
    var teacherId = props.data[0].data[0].teachers_id.id;

    if (scheduleChangeData2.length > 0) {
      var result_previous = Enumerable.from(scheduleChangeData2)
        .where(w =>
          Enumerable.from(teacherId).contains(w.teachers_id.id))
        .orderBy(o => o.date)
        .toArray();

      if (result_previous.length > 0) {
        //eslint-disable-next-line array-callback-return
        result_previous.map((value, index) => {
          list.push(
            <div key={index}>
              {index + 1}) {getWeek(moment(value.date).format("dddd"))}, {moment(value.date).format("DD-MMM-YYYY")} ({value.subject_classes_id.name}) : {value.previous_teacher.nickname} --{'>'} {value.teachers_id.nickname} <br />
              <span style={{ paddingLeft: "18px" }}>{Reason} : {value.teacher_reason}</span>
            </div>
          )
        })
      }
    }

    return (<div>{list.length > 0 ? <span style={{ color: "#0378d5" }}><b>{Changed_Schedule} :</b></span> : null}{list}</div>)
  }

  const onFilteringTeacher = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataTeacher, query);
  };

  const handleFilter = async (e, type) => {
    var value = '';
    if (type === "start_date" || type === "end_date") {
      value = e.value;
    } else if (type === "teacher" || type === "subject_class") {
      value = e.itemData.id;
    }
    setDataFilter({ ...dataFilterRef.current, [type]: value });

    // getData();
    // getData({ [type]: value })
  }

  const search = () => {
    setLoadingSearching(true);
    getData();
  }

  const getTeacherName = (value) => {
    var teacher_name = "";
    if (value.nickname) {
      teacher_name += value.nickname;

      if (value.name && value.name !== value.nickname) {
        teacher_name += " (" + value.name + ")"
      }
    } else {
      teacher_name = value.name;
    }
    return teacher_name;
  }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Teacher_Meeting_Summary}</div>
          </div>
          <div className="content-main">
            <div style={{ marginTop: "30px", display: 'flex', width: "100%", height: "30px", marginBottom: "25px" }}>

              <div style={{ paddingRight: "5px" }}>
                <DatePickerComponent
                  id="dp_custom"
                  showClearButton={false}
                  cssClass="e-outline"
                  name="start_date"
                  format='dd-MMM-yyyy'
                  value={dataFilterRef.current.start_date}
                  onChange={(e) => handleFilter(e, "start_date")}
                />
              </div>
              <div style={{ paddingRight: "5px" }}>
                <DatePickerComponent
                  id="dp_custom"
                  showClearButton={false}
                  cssClass="e-outline"
                  name="end_date"
                  format='dd-MMM-yyyy'
                  value={dataFilterRef.current.end_date}
                  onChange={(e) => handleFilter(e, "end_date")}
                />
              </div>
              <div style={{ paddingRight: "5px" }}>
                <DropDownListComponent
                  id="ddl_custom"
                  dataSource={dataTeacher}
                  name="teacher"
                  cssClass="e-outline"
                  placeholder={All_Teachers}
                  fields={{ text: 'nickname', value: 'id' }}
                  select={(e) => handleFilter(e, "teacher")}
                  value={dataFilterRef.current.teacher}
                  // search teacher by name 
                  filtering={onFilteringTeacher}
                  allowFiltering={true}
                  className="form-control"
                />
              </div>
              <div style={{ paddingRight: "5px", lineHeight: "30px" }}>
                {loadingSearchingRef.current === false ?
                  <ButtonComponent
                    className="btn-custom"
                    iconCss='e-icons icon-MT_search'
                    onClick={search}
                  >
                    <span className="btn-text-custom">
                      {Search}
                    </span>
                  </ButtonComponent>
                  :
                  <CircularProgress disableShrink style={{ color: "#3c8dbc", width: "25px", height: "25px", marginTop: "2.5px" }} />
                }
              </div>
            </div>


            {data ? data.length > 0 ?
              data.map((value, index) =>
                <div key={index}>
                  <div id="teacher_summary_header">
                    {getTeacherName(value.data[0].data[0].teachers_id)}
                  </div>
                  <div id="teacher_summary_content">
                    <table id="table_teacher_meetings">
                      <thead>
                        <tr>
                          <td style={{ minWidth: "200px" }}><b>{Class}</b></td>
                          <td style={{ minWidth: "20%" }}><b>{Number_of_Meetings}</b></td>
                        </tr>
                      </thead>
                      <tbody>
                        {value.data.map((value2, index2) =>
                          <tr key={index2}>
                            <td>{value2.data[0].subject_classes_id.subjects.name}</td>
                            <td style={{ textAlign: "right", paddingRight: "8%" }}>{value2.data.length}</td>
                          </tr>)}
                        <tr>
                          <td><b>{Total}</b></td>
                          <td style={{ textAlign: "right", paddingRight: "8%" }}><b>{getTotal(value)}</b></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {data.length === 1 ? <>
                    <div id="teacher_summary_detail" style={{ marginTop: "30px" }}>
                      <table id="table_teacher_meetings">
                        <thead>
                          <tr>
                            <td style={{ minWidth: "150px" }}><b>{Date_Time}</b></td>
                            <td style={{ minWidth: "50%" }}><b>{Class}</b></td>
                          </tr>
                        </thead>
                        <tbody>
                          {dataTeacherScheduleRef.current.map((value2, index2) =>
                            <tr key={index2}>
                              <td>{moment(value2.date).format("DD-MMM-YYYY")} {moment(value2.time, "HH:mm:00").format("HH:mm")}</td>
                              <td>{value2.subject_classes_id.name}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </> : null}
                  <br />
                  <div id="teacher_summary_note">
                    {scheduleChangeData.length > 0 || scheduleChangeData2 > 0 ? <b>{Note} : </b> : null}
                    {getTeacherChangeInfo(value)}
                    {getTeacherChangeInfo2(value)}
                  </div>
                  <br />
                </div>
              )
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div> : ''}
          </div>
        </div>
      }
    </div>
  );
}

export default MeetingSummary;