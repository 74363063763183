import React, { useState, useEffect, useRef } from 'react';
import useStateRef from "react-usestateref";
import { useNavigate, Link } from 'react-router-dom';

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import linq from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

let cancelFlag = false;
const ActivityType = () => {
  /* eslint-disable no-unused-vars*/
  const navigate = useNavigate();

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  const [Name] = useState(i18next.t('Pages.activity_data.Name'));
  const [Summary] = useState(i18next.t('Pages.activity_data.Summary'));
  // const [Action] = useState(i18next.t('Pages.data.Action'));

  const [Activity_Type] = useState(i18next.t('Pages.activity_data.Activity_Type'));
  const [Enter_activity_type_name] = useState(i18next.t('Pages.activity_data.Enter_activity_type_name'));
  const [Are_you_sure_you_want_to_delete_the_data_of_activity_type] = useState(i18next.t('Pages.activity_data.Are_you_sure_you_want_to_delete_the_data_of_activity_type'));
  const [Activity_type_created_successfully] = useState(i18next.t('Pages.activity_data.Activity_type_created_successfully'));
  const [Activity_type_deleted_successfully] = useState(i18next.t('Pages.activity_data.Activity_type_deleted_successfully'));

  //data
  let [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogDeleteActivity = useRef(false);
  let dialogAddActivity = useRef(false);

  //grid component setting
  const grid = useRef();
  const setting = { mode: 'Both' };
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    // mode: 'Batch' 
    mode: 'Normal'
  };
  const validationRule = { required: true };

  //set state add activity
  const [state, setState] = useState({ name: null, status: "published" });

  //set state filtering
  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    name: null
  });

  //set error
  const [error, setError, errorRef] = useStateRef({
    name: false
  });
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "delete_activity") {
        dialogDeleteActivity.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Activity_type_deleted_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })

  // {/* ================== Get All Data ========================================== */ }
  //get list of activity type
  const getData = async () => {
    try {
      var name = { "nnull": null };

      if (stateFiltering.name !== null && stateFiltering.name !== "") {
        name = { "contains": stateFilteringRef.current.name };
      }

      var result = await directus.getItems('activity_type',
        {
          fields: "*.*",
          sort: "name",
          filter: {
            name: name,
            status: "published"
          }
        });

      setData(result.data);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }



  // {/* ================== Filtering ========================================== */ }

  const handleChangeFilter = (event, type) => {
    var value = "";
    if (type === "name") {
      value = event.value
    }
    setStateFiltering({ ...stateFiltering, [type]: value })
    if (event.syntheticEvent.type === 'change') {
      getData();
    }
  }

  const searchActivity = () => {
    getData();
  }


  // {/* ================== Data Grid Template ========================================== */ }

  const buttonTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_hover" style={{ display: "flex" }}>
          <TooltipComponent content={Summary} position="BottomCenter" >
            <span>
              <Link to={{ pathname: `/activity_type/summary/${props.id}` }} style={{ textDecoration: "none" }}>
                <span className="e-icons icon-MT_List"
                  style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
                />
              </Link>
            </span>
          </TooltipComponent>

          <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px", }} >
            <span className="e-icons icon-delete-05"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => handleOpenDialogDeleteActivity(props)}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const buttonEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter" >
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  // {/* ================== Data Grid Action (edit) ========================================== */ }

  const actionGrid = async (event) => {
    if (event.action === "edit" && event.requestType === "save") {
      if (cancelFlag === false) {
        try {
          const data = event.data;
          var result = await directus.updateItem('activity_type', data.id, { name: data.name });

          var description = data.name;
          if (data.name !== event.previousData.name) {
            description = event.previousData.name + " --> " + data.name;
          }
          const body_activity = {
            action: "Update",
            category: 13,
            description: description,
            status: "published"
          }
          await directus.createItem('activity_log', body_activity);

          if (!result.error) {
            getData();
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          setLoadingButton(false);
        }
      } else {
        grid.current.editModule.closeEdit()
      }
      cancelFlag = false;

    } else if (event.requestType === "beginEdit") {
      cancelFlag = true;
    }
  }

  // {/* ================== Navigation ========================================== */ }

  // function clickActivity(id) {
  //   return navigate('/activity_type/summary/' + id);
  // }

  // {/* ================== Handle Add Acitivity ========================================== */ }

  const handleOpenDialogAddActivity = () => {
    dialogAddActivity.current.visible = true;
    setState({ name: null, status: "published" })
  }

  const handleCloseDialogAddActivity = () => {
    dialogAddActivity.current.visible = false;
    setError({ name: false });
  }

  const handleChange = (event, type) => {
    var value = event.value;
    if (value) {
      setError({ ...errorRef.current, [type]: false })
    }
    setState({ ...state, [type]: value })
  }

  const addActivity = async () => {
    try {
      setError({ name: false });
      if (state.name) {
        setLoadingButton(true);
        var result = await directus.createItem('activity_type', state);

        const body_activity = {
          action: "Add",
          category: 13,
          description: state.name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          getData();
          dialogAddActivity.current.visible = false;
          setState({ name: null, status: "published" });
          EventEmitter.emit('alert_toast', { content: Activity_type_created_successfully, type: "success" });
          return navigate('/activity_type/summary/' + result.data.id);
        }
      } else {
        if (!state.name) { setError({ ...errorRef.current, name: true }) }
      }

    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Handle Dialog Remove Activity Type ========================================== */ }

  const handleOpenDialogDeleteActivity = (props) => {
    dialogDeleteActivity.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteActivity = () => {
    dialogDeleteActivity.current.visible = false;
  }

  const deleteActivity = async () => {
    try {
      setLoadingButton(true);

      var result = await directus.getItems('activities',
        {
          filter: { type: selectedData.id }
        });
      var activities = result.data;

      if (activities.length > 0) {
        activities = linq.from(activities)
          .select(x => x.id)
          .toArray();

        //get all activities teachers and delete
        var result_teacher = await directus.getItems('activities_teachers',
          {
            filter: { activities_id: { "in": activities } }
          });
        var activities_teachers = result_teacher.data;

        if (activities_teachers.length > 0) {
          activities_teachers = linq.from(activities_teachers)
            .select(x => x.id)
            .toArray();

          await directus.deleteItems('activities_teachers', activities_teachers);
        }

        //get all activities students and delete
        var result_student = await directus.getItems('activities_students',
          {
            filter: { activities_id: { "in": activities } }
          });

        var activities_students = result_student.data;

        if (activities_students.length > 0) {
          activities_students = linq.from(activities_students)
            .select(x => x.id)
            .toArray();
          await directus.deleteItems('activities_students', activities_students);
        }

        //get all activities rooms and delete
        var result_room = await directus.getItems('activities_rooms',
          {
            filter: { activities_id: { "in": activities } }
          });

        var activities_rooms = result_room.data;
        if (activities_rooms.length > 0) {
          activities_rooms = linq.from(activities_rooms)
            .select(x => x.id)
            .toArray();

          await directus.deleteItems('activities_rooms', activities_rooms);
        }

        //delete all activities with selected activity type
        await directus.deleteItems('activities', activities);
      }

      //delete activity type
      var result_type = await directus.deleteItem('activity_type', selectedData.id);

      const body_activity = {
        action: "Delete",
        category: 13,
        description: selectedData.name,
        status: "published"
      }
      await directus.createItem('activity_log', body_activity);

      if (!result_type.error) {
        getData();
        setDataButton("delete_activity");
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Footer Template ========================================== */}

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }
  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      cancelFlag = false;
      grid.current.endEdit();
    }
  }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Activity_Type}</div>
            <div className="content-searching hide-content">
              <div style={{ display: 'flex', width: "100%" }}>
                <div style={{ width: "250px", height: "30px", marginRight: "10px" }}>
                  <TextBoxComponent
                    cssClass="e-outline searching-text"
                    className="searching-input"
                    id="searching-input"
                    placeholder={Enter_activity_type_name}
                    onChange={(e) => handleChangeFilter(e, "name")}
                    onBlur={(e) => searchActivity(e)}
                    showClearButton="true"
                    value={stateFiltering.name}
                  />
                </div>
                <div>
                  <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddActivity}>
                    <span className="btn-text-custom">{Activity_Type}</span>
                  </ButtonComponent>
                </div>
                {/* <br />
              {data ? data.length > 0 ? 'Total : ' + data.length : ''
                : ''} */}
              </div>
            </div>
          </div>
          <div className="content-main">
            {data ? data.length > 0 ?
              <div style={{ marginTop: "25px", marginBottom: "20px" }}>
                <GridComponent
                  width="70%"
                  dataSource={data}
                  editSettings={editSettings}
                  allowSorting={true}
                  ref={grid}
                  allowTextWrap={true}
                  textWrapSettings={{ wrapMode: "Content" }}
                  allowResizing={true}
                  gridLines='Horizontal'
                  actionBegin={actionGrid}
                  selectionSettings={setting}
                  clipMode="EllipsisWithTooltip"
                  keyPressed={onKeyPressed}
                >
                  <ColumnsDirective>
                    <ColumnDirective customAttributes={{ id: 'first' }} field='name' headerText={Name} validationRules={validationRule}></ColumnDirective>
                    <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="20%" template={buttonTemplate} editTemplate={buttonEditTemplate}></ColumnDirective>
                  </ColumnsDirective>
                  <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                </GridComponent>
              </div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
            }

            <DialogComponent
              id="dialogAddActivity"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogAddActivity}
              header={Activity_Type}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Update, func: addActivity, icon: "icon-check-mark-01", status: "e-success" },
                { name: Cancel, func: handleCloseDialogAddActivity, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogAddActivity}
            >
              <div>
                <b>{Name}</b>
                <TextBoxComponent
                  name="name"
                  cssClass='e-outline'
                  change={(e) => handleChange(e, "name")}
                  value={state.name}
                />
                {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
              </div>
            </DialogComponent>


            <DialogComponent
              id="dialogDeleteActivity"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogDeleteActivity}
              header={Delete_Confirmation}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Delete, func: deleteActivity, icon: "icon-check-mark-01", status: "e-success" },
                { name: Cancel, func: handleCloseDialogDeleteActivity, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogDeleteActivity}
            >
              <div>
                {selectedData ?
                  <div style={{ fontSize: "14px" }}>
                    {Are_you_sure_you_want_to_delete_the_data_of_activity_type} ({selectedData.name})?
                  </div>
                  : ''}
              </div>
            </DialogComponent>

          </div>
        </div>
      }
    </div>
  );
}

export default ActivityType;