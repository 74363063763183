import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { directusProject } from 'src/services/directus';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import MaskedInput from "react-text-mask";
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Enumerable from 'linq';
import useStateRef from "react-usestateref";
const useStyles = makeStyles(() => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    // height: '100%',
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3)
  }
}));

let company = [];
let company_image = [];

const LoginSMSEmail = (props) => {
  /* eslint-disable no-unused-vars*/
  const [open, setOpen] = React.useState(false);
  const [PIN, setPIN] = React.useState(null);
  const [errorPIN, setErrorPIN] = React.useState(false);
  const [companyImage, setCompanyImage, companyImageRef] = useStateRef([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  /* eslint-enable no-unused-vars*/

  function ChooseCompanyDialog(props) {
    const classes = useStyles();
    const { onClose, open } = props;

    const handleListItemClick = async (value) => {
      if (value !== false) {
        window.localStorage.setItem('school', JSON.stringify(value.school.name));

        window.localStorage.setItem("school_list", JSON.stringify(company));
        window.localStorage.setItem("school_images", JSON.stringify(companyImageRef.current));


        let directus = await directusProject(value.school.domain, value);
        window.localStorage.setItem('directus_data', JSON.stringify(value));
        window.localStorage.setItem('directus_user', JSON.stringify(value.user));

        var lang = "id";
        if (value.user.locale === "en-US") { lang = "en" }
        window.localStorage.setItem("lang", lang);

        //set user setting
        var user = value;
        var setting = null;
        if (user.account_type === "administrator") {
          setting = {
            account_type: "administrator",
            user_id: user.user.id,
            data_id: null,
            email: user.user.email,
            name: user.user.first_name + " " + user.user.last_name,
            mobile_number: null,
            lang: lang
          }
        } else {
          var user_setting = null;
          if (user.account_type === "staff") { user_setting = "staff" }
          else if (user.account_type === "student") { user_setting = "students" }
          else if (user.account_type === "teacher") { user_setting = "teachers" }
          else if (user.account_type === "contact") { user_setting = "contacts" }

          var result = await directus.getItems(user_setting, {
            fields: "*.*",
            filter: {
              "user.id": user.user.id
            }
          });

          var new_data = result.data[0];
          setting = {
            account_type: user.account_type,
            user_id: user.user.id,
            data_id: new_data.id,
            email: new_data.email,
            name: new_data.name,
            mobile_number: new_data.mobile_number,
            lang: window.localStorage.lang ? window.localStorage.lang : "id"
          }
        }

        localStorage.setItem('user_setting', JSON.stringify(setting));


        var activity = {
          action: "Login",
          category: 1,
          description: value.user.email,
          status: "published"
        }
        await directus.createItem('activity_log', activity);

        setIsBusy(false);
        onClose(false);
        window.location.href = '/';

      }
      else {
        onClose(false);
      }

    };

    var newColor = "#5F99E9";
    var brightness = "light";

    function randomColor() {
      newColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
      brightness = lightOrDark(newColor);
    }

    function lightOrDark(color) {
      var r, g, b, hsp;
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {

        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
      }
      else {

        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
          color.length < 5 && /./g, '$&$&'
        )
        );

        r = color >> 16;
        g = color >> 8 & 255; //eslint-disable-line no-mixed-operators
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
      );

      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 127.5) {

        return 'light';
      }
      else {

        return 'dark';
      }
    }

    const getImage = (props) => {
      var result = Enumerable.from(companyImageRef.current)
        .where(w => w.name === props)
        .toArray();

      return result[0].image ? result[0].image.full_url : "";
    }

    return companyImageRef.current.length === company.length ?
      <Dialog id="company-dialog" isopen={`${open}`} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title" style={{ fontSize: "16px" }}>Choose Company</DialogTitle>
        <List style={{ width: '375px' }}>
          {company !== 0 ?
            company.map((companylist, index) => (
              <div className={classes.demo} key={index}>
                {randomColor()}
                <List>
                  <ListItem id={`company_${index}`} button onClick={() => handleListItemClick(companylist)}>
                    <ListItemAvatar>
                      {companyImageRef.current[index].image ?
                        <Avatar alt={companylist.school.name} src={getImage(companylist.school.domain)} />
                        :
                        <Avatar style={{ backgroundColor: newColor, color: brightness === "light" ? "black" : "white", fontSize: "20px", fontWeight: "500" }}>{companylist.school.name.match(/\b(\w)/g).join('')}</Avatar>
                      }
                    </ListItemAvatar>
                    <ListItemText className="school_name" primary={companylist.school.name} />
                  </ListItem>
                  <Divider variant="middle" component="li" />
                </List>
              </div>
            ))
            : ''
          }
        </List>
        <DialogActions>
          <Button onClick={() => handleListItemClick(false)} color="primary" style={{ fontSize: "15px" }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      : null;
  }

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    }
  }, [isInitialized, isBusy]);

  function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, '-', /\d/, '-', /\d/, '-', /\d/, '-', /\d/, '-', /\d/]}
        placeholderChar={'_'}
        showMask
      />
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
    setIsBusy(false);
  };


  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

  const handleChange = (e) => {
    setErrorPIN(false);
    setPIN(e.target.value)
  }

  const handleSubmit = () => {
    if (props.username && PIN) {
      setIsBusy(true);
      // let splitData = PIN.split("");
      // let splitValue = splitData[0] + "" + splitData[1] + "" + splitData[2] + "" + splitData[3] + "" + splitData[4] + "" + splitData[5];

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: props.username, otp: String(PIN) })
      };

      fetch(`${process.env.REACT_APP_URL}${props.company}console/custom/auth/authenticate?mode=otp`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          if (result.error) {
            window.localStorage.clear();
            window.alert(result.error.message);
            setIsBusy(false);
          }
          else {
            if (result.data.length === 1) {
              window.localStorage.setItem('school', JSON.stringify(result.data[0].school.company));
              let directus = directusProject(result.data[0].school.domain, result.data[0]);

              await fetch(`${process.env.REACT_APP_URL}${result.data[0].school.domain}/`)
                .then(response => response.json())
                .then(result_data => {
                  setCompanyImage({
                    name: result.data[0].school.domain,
                    image: result_data.data.api.project_logo
                  })
                })

              window.localStorage.setItem("school_list", JSON.stringify(result.data));
              window.localStorage.setItem("school_images", JSON.stringify([companyImageRef.current]));
              window.localStorage.setItem("lang", result.data[0].user.locale === "en-US" ? "en" : "id");

              window.localStorage.setItem('directus_data', JSON.stringify(result.data[0]));
              window.localStorage.setItem('directus_user', JSON.stringify(result.data[0].user));

              //set user setting
              var user = result.data[0];
              var setting = null;
              if (user.account_type === "administrator") {
                setting = {
                  account_type: "administrator",
                  user_id: user.user.id,
                  data_id: null,
                  email: user.user.email,
                  name: user.user.first_name + " " + user.user.last_name,
                  mobile_number: null,
                  lang: user.user.locale === "en-US" ? "en" : "id"
                }
              } else {
                var user_setting = null;
                if (user.account_type === "staff") { user_setting = "staff" }
                else if (user.account_type === "student") { user_setting = "students" }
                else if (user.account_type === "teacher") { user_setting = "teachers" }
                else if (user.account_type === "contact") { user_setting = "contacts" }

                var result2 = await directus.getItems(user_setting, {
                  fields: "*.*",
                  filter: {
                    "user.id": user.user.id
                  }
                });

                var new_data = result2.data[0];
                setting = {
                  account_type: user.account_type,
                  user_id: user.user.id,
                  data_id: new_data.id,
                  email: new_data.email,
                  name: new_data.name,
                  mobile_number: new_data.mobile_number,
                  lang: window.localStorage.lang ? window.localStorage.lang : "id"
                }
              }

              localStorage.setItem('user_setting', JSON.stringify(setting));

              var activity = {
                action: "Login",
                category: 1,
                description: props.username,
                status: "published"
              }
              await directus.createItem('activity_log', activity);

              window.location.href = '/';
              setIsBusy(false);
            }

            else {
              result.data.map(async (data, index) => {
                company[index] = data;
              })

              setCompanyImage([])

              company.map(async (value, index) => {
                var new_result = await fetch(`${process.env.REACT_APP_URL}${value.school.domain}/`)
                  .then(response => response.json())
                  .then(result_data => {
                    return {
                      name: value.school.domain,
                      image: result_data.data.api.project_logo
                    };
                  })
                company_image[index] = new_result;

                var companyImageTemp = [new_result].concat(companyImageRef.current)
                setCompanyImage(companyImageTemp)
              })

              handleClickOpen();
            }
          }
        })
    } else {
      if (!props.username) { props.setErrorUsername(true); }
      if (!PIN) { setErrorPIN(true); }
    }
  }

  return (
    <div>
      <div className="textbox_login">
        <MaskedTextBoxComponent style={{ height: "50px", fontSize: "16px" }} className="textbox_custom" cssClass={`e-outline`} mask='>0 - 0 - 0 - 0 - 0 - 0' onChange={handleChange} value={PIN} name="email"></MaskedTextBoxComponent>
      </div>
      {errorPIN ? <div style={{ color: "red" }}>*PIN is Required</div> : ''}
      <br />
      <ButtonComponent id="signin_pin_button" cssClass='e-info' iconCss='e-icons e2-key' className="btn_login" type="submit" disabled={isBusy} onClick={() => handleSubmit()}>Sign in</ButtonComponent>
      <br></br>
      <Button style={{ fontSize: "15px" }} startIcon={<KeyboardBackspaceIcon />} onClick={() => props.setMode(1)}>Back</Button>
      <ChooseCompanyDialog id="choose_company_dialog" open={open} onClose={handleClose} />
    </div>
  );
};

export default LoginSMSEmail;
