import React, { useState, useEffect, useRef } from 'react';
import useStateRef from "react-usestateref";
import i18next from "i18next";

import { TextBoxComponent, ColorPickerComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

let cancelFlag = false;
const SubjectGroup = () => {
  /* eslint-disable no-unused-vars*/
  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  const [Name] = useState(i18next.t('Pages.data.Name'));
  const [Sort_2] = useState(i18next.t('Pages.data.Sort'));
  // const [Action] = useState(i18next.t('Pages.data.Action'));

  const [Color] = useState(i18next.t('Pages.subject_class_data.Color'));
  const [Enter_subject_group_name] = useState(i18next.t('Pages.subject_class_data.Enter_subject_group_name'));
  const [Subject_Group] = useState(i18next.t('Pages.subject_class_data.Subject_Group'));
  const [Are_you_sure_you_want_to_delete_the_data_of_subject_group] = useState(i18next.t('Pages.subject_class_data.Are_you_sure_you_want_to_delete_the_data_of_subject_group'));
  const [Subject_group_created_successfully] = useState(i18next.t('Pages.subject_class_data.Subject_group_created_successfully'));
  const [Subject_group_deleted_successfully] = useState(i18next.t('Pages.subject_class_data.Subject_group_deleted_successfully'));
  const [Default_Meetings] = useState(i18next.t('Pages.subject_class_data.Default_Meetings'));

  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);


  const editSettings = {
    allowEditing: true, allowAdding: true, allowDeleting: true,
    mode: 'Normal'
  };

  let dialogAddSubjectGroup = useRef(false);
  let dialogDeleteSubjectGroup = useRef(false);

  const grid = useRef();
  const setting = { mode: 'Both' };

  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    name: null
  });

  const [state, setState] = useState({
    name: null,
    default_meetings: 24,
    status: "published"
  });

  const [error, setError] = useState({
    name: false,
    default_meetings: false
  });
  /* eslint-enable no-unused-vars*/

  const getInitialValue = () => {
    setState({
      name: null,
      default_meetings: 24,
      status: "published"
    });
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "add_subject_group") {
        dialogAddSubjectGroup.current.visible = false;
        setState({ name: null, status: "published" });
        EventEmitter.emit('alert_toast', { content: Subject_group_created_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_subject_group") {
        dialogDeleteSubjectGroup.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Subject_group_deleted_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }


  const getData = async () => {
    try {
      var name = { "nnull": null };

      if (stateFiltering.name !== null && stateFiltering.name !== "") {
        name = { "contains": stateFilteringRef.current.name };
      }

      var result = await directus.getItems('subject_group',
        {
          filter: {
            name: name,
            status: "published"
          },
          sort: "sort,name"
        });

      setData(result.data);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Data Grid Template ========================================== */ }

  const buttonTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_hover" style={{ display: "flex" }}>
          <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px", }} >
            <span className="e-icons icon-delete-05"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => handleOpenDialogDeleteSubjectGroup(props)}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const buttonEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter" >
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const actionGrid = async (event) => {
    if (event.action === "edit" && event.requestType === "save") {
      if (cancelFlag === false) {
        try {
          const data = event.data;
          const body = {
            name: data.name,
            sort: data.sort,
            default_meetings: data.default_meetings
          }

          var result = await directus.updateItem('subject_group', data.id, body);

          var description = data.name;
          if (data.name !== event.previousData.name) {
            description = event.previousData.name + " --> " + data.name;
          }

          const body_activity = {
            action: "Update",
            category: 12,
            description: description,
            status: "published"
          }
          await directus.createItem('activity_log', body_activity);

          if (!result.error) {
            getData();
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          }

        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        }
      } else {
        grid.current.editModule.closeEdit()
      }
      cancelFlag = false;

    } else if (event.requestType === "beginEdit") {
      cancelFlag = true;
    }
  }

  // {/* ================== Filtering ========================================== */ }

  const handleChangeFilter = (event, type) => {
    var value = "";
    if (type === "name") {
      value = event.value
    }

    setStateFiltering({ ...stateFiltering, [type]: value })

    if (event.syntheticEvent.type === 'change') {
      getData();
    }

  }

  const searchSubjectGroup = () => {
    getData();
  }

  // {/* ================== Handle Add Subject Group ========================================== */ }

  const addSubjectGroup = async () => {
    try {
      setError({ name: false });

      if (state.name && state.default_meetings) {
        setLoadingButton(true);
        var result = await directus.createItem('subject_group', state);

        const body_activity = {
          action: "Add",
          category: 12,
          description: state.name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          getData();
          setDataButton("add_subject_group");
        }

      } else {
        if (!state.name) { setError({ ...error, name: true }) }
        if (!state.default_meetings) { setError({ ...error, default_meetings: true }) }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  const handleCloseDialogAddSubjectGroup = () => {
    setError({ name: false });
    dialogAddSubjectGroup.current.visible = false;
    getInitialValue();
  }

  const handleOpenDialogAddSubjectGroup = () => {
    dialogAddSubjectGroup.current.visible = true;
  }

  const handleChange = (event, type) => {
    var value = event.value;
    setState({ ...state, [type]: value })
  }

  // {/* ================== Handle Dialog Remove Activity Type ========================================== */ }

  const handleOpenDialogDeleteSubjectGroup = (props) => {
    dialogDeleteSubjectGroup.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteSubjectGroup = () => {
    dialogDeleteSubjectGroup.current.visible = false;
  }

  const deleteSubjectGroup = async () => {
    try {
      //set teacher status from published to draft
      const body = {
        status: 'draft'
      }
      setLoadingButton(true);
      var result = await directus.updateItem('subject_group', selectedData.id, body);

      const body_activity = {
        action: "Delete",
        category: 12,
        description: selectedData.name,
        status: "published"
      }
      await directus.createItem('activity_log', body_activity);

      if (!result.error) {
        getData();
        setDataButton("delete_subject_group")
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  const colorTemplate = props => {
    return (
      <div style={{ backgroundColor: props.color, textAlign: "center", width: "100%" }} >{props.color}</div>
    )
  };

  const editColorTemplate = (props) => {
    return (
      <div>
        <ColorPickerComponent
          name="color"
          value={props.color}
          noColor={true}
          change={(e) => handleColor(e, props)}
        ></ColorPickerComponent>
      </div>
    )
  }

  const handleColor = async (e, props) => {
    try {
      await directus.updateItem('subject_group', props.id, { color: e.value });

    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const footerTemplateAddSubjectGroup = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-success e-flat" data-ripple="true" onClick={(e) => addSubjectGroup(e)}>
            <span className="e-btn-icon e-icons icon-check-mark-01 e-icon-left"></span>{Update}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogAddSubjectGroup()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  const footerTemplateDeleteSubjectGroup = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-warning e-flat" data-ripple="true" onClick={(e) => deleteSubjectGroup(e)}>
            <span className="e-btn-icon e-icons icon-delete-05 e-icon-left"></span>{Delete}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogDeleteSubjectGroup()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }
  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      cancelFlag = false;
      grid.current.endEdit();
    }
  }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Subject_Group}</div>
            <div className="content-searching hide-content">
              <div style={{ display: 'flex', width: "100%" }}>
                <div style={{ width: "300px", marginRight: "10px" }}>
                  <TextBoxComponent
                    cssClass="e-outline searching-text"
                    className="searching-input"
                    id="searching-input"
                    placeholder={Enter_subject_group_name}
                    onChange={(e) => handleChangeFilter(e, "name")}
                    onBlur={(e) => searchSubjectGroup(e)}
                    // close={()=>alert("clear")}
                    showClearButton="true"
                    value={stateFiltering.name}
                  />
                </div>
                <div>
                  <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddSubjectGroup}>
                    <span className="btn-text-custom">{Subject_Group}</span>
                  </ButtonComponent>
                </div>
                {/* <br />
              {data ? data.length > 0 ? 'Total : ' + data.length : <br /> : ''} */}
              </div>
            </div>
          </div>

          <div className="content-main">
            {data ? data.length > 0 ?
              <div style={{ marginTop: "25px", marginBottom: "20px" }}>
                <div className="grid-container">
                  <GridComponent
                    className="grid-list"
                    dataSource={data}
                    editSettings={editSettings}
                    ref={grid}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: "Content" }}
                    allowResizing={true}
                    gridLines='Vertical'
                    actionBegin={actionGrid}
                    selectionSettings={setting}
                    allowReordering={true}
                    clipMode="Ellipsis"
                    keyPressed={onKeyPressed}
                  >
                    <ColumnsDirective>
                      <ColumnDirective customAttributes={{ id: 'first' }} width="28%" field='name' headerText={Name}></ColumnDirective>
                      <ColumnDirective width="10%" field='sort' headerText={Sort_2}></ColumnDirective>
                      <ColumnDirective width="20%" editType='numericedit' edit={{ params: { format: "##" } }} field='default_meetings' headerText={Default_Meetings}></ColumnDirective>
                      <ColumnDirective width="34%" headerText={Color} template={colorTemplate} editTemplate={editColorTemplate}></ColumnDirective>
                      <ColumnDirective customAttributes={{ id: 'last' }} width="8%" template={buttonTemplate} editTemplate={buttonEditTemplate}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                  </GridComponent>
                </div>
              </div>
              : <div style={{ textAlign: "center", paddingTop: "10px" }}>{No_data_found}</div>
              : <div style={{ textAlign: "center", paddingTop: "10px" }}>{No_data_found}</div>
            }

            <DialogComponent
              id="dialogAddSubjectGroup"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogAddSubjectGroup}
              header={Subject_Group}
              isModal={true}
              close={handleCloseDialogAddSubjectGroup}
              footerTemplate={footerTemplateAddSubjectGroup}
            >
              <div>
                <div>
                  <b>*{Name}</b>
                  <TextBoxComponent
                    name="name"
                    cssClass='e-outline'
                    change={(e) => handleChange(e, "name")}
                    value={state.name}
                  />
                  {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <b>{Sort_2}</b>
                  <NumericTextBoxComponent
                    cssClass="e-outline"
                    name="sort"
                    format='#########'
                    change={(e) => handleChange(e, "sort")}
                    value={state.sort}
                  />
                  <br /><br />
                  <b>*{Default_Meetings}</b>
                  <NumericTextBoxComponent
                    name="default_meetings"
                    cssClass='e-outline'
                    format='##'
                    min={0}
                    change={(e) => handleChange(e, "default_meetings")}
                    value={state.default_meetings}
                  />
                  {error.default_meetings === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                </div>
              </div>
            </DialogComponent>


            <DialogComponent
              id="dialogDeleteSubjectGroup"
              width="35%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogDeleteSubjectGroup}
              header={Delete_Confirmation}
              isModal={true}
              close={handleCloseDialogDeleteSubjectGroup}
              footerTemplate={footerTemplateDeleteSubjectGroup}
            >
              <div>
                {selectedData ?
                  <div style={{ fontSize: "14px" }}>
                    {Are_you_sure_you_want_to_delete_the_data_of_subject_group} ({selectedData.name})?
                    <br /><br />

                  </div>
                  : ''}
              </div>
            </DialogComponent>
          </div>
        </div>
      }
    </div>
  );
}

export default SubjectGroup;