import React, { useState, useEffect, useRef } from 'react';
import useStateRef from "react-usestateref";

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';
import i18next from "i18next";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

let cancelFlag = false;
const PublicHoliday = () => {
  /* eslint-disable no-unused-vars*/
  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  const [Sunday] = useState(i18next.t('Pages.data.Sunday'));
  const [Monday] = useState(i18next.t('Pages.data.Monday'));
  const [Tuesday] = useState(i18next.t('Pages.data.Tuesday'));
  const [Wednesday] = useState(i18next.t('Pages.data.Wednesday'));
  const [Thursday] = useState(i18next.t('Pages.data.Thursday'));
  const [Friday] = useState(i18next.t('Pages.data.Friday'));
  const [Saturday] = useState(i18next.t('Pages.data.Saturday'));

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [Public_Holiday] = useState(i18next.t('Pages.public_holiday_data.Public_Holiday'));
  const [Date_2] = useState(i18next.t('Pages.public_holiday_data.Date'));
  const [Day] = useState(i18next.t('Pages.public_holiday_data.Day'));
  const [Description] = useState(i18next.t('Pages.public_holiday_data.Description'));
  // const [Action] = useState(i18next.t('Pages.public_holiday_data.Action'));
  const [Enter_description] = useState(i18next.t('Pages.public_holiday_data.Enter_description'));
  const [Are_you_sure_you_want_to_delete_the_data_of_public_holiday] = useState(i18next.t('Pages.public_holiday_data.Are_you_sure_you_want_to_delete_the_data_of_public_holiday'));
  const [Public_holiday_created_successfully] = useState(i18next.t('Pages.public_holiday_data.Public_holiday_created_successfully'));
  const [Public_holiday_deleted_successfully] = useState(i18next.t('Pages.public_holiday_data.Public_holiday_deleted_successfully'));

  //data
  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);


  //dialog
  let dialogAddData = useRef(false);
  let dialogDeleteData = useRef(false);

  //grid setting
  const grid = useRef();
  const setting = { mode: 'Both' };
  const editSettings = {
    allowEditing: true, allowAdding: true, allowDeleting: true,
    mode: 'Normal'
  };

  //other component setting
  const weeks = [{ id: "Sunday", name: Sunday }, { id: "Monday", name: Monday }, { id: "Tuesday", name: Tuesday }, { id: "Wednesday", name: Wednesday }, { id: "Thursday", name: Thursday }, { id: "Friday", name: Friday }, { id: "Saturday", name: Saturday }];


  //set state
  //set state add student
  const [state, setState, stateRef] = useStateRef({
    date: null,
    description: null
  });

  //set state filtering
  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    description: null,
    date: moment().format(),
    start_date: moment().clone().startOf('year').format(),
    end_date: moment().clone().endOf('year').format(),
  });

  //set error in add student dialog
  const [error, setError, errorRef] = useStateRef({
    date: false,
    description: false
  });
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })


  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "add_data") {
        dialogAddData.current.visible = false;
        setState({ name: null, date: null });
        EventEmitter.emit('alert_toast', { content: Public_holiday_created_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_data") {
        dialogDeleteData.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Public_holiday_deleted_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get list of activity logs
  const getData = async () => {
    try {

      var description = { "nnull": null };
      if (stateFilteringRef.current.description !== null && stateFilteringRef.current.description !== "") {
        description = { "contains": stateFilteringRef.current.description };
      }
      var result = await directus.getItems('public_holiday',
        {
          fields: "*.*",
          filter: {
            date: {
              'between': [moment(stateFilteringRef.current.start_date).format("YYYY-MM-DD"), moment(stateFilteringRef.current.end_date).format("YYYY-MM-DD")]
            },
            description: description
          },
          sort: "date",
        });

      setData(result.data);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }
  // {/* ================== Filtering ========================================== */ }

  const handleFilter = async (e, type) => {
    var value = e.value;

    if (type === "date") {
      setStateFiltering({
        ...stateFilteringRef.current,
        start_date: moment(value).clone().startOf('year').format(),
        end_date: moment(value).clone().endOf('year').format()
      });
    } else {
      setStateFiltering({ ...stateFilteringRef.current, [type]: value })
    }

    if (type === "description") {
      if (e.syntheticEvent.type === 'change') {
        getData();
      }
    } else {
      getData();
    }
  }

  const searchDescription = () => { getData(); }

  // {/* ================== Handle Public Holiday ========================================== */ }

  const handleOpenDialogAddData = () => {
    setState({ date: null, description: null });
    setError({ date: false, description: false });
    dialogAddData.current.visible = true;

  }

  const handleCloseDialogAddData = () => {

    setError({ date: false, description: false });
    dialogAddData.current.visible = false;
  }

  const handleChangeAddData = (event, type) => {
    var value = null;
    if (type === "date" || type === "description") {
      value = event.value;
    }
    setState({ ...stateRef.current, [type]: value })
  }

  const addData = async () => {
    try {
      setError({ date: false, description: false })

      if (stateRef.current.date && stateRef.current.description) {
        setLoadingButton(true);

        const body = {
          date: stateRef.current.date ? moment(stateRef.current.date).format("YYYY-MM-DD") : null,
          description: stateRef.current.description,
          status: "published"
        }

        var result = await directus.createItem('public_holiday', body);

        if (!result.error) {
          getData();
          setDataButton("add_data")
        }

      } else {
        if (!stateRef.current.date) { setError({ ...errorRef.current, date: true }) }
        if (!stateRef.current.description) { setError({ ...errorRef.current, description: true }) }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Handle Dialog Delete Data ========================================== */ }

  const handleOpenDialogDeleteData = (props) => {
    dialogDeleteData.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteData = () => {
    dialogDeleteData.current.visible = false;
  }

  const deleteData = async () => {
    //set teacher status from published to draft
    try {
      setLoadingButton(true);
      var result = await directus.deleteItem('public_holiday', selectedData.id);

      if (!result.error) {
        getData();
        setDataButton("delete_data")
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }

  }

  // {/* ================== Data Grid Template ========================================== */ }

  const dayTemplate = props => {
    return (<div>{getWeek(moment(props.date).format("dddd"))}</div>)
  }

  const getWeek = (props) => {
    var week2 = props ? props : null;
    if (weeks) {
      weeks.forEach((value) => {
        if (value.id === week2) {
          week2 = value.name
        }
      })
    }
    return "" + week2
  }

  const buttonTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_hover" style={{ display: "flex" }}>
          <TooltipComponent content={Delete} position="BottomCenter">
            <span className="e-icons icon-delete-05"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => handleOpenDialogDeleteData(props)}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const buttonEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter" >
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  // {/* ================== Data Grid Action (edit) ========================================== */ }

  const actionGrid = async (event) => {
    if (event.action === "edit" && event.requestType === "save") {
      if (cancelFlag === false) {
        const data = event.data;
        try {
          const body = {
            date: data.date ? moment(data.date).format("YYYY-MM-DD") : null,
            description: data.description
          }
          var result = await directus.updateItem('public_holiday', data.id, body);

          if (!result.error) {
            getData();
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          setLoadingButton(false);
        }
      } else {
        grid.current.editModule.closeEdit()
      }
      cancelFlag = false;

    } else if (event.requestType === "beginEdit") {
      cancelFlag = true;
    }
  }

  // {/* ================== Footer Template ========================================== */ }

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */}
  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      cancelFlag = false;
      grid.current.endEdit();
    }
  }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Public_Holiday}</div>
            <div className="content-searching hide-content">
              <div style={{ display: 'flex', width: "100%" }}>
                <div className="ddl_custom_header" style={{ marginRight: "10px", width: "120px", marginTop: "1.99px" }}>
                  <DatePickerComponent
                    id="dp_custom"
                    showClearButton={false}
                    cssClass="e-outline"
                    name="date"
                    format='yyyy'
                    start="Decade"
                    depth="Decade"
                    openOnFocus={true}
                    value={stateFilteringRef.current.date}
                    min={stateFilteringRef.current.min_date}
                    max={stateFilteringRef.current.max_date}
                    onChange={(e) => handleFilter(e, "date")}
                  />
                </div>
                <div style={{ width: "250px", marginRight: "10px" }}>
                  <TextBoxComponent
                    name="description"
                    cssClass="e-outline searching-text"
                    className="searching-input"
                    id="searching-input"
                    placeholder={Enter_description}
                    onChange={(e) => handleFilter(e, "description")}
                    onBlur={(e) => searchDescription(e)}
                    showClearButton="true"
                  />
                </div>
                <div>
                  <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddData}>
                    <span className="btn-text-custom">{Public_Holiday}</span>
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </div>
          <div className="content-main" style={{ marginTop: "20px" }}>
            {data ? data.length > 0 ? 'Total : ' + data.length : <br /> : ''}

            {data ? data.length > 0 ?
              <div style={{ marginBottom: "20px" }}>
                <div className="grid-container">
                  <GridComponent
                    width="100%"
                    className="grid-list"
                    dataSource={data}
                    editSettings={editSettings}
                    allowEditing={true}
                    ref={grid}
                    actionBegin={actionGrid}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: "Content" }}
                    allowResizing={true}
                    gridLines='Both'
                    selectionSettings={setting}
                    clipMode="EllipsisWithTooltip"
                    keyPressed={onKeyPressed}
                  >
                    <ColumnsDirective>
                      <ColumnDirective customAttributes={{ id: 'first' }} width="15%" field='date' headerText={Date_2} type="date" format="dd-MMM-yyyy" validationRules={{ required: true }} editType='datepickeredit' edit={{ params: { format: 'dd-MMM-yyyy' } }}></ColumnDirective>
                      <ColumnDirective width="15%" allowEditing={false} headerText={Day} template={dayTemplate} editTemplate={dayTemplate}></ColumnDirective>
                      <ColumnDirective width="62%" field='description' headerText={Description}></ColumnDirective>
                      <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="8%" template={buttonTemplate} editTemplate={buttonEditTemplate}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                  </GridComponent>
                </div>
              </div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
            }
          </div>

          {/* add public holiday  */}
          <DialogComponent
            id="dialogAddData"
            width="30%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogAddData}
            header={Public_Holiday}
            isModal={true}
            footerTemplate={() => footerTemplate([
              { name: Update, func: addData, icon: "icon-check-mark-01", status: "e-success" },
              { name: Cancel, func: handleCloseDialogAddData, icon: "icon-treeview", status: "" }
            ])}
            close={handleCloseDialogAddData}
          >
            <div>
              <b>{Date_2}</b>
              <DatePickerComponent
                name="date"
                cssClass="e-outline"
                format="dd-MMM-yyyy"
                change={(e) => handleChangeAddData(e, "date")}
                value={stateRef.current.date ? stateRef.current.date : null}
              />
              {errorRef.current.date === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
              <br /><br />
              <b>*{Description}</b>
              <TextBoxComponent
                name="description"
                cssClass="e-outline"
                change={(e) => handleChangeAddData(e, "description")}
                value={stateRef.current.description ? stateRef.current.description : null}
              />
              {errorRef.current.description === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
            </div>
          </DialogComponent>

          {/* delete public holiday  */}
          <DialogComponent
            id="dialogDeleteData"
            width="35%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogDeleteData}
            header={Delete_Confirmation}
            isModal={true}
            close={handleCloseDialogDeleteData}
            footerTemplate={() => footerTemplate([
              { name: Delete, func: deleteData, icon: "icon-delete-05", status: "e-warning" },
              { name: Cancel, func: handleCloseDialogDeleteData, icon: "icon-treeview", status: "" }
            ])}
          >
            <div>
              {selectedData ?
                <div style={{ fontSize: "14px" }}>
                  {Are_you_sure_you_want_to_delete_the_data_of_public_holiday} ({selectedData.description})?
                </div>
                : ''}
            </div>
          </DialogComponent>
        </div>
      }
    </div>
  );
}

export default PublicHoliday;