import React, { useState, useEffect, useRef } from 'react';
import useStateRef from "react-usestateref";
import { useParams } from 'react-router-dom';
import i18next from "i18next";

import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  ColumnMenu,
  Edit,
  Toolbar,
  Page,
  Inject,
  Sort,
  Filter,
  Reorder,
  Group,
}
  from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import linq from "linq";

let cancelFlag = false;
const Topic = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  // const [Action] = useState(i18next.t('Pages.data.Action'));
  const [Sort_2] = useState(i18next.t('Pages.data.Sort'));

  const [Subject] = useState(i18next.t('Pages.subject_class_data.Subject'));
  const [Are_you_sure_you_want_to_delete_the_data_of_topic] = useState(i18next.t('Pages.subject_class_data.Are_you_sure_you_want_to_delete_the_data_of_topic'));
  const [Topic_2] = useState(i18next.t('Pages.subject_class_data.Topic'));
  const [Add_Topic] = useState(i18next.t('Pages.subject_class_data.Add_Topic'));
  const [Topic_created_successfully] = useState(i18next.t('Pages.subject_class_data.Topic_created_successfully'));
  const [Topic_deleted_successfully] = useState(i18next.t('Pages.subject_class_data.Topic_deleted_successfully'));

  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  const editSettings = {
    allowEditing: true, allowAdding: true, allowDeleting: true,
    // mode: 'Batch' 
    mode: 'Normal'
  };

  let dialogAddTopic = useRef(false);
  let dialogDeleteTopic = useRef(false);

  const grid = useRef();
  const setting = { mode: 'Both' };

  const [state, setState, stateRef] = useStateRef({
    sort: null,
    topic_description: null,
    test: 0,
    status: "published"
  });


  const [error, setError, errorRef] = useStateRef({
    topic_description: false
  });
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }
  })

  React.useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "add_topic") {
        dialogAddTopic.current.visible = false;
        setState({ sort: null, topic_description: null, test: 0, review: 0 });
        EventEmitter.emit('alert_toast', { content: Topic_created_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_topic") {
        dialogDeleteTopic.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Topic_deleted_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }


  const getData = async () => {
    try {

      var result = await directus.getItems('subjects_subject_schedule',
        {
          fields: '*.*.*',
          filter: {
            subjects_id: id
          }
        }
      );

      var result_2 = linq.from(result.data)
        .orderBy(o => o.subject_schedule_id.sort)
        .toArray();

      setState({ ...stateRef.current, sort: (result_2.length + 1) });

      setData(result_2);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Data Grid Template ========================================== */ }

  const buttonTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center", justifyContent: "center" }}>
        <div className="action_hover" style={{ display: "flex" }}>
          <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px", }} >
            <span className="e-icons icon-delete-05"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => handleOpenDialogDeleteTopic(props)}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const buttonEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter" >
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const actionGrid = async (event) => {
    try {
      if (event.action === "edit" && event.requestType === "save") {
        if (cancelFlag === false) {
          const data = event.data;
          const body = {
            id: data.subject_schedule_id.id,
            sort: data.subject_schedule_id.sort,
            topic_description: data.subject_schedule_id.topic_description
          }
          var result = await directus.updateItem('subject_schedule', body.id, body);
          if (!result.error) {
            getData();
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          }
        } else {
          grid.current.editModule.closeEdit()
        }
        cancelFlag = false;

      } else if (event.requestType === "beginEdit") {
        cancelFlag = true;
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Handle Add Subject ========================================== */ }

  const addTopic = async () => {
    setError({ topic_description: false });

    if (state.topic_description) {
      state.status = "published";
      try {
        setLoadingButton(true);
        var result = await directus.createItem('subject_schedule', state);
        if (!result.error) {
          var body = {
            subject_schedule_id: result.data.id,
            subjects_id: id
          }

          var result2 = await directus.createItem('subjects_subject_schedule', body);
          if (!result2.error) {
            getData();
            setDataButton("add_topic");
          }
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        setLoadingButton(false);
      }

    } else {
      if (!state.topic_description) { setError({ ...errorRef.current, topic_description: true }) }
    }
  }

  const handleCloseDialogAddTopic = () => {
    setError({ topic_description: false });
    setState({ sort: null, topic_description: null, test: 0 });
    dialogAddTopic.current.visible = false;
  }

  const handleOpenDialogAddTopic = () => {
    dialogAddTopic.current.visible = true;
  }

  const handleChange = (event, type) => {
    var value = null;
    if (type === "test" || type === "review") {
      value = event.checked;
    } else {
      value = event.value;
    }

    setState({ ...stateRef.current, [type]: value });
  }

  // {/* ================== Handle Dialog Remove Activity Type ========================================== */ }

  const handleOpenDialogDeleteTopic = (props) => {
    dialogDeleteTopic.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteTopic = () => {
    dialogDeleteTopic.current.visible = false;
  }

  const deleteTopic = async (event) => {
    event.preventDefault();
    try {
      setLoadingButton(true);
      var result = await directus.deleteItem('subjects_subject_schedule', selectedData.id);
      if (!result.error) {
        var result2 = await directus.updateItem('subject_schedule', selectedData.subject_schedule_id.id, { status: "draft" });
        if (!result2.error) {
          getData();
          setDataButton("delete_topic");
        }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }


  const testTemplate = props => {
    return (
      <span style={{ color: parseInt(props.subject_schedule_id.test) === 1 ? "black" : "lightgray", cursor: "pointer" }}
        onClick={() => checkTest(props)}
        className="e-icons icon-check-mark-01">
      </span>
    )
  };

  const checkTest = async (props) => {
    var status = props.subject_schedule_id.test;
    if (parseInt(status) === 1) { status = 0; } else { status = 1; }

    try {
      var result = await directus.updateItem('subject_schedule', props.subject_schedule_id.id, { test: status });
      if (!result.error) {
        getData();
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }


  const reviewTemplate = props => {
    return (
      <div>
        <span
          style={{ color: parseInt(props.subject_schedule_id.review) === 1 ? "black" : "lightgray", cursor: "pointer" }}
          onClick={() => checkReview(props)}
          className="e-icons icon-check-mark-01">
        </span>
      </div>
    )
  };

  const checkReview = async (props) => {
    var status = props.subject_schedule_id.review;
    if (parseInt(status) === 1) { status = 0; } else { status = 1; }

    try {
      var result = await directus.updateItem('subject_schedule', props.subject_schedule_id.id, { review: status });
      if (!result.error) {
        getData();
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const footerTemplateAddTopic = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-success e-flat" data-ripple="true" onClick={(e) => addTopic(e)}>
            <span className="e-btn-icon e-icons icon-check-mark-01 e-icon-left"></span>{Update}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogAddTopic()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  const footerTemplateDeleteTopic = () => {
    return (
      loadingButtonRef.current === false ?
        <div>
          <button type="button" className="e-control e-btn e-lib e-warning e-flat" data-ripple="true" onClick={(e) => deleteTopic(e)}>
            <span className="e-btn-icon e-icons icon-delete-05 e-icon-left"></span>{Delete}
          </button>
          <button type="button" className="e-control e-btn e-lib e-flat" data-ripple="true" onClick={() => handleCloseDialogDeleteTopic()}>
            <span className="e-btn-icon e-icons icon-treeview e-icon-left"></span>{Cancel}
          </button>
        </div>
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }

  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      cancelFlag = false;
      grid.current.endEdit();
    }
  }

  return (
    <div id="topic" style={{ alignContent: "center", minHeight: "300px", paddingTop: "10px", paddingBottom: "10px" }}>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "5%", marginLeft: "50%" }} />
        :
        <div>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <div>
              <ButtonComponent style={{ float: "right" }} className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddTopic}>
                <span className="btn-text-custom">{Subject}</span>
              </ButtonComponent>
              {/* <ButtonComponent style={{ float: "right" }} iconCss='e-icons icon-plus' onClick={handleOpenDialogAddTopic}>{Subject}</ButtonComponent> */}

              <br />
              {data ? data.length > 0 ? 'Total : ' + data.length : <br />
                : ''}
            </div>

            {data ? data.length > 0 ?
              <div>
                <GridComponent
                  dataSource={data}
                  editSettings={editSettings}
                  ref={grid}
                  allowTextWrap={true}
                  gridLines='Vertical'
                  actionBegin={actionGrid}
                  selectionSettings={setting}
                  keyPressed={onKeyPressed}
                >
                  <ColumnsDirective>
                    <ColumnDirective customAttributes={{ id: 'first' }} width="12%" textAlign="Center" field='subject_schedule_id.sort' allowFiltering={false} allowSorting={true} showColumnMenu={false} headerText={Sort_2}></ColumnDirective>
                    <ColumnDirective width="54%" field='subject_schedule_id.topic_description' headerText={Topic_2}></ColumnDirective>
                    <ColumnDirective width="12%" field='subject_schedule_id.review' textAlign='Center' headerText="Review" allowFiltering={false} template={reviewTemplate} editTemplate={reviewTemplate}></ColumnDirective>
                    <ColumnDirective width="12%" field='subject_schedule_id.test' textAlign='Center' headerText="Test" allowFiltering={false} template={testTemplate} editTemplate={testTemplate}></ColumnDirective>
                    <ColumnDirective customAttributes={{ id: 'last' }} width="10%" template={buttonTemplate} editTemplate={buttonEditTemplate}></ColumnDirective>
                  </ColumnsDirective>
                  <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Group]} />
                </GridComponent>
              </div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>}



            <DialogComponent
              id="dialogAddTopic"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogAddTopic}
              header={Add_Topic}
              isModal={true}
              close={handleCloseDialogAddTopic}
              footerTemplate={footerTemplateAddTopic}
            >
              <div>
                <div>
                  <b>{Sort_2}</b>
                  <NumericTextBoxComponent
                    cssClass="e-outline"
                    name="sort"
                    format='#########'
                    change={(e) => handleChange(e, "sort")}
                    value={state.sort}
                  />
                  <br /><br />
                  <b>*{Topic_2}</b>
                  <TextBoxComponent
                    name="topic_description"
                    cssClass='e-outline'
                    change={(e) => handleChange(e, "topic_description")}
                    value={state.topic_description}
                  />
                  {error.topic_description === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <CheckBoxComponent
                    checked={parseInt(state.review) === 1 ? true : false}
                    value={state.review}
                    label="Review"
                    change={(e) => handleChange(e, "review")}
                  />
                  <span style={{ paddingLeft: "15px" }}>
                    <CheckBoxComponent
                      checked={parseInt(state.test) === 1 ? true : false}
                      value={state.test}
                      label="Test"
                      change={(e) => handleChange(e, "test")}
                    />
                  </span>
                </div>
              </div>
            </DialogComponent>


            <DialogComponent
              id="dialogDeleteTopic"
              width="35%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogDeleteTopic}
              header={Delete_Confirmation}
              isModal={true}
              close={handleCloseDialogDeleteTopic}
              footerTemplate={footerTemplateDeleteTopic}
            >
              <div>
                {selectedData ?
                  <div style={{ fontSize: "14px" }}>
                    {Are_you_sure_you_want_to_delete_the_data_of_topic} "{selectedData.subject_schedule_id ? selectedData.subject_schedule_id.topic_description : null}"?
                    <br /><br />

                  </div>
                  : ''}
              </div>
            </DialogComponent>
          </div>
        </div>
      }
    </div>
  );
}

export default Topic;