//No Company

import DirectusSDK from '@directus/sdk-js';

let directus;

var school = JSON.parse(window.localStorage.getItem('school'));
var data = JSON.parse(window.localStorage.getItem('directus_data'));
const url =  process.env.REACT_APP_URL;

var list
if(data)
{
  list = {
    url: url,
    project: school,
    persist: true,
    token: data.token
  }
}
else
{
  list = {
    url: url,
    project: school,
    persist: true,
    storage: window.localStorage,
  }
}

directus = new DirectusSDK(list);

export function directusProject(school, data) {

  window.localStorage.setItem('school', JSON.stringify(school));

  directus = new DirectusSDK({
    url: url,
    project: school,
    persist: true,
    token: data.token
  });

  return directus;
}

export function changeProject(data) {
  window.localStorage.setItem('school', JSON.stringify(data));

  directus = new DirectusSDK({
    url: url,
    project: data,
    persist: true,
    storage: window.localStorage
  });

  return directus;
}

export default directus;



//==========================================================

//Ada Company
// import DirectusSDK from '@directus/sdk-js';

// let directus;

// var company = JSON.parse(window.localStorage.getItem('company'));
// var data = JSON.parse(window.localStorage.getItem('directus_data'));
// const url =  process.env.REACT_APP_URL;

// var list
// if(data)
// {
//   list = {
//     url: url,
//     project: company,
//     persist: true,
//     token: data.token
//   }
// }
// else
// {
//   list = {
//     url: url,
//     project: company,
//     persist: true,
//     storage: window.localStorage,
//   }
// }

// directus = new DirectusSDK(list);

// export function directusProject(company, data) {
//   window.localStorage.setItem('company', JSON.stringify(company));

//   directus = new DirectusSDK({
//     url: url,
//     project: company,
//     persist: true,
//     token: data.token
//   });

//   return directus;
// }

// export function changeProject(data) {
//   window.localStorage.setItem('company', JSON.stringify(data));

//   directus = new DirectusSDK({
//     url: url,
//     project: data,
//     persist: true,
//     storage: window.localStorage
//   });

//   return directus;
// }

// export default directus;