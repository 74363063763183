import React from 'react';

const LogoTransparent = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/images/Akademix-Logo-Dark-Text-with-Transparent-Background-1.png"
      {...props}
    />
  );
};

export default LogoTransparent;
