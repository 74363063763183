import React, { useState, useEffect, useRef } from 'react';
import useStateRef from "react-usestateref";
import { useParams } from 'react-router-dom';

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import linq from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

import './index.css';

let cancelFlag = false;
const Notes = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));

  // const [Action] = useState(i18next.t('Pages.data.Action'));
  const [Created_on] = useState(i18next.t('Pages.data.Created_on'));
  const [Created_by] = useState(i18next.t('Pages.data.Created_by'));
  const [Student_Note] = useState(i18next.t('Pages.student_data.Student_Note'));
  const [Note] = useState(i18next.t('Pages.student_data.Note'));
  const [Note_created_successfully] = useState(i18next.t('Pages.student_data.Note_created_successfully'));
  const [Note_deleted_successfully] = useState(i18next.t('Pages.student_data.Note_deleted_successfully'));
  const [Are_you_sure_you_want_to_delete_the_data_of_student_note] = useState(i18next.t('Pages.student_data.Are_you_sure_you_want_to_delete_the_data_of_student_note'));

  //data
  const [data, setData] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogDeleteNote = useRef(false);
  let dialogAddNote = useRef(false);

  //grid setting
  const grid = useRef();
  const filterOptions = { type: 'Menu' };
  const setting = { mode: 'Both' };
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' };

  //set the selected note for deletion
  const [selectedData, setSelectedData] = useState(null);

  //set state
  const [state, setState] = useState({ note: null });

  //set error
  const [error, setError] = useState({ note: false });
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoadingNotes(true);
      getNotes();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "delete_note") {
        dialogDeleteNote.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Note_deleted_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "add_note") {
        dialogAddNote.current.visible = false;
        setState({ note: null });
        EventEmitter.emit('alert_toast', { content: Note_created_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get student notes
  const getNotes = async () => {
    try {
      var result = await directus.getItems('students',
        {
          fields: 'id, student_notes.student_notes_id.owner.*,  student_notes.student_notes_id.*',
          filter: {
            id: id
          }
        }
      );

      console.log("result", result)

      var result_data = linq.from(result.data[0].student_notes).toArray();

      if (result_data.length > 0) {
        result_data = linq.from(result_data)
          .orderByDescending(item => item.student_notes_id.created_on)
          .toArray();
      }

      setData(result_data);
      setLoadingNotes(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Handle Add Note ========================================== */ }
  const handleOpenDialogAddNote = () => {
    dialogAddNote.current.visible = true;
    setError({ ...error, note: false });
  }

  const handleCloseDialogAddNote = () => {
    dialogAddNote.current.visible = false;
    setError({ ...error, note: false });
  }

  const handleChangeNote = (event, type) => {
    if (type === "note") {
      if (event.value) {
        var value = event.value;
        setState({ ...state, [type]: value });
        setError({ ...error, note: false });
      } else {
        setError({ ...error, note: true });
      }
    }
  }

  //add new contact to database
  const addNote = async () => {
    if (!error.note) {
      if (state.note) {
        setError({ ...error, note: false });
        try {
          setLoadingButton(true);
          var result = await directus.createItem('student_notes', state);

          if (!result.error) {
            var body = {
              students_id: id,
              student_notes_id: result.data.id,
            }
            var result2 = await directus.createItem('students_student_notes', body);

            if (!result2.error) {
              getNotes();
              setDataButton("add_note");
            }
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        }
      } else {
        setError({ ...error, note: true })
      }
    }
  }

  // {/* ================== Handle Dialog Delete Note ========================================== */ }

  const handleOpenDialogDeleteNote = (props) => {
    dialogDeleteNote.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteNote = () => {
    dialogDeleteNote.current.visible = false;
  }

  const deleteNote = async () => {
    try {
      setLoadingButton(true);
      await directus.deleteItem('students_student_notes', selectedData.id);

      var result2 = await directus.updateItem('student_notes', selectedData.student_notes_id.id, { status: 'draft' });

      if (!result2.error) {
        getNotes();
        setDataButton("delete_note");
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Data Grid Template ========================================== */ }
  // const createdOnTemplate = props => {
  //   return (
  //     <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
  //       <div>{props.student_notes_id.created_on ? moment(props.student_notes_id.created_on).format('DD-MMM-yyyy HH:mm') : ''}</div>
  //       <div className="action_hover" style={{ display: "flex" }}>
  //         <TooltipComponent content={Delete} position="BottomCenter" >
  //           <span className="e-icons icon-delete-05"
  //             style={{ cursor: "pointer", color: '#CD5542', fontSize: "16px",  lineHeight: "40px" }}
  //             onClick={() => handleOpenDialogDeleteNote(props)}
  //           />
  //         </TooltipComponent>
  //       </div>
  //     </div>
  //   )
  // }

  const buttonTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_hover" style={{ display: "flex" }}>
          <TooltipComponent content={Delete} position="BottomCenter" >
            <span className="e-icons icon-delete-05"
              style={{ cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => handleOpenDialogDeleteNote(props)}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const buttonEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter" style={{ marginLeft: "15px", }} >
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "15px", }} >
            <span className="e-icons icon-circle-close-2"
              style={{ cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  // {/* ================== Data Grid Action (edit) ========================================== */ }
  const actionGrid = async (event) => {
    if (event.action === "edit" && event.requestType === "save") {
      if (cancelFlag === false) {
        try {
          const data = event.data;
          const body = { note: data.student_notes_id.note }

          var result = await directus.updateItem('student_notes', data.student_notes_id.id, body);
          if (result.data) {
            getNotes();
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        }
      } else {
        grid.current.editModule.closeEdit()
      }
      cancelFlag = false;

    } else if (event.requestType === "beginEdit") {
      cancelFlag = true;
    }
  }

  const onColumnMenuOpen = (args) => {
    for (const item of args.items) {
      if (item.text === 'Autofit all columns' || item.text === 'Autofit this column') {
        item.hide = true;
      }
    }
  }

  // {/* ================== Footer Template ========================================== */ }

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }
  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      cancelFlag = false;
      grid.current.endEdit();
    }
  }

  return (
    <div id="biodata" style={{ alignContent: "center", color: "#333", minHeight: "600px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }}>
      {loadingNotes ? <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} /> :
        <div>
          <div style={{ float: "right" }}>
            <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddNote}>
              <span className="btn-text-custom">{Student_Note}</span>
            </ButtonComponent>
          </div>
          <br /><br /><br />

          {data ? data.length > 0 ?
            <div>
              <GridComponent
                width="100%"
                dataSource={data}
                editSettings={editSettings}
                allowSorting={true}
                filterSettings={filterOptions}
                allowFiltering={true}
                ref={grid}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: "Content" }}
                allowResizing={true}
                gridLines='Vertical'
                showColumnMenu={true}
                columnMenuOpen={onColumnMenuOpen}
                actionBegin={actionGrid}
                selectionSettings={setting}
                allowReordering={true}
                clipMode="EllipsisWithTooltip"
                keyPressed={onKeyPressed}
              >
                <ColumnsDirective>
                  <ColumnDirective customAttributes={{ id: 'first' }} width="40%" field='student_notes_id.note' headerText={Note} validationRules={{ required: true }}></ColumnDirective>
                  <ColumnDirective width="17%" field='student_notes_id.owner.first_name' allowEditing={false} headerText={Created_by}></ColumnDirective>
                  <ColumnDirective width="18%" field='student_notes_id.created_on' allowEditing={false} headerText={Created_on}></ColumnDirective>
                  <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="10%" template={buttonTemplate} editTemplate={buttonEditTemplate}></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
              </GridComponent>
            </div>
            : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
            : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
          }

          {/* dialog add note  */}
          <DialogComponent
            id="dialogAddNote"
            width="35%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogAddNote}
            header={Student_Note}
            isModal={true}
            close={handleCloseDialogAddNote}
            footerTemplate={() => footerTemplate([
              { name: Update, func: addNote, icon: "icon-check-mark-01", status: "e-success" },
              { name: Cancel, func: handleCloseDialogAddNote, icon: "icon-treeview", status: "" }
            ])}
          >
            <div>
              <b>{Note}</b>
              <TextBoxComponent
                name="notes"
                cssClass="e-outline"
                multiline={true}
                change={(e) => handleChangeNote(e, "note")}
                value={state.note}
              />
              {error.note === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
            </div>
          </DialogComponent>

          {/* dialog delete note  */}
          <DialogComponent
            id="dialogDeleteNote"
            width="20%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogDeleteNote}
            header={Delete_Confirmation}
            isModal={true}
            close={handleCloseDialogDeleteNote}
            footerTemplate={() => footerTemplate([
              { name: Delete, func: deleteNote, icon: "icon-delete-05", status: "e-warning" },
              { name: Cancel, func: handleCloseDialogDeleteNote, icon: "icon-treeview", status: "" }
            ])}
          >
            <div>
              {selectedData ?
                <div style={{ fontSize: "14px" }}>
                  {Are_you_sure_you_want_to_delete_the_data_of_student_note}?
                </div>
                : ''}
            </div>
          </DialogComponent>
        </div>
      }
    </div >
  )
}

export default Notes;