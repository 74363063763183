import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';

import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Group, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

let cancelFlag = false;
const Result = () => {
  const { id } = useParams();
  // const navigate = useNavigate();

  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));

  const [Student] = useState(i18next.t('Pages.data.Student'));

  const [Mid_Test] = useState(i18next.t('Pages.result_data.Mid_Test'));
  const [Final_Test] = useState(i18next.t('Pages.result_data.Final_Test'));
  const [Participation] = useState(i18next.t('Pages.result_data.Participation'));

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  //set data
  const [data, setData] = useState(null);

  //set initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);

  //grid component setting
  const grid = useRef();
  const editSettings = {
    allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal'
  };

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  useEffect(() => {
    const updateData = (props) => {
      if (props.update === true) { getData(); }
    }
    const listener = EventEmitter.addListener('update_student', updateData);
    return () => { listener.remove(); }
  })

  // {/* ================== Get All Data ========================================== */ }
  //get all result data
  const getData = async () => {
    try {
      var result = await directus.getItems('results', {
        fields: "*.*",
        filter: {
          subject_class: id
        }
      });
      setData(result.data);
      setLoading(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Data Grid Template ========================================== */ }
  const numberTemplate = (props) => {
    return (<div>{parseInt(props.index) + 1}</div>)
  }

  const studentTemplate = (props) => {
    return (
      <div>
        <Link to={{ pathname: `/student/profile/${props.student.id}` }} style={{ textDecoration: "none" }}>
          <span
            style={{ padding: "10px", cursor: "pointer", color: "#3c8dbc", fontWeight: "550", textTransform: "uppercase" }}
          >
            {props.student.nickname ? props.student.nickname : props.student.name}
          </span>
        </Link>

      </div>
    )
  }
  // {/* ================== Data Grid Action (edit) ========================================== */ }
  const actionGrid = async (event) => {
    if (event.action === "edit" && event.requestType === "save") {
      if (cancelFlag === false) {
        const data = event.data;
        const body = {
          id: data.id,
          exam_mid: data.exam_mid ? data.exam_mid : 0,
          exam_end: data.exam_end ? data.exam_end : 0,
          participation: data.participation ? data.participation : 0
        }
        try {
          var result = await directus.updateItem('results', body.id, body);
          if (!result.error) {
            getData();
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        }
      } else {
        grid.current.editModule.closeEdit()
      }
      cancelFlag = false;

    } else if (event.requestType === "beginEdit") {
      cancelFlag = true;
    }
  }


  const lastRowTemplate = () => {
    return (
      <div></div>
    )
  };

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const lastRowEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter" style={{ marginLeft: "15px" }} >
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  // {/* ================== Main Content ========================================== */}
  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      cancelFlag = false;
      grid.current.endEdit();
    }
  }

  return (
    <div style={{ paddingLeft: "1%", paddingRight: "1%", paddingTop: "1%", paddingBottom: "1%" }}>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", marginLeft: "50%" }} />
        :
        <div>
          <div style={{ paddingTop: "10px", paddingBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }}>
            <GridComponent
              dataSource={data}
              editSettings={editSettings}
              ref={grid}
              allowTextWrap={true}
              gridLines='Vertical'
              textWrapSettings={{ wrapMode: "Content" }}
              allowResizing={true}
              actionBegin={actionGrid}
              selectionSettings={{ mode: 'Both' }}
              allowFiltering={false}
              clipMode="EllipsisWithTooltip"
              keyPressed={onKeyPressed}
            >
              <ColumnsDirective>
                <ColumnDirective customAttributes={{ id: 'first' }} width="10%" textAlign="center" template={numberTemplate} editTemplate={numberTemplate} allowEditing={false} headerText='No.'></ColumnDirective>
                <ColumnDirective width="30%" field='student.nickname' template={studentTemplate} allowEditing={false} headerText={Student}></ColumnDirective>
                <ColumnDirective width="15%" field='exam_mid' headerText={Mid_Test}></ColumnDirective>
                <ColumnDirective width="15%" field='exam_end' headerText={Final_Test}></ColumnDirective>
                <ColumnDirective customAttributes={{ id: 'last' }} width="15%" field='participation' headerText={Participation}></ColumnDirective>
                <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="15%" template={lastRowTemplate} editTemplate={lastRowEditTemplate}></ColumnDirective>
              </ColumnsDirective>
              <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Group, Resize]} />
            </GridComponent>

          </div>
        </div>
      }
    </div>
  );
}

export default Result;