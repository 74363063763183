import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import i18next from "i18next";
import linq from "linq";
import moment from 'moment';

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

const MonthlyReport = () => {
  /* eslint-disable no-unused-vars*/
  // const navigate = useNavigate();
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));

  const [Name] = useState(i18next.t('Pages.data.Name'));

  const [Start_Date] = useState(i18next.t('Pages.schedule_data.Start_Date'));
  const [End_Date] = useState(i18next.t('Pages.schedule_data.End_Date'));

  const [Student_Monthly_Report] = useState(i18next.t('Pages.monthly_report_data.Student_Monthly_Report'));
  const [New_Students] = useState(i18next.t('Pages.monthly_report_data.New_Students'));
  const [Students_that_are_not_continuing] = useState(i18next.t('Pages.monthly_report_data.Students_that_are_not_continuing'));
  const [Students_that_started_class_this_month] = useState(i18next.t('Pages.monthly_report_data.Students_that_started_class_this_month'));
  const [Students_that_finished_class_this_month] = useState(i18next.t('Pages.monthly_report_data.Students_that_finished_class_this_month'));
  const [Class_Name] = useState(i18next.t('Pages.monthly_report_data.Class_Name'));
  const [Month] = useState(i18next.t('Pages.monthly_report_data.Month'));

  //data
  const [newStudent, setNewStudent] = useState(null);
  const [studentDiscontinue, setStudentDiscontinue] = useState(null);
  const [studentStartClass, setStudentStartClass] = useState(null);
  const [studentFinishClass, setStudentFinishClass] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);

  //grid setting
  const grid = useRef();
  const gridFinishClass = useRef();

  //date picker (MMMM-YYYY)
  var datePickerRef = useRef(null);

  //data filter
  const [dataFilter, setDataFilter, dataFilterRef] = useStateRef({
    date: moment().format(),
    start_date: moment().clone().startOf('month').format(),
    end_date: moment().clone().endOf('month').format(),
    teacher: 0,
    min_date: moment().clone().startOf('month').format(),
    max_date: moment().clone().endOf('month').format(),
  })
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getMinMaxMDate();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get min max date in subject class
  const getMinMaxMDate = async () => {
    try {
      var result_min = await directus.getItems('subject_classes',
        {
          meta: "*", sort: "start_date", limit: 1
        });

      var result_max = await directus.getItems('subject_classes',
        {
          meta: "*", sort: "-end_date", limit: 1
        });

      if (result_min.data && result_max.data) {
        if (result_min.data.length > 0 && result_max.data.length > 0) {
          setDataFilter({
            ...dataFilterRef.current,
            min_date: moment(result_min.data[0].start_date).clone().startOf('month').format(),
            max_date: moment(result_max.data[0].end_date).clone().endOf('month').format()
          })

          getStudentStartClass();
        } else {
          setDataFilter({
            ...dataFilterRef.current,
            min_date: moment().clone().startOf('month').format(),
            max_date: moment().clone().endOf('month').format()
          })

          setLoading(false);
        }

      } else {
        setDataFilter({
          ...dataFilterRef.current,
          min_date: moment().clone().startOf('month').format(),
          max_date: moment().clone().endOf('month').format()
        })

        setLoading(false);
      }


    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of students who enrolled a class in a given month
  const getStudentStartClass = async () => {
    try {

      var result = await directus.getItems('students_subject_classes', {
        fields: '*.*',
        filter: {
          'subject_classes_id.start_date': {
            'between': [moment(dataFilterRef.current.start_date).format("YYYY-MM-DD"), moment(dataFilterRef.current.end_date).format("YYYY-MM-DD")]
          }
        }
      });

      var result_data = linq.from(result.data)
        .orderBy(o => o.students_id.name)
        .toArray();

      setStudentStartClass(result_data);
      getNewStudent(result_data);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const getNewStudent = async (result_data) => {
    try {
      var new_student = [];
      if (result_data) {
        if (result_data.length > 0) {
          var listStudent = linq.from(result_data)
            .select(data => data.students_id.id)
            .toArray();

          var result = await directus.getItems('students_subject_classes', {
            fields: '*.*',
            filter: {
              'students_id.id': {
                'in': listStudent
              }
            },
            sort: "students_id"
          });

          var groupStudent = linq.from(result.data)
            .groupBy(x => x.students_id.id)
            .toArray();

          //eslint-disable-next-line array-callback-return
          groupStudent.map((value) => {
            if (value.getSource().length > 0) {
              // 'between': [moment(dataFilterRef.current.start_date).format("YYYY-MM-DD"), moment(dataFilterRef.current.end_date).format("YYYY-MM-DD")]
              var studentSubjectClass = value.getSource();

              var selectedSubjectClass = linq.from(studentSubjectClass)
                .where(w => moment(w.subject_classes_id.start_date).format("YYYY-MM-DD") >= moment(dataFilterRef.current.start_date).format("YYYY-MM-DD")
                  && moment(w.subject_classes_id.start_date).format("YYYY-MM-DD") <= moment(dataFilterRef.current.end_date).format("YYYY-MM-DD")
                )
                .toArray();


              var statusNewStudent = true;
              if (selectedSubjectClass.length > 0) {
                //eslint-disable-next-line array-callback-return
                selectedSubjectClass.map((value) => {
                  var checkSubjectClass = linq.from(studentSubjectClass)
                    .where(w =>
                      (moment(w.subject_classes_id.start_date).format("YYYY-MM-DD") >= moment(value.subject_classes_id.start_date).subtract(2, 'months').format("YYYY-MM-DD")
                        && moment(w.subject_classes_id.start_date).format("YYYY-MM-DD") <= moment(value.subject_classes_id.end_date).format("YYYY-MM-DD"))
                      ||
                      (moment(w.subject_classes_id.end_date).format("YYYY-MM-DD") >= moment(value.subject_classes_id.start_date).subtract(2, 'months').format("YYYY-MM-DD")
                        && moment(w.subject_classes_id.end_date).format("YYYY-MM-DD") <= moment(value.subject_classes_id.end_date).format("YYYY-MM-DD"))
                    )
                    .toArray();

                  if (checkSubjectClass.length > 1) {
                    statusNewStudent = false;
                  }

                })
              }

              if (statusNewStudent) {
                new_student.push(value.getSource()[0].students_id)
              }

            }
          })
        }
      }

      setNewStudent(new_student);

      getStudentFinishClass();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of students who finished a class in a given month
  const getStudentFinishClass = async () => {
    try {
      var result = await directus.getItems('students_subject_classes', {
        fields: '*.*',
        filter: {
          'subject_classes_id.end_date': {
            'between': [moment(dataFilterRef.current.start_date).format("YYYY-MM-DD"), moment(dataFilterRef.current.end_date).format("YYYY-MM-DD")]
          }
        }
      });

      var result_data = linq.from(result.data)
        .orderBy(o => o.students_id.name)
        .toArray();

      setStudentFinishClass(result_data);
      getStudentDiscontinue(result_data);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of students who have no classes in a given month
  const getStudentDiscontinue = async (result_data) => {
    try {
      var idStudentFinishClass = null;
      if (result_data.length > 0) {
        idStudentFinishClass = linq.from(result_data)
          .select(data => data.students_id.id)
          .toArray();
      }

      var result = await directus.getItems('students_subject_classes', {
        fields: '*.*',
        filter: {
          "students_id.id": { "in": idStudentFinishClass },
          'subject_classes_id.start_date': {
            'between': [moment(dataFilterRef.current.start_date).format("YYYY-MM-DD"), moment(dataFilterRef.current.end_date).add(14, "days").format("YYYY-MM-DD")]
          }
        }
      });

      var idStudentContinue = null;
      if (result.data.length > 0) {
        idStudentContinue = linq.from(result.data)
          .select(data => data.students_id.id)
          .toArray();

        var result_student_discontinue = linq.from(result_data)
          .where(w =>
            !linq.from(idStudentContinue).contains(w.students_id.id))
          .toArray();



        setStudentDiscontinue(result_student_discontinue);
      } else {
        setStudentDiscontinue(result_data);
      }

      setLoading(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Filtering ========================================== */ }

  const handleFilter = async (e, type) => {
    var value = '';
    if (type === "date") { value = e.value; }

    setDataFilter({
      ...dataFilterRef.current,
      start_date: moment(value).clone().startOf('month').format(),
      end_date: moment(value).clone().endOf('month').format()
    });

    getStudentStartClass();
  }

  // {/* ================== Data Grid Template ========================================== */ }

  //hide header in grid component (new student and student discontinue)
  const onCreated = () => {
    const header = document.getElementsByClassName("e-headercontent");
    header[0].style.display = "none"
    header[1].style.display = "none"

    const gridLine = document.getElementsByClassName("e-grid");
    for (var i = 0; i < gridLine.length; i++) {
      gridLine[i].style.border = "0px solid white";
    }
  }

  //show blank if no data
  const onDataBound = () => {
    const empty = document.getElementsByClassName("e-emptyrow");
    if (empty.length > 0) {
      for (var i = 0; i < empty.length; i++) {
        empty[i].children[0].innerHTML = "";
      }
    }
  }

  const nameTemplate = (props) => {
    return (
      <span>
        <Link to={{ pathname: `/student/profile/${props.id}` }} style={{ textDecoration: "none" }}>
          <span style={{ color: "#3c8dbc", cursor: "pointer" }} >{props.name}</span>
        </Link>
      </span>
    )
  }

  const startStudentNameTemplate = (props) => {
    return (
      <span>
        <Link to={{ pathname: `/student/profile/${props.students_id.id}` }} style={{ textDecoration: "none" }}>
          <span style={{ color: "#3c8dbc", cursor: "pointer" }} >{props.students_id.name}</span>
        </Link>
      </span>
    )
  }

  const startClassNameTemplate = (props) => {
    return (
      <span>
        <Link to={{ pathname: `/subject_class/detail/${props.subject_classes_id.id}` }} style={{ textDecoration: "none" }}>
          <span style={{ color: "#3c8dbc", cursor: "pointer" }} >{props.subject_classes_id.name}</span>
        </Link>
      </span>
    )
  }

  const studentDiscontinueNameTemplate = (props) => {
    return (
      <span>
        <Link to={{ pathname: `/student/profile/${props.students_id.id}` }} style={{ textDecoration: "none" }}>
          <span style={{ color: "#3c8dbc", cursor: "pointer" }} >{props.students_id.name}</span>
        </Link>
      </span>
    )
  }

  const changeDate = (args) => {
    var num = 0;
    if (args === "prev") {
      num = -1
    } else {
      num = 1
    }

    setDataFilter({
      ...dataFilterRef.current,
      date: moment(datePickerRef.value).add(num, "month").format(),
      start_date: moment(datePickerRef.value).add(num, "month").clone().startOf('month').format(),
      end_date: moment(datePickerRef.value).add(num, "month").clone().endOf('month').format()
    });

    getStudentStartClass();
  }

  // {/* ================== Main Content ========================================== */ }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Student_Monthly_Report}</div>
          </div>
          <div className="content-main">
            <div style={{ marginTop: "30px", marginBottom: "20px" }}>
              <div style={{ fontWeight: "700", lineHeight: "30px" }} >{Month}:</div>
              <div style={{ height: "30px" }}>
                <div style={{ display: "flex" }}>
                  <div><ButtonComponent className="btn-monthly-report" style={{ borderRadius: "4px 0 0 4px" }} iconCss='e-icons icon-arrow-sans-left-01' onClick={() => changeDate("prev", dataFilterRef.current.date)} /></div>
                  <div className="dp_container" style={{ width: "120px" }}>
                    <DatePickerComponent
                      id="dp_custom"
                      showClearButton={false}
                      ref={scope => datePickerRef = scope}
                      cssClass="e-outline"
                      name="date"
                      format='MMM yyyy'
                      start="Year"
                      depth="Year"
                      openOnFocus={true}
                      value={dataFilterRef.current.date}
                      min={dataFilterRef.current.min_date}
                      max={dataFilterRef.current.max_date}
                      onChange={(e) => handleFilter(e, "date")}
                    />
                  </div>
                  <div><ButtonComponent className="btn-monthly-report" style={{ borderRadius: "0 4px 4px 0" }} iconCss='e-icons icon-arrow-sans-right-01' onClick={() => changeDate("next", dataFilterRef.current.date)} /></div>
                </div>
              </div>
              <hr />
              <div id="gridstudent">
                <div style={{ display: "flex", width: "80%" }}>
                  <div style={{ marginRight: "20px" }}>
                    <h4><b>{New_Students}</b></h4>
                    <GridComponent
                      className="grid-list-monthly_1"
                      ref={grid}
                      dataSource={newStudent}
                      allowTextWrap={true}
                      created={onCreated}
                      dataBound={onDataBound}
                    >
                      <ColumnsDirective>
                        <ColumnDirective field='name' template={nameTemplate} ></ColumnDirective>
                      </ColumnsDirective>
                      <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder]} />
                    </GridComponent>
                  </div>

                  <div style={{ marginLeft: "20px" }}>
                    <h4><b>{Students_that_are_not_continuing}</b></h4>
                    <GridComponent
                      className="grid-list-monthly_1"
                      ref={grid}
                      dataSource={studentDiscontinue}
                      allowTextWrap={true}
                      created={onCreated}
                      dataBound={onDataBound}
                    >
                      <ColumnsDirective>
                        <ColumnDirective field='name' template={studentDiscontinueNameTemplate} ></ColumnDirective>
                      </ColumnsDirective>
                      <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder]} />
                    </GridComponent>
                  </div>
                </div>


                <div style={{ width: "100%", paddingTop: "20px" }}>
                  <hr />
                  <h4><b>{Students_that_started_class_this_month}</b></h4>
                  <GridComponent
                    className="grid-list-monthly_2"
                    ref={grid}
                    width="auto"
                    dataSource={studentStartClass}
                    allowTextWrap={true}
                    created={onCreated}
                  >
                    <ColumnsDirective>
                      <ColumnDirective customAttributes={{ id: 'first' }} width="177px" field='students_id.name' headerText={Name} template={startStudentNameTemplate} ></ColumnDirective>
                      <ColumnDirective width="177px" field='subject_classes_id.name' headerText={Class_Name} template={startClassNameTemplate} ></ColumnDirective>
                      <ColumnDirective customAttributes={{ id: 'last' }} width="177px" field='subject_classes_id.start_date' headerText={Start_Date} editType='datepickeredit' type='date' format="dd-MMM-yyyy"></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder]} />
                  </GridComponent>
                </div>

                <div style={{ width: "100%", paddingTop: "20px" }}>
                  <hr />
                  <h4><b>{Students_that_finished_class_this_month}</b></h4>
                  <GridComponent
                    className="grid-list-monthly_2"
                    ref={gridFinishClass}
                    width="auto"
                    dataSource={studentFinishClass}
                    allowTextWrap={true}
                    dataBound={onDataBound}
                  >
                    <ColumnsDirective>
                      <ColumnDirective customAttributes={{ id: 'first' }} width="177px" field='students_id.name' headerText={Name} template={startStudentNameTemplate} ></ColumnDirective>
                      <ColumnDirective width="177px" field='subject_classes_id.name' headerText={Class_Name} template={startClassNameTemplate} ></ColumnDirective>
                      <ColumnDirective width="177px" field='subject_classes_id.start_date' headerText={Start_Date} editType='datepickeredit' type='date' format="dd-MMM-yyyy"></ColumnDirective>
                      <ColumnDirective customAttributes={{ id: 'last' }} width="177px" field='subject_classes_id.end_date' headerText={End_Date} editType='datepickeredit' type='date' format="dd-MMM-yyyy"></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder]} />
                  </GridComponent>

                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default MonthlyReport;