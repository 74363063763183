import directus from 'src/services/directus';

/**
 * Get current directus user
 * @param {boolean} forceReload
 */
/* eslint-disable array-callback-return*/
export async function createSubjectClassItem(data) {
  var result = await directus.createItems('subject_classes',
    data
  );
  if (!result.error) {

    return result.data;
  }
}


export async function createSubjectMeeting(data) {
  var result = await directus.createItems('subject_classes_meetings',
    data
  );
  if (!result.error) {

    return result.data;
  }
}

export async function createStudentsSubjectClasses(data) {

  var result = await directus.createItems('students_subject_classes',
    data
  );
  if (!result.error) {

    return result.data;
  }
}

export async function createAttendances(data) {

  var result = await directus.createItems('attendances',
    data
  );
  if (!result.error) {
    console.log(result.data)
    return result.data;
  }
}


export async function getDataById(id) {
  let teachers = [];
  var result = await directus.getItems('subject_classes',
    {
      fields: '*.*, subjects.*.*.*, meetings.meetings_id.*.*, students.students_id.*',
      filter: {
        id: id
      }
    }
  );
  if (!result.error) {
    teachers = result.data;
    return teachers;
  }
  else {
    window.localStorage.clear();
    window.location.reload();
  }

  return teachers;
}

export async function deleteStudentSubjectClass(data) {

  var result = await directus.getItems('students_subject_classes', {
    filter: {
      subject_classes_id: data.subject_classes_id,
      students_id: data.students_id
    }
  })

  if (!result.error) {

    var attendances = await directus.getItems('attendances', {
      filter: {
        students_subject_classes_id: result.data[0].id
      }
    })

    var attendances_id = [];
    attendances.data.map((value) => {
      attendances_id.push(value.id)
    })

    await directus.deleteItems('attendances', attendances_id);
    await directus.deleteItem('students_subject_classes', result.data[0].id);

  }

}

export async function deleteAttendance(data) {

  var result = await directus.getItems('students_subject_classes', {
    filter: {
      subject_classes_id: data.subject_classes_id,
      students_id: data.students_id
    }
  })

  if (!result.error) {
    await directus.deleteItem('students_subject_classes', result.data[0].id);
  }
}

export async function deleteSubjectClass(data) {
  try {
    await directus.deleteItem('subject_classes', data)

    var result = await directus.getItems('subject_classes_meetings', {
      filter: {
        subject_classes_id: data
      }
    })

    var subject_classes_meetings_id = [];
    result.data.map((value) => {
      subject_classes_meetings_id.push(value.id)
    })

    //delete subject class meeting
    await directus.deleteItem('subject_classes_meetings', subject_classes_meetings_id)

    var meetings_id = [];
    result.data.map((value) => {
      meetings_id.push(value.meetings_id)
    })

    //delete meeting
    await directus.deleteItem('meetings', meetings_id)

    var result_schedules = await directus.getItems('schedules', {
      filter: {
        subject_classes_id: data
      }
    })

    var schedules_id = [];
    result_schedules.data.map((value) => {
      schedules_id.push(value.id)
    })

    //delete schedules
    await directus.deleteItem('schedules', schedules_id)

    var result_attendances = await directus.getItems('attendances', {
      filter: {
        schedules_id: { "in": schedules_id }
      }
    })

    var attendances_id = [];
    result_attendances.data.map((value) => {
      attendances_id.push(value.id)
    })

    //delete attendances
    if (attendances_id.length > 0) {
      await directus.deleteItem('attendances', attendances_id)
    }

    var result_ssc = await directus.getItems('students_subject_classes', {
      filter: {
        subject_classes_id: data
      }
    })

    var students_subject_classes_id = [];
    result_ssc.data.map((value) => {
      students_subject_classes_id.push(value.id)
    })

    //delete students subject classes
    if (students_subject_classes_id.length > 0) {
      await directus.deleteItem('students_subject_classes', students_subject_classes_id)
    }

    var result_student = await directus.getItems('results', {
      filter: {
        subject_class: data
      }
    })

    var results_id = [];
    result_student.data.map((value) => {
      results_id.push(value.id)
    })


    if (results_id.length > 0) {
      await directus.deleteItems('results', results_id)
    }

  } catch (error) {
    console.log(error)
  }
}
/* eslint-enable array-callback-return*/