import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';

import i18next from "i18next";
import moment from 'moment';

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

import './index.css';

import Biodata from './biodata';
import Student from './student';

const ContactProfile = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();
  const navigate = useNavigate();

  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [Please_match_the_requested_format] = useState(i18next.t('Pages.general.Please_match_the_requested_format'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  const [Notes] = useState(i18next.t('Pages.data.Notes'));
  const [Student_2] = useState(i18next.t('Pages.data.Student'));

  const [Parent_Guardian_Profile] = useState(i18next.t('Pages.contact_data.Parent_Guardian_Profile'));
  const [Parent_Guardian_Recap] = useState(i18next.t('Pages.contact_data.Parent_Guardian_Recap'));
  const [Mobile] = useState(i18next.t('Pages.contact_data.Mobile'));
  const [Home] = useState(i18next.t('Pages.contact_data.Home'));
  // const [Other] = useState(i18next.t('Pages.contact_data.Other'));
  const [Address] = useState(i18next.t('Pages.contact_data.Address'));
  const [Contact] = useState(i18next.t('Pages.contact_data.Contact'));
  const [Student_Parent_Guardian_deleted_successfully] = useState(i18next.t('Pages.contact_data.Student_Parent_Guardian_deleted_successfully'));
  const [Students] = useState(i18next.t('Pages.contact_data.Students'));
  const [Are_you_sure_you_want_to_delete_the_data_of_student_parent_guardian] = useState(i18next.t('Pages.contact_data.Are_you_sure_you_want_to_delete_the_data_of_student_parent_guardian'));
  const [If_you_want_to_delete_the_data_fill_in_the_name_below] = useState(i18next.t('Pages.contact_data.If_you_want_to_delete_the_data_fill_in_the_name_below'));
  const [Delete_Parent_Guardian] = useState(i18next.t('Pages.contact_data.Delete_Parent_Guardian'));

  //set data
  const [data, setData] = useState(null);
  const [dataStudentContact, setDataStudentContact] = useState(null);

  //set initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogDeleteContact = useRef(false);

  //delete contact
  const [deleteWord, setDeleteWord] = useState(null);

  //other component setting
  const headerText = [{ text: "Biodata" }, { text: Student_2 }];
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getContact();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  useEffect(() => {
    const reloadData = () => {
      getContact();
    }

    const listener = EventEmitter.addListener('reloadContactData', reloadData);
    return () => {
      listener.remove();
    }
  })

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "delete_contact") {
        dialogDeleteContact.current.visible = false;
        setDeleteWord('');
        EventEmitter.emit('alert_toast', { content: Student_Parent_Guardian_deleted_successfully, type: "success" });
        setDataButton(null);
        return navigate('/contact/list');
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get list of contacts
  const getContact = async () => {
    try {
      var result = await directus.getItems('contacts',
        {
          fields: '*.*.*',
          filter: {
            id: id
          }
        }
      );

      setData(result.data[0]);
      getStudentContact();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of contacts who have students
  const getStudentContact = async () => {
    try {
      var result = await directus.getItems('students_contacts', {
        fields: '*.*',
        filter: {
          'contacts_id.id': { 'in': id }
        }
      });
      setDataStudentContact(result.data);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }


  // {/* ================== Handle Contacts ========================================== */ }
  const handleOpenDialogDeleteContact = () => {
    dialogDeleteContact.current.visible = true;
  }

  const handleCloseDialogDeleteContact = () => {
    dialogDeleteContact.current.visible = false;
    setDeleteWord('');
  }

  const handleChangeWord = (e) => {
    setDeleteWord(e.value);
  }

  const deleteContact = async () => {
    try {
      if (deleteWord === data.name) {
        setLoadingButton(true);
        var result = await directus.updateItem('contacts', id, { status: "draft" });

        const body_activity = {
          action: "Delete",
          category: 10,
          description: data.name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (result.data) {
          getContact();
          setDataButton("delete_contact");
        }

      } else if (deleteWord !== data.name) {
        EventEmitter.emit('alert_toast', { content: Please_match_the_requested_format, type: "danger" });
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Tab Component Display ========================================== */ }

  const contentBiodata = () => {
    return (<div><Biodata /></div>)
  }

  const contentStudent = () => {
    return (<div><Student /></div>)
  }

  // {/* ================== Other Setting ========================================== */ }
  //get student's age
  //eslint-disable-next-line no-unused-vars
  var age = null;
  if (data) {
    if (data.date_of_birth) {
      const birthdate = moment(new Date(data.date_of_birth), 'MM-YYYY');
      const now = moment();
      var date = moment.duration(now.diff(birthdate));
      age = date.years();
    }
  }

  //click student name
  // function btnClick(id) {
  //   return navigate('/student/profile/' + id);
  // }

  // {/* ================== Footer Template ========================================== */ }
  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }

  return loading ?
    <div><CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} /></div>
    :
    data ?
      <div>
        <div className="header">
          <div className="content-header">{Parent_Guardian_Profile}</div>
        </div>
        <div className="content-main" style={{ marginTop: "30px" }}>
          <div className="col-md-3">
            <div id="contact_profile" style={{
              color: "#333", backgroundColor: "#fff",
              boxShadow: "0 2px 2px rgb(0 0 0 / 10%)",
              padding: "10px",
              borderTop: "5px solid #3c8dbc",
              borderRadius: "3px"
            }}>

              <div id="contact_profile_info" style={{ paddingTop: "20px" }}>
                <div id="contact_biodata_1" style={{ textAlign: "center" }}>
                  <h4>{data.name}</h4>
                  <p>{data.nickname ? data.nickname : '-'}</p>
                </div>
              </div>
            </div>
            <br />
            <div id="contact_recap" style={{
              color: "#333", backgroundColor: "#fff",
              boxShadow: "0 2px 2px rgb(0 0 0 / 10%)",
              padding: "10px",
              borderTop: "5px solid #3c8dbc",
              borderRadius: "3px"
            }}>
              <h4>{Parent_Guardian_Recap}</h4>

              <div id="teacher_contact_table" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <div>
                  <span className="e-icons icon-MT_Resource" />
                  <span style={{ fontSize: "15px", paddingLeft: "5px" }}><b>{Contact}</b></span>
                  <br />
                  {data.mobile_number ? <li>{Mobile} : {data.mobile_number} <br /> </li> : ''}
                  {data.phone_number ? <li>{Home} : {data.phone_number} <br /> </li> : ''}
                </div>
                <hr />
                <div>
                  <span className="e-icons icon-MT_Resource" />
                  <span style={{ fontSize: "15px", paddingLeft: "5px" }}><b>{Students}</b></span>
                  <br />
                  <div style={{ paddingLeft: "5px" }}>
                    {dataStudentContact.length > 0 ? dataStudentContact.map((value, index) => (
                      <li key={index}>
                        <Link to={{ pathname: `/student/profile/${value.students_id.id}` }} style={{ textDecoration: "none" }}>
                          <ButtonComponent cssClass='e-link' style={{ color: "#3c8dbc" }}
                          >
                            {value.students_id.nickname}
                          </ButtonComponent>
                        </Link>
                      </li>
                    )) : ''}
                  </div>
                </div>
                <hr />
                <div style={{ fontSize: "15px" }}>
                  <span className="e-icons icon-MT_Location" />
                  <span style={{ fontSize: "15px", paddingLeft: "5px" }}><b>{Address}</b></span>
                  <br />
                  {data.address ? <div>{data.address} <br /> </div> : ''}
                </div>
                <hr />
                <div style={{ fontSize: "15px" }}>
                  <span className="e-icons icon-MT_Description" />
                  <span style={{ fontSize: "15px", paddingLeft: "5px" }}><b>{Notes}</b></span>
                  <br />
                  {data.notes ? <div>{data.notes} <br /> </div> : ''}
                </div>
                <hr />
                <div style={{ padding: "5px 0 5px 0" }}>
                  <ButtonComponent className="btn-custom delete" style={{ width: "100%" }} iconCss='e-icons icon-delete-05'
                    onClick={() => handleOpenDialogDeleteContact()}
                  >
                    <span className="btn-text-custom delete" style={{ color: "red" }}>{Delete_Parent_Guardian}</span>
                  </ButtonComponent>

                  <br />
                </div>
              </div>
            </div>
            <br />
          </div>
          <div className="col-md-9" style={{ paddingLeft: "30px" }}>
            <div className='control-section' style={{ minHeight: "600px", display: "block", boxShadow: "rgb(0 0 0 / 10%) 0px 2px 2px" }}>
              {data ?
                <TabComponent className="e-fill" heightAdjustMode='None' enablePersistence={true}>
                  <TabItemsDirective>
                    <TabItemDirective header={headerText[0]} content={contentBiodata} />
                    <TabItemDirective header={headerText[1]} content={contentStudent} />
                  </TabItemsDirective>
                </TabComponent>
                : <div></div>}
            </div>
          </div>
        </div>
        <DialogComponent
          id="dialogDeleteContact"
          width="35%"
          showCloseIcon={!loadingButton ? true : false}
          visible={false}
          ref={dialogDeleteContact}
          header={Delete_Confirmation}
          isModal={true}
          footerTemplate={() => footerTemplate([
            { name: Delete, func: deleteContact, icon: "icon-delete-05", status: "e-warning" },
            { name: Cancel, func: handleCloseDialogDeleteContact, icon: "icon-treeview", status: "" }
          ])}
          close={handleCloseDialogDeleteContact}
        >
          <div id="contentDialogTeacher" style={{ fontSize: "14px" }}>
            {Are_you_sure_you_want_to_delete_the_data_of_student_parent_guardian} ({data.name ? data.name : ''})?
            <br /><br />
            <p style={{ color: "red" }}>
              {If_you_want_to_delete_the_data_fill_in_the_name_below}:
            </p>

            <TextBoxComponent
              cssClass="e-outline"
              name="delete_teacher"
              change={(e) => handleChangeWord(e)}
              value={deleteWord}
            />
          </div>
        </DialogComponent>

      </div>
      : ''
    ;
}

export default ContactProfile;