import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import RegisterView from 'src/views/auth/RegisterView';
import PasswordReset from 'src/views/auth/ResetPasswordPage';
import StudentList from 'src/views/Students';
import StudentProfile from 'src/views/Students/profile';
import TeacherProfile from 'src/views/Teachers/profile';
import ContactList from 'src/views/Contacts';
import ContactProfile from 'src/views/Contacts/Profile';
import TeacherList from 'src/views/Teachers';
import SubjectClassList from 'src/views/Classes';
import RoomList from 'src/views/Classes/Room';
import ClassSchedule from 'src/views/Classes/Schedule';
import SubjectClassDetail from 'src/views/Classes/SubjectClass';
import Attendance from 'src/views/Attendance';
import MeetingSummary from 'src/views/Teachers/MeetingSummary';
import ActivityList from 'src/views/Avtivities';
import ActivityDetail from 'src/views/Avtivities/Detail';
import ActivityType from 'src/views/Avtivities/ActivityType';
import ActivitySummary from 'src/views/Avtivities/ActivitySummary';
import SubjectGroup from 'src/views/SystemConfig/SubjectGroup';
import Subject from 'src/views/SystemConfig/Subject';
import SubjectView from 'src/views/SystemConfig/Subject/View';
import ActivityLog from 'src/views/SystemConfig/ActivityLog';
import PublicHoliday from 'src/views/SystemConfig/PublicHoliday';
import Profile from 'src/views/Profile';
import MonthlyReport from 'src/views/Students/MonthlyReport';
import Training from 'src/views/Teachers/Training';
import TrainingParticipant from 'src/views/Teachers/TrainingParticipant';
import StaffList from 'src/views/Staffs';
import * as permission from 'src/utils/Permission';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      // { path: '/', element: <Dashboard /> },
      { path: '/', element: <StudentList /> },
      // { path: '/dashboard', element: <Dashboard /> },
      { path: '/student/list', element: <StudentList /> },
      { path: '/student/profile/:id', element: <StudentProfile /> },
      { path: '/contact/list', element: permission.AdminPermission() ? <ContactList /> : <Navigate to="/" /> },
      { path: '/contact/profile/:id', element: permission.AdminPermission() ? <ContactProfile /> : <Navigate to="/" /> },
      { path: '/teacher/list', element: <TeacherList /> },
      { path: '/teacher/profile/:id', element: <TeacherProfile /> },
      { path: '/subject_class/list', element: <SubjectClassList /> },
      { path: '/subject_class/detail/:id', element: <SubjectClassDetail /> },
      { path: '/room/list', element: permission.AdminPermission() ?  <RoomList /> :  <Navigate to="/" />},
      { path: '/schedule/weekly', element: <ClassSchedule /> },
      { path: '/schedule/list/all', element: <Attendance /> },
      { path: '/schedule/teacher_summary', element: permission.AdminPermission() ? <MeetingSummary /> : <Navigate to="/" /> },
      { path: '/activity/list', element: <ActivityList /> },
      { path: '/activity_type/list', element: permission.AdminPermission() ? <ActivityType /> : <Navigate to="/" /> },
      { path: '/activity_type/summary/:id', element: permission.AdminPermission() ? <ActivitySummary /> : <Navigate to="/" /> },
      { path: '/activity/detail/:id', element: <ActivityDetail /> },
      { path: '/subject_group/list', element: permission.AdminPermission() ? <SubjectGroup /> : <Navigate to="/" /> },
      { path: '/subject/list', element: permission.AdminPermission() ? <Subject /> : <Navigate to="/" /> },
      { path: '/subject/view/:id', element: <SubjectView /> },
      { path: '/user/profile', element: <Profile /> },
      { path: '/class_student/monthly_report', element: permission.AdminPermission() ? <MonthlyReport /> : <Navigate to="/" /> },
      { path: '/training/list', element: <Training /> },
      { path: '/training_participant/list', element: <TrainingParticipant /> },
      { path: '/training_participant/list/:training_id', element: <TrainingParticipant /> },
      { path: '/activity_log/list', element: permission.AdminPermission() ? <ActivityLog /> : <Navigate to="/" /> },
      { path: '/public_holiday/list', element: permission.AdminPermission() ? <PublicHoliday /> : <Navigate to="/" /> },
      { path: '/staff/list', element: permission.AdminPermission() ? <StaffList /> : <Navigate to="/" /> },
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'passwordreset', element: <PasswordReset /> },
      { path: 'sign-in', element: <LoginView /> },
      { path: 'sign-up', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;

