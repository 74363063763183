import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { InPlaceEditorComponent } from '@syncfusion/ej2-react-inplace-editor';
import { Query } from '@syncfusion/ej2-data';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FaFemale, FaMale } from 'react-icons/fa';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as link from 'src/constants/link';

import "./index.css"

const Contact = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));

  const [Name] = useState(i18next.t('Pages.data.Name'));
  const [Gender] = useState(i18next.t('Pages.data.Gender'));
  const [Place_of_Birth] = useState(i18next.t('Pages.data.Place_of_Birth'));
  const [Date_of_Birth] = useState(i18next.t('Pages.data.Date_of_Birth'));
  const [Male] = useState(i18next.t('Pages.data.Male'));
  const [Female] = useState(i18next.t('Pages.data.Female'));
  const [Religion] = useState(i18next.t('Pages.data.Religion'));
  const [Islam] = useState(i18next.t('Pages.data.Islam'));
  const [Christian_Protestant] = useState(i18next.t('Pages.data.Christian_Protestant'));
  const [Catholic] = useState(i18next.t('Pages.data.Catholic'));
  const [Buddha] = useState(i18next.t('Pages.data.Buddha'));
  const [Hindu] = useState(i18next.t('Pages.data.Hindu'));
  const [Confucianism] = useState(i18next.t('Pages.data.Confucianism'));
  const [Others] = useState(i18next.t('Pages.data.Others'));
  const [Single] = useState(i18next.t('Pages.data.Single'));
  const [Married] = useState(i18next.t('Pages.data.Married'));
  const [Widow_Widower] = useState(i18next.t('Pages.data.Widow_Widower'));
  const [Notes] = useState(i18next.t('Pages.data.Notes'));
  const [Select] = useState(i18next.t('Pages.data.Select'));
  const [Phone_Number] = useState(i18next.t('Pages.data.Phone_Number'));

  const [Student_Parent_Guardian] = useState(i18next.t('Pages.student_data.Student_Parent_Guardian'));
  const [Related_Student] = useState(i18next.t('Pages.student_data.Related_Student'));
  const [Are_you_sure_you_want_to_delete_the_data_of_sibling] = useState(i18next.t('Pages.student_data.Are_you_sure_you_want_to_delete_the_data_of_sibling'));

  const [Email] = useState(i18next.t('Pages.contact_data.Email'));
  const [Address] = useState(i18next.t('Pages.contact_data.Address'));
  const [Regency_City] = useState(i18next.t('Pages.contact_data.Regency_City'));
  const [Province] = useState(i18next.t('Pages.contact_data.Province'));
  const [Postal_Code] = useState(i18next.t('Pages.contact_data.Postal_Code'));
  const [Suburb_Village] = useState(i18next.t('Pages.contact_data.Suburb_Village'));
  const [District] = useState(i18next.t('Pages.contact_data.District'));
  const [Marital_Status] = useState(i18next.t('Pages.contact_data.Marital_Status'));
  const [Nationality] = useState(i18next.t('Pages.contact_data.Nationality'));
  const [Everyday_Language] = useState(i18next.t('Pages.contact_data.Everyday_Language'));
  const [Education_Level] = useState(i18next.t('Pages.contact_data.Education_Level'));
  const [Occupation] = useState(i18next.t('Pages.contact_data.Occupation'));
  const [Company_Name] = useState(i18next.t('Pages.contact_data.Company_Name'));
  const [Field_of_Business] = useState(i18next.t('Pages.contact_data.Field_of_Business'));
  const [Position] = useState(i18next.t('Pages.contact_data.Position'));
  const [Monthly_Income] = useState(i18next.t('Pages.contact_data.Monthly_Income'));
  const [Special_Needs] = useState(i18next.t('Pages.contact_data.Special_Needs'));
  const [Relation] = useState(i18next.t('Pages.contact_data.Relation'));
  const [Contact] = useState(i18next.t('Pages.contact_data.Contact'));
  const [New_Parent_Guardian] = useState(i18next.t('Pages.contact_data.New_Parent_Guardian'));
  const [Parent_Guardian] = useState(i18next.t('Pages.contact_data.Parent_Guardian'));
  const [Father] = useState(i18next.t('Pages.contact_data.Father'));
  const [Mother] = useState(i18next.t('Pages.contact_data.Mother'));
  const [Guardian] = useState(i18next.t('Pages.contact_data.Guardian'));
  const [Friend] = useState(i18next.t('Pages.contact_data.Friend'));
  const [Uncle] = useState(i18next.t('Pages.contact_data.Uncle'));
  const [Aunt] = useState(i18next.t('Pages.contact_data.Aunt'));
  const [Grandfather] = useState(i18next.t('Pages.contact_data.Grandfather'));
  const [Grandmother] = useState(i18next.t('Pages.contact_data.Grandmother'));
  const [Cousin] = useState(i18next.t('Pages.contact_data.Cousin'));
  const [Sibling] = useState(i18next.t('Pages.contact_data.Sibling'));
  const [Contact_created_successfully] = useState(i18next.t('Pages.contact_data.Contact_created_successfully'));
  const [Student_Parent_Guardian_created_successfully] = useState(i18next.t('Pages.contact_data.Student_Parent_Guardian_created_successfully'));
  const [Student_Parent_Guardian_deleted_successfully] = useState(i18next.t('Pages.contact_data.Student_Parent_Guardian_deleted_successfully'));
  const [Please_select_relation] = useState(i18next.t('Pages.contact_data.Please_select_relation'));
  const [Please_select_contact] = useState(i18next.t('Pages.contact_data.Please_select_contact'));
  const [Are_you_sure_you_want_to_delete_the_data_of_contact] = useState(i18next.t('Pages.contact_data.Are_you_sure_you_want_to_delete_the_data_of_contact'));

  const [Grade] = useState(i18next.t('Pages.data.Grade'));
  const [Age] = useState(i18next.t('Pages.data.Age'));
  const [Mobile_Number] = useState(i18next.t('Pages.data.Mobile_Number'));
  const [Mothers_Mobile_Number] = useState(i18next.t('Pages.data.Mothers_Mobile_Number'));
  const [Fathers_Mobile_Number] = useState(i18next.t('Pages.data.Fathers_Mobile_Number'));
  const [Created_on] = useState(i18next.t('Pages.data.Created_on'));
  const [years] = useState(i18next.t('Pages.student_data.years'));
  const [months] = useState(i18next.t('Pages.student_data.months'));
  const [Brother_Sister] = useState(i18next.t('Pages.student_data.Brother_Sister'));
  const [Cousin_2] = useState(i18next.t('Pages.student_data.Cousin'));
  const [Relative] = useState(i18next.t('Pages.student_data.Relative'));
  const [Close_Friend] = useState(i18next.t('Pages.student_data.Close_Friend'));

  //data
  const [dataStudentContact, setDataStudentContact, dataStudentContactRef] = useStateRef(null);
  const [dataContact, setDataContact, dataContactRef] = useStateRef(null);
  const [selectedData, setSelectedData] = useState(null);
  const [dataStudent, setDataStudent, dataStudentRef] = useStateRef(null);
  const [dataSibling, setDataSibling, dataSiblingRef] = useStateRef(null);
  const [idSibling, setIdSibling] = useState(null);
  const [selectedSibling, setSelectedSibling] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogAddContactRelation = useRef(false);
  let dialogAddContact = useRef(false);
  let dialogDeleteContactRelation = useRef(false);
  let dialogAddSibling = useRef(false);
  let dialogDeleteSibling = useRef(false);

  //other component setting
  const fields = { text: 'name', value: 'id' };
  const gender = [{ id: "Male", name: Male }, { id: "Female", name: Female }]
  const religion = [
    { id: 'Islam', text: Islam },
    { id: 'Christian/Protestant', text: Christian_Protestant },
    { id: 'Catholic', text: Catholic },
    { id: 'Buddha', text: Buddha },
    { id: 'Hindu', text: Hindu },
    { id: 'Confucianism', text: Confucianism },
    { id: 'Others', text: Others },
  ];
  const relation = [
    { id: 'Father', text: Father },
    { id: 'Mother', text: Mother },
    { id: 'Guardian', text: Guardian },
    { id: 'Friend', text: Friend },
    { id: 'Uncle', text: Uncle },
    { id: 'Aunt', text: Aunt },
    { id: 'Grandfather', text: Grandfather },
    { id: 'Grandmother', text: Grandmother },
    { id: 'Cousin', text: Cousin },
    { id: 'Sibling', text: Sibling },
  ];
  const marital_status = [
    { id: 'Single', text: Single },
    { id: 'Married', text: Married },
    { id: 'Widow/Widower', text: Widow_Widower }
  ];
  const related_student_sibling = [
    { id: 1, text: Brother_Sister },
    { id: 2, text: Cousin_2 },
    { id: 3, text: Relative },
    { id: 4, text: Close_Friend }
  ]

  //set state add contact
  const [stateContact, setStateContact] = useState({
    name: null,
    phone_number: null,
    mobile_number: null,
    email: null,
    occupation: null,
    gender: null,
    pob: null,
    dob: null,
    religion: null,
    address: null,
    address_rt: null,
    address_rw: null,
    address_city: null,
    address_state: null,
    address_post_code: null,
    address_town: null,
    address_district: null,
    notes: null
  });

  //set state add relation
  const [stateRelation, setStateRelation] = useState({ contact: null, relation: null });

  //set state add relation
  const [stateSibling, setStateSibling] = useState({
    sibling: null,
    relation: null
  });

  //set error in add contact
  const [error, setError, errorRef] = useStateRef({
    contact_name: false,
    contact: false,
    relation: false,
    sibling: false
  });
  /* eslint-enable no-unused-vars*/

  const getInitialValue = () => {
    setStateContact({
      name: null,
      phone_number: null,
      mobile_number: null,
      email: null,
      occupation: null,
      gender: null,
      pob: null,
      dob: null,
      religion: null,
      address: null,
      address_rt: null,
      address_rw: null,
      address_city: null,
      address_state: null,
      address_post_code: null,
      address_town: null,
      address_district: null,
      notes: null
    });

    setStateRelation({
      contact: null,
      relation: null
    })
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoadingContact(true);
      getStudentContact();
    }
  })

  useEffect(() => {
    const reloadRelationship = () => {
      getStudentContact();
    }

    const listener = EventEmitter.addListener('reloadRelationshipData', reloadRelationship);
    return () => {
      listener.remove();
    }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "add_contact") {
        dialogAddContact.current.visible = false;
        getInitialValue();
        EventEmitter.emit('alert_toast', { content: Contact_created_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "add_contact_relation") {
        dialogAddContactRelation.current.visible = false;
        setStateRelation({ relation: null, contact: null });
        EventEmitter.emit('alert_toast', { content: Student_Parent_Guardian_created_successfully, type: "success" });
        EventEmitter.emit('reloadStudentData', { text: "click" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_contact_relation") {
        dialogDeleteContactRelation.current.visible = false;
        setStateRelation({ relation: null, contact: null });
        EventEmitter.emit('alert_toast', { content: Student_Parent_Guardian_deleted_successfully, type: "success" });
        EventEmitter.emit('reloadStudentData', { text: "click" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "add_sibling") {
        dialogAddSibling.current.visible = false;
        setStateSibling(null);
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        EventEmitter.emit('updateSibling', { text: "click" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_sibling") {
        dialogDeleteSibling.current.visible = false;
        setSelectedSibling(null);
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        EventEmitter.emit('updateSibling', { text: "click" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }

  //get list of student contact
  const getStudentContact = async () => {
    try {
      var result = await directus.getItems('students_contacts', {
        fields: '*.*', filter: {
          "students_id": id,
          "contacts_id.status": "published"
        }
      });

      setDataStudentContact(result.data);

      getContact();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of contact
  const getContact = async () => {
    try {
      //get the contacts that are related to the student
      var student_contact_id = linq.from(dataStudentContactRef.current)
        .select(data => data.contacts_id.id)
        .toArray();

      //display the contacts that are not related to the student
      var result = await directus.getItems('contacts', {
        sort: "name",
        filter: {
          id: {
            'nin': student_contact_id
          },
          status: "published"
        },
        fields: "id, name"
      });

      setDataContact(result.data);
      getSiblings();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const getSiblings = async () => {
    try {
      var result = await directus.getItems('siblings',
        {
          fields: 'student_2.*.*.*, *.*.*',
          filter: {
            "student_1.id": { "in": id }
          }
        }
      );

      setDataSibling(result.data);

      var result_sibling = linq.from(result.data)
        .select(data => data.student_2.id)
        .toArray();

      if (result_sibling.length > 0) {
        result_sibling.push(parseInt(id));
      }

      setIdSibling(result_sibling);
      getStudent(result_sibling);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const getStudent = async (result_sibling) => {
    try {
      var result = await directus.getItems('students',
        {
          fields: 'id,name,nickname',
          filter: {
            id: { "nin": result_sibling }
          },
          sort: "nickname",
          limit: 5,
          page: 1
        }
      );

      setDataStudent(result.data);
      setLoadingContact(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //close all dialogs
  const handleCloseDialog = () => {
    setError({ ...errorRef.current, contact_name: false, contact: false, relation: false })
    getInitialValue();
    dialogAddContact.current.visible = false;
    dialogAddContactRelation.current.visible = false;
    dialogDeleteContactRelation.current.visible = false;
  }

  // {/* ================== Handle Add Contact ========================================== */ }
  const handleOpenDialogAddContact = () => {
    setError({ ...errorRef.current, contact_name: false, contact: false, relation: false })
    dialogAddContact.current.visible = true;
  }

  const handleChangeContact = (event, type) => {
    var value = "";
    if (type === "gender" || type === "religion") {
      value = event.itemData.id;
    } else if (type === "name") {
      if (event.value) {
        value = event.value;
        setError({ ...error, contact_name: false });
      } else {
        setError({ ...error, contact_name: true });
      }
    } else {
      value = event.value;
    }

    setStateContact({ ...stateContact, [type]: value })
  }

  const addContact = async () => {
    if (!error.contact_name) {
      try {
        setError({ ...errorRef.current, contact_name: false })

        if (stateContact.name) {
          setLoadingButton(true);
          const newDate = stateContact.dob ? moment(stateContact.dob).format("YYYY-MM-DD") : null;
          stateContact.dob = newDate;
          stateContact.status = "published";

          var result = await directus.createItem('contacts', stateContact);

          if (!result.error) {
            const body = {
              students_id: id,
              contacts_id: result.data.id,
              status: "published"
            }

            var result_2 = await directus.createItem('students_contacts', body);

            if (!result_2.error) {
              getStudentContact();
              setDataButton("add_contact");
            }
          }
        } else {
          if (!stateContact.name) {
            setError({ ...errorRef.current, contact_name: true })
          }
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        setLoadingButton(false);
      }
    }
  }


  // {/* ================== Handle Add Student Contact Relation ========================================== */ }

  const handleOpenDialogAddContactRelation = () => {
    setError({ ...errorRef.current, contact_name: false, contact: false, relation: false })
    dialogAddContactRelation.current.visible = true;
  }

  const handleChangeRelation = (event, type) => {
    if (event.itemData.id) {
      const value = event.itemData.id;
      setStateRelation({ ...stateRelation, [type]: value });
      setError({ ...error, [type]: false });
    } else {
      setError({ ...error, [type]: true });
    }
  }

  const addContactRelation = async () => {
    if (!error.relation && !error.contact) {
      try {
        setError({ ...errorRef.current, contact: false, relation: false })
        setLoadingButton(true);
        if (stateRelation.contact && stateRelation.relation) {
          const body = {
            students_id: id,
            contacts_id: stateRelation.contact,
            relation: stateRelation.relation,
            status: "published"
          }

          var result = await directus.createItem('students_contacts', body);

          if (!result.error) {
            getStudentContact();
            setDataButton("add_contact_relation");
          }

        } else {
          if (!stateRelation.contact) { setError({ ...errorRef.current, contact: true }) }
          if (!stateRelation.relation) { setError({ ...errorRef.current, relation: true }) }
          setLoadingButton(false);
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      }
    }
  }

  // {/* ================== Handle Delete Student Contact Relation ========================================== */ }
  const handleOpenDialogDeleteContactRelation = (value) => {
    setSelectedData(value);
    dialogDeleteContactRelation.current.visible = true;
  }


  const deleteContactRelation = async () => {
    try {
      setLoadingButton(true);
      var result = await directus.deleteItem('students_contacts', selectedData.id);

      if (!result.error) {
        getStudentContact();
        setDataButton("delete_contact_relation")
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Filtering ========================================== */ }
  const onFiltering = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataContact, query);
  };

  const onFilteringStudent = (e) => {
    let query = new Query();
    // //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('nickname', 'contains', e.text, true) : query;
    // e.updateData(dataStudentRef.current, query);
    //pass the filter data source, filter query to updateData method.
    try {
      if (e.text !== '') {
        window.setTimeout(async () => {
          var result = await directus.getItems('students',
            {
              fields: 'id,name,nickname',
              filter: {
                nickname: { "contains": e.text },
                // id: { "nin": mulObj.current.value },
                id: { "logical": "and", "nin": idSibling }
              },
              limit: 5,
              page: 1
            }
          );

          e.updateData(result.data, query);
        }, 500)
      }
      else {
        window.setTimeout(async () => {
          var result = await directus.getItems('students',
            {
              fields: 'id,name,nickname',
              filter: {
                id: { "nin": idSibling }
              },
              limit: 5,
              page: 1
            }
          );
          e.updateData(result.data, query);
        }, 500)
      }


    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  };


  // {/* ================== Handle Sibling ========================================== */ }

  function btnClick(value) {
    const id = value.student_2.id;
    EventEmitter.emit('click_student', { update: true, new_id: id })
  }

  function getAge(date_of_birth) {
    var age = "-";
    if (date_of_birth) {
      const birthdate = moment(new Date(date_of_birth), 'MM-YYYY');
      const now = moment();
      var date = moment.duration(now.diff(birthdate));
      age = date.years() + " " + years + " ";
      if (date.months() > 0) {
        age += date.months() + " " + months;
      }
    }

    return age;
  }

  function getNumber(props, relation) {
    var contactNumber = null;
    var contact = linq.from(props)
      .where(w => w.relation === relation)
      .toArray();

    contactNumber = contact.length > 0 ? contact[0].contacts_id.mobile_number : null;
    return contactNumber ? <span>{contactNumber}</span> : null;
  }

  // {/* ================== Handle Add Sibling ========================================== */ }

  const handleOpenDialogAddSibling = () => {
    setError({ ...errorRef.current, sibling: false, relation: false })
    dialogAddSibling.current.visible = true;
  }

  const handleCloseDialogAddSibling = () => {
    setStateSibling({ sibling: null, relation: null });
    setError({ ...errorRef.current, sibling: false, relation: false })
    dialogAddSibling.current.visible = true;
  }

  const handleChangeSibling = (event, type) => {
    if (event.itemData.id) {
      const value = event.itemData.id;
      setStateSibling({ ...stateSibling, [type]: value });
      setError({ ...error, [type]: false });
    } else {
      setError({ ...error, [type]: true });
    }
  }

  const addSibling = async () => {
    if (!error.sibling && !error.relation) {
      setError({ ...errorRef.current, sibling: false, relation: false });
      if (stateSibling.sibling && stateSibling.relation) {
        try {
          setLoadingButton(true);
          var new_sibling = [];
          new_sibling.push({ student_1: id, student_2: stateSibling.sibling, relation: stateSibling.relation, status: "published" })
          new_sibling.push({ student_1: stateSibling.sibling, student_2: id, relation: stateSibling.relation, status: "published" })

          var result = await directus.createItems('siblings', new_sibling);
          if (!result.error) {
            getSiblings();
            setDataButton("add_sibling");
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          setLoadingButton(false);
        }
      } else {
        if (!stateSibling.sibling) { setError({ ...errorRef.current, sibling: true }) }
        if (!stateSibling.relation) { setError({ ...errorRef.current, relation: true }) }
      }
    }
  }

  // {/* ================== Handle Delete Sibling ========================================== */ }

  const handleOpenDialogDeleteSibling = (value) => {
    setSelectedSibling(value)
    dialogDeleteSibling.current.visible = true;
  }

  const handleCloseDialogDeleteSibling = () => {
    setSelectedSibling(null);
    dialogDeleteSibling.current.visible = true;
  }

  const deleteSibling = async () => {
    try {
      setLoadingButton(true);
      var selectedSibling2 = await directus.getItems('siblings',
        {
          fields: '*',
          filter: {
            student_1: { "in": selectedSibling.student_2.id },
            student_2: id
          }
        }
      );

      var delete_data = [selectedSibling.id, selectedSibling2.data[0].id];

      var result = await directus.deleteItems('siblings', delete_data);
      if (!result.error) {
        getSiblings();
        setDataButton("delete_sibling");
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }


  // {/* ================== Inplace Editor Component ========================================== */ }
  //update student contact
  const actionSuccess = async (event, type, value) => {
    var body = null;
    try {
      if (type === "relation") {
        var result = await directus.updateItem('students_contacts', value.id,
          { [type]: getTranslation(event.value, "id", relation) }
        );

        if (!result.error) {
          EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          EventEmitter.emit('reloadStudentData', { text: "click" });
        }
      } else {
        if (type === "dob") {
          body = event.value ? moment(new Date(event.value)).format("YYYY-MM-DD") : null;
        } else if (type === "gender") {
          body = getGender(event.value, "id");
        } else if (type === "religion") {
          body = getTranslation(event.value, "id", religion);
        } else if (type === "marital_status") {
          body = getTranslation(event.value, "id", marital_status);
        } else {
          body = event.value;
        }

        var result_contact = await directus.updateItem('contacts', value.contacts_id.id,
          { [type]: body }
        );

        if (!result_contact.error) {
          EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          if (type === "name" || type === "mobile_number") {
            EventEmitter.emit('reloadStudentData', { text: "click" });
          }
        }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const actionSuccessSibling = async (event, type, value) => {
    if (type === "relation") {
      var selectedSibling2 = await directus.getItems('siblings',
        {
          fields: '*.*',
          filter: {
            student_1: value.student_2.id,
            student_2: value.student_1.id
          }
        }
      )

      var new_sibling = [];
      new_sibling.push({ id: value.id, relation: getTranslation(event.value, "id", related_student_sibling) })
      new_sibling.push({ id: selectedSibling2.data[0].id, relation: getTranslation(event.value, "id", related_student_sibling) })

      var result_sibling = await directus.updateItems('siblings', new_sibling);

      if (!result_sibling.error) {
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
      }
    }
  }

  // {/* ================== Translate Relation, Gender, Religion, Marital Status Data ========================================== */ }

  const getGender = (props, type) => {
    var gender2 = props ? props : null;
    if (gender) {
      gender.forEach((value) => {
        if (type === "name") {
          if (value.id === gender2) { gender2 = value.name }
        }
        if (type === "id") {
          if (value.name === gender2) { gender2 = value.id }
        }
      })
    }
    return "" + gender2
  }

  const getTranslation = (props, type, name) => {
    var oldData = props ? props : null;

    if (name) {
      name.forEach((value) => {
        if (type === "name") {
          if (value.id === oldData) {
            oldData = value.text
          }
        }
        if (type === "id") {
          if (value.text === oldData) {
            oldData = value.id
          }
        }
      })
    }

    return "" + oldData
  }

  // {/* ================== Footer Template ========================================== */ }
  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }

  return (
    <div id="contacts" style={{ color: "#333", minHeight: "600px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }}>
      {loadingContact ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <span>
            <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddContact}>
              <span className="btn-text-custom">{New_Parent_Guardian}</span>
            </ButtonComponent>
          </span>
          <span style={{ paddingLeft: "5px" }}>
            <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddContactRelation}>
              <span className="btn-text-custom">{Parent_Guardian}</span>
            </ButtonComponent>
          </span>
          <span style={{ paddingLeft: "5px" }}>
            <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddSibling}>
              <span className="btn-text-custom">{Related_Student}</span>
            </ButtonComponent>
          </span>

          <div style={{ marginTop: "20px" }}>
            {dataSiblingRef.current ? dataSiblingRef.current.length > 0 ?
              <div>
                <h4>{Related_Student} :</h4>
                <div className='control-section card-control-section tile_layout' style={{ marginLeft: "-20px" }}>
                  <div className="row e-card-layout">
                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "flex-start", alignContent: "flex-start" }}>
                      {dataSiblingRef.current ? dataSiblingRef.current.length > 0 ? dataSiblingRef.current.map((value, index) => (
                        <div style={{ width: "auto", marginLeft: "20px", height: "260px", marginBottom: "20px" }} key={index}>
                          <div id={'card_sample_' + (index + 1)} className=''>
                            <div className='e-card' style={{ borderRadius: '15px', width: "300px" }}>
                              <div className="header-subject_class" style={{ textAlign: "center", background: "#3c8dbc", color: "#eeeeee", height: "40px" }}>
                                <div style={{ position: "absolute", right: "15.31px", height: "inherit", cursor: "pointer", marginTop: "2px" }} >
                                  <span className='e-icons icon-MT_Clear' onClick={() => handleOpenDialogDeleteSibling(value)} style={{ fontSize: "10.5px", fontWeight: "500", color: "#FFFFFF", padding: "4.69px 4.44px", borderRadius: "50%" }} ></span>
                                </div>
                                <span style={{ textDecoration: "uppercase", lineHeight: "40px" }}>
                                  <InPlaceEditorComponent
                                    id="sibling_dropdown"
                                    type='DropDownList'
                                    mode='Popup'
                                    actionOnBlur="Cancel"
                                    name='relation'
                                    data-underline='false'
                                    editableOn="DblClick"
                                    emptyText={'-'}
                                    value={value.relation ? getTranslation(parseInt(value.relation), "name", related_student_sibling) : "-"}
                                    model={{
                                      placeholder: Relation,
                                      dataSource: related_student_sibling, fields: { text: 'text', value: 'text' }
                                    }}
                                    actionSuccess={(e) => actionSuccessSibling(e, "relation", value)} />
                                </span>
                                {/* <div style={{ paddingTop: "10px", paddingRight: "15px", float: "right", cursor: "pointer", lineHeight: "100%", height: "100%" }}>
                              <span style={{ verticalAlign: "middle", fontSize: "20px", paddingLeft: "5px", color: "rgb(238, 238, 238)" }} className='e-icons icon-close' onClick={() => handleOpenDialogDeleteSibling(value)} ></span>
                            </div> */}
                              </div>
                              <div className="header-subject_class"
                                style={{ backgroundColor: "#E1EBF2", paddingLeft: "25px", height: "50px" }}>
                                <div style={{ float: "right", paddingRight: "10px", paddingTop: "10px" }}>
                                  <div className="image" style={{ width: "30px", height: "30px" }}>
                                    <img
                                      style={{
                                        maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                                        objectFit: "cover", borderRadius: "50%", position: "center",
                                      }}
                                      className="image"
                                      src={value.student_2.image ? value.student_2.image.data ? value.student_2.image.data.full_url : link.default_image : link.default_image}
                                      alt={value.student_2.name}
                                    />
                                  </div>
                                </div>
                                <Link to={{ pathname: `/student/profile/${value.student_2.id}` }} style={{ textDecoration: "none" }} onClick={() => btnClick(value)}>
                                  <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", color: "#516573", fontSize: "16px", fontWeight: "800", cursor: "pointer", lineHeight: "50px" }}>
                                    {value.student_2.name}
                                  </div>
                                </Link>
                              </div>
                              <div className='e-card-content' style={{ minHeight: "auto", fontSize: "12px", lineHeight: "1.5", background: "#F1F4FA", height: "140px", position: "relative" }}>
                                <span className='e-card-text' style={{ float: "right", fontSize: "20px", marginRight: "-10px" }}>{value.student_2.gender === "Female" ? <FaFemale style={{ color: "hotpink" }} /> : value.student_2.gender === "Male" ? <FaMale style={{ color: "dodgerblue" }} /> : ""}</span>
                                {value.student_2.date_of_birth ? <div><span className='e-card-text'>{Age} : {getAge(value.student_2.date_of_birth)} </span></div> : ''}
                                {value.student_2.current_school_level ? <div><span className='e-card-text'>{Grade} : {value.student_2.current_school_level} </span></div> : ''}
                                {value.student_2.mobile_number ? <div><span className='e-card-text'>{Mobile_Number} : {value.student_2.mobile_number} </span></div> : ''}
                                {value.student_2.contact ? value.student_2.contact.length > 0 ? getNumber(value.student_2.contact, "Mother") ? <div><span className='e-card-text'>{Mothers_Mobile_Number} : {getNumber(value.student_2.contact, "Mother")} </span></div> : '' : '' : ''}
                                {value.student_2.contact ? value.student_2.contact.length > 0 ? getNumber(value.student_2.contact, "Father") ? <div><span className='e-card-text'>{Fathers_Mobile_Number} : {getNumber(value.student_2.contact, "Father")} </span></div> : '' : '' : ''}
                              </div>

                              <div className='e-card-content' style={{ minHeight: "auto", fontSize: "12px", lineHeight: "1.5", background: "#E1EBF2", height: "30px", position: "relative" }}>
                                <span style={{ right: "25px", height: "20px", fontSize: "10px", position: "absolute", bottom: "0" }}>
                                  {Created_on} : {moment(new Date(value.student_2.created_on)).format("DD-MMM-yyyy")}
                                </span>
                              </div>
                            </div>
                          </div>

                        </div>
                      ))
                        : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
                        : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              : null : null}
            {dataStudentContactRef.current ? dataStudentContactRef.current.length > 0 ?
              <div>
                <h4>{Parent_Guardian} :</h4>
                <div className='control-section card-control-section tile_layout' style={{ marginLeft: "-10px" }}>
                  <div className="row e-card-layout">
                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "flex-start", alignContent: "flex-start" }}>
                      {dataStudentContactRef.current ? dataStudentContactRef.current.map((value, index) => (
                        <div style={{ width: "420px", marginLeft: "10px", marginBottom: "20px" }} key={index}>
                          <div id={'card_sample_' + (index + 1)} className=''>
                            <div className='e-card' style={{ border: '2px solid #ddd', borderRadius: '5px' }}>
                              <div className='header_contact' style={{ backgroundColor: "#E1EBF2", paddingLeft: "15px", paddingRight: "15px" }}>
                                <div>
                                  <InPlaceEditorComponent name='contact_name'
                                    id="contact_dropdown"
                                    data-underline='false'
                                    editableOn="DblClick"
                                    actionOnBlur="Cancel"
                                    value={value.contacts_id.name}
                                    model={{ placeholder: 'Name' }}
                                    showButtons='false'
                                    mode="Popup"
                                    emptyText={'-'}
                                    actionSuccess={(e) => actionSuccess(e, "name", value)} />
                                  <span style={{ color: "#516573", fontWeight: "600" }}>(<InPlaceEditorComponent id="contact_relation_dropdown" emptyText="" type='DropDownList' mode='Popup' actionOnBlur="Cancel" name='relation' data-underline='false' editableOn="DblClick" value={value.relation ? getTranslation(value.relation, "name", relation) : ""} model={{ placeholder: Relation, dataSource: relation, fields: { text: 'text', value: 'text' } }} actionSuccess={(e) => actionSuccess(e, "relation", value)} />)</span>
                                  <div style={{ float: "right", height: "inherit", cursor: "pointer", marginTop: "2px" }} >
                                    <span className='e-icons icon-MT_Clear' onClick={() => handleOpenDialogDeleteContactRelation(value)} style={{ fontSize: "10.5px", fontWeight: "500", color: "#FFFFFF", padding: "4.69px 4.44px", borderRadius: "50%", background: "#B5C2CC" }} ></span>
                                  </div>
                                </div>
                              </div>
                              <div className='e-card-content' style={{ maxHeight: "max-content" }}>
                                <table id="table_contact" style={{ width: "100%", borderTopStyle: "1px solid #ccc", borderBottomStyle: "1px solid #ccc" }}>
                                  <tbody>
                                    <tr>
                                      <td>{Mobile_Number}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" mode='Inline' name='mobile_number' data-underline='false' editableOn="DblClick" value={value.contacts_id.mobile_number} model={{ placeholder: Mobile_Number }} actionSuccess={(e) => actionSuccess(e, "mobile_number", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Phone_Number}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" mode='Inline' name='phone_number' data-underline='false' editableOn="DblClick" value={value.contacts_id.phone_number} model={{ placeholder: Phone_Number }} actionSuccess={(e) => actionSuccess(e, "phone_number", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Email}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='email' data-underline='false' editableOn="DblClick" value={value.contacts_id.email} model={{ placeholder: Email }} actionSuccess={(e) => actionSuccess(e, "email", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Address}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='address' data-underline='false' editableOn="DblClick" value={value.contacts_id.address} model={{ placeholder: Address }} actionSuccess={(e) => actionSuccess(e, "address", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>RT</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='address_rt' data-underline='false' editableOn="DblClick" value={value.contacts_id.address_rt} model={{ placeholder: 'RT' }} actionSuccess={(e) => actionSuccess(e, "address_rt", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>RW</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='address_rw' data-underline='false' editableOn="DblClick" value={value.contacts_id.address_rw} model={{ placeholder: 'RW' }} actionSuccess={(e) => actionSuccess(e, "address_rw", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Regency_City}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='address_city' data-underline='false' editableOn="DblClick" value={value.contacts_id.address_city} model={{ placeholder: Regency_City }} actionSuccess={(e) => actionSuccess(e, "address_city", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Province}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='address_state' data-underline='false' editableOn="DblClick" value={value.contacts_id.address_state} model={{ placeholder: Province }} actionSuccess={(e) => actionSuccess(e, "address_state", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Postal_Code}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='address_post_code' data-underline='false' editableOn="DblClick" value={value.contacts_id.address_post_code} model={{ placeholder: Postal_Code }} actionSuccess={(e) => actionSuccess(e, "address_post_code", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Suburb_Village}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='address_town' data-underline='false' editableOn="DblClick" value={value.contacts_id.address_town} model={{ placeholder: Suburb_Village }} actionSuccess={(e) => actionSuccess(e, "address_town", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{District}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='address_district' data-underline='false' editableOn="DblClick" value={value.contacts_id.address_district} model={{ placeholder: District }} actionSuccess={(e) => actionSuccess(e, "address_district", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Gender}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} type='DropDownList' mode='Inline' name='relation' data-underline='false' editableOn="DblClick" value={value.contacts_id.gender ? getGender(value.contacts_id.gender, "name") : null} model={{ placeholder: Gender, dataSource: gender, fields: { text: 'name', value: 'name' } }} actionSuccess={(e) => actionSuccess(e, "gender", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Marital_Status}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} type='DropDownList' mode='Inline' name='marital_status' data-underline='false' editableOn="DblClick" value={value.contacts_id.marital_status ? getTranslation(value.contacts_id.marital_status, "name", marital_status) : null} model={{ placeholder: Marital_Status, dataSource: marital_status, fields: { text: 'text', value: 'text' } }} actionSuccess={(e) => actionSuccess(e, "marital_status", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Place_of_Birth}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='pob' data-underline='false' editableOn="DblClick" value={value.contacts_id.pob} model={{ placeholder: Place_of_Birth }} actionSuccess={(e) => actionSuccess(e, "pob", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Date_of_Birth}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" mode='Inline' name='dob' data-underline='false' type="Date" editableOn="DblClick" value={value.contacts_id.dob ? value.contacts_id.dob : null} model={{ placeholder: Date_of_Birth, format: "dd-MMM-yyyy" }} actionSuccess={(e) => actionSuccess(e, "dob", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>NIK</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='id_number' data-underline='false' editableOn="DblClick" value={value.contacts_id.id_number} model={{ placeholder: 'NIK' }} actionSuccess={(e) => actionSuccess(e, "id_number", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Nationality}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='nationality' data-underline='false' editableOn="DblClick" value={value.contacts_id.nationality} model={{ placeholder: Nationality }} actionSuccess={(e) => actionSuccess(e, "nationality", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Religion}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} type='DropDownList' mode='Inline' name='religion' data-underline='false' editableOn="DblClick" value={value.contacts_id.religion ? getTranslation(value.contacts_id.religion, "name", religion) : null} model={{ placeholder: Religion, dataSource: religion, fields: { text: 'text', value: 'text' } }} actionSuccess={(e) => actionSuccess(e, "religion", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Everyday_Language}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='home_language' data-underline='false' editableOn="DblClick" value={value.contacts_id.home_language} model={{ placeholder: Everyday_Language }} actionSuccess={(e) => actionSuccess(e, "home_language", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Education_Level}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='education_level' data-underline='false' editableOn="DblClick" value={value.contacts_id.education_level} model={{ placeholder: Education_Level }} actionSuccess={(e) => actionSuccess(e, "education_level", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Occupation}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='occupation' data-underline='false' editableOn="DblClick" value={value.contacts_id.occupation} model={{ placeholder: Occupation }} actionSuccess={(e) => actionSuccess(e, "occupation", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Company_Name}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='workplace_name' data-underline='false' editableOn="DblClick" value={value.contacts_id.workplace_name} model={{ placeholder: Company_Name }} actionSuccess={(e) => actionSuccess(e, "workplace_name", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Field_of_Business}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='workplace_type' data-underline='false' editableOn="DblClick" value={value.contacts_id.workplace_type} model={{ placeholder: Field_of_Business }} actionSuccess={(e) => actionSuccess(e, "workplace_type", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Position}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='workplace_role' data-underline='false' editableOn="DblClick" value={value.contacts_id.workplace_role} model={{ placeholder: Position }} actionSuccess={(e) => actionSuccess(e, "workplace_role", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Monthly_Income}</td>
                                      <td><span style={{ float: "right" }}>
                                        <InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='income' type='Numeric' data-underline='false' editableOn="DblClick" value={value.contacts_id.income} model={{ placeholder: Monthly_Income, format: "IDR ###############" }} actionSuccess={(e) => actionSuccess(e, "income", value)} />
                                      </span></td>
                                    </tr>
                                    <tr>
                                      <td>{Special_Needs}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='special_needs' data-underline='false' editableOn="DblClick" value={value.contacts_id.special_needs} model={{ placeholder: Special_Needs }} actionSuccess={(e) => actionSuccess(e, "special_needs", value)} /></span></td>
                                    </tr>
                                    <tr>
                                      <td>{Notes}</td>
                                      <td><span style={{ float: "right" }}><InPlaceEditorComponent emptyText={'-'} actionOnBlur="Cancel" enableRtl={true} mode='Inline' name='notes' data-underline='false' editableOn="DblClick" value={value.contacts_id.notes} model={{ placeholder: Notes }} actionSuccess={(e) => actionSuccess(e, "notes", value)} /></span></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )) : ''}
                    </div>
                  </div>
                </div>
              </div>
              : null : null}
          </div>

          <DialogComponent
            id="dialogAddContact"
            width="35%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogAddContact}
            header={Student_Parent_Guardian}
            isModal={true}
            close={handleCloseDialog}
            footerTemplate={() => footerTemplate([
              { name: Update, func: addContact, icon: "icon-check-mark-01", status: "e-success" },
              { name: Cancel, func: handleCloseDialog, icon: "icon-treeview", status: "" }
            ])}
          >
            <div>
              <div>
                <b>*{Name}</b>
                <TextBoxComponent
                  name="name"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "name")}
                  value={stateContact.name}
                />
                {error.contact_name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                <br /><br />
                <b>{Mobile_Number}</b>
                <TextBoxComponent
                  name="mobile_number"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "mobile_number")}
                  value={stateContact.mobile_number}
                />
                <br /><br />
                <b>{Phone_Number}</b>
                <TextBoxComponent
                  name="phone_number"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "phone_number")}
                  value={stateContact.phone_number}
                />
                <br /><br />
                <b>{Email}</b>
                <TextBoxComponent
                  name="email"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "email")}
                  value={stateContact.email}
                />
                <br /><br />
                <b>{Occupation}</b>
                <TextBoxComponent
                  name="occupation"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "occupation")}
                  value={stateContact.occupation}
                />
                <br /><br />
                <b>{Gender}</b>
                <DropDownListComponent
                  dataSource={gender}
                  fields={{ text: 'name', value: 'name' }}
                  name="gender"
                  cssClass="e-outline"
                  placeholder={`--${Select}--`}
                  select={(e) => handleChangeContact(e, "gender")}
                  value={stateContact.gender}
                />
                <br /><br />
                <b>{Place_of_Birth}</b>
                <TextBoxComponent
                  name="pob"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "pob")}
                  value={stateContact.pob}
                />
                <br /><br />
                <b>{Date_of_Birth}</b>
                <DatePickerComponent
                  name="date_of_birth"
                  cssClass="e-outline"
                  format="dd-MMM-yyyy"
                  change={(e) => handleChangeContact(e, "dob")}
                  value={stateContact.dob}
                />
                <br /><br />
                <b>{Religion}</b>
                <DropDownListComponent
                  name="religion"
                  cssClass="e-outline"
                  dataSource={religion}
                  fields={{ text: 'text', value: 'text' }}
                  placeholder={`--${Select}--`}
                  select={(e) => handleChangeContact(e, "religion")}
                  value={stateContact.religion}
                />
                <br /><br />
                <b>{Address}</b>
                <TextBoxComponent
                  name="addres"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address")}
                  value={stateContact.address}
                />
                <br /><br />
                <b>RT</b>
                <TextBoxComponent
                  name="address_rt"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_rt")}
                  value={stateContact.address_rt}
                />
                <br /><br />
                <b>RW</b>
                <TextBoxComponent
                  name="address_rw"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_rw")}
                  value={stateContact.address_rw}
                />
                <br /><br />
                <b>{Regency_City}</b>
                <TextBoxComponent
                  name="address_city"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_city")}
                  value={stateContact.address_city}
                />
                <br /><br />
                <b>{Province}</b>
                <TextBoxComponent
                  name="address_state"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_state")}
                  value={stateContact.address_state}
                />
                <br /><br />
                <b>{Postal_Code}</b>
                <TextBoxComponent
                  name="address_post_code"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_post_code")}
                  value={stateContact.address_post_code}
                />
                <br /><br />
                <b>{Suburb_Village}</b>
                <TextBoxComponent
                  name="address_town"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_town")}
                  value={stateContact.address_town}
                />
                <br /><br />
                <b>{District}</b>
                <TextBoxComponent
                  name="address_district"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_district")}
                  value={stateContact.address_district}
                />
                <br /><br />
                <b>{Notes}</b>
                <TextBoxComponent
                  name="notes"
                  cssClass="e-outline"
                  multiline={true}
                  change={(e) => handleChangeContact(e, "notes")}
                  value={stateContact.notes}
                />
              </div>
            </div>

          </DialogComponent>

          <DialogComponent
            id="dialogAddContactRelation"
            width="35%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogAddContactRelation}
            header={Student_Parent_Guardian}
            isModal={true}
            close={handleCloseDialog}
            footerTemplate={() => footerTemplate([
              { name: Update, func: addContactRelation, icon: "icon-check-mark-01", status: "e-success" },
              { name: Cancel, func: handleCloseDialog, icon: "icon-treeview", status: "" }
            ])}
          >
            <div>
              <div>
                <b>{Relation}</b>
                <br /><br />
                <DropDownListComponent
                  dataSource={relation}
                  fields={{ text: 'text', value: 'text' }}
                  name="relation"
                  cssClass="e-outline"
                  placeholder={`--${Select}--`}
                  select={(e) => handleChangeRelation(e, "relation")}
                  value={stateRelation.relation}
                />
                {error.relation === true ? <span style={{ color: 'red' }}>{Please_select_relation}</span> : ''}
                <br /><br />
                <b>{Contact}</b>
                <br /><br />
                <DropDownListComponent
                  name="contact"
                  cssClass="e-outline"
                  dataSource={dataContactRef.current}
                  filtering={onFiltering}
                  allowFiltering={true}
                  fields={fields}
                  placeholder={`--${Select}--`}
                  select={(e) => handleChangeRelation(e, "contact")}
                  value={stateRelation.contact}
                  popupHeight="220px" />
                {error.contact === true ? <span style={{ color: 'red' }}>{Please_select_contact}</span> : ''}
              </div>
            </div>
          </DialogComponent>

          <DialogComponent
            id="dialogDeleteContactRelation"
            width="30%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogDeleteContactRelation}
            header={Delete_Confirmation}
            isModal={true}
            close={handleCloseDialog}
            footerTemplate={() => footerTemplate([
              { name: Delete, func: deleteContactRelation, icon: "icon-delete-05", status: "e-warning" },
              { name: Cancel, func: handleCloseDialog, icon: "icon-treeview", status: "" }
            ])}
          >
            <div>{Are_you_sure_you_want_to_delete_the_data_of_contact} ({selectedData ? selectedData.contacts_id.name : null})?</div>
          </DialogComponent>

          <DialogComponent
            id="dialogAddSibling"
            width="35%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogAddSibling}
            header={Related_Student}
            isModal={true}
            close={handleCloseDialog}
            footerTemplate={() => footerTemplate([
              { name: Update, func: addSibling, icon: "icon-check-mark-01", status: "e-success" },
              { name: Cancel, func: handleCloseDialogAddSibling, icon: "icon-treeview", status: "" }
            ])}
          >
            <div>
              <div>
                <b>{Relation}</b>
                <br /><br />
                <DropDownListComponent
                  dataSource={related_student_sibling}
                  fields={{ text: 'text', value: 'id' }}
                  name="relation"
                  cssClass="e-outline"
                  placeholder={`--${Select}--`}
                  select={(e) => handleChangeSibling(e, "relation")}
                  value={stateRelation.relation}
                />
                {error.relation === true ? <span style={{ color: 'red' }}>{Please_select_relation}</span> : ''}
                <br /><br />
                <b>{Name}</b>
                <br /><br />
                <DropDownListComponent
                  name="name"
                  cssClass="e-outline"
                  dataSource={dataStudentRef.current}
                  filtering={onFilteringStudent}
                  allowFiltering={true}
                  fields={{ text: "nickname", value: "id" }}
                  placeholder={`--${Select}--`}
                  select={(e) => handleChangeSibling(e, "sibling")}
                  value={stateSibling}
                  noRecordsTemplate={No_data_found}
                  popupHeight="220px" />
                {error.sibling === true ? <span style={{ color: 'red' }}>{Please_select_contact}</span> : ''}
              </div>
            </div>
          </DialogComponent>

          <DialogComponent
            id="dialogDeleteSibling"
            width="30%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogDeleteSibling}
            header={Delete_Confirmation}
            isModal={true}
            close={handleCloseDialog}
            footerTemplate={() => footerTemplate([
              { name: Delete, func: deleteSibling, icon: "icon-delete-05", status: "e-warning" },
              { name: Cancel, func: handleCloseDialogDeleteSibling, icon: "icon-treeview", status: "" }
            ])}
          >
            <div>{Are_you_sure_you_want_to_delete_the_data_of_sibling} ({selectedSibling ? selectedSibling.student_2.name : null})?</div>
          </DialogComponent>
        </div>
      }
    </div >
  )
}

export default Contact;