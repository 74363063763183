import React, { useState } from 'react';
import { Link, BrowserRouter } from 'react-router-dom';

import { FaFemale, FaMale } from 'react-icons/fa';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";

import * as link from 'src/constants/link';
import './index.css';
import * as permission from 'src/utils/Permission';

// {/* ================== Student Card Display ========================================== */}

const CardRender = (props) => {
  const [Grade] = useState(i18next.t('Pages.data.Grade'));
  const [Age] = useState(i18next.t('Pages.data.Age'));
  const [Mobile_Number] = useState(i18next.t('Pages.data.Mobile_Number'));
  const [Mothers_Mobile_Number] = useState(i18next.t('Pages.data.Mothers_Mobile_Number'));
  const [Fathers_Mobile_Number] = useState(i18next.t('Pages.data.Fathers_Mobile_Number'));
  const [Created_on] = useState(i18next.t('Pages.data.Created_on'));
  const [years] = useState(i18next.t('Pages.student_data.years'));
  const [months] = useState(i18next.t('Pages.student_data.months'));


  // {/* ================== Card Data ========================================== */ }
  //get student's age
  var age = "-";
  if (props.data.date_of_birth) {
    const birthdate = moment(new Date(props.data.date_of_birth), 'MM-YYYY');
    var date = moment.duration(moment().diff(birthdate));
    age = date.years() + " " + years + " ";
    if (date.months() > 0) {
      age += date.months() + " " + months;
    }
  }

  function getNumber(props, relation) {
    var contactNumber = null;
    var contact = linq.from(props)
      .where(w => w.relation === relation)
      .toArray();

    contactNumber = contact.length > 0 ? contact[0].contacts_id.mobile_number : null;
    return contactNumber ? <span>{contactNumber}</span> : null;
  }

  // {/* ================== Navigation ========================================== */ }
  function btnClick(props) {
    return props.navigate('/student/profile/' + props.data.id);
  }

  // {/* ================== Main Content Card ========================================== */ }

  var fatherNumber = props.data.contact.length > 0 ? getNumber(props.data.contact, "Father") : null;
  var motherNumber = props.data.contact.length > 0 ? getNumber(props.data.contact, "Mother") : null;

  return (
    <div className='e-card' style={{ borderRadius: '20px' }}>
      <div className="header-subject_class"
        style={{ backgroundColor: "#E1EBF2", paddingLeft: "25px", height: "50px" }}>
        <div style={{ float: "right", paddingRight: "10px", paddingTop: "10px" }}>
          <div style={{ width: "30px", height: "30px" }}>
            <img
              style={{
                maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                objectFit: "cover", borderRadius: "50%", position: "center",
              }}
              className="image"
              src={props.data.image ? props.data.image.data.full_url : link.default_image}
              alt={props.data.name}
            />
          </div>
        </div>
        {permission.AdminPermission() ?
          <BrowserRouter>
            <Link className="link-path" to={{ pathname: `/student/profile/${props.data.id}` }} style={{ textDecoration: "none" }}
              onClick={() => btnClick(props)}
            >
              <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", color: "#516573", fontSize: "16px", fontWeight: "800", cursor: "pointer", lineHeight: "50px" }} onClick={(e) => e.preventDefault()}>
                {props.data.name}
              </div>
            </Link>
          </BrowserRouter>
          :
          <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", color: "#516573", fontSize: "16px", fontWeight: "800", cursor: "pointer", lineHeight: "50px" }}>
            {props.data.name}
          </div>
        }
      </div>

      <div className='e-card-content' style={{ minHeight: "auto", fontSize: "12px", lineHeight: "1.5", background: "#F1F4FA", height: "140px", position: "relative" }}>
        <span className='e-card-text' style={{ float: "right", fontSize: "20px", marginRight: "-10px" }}>{props.data.gender === "Female" ? <FaFemale style={{ color: "hotpink" }} /> : props.data.gender === "Male" ? <FaMale style={{ color: "dodgerblue" }} /> : ""}</span>
        {props.data.date_of_birth ? <div><span className='e-card-text'>{Age} : {age} </span></div> : ''}
        {props.data.current_school_level ? <div><span className='e-card-text'>{Grade} : {props.data.current_school_level} </span></div> : ''}
        {props.data.mobile_number ? <div><span className='e-card-text'>{Mobile_Number} : {props.data.mobile_number} </span></div> : ''}
        {motherNumber ? <div><span className='e-card-text'>{Mothers_Mobile_Number} : {motherNumber} </span></div> : ''}
        {fatherNumber ? <div><span className='e-card-text'>{Fathers_Mobile_Number} : {fatherNumber} </span></div> : ''}
      </div>

      <div className='e-card-content' style={{ minHeight: "auto", fontSize: "12px", lineHeight: "1.5", background: "#E1EBF2", height: "30px", position: "relative" }}>
        <span style={{ right: "25px", height: "20px", fontSize: "10px", position: "absolute", bottom: "0" }}>
          {Created_on}: {moment(new Date(props.data.created_on)).format("DD MMM yyyy")}
        </span>
      </div>

    </div>
  )
}

export default CardRender;