import React, { useState, useEffect } from 'react';
import useStateRef from "react-usestateref";
import { useParams } from 'react-router-dom';
import EventEmitter from 'src/utils/EventEmitter';
import i18next from "i18next";

import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import CircularProgress from '@material-ui/core/CircularProgress';

import directus from 'src/services/directus';
import './index.css';

import Configure from './configure';
import Topic from './topic';

const SubjectView = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();

  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));

  const [Students] = useState(i18next.t('Pages.data.Students'));
  const [Teachers] = useState(i18next.t('Pages.data.Teachers'));
  const [Classes] = useState(i18next.t('Pages.data.Classes'));
  const [Devices] = useState(i18next.t('Pages.data.Devices'));

  const [Subject] = useState(i18next.t('Pages.subject_class_data.Subject'));
  const [Topic_2] = useState(i18next.t('Pages.subject_class_data.Topic'));
  const [Settings] = useState(i18next.t('Pages.subject_class_data.Settings'));


  const [data, setData] = useState(null);

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);

  const headerText = [{ text: Settings }, { text: Topic_2 }];

  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    name: null
  });
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }


  const getData = async () => {
    try {
      var name = { "nnull": null };

      if (stateFiltering.name !== null && stateFiltering.name !== "") {
        name = { "contains": stateFilteringRef.current.name };
      }

      var result = await directus.getItems('subject_classes',
        {
          fields: "*.*.*",
          filter: {
            name: name,
            status: "published",
            subjects: id
          },
          sort: "name"
        });

      setData(result.data);
      setLoading(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  function totalStudents(props) {
    var total = 0;
    var students_id = [];

    if (props) {
      /* eslint-disable array-callback-return*/
      props.map((value) => {
        if (value.students.length > 0) {
          value.students.map((value2) => {
            if (students_id.indexOf(value2.students_id.id) === -1) {
              students_id.push(value2.students_id.id);
              total += 1;
            }
          })
        }
      })
      /* eslint-enable array-callback-return*/
    }
    return (<span>{total}</span>)
  }


  function totalTeachers(props) {
    var total = 0;
    var teachers_id = [];

    if (props) {
      /* eslint-disable array-callback-return*/
      props.map((value) => {
        if (value.meetings.length > 0) {
          value.meetings.map((value2) => {
            if (teachers_id.indexOf(value2.meetings_id.teacher) === -1) {
              teachers_id.push(value2.meetings_id.teacher);
              total += 1;
            }
          })
        }
      })
      /* eslint-enable array-callback-return*/
    }
    return (<span>{total}</span>)
  }


  // {/* ================== Tab Component Display ========================================== */ }

  const contentConfigure = () => {
    return (<Configure />)
  }

  const contentTopic = () => {
    return (<Topic />)
  }

  // {/* ================== Main Content ========================================== */ }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          {data ?
            <div>
              <div className="header">
                <div className="content-header">{Subject}</div>
              </div>
              <div className="content-main" style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ width: "25%" }}>
                    <div style={{ minHeight: "90px", backgroundColor: "white" }}>
                      <span className="e-icons icon-MT_Resource info-box-icon" style={{ backgroundColor: '#00c0ef', color: 'white' }}></span>
                      <div className="info-box-content">
                        <span className="info-box-text">{Students}</span>
                        <span className="info-box-number">{totalStudents(data)}</span>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "25%" }}>
                    <div style={{ minHeight: "90px", backgroundColor: "white" }}>
                      <span className="e-icons icon-MT_Resource info-box-icon" style={{ backgroundColor: '#dd4b39', color: 'white' }}></span>
                      <div className="info-box-content">
                        <span className="info-box-text">{Teachers}</span>
                        <span className="info-box-number">{totalTeachers(data)}</span>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "25%" }}>
                    <div style={{ minHeight: "90px", backgroundColor: "white" }}>
                      <span className="e-icons icon-MT_Description info-box-icon" style={{ backgroundColor: '#00a65a', color: 'white' }}></span>
                      <div className="info-box-content">
                        <span className="info-box-text">{Classes}</span>
                        <span className="info-box-number">{data ? data.length : 0}</span>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "25%" }}>
                    <div style={{ minHeight: "90px", backgroundColor: "white" }}>
                      <span className="e-icons icon-M_PV_Thumbnail info-box-icon" style={{ backgroundColor: '#f39c12', color: 'white' }}></span>
                      <div className="info-box-content">
                        <span className="info-box-text">{Devices}</span>
                        <span className="info-box-number"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className='control-section' style={{ minHeight: "500px", backgroundColor: "white", display: "block" }}>
                  <TabComponent className="e-fill" heightAdjustMode='Content' enablePersistence={true}>
                    <TabItemsDirective>
                      <TabItemDirective header={headerText[0]} content={contentConfigure} />
                      <TabItemDirective header={headerText[1]} content={contentTopic} />
                    </TabItemsDirective>
                  </TabComponent>
                </div>
              </div>
            </div>
            : null}
        </div>
      }
    </div>
  );
}

export default SubjectView;