import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { FaFemale, FaMale } from 'react-icons/fa';

import i18next from "i18next";
import moment from 'moment';

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as link from 'src/constants/link';

import './index.css';

import Biodata from './biodata';
import Contact from './contact';
import StudentSchedule from './schedule';
import ClassSummary from './class_summary';
import Notes from './notes';

const StudentProfile = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();
  const [studentId, setStudentId] = useState(id);
  const navigate = useNavigate();

  const [Profile_updated_successfully] = useState(i18next.t('Pages.profile.Profile_updated_successfully'));
  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));
  const [Edit] = useState(i18next.t('Pages.button.Edit'));

  const [Please_match_the_requested_format] = useState(i18next.t('Pages.general.Please_match_the_requested_format'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  const [Gender] = useState(i18next.t('Pages.data.Gender'));
  const [Age] = useState(i18next.t('Pages.data.Age'));
  const [Mobile_Number] = useState(i18next.t('Pages.data.Mobile_Number'));
  const [Please_upload_the_photo] = useState(i18next.t('Pages.data.Please_upload_the_photo'));
  const [Change_Profile_Photo] = useState(i18next.t('Pages.data.Change_Profile_Photo'));
  const [Student_Notes] = useState(i18next.t('Pages.data.Notes'));

  const [Student_deleted_successfully] = useState(i18next.t('Pages.student_data.Student_deleted_successfully'));
  const [Are_you_sure_you_want_to_delete_the_data_of_student] = useState(i18next.t('Pages.student_data.Are_you_sure_you_want_to_delete_the_data_of_student'));
  const [All_invoice_data_payments_schedules_and_attendance_records_will_be_deleted_also] = useState(i18next.t('Pages.student_data.All_invoice_data_payments_schedules_and_attendance_records_will_be_deleted_also'));
  const [If_you_want_to_delete_the_student_fill_in_the_name_below] = useState(i18next.t('Pages.student_data.If_you_want_to_delete_the_student_fill_in_the_name_below'));
  const [Student_Profile] = useState(i18next.t('Pages.student_data.Student_Profile'));
  const [Number_of_Subjects] = useState(i18next.t('Pages.student_data.Number_of_Subjects'));
  const [Student_Parent_Guardian] = useState(i18next.t('Pages.student_data.Student_Parent_Guardian'));
  const [Delete_Student] = useState(i18next.t('Pages.student_data.Delete_Student'));
  const [Schedule] = useState(i18next.t('Pages.student_data.Schedule'));
  const [Class_Summary] = useState(i18next.t('Pages.student_data.Class_Summary'));
  const [Relationship] = useState(i18next.t('Pages.student_data.Relationship'));

  const [Father] = useState(i18next.t('Pages.contact_data.Father'));
  const [Mother] = useState(i18next.t('Pages.contact_data.Mother'));
  const [Guardian] = useState(i18next.t('Pages.contact_data.Guardian'));
  const [Friend] = useState(i18next.t('Pages.contact_data.Friend'));
  const [Uncle] = useState(i18next.t('Pages.contact_data.Uncle'));
  const [Aunt] = useState(i18next.t('Pages.contact_data.Aunt'));
  const [Grandfather] = useState(i18next.t('Pages.contact_data.Grandfather'));
  const [Grandmother] = useState(i18next.t('Pages.contact_data.Grandmother'));
  const [Cousin] = useState(i18next.t('Pages.contact_data.Cousin'));
  const [Sibling] = useState(i18next.t('Pages.contact_data.Sibling'));

  //set data
  const [data, setData] = useState(null);
  const [dataStudentSubjectClass, setDataStudentSubjectClass] = useState(null);

  //set initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogChangeProfile = useRef(false);
  let dialogDeleteStudent = useRef(false);

  //delete student setting
  const [deleteWord, setDeleteWord] = useState(null);
  const [error, setError, errorRef] = useStateRef({ image: false });

  //upload image
  let my_form2 = useRef(null);
  let uploadObj = useRef(null);
  const [image, setImage] = useState(null);
  const [oldImage, setOldImage] = useState(null);
  const asyncSettings = { saveUrl: link.saveUrl, removeUrl: link.removeUrl };

  //other component setting
  const headerText = [{ text: "Biodata" }, { text: Relationship }, { text: Schedule }, { text: Class_Summary }, { text: Student_Notes }];
  const relation = [
    { id: 'Father', text: Father },
    { id: 'Mother', text: Mother },
    { id: 'Guardian', text: Guardian },
    { id: 'Friend', text: Friend },
    { id: 'Uncle', text: Uncle },
    { id: 'Aunt', text: Aunt },
    { id: 'Grandfather', text: Grandfather },
    { id: 'Grandmother', text: Grandmother },
    { id: 'Cousin', text: Cousin },
    { id: 'Sibling', text: Sibling },
  ];

  //set state change profile
  const [state, setState] = useState({ image: null })
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getStudent();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })

  useEffect(() => {
    const reloadData = () => {
      getStudent();
    }

    EventEmitter.addListener('reloadStudentData', reloadData);
  })

  useEffect(() => {
    const clickStudent = (props) => {
      if (props.update === true) {
        setStudentId(props.new_id);
        setInitialized(false);
      }
    }
    const listener = EventEmitter.addListener('click_student', clickStudent);
    return () => { listener.remove(); }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "change_profile") {
        dialogChangeProfile.current.visible = false;
        setState({ image: null });
        uploadObj.current.clearAll();
        EventEmitter.emit('alert_toast', { content: Profile_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_student") {
        dialogDeleteStudent.current.visible = false;
        setDeleteWord('');
        EventEmitter.emit('alert_toast', { content: Student_deleted_successfully, type: "success" });
        setDataButton(null);
        return navigate('/student/list');
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get student data
  const getStudent = async () => {
    try {
      var result = await directus.getItems('students',
        {
          fields: '*,contact.*.*,image.*.*',
          filter: {
            id: studentId,
            status: "published"
          }
        }
      );

      setData(result.data[0]);
      var img_student = result.data[0].image ? result.data[0].image.data.full_url : link.default_image;
      setImage(img_student);
      setOldImage(img_student);
      getStudentSubjectClass();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of subject class
  const getStudentSubjectClass = async () => {
    try {
      var result = await directus.getItems('students_subject_classes',
        {
          fields: "id",
          filter: {
            students_id: studentId
          },
          meta: "filter_count"
        }
      );

      setDataStudentSubjectClass(result.meta.filter_count);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }


  // {/* ================== Handle Students ========================================== */ }
  const handleOpenDialogDeleteStudent = () => {
    dialogDeleteStudent.current.visible = true;
  }

  const handleCloseDialogDeleteStudent = () => {
    dialogDeleteStudent.current.visible = false;
    setDeleteWord('');
  }

  const handleChangeWord = (e) => {
    setDeleteWord(e.value);
  }

  const deleteStudent = async () => {
    if (deleteWord === data.name) {
      try {
        setLoadingButton(true);
        var result = await directus.updateItem('students', studentId, { status: 'draft' });
        if (!result.error) {
          const body_activity = {
            action: "Delete",
            category: 2,
            description: data.name,
            status: "published"
          }
          await directus.createItem('activity_log', body_activity);
          setLoadingButton(false);
          setDataButton("delete_student");
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        setLoadingButton(false);
      }

    } else if (deleteWord !== data.name) {
      EventEmitter.emit('alert_toast', { content: Please_match_the_requested_format, type: "danger" });
    }
  }

  // {/* ================== Handle Upload File ========================================== */ }

  const uploadFile = async (args) => {
    if (args.operation === "upload") {
      const data = new FormData(my_form2.current);
      try {
        var result = await directus.uploadFiles(data, onUploadProgress);
        const imageId = result.data.id;
        setImage(result.data.data.full_url);
        setState({ ...state, image: imageId });
        setError({ ...error, image: false });
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      }
    } else if (args.operation === "remove") {
      cancelFile();
    }
  }


  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + "% Done");
  }

  const cancelFile = () => {
    setState({ ...state, image: null })
    setImage(oldImage);
    setError({ image: false })
  }


  // {/* ================== Handle Change Profile Dialog ========================================== */ }

  const handleOpenDialogChangeProfile = () => {
    dialogChangeProfile.current.visible = true;
  }

  const handleCloseDialogChangeProfile = () => {
    dialogChangeProfile.current.visible = false;
    uploadObj.current.clearAll();
    cancelFile();
  }

  const changeProfile = async () => {
    setError({ image: false })
    if (state.image !== null) {
      try {
        setLoadingButton(true);
        var result = await directus.updateItem('students', studentId, state);

        const body_activity = {
          action: "Update",
          category: 2,
          description: data.name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);
        if (!result.error) {
          getStudent();
          setDataButton("change_profile");
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        setLoadingButton(false);
      }
    } else {
      setError({ ...errorRef.current, image: true })
    }
  }
  // {/* ================== Tab Component Display ========================================== */ }

  const contentBiodata = () => {
    return (<div><Biodata /></div>)
  }

  const contentContact = () => {
    return (<div><Contact /></div>)
  }

  const contentSchedule = () => {
    return (<div><StudentSchedule /></div>)
  }

  const contentClassSummary = () => {
    return (<div><ClassSummary /></div>)
  }

  const contentNotes = () => {
    return (<div><Notes /></div>)
  }

  // {/* ================== Other Setting ========================================== */ }
  //get student's age
  var age = '';
  if (data) {
    if (data.date_of_birth) {
      const birthdate = moment(new Date(data.date_of_birth), 'MM-YYYY');
      const now = moment();
      var date = moment.duration(now.diff(birthdate));
      age = date.years();
    }
  }


  const getRelation = (props) => {
    var relation2 = props ? props : null;
    if (relation) {
      relation.forEach((value) => {
        if (value.id === relation2) {
          relation2 = value.text
        }
      })
    }
    return "" + relation2
  }

  // {/* ================== Footer Template ========================================== */ }
  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }

  return loading ?
    <div><CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} /></div>
    :
    data ?
      <div>
        <div className="header">
          <div className="content-header">{Student_Profile}</div>
        </div>
        <div className="content-main" style={{ marginTop: "30px" }}>
          <div className="col-md-3">
            <div id="student_profile" style={{
              color: "#333", backgroundColor: "#fff",
              boxShadow: "0 2px 2px rgb(0 0 0 / 10%)",
              padding: "10px",
              borderTop: "5px solid #3c8dbc",
              borderRadius: "3px"
            }}>
              <div id="student_profile_info" style={{ paddingTop: "20px" }}>
                <div id="student_biodata_1" style={{ textAlign: "center" }}>
                  <div id="student_image" >
                    <div className="container2" style={{ display: "flex", justifyContent: "center" }}>
                      <div className="image" style={{ width: "150px", height: "150px" }}>
                        <img
                          style={{
                            maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                            objectFit: "cover", border: "3px solid #d2d6de", borderRadius: "50%", position: "center",
                          }}
                          src={data.image ? data.image.data.full_url : link.default_image}
                          alt={data.name}
                        />
                      </div>

                      <div className="edit-middle">
                        <ButtonComponent
                          iconCss="e-icons icon-edit-icon1"
                          onClick={() => handleOpenDialogChangeProfile()}
                          style={{ fontSize: "12px" }}
                        >{Edit}</ButtonComponent>
                      </div>
                    </div>
                    <div style={{ wordWrap: "break-word" }}>
                      <h4>
                        {data.name}
                      </h4>
                      <p>{data.nickname ? data.nickname : '-'}</p>
                    </div>
                  </div>
                </div>
                <div id="student_biodata_2" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td>{Gender}</td>

                        {/* <td style={{ textAlign: "right" }}>{data.gender}</td> */}
                        <td style={{ textAlign: "right" }}>{data.gender === "Female" ? <FaFemale style={{ color: "hotpink" }} /> : data.gender === "Male" ? <FaMale style={{ color: "dodgerblue" }} /> : ""}</td>
                      </tr>
                      <tr>
                        <td>{Age}</td>
                        <td style={{ textAlign: "right" }}>{age}</td>
                      </tr>
                      <tr>
                        <td>{Number_of_Subjects}</td>
                        <td style={{ textAlign: "right" }}>{dataStudentSubjectClass}</td>
                      </tr>
                      <tr>
                        <td>{Mobile_Number}</td>
                        <td style={{ textAlign: "right" }}>{data.mobile_number}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <br />
            <div id="student_contact" style={{
              color: "#333", backgroundColor: "#fff",
              boxShadow: "0 2px 2px rgb(0 0 0 / 10%)",
              padding: "10px",
              borderTop: "5px solid #3c8dbc",
              borderRadius: "3px"
            }}>
              <h4>{Student_Parent_Guardian}</h4>
              <div id="student_contact_table" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                {data.contact ?
                  <table width="100%">
                    <tbody>
                      {data.contact.map((value, index) => (
                        value.contacts_id.status === "published" ?
                          <tr key={index}>
                            <td>{value.contacts_id.name} ({getRelation(value.relation)})</td>
                            <td style={{ textAlign: "right" }}>{value.contacts_id.mobile_number ? value.contacts_id.mobile_number : '-'}</td>
                          </tr>
                          : null
                      ))}

                    </tbody>
                  </table>
                  : ''}
              </div>
            </div>
            <br />
            <div id="student_" style={{
              color: "#333", backgroundColor: "#fff",
              boxShadow: "0 2px 2px rgb(0 0 0 / 10%)",
              padding: "10px",
              borderTop: "5px solid #3c8dbc",
              borderRadius: "3px"
            }}>
              <div style={{ padding: "5px 0 5px 0" }}>
                <ButtonComponent className="btn-custom delete" style={{ width: "100%" }} iconCss='e-icons icon-delete-05'
                  onClick={() => handleOpenDialogDeleteStudent()}
                >
                  <span className="btn-text-custom delete" style={{ color: "red" }}>{Delete_Student}</span>
                </ButtonComponent>
              </div>
            </div>

            <DialogComponent
              id="dialogChangeProfile"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogChangeProfile}
              header={Change_Profile_Photo}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Update, func: changeProfile, icon: "icon-check-mark-01", status: "e-success" },
                { name: Cancel, func: handleCloseDialogChangeProfile, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogChangeProfile}
            >
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="image" style={{ width: "150px", height: "150px" }}>
                    <img
                      style={{
                        maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                        objectFit: "cover", position: "center",
                      }}
                      src={image}
                      alt="change_profile"
                    />
                  </div>
                </div>
                <br />
                <form ref={my_form2}>
                  <UploaderComponent
                    id='previewfileupload'
                    type='file'
                    ref={uploadObj}
                    asyncSettings={asyncSettings}
                    success={uploadFile}
                    multiple={false}
                    autoUpload={false}
                    clearing={cancelFile}
                  />
                  {error.image === true ? <span style={{ color: 'red' }}>{Please_upload_the_photo}</span> : ''}
                </form>
              </div>
            </DialogComponent>
          </div>

          <div className="col-md-9" style={{ paddingBottom: "20px", paddingLeft: "30px" }}>
            <div className='control-section' style={{ minHeight: "600px", backgroundColor: "white", display: "block", boxShadow: "rgb(0 0 0 / 10%) 0px 2px 2px" }}>
              {data ?
                <TabComponent className="e-fill" heightAdjustMode='None' enablePersistence={true}>
                  <TabItemsDirective>
                    <TabItemDirective header={headerText[0]} content={contentBiodata} />
                    <TabItemDirective header={headerText[1]} content={contentContact} />
                    <TabItemDirective header={headerText[2]} content={contentSchedule} />
                    <TabItemDirective header={headerText[3]} content={contentClassSummary} />
                    <TabItemDirective header={headerText[4]} content={contentNotes} />
                  </TabItemsDirective>
                </TabComponent>
                : <div></div>}
            </div>
          </div>

          <DialogComponent
            id="dialogDeleteStudent"
            width='35%'
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogDeleteStudent}
            header={Delete_Confirmation}
            isModal={true}
            footerTemplate={() => footerTemplate([
              { name: Delete, func: deleteStudent, icon: "icon-delete-05", status: "e-warning" },
              { name: Cancel, func: handleCloseDialogDeleteStudent, icon: "icon-treeview", status: "" }
            ])}
            close={handleCloseDialogDeleteStudent}
          >
            <div id="contentDialogStudent" style={{ fontSize: "14px" }}>
              {Are_you_sure_you_want_to_delete_the_data_of_student} ({data.name ? data.name : ''})?
              <br /><br />
              <p style={{ color: "red" }}>
                {All_invoice_data_payments_schedules_and_attendance_records_will_be_deleted_also}!
                {If_you_want_to_delete_the_student_fill_in_the_name_below}:
              </p>
              <TextBoxComponent
                cssClass="e-outline"
                name="delete_student"
                change={(e) => handleChangeWord(e)}
                value={deleteWord}
              />
            </div>
          </DialogComponent>
        </div>
      </div>
      : ''
    ;
}

export default StudentProfile;