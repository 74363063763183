import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { MultiSelectComponent, CheckBoxSelection, Inject as Inject2 } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";
import Enumerable from "linq";
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import ImageModule from 'docxtemplater-image-module-free';
import { PDFDocument } from 'pdf-lib';

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

import './index.css';

const ClassSummary = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();

  const [Preview] = useState(i18next.t('Pages.button.Preview'));
  const [Download] = useState(i18next.t('Pages.button.Download'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));

  const [Select_Class] = useState(i18next.t('Pages.data.Select_Class'));

  const [Class_Name] = useState(i18next.t('Pages.schedule_data.Class_Name'));
  const [Mid_Test] = useState(i18next.t('Pages.result_data.Mid_Test'));
  const [Final_Test] = useState(i18next.t('Pages.result_data.Final_Test'));
  const [Participation] = useState(i18next.t('Pages.result_data.Participation'));
  const [Finish_Date] = useState(i18next.t('Pages.result_data.Finish_Date'));
  const [Download_Certificate] = useState(i18next.t('Pages.result_data.Download_Certificate'));
  const [The_number_of_classes_selected_must_be_3] = useState(i18next.t('Pages.result_data.The_number_of_classes_selected_must_be_3'));
  const [Certificate_downloaded_successfully] = useState(i18next.t('Pages.result_data.Certificate_downloaded_successfully'));
  const [File_name] = useState(i18next.t('Pages.result_data.File_name'));
  const [Certificate] = useState(i18next.t('Pages.result_data.Certificate'));

  const [Teacher] = useState(i18next.t('Pages.subject_class_data.Teacher'));

  //data
  const [data, setData] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogDownloadCertificate = useRef(false);
  let tooltip = useRef(false);

  //certificate setting
  let mulObj = useRef(false);
  const [selectedData, setSelectedData] = useState(null);
  const [certificateNumber, setCertificateNumber] = useState(null);
  const [order, setOrder] = useState({ TK: null, SD: null, SMP: null, SMA: null });

  //grid setting
  const grid = useRef();
  // const filterOptions = { type: 'Menu' };
  const setting = { mode: 'Both' };
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' };
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoadingNotes(true);
      getLatestCertificate();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })

  useEffect(async () => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "download_certificate") {
        dialogDownloadCertificate.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Certificate_downloaded_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get list of the latest certificates and 
  const getLatestCertificate = async () => {
    try {
      //get the latest certificate data in TK level in this year
      var result_TK = await directus.getItems('certificates',
        {
          fields: '*.*', sort: "-order", limit: 1,
          filter: {
            "level": "TK", "status": "published",
            "created_on": { 'gte': moment().startOf('year').format("YYYY-MM-DD") }
          }
        }
      );

      //get the latest certificate data in SD level in this year
      var result_SD = await directus.getItems('certificates',
        {
          fields: '*.*', sort: "-order", limit: 1,
          filter: {
            "level": "SD", "status": "published",
            "created_on": { 'gte': moment().startOf('year').format("YYYY-MM-DD") }
          }
        }
      );

      //get the latest certificate data in SMP level in this year
      var result_SMP = await directus.getItems('certificates',
        {
          fields: '*.*', sort: "-order", limit: 1,
          filter: {
            "level": "SMP", "status": "published",
            "created_on": { 'gte': moment().startOf('year').format("YYYY-MM-DD") }
          }
        }
      );

      //get the latest certificate data in SMA level in this year
      var result_SMA = await directus.getItems('certificates',
        {
          fields: '*.*', sort: "-order", limit: 1,
          filter: {
            "level": "SMA", "status": "published",
            "created_on": { 'gte': moment().startOf('year').format("YYYY-MM-DD") }
          }
        }
      );

      //save all order of each level
      setOrder({
        TK: result_TK.data.length > 0 ? result_TK.data[0].order : null,
        SD: result_SD.data.length > 0 ? result_SD.data[0].order : null,
        SMP: result_SMP.data.length > 0 ? result_SMP.data[0].order : null,
        SMA: result_SMA.data.length > 0 ? result_SMA.data[0].order : null,
      })
      getResult();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of student result
  const getResult = async () => {
    try {
      var results = await directus.getItems('results',
        {
          fields: '*,subject_class.*',
          filter: {
            student: id,
            "subject_class.status": "published"
          }
        }
      );


      var result_data = results.data;
      getSchedule(result_data);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of the latest schedule data for each class that student enter
  const getSchedule = async (result_data) => {
    try {
      if (result_data.length > 0) {
        var subject_classes_id = linq.from(result_data)
          .select(data => data.subject_class.id)
          .toArray();

        //get all class schedules entered by student
        var result = await directus.getItems('schedules',
          {
            fields: 'id,subject_classes_id.subjects.*,subject_classes_id.*,teachers_id.*,teachers_id.signature.*',
            filter: {
              "subject_classes_id": { 'in': subject_classes_id }
            }
          }
        );

        //group all schedule based on subject class
        var data_schedule = result.data;
        data_schedule = linq.from(data_schedule)
          .groupBy(x => x.subject_classes_id.id,
            element => element,
            (subject_classes_id, schedules) => ({ subject_classes_id, schedules: schedules.toArray() })
          )
          .toArray();


        //combine all student results with related schedules
        var new_result = Enumerable.from(result_data)
          .groupJoin(Enumerable.from(data_schedule),
            pk => pk.subject_class.id,
            fk => fk.subject_classes_id,
            (left, right) => ({ ...left, right }))
          .selectMany(m => m.right.defaultIfEmpty(),
            (left, right) => ({ ...left, ...right }))
          .orderByDescending(x => x.id)
          .toArray();
      }

      setData(new_result);
      setLoadingNotes(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }
  // {/* ================== Data Grid Template ========================================== */ }
  const finishDateTemplate = (props) => {
    var finish_date = getFinishDate(props);
    return (
      <div>{moment(finish_date, "MMMM, Do YYYY").format("DD-MMM-YYYY")}</div>
    )
  }

  const certificateTemplate = (props) => {
    var certificate_number = null;
    if (props.certificate) {
      certificate_number = "No. CRF/ " + props.certificate.level + "/ " + moment(props.certificate.created_on).format("YYYY") + "/ " + props.certificate.order
    }
    return (
      <div style={{ backgroundColor: props.certificate ? "lightgreen" : null }}>{certificate_number}</div>
    )
  }

  const teacherTemplate = (props) => {
    var student_schedule = props.schedules;
    const lastSchedule = linq.from(student_schedule)
      .maxBy(s => new Date(s.date));
    return (
      <div>{lastSchedule.teachers_id.nickname ? lastSchedule.teachers_id.nickname : lastSchedule.teachers_id.name}</div>
    )
  }

  const classTemplate = (props) => {
    return (
      <div>
        <Link to={{ pathname: `/subject_class/detail/${props.subject_class.id}` }} style={{ textDecoration: "none" }}>
          <span style={{ cursor: "pointer", color: '#3c8dbc' }}>
            {props.subject_class.name}
          </span>
        </Link>
      </div>
    )
  }

  // {/* ================== Data Grid Action (edit) ========================================== */ }
  const actionGrid = async (event) => {
    if (event.action === "edit") {
      try {
        const data = event.data;
        const body = {
          exam_mid: data.exam_mid ? data.exam_mid : 0,
          exam_end: data.exam_end ? data.exam_end : 0,
          participation: data.participation ? data.participation : 0
        }

        var result = await directus.updateItem('results', data.id, body);
        if (result.data) {
          getLatestCertificate();
          EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      }
    }
  }

  // {/* ================== Handle Certificate Data ========================================== */ }
  //get finish date of each class
  const getFinishDate = (props) => {
    var student_schedule = props.schedules;
    const lastSchedule = linq.from(student_schedule)
      .maxBy(s => new Date(s.date));
    return moment(lastSchedule.date).format("MMMM, Do YYYY");
  }

  //get the total results of each class and convert to letter grades
  const getTotal = (props) => {
    var total = 0;
    var grade = null;

    //check if student took the mid exam and get the total results
    if (props.exam_mid !== 0) {
      total = (props.exam_mid * 0.3) + (props.exam_end * 0.5) + (props.participation * 0.2);
    } else {
      total = (props.exam_end * 0.7) + (props.participation * 0.3);
    }

    //convert the total results to letter grades
    if (total >= 85) { grade = "A"; }
    else if (total >= 61) { grade = "B"; }
    else if (total >= 50) { grade = "C"; }
    else if (total >= 45) { grade = "D"; }
    else { grade = "E" }

    if (total === 0) { grade = "-" }

    return grade;
  }

  //get all certificate data
  const getCertificateData = async () => {
    //get result data based on selected data in multi select component
    var result = Enumerable.from(data)
      .where(w =>
        Enumerable.from(mulObj.current.value).contains(w.id))
      .orderBy(o => o.id)
      .toArray();

    var student_schedule = result[2].schedules;
    //get the latest schedule from class
    const lastSchedule = linq.from(student_schedule)
      .maxBy(s => new Date(s.date));

    //get level in the last class attended
    var level = result[2].subject_class.subjects.level ? result[2].subject_class.subjects.level : null;

    //get the order in which certificates are made
    var dataOrder = await getCerticateOrder(level);

    //split all subject names
    var NU_1 = result[0].subject_class.subjects.name;
    var array_1 = NU_1.split(" ");

    var NU_2 = result[1].subject_class.subjects.name;
    var array_2 = NU_2.split(" ");

    var NU_3 = result[2].subject_class.subjects.name;
    var array_3 = NU_3.split(" ");

    //abbreviate all subject names
    var sameWord_12 = '';
    var position_12 = 0;
    for (var i = 0; i < array_1.length; i++) {
      if (i < array_2.length) {
        if (array_1[i] === array_2[i]) {
          sameWord_12 += array_1[i];
          position_12 += 1;
        } else {
          break;
        }
      }
    }

    var sameWord_23 = '';
    var position_23 = 0;
    for (var k = 0; k < array_2.length; k++) {
      if (k < array_3.length) {
        if (array_2[k] === array_3[k]) {
          sameWord_23 += array_2[k];
          position_23 += 1;
        } else {
          break;
        }
      }
    }

    var newNU_1 = NU_1;
    var newNU_2 = '';
    var newNU_3 = '';

    if (sameWord_12 === sameWord_23) {
      for (var l = position_12; l < array_2.length; l++) {
        newNU_2 += array_2[l];
        if (l !== array_2.length - 1) {
          newNU_2 += ' ';
        }
      }
      //eslint-disable-next-line no-redeclare
      for (var k = position_23; k < array_3.length; k++) {
        newNU_3 += array_3[k];
        if (k !== array_2.length - 1) {
          newNU_3 += ' ';
        }
      }
    } else {
      if (position_12 !== 0) {
        //eslint-disable-next-line no-redeclare
        for (var l = position_12; l < array_2.length; l++) {
          newNU_2 += array_2[l];
          if (l !== array_2.length - 1) {
            newNU_2 += ' ';
          }
        }
      } else {
        newNU_2 = NU_2;
      }

      if (position_23 !== 0) {
        for (var m = position_23; m < array_3.length; m++) {
          newNU_3 += array_3[m];
          if (m !== array_2.length - 1) {
            newNU_3 += ' ';
          }
        }
      } else {
        newNU_3 = NU_3;
      }
    }

    //get the data of the last teacher teaching in class
    var teacher_name = lastSchedule.teachers_id.name;
    if (lastSchedule.teachers_id.degree) {
      teacher_name += ", " + lastSchedule.teachers_id.degree;
    }

    const dataCertificate = {
      lastSchedule: lastSchedule,
      result: result,
      NU_1: NU_1,
      NU_2: NU_2,
      NU_3: NU_3,
      newNU_1: newNU_1,
      newNU_2: newNU_2,
      newNU_3: newNU_3,
      new_order: dataOrder.new_order,
      teacher_name: teacher_name
    }

    return dataCertificate;
  }

  //get the order of certificates by level in the last class attended
  const getCerticateOrder = (level) => {
    var order_level = 0;
    if (level) {
      if (level === "TK") { order_level = order.TK }
      else if (level === "SD") { order_level = order.SD }
      else if (level === "SMP") { order_level = order.SMP }
      else if (level === "SMA") { order_level = order.SMA }
    }

    var new_order = null;
    if (order_level) {
      new_order = order_level + 1;
      if (new_order < 10) { new_order = "00" + new_order }
      else if (new_order < 100) { new_order = "0" + new_order }
      else if (new_order >= 100) { new_order = "" + new_order }
    } else {
      new_order = "001";
    }

    const dataOrder = {
      order_level: order_level,
      new_order: new_order
    }

    return dataOrder;
  }

  //display certificate number in certificate download dialog based on 3 selected class
  const onSelectData = async (e) => {
    if (e.value.length === 3) {

      var result = Enumerable.from(data)
        .where(w =>
          Enumerable.from(e.value).contains(w.id))
        .orderBy(o => o.id)
        .toArray();

      var level = result[2].subject_class.subjects.level ? result[2].subject_class.subjects.level : null;
      var dataOrder = await getCerticateOrder(level);
      setCertificateNumber("NO. CRF/ " + level + "/ " + moment().format("YYYY") + "/ " + dataOrder.new_order)
    }
  }

  // {/* ================== Handle Update Certificate ========================================== */ }

  const handleOpenDialogDownloadCertificate = async () => {
    //select the last 3 classes and save into multi select component value
    const selectLastThree = [];
    if (data.length >= 3) {
      selectLastThree.push(data[2].id, data[1].id, data[0].id)

      var level = data[0].subject_class.subjects.level ? data[0].subject_class.subjects.level : null;
      var dataOrder = await getCerticateOrder(level);

      setCertificateNumber("No. CRF/ " + level + "/ " + moment().format("YYYY") + "/ " + dataOrder.new_order)
    }

    mulObj.current.value = selectLastThree;
    dialogDownloadCertificate.current.visible = true;
  }

  const handleCloseDialogDownloadCertificate = () => {
    mulObj.current.value = [];
    dialogDownloadCertificate.current.visible = false;
  }

  const updateCertificate = async (results_id, level, new_order) => {
    var body_certificate = {
      order: new_order,
      level: level,
      status: "published"
    }

    var result = await directus.createItems('certificates', body_certificate);
    var certificate_id = result.data.id;

    //add certificate number into student results
    var body_results = [];

    //eslint-disable-next-line array-callback-return
    results_id.map((value) => {
      body_results.push({
        id: value,
        certificate: certificate_id
      })
    })

    await directus.updateItems('results', body_results);

    getLatestCertificate();
    setDataButton("download_certificate");
  }

  // {/* ================== Read Certificate ========================================== */ }
  //unzip and load file docx, pdf, image
  function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }

  //convert blob data to url
  function blobToDataURL(blob, callback) {
    var a = new FileReader();
    a.onload = function (e) { callback(e.target.result); }
    a.readAsDataURL(blob);
  }

  const getOrdinalDate = (d) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  // {/* ================== Generate Certificate ========================================== */ }
  const generateDocument = async () => {
    if (mulObj.current.value.length !== 3) {
      EventEmitter.emit('alert_toast', { content: The_number_of_classes_selected_must_be_3, type: "danger" });
    } else {
      setLoadingButton(true);

      var dataCertificate = null;
      await getCertificateData()
        .then((response) => dataCertificate = response);

      var results_id = linq.from(dataCertificate.result)
        .select(data => data.id)
        .toArray();

      //set document name
      const docName = "certificate-" + dataCertificate.result[0].student.name + ".docx";

      //get the latest level
      var level = dataCertificate.result[2].subject_class.subjects.level ? dataCertificate.result[2].subject_class.subjects.level : null;

      //load certificate docx template
      await loadFile('/static/certificates/New_Certificate_Template.docx', async function (
        error,
        content
      ) {
        if (error) {
          throw error;
        }

        var opts = {};
        opts.centered = false;

        //get the latest teacher signature image
        var sign_link = dataCertificate.lastSchedule.teachers_id.signature ? "https://admin.staging.akademix.cloud" + dataCertificate.lastSchedule.teachers_id.signature.data.asset_url : '';
        opts.getImage = function () {
          return new Promise(function (resolve, reject) {
            loadFile(sign_link, async function (
              error,
              content
            ) {
              if (error) {
                return reject(error);
              }
              return resolve(content);
            });
          });
        }

        opts.getSize = function () { return [105, 105]; };

        var imageModule = new ImageModule(opts);
        var zip = new PizZip(content);
        var doc = new Docxtemplater()
          .loadZip(zip)
          .attachModule(imageModule)
          .compile();

        //insert data into document based on parameters created in docx template
        doc.resolveData({
          name: dataCertificate.result[0].student.name,
          NU1_1: dataCertificate.newNU_1,
          NU1_2: dataCertificate.newNU_2,
          NU1_3: dataCertificate.newNU_3,
          NU2_1: dataCertificate.NU_1,
          NU2_2: dataCertificate.NU_2,
          NU2_3: dataCertificate.NU_3,
          day: moment().format("D"),
          ord: getOrdinalDate(new Date().getDate()),
          month: moment().format("MMMM"),
          year: moment().format("YYYY"),
          date_1: getFinishDate(dataCertificate.result[0]),
          date_2: getFinishDate(dataCertificate.result[1]),
          date_3: getFinishDate(dataCertificate.result[2]),
          mid_1: dataCertificate.result[0].exam_mid,
          mid_2: dataCertificate.result[1].exam_mid,
          mid_3: dataCertificate.result[2].exam_mid,
          final_1: dataCertificate.result[0].exam_end,
          final_2: dataCertificate.result[1].exam_end,
          final_3: dataCertificate.result[2].exam_end,
          part_1: dataCertificate.result[0].participation,
          part_2: dataCertificate.result[1].participation,
          part_3: dataCertificate.result[2].participation,
          total_1: getTotal(dataCertificate.result[0]),
          total_2: getTotal(dataCertificate.result[1]),
          total_3: getTotal(dataCertificate.result[2]),
          order: dataCertificate.new_order,
          lvl: dataCertificate.result[2].subject_class.subjects.level,
          teacher: dataCertificate.teacher_name,
          teacher_sign: "image"
        }).then(function () {
          doc.render();
          //generate the document into blob data
          var out = doc.getZip().generate({
            type: "blob",
            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          //save the document
          saveAs(out, docName);
          //update certificate data into databse
          updateCertificate(results_id, level, dataCertificate.new_order);
        })
      })
    }

  };

  // {/* ================== Preview Certificate ========================================== */ }
  const preview = async () => {
    if (mulObj.current.value.length !== 3) {
      EventEmitter.emit('alert_toast', { content: The_number_of_classes_selected_must_be_3, type: "danger" });
    } else {
      setLoadingButton(true);

      var dataCertificate = null;
      await getCertificateData()
        .then((response) => dataCertificate = response);

      //load certificate pdf template
      await loadFile('/static/certificates/New_Certificate_Template.pdf', async function (
        error,
        content
      ) {
        if (error) {
          throw error;
        }

        const pdfDoc = await PDFDocument.load(content);

        //get the latest teacher signature image
        var teacher_link = dataCertificate.lastSchedule.teachers_id.signature ? "https://admin.staging.akademix.cloud" + dataCertificate.lastSchedule.teachers_id.signature.data.asset_url : '';

        loadFile(teacher_link, async function (
          error,
          content_teacher
        ) {
          //embed teacher sign into document
          var teacher_sign = await pdfDoc.embedPng(content_teacher);

          //insert data into document based on parameters created in docx template
          const form = pdfDoc.getForm();
          form.getTextField('name').setText(dataCertificate.result[0].student.name);
          form.getTextField('completed_classes').setText("has completed " + dataCertificate.newNU_1 + ", " + dataCertificate.newNU_2 + " & " + dataCertificate.newNU_3 + " in English studies");
          form.getTextField('date').setText("Pekanbaru, " + moment().format("D") + "" + getOrdinalDate(new Date().getDate()) + " " + moment().format("MMMM") + " " + moment().format("YYYY"));
          form.getTextField('certificate_number').setText("NO. CRF/ " + dataCertificate.result[2].subject_class.subjects.level + "/ " + moment().format("YYYY") + "/ " + dataCertificate.new_order);
          form.getTextField('director_name').setText("Jonathan Thorne");
          form.getTextField('manager_name').setText("Fitrah Qalbina, S. Psi");
          form.getTextField('teacher_name').setText(dataCertificate.teacher_name);
          form.getTextField('teacher_sign').setImage(teacher_sign);

          form.getTextField('nu2_1').setText(dataCertificate.NU_1);
          form.getTextField('nu2_2').setText(dataCertificate.NU_2);
          form.getTextField('nu2_3').setText(dataCertificate.NU_3);

          form.getTextField('date_1').setText(getFinishDate(dataCertificate.result[0]));
          form.getTextField('date_2').setText(getFinishDate(dataCertificate.result[1]));
          form.getTextField('date_3').setText(getFinishDate(dataCertificate.result[2]));

          form.getTextField('mid_1').setText("" + dataCertificate.result[0].exam_mid);
          form.getTextField('mid_2').setText("" + dataCertificate.result[1].exam_mid);
          form.getTextField('mid_3').setText("" + dataCertificate.result[2].exam_mid);

          form.getTextField('final_1').setText("" + dataCertificate.result[0].exam_end);
          form.getTextField('final_2').setText("" + dataCertificate.result[1].exam_end);
          form.getTextField('final_3').setText("" + dataCertificate.result[2].exam_end);

          form.getTextField('part_1').setText("" + dataCertificate.result[0].participation);
          form.getTextField('part_2').setText("" + dataCertificate.result[1].participation);
          form.getTextField('part_3').setText("" + dataCertificate.result[2].participation);

          form.getTextField('total_1').setText(getTotal(dataCertificate.result[0]));
          form.getTextField('total_2').setText(getTotal(dataCertificate.result[1]));
          form.getTextField('total_3').setText(getTotal(dataCertificate.result[2]));
          //flatten all field in pdf form
          form.flatten();
          //save the document into pdfbytes
          const pdfBytes = await pdfDoc.save();
          //convert the pdf into blob
          var blob = new Blob([pdfBytes], { type: "application/pdf" });

          //display the document in iframe
          blobToDataURL(blob, function (dataurl) {
            var newsrc = "" + dataurl + "#toolbar=0&embedded=true"
            document.getElementById("previewFrame").src = newsrc;
            setLoadingButton(false);
          });
        });
      })

    }
  }

  // {/* ================== Footer Template ========================================== */ }

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  const beforeRender = (args) => {
    // event triggered before render the tooltip on target element.
    tooltip.content = args.target.closest("th").innerText;
  }

  // {/* ================== Main Content ========================================== */ }

  return (
    <div id="biodata" style={{ alignContent: "center", color: "#333", minHeight: "600px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }}>
      {loadingNotes ? <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} /> :
        <div>
          <div>
            <div style={{ float: "right" }}>
              <ButtonComponent className="btn-custom" iconCss='e-icons icon-M_PV_Download' onClick={handleOpenDialogDownloadCertificate}>
                <span className="btn-text-custom">{Download_Certificate}</span>
              </ButtonComponent>
            </div>
            <br /><br /><br />

            {data ? data.length > 0 ?
              <div>
                <TooltipComponent ref={t => tooltip = t} target='.e-headercell' beforeRender={beforeRender}>
                  <GridComponent
                    width="100%"
                    dataSource={data}
                    editSettings={editSettings}
                    ref={grid}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: "Content" }}
                    allowResizing={true}
                    gridLines='Vertical'
                    actionBegin={actionGrid}
                    selectionSettings={setting}
                    allowReordering={true}
                    clipMode="Ellipsis"
                  >
                    <ColumnsDirective>
                      <ColumnDirective customAttributes={{ id: 'first' }} width="20%" field='subject_class.name' allowEditing={false} headerText={Class_Name} template={classTemplate} editTemplate={classTemplate}></ColumnDirective>
                      <ColumnDirective width="15%" field='exam_mid' headerText={Mid_Test}></ColumnDirective>
                      <ColumnDirective width="15%" field='exam_end' headerText={Final_Test}></ColumnDirective>
                      <ColumnDirective width="15%" field='participation' headerText={Participation}></ColumnDirective>
                      <ColumnDirective width="15%" template={teacherTemplate} editTemplate={teacherTemplate} allowEditing={false} headerText={Teacher}></ColumnDirective>
                      <ColumnDirective width="20%" template={finishDateTemplate} editTemplate={finishDateTemplate} allowEditing={false} headerText={Finish_Date}></ColumnDirective>
                      <ColumnDirective customAttributes={{ id: 'last' }} width="15%" template={certificateTemplate} editTemplate={certificateTemplate} headerText={Certificate}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                  </GridComponent>
                </TooltipComponent>
              </div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
            }

            <DialogComponent
              id="dialogDownloadCertificate"
              width="80%"
              height="90%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogDownloadCertificate}
              header={Download_Certificate}
              isModal={true}
              close={handleCloseDialogDownloadCertificate}
              footerTemplate={() => footerTemplate([
                { name: Preview, func: preview, icon: "icon-MT_Preview", status: "e-info" },
                { name: `${Download} docx`, func: generateDocument, icon: "icon-MT_Preview", status: "e-success" },
                { name: Cancel, func: handleCloseDialogDownloadCertificate, icon: "icon-treeview", status: "" }
              ])}
            >
              <div>
                <div>
                  {Select_Class}
                  <MultiSelectComponent id="checkbox" ref={mulObj} cssClass="e-outline" dataSource={data}
                    fields={{ text: "subject_class.name", value: "id" }} mode="Box"
                    showDropDownIcon={true} maximumSelectionLength={3} filterBarPlaceholder="Search classes" popupHeight="350px"
                    change={onSelectData}
                    value={selectedData} >
                    <Inject2 services={[CheckBoxSelection]} />
                  </MultiSelectComponent>
                  {File_name}: Certificate-{data ? data[0].student.name : null}.docx <br />
                  {certificateNumber}

                  <br /><br />
                  <iframe id="previewFrame" title="previewFrame" width="100%" height="450px" frameBorder="0" marginHeight="0" marginWidth="0" scrolling="no"></iframe>

                </div>
              </div>
            </DialogComponent>
          </div>
          <br />
          <br />
        </div>
      }
    </div >
  )
}

export default ClassSummary;