import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, DetailRow, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";
import Enumerable from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

var cancelFlag = false;
const ContactList = () => {
  /* eslint-disable no-unused-vars*/
  const navigate = useNavigate();

  var searchTimeout = null;

  var numOfPage = JSON.parse(window.localStorage.getItem('number_of_page'));
  var numContact = numOfPage ? numOfPage.contact ? numOfPage.contact : 15 : 15;
  if (!numOfPage) {
    window.localStorage.setItem('number_of_page', JSON.stringify({ contact: 15 }));
  } else {
    if (!numOfPage.contact) {
      numOfPage.contact = 15;
      window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
    }
  }

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));
  const [Clear] = useState(i18next.t('Pages.button.Clear'));
  const [Search] = useState(i18next.t('Pages.button.Search'));
  const [Preview] = useState(i18next.t('Pages.button.Preview'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [Please_match_the_requested_format] = useState(i18next.t('Pages.general.Please_match_the_requested_format'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));
  const [Items_per_page] = useState(i18next.t('Pages.general.Items_per_page'));
  const [of] = useState(i18next.t('Pages.general.of'));
  const [pages] = useState(i18next.t('Pages.general.pages'));
  // const [Total] = useState(i18next.t('Pages.general.Total'));

  const [Name] = useState(i18next.t('Pages.data.Name'));
  const [Gender] = useState(i18next.t('Pages.data.Gender'));
  const [Place_of_Birth] = useState(i18next.t('Pages.data.Place_of_Birth'));
  const [Date_of_Birth] = useState(i18next.t('Pages.data.Date_of_Birth'));
  const [Male] = useState(i18next.t('Pages.data.Male'));
  const [Female] = useState(i18next.t('Pages.data.Female'));
  const [Religion] = useState(i18next.t('Pages.data.Religion'));
  const [Islam] = useState(i18next.t('Pages.data.Islam'));
  const [Christian_Protestant] = useState(i18next.t('Pages.data.Christian_Protestant'));
  const [Catholic] = useState(i18next.t('Pages.data.Catholic'));
  const [Buddha] = useState(i18next.t('Pages.data.Buddha'));
  const [Hindu] = useState(i18next.t('Pages.data.Hindu'));
  const [Confucianism] = useState(i18next.t('Pages.data.Confucianism'));
  const [Others] = useState(i18next.t('Pages.data.Others'));
  const [Notes] = useState(i18next.t('Pages.data.Notes'));
  const [Select] = useState(i18next.t('Pages.data.Select'));
  const [Mobile_Number] = useState(i18next.t('Pages.data.Mobile_Number'));
  const [Phone_Number] = useState(i18next.t('Pages.data.Phone_Number'));
  // const [Action] = useState(i18next.t('Pages.data.Action'));

  const [Student_Parent_Guardian] = useState(i18next.t('Pages.student_data.Student_Parent_Guardian'));
  const [Advanced_Search] = useState(i18next.t('Pages.student_data.Advanced_Search'));
  const [Enter_student_name_or_nickname] = useState(i18next.t('Pages.student_data.Enter_student_name_or_nickname'));

  // const [Phone_Mobile] = useState(i18next.t('Pages.contact_data.Phone_Mobile'));
  // const [Phone_Home] = useState(i18next.t('Pages.contact_data.Phone_Home'));
  // const [Phone_Other] = useState(i18next.t('Pages.contact_data.Phone_Other'));
  const [Email] = useState(i18next.t('Pages.contact_data.Email'));
  const [Address] = useState(i18next.t('Pages.contact_data.Address'));
  const [Regency_City] = useState(i18next.t('Pages.contact_data.Regency_City'));
  const [Province] = useState(i18next.t('Pages.contact_data.Province'));
  const [Postal_Code] = useState(i18next.t('Pages.contact_data.Postal_Code'));
  const [Suburb_Village] = useState(i18next.t('Pages.contact_data.Suburb_Village'));
  const [District] = useState(i18next.t('Pages.contact_data.District'));
  const [Occupation] = useState(i18next.t('Pages.contact_data.Occupation'));
  const [Relation] = useState(i18next.t('Pages.contact_data.Relation'));
  const [Father] = useState(i18next.t('Pages.contact_data.Father'));
  const [Mother] = useState(i18next.t('Pages.contact_data.Mother'));
  const [Guardian] = useState(i18next.t('Pages.contact_data.Guardian'));
  const [Friend] = useState(i18next.t('Pages.contact_data.Friend'));
  const [Uncle] = useState(i18next.t('Pages.contact_data.Uncle'));
  const [Aunt] = useState(i18next.t('Pages.contact_data.Aunt'));
  const [Grandfather] = useState(i18next.t('Pages.contact_data.Grandfather'));
  const [Grandmother] = useState(i18next.t('Pages.contact_data.Grandmother'));
  const [Cousin] = useState(i18next.t('Pages.contact_data.Cousin'));
  const [Sibling] = useState(i18next.t('Pages.contact_data.Sibling'));
  const [Contact_created_successfully] = useState(i18next.t('Pages.contact_data.Contact_created_successfully'));
  const [Student_Parent_Guardian_deleted_successfully] = useState(i18next.t('Pages.contact_data.Student_Parent_Guardian_deleted_successfully'));
  const [Enter_parent_guardian_name] = useState(i18next.t('Pages.contact_data.Enter_parent_guardian_name'));
  const [Students] = useState(i18next.t('Pages.contact_data.Students'));
  const [Are_you_sure_you_want_to_delete_the_data_of_student_parent_guardian] = useState(i18next.t('Pages.contact_data.Are_you_sure_you_want_to_delete_the_data_of_student_parent_guardian'));
  const [If_you_want_to_delete_the_data_fill_in_the_name_below] = useState(i18next.t('Pages.contact_data.If_you_want_to_delete_the_data_fill_in_the_name_below'));
  const [Searching] = useState(i18next.t('Pages.contact_data.Searching'));

  //data
  const [data, setData, dataRef] = useStateRef(null);
  const [dataStudentContact, setDataStudentContact] = useState(null);
  const [dataMeta, setDataMeta] = useState(null);
  let [numberOfPage, setNumberOfPage, numberOfPageRef] = useStateRef(numContact);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogAddContact = useRef(false);
  let dialogDeleteContact = useRef(false);
  let dialogSearching = useRef(false);
  let nt_ref = useRef(false);

  //grid setting
  const grid = useRef();
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' };
  const filterOptions = { type: 'Menu' };
  const setting = { mode: 'Both' };

  //delete contact setting
  const [selectedData, setSelectedData] = useState(null);
  const [deleteWord, setDeleteWord] = useState(null);


  //other component setting
  const gender = [{ id: "Male", name: Male }, { id: "Female", name: Female }]
  const religion = [
    { id: 'Islam', text: Islam },
    { id: 'Christian/Protestant', text: Christian_Protestant },
    { id: 'Catholic', text: Catholic },
    { id: 'Buddha', text: Buddha },
    { id: 'Hindu', text: Hindu },
    { id: 'Confucianism', text: Confucianism },
    { id: 'Others', text: Others },
  ];
  const relation = [
    { id: 'Father', text: Father },
    { id: 'Mother', text: Mother },
    { id: 'Guardian', text: Guardian },
    { id: 'Friend', text: Friend },
    { id: 'Uncle', text: Uncle },
    { id: 'Aunt', text: Aunt },
    { id: 'Grandfather', text: Grandfather },
    { id: 'Grandmother', text: Grandmother },
    { id: 'Cousin', text: Cousin },
    { id: 'Sibling', text: Sibling },
  ];
  const fields_religion = { text: 'text', value: 'id' };

  //set state add contact
  const [stateContact, setStateContact] = useState({
    name: null,
    mobile_number: null,
    phone_number: null,
    email: null,
    occupation: null,
    gender: null,
    pob: null,
    dob: null,
    religion: null,
    address: null,
    address_rt: null,
    address_rw: null,
    address_city: null,
    address_state: null,
    address_post_code: null,
    address_town: null,
    address_district: null,
    notes: null
  });

  //set state filter 
  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    name: null,
    student: null,
    page: 1
  });

  //set error in add contact dialog
  const [error, setError, errorRef] = useStateRef({ contact_name: false });
  /* eslint-enable no-unused-vars*/

  const getInitialValue = () => {
    setStateContact({
      name: null,
      mobile_number: null,
      phone_number: null,
      email: null,
      occupation: null,
      gender: null,
      pob: null,
      dob: null,
      religion: null,
      address: null,
      address_rt: null,
      address_rw: null,
      address_city: null,
      address_state: null,
      address_post_code: null,
      address_town: null,
      address_district: null,
      notes: null
    });
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getContact();
    }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "searching") {
        dialogSearching.current.visible = false;
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_contact") {
        dialogDeleteContact.current.visible = false;
        setDeleteWord('');
        EventEmitter.emit('alert_toast', { content: Student_Parent_Guardian_deleted_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get list of contact
  const getContact = async () => {
    try {
      var name = { "nnull": null };
      var student = { "nnull": null };

      if (stateFiltering.name !== null) {
        name = { "contains": stateFilteringRef.current.name };
      }

      var id_contact = { "nnull": null };

      //check if there is a search by student name
      if (stateFiltering.student !== null) {
        student = { "logical": "or", "contains": stateFilteringRef.current.student };

        //get student contacts based on student name and contact name
        var result = await directus.getItems('students_contacts', {
          fields: '*.*',
          filter: {
            "students_id.name": student,
            "students_id.nickname": student,
            "contacts_id.name": name,
            "contacts_id.status": "published",
          }
        });

        if (result.data.length > 0) {
          var result_contact = linq.from(result.data)
            .select(data => data.contacts_id.id
            ).toArray();

          id_contact = { "in": result_contact };
        } else {
          id_contact = { "null": "null" };
        }
      }

      //get contact
      var result_contacts = await directus.getItems('contacts',
        {
          fields: "*,image.*",
          filter: {
            id: id_contact,
            name: name,
            status: 'published'
          },
          sort: 'name',
          limit: numberOfPageRef.current,
          meta: "*",
          page: stateFilteringRef.current.page ? stateFilteringRef.current.page : 1
        });

      var data_contact = result_contacts.data;

      var data_contact_id = linq.from(data_contact)
        .select(data => data.id)
        .toArray();

      var result_sc = await directus.getItems('students_contacts', {
        fields: '*.*',
        filter: {
          'contacts_id.id': { 'in': data_contact_id }
        }
      });

      var list_student_contact = linq.from(result_sc.data)
        .select(data => ({ id: data.id, contacts_id: data.contacts_id.id, data: data }))
        .toArray();


      //get list of students related to the contact
      let data_student_contact = result_sc.data;
      data_student_contact = linq.from(data_student_contact)
        .groupBy(x => x.contacts_id.id,
          element => element,
          (contacts_id, data) => ({ contacts_id, data: data.toArray() })
        )
        .toArray();

      //join students contacts data with contacts
      var result2 = Enumerable.from(data_contact)
        .groupJoin(Enumerable.from(data_student_contact),
          pk => pk.id,
          fk => fk.contacts_id,
          (left, right) => ({ ...left, right }))
        .selectMany(m => m.right.defaultIfEmpty(),
          (left, right) => ({ ...left, ...right }))
        .toArray();


      setData(result2);
      setDataMeta(result_contacts.meta);
      setDataStudentContact(list_student_contact);
      setLoading(false);
      setLoadingButton(false);

    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Handle Dialog Add Contact ========================================== */ }

  const handleOpenDialogAddContact = () => {
    setError({ ...errorRef.current, contact_name: false });
    dialogAddContact.current.visible = true;
  }

  const handleCloseDialogAddContact = () => {
    setError({ ...errorRef.current, contact_name: false });
    getInitialValue();
    dialogAddContact.current.visible = false;
  }

  const addContact = async () => {
    if (!error.contact_name) {
      try {
        setError({ ...errorRef.current, contact_name: false })
        if (stateContact.name) {
          setLoadingButton(true);
          const newDate = stateContact.dob ? moment(stateContact.dob).format("YYYY-MM-DD") : null;
          stateContact.dob = newDate;
          stateContact.status = "published";

          var result = await directus.createItem('contacts', stateContact);

          const body_activity = {
            action: "Add",
            category: 10,
            description: stateContact.name,
            status: "published"
          }
          await directus.createItem('activity_log', body_activity);

          if (!result.error) {
            setLoadingButton(false);
            dialogAddContact.current.visible = false;
            EventEmitter.emit('alert_toast', { content: Contact_created_successfully, type: "success" });
            return navigate('/contact/profile/' + result.data.id);
          }
        } else {
          if (!stateContact.name) {
            setError({ ...errorRef.current, contact_name: true });
            setLoadingButton(false);
          }
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        setLoadingButton(false);
      }
    }

  }

  const handleChangeContact = (event, type) => {
    var value = "";
    if (type === "gender" || type === "religion") {
      value = event.itemData.id;
    } else if (type === "name") {
      if (event.value) {
        value = event.value;
        setError({ ...error, contact_name: false });
      } else {
        setError({ ...error, contact_name: true });
      }
    } else {
      value = event.value;
    }

    setStateContact({ ...stateContact, [type]: value })
  }

  // {/* ================== Handle Dialog Delete Contact ========================================== */ }

  const handleOpenDialogDeleteContact = (props) => {
    dialogDeleteContact.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteContact = () => {
    dialogDeleteContact.current.visible = false;
    setDeleteWord('');
  }

  const handleChangeWord = (e) => {
    setDeleteWord(e.value);
  }

  const deleteContact = async () => {
    try {
      if (deleteWord === selectedData.name) {
        setLoadingButton(true);
        var result = await directus.updateItem('contacts', selectedData.id, { status: "draft" });

        const body_activity = {
          action: "Delete",
          category: 10,
          description: selectedData.name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          getContact();
          setDataButton("delete_contact");
        }
      } else if (deleteWord !== selectedData.name) {
        EventEmitter.emit('alert_toast', { content: Please_match_the_requested_format, type: "danger" });
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Handle Searching For Contacts ========================================== */ }

  const handleChangeFilter = (event, type) => {
    var value = "";
    if (type === "name") { value = event.value }
    setStateFiltering({ ...stateFiltering, [type]: value })

    if (event.syntheticEvent.type === 'change') {
      getContact();
    }
  }

  const searchContact = () => {
    getContact();
  }

  const handleOpenSearchingDialog = () => {
    dialogSearching.current.visible = true;
  }

  const clearDialogState = () => {
    setStateFiltering({ ...stateFiltering, student: null })
  }

  const handleCloseDialogSearching = () => {
    dialogSearching.current.visible = false;
  }

  const handleChangeSearching = (event, type) => {
    var value = event.value;
    setStateFiltering({ ...stateFiltering, [type]: value })
  }

  const searching = async () => {
    setLoadingButton(true);
    getContact();
    setDataButton("searching");
  }


  // {/* ================== Data Grid Template ========================================== */ }
  const studentTemplate = props => {
    var list = "";

    if (props.data) {
      //eslint-disable-next-line array-callback-return
      props.data.map((value, index) => {
        list += value.students_id.nickname
        if (index !== props.data.length - 1) {
          list += ", "
        }
      })
    }
    return (<div>{list}</div>)
  }

  const buttonTemplate = props => {
    return (<div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
      <div className="action_hover" style={{ display: "flex" }}>
        <TooltipComponent content={Preview} position="BottomCenter" >
          <span>
            <Link to={{ pathname: `/contact/profile/${props.id}` }} style={{ textDecoration: "none" }}>
              <span className="e-icons icon-MT_Preview"
                style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              />
            </Link>
          </span>
        </TooltipComponent>

        <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px" }} >
          <span className="e-icons icon-delete-05"
            style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
            onClick={() => handleOpenDialogDeleteContact(props)}
          />
        </TooltipComponent>
      </div>
    </div>)
  }

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const buttonEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter">
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }


  const buttonStudentTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_hover" style={{ display: "flex" }}>
          <TooltipComponent content={Preview} position="BottomCenter" >
            <span>
              {/* <Link to={{ pathname: `/contact/profile/${props.data.students_id.id}` }} style={{ textDecoration: "none" }}> */}
              <span className="e-icons icon-MT_Preview"
                style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => btnClickStudent(props.data.students_id.id)}
              />
              {/* </Link> */}
            </span>
            {/* <span className="e-icons icon-MT_Preview"
            style={{ padding: "10px", cursor: "pointer", color: '#3c8dbc' }}
            onClick={() => btnClickStudent(props.data.students_id.id)}
          /> */}
          </TooltipComponent>
        </div>
      </div>
    )
  }


  //add expand cell if contact has students
  const rowDataBound = (args) => {
    var filter = args.data.data;
    if (!filter) {
      args.row.querySelector('td').innerHTML = " ";
      args.row.querySelector('td').className = "e-customizedExpandcell";
    }
  }

  //expand contact data after click
  const rowSelected = (args) => {
    if (grid) {
      if (args.target) {
        if (args.target.previousSibling) {
          var className = args.target.previousSibling.className;
          if (className.indexOf("e-detailrowcollapse")) {
            grid.current.detailRowModule.collapse(args.rowIndex);
          } else {
            grid.current.detailRowModule.expand(args.rowIndex);
          }
        }
      }

    }
  }

  // {/* ================== Data Grid Action (edit) ========================================== */ }

  const actionGrid = async (event) => {
    try {
      if (event.action === "edit" && event.requestType === "save") {
        if (cancelFlag === false) {
          const data = event.data;
          const body = {
            name: data.name,
            mobile_number: data.mobile_number,
            phone_number: data.phone_number,
          }
          // contact.updateContactItem(true, data.id, data);
          var result = await directus.updateItem('contacts', data.id, body);
          if (!result.error) {
            var description = data.name;
            if (data.name !== event.previousData.name) {
              description = event.previousData.name + " --> " + data.name;
            }

            const body_activity = {
              action: "Update",
              category: 10,
              description: description,
              status: "published"
            }
            await directus.createItem('activity_log', body_activity);
            getContact();
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });

          }
        } else {
          grid.current.editModule.closeEdit();
        }

        cancelFlag = false;

      } else if (event.requestType === "beginEdit") {
        cancelFlag = true;
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Navigate ========================================== */ }
  // function btnClickContact(id) {
  //   return navigate('/contact/profile/' + id);
  // }/

  function btnClickStudent(id) {
    return navigate('/student/profile/' + id);
  }

  // {/* ================== Data Grid Child ========================================== */ }
  const getRelation = (props) => {
    var relation2 = props ? props : null;
    if (relation) {
      relation.forEach((value) => {
        if (value.id === relation2) {
          relation2 = value.text
        }
      })
    }
    return "" + relation2
  }


  const relationTemplate = props => {
    return (
      <div>{getRelation(props.data.relation)}</div>
    )
  }

  const childGridOptions = {
    columns: [
      { field: 'data.students_id.nickname', headerText: Name, width: 120, customAttributes: { id: 'first' } },
      { field: 'data.relation', headerText: Relation, width: 120, template: relationTemplate },
      { width: 50, template: buttonStudentTemplate, customAttributes: { id: 'last' } },
    ],
    dataSource: dataStudentContact,
    queryString: 'contacts_id'
  }

  const changeNumOfData = (e) => {
    var value = e.value ? e.value : 1;
    setNumberOfPage(value);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      numOfPage.contact = value;
      window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
      setStateFiltering({ ...stateFilteringRef.current, page: 1 })
      getContact();
    }, 1000)
  }

  // {/* ================== Pagination ========================================== */ }

  const pageTemplate = () => {
    var list = [];
    if (dataMeta) {
      for (var i = 0; i < dataMeta.page_count; i++) {
        const page = i + 1;
        var class_name = "e-link e-numericitem e-spacing e-pager-default";
        if (dataMeta.page === page) {
          class_name = "e-link e-numericitem e-spacing e-currentitem e-active"
        }
        var display = "none";
        if (page <= 3) { display = ""; }
        list.push(<div key={"page-" + page}><span className={class_name} id={"page-" + page} role="link" tabIndex="-1" aria-label={'Goto Page ' + page} onClick={() => clickPage(page, "num")} name={'Goto Page ' + page} aria-owns="grid_940258773_0_content_table" style={{ display: display }} >{page}</span></div>)
      }
    }

    //check page 
    var check_page = [];
    if (dataMeta.page_count <= 3) {
      for (var j = 0; j < dataMeta.page_count; j++) { check_page.push(j + 1) }
    } else {
      if (dataMeta.page <= 2) {
        for (var k = 0; k < 3; k++) { check_page.push(k + 1) }
      } else if (dataMeta.page >= dataMeta.page_count) {
        for (var l = dataMeta.page_count; l > dataMeta.page_count - 3; l--) { check_page.push(l) }
      } else {
        var list_page = [dataMeta.page - 1, dataMeta.page, dataMeta.page + 1];
        list_page.forEach((value) => {
          check_page.push(value)
        })
      }
    }

    var select_page2 = document.querySelectorAll('.e-numericitem');
    if (select_page2.length > 0) {
      if (select_page2.length > 0) {
        for (var index = 0; index < select_page2.length; index++) {
          select_page2[index].style.display = "none";
        }
      }

      if (check_page.length > 0) {
        //eslint-disable-next-line array-callback-return
        check_page.map((value) => {
          var select_page = document.querySelectorAll(`[id=page-${value}]`);
          if (select_page.length > 0) {
            for (var index2 = 0; index2 < select_page.length; index2++) {
              select_page[index2].style.display = "";
            }
          }
        })
      }
    }

    return (
      <div style={{ height: "35px", textAlign: "center" }}>
        <div className="e-gridpager e-control e-pager e-lib" style={{ display: "flex", width: "100%" }} >
          <div style={{ width: "32.5%", background: "#E1E7EB", borderRadius: "17.5px 5px 5px 17.5px" }}>
            <div className="e-pagercontainer" role="navigation" >
              <div className={dataMeta.page === 1 ? "e-first e-icons e-icon-first e-firstpagedisabled e-disable" : "e-first e-icons e-icon-first e-firstpage e-pager-default"} onClick={() => clickPage(1, "first")} title="Go to first page" aria-label="Go to first page" tabIndex="-1" index="1" aria-owns="grid_940258773_0_content_table"></div>
              <div className={dataMeta.page === 1 ? "e-prev e-icons e-icon-prev e-prevpagedisabled e-disable" : "e-prev e-icons e-icon-prev e-prevpage e-pager-default"} onClick={() => clickPage(dataMeta.page - 1, "prev")} title="Go to previous page" aria-label="Go to previous page" tabIndex="-1" index="0" aria-owns="grid_940258773_0_content_table"></div>
              <div className="e-numericcontainer">{list}</div>
              <div className={dataMeta.page === dataMeta.page_count ? "e-next e-icons e-icon-next e-nextpagedisabled e-disable" : "e-next e-icons e-icon-next e-nextpage e-pager-default"} onClick={() => clickPage(dataMeta.page + 1, "next")} title="Go to next page" aria-label="Go to next page" tabIndex="-1" index="2" aria-owns="grid_940258773_0_content_table"></div>
              <div className={dataMeta.page === dataMeta.page_count ? "e-last e-icons e-icon-last e-lastpagedisabled e-disable" : "e-last e-icons e-icon-last e-lastpage e-pager-default"} onClick={() => clickPage(dataMeta.page_count, "last")} title="Go to last page" aria-label="Go to last page" tabIndex="-1" index="2" aria-owns="grid_940258773_0_content_table"></div>
            </div>
          </div>
          <div style={{ width: "35%", background: "#E1E7EB", marginLeft: "2px", marginRight: "2px", borderRadius: "5px" }}>
            <div className="e-pagerconstant">
              <span className="e-constant" style={{ display: "flex" }}>
                <span style={{ marginRight: "6px" }}>
                  <span style={{ display: "flex", flexDirection: "column", position: "absolute", marginTop: "7px" }}>
                    <span className="e-icons icon-arrowhead-up"
                      style={{ cursor: "pointer", color: '#516573', fontSize: "10px" }}
                      onClick={() => handleNumberOfPage("+")}
                    />
                    <span className="e-icons icon-arrowhead-down"
                      style={{ cursor: "pointer", color: '#516573', fontSize: "10px" }}
                      onClick={() => handleNumberOfPage("-")}
                    />
                  </span>
                </span>
                <span className="nt_custom" style={{ fontSize: "17px", fontWeight: "800", color: "#516573", width: "35px", marginRight: "6px", marginLeft: "6px" }}>
                  <NumericTextBoxComponent ref={nt_ref} strictMode={false} style={{ textAlign: "center", padding: "0px" }} format='####' value={numberOfPageRef.current} showSpinButton={false} max={999} min={1} onChange={(e) => changeNumOfData(e)} />
                </span>
                <span style={{ color: "#516573" }}>
                  {Items_per_page} (total {dataMeta ? dataMeta.filter_count : 0} records)
                </span>

              </span>
            </div>
          </div>
          <div className="e-parentmsgbar" style={{ width: "32.5%", background: "#E1E7EB", borderRadius: "5px 17.5px 17.5px 5px" }}>
            <span className="e-pagenomsg">{dataMeta ? <span>{dataMeta.page} {of} {dataMeta.page_count} {pages} </span> : null}</span>
          </div>
        </div>
      </div>
    )
  }

  const handleNumberOfPage = (props) => {
    if (numberOfPageRef.current - 1 > -1) {
      var value = numberOfPageRef.current;
      if (props === "+") {
        value = numberOfPageRef.current + 5;
      } else {
        if (numberOfPageRef.current > 5) {
          value = numberOfPageRef.current - 5;
        } else {
          value = 1;
        }
      }
      setNumberOfPage(value);
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        numOfPage.contact = value;
        window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
        getContact();
      }, 1000)
    }
  }

  const clickPage = (page, type) => {
    var status = false;
    if (type === "num") {
      if (dataMeta.page !== page) { status = true; }
    } else if (type === "first" || type === "prev") {
      if (dataMeta.page !== 1) { status = true; }
    } else if (type === "next" || type === "last") {
      if (dataMeta.page !== dataMeta.page_count) { status = true; }
    }

    if (status) {
      setStateFiltering({ ...stateFilteringRef.current, page: page });
      getContact();
    }
  }

  const onColumnMenuOpen = (args) => {
    for (const item of args.items) {
      if (item.text === 'Autofit all columns' || item.text === 'Autofit this column') {
        item.hide = true;
      }
    }
  }

  // {/* ================== Footer Template ========================================== */ }

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }
  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      cancelFlag = false;
      grid.current.endEdit();
    }
  }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Student_Parent_Guardian}</div>
            <div className="content-searching hide-content">
              <div style={{ display: 'flex', width: "100%" }}>
                <div style={{ width: "260px", marginRight: "10px" }}>
                  <TextBoxComponent
                    cssClass="e-outline searching-text"
                    className="searching-input"
                    id="searching-input"
                    placeholder={Enter_parent_guardian_name}
                    onChange={(e) => handleChangeFilter(e, "name")}
                    onBlur={(e) => searchContact(e)}
                    showClearButton="true"
                    value={stateFiltering.name}
                  />
                </div>
                <div style={{ marginRight: "10px" }}>
                  <ButtonComponent
                    className="btn-custom"
                    iconCss='e-icons icon-MT_search'
                    onClick={handleOpenSearchingDialog}
                  >
                    <span className="btn-text-custom">
                      {Advanced_Search}
                    </span>
                  </ButtonComponent>
                </div>
                <div>
                  <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddContact}>
                    <span className="btn-text-custom">{Student_Parent_Guardian}</span>
                  </ButtonComponent>
                </div>
                {/* {dataMeta ? `${Total} : ` + dataMeta.filter_count : ''} */}
                {/* {dataMeta ? dataMeta.filter_count > 15 ? pageTemplate() : null : null} */}
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="content-main">
            {/* ================== Grid View ========================================== */}
            {dataRef.current ? dataRef.current.length > 0 ?
              <div style={{ marginTop: "25px", marginBottom: "15px" }}>
                <div className="grid-container">
                  <GridComponent
                    width="100%"
                    className="grid-list parent-grid-list"
                    dataSource={dataRef.current}
                    editSettings={editSettings}
                    allowSorting={true}
                    filterSettings={filterOptions}
                    allowFiltering={true}
                    ref={grid}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: "Content" }}
                    clipMode="EllipsisWithTooltip"
                    gridLines='Both'
                    showColumnMenu={true}
                    columnMenuOpen={onColumnMenuOpen}
                    actionBegin={actionGrid}
                    selectionSettings={setting}
                    allowReordering={true}
                    allowResizing={true}
                    childGrid={childGridOptions}
                    rowDataBound={rowDataBound}
                    rowSelected={rowSelected}
                    keyPressed={onKeyPressed}
                  >
                    <ColumnsDirective>
                      <ColumnDirective customAttributes={{ id: 'first' }} width='22%' field='name' headerText={Name} validationRules={{ required: true }}></ColumnDirective>
                      {/* <ColumnDirective customAttributes={{ id: 'first', colSpan: '2' }} width='18%' field='name' headerText={Name} validationRules={{ required: true }}></ColumnDirective> */}
                      <ColumnDirective width='18%' field='mobile_number' headerText={Mobile_Number}></ColumnDirective>
                      <ColumnDirective width='18%' field='phone_number' headerText={Phone_Number}></ColumnDirective>
                      <ColumnDirective width='26%' allowEditing={false} headerText={Students} template={studentTemplate} editTemplate={studentTemplate}></ColumnDirective>
                      <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width='10%' template={buttonTemplate} editTemplate={buttonEditTemplate} ></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[DetailRow, Resize, Page, Toolbar, Edit, Sort, Filter, ColumnMenu]} />
                  </GridComponent>
                </div>
              </div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
            }
          </div>
          <div className="content-pagination">
            <div className="content-pagination-inside">
              {dataMeta ? dataMeta.filter_count > 0 ? pageTemplate() : null : null}
            </div>
          </div>

          {/* add contact  */}
          <DialogComponent
            id="dialogAddContact"
            width="35%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogAddContact}
            header={Student_Parent_Guardian}
            isModal={true}
            close={handleCloseDialogAddContact}
            footerTemplate={() => footerTemplate([
              { name: Update, func: addContact, icon: "icon-check-mark-01", status: "e-success" },
              { name: Cancel, func: handleCloseDialogAddContact, icon: "icon-treeview", status: "" }
            ])}
          >
            <div>
              <div>
                <b>*{Name}</b>
                <TextBoxComponent
                  name="name"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "name")}
                  value={stateContact.name}
                />
                {error.contact_name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                <br /><br />
                <b>{Mobile_Number}</b>
                <TextBoxComponent
                  name="mobile_number"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "mobile_number")}
                  value={stateContact.mobile_number}
                />
                <br /><br />
                <b>{Phone_Number}</b>
                <TextBoxComponent
                  name="phone_number"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "phone_number")}
                  value={stateContact.phone_number}
                />
                <br /><br />
                <b>{Email}</b>
                <TextBoxComponent
                  name="email"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "email")}
                  value={stateContact.email}
                />
                <br /><br />
                <b>{Occupation}</b>
                <TextBoxComponent
                  name="occupation"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "occupation")}
                  value={stateContact.occupation}
                />
                <br /><br />
                <b>{Gender}</b>
                <DropDownListComponent
                  dataSource={gender}
                  fields={{ text: 'name', value: 'id' }}
                  name="gender"
                  cssClass="e-outline"
                  placeholder={`--${Select}--`}
                  select={(e) => handleChangeContact(e, "gender")}
                  value={stateContact.gender}
                />
                <br /><br />
                <b>{Place_of_Birth}</b>
                <TextBoxComponent
                  name="pob"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "pob")}
                  value={stateContact.pob}
                />
                <br /><br />
                <b>{Date_of_Birth}</b>
                <DatePickerComponent
                  name="date_of_birth"
                  cssClass="e-outline"
                  format="dd-MMM-yyyy"
                  change={(e) => handleChangeContact(e, "dob")}
                  value={stateContact.dob}
                />
                <br /><br />
                <b>{Religion}</b>
                <DropDownListComponent
                  name="religion"
                  cssClass="e-outline"
                  dataSource={religion}
                  fields={fields_religion}
                  placeholder={`--${Select}--`}
                  select={(e) => handleChangeContact(e, "religion")}
                  value={stateContact.religion}
                />
                <br /><br />
                <b>{Address}</b>
                <TextBoxComponent
                  name="addres"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address")}
                  value={stateContact.address}
                />
                <br /><br />
                <b>RT</b>
                <TextBoxComponent
                  name="address_rt"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_rt")}
                  value={stateContact.address_rt}
                />
                <br /><br />
                <b>RW</b>
                <TextBoxComponent
                  name="address_rw"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_rw")}
                  value={stateContact.address_rw}
                />
                <br /><br />
                <b>{Regency_City}</b>
                <TextBoxComponent
                  name="address_city"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_city")}
                  value={stateContact.address_city}
                />
                <br /><br />
                <b>{Province}</b>
                <TextBoxComponent
                  name="address_state"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_state")}
                  value={stateContact.address_state}
                />
                <br /><br />
                <b>{Postal_Code}</b>
                <TextBoxComponent
                  name="address_post_code"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_post_code")}
                  value={stateContact.address_post_code}
                />
                <br /><br />
                <b>{Suburb_Village}</b>
                <TextBoxComponent
                  name="address_town"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_town")}
                  value={stateContact.address_town}
                />
                <br /><br />
                <b>{District}</b>
                <TextBoxComponent
                  name="address_district"
                  cssClass="e-outline"
                  change={(e) => handleChangeContact(e, "address_district")}
                  value={stateContact.address_district}
                />
                <br /><br />
                <b>{Notes}</b>
                <TextBoxComponent
                  name="notes"
                  cssClass="e-outline"
                  multiline={true}
                  change={(e) => handleChangeContact(e, "notes")}
                  value={stateContact.notes}
                />
              </div>
            </div>
          </DialogComponent>

          {/* dialog delete contact  */}
          <DialogComponent
            id="dialogDeleteContact"
            width="35%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogDeleteContact}
            header={Delete_Confirmation}
            isModal={true}
            close={handleCloseDialogDeleteContact}
            footerTemplate={() => footerTemplate([
              { name: Delete, func: deleteContact, icon: "icon-delete-05", status: "e-warning" },
              { name: Cancel, func: handleCloseDialogDeleteContact, icon: "icon-treeview", status: "" }
            ])}
          >
            <div>
              {selectedData ?
                <div style={{ fontSize: "14px" }}>
                  {Are_you_sure_you_want_to_delete_the_data_of_student_parent_guardian} ({selectedData.name})?
                  <br /><br />
                  <p style={{ color: "red" }}>
                    {If_you_want_to_delete_the_data_fill_in_the_name_below}:
                  </p>
                  <TextBoxComponent
                    cssClass="e-outline"
                    name="delete_teacher"
                    change={(e) => handleChangeWord(e)}
                    value={deleteWord}
                  ></TextBoxComponent>
                </div>
                : ''}
            </div>
          </DialogComponent>

          {/* searching for contact  */}
          <DialogComponent
            id="dialogSearching"
            width="30%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogSearching}
            header={Searching}
            isModal={true}
            footerTemplate={() => footerTemplate([
              { name: Search, func: searching, icon: "icon-MT_search", status: "e-info" },
              { name: Cancel, func: handleCloseDialogSearching, icon: "icon-treeview", status: "" },
              { name: Clear, func: clearDialogState, icon: "e2-clear", status: "" }
            ])}
            close={handleCloseDialogSearching}
          >
            <div>
              <b>{Enter_student_name_or_nickname}</b>
              <TextBoxComponent
                name="stdent"
                cssClass="e-outline"
                change={(e) => handleChangeSearching(e, "student")}
                value={stateFiltering.student}
              />
            </div>
          </DialogComponent>

        </div>
      }
    </div >
  );
}

export default ContactList;