import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

/**
 * Get current directus user
 * @param {boolean} forceReload
 */

export async function getUser(forceReload = false) {
  try {
    var user = JSON.parse(window.localStorage.getItem('directus_data'));
    if (forceReload) {
      var result = await directus.getItem('directus_users', user.user.id, { fields: '*.*' });
      if (!result.error) {
        window.localStorage.setItem('directus_user', JSON.stringify(result.data));
        if (window.location.pathname === "/user/profile") {
          EventEmitter.emit('changeProfile', { value: "true" });
        }
        return result.data;
      }
      else {
        window.localStorage.clear();
        window.location.reload();
      }
    }
  } catch (err) { }

  return JSON.parse(window.localStorage.getItem('directus_user'));
}

export function isAuthenticated() {

  return window.localStorage.getItem('directus_users');
}

export async function redirectLogin() {
  var user = JSON.parse(window.localStorage.getItem('directus_user'));
  if (user !== null) {
    try {
      await directus.getMe();
    }
    catch (e) {
      window.localStorage.clear();
      window.location.reload();
    }
  }

}

export async function refreshToken() {

  if (JSON.parse(window.localStorage.getItem('directus_data'))) {
    const savedData = JSON.parse(window.localStorage.getItem('directus_data'));
    var result;

    try {
      result = await directus.refresh(savedData.token);
    }
    catch (err) { }

    window.localStorage.setItem('directus_data', JSON.stringify({ ...savedData, token: result.data.token }));

  }
}

/**
 * Get current user setting
 * @param {boolean} forceReload
 */
// export async function getUserSetting(forceReload = false) {
//   try {
//     var user = JSON.parse(window.localStorage.getItem('user_setting'));
//     var localStorage
//     if (forceReload) {
//       var user_setting = null;
//       if (user.account_type === "staff") { user_setting = "staff" }
//       else if (user.account_type === "student") { user_setting = "students" }
//       else if (user.account_type === "teacher") { user_setting = "teachers" }
//       else if (user.account_type === "contact") { user_setting = "contacts" }

//       var setting = null;
//       if (user.account_type === "administrator") {

//         setting = {
//           account_type: "administrator",
//           user_id: user.user_id,
//           data_id: null,
//           email: user.email,
//           name: user.name,
//           mobile_number: null,
//           lang: user.lang
//         }

//         window.localStorage.setItem('user_setting', JSON.stringify(setting));

//         var school_list = JSON.parse(localStorage.school_list);
//         for (let i = 0; i < school_list.length; i++) {
//           if (school_list[i].school.domain === user.school.domain) {
//             school_list[i] = user;
//           }
//         }

//         localStorage.setItem('school_list', JSON.stringify(school_list));

//       } else {
//         var user_setting = null;
//         if (user.account_type === "staff") { user_setting = "staff" }
//         else if (user.account_type === "student") { user_setting = "students" }
//         else if (user.account_type === "teacher") { user_setting = "teachers" }
//         else if (user.account_type === "contact") { user_setting = "contacts" }

//         var result = await directus.getItems(user_setting, {
//           fields: "*.*",
//           filter: {
//             "user.id": user.user.id
//           }
//         });

//         var new_data = result.data[0];
//         setting = {
//           account_type: user.account_type,
//           user_id: user.user.id,
//           data_id: new_data.id,
//           email: new_data.email,
//           name: new_data.name,
//           mobile_number: new_data.mobile_number,
//           lang: window.localStorage.lang ? window.localStorage.lang : "id"
//         }
//         window.localStorage.setItem('user_setting', JSON.stringify(setting));

//         var school_list = JSON.parse(localStorage.school_list);
//         for (let i = 0; i < school_list.length; i++) {
//           if (school_list[i].school.domain === user.school.domain) {
//             school_list[i] = user;
//           }
//         }

//         localStorage.setItem('school_list', JSON.stringify(user_setting));

//       }

//       if (window.location.pathname === "/user/profile") {
//         EventEmitter.emit('changeProfile', { value: "true" });
//       }

//       return JSON.parse(window.localStorage.getItem('user_setting'));
//     }
//   } catch (err) { }

//   return JSON.parse(window.localStorage.getItem('user_setting'));
// }

// export async function getConfiguration(forceReload = false) {
//   if(JSON.parse(window.localStorage.getItem('directus_data')))
//   {
//     try {
//       if (forceReload) {
//         var user = JSON.parse(window.localStorage.getItem('directus_data'));
//         var directus_user = JSON.parse(window.localStorage.getItem('directus_user'));
//         console.log("hereeeeee", directus_user)
//         if (!directus_user) {
//           var setting = null;
//           if (user.account_type === "administrator") {
//             setting = {
//               account_type: "administrator",
//               user_id: user.user.id,
//               data_id: null,
//               email: user.user.email,
//               name: user.user.first_name + " " + user.user.last_name,
//               mobile_number: null,
//               lang: window.localStorage.lang ? window.localStorage.lang : "id"
//             }
//           } else {
//             var user_setting = null;
//             if (user.account_type === "staff") { user_setting = "staff" }
//             else if (user.account_type === "student") { user_setting = "students" }
//             else if (user.account_type === "teacher") { user_setting = "teachers" }
//             else if (user.account_type === "contact") { user_setting = "contacts" }

//             var result = await directus.getItems(user_setting, {
//               fields: "*.*",
//               filter: {
//                 "user.id": directus_user.data.id
//               }
//             });

//             var new_data = result.data[0];
//             setting = {
//               account_type: user.account_type,
//               user_id: user.user.id,
//               data_id: new_data.id,
//               email: new_data.email,
//               name: new_data.name,
//               mobile_number: new_data.mobile_number,
//               lang: window.localStorage.lang ? window.localStorage.lang : "id"
//             }
//           }

//           localStorage.setItem('user_setting', JSON.stringify(setting));
//           if (window.location.pathname === "/user/profile") {
//             EventEmitter.emit('changeProfile', { value: "true" });
//           }

//           var school_list = JSON.parse(localStorage.school_list);
//           for (let i = 0; i < school_list.length; i++) {
//             if (school_list[i].school.domain === user.school.domain) {
//               school_list[i] = user;
//             }
//           }

//           localStorage.setItem('school_list', JSON.stringify(school_list));
//           EventEmitter.emit('changeRole', { value: "true" });

//           // return result.data;
//         }
//         else {
//           // window.localStorage.clear();
//           // window.location.reload();
//         }
//         // var result = await directus.getItems('configuration');
//         // if (!result.error) {
//         //   window.localStorage.setItem('configuration', JSON.stringify(result.data[0]));
//         //   return result.data;
//         // }
//         // else
//         // {
//         //   window.localStorage.clear();
//         //   window.location.reload();
//         // }
//       }
//     } catch (err) {console.log(err);}

//     return JSON.parse(window.localStorage.getItem('directus_data'));
//   }
// }