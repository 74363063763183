import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Group, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

import './index.css';

const StudentSchedule = () => {
  /* eslint-disable no-unused-vars*/
  const { id } = useParams();

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));

  const [Sunday] = useState(i18next.t('Pages.data.Sunday'));
  const [Monday] = useState(i18next.t('Pages.data.Monday'));
  const [Tuesday] = useState(i18next.t('Pages.data.Tuesday'));
  const [Wednesday] = useState(i18next.t('Pages.data.Wednesday'));
  const [Thursday] = useState(i18next.t('Pages.data.Thursday'));
  const [Friday] = useState(i18next.t('Pages.data.Friday'));
  const [Saturday] = useState(i18next.t('Pages.data.Saturday'));

  const [Date_Time] = useState(i18next.t('Pages.schedule_data.Date_Time'));
  // const [Teacher_Attendance] = useState(i18next.t('Pages.schedule_data.Teacher_Attendance'));
  const [Teacher] = useState(i18next.t('Pages.schedule_data.Teacher'));
  const [Student_Attendance] = useState(i18next.t('Pages.schedule_data.Student_Attendance'));
  const [Present] = useState(i18next.t('Pages.schedule_data.Present'));
  const [Excused] = useState(i18next.t('Pages.schedule_data.Excused'));
  const [Personal_Leave] = useState(i18next.t('Pages.schedule_data.Personal_Leave'));
  const [Absent] = useState(i18next.t('Pages.schedule_data.Absent'));
  const [Lesson_schedule_changed] = useState(i18next.t('Pages.schedule_data.Lesson_schedule_changed'));
  const [Beginning] = useState(i18next.t('Pages.schedule_data.Beginning'));
  const [Beginning_Teacher] = useState(i18next.t('Pages.schedule_data.Beginning_Teacher'));

  //data
  const [dataSubjectClass, setDataSubjectClass] = useState(null);
  const [dataAttendances, setDataAttendances, dataAttendancesRef] = useStateRef(null);
  const [selectedSubject, setSelectedSubject, selectedSubjectRef] = useStateRef(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loadingSchedule, setLoadingSchedule] = useState(false);

  //grid setting
  const grid = useRef();
  const fields = { text: 'name', value: 'id' };
  const weeks = [{ id: "Sunday", name: Sunday }, { id: "Monday", name: Monday }, { id: "Tuesday", name: Tuesday }, { id: "Wednesday", name: Wednesday }, { id: "Thursday", name: Thursday }, { id: "Friday", name: Friday }, { id: "Saturday", name: Saturday }]
  const teacherStatus = [{ id: "---", name: "---" }, { id: "Present", name: Present }, { id: "Absent", name: Absent }]
  const studentStatus = [{ id: "---", name: "---" }, { id: "Present", name: Present }, { id: "Excused", name: Excused }, { id: "Personal Leave", name: Personal_Leave }, { id: "Absent", name: Absent }]
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoadingSchedule(true);
      getStudentSubjectClass();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get list of student subject class
  const getStudentSubjectClass = async () => {
    try {
      var result = await directus.getItems('students_subject_classes',
        {
          fields: 'subject_classes_id.subjects.*,subject_classes_id.*,*',
          filter: {
            students_id: id,
            "subject_classes_id.status": "published"
          }
        }
      );

      if (result.data.length > 0) {

        var subjectClassData = linq.from(result.data)
          .select(data => ({
            id: data.subject_classes_id.id,
            name: data.subject_classes_id.name
          }
          ))
          .orderBy(x => x.name)
          .toArray();

        setSelectedSubject(selectedSubject ? selectedSubject : subjectClassData[0])
        setDataSubjectClass(subjectClassData);
        getAttendance();
      } else {
        setLoadingSchedule(false);
      }

    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list ofattendance
  const getAttendance = async () => {
    try {
      var subject = { "nnull": null };
      if (selectedSubjectRef.current) {
        subject = { "in": selectedSubjectRef.current.id }
      }
      var result = await directus.getItems('attendances',
        {
          fields: 'schedules_id.teachers_id.*,schedules_id.*,*',
          filter: {
            "students_subject_classes_id.students_id.id": id,
            "schedules_id.subject_classes_id.id": subject
          }
        }
      );

      var dataAttendances2 = result.data;
      dataAttendances2 = linq.from(dataAttendances2)
        .orderBy(x => new Date(x.schedules_id.time))
        .orderBy(x => new Date(x.schedules_id.date))
        .toArray();
      setDataAttendances(dataAttendances2);

      setLoadingSchedule(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Handle Attendances Status ========================================== */ }

  // const teacherAttdChange = async (e, item) => {
  //   try {
  //     const id = item.schedules_id.id;
  //     var result = await directus.updateItem('schedules', id, { student_attd: e.itemData.id })
  //     if (!result.error) {
  //       getAttendance();
  //       EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
  //     }
  //   } catch (error) {
  //     EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
  //   }
  // }

  const studentAttdChange = async (e, item) => {
    try {
      const id = item.id;
      var result = await directus.updateItem('attendances', id, { student_attd: e.itemData.id });

      if (!result.error) {
        getAttendance();
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const studentPresent = async (item) => {
    try {
      const id = item.id;
      var result = await directus.updateItem('attendances', id, { student_attd: "Present" });
      if (!result.error) {
        getAttendance();
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }


  // {/* ================== Handle Filter Class ========================================== */ }
  //get selected subject class
  const handleFilter = async (e, type) => {
    var value = null;
    if (type === "subject") { value = e.itemData }
    setSelectedSubject(value);
    getAttendance();
  }

  // {/* ================== Translate Week Data ========================================== */ }
  const getWeek = (props) => {
    var week2 = props ? props : null;
    if (weeks) {
      weeks.forEach((value) => {
        if (value.id === week2) { week2 = value.name }
      })
    }
    return "" + week2
  }

  // {/* ================== Grid Template ========================================== */ }
  const dateTimeTemplate = (props) => {
    return (
      <div>
        {props.schedules_id.previous_date || props.schedules_id.previous_time ?
          <TooltipComponent
            content={
              Lesson_schedule_changed + ', ' + Beginning + ': ' + (props.schedules_id.previous_date !== null && props.schedules_id.previous_date !== "" ?
                moment(props.schedules_id.previous_date).format("dddd, DD-MMM-YYYY")
                :
                moment(props.schedules_id.date).format("dddd, DD-MMM-YYYY")
              )
              + ' ' + (props.schedules_id.previous_time ? props.schedules_id.previous_time : props.schedules_id.time)} opensOn='Hover' style={{ fontSize: "inherit" }
              }
          >
            <span style={{ color: "green" }}> {moment(props.schedules_id.date).format("dddd, DD-MMM-YYYY")} {props.schedules_id.time}</span>
          </TooltipComponent>
          :
          <span>{getWeek(moment(props.schedules_id.date).format("dddd"))}, {moment(props.schedules_id.date).format("DD-MMM-YYYY")} {moment(props.schedules_id.time, "HH:mm:ss").format("HH:mm")}</span>
        }
      </div>
    )
  }

  // const teacherAttendanceTemplate = (props) => {
  //   return (
  //     <div>
  //       <DropDownListComponent
  //         dataSource={teacherStatus}
  //         fields={{ text: 'name', value: 'id' }}
  //         name="teacher_attd"
  //         placeholder="---"
  //         enabled={false}
  //         cssClass="e-outline"
  //         style={{
  //           backgroundColor: props.schedules_id.teacher_attd === 'Present' ? 'lightgreen' :
  //             props.schedules_id.teacher_attd === 'Absent' ? 'pink' : ''
  //         }}
  //         value={props.schedules_id.teacher_attd ? props.schedules_id.teacher_attd : '---'}
  //         select={(e) => teacherAttdChange(e, props)}
  //       />
  //     </div>
  //   )
  // }

  const teacherTemplate = (props) => {
    return (
      <div>
        {props.schedules_id.previous_teacher ?
          <TooltipComponent content={Beginning_Teacher + ': ' + props.schedules_id.previous_teacher.nickname} opensOn='Hover' style={{ fontSize: "inherit" }}>
            <span style={{ color: "green" }}>{props.schedules_id.teachers_id.nickname}</span>
          </TooltipComponent>
          :
          props.schedules_id.teachers_id.nickname
        }
      </div>
    )
  }

  const studentAttendanceTemplate = (props) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <DropDownListComponent
            id="ddl_custom"
            key={props.index}
            dataSource={studentStatus}
            fields={{ text: 'name', value: 'id' }}
            name="student_attd"
            placeholder="---"
            cssClass="e-outline"
            style={{
              height: "10px",
              backgroundColor: props.student_attd === 'Present' ? 'lightgreen' :
                props.student_attd === 'Absent' ? 'pink' :
                  props.student_attd === 'Excused' ? 'lightblue' :
                    props.student_attd === 'Personal Leave' ? 'orange' : '',

            }}
            value={props.student_attd}
            select={(e) => studentAttdChange(e, props)}
          />
        </div>
        <div style={{ margin: "0 10px" }}>
          {props.student_attd === null || props.student_attd === '---' ?
            <span style={{ cursor: "pointer", color: "#3c8dbc" }} onClick={() => studentPresent(props)} className="e-icons icon-check-mark-01"></span>
            : ''}
        </div>
      </div>
    )
  }

  // {/* ================== Main Content ========================================== */ }
  return (
    <div id="schedule" style={{ alignContent: "center", color: "#333", minHeight: "600px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "30px", paddingRight: "30px" }}>
      {loadingSchedule ? <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} /> :
        <div>
          {dataSubjectClass ?
            <div>
              <div style={{ width: "250px" }}>
                <DropDownListComponent
                  id="ddl_custom"
                  dataSource={dataSubjectClass}
                  name="subject"
                  cssClass="e-outline"
                  placeholder="All Subjects"
                  fields={fields}
                  select={(e) => handleFilter(e, "subject")}
                  value={dataSubjectClass ? dataSubjectClass[0].id : null}
                  className="form-control"
                />
              </div>
              <br />

              {dataAttendancesRef.current ? dataAttendancesRef.current.length > 0 ?
                <div>
                  <GridComponent
                    width="90%"
                    dataSource={dataAttendancesRef.current}
                    ref={grid}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: "Content" }}
                    allowResizing={true}
                    gridLines='Both'
                    allowEditing={false}
                    selectionSettings={{ mode: 'Both' }}
                    clipMode="Ellipsis"
                  >
                    <ColumnsDirective>
                      <ColumnDirective customAttributes={{ id: 'first' }} width="45%" headerText={Date_Time} template={dateTimeTemplate} ></ColumnDirective>
                      <ColumnDirective width="20%" headerText={Teacher} template={teacherTemplate} ></ColumnDirective>
                      <ColumnDirective customAttributes={{ id: 'last' }} width="35%" headerText={Student_Attendance} template={studentAttendanceTemplate} ></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Group, Resize]} />
                  </GridComponent>
                </div>
                : null : null}
            </div>
            : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
          }
        </div>
      }
    </div>
  )
}

export default StudentSchedule;