import React, { useState, useRef, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { useNavigate, Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent, UploaderComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as link from 'src/constants/link';
import * as permission from 'src/utils/Permission';

import CardRender from './teacherCardRender';

let cancelFlag = false;
const TeacherList = () => {
  /* eslint-disable no-unused-vars*/
  const navigate = useNavigate();

  var searchTimeout = null;

  var numOfPage = JSON.parse(window.localStorage.getItem('number_of_page'));
  var numTeacher = numOfPage ? numOfPage.teacher ? numOfPage.teacher : 15 : 15;
  if (!numOfPage) {
    window.localStorage.setItem('number_of_page', JSON.stringify({ teacher: 15 }));
  } else {
    if (!numOfPage.teacher) {
      numOfPage.teacher = 15;
      window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
    }
  }

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));
  const [Preview] = useState(i18next.t('Pages.button.Preview'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [Please_match_the_requested_format] = useState(i18next.t('Pages.general.Please_match_the_requested_format'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));
  const [Items_per_page] = useState(i18next.t('Pages.general.Items_per_page'));
  const [of] = useState(i18next.t('Pages.general.of'));
  const [pages] = useState(i18next.t('Pages.general.pages'));

  // const [Photo] = useState(i18next.t('Pages.data.Photo'));
  const [Name] = useState(i18next.t('Pages.data.Name'));
  const [Nickname] = useState(i18next.t('Pages.data.Nickname'));
  const [Gender] = useState(i18next.t('Pages.data.Gender'));
  const [Place_of_Birth] = useState(i18next.t('Pages.data.Place_of_Birth'));
  const [Date_of_Birth] = useState(i18next.t('Pages.data.Date_of_Birth'));
  // const [Action] = useState(i18next.t('Pages.data.Action'));
  const [Please_upload_the_photo] = useState(i18next.t('Pages.data.Please_upload_the_photo'));
  const [Please_select_gender] = useState(i18next.t('Pages.data.Please_select_gender'));
  const [Male] = useState(i18next.t('Pages.data.Male'));
  const [Female] = useState(i18next.t('Pages.data.Female'));
  const [Religion] = useState(i18next.t('Pages.data.Religion'));
  const [Islam] = useState(i18next.t('Pages.data.Islam'));
  const [Christian_Protestant] = useState(i18next.t('Pages.data.Christian_Protestant'));
  const [Catholic] = useState(i18next.t('Pages.data.Catholic'));
  const [Buddha] = useState(i18next.t('Pages.data.Buddha'));
  const [Hindu] = useState(i18next.t('Pages.data.Hindu'));
  const [Confucianism] = useState(i18next.t('Pages.data.Confucianism'));
  const [Others] = useState(i18next.t('Pages.data.Others'));
  const [Single] = useState(i18next.t('Pages.data.Single'));
  const [Married] = useState(i18next.t('Pages.data.Married'));
  const [Widow_Widower] = useState(i18next.t('Pages.data.Widow_Widower'));
  const [Select] = useState(i18next.t('Pages.data.Select'));

  const [Teacher_List] = useState(i18next.t('Pages.teacher_data.Teacher_List'));
  const [Teacher_Biodata] = useState(i18next.t('Pages.teacher_data.Teacher_Biodata'));
  const [Teacher] = useState(i18next.t('Pages.teacher_data.Teacher'));
  const [Email] = useState(i18next.t('Pages.teacher_data.Email'));
  const [Mobile_Number] = useState(i18next.t('Pages.data.Mobile_Number'));
  const [Address] = useState(i18next.t('Pages.teacher_data.Address'));
  const [Teacher_created_successfully] = useState(i18next.t('Pages.teacher_data.Teacher_created_successfully'));
  const [Teacher_deleted_successfully] = useState(i18next.t('Pages.teacher_data.Teacher_deleted_successfully'));
  const [Enter_teacher_name] = useState(i18next.t('Pages.teacher_data.Enter_teacher_name'));
  const [Are_you_sure_you_want_to_delete_the_data_of_teacher] = useState(i18next.t('Pages.teacher_data.Are_you_sure_you_want_to_delete_the_data_of_teacher'));
  const [If_you_want_to_delete_the_teacher_fill_in_the_name_below] = useState(i18next.t('Pages.teacher_data.If_you_want_to_delete_the_teacher_fill_in_the_name_below'));
  const [Degree] = useState(i18next.t('Pages.teacher_data.Degree'));
  const [KTP_Number] = useState(i18next.t('Pages.teacher_data.KTP_Number'));
  const [KTP_Expiry_Date] = useState(i18next.t('Pages.teacher_data.KTP_Expiry_Date'));
  const [Position] = useState(i18next.t('Pages.teacher_data.Position'));
  const [Field_of_Study] = useState(i18next.t('Pages.teacher_data.Field_of_Study'));
  const [Marital_Status] = useState(i18next.t('Pages.teacher_data.Marital_Status'));
  const [Name_of_Wife_Husband] = useState(i18next.t('Pages.teacher_data.Name_of_Wife_Husband'));
  const [Number_of_Children] = useState(i18next.t('Pages.teacher_data.Number_of_Children'));

  const [Email_has_already_been_taken] = useState(i18next.t('Pages.staff_data.Email_has_already_been_taken'));
  const [Language] = useState(i18next.t('Pages.staff_data.Language'));

  //card
  let cardEle;

  //data
  let [data, setData] = useState(null);
  let [dataMeta, setDataMeta] = useState(null);
  let [numberOfPage, setNumberOfPage, numberOfPageRef] = useStateRef(numTeacher);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogAddTeacher = useRef(false);
  let dialogDeleteTeacher = useRef(false);
  let dialogEditAccount = useRef(false);

  //grid setting
  const grid = useRef();
  const editSettings = { allowEditing: permission.AdminPermission() ? true : false, allowAdding: true, allowDeleting: true, mode: 'Normal' };
  const filterOptions = { type: 'Menu' };
  const setting = { mode: 'Both' };

  //delete student setting
  const [selectedData, setSelectedData] = useState(null);
  const [deleteWord, setDeleteWord] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  //upload image
  let my_form2 = useRef(null);
  let uploadObj = useRef(null);
  const [image, setImage] = useState(link.default_image);
  const asyncSettings = { saveUrl: link.saveUrl, removeUrl: link.removeUrl };

  //other component setting
  let [view, setView] = useState(localStorage.getItem('view') || 'Card');
  const gender = [{ id: "Male", name: Male }, { id: "Female", name: Female }]
  const religion = [
    { id: 'Islam', text: Islam },
    { id: 'Christian/Protestant', text: Christian_Protestant },
    { id: 'Catholic', text: Catholic },
    { id: 'Buddha', text: Buddha },
    { id: 'Hindu', text: Hindu },
    { id: 'Confucianism', text: Confucianism },
    { id: 'Others', text: Others },
  ];
  const marital_status = [
    { id: 'Single', text: Single },
    { id: 'Married', text: Married },
    { id: 'Widow/Widower', text: Widow_Widower }
  ];

  //set state add teacher
  const [state, setState] = useState({
    name: null,
    nickname: null,
    pob: null,
    dob: null,
    gender: null,
    mobile_number: null,
    image: null,
    address: null
  });

  //set state filter
  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    name: null,
    page: 1
  });

  //set error in add teacher dialog
  const [error, setError, errorRef] = useStateRef({
    name: false,
    gender: false,
    dob: false,
    image: false
  });

  const [errorAccount, setErrorAccount, errorAccountRef] = useStateRef({
    name: false,
    email: false,
    password: false
  });

  const [errorEmail, setErrorEmail] = useState(false);
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "delete_teacher") {
        dialogDeleteTeacher.current.visible = false;
        setDeleteWord('');
        EventEmitter.emit('alert_toast', { content: Teacher_deleted_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get list of teachers
  const getData = async () => {
    try {
      var name = { "nnull": null };

      if (stateFiltering.name !== null && stateFiltering.name !== "") {
        name = { "contains": stateFilteringRef.current.name };
      }

      var result = await directus.getItems('teachers',
        {
          fields: "*, image.*, user.*",
          sort: "name",
          filter: {
            name: name,
            status: 'published'
          },
          limit: numberOfPageRef.current,
          meta: "*",
          page: stateFilteringRef.current.page ? stateFilteringRef.current.page : 1
        });
      setData(result.data);
      setDataMeta(result.meta);
      setLoading(false);
      setLoadingButton(false);
      cardRendering(result.data);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Change View (Card or Grid) ========================================== */ }

  const changeView = () => {
    var viewStatus = view;
    if (view === 'Card') {
      viewStatus = 'Grid';
    } else {
      viewStatus = 'Card'
    }
    setView(viewStatus);
    localStorage.setItem("view", viewStatus);
  }

  // {/* ================== Card Render ========================================== */ }

  const cardRendering = (data) => {
    if (data.length > 0) {
      data.forEach((data, index) => {
        cardEle = document.getElementById('card_teacher_' + (++index));
        if (cardEle) {
          ReactDOM.render(<CardRender data={data} navigate={navigate} />, cardEle);
        }
      });
    }
  }


  // {/* ================== Filtering ========================================== */ }

  const handleChangeFilter = (event, type) => {
    var value = "";
    if (type === "name") { value = event.value }
    setStateFiltering({ ...stateFiltering, [type]: value })
    if (event.syntheticEvent.type === 'change') {
      getData();
    }
  }

  const searchTeacher = () => {
    getData();
  }

  // {/* ================== Handle Add Teacher ========================================== */ }
  const handleOpenDialogAddTeacher = () => {
    dialogAddTeacher.current.visible = true;
  }

  const handleCloseDialogAddTeacher = () => {
    setState({ nik: null, name: null, nickname: null, pob: null, dob: null, gender: null, mobile_number: null, image: null, address: null });
    dialogAddTeacher.current.visible = false;
    setError({ name: false, gender: false, dob: false, image: false })
    uploadObj.current.clearAll();
  }

  const handleChangeAddTeacher = (event, type) => {
    var value = "";
    if (type === "gender" || type === "religion" || type === "marital_status") {
      value = event.itemData.id;
    } else {
      value = event.value;
    }

    if (type === "name" || type === "gender") {
      if (value) {
        setError({ ...errorRef.current, [type]: false })
      } else {
        setError({ ...errorRef.current, [type]: true })
      }
    }
    setState({ ...state, [type]: value })
  }

  const addTeacher = async () => {
    if (uploadObj.current.filesData.length > 0) {
      if (!state.image) {
        setError({ ...errorRef.current, image: true })
      } else {
        setError({ ...errorRef.current, image: false })
      }
    }

    if (!error.name && !error.gender && !errorRef.current.image) {
      setLoadingButton(true);
      setError({ name: false, gender: false, dob: false, image: false })

      //check the age of teachers 
      if (state.dob) {
        const birthdate = moment(new Date(state.dob), 'MM-YYYY');
        const now = moment();
        var date = moment.duration(now.diff(birthdate));
        if (date.years() < 1) {
          setError({ ...errorRef.current, dob: true })
        }
      }

      if (state.name && state.gender) {
        setLoadingButton(true);
        const newDate = state.dob ? moment(state.dob).format("YYYY-MM-DD") : null;
        state.dob = newDate;
        state.status = "published";
        if (!state.nickname) {
          state.nickname = state.name;
        }
        var result = await directus.createItem('teachers', state);

        const body_activity = {
          action: "Add",
          category: 5,
          description: state.name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          setLoadingButton(false);
          EventEmitter.emit('alert_toast', { content: Teacher_created_successfully, type: "success" });
          dialogAddTeacher.current.visible = false;
          return navigate('/teacher/profile/' + result.data.id);
        }

      } else {
        if (!state.name) { setError({ ...errorRef.current, name: true }) }
        if (!state.gender) { setError({ ...errorRef.current, gender: true }) }
        setLoadingButton(false);
      }
    }
  }


  // {/* ================== Handle Dialog Delete Teacher ========================================== */ }

  const handleOpenDialogDeleteTeacher = (props) => {
    dialogDeleteTeacher.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteTeacher = () => {
    dialogDeleteTeacher.current.visible = false;
    setDeleteWord('');
  }

  const handleChangeWord = (e) => {
    setDeleteWord(e.value);
  }

  const deleteTeacher = async () => {
    try {
      if (deleteWord === selectedData.name) {
        setLoadingButton(true);
        //set teacher status from published to draft
        var result = await directus.updateItem('teachers', selectedData.id, { status: "draft" });

        if (selectedData.user) {
          await directus.updateItem('directus_users', selectedData.user.id, { status: "draft" });
        }

        const body_activity = {
          action: "Delete",
          category: 5,
          description: selectedData.name,
          status: "published"
        }

        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          getData();
          setDataButton("delete_teacher");
        }
      } else if (deleteWord !== selectedData.name) {
        EventEmitter.emit('alert_toast', { content: Please_match_the_requested_format, type: "danger" });
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }


  // {/* ================== Handle Dialog Edit Account ========================================== */ }

  const handleOpenDialoEditAccount = (props) => {
    dialogEditAccount.current.visible = true;
    setSelectedAccount({
      data_id: props.id,
      user_id: props.user ? props.user.id : null,
      name: props.name,
      email: props.user ? props.user.email : props.email,
      password: null,
      mobile_number: props.mobile_number,
      lang: props.user ? props.user.locale === "en-US" ? "en" : "id" : "id"
    });
  }

  const handleCloseDialogEditAccount = () => {
    dialogEditAccount.current.visible = false;
  }

  const editAccount = async () => {
    if (!errorAccount.name && !errorAccount.email && !errorEmail) {
      setLoadingButton(true);
      setErrorAccount({ name: false, email: false, password: false })
      setErrorEmail(false);
      if (selectedAccount.name) {
        try {
          setLoadingButton(true);
          var split_name = selectedAccount.name.split(" ");
          var first_name = '';
          var last_name = '';
          first_name = split_name[0];
          if (split_name.length > 1) {
            for (let i = 1; i < split_name.length; i++) {
              last_name += split_name[i];
              if (i !== split_name.length - 1) {
                last_name += " ";
              }
            }
          }

          var body_user = {
            id: selectedAccount.user_id,
            first_name: first_name,
            last_name: last_name,
            locale: selectedAccount.lang === "id" ? "id-ID" : "en-US",
            email: selectedAccount.email,
            role: 1,
            status: "active",
          }

          if (selectedAccount.password) {
            body_user.password = selectedAccount.password
          }

          var result = await directus.updateItem('directus_users', body_user.id, body_user);

          if (result.data) {
            const body_data = {
              id: selectedAccount.data_id,
              name: selectedAccount.name,
              email: selectedAccount.email,
              mobile_number: selectedAccount.mobile_number,
              user: selectedAccount.user_id,
              status: "published",
            }

            var result_data = await directus.updateItem('teachers', body_data.id, body_data);

            const body_activity = {
              action: "Update",
              category: 5,
              description: "Account " + selectedAccount.name,
              status: "published"
            }
            await directus.createItem('activity_log', body_activity);

            if (!result_data.error) {
              setLoadingButton(false);
              EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
              dialogEditAccount.current.visible = false;
              getData();
            }
          }

        } catch (error) {
          if (error.message.includes("Failed generating the SQL query")) {
            setErrorEmail(true);
          } else {
            EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          }
          setLoadingButton(false);
        }
      } else {
        if (!selectedAccount.name) { setErrorAccount({ ...errorAccountRef.current, name: true }) }
        if (!selectedAccount.email) { setErrorAccount({ ...errorAccountRef.current, email: true }) }
        setLoadingButton(false);
      }
    }
  }

  const addAccount = async () => {
    if (!errorAccount.name && !errorAccount.email && !errorAccount.password && !errorEmail) {
      setLoadingButton(true);
      setErrorAccount({ name: false, email: false, password: false })
      setErrorEmail(false);
      if (selectedAccount.name && selectedAccount.email && selectedAccount.password) {
        try {
          setLoadingButton(true);
          var split_name = selectedAccount.name.split(" ");
          var first_name = '';
          var last_name = '';
          first_name = split_name[0];
          if (split_name.length > 1) {
            for (let i = 1; i < split_name.length; i++) {
              last_name += split_name[i];
              if (i !== split_name.length - 1) {
                last_name += " ";
              }
            }
          }

          var body_user = {
            first_name: first_name,
            last_name: last_name,
            locale: selectedAccount.lang === "id" ? "id-ID" : "en-US",
            email: selectedAccount.email,
            role: 6,
            status: "active",
          }

          if (selectedAccount.password) {
            body_user.password = selectedAccount.password
          }

          var result = await directus.createItem('directus_users', body_user);

          if (result.data) {
            const body_data = {
              id: selectedAccount.data_id,
              name: selectedAccount.name,
              email: selectedAccount.email,
              mobile_number: selectedAccount.mobile_number,
              user: result.data.id,
              status: "published",
            }

            var result_data = await directus.updateItem('teachers', body_data.id, body_data);

            const body_activity = {
              action: "Update",
              category: 5,
              description: "Account " + selectedAccount.name,
              status: "published"
            }
            await directus.createItem('activity_log', body_activity);

            if (!result_data.error) {
              setLoadingButton(false);
              EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
              dialogEditAccount.current.visible = false;
              getData();
            }
          }

        } catch (error) {
          if (error.message.includes("Duplicate")) {
            setErrorEmail(true);
          } else {
            EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          }
          setLoadingButton(false);
        }
      } else {
        if (!selectedAccount.name) { setErrorAccount({ ...errorAccountRef.current, name: true }) }
        if (!selectedAccount.email) { setErrorAccount({ ...errorAccountRef.current, email: true }) }
        if (!selectedAccount.password) { setErrorAccount({ ...errorAccountRef.current, password: true }) }
        setLoadingButton(false);
      }
    }
  }

  const handleChangeEditAccount = (event, type) => {
    var value = "";
    if (type === "lang") {
      value = event.itemData.id;
    } else {
      value = event.value;
    }

    if (type === "name" || type === "email") {
      if (value) {
        setErrorAccount({ ...errorAccountRef.current, [type]: false })
        if (type === "email") { setErrorEmail(false); }
      } else {
        setErrorAccount({ ...errorAccountRef.current, [type]: true })
      }
    }

    if (!selectedAccount.user_id) {
      if (type === "password") {
        setErrorAccount({ ...errorAccountRef.current, [type]: false })
      }
    }

    setSelectedAccount({ ...selectedAccount, [type]: value })
  }


  // {/* ================== Handle Upload File ========================================== */ }

  const uploadFile = async (args) => {
    if (args.operation === "upload") {
      try {
        const data = new FormData(my_form2.current);
        var result = await directus.uploadFiles(data, onUploadProgress);
        const imageId = result.data.id;
        setImage(result.data.data.full_url);
        setState({ ...state, image: imageId })
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      }
    } else if (args.operation === "remove") {
      cancelFile();
    }
  }

  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + "% Done");
  }

  const cancelFile = () => {
    setState({ ...state, image: null })
    setImage(link.default_image);
    setError({ ...error, image: false })
  }

  // {/* ================== Data Grid Template ========================================== */ }

  const nameTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <span className="image" style={{ width: "25px", height: "25px" }}>
          <img
            style={{
              maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
              objectFit: "cover", borderRadius: "50%", position: "center",
            }}
            className="image"
            src={props.image ? props.image.data.full_url : link.default_image}
            alt={props.name}
          />
        </span>
        <span style={{ paddingLeft: "20px", textAlign: "left" }}>{props.name}</span>
      </div>
    )
  };

  // const imageEditTemplate = props => {
  //   return (
  //     <div className="image" style={{ width: "50px", height: "50px" }}>
  //       <img
  //         style={{
  //           maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
  //           objectFit: "cover", border: "3px solid #d2d6de", borderRadius: "50%", position: "center",
  //         }}
  //         className="image"
  //         src={props.image ? props.image.data.full_url : link.default_image}
  //         alt={props.name}
  //       />
  //     </div>
  //   )
  // };

  const accountTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        {permission.AdminPermission() ? props.user ?
          <div style={{ display: "flex" }}>
            <div style={{ color: "#516573", fontSize: "17px" }} className="e-icons icon-check-4" />

            {permission.AdminPermission() ?
              <TooltipComponent content="Edit Account" position="BottomCenter" >
                <div style={{ marginLeft: "10px", cursor: "pointer", color: "#5F99E9", fontWeight: 600 }} onClick={() => handleOpenDialoEditAccount(props)}>Edit</div>
              </TooltipComponent>
              : null}
          </div>
          :
          <div style={{ display: "flex" }}>
            <div style={{ color: "#cfd8dc ", fontSize: "15px" }} className="e-icons icon-close-large-1" />
            {permission.AdminPermission() ?
              <TooltipComponent content="Add Account" position="BottomCenter" >
                <div style={{ marginLeft: "10px", cursor: "pointer", color: "#5F99E9", fontWeight: 600 }} onClick={() => handleOpenDialoEditAccount(props)}>Add</div>
              </TooltipComponent>
              : null}
          </div>
          : null}
      </div>
    )
  }

  const buttonTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        {permission.AdminPermission() ?
          <div className="action_hover" style={{ display: "flex" }}>
            <TooltipComponent content={Preview} position="BottomCenter" >
              <span>
                <Link to={{ pathname: `/teacher/profile/${props.id}` }} style={{ textDecoration: "none" }}>
                  <span className="e-icons icon-MT_Preview"
                    style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
                  />
                </Link>
              </span>
            </TooltipComponent>

            <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px", }} >
              <span className="e-icons icon-delete-05"
                style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => handleOpenDialogDeleteTeacher(props)}
              />
            </TooltipComponent>
          </div>
          : null}
      </div>
    )
  }

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const buttonEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter" >
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  // {/* ================== Data Grid Action (edit) ========================================== */ }

  const actionGrid = async (event) => {
    if (event.action === "edit" && event.requestType === "save") {
      if (cancelFlag === false) {
        try {
          if (event.previousData.email && !event.data.email) {
            EventEmitter.emit('alert_toast', { content: "This email can't be deleted. Please delete the account, first.", type: "danger" });
            grid.current.editModule.closeEdit();
          } else {
            const data = event.data;
            const body_data = {
              id: data.id,
              name: data.name,
              nickname: data.nickname,
              email: data.email,
              mobile_number: data.mobile_number
            }

            if (data.user) {
              var split_name = data.name.split(" ");
              var first_name = '';
              var last_name = '';
              first_name = split_name[0];
              if (split_name.length > 1) {
                for (let i = 1; i < split_name.length; i++) {
                  last_name += split_name[i];
                  if (i !== split_name.length - 1) {
                    last_name += " ";
                  }
                }
              }

              if (data.email !== data.user.email || first_name !== data.user.first_name || last_name !== data.user.last_name) {
                var body_user = {
                  id: data.user.id,
                  first_name: first_name,
                  last_name: last_name,
                  email: data.email,
                }

                var result = await directus.updateItem('directus_users', body_user.id, body_user);

                if (result.data) {
                  var result_data = await directus.updateItem('teachers', body_data.id, body_data);

                  const body_activity = {
                    action: "Update",
                    category: 14,
                    description: state.name,
                    status: "published"
                  }
                  await directus.createItem('activity_log', body_activity);

                  if (!result_data.error) {
                    setLoadingButton(false);
                    EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
                    getData();
                  }
                }

              }
            } else {
              await directus.updateItem('teachers', data.id, body_data);
            }

            var description = data.name;

            if (data.name !== event.previousData.name) {
              description = event.previousData.name + " --> " + data.name;
            }

            const body_activity = {
              action: "Add",
              category: 5,
              description: description,
              status: "published"
            }
            var result2 = await directus.createItem('activity_log', body_activity);

            if (!result2.error) {
              getData();
              EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
            }
          }

        } catch (error) {
          if (error.message.includes("Failed generating the SQL query")) {
            EventEmitter.emit('alert_toast', { content: Email_has_already_been_taken, type: "danger" });
          } else {
            EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          }
          getData();
        }
      } else {
        grid.current.editModule.closeEdit()
      }
      cancelFlag = false;

    } else if (event.requestType === "beginEdit") {
      cancelFlag = true;
    }
  }

  const changeNumOfData = (e) => {
    var value = e.value ? e.value : 1;
    setNumberOfPage(value);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      numOfPage.teacher = value;
      window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
      getData();
    }, 1000)
  }

  // {/* ================== Pagination ========================================== */ }

  const pageTemplate = () => {
    var list = [];
    if (dataMeta) {
      for (var i = 0; i < dataMeta.page_count; i++) {
        const page = i + 1;
        var class_name = "e-link e-numericitem e-spacing e-pager-default";
        if (dataMeta.page === page) {
          class_name = "e-link e-numericitem e-spacing e-currentitem e-active"
        }
        var display = "none";
        if (page <= 5) { display = ""; }
        list.push(<div key={"page-" + page}><span className={class_name} id={"page-" + page} role="link" tabIndex="-1" aria-label={'Goto Page ' + page} onClick={() => clickPage(page, "num")} name={'Goto Page ' + page} aria-owns="grid_940258773_0_content_table" style={{ display: display }} >{page}</span></div>)
      }
    }

    //check page 
    var check_page = [];
    if (dataMeta.page_count <= 5) {
      for (var j = 0; j < dataMeta.page_count; j++) { check_page.push(j + 1) }
    } else {
      if (dataMeta.page <= 3) {
        for (var k = 0; k < 5; k++) { check_page.push(k + 1) }
      } else if (dataMeta.page >= dataMeta.page_count - 2) {
        for (var l = dataMeta.page_count; l > dataMeta.page_count - 5; l--) { check_page.push(l) }
      } else {
        var list_page = [dataMeta.page - 2, dataMeta.page - 1, dataMeta.page, dataMeta.page + 1, dataMeta.page + 2];
        list_page.forEach((value) => {
          check_page.push(value)
        })
      }
    }

    var select_page2 = document.querySelectorAll('.e-numericitem');
    if (select_page2.length > 0) {
      if (select_page2.length > 0) {
        for (var index = 0; index < select_page2.length; index++) {
          select_page2[index].style.display = "none";
        }
      }

      if (check_page.length > 0) {
        //eslint-disable-next-line array-callback-return
        check_page.map((value) => {
          var select_page = document.querySelectorAll(`[id=page-${value}]`);
          if (select_page.length > 0) {
            for (var index2 = 0; index2 < select_page.length; index2++) {
              select_page[index2].style.display = "";
            }
          }
        })
      }
    }

    return (
      <div style={{ height: "35px", textAlign: "center" }}>
        <div className="e-gridpager e-control e-pager e-lib" style={{ display: "flex", width: "100%" }} >
          <div style={{ width: "32.5%", background: "#E1E7EB", borderRadius: "17.5px 5px 5px 17.5px" }}>
            <div className="e-pagercontainer" role="navigation" >
              <div className={dataMeta.page === 1 ? "e-first e-icons e-icon-first e-firstpagedisabled e-disable" : "e-first e-icons e-icon-first e-firstpage e-pager-default"} onClick={() => clickPage(1, "first")} title="Go to first page" aria-label="Go to first page" tabIndex="-1" index="1" aria-owns="grid_940258773_0_content_table"></div>
              <div className={dataMeta.page === 1 ? "e-prev e-icons e-icon-prev e-prevpagedisabled e-disable" : "e-prev e-icons e-icon-prev e-prevpage e-pager-default"} onClick={() => clickPage(dataMeta.page - 1, "prev")} title="Go to previous page" aria-label="Go to previous page" tabIndex="-1" index="0" aria-owns="grid_940258773_0_content_table"></div>
              <div className="e-numericcontainer">{list}</div>
              <div className={dataMeta.page === dataMeta.page_count ? "e-next e-icons e-icon-next e-nextpagedisabled e-disable" : "e-next e-icons e-icon-next e-nextpage e-pager-default"} onClick={() => clickPage(dataMeta.page + 1, "next")} title="Go to next page" aria-label="Go to next page" tabIndex="-1" index="2" aria-owns="grid_940258773_0_content_table"></div>
              <div className={dataMeta.page === dataMeta.page_count ? "e-last e-icons e-icon-last e-lastpagedisabled e-disable" : "e-last e-icons e-icon-last e-lastpage e-pager-default"} onClick={() => clickPage(dataMeta.page_count, "last")} title="Go to last page" aria-label="Go to last page" tabIndex="-1" index="2" aria-owns="grid_940258773_0_content_table"></div>
            </div>
          </div>
          <div style={{ width: "35%", background: "#E1E7EB", marginLeft: "2px", marginRight: "2px", borderRadius: "5px" }}>
            <div className="e-pagerconstant">
              <span className="e-constant" style={{ display: "flex" }}>
                <span style={{ marginRight: "6px" }}>
                  <span style={{ display: "flex", flexDirection: "column", position: "absolute", marginTop: "7px" }}>
                    <span className="e-icons icon-arrowhead-up"
                      style={{ cursor: "pointer", color: '#516573', fontSize: "10px" }}
                      onClick={() => handleNumberOfPage("+")}
                    />
                    <span className="e-icons icon-arrowhead-down"
                      style={{ cursor: "pointer", color: '#516573', fontSize: "10px" }}
                      onClick={() => handleNumberOfPage("-")}
                    />
                  </span>
                </span>
                <span className="nt_custom" style={{ fontSize: "17px", fontWeight: "800", color: "#516573", width: "35px", marginRight: "6px", marginLeft: "6px" }}>
                  <NumericTextBoxComponent style={{ textAlign: "center", padding: "0px" }} step={5} format='####' value={numberOfPageRef.current} showSpinButton={false} max={999} min={1} onChange={(e) => changeNumOfData(e)} />
                </span>
                <span style={{ color: "#516573" }}>
                  {Items_per_page} (total {dataMeta ? dataMeta.filter_count : 0} records)
                </span>
              </span>
            </div>
          </div>
          <div className="e-parentmsgbar" style={{ width: "32.5%", background: "#E1E7EB", borderRadius: "5px 17.5px 17.5px 5px" }}>
            <span className="e-pagenomsg">{dataMeta ? <span>{dataMeta.page} {of} {dataMeta.page_count} {pages} </span> : null}</span>
          </div>
        </div>
      </div>
    )
  }

  const handleNumberOfPage = (props) => {
    if (numberOfPageRef.current - 1 > 0) {
      var value = numberOfPageRef.current;
      if (props === "+") {
        value = numberOfPageRef.current + 5;
      } else {
        if (numberOfPageRef.current > 5) {
          value = numberOfPageRef.current - 5;
        } else {
          value = 1;
        }
      }
      setNumberOfPage(value);
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        numOfPage.teacher = value;
        window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
        getData();
      }, 1000)
    }
  }

  const clickPage = (page, type) => {
    var status = false;
    if (type === "num") {
      if (dataMeta.page !== page) { status = true; }
    } else if (type === "first" || type === "prev") {
      if (dataMeta.page !== 1) { status = true; }
    } else if (type === "next" || type === "last") {
      if (dataMeta.page !== dataMeta.page_count) { status = true; }
    }

    if (status) {
      setStateFiltering({ ...stateFilteringRef.current, page: page })
      getData();
    }
  }

  const onColumnMenuOpen = (args) => {
    for (const item of args.items) {
      if (item.text === 'Autofit all columns' || item.text === 'Autofit this column') {
        item.hide = true;
      }
    }
  }

  // {/* ================== Footer Template ========================================== */}

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }
  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      cancelFlag = false;
      grid.current.endEdit();
    }
  }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div>
            <div className="header">
              <div className="content-header">{Teacher_List}</div>

              <div className="content-searching">
                <div style={{ display: 'flex', width: "100%" }}>
                  <div style={{ width: "250px", marginRight: "10px" }}>
                    <TextBoxComponent
                      cssClass="e-outline searching-text"
                      className="searching-input"
                      id="searching-input"
                      placeholder={Enter_teacher_name}
                      onChange={(e) => handleChangeFilter(e, "name")}
                      onBlur={(e) => searchTeacher(e)}
                      showClearButton="true"
                      value={stateFiltering.name}
                    />
                  </div>
                  <div>
                    <ButtonComponent
                      iconCss="e2-list"
                      style={{ width: "30px", height: "30px", color: view === 'Card' ? '#516573' : 'white', backgroundColor: view === 'Card' ? '#FFFFFF' : '#5F99E9', borderRadius: "5px 0px 0px 5px" }}
                      onClick={() => changeView('Grid')} ></ButtonComponent>

                    <ButtonComponent
                      iconCss="e2-th-large"
                      style={{ width: "30px", height: "30px", color: view === 'Grid' ? '#516573' : 'white', backgroundColor: view === 'Grid' ? '#FFFFFF' : '#5F99E9', borderRadius: "0px 5px 5px 0px" }}
                      onClick={() => changeView('Card')} ></ButtonComponent>
                  </div>
                  {permission.AdminPermission() ?
                    <div style={{ marginLeft: "20px" }}>
                      <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddTeacher}>
                        <span className="btn-text-custom">{Teacher}</span>
                      </ButtonComponent>
                    </div>
                    : null}
                </div>
              </div>
            </div>

            <div className="content-main">
              {data ? data.length > 0 ?
                <div className="control-menu">
                  {/* ================== Card View ========================================== */}
                  <div style={{ display: view === 'Card' ? 'block' : 'none', marginTop: "30px", marginRight: "-15px" }}>
                    <div className="control-section card-control-section tile_layout">
                      <div className='row e-card-layout' style={{ textAlign: 'center' }}>
                        {/* edit marginleft marginright here */}
                        {/* <div className="row" > */}
                        <div className='card-list' style={{ display: "flex", flexWrap: "wrap", overflow: "auto", alignItems: "flex-start", alignContent: "flex-start", justifyContent: "center" }}>
                          {data ? data.length > 0 ? data.map((value, index) => (
                            <div style={{ width: "auto", marginLeft: "30px", height: "220px", marginBottom: "30px" }} key={index}>
                              <div id={'card_teacher_' + (index + 1)} className='card_sample' style={{ width: "300px" }}></div>
                            </div>
                          )) : null : null}
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>


                  {/* ================== Grid View ========================================== */}

                  <div style={{ display: view === 'Grid' ? 'block' : 'none', marginTop: "25px", marginBottom: "20px", marginLeft: "30px", marginRight: "0px" }}>
                    <div className="grid-container">
                      <div>
                        <GridComponent
                          width="100%"
                          className="grid-list"
                          dataSource={data}
                          editSettings={editSettings}
                          allowSorting={true}
                          filterSettings={filterOptions}
                          allowFiltering={true}
                          ref={grid}
                          allowTextWrap={true}
                          textWrapSettings={{ wrapMode: "Content" }}
                          allowResizing={true}
                          gridLines='Vertical'
                          showColumnMenu={true}
                          columnMenuOpen={onColumnMenuOpen}
                          actionBegin={actionGrid}
                          selectionSettings={setting}
                          clipMode="EllipsisWithTooltip"
                          keyPressed={onKeyPressed}
                        >
                          <ColumnsDirective>
                            {/* <ColumnDirective customAttributes={{ id: 'first' }} width="10%" headerText={Photo} textAlign='Center' editTemplate={imageEditTemplate} template={imageEditTemplate}></ColumnDirective> */}
                            <ColumnDirective customAttributes={{ id: 'first' }} width="26%" field='name' headerText={Name} template={nameTemplate} validationRules={{ required: true }}></ColumnDirective>
                            <ColumnDirective width="13%" field='nickname' headerText={Nickname} validationRules={{ required: true }}></ColumnDirective>
                            <ColumnDirective width="18%" field='email' headerText={Email}></ColumnDirective>
                            <ColumnDirective width="13%" field='mobile_number' headerText={Mobile_Number} ></ColumnDirective>
                            {permission.AdminPermission() ? <ColumnDirective clipMode="Ellipsis" width="10%" headerText="Account" template={accountTemplate} editTemplate={accountTemplate}></ColumnDirective> : null}
                            <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="10%" template={buttonTemplate} editTemplate={buttonEditTemplate}></ColumnDirective>
                          </ColumnsDirective>
                          <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                        </GridComponent>
                      </div>
                    </div>
                  </div>
                </div>

                : <div style={{ textAlign: "center", marginTop: "30px" }}>{No_data_found}</div>
                : <div style={{ textAlign: "center", marginTop: "30px" }}>{No_data_found}</div>
              }
            </div>

            {/* {dataMeta ? dataMeta.filter_count > 15 ? pageTemplate() : null : null} */}
            <div className="content-pagination">
              <div className="content-pagination-inside">
                {dataMeta ? dataMeta.filter_count > 0 ? pageTemplate() : null : null}
              </div>
            </div>
            {/* ================== Dialog ========================================== */}

            <DialogComponent
              id="dialogAddTeacher"
              width="30%"
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogAddTeacher}
              header={Teacher_Biodata}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Update, func: addTeacher, icon: "icon-check-mark-01", status: "e-success" },
                { name: Cancel, func: handleCloseDialogAddTeacher, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogAddTeacher}
            >
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="image" style={{ width: "150px", height: "150px" }}>
                    <img
                      style={{
                        maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                        objectFit: "cover", position: "center",
                      }}
                      src={image}
                      alt="teacher_photo"
                    />
                  </div>
                </div>
                <form ref={my_form2}>
                  <UploaderComponent
                    id='previewfileupload'
                    type='file'
                    ref={uploadObj}
                    asyncSettings={asyncSettings}
                    success={uploadFile}
                    multiple={false}
                    autoUpload={false}
                    clearing={cancelFile}
                    removing={cancelFile}
                  >
                  </UploaderComponent>
                  {error.image === true ? <span style={{ color: 'red' }}>{Please_upload_the_photo}</span> : ''}
                </form>
                <br />
                <div>
                  <b>NIK</b>
                  <TextBoxComponent
                    name="nik"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "nik")}
                    value={state.nik}
                  />
                  <br /><br />
                  <b>*{Name}</b>
                  <TextBoxComponent
                    name="name"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "name")}
                    value={state.name}
                  />
                  {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <b>{Degree}</b>
                  <TextBoxComponent
                    name="degree"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "degree")}
                    value={state.degree}
                  />
                  <br /><br />
                  <b>{Nickname}</b>
                  <TextBoxComponent
                    name="nickname"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "nickname")}
                    value={state.nickname}
                  />
                  <br /><br />
                  <b>{Email}</b>
                  <TextBoxComponent
                    name="email"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "email")}
                    value={state.email}
                  />
                  <br /><br />
                  <b>{Place_of_Birth}</b>
                  <TextBoxComponent
                    name="pob"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "pob")}
                    value={state.pob}
                  />
                  <br /><br />
                  <b>{Date_of_Birth}</b>
                  <DatePickerComponent
                    cssClass="e-outline"
                    name="dob"
                    format='dd-MMM-yyyy'
                    change={(e) => handleChangeAddTeacher(e, "dob")}
                    value={state.dob}
                  />
                  <br /><br />
                  <b>{Gender}</b>
                  <DropDownListComponent
                    dataSource={gender}
                    name="gender"
                    cssClass='e-outline'
                    fields={{ text: 'name', value: 'id' }}
                    placeholder={`--${Select}--`}
                    select={(e) => handleChangeAddTeacher(e, "gender")}
                    value={state.gender}
                  />
                  {error.gender === true ? <span style={{ color: 'red' }}>{Please_select_gender}</span> : ''}
                  <br /><br />
                  <b>{Religion}</b>
                  <DropDownListComponent
                    name="religion"
                    cssClass="e-outline"
                    dataSource={religion}
                    fields={{ text: 'text', value: 'id' }}
                    placeholder={`--${Select}--`}
                    select={(e) => handleChangeAddTeacher(e, "religion")}
                    value={state.religion}
                  />
                  <br /><br />
                  <b>{KTP_Number}</b>
                  <TextBoxComponent
                    name="ktp_number"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "ktp_number")}
                    value={state.ktp_number}
                  />
                  <br /><br />
                  <b>{KTP_Expiry_Date}</b>
                  <TextBoxComponent
                    name="ktp_expiry_date"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "ktp_expiry_date")}
                    value={state.ktp_expiry_date}
                  />
                  <br /><br />
                  <b>{Address}</b>
                  <TextBoxComponent
                    name="address"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "address")}
                    value={state.address}
                  />
                  <br /><br />
                  <b>{Position}</b>
                  <TextBoxComponent
                    name="job_role"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "job_role")}
                    value={state.job_role}
                  />
                  <br /><br />
                  <b>{Field_of_Study}</b>
                  <TextBoxComponent
                    name="department"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "department")}
                    value={state.department}
                  />
                  <br /><br />
                  <b>{Marital_Status}</b>
                  <DropDownListComponent
                    name="marital_status"
                    cssClass="e-outline"
                    dataSource={marital_status}
                    fields={{ text: 'text', value: 'id' }}
                    placeholder={`--${Select}--`}
                    select={(e) => handleChangeAddTeacher(e, "marital_status")}
                    value={state.marital_status}
                  />
                  <br /><br />
                  <b>{Name_of_Wife_Husband}</b>
                  <TextBoxComponent
                    name="marriage_partner_name"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "marriage_partner_name")}
                    value={state.marriage_partner_name}
                  />
                  <br /><br />
                  <b>{Number_of_Children}</b>
                  <TextBoxComponent
                    name="number_of_children"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "number_of_children")}
                    value={state.number_of_children}
                  />
                  <br /><br />
                  <b>{Mobile_Number}</b>
                  <TextBoxComponent
                    name="mobile_number"
                    cssClass='e-outline'
                    change={(e) => handleChangeAddTeacher(e, "mobile_number")}
                    value={state.mobile_number}
                  />
                </div>
              </div>
            </DialogComponent>


            <DialogComponent
              id="dialogDeleteTeacher"
              width='35%'
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogDeleteTeacher}
              header={Delete_Confirmation}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Delete, func: deleteTeacher, icon: "icon-delete-05", status: "e-warning" },
                { name: Cancel, func: handleCloseDialogDeleteTeacher, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogDeleteTeacher}
            >
              <div>
                {selectedData ?
                  <div style={{ fontSize: "14px" }}>
                    {Are_you_sure_you_want_to_delete_the_data_of_teacher} ({selectedData.name})?
                    <br /><br />
                    <p style={{ color: "red" }}>
                      {If_you_want_to_delete_the_teacher_fill_in_the_name_below}:
                    </p>
                    <TextBoxComponent
                      cssClass="e-outline"
                      name="delete_teacher"
                      change={(e) => handleChangeWord(e)}
                      value={deleteWord}
                    ></TextBoxComponent>
                  </div>
                  : ''}
              </div>
            </DialogComponent>


            <DialogComponent
              id="dialogEditAccount"
              width='35%'
              showCloseIcon={!loadingButton ? true : false}
              visible={false}
              ref={dialogEditAccount}
              header={selectedAccount ? selectedAccount.user_id ? "Edit Account" : "Add Account" : null}
              isModal={true}
              footerTemplate={() => footerTemplate([
                { name: Update, func: selectedAccount ? selectedAccount.user_id ? editAccount : addAccount : '', icon: "icon-check-mark-01", status: "e-success" },
                { name: Cancel, func: handleCloseDialogEditAccount, icon: "icon-treeview", status: "" }
              ])}
              close={handleCloseDialogEditAccount}
            >
              <div>
                {selectedAccount ?
                  <div>
                    <b>*{Name}</b>
                    <TextBoxComponent
                      name="name"
                      cssClass='e-outline'
                      change={(e) => handleChangeEditAccount(e, "name")}
                      value={selectedAccount.name}
                    />
                    {errorAccount.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                    <br /><br />
                    <b>*{Email}</b>
                    <TextBoxComponent
                      name="email"
                      cssClass='e-outline'
                      change={(e) => handleChangeEditAccount(e, "email")}
                      value={selectedAccount.email}
                    />
                    {errorAccount.email === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                    {errorEmail === true ? <span style={{ color: 'red' }}>{Email_has_already_been_taken}</span> : ''}
                    <br /><br />
                    <b>*Password</b>
                    <TextBoxComponent
                      type="password"
                      name="password"
                      cssClass='e-outline'
                      change={(e) => handleChangeEditAccount(e, "password")}
                      value={selectedAccount.password}
                    />
                    {errorAccount.password === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                    <br /><br />
                    <b>{Mobile_Number}</b>
                    <TextBoxComponent
                      name="mobile_number"
                      cssClass='e-outline'
                      change={(e) => handleChangeEditAccount(e, "mobile_number")}
                      value={selectedAccount.mobile_number}
                    />
                    <br /><br />
                    <b>{Language}</b>
                    <DropDownListComponent
                      name="lang"
                      cssClass="e-outline"
                      dataSource={[{ id: "id", name: "Indonesia" }, { id: "en", name: "English" }]}
                      fields={{ text: 'name', value: 'id' }}
                      value={selectedAccount.lang}
                      select={(e) => handleChangeEditAccount(e, "lang")}
                    ></DropDownListComponent>
                  </div>
                  : null}
              </div>
            </DialogComponent>
          </div>
        </div>
      }
    </div>
  );
}



export default TeacherList;