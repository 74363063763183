import React, { useState, useEffect } from 'react';
import EventEmitter from 'src/utils/EventEmitter';
import useStateRef from "react-usestateref";

import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import CircularProgress from '@material-ui/core/CircularProgress';

import directus from 'src/services/directus';
import i18next from "i18next";
import { Typography } from '@material-ui/core';

const Profile = () => {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ id: null, email: null, password: null, name: null, phone: null, lang: null });

  const [User_Profile] = useState(i18next.t('Pages.profile.User_Profile'));
  const [Name] = useState(i18next.t('Pages.profile.Name'));
  const [Language] = useState(i18next.t('Pages.profile.Language'));
  const [Profile_updated_successfully] = useState(i18next.t('Pages.profile.Profile_updated_successfully'));
  const [Roles] = useState(i18next.t('Pages.profile.Roles'));
  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Mobile_Number] = useState(i18next.t('Pages.data.Mobile_Number'));
  const [Email_has_already_been_taken] = useState(i18next.t('Pages.staff_data.Email_has_already_been_taken'));

  const [error, setError, errorRef] = useStateRef({
    name: false,
    password: false
  });

  const [errorEmail, setErrorEmail] = useState(false);
  const school_list = JSON.parse(window.localStorage.school_list);

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  useEffect(() => {
    const changeProfile = () => { getData(); }
    const listener = EventEmitter.addListener('changeProfile', changeProfile);
    return () => {
      listener.remove();
    }
  })

  const getData = async () => {
    try {

      var user_setting = JSON.parse(window.localStorage.getItem('user_setting'));
      var directus_user = JSON.parse(window.localStorage.getItem('directus_user'));
      setState({
        account_type: user_setting.account_type,
        user_id: user_setting.user_id,
        data_id: user_setting.data_id,
        email: directus_user.email,
        password: null,
        mobile_number: user_setting.mobile_number,
        name: user_setting.name,
        lang: user_setting.lang
      })

      setLoading(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }


  const handleChange = (event, type) => {
    var value = "";
    if (type === "lang") {
      value = event.itemData.id;
    } else {
      value = event.value;
    }

    if ((type === "name" || type === "email") && value) {
      setError({ ...errorRef.current, [type]: false })
      setErrorEmail(false);
    }

    setState({ ...state, [type]: value })
  }

  const handleUpdate = async () => {
    try {
      if (state.name && state.email) {
        setErrorEmail(false);
        setError({ name: false, password: false })

        var split_name = state.name.split(" ");
        var first_name = '';
        var last_name = '';
        first_name = split_name[0];
        if (split_name.length > 1) {
          for (let i = 1; i < split_name.length; i++) {
            last_name += split_name[i];
            if (i !== split_name.length - 1) {
              last_name += " ";
            }
          }
        }

        if (state.account_type !== "administrator") {
          var body_data = {
            id: state.data_id,
            mobile_number: state.mobile_number,
            name: state.name,
            email: state.email
          }

          var user_setting = null;
          if (state.account_type === "staff") { user_setting = "staff" }
          else if (state.account_type === "student") { user_setting = "students" }
          else if (state.account_type === "teacher") { user_setting = "teachers" }
          else if (state.account_type === "contact") { user_setting = "contacts" }

          await directus.updateItem(user_setting, body_data.id, body_data);
        }

        var lang = state.lang === "en" ? "en-US" : "id-ID";
        var body_user = {
          id: state.user_id,
          first_name: first_name,
          last_name: last_name,
          locale: lang,
          email: state.email
        }

        if (state.password) {
          body_user.password = state.password
        }

        await directus.updateItem("directus_users", state.user_id, body_user);

        const body_activity = {
          action: "Update",
          category: 11,
          description: state.email,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        var directus_data = JSON.parse(localStorage.directus_data);
        directus_data.user.first_name = first_name;
        directus_data.user.last_name = last_name;
        directus_data.user.locale = lang;
        directus_data.user.email = state.email;

        window.localStorage.setItem('directus_data', JSON.stringify(directus_data));
        window.localStorage.setItem("lang", state.lang);


        // var user_setting2 = null;
        var user = directus_data;

        // if (user.account_type === "staff") { user_setting2 = "staff" }
        // else if (user.account_type === "student") { user_setting2 = "students" }
        // else if (user.account_type === "teacher") { user_setting2 = "teachers" }
        // else if (user.account_type === "contact") { user_setting2 = "contacts" }

        var setting = null;
        if (user.account_type === "administrator") {

          setting = {
            account_type: "administrator",
            user_id: user.user.id,
            data_id: null,
            email: state.email,
            name: first_name + " " + last_name,
            mobile_number: null,
            lang: state.lang
          }

          window.localStorage.setItem('user_setting', JSON.stringify(setting));

          var school_list = JSON.parse(localStorage.school_list);
          for (let i = 0; i < school_list.length; i++) {
            if (school_list[i].school.domain === user.school.domain) {
              school_list[i] = user;
            }
          }

          localStorage.setItem('school_list', JSON.stringify(school_list));

        } else {
          var user_setting3 = null;
          if (user.account_type === "staff") { user_setting3 = "staff" }
          else if (user.account_type === "student") { user_setting3 = "students" }
          else if (user.account_type === "teacher") { user_setting3 = "teachers" }
          else if (user.account_type === "contact") { user_setting3 = "contacts" }

          var result = await directus.getItems(user_setting3, {
            fields: "*.*",
            filter: {
              "user.id": user.user.id
            }
          });

          var new_data = result.data[0];
          setting = {
            account_type: user.account_type,
            user_id: user.user.id,
            data_id: new_data.id,
            email: new_data.email,
            name: new_data.name,
            mobile_number: new_data.mobile_number,
            lang: state.lang ? state.lang : "id"
          }
          window.localStorage.setItem('user_setting', JSON.stringify(setting));

          var school_list2 = JSON.parse(localStorage.school_list);
          for (let i = 0; i < school_list2.length; i++) {
            if (school_list2[i].school.domain === user.school.domain) {
              school_list2[i] = user;
            }
          }

          localStorage.setItem('school_list', JSON.stringify(school_list2));

        }

        EventEmitter.emit('alert_toast', { content: Profile_updated_successfully, type: "success" });
        setTimeout(function () { window.location.reload(); }, 2000);
      } else {
        if (!state.name) { setError({ ...errorRef.current, name: true }) }
        if (!state.email) { setError({ ...errorRef.current, email: true }) }
      }
    } catch (error) {
      if (error.message.includes("generating")) {
        setErrorEmail(true);
      } else {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      }
    }
  }

  const getRoles = () => {
    const list = [];

    //eslint-disable-next-line array-callback-return
    school_list.map((value, index) => {
      list.push(
        <div key={index} style={{ marginTop: "10px" }}>
          <div style={{ fontWeight: "700" }}>{value.school.name ? value.school.name.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : ''}</div>
          <div style={{ fontWeight: "500" }}>{value.account_type ? value.account_type.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : ''}</div>
        </div>
      )
    })

    return (<div>{list}</div>)
  }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div style={{ fontFamily: "'Manrope', sans-serif", color: "#516573", size: "16px", fontWeight: "800" }}>
          <div className="header">
            <div className="content-header">{User_Profile}</div>
          </div>
          <div className="content-main">
            <div style={{ width: "234px", marginTop: "25px" }}>
              <div className="custom-text-height"><span className="custom-text">Email</span></div>
              <TextBoxComponent
                name="email"
                type="email"
                cssClass="e-outline searching-text"
                className="searching-input"
                id="searching-input"
                change={(e) => handleChange(e, "email")}
                value={state.email}
              />
              {error.email === true ? <span style={{ color: 'red', fontWeight: 500 }}>*{This_data_is_required}</span> : ''}
              {errorEmail === true ? <span style={{ color: 'red' }}>{Email_has_already_been_taken}</span> : ''}
              <br />
              <div className="custom-text-height"><span className="custom-text">Password</span></div>
              <TextBoxComponent
                name="password"
                cssClass="e-outline searching-text"
                className="searching-input"
                id="searching-input"
                change={(e) => handleChange(e, "password")}
                type="password"
              />
              <br />
              <div className="custom-text-height"><span className="custom-text">{Name}</span></div>
              <TextBoxComponent
                name="name"
                cssClass="e-outline searching-text"
                className="searching-input"
                id="searching-input"
                change={(e) => handleChange(e, "name")}
                value={state.name}
              />
              {error.name === true ? <span style={{ color: 'red', fontWeight: 500 }}>*{This_data_is_required}</span> : ''}
              <br />
              {state.account_type !== "administrator" ?
                <div>
                  <div className="custom-text-height"><span className="custom-text">{Mobile_Number}</span></div>
                  <TextBoxComponent
                    name="mobile_number"
                    cssClass="e-outline searching-text"
                    className="searching-input"
                    id="searching-input"
                    change={(e) => handleChange(e, "mobile_number")}
                    value={state.mobile_number}
                  />
                  <br />
                </div>
                : ''}
              <div className="custom-text-height"><span className="custom-text">{Language}</span></div>
              <DropDownListComponent
                id="ddl_custom2"
                name="lang"
                cssClass="e-outline"
                dataSource={[{ id: "id", name: "Indonesia" }, { id: "en", name: "English" }]}
                fields={{ text: 'name', value: 'id' }}
                value={state.lang}
                select={(e) => handleChange(e, "lang")}
              ></DropDownListComponent>
            </div>
            <div style={{ marginTop: "20px" }}>
              <ButtonComponent
                className="btn-custom"
                iconCss='e-icons icon-MT_Download'
                onClick={handleUpdate}
              >
                <span className="btn-text-custom">
                  {Update}
                </span>
              </ButtonComponent>
            </div>

            {school_list ?
              <div style={{ marginTop: "20px" }}>
                <div style={{ fontSize: "24px", fontWeight: "700" }}>{Roles}</div>
                {getRoles()}
              </div>
              : null}
            <br />
            <div><Typography style={{ fontSize: "18px", fontWeight: "500" }}>Version 2.0.1</Typography></div>
          </div>
        </div>
      }
    </div>
  );
}

export default Profile;