import React, { useState, useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { useNavigate, Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent, UploaderComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Query } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, DetailRow, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as link from 'src/constants/link';
import './index.css';
import * as permission from 'src/utils/Permission';
import CardRender from './studentCardRender';

let cancelFlag = false;
const StudentList = () => {
  /* eslint-disable no-unused-vars*/
  const navigate = useNavigate();

  var searchTimeout = null;

  var numOfPage = JSON.parse(window.localStorage.getItem('number_of_page'));
  var numStudent = numOfPage ? numOfPage.student ? numOfPage.student : 15 : 15;
  if (!numOfPage) {
    window.localStorage.setItem('number_of_page', JSON.stringify({ student: 15 }));
  } else {
    if (!numOfPage.student) {
      numOfPage.student = 15;
      window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
    }
  }

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));
  const [Search] = useState(i18next.t('Pages.button.Search'));
  const [Clear] = useState(i18next.t('Pages.button.Clear'));
  const [Preview] = useState(i18next.t('Pages.button.Preview'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [Please_match_the_requested_format] = useState(i18next.t('Pages.general.Please_match_the_requested_format'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));
  const [Items_per_page] = useState(i18next.t('Pages.general.Items_per_page'));
  const [of] = useState(i18next.t('Pages.general.of'));
  const [pages] = useState(i18next.t('Pages.general.pages'));
  // const [Total] = useState(i18next.t('Pages.general.Total'));

  const [Name] = useState(i18next.t('Pages.data.Name'));
  const [Nickname] = useState(i18next.t('Pages.data.Nickname'));
  const [Gender] = useState(i18next.t('Pages.data.Gender'));
  const [Place_of_Birth] = useState(i18next.t('Pages.data.Place_of_Birth'));
  const [Date_of_Birth] = useState(i18next.t('Pages.data.Date_of_Birth'));
  // const [Action] = useState(i18next.t('Pages.data.Action'));
  const [Select_Teacher] = useState(i18next.t('Pages.data.Select_Teacher'));
  const [Select_Subject] = useState(i18next.t('Pages.data.Select_Subject'));
  const [All_Teachers] = useState(i18next.t('Pages.data.All_Teachers'));
  const [All_Classes] = useState(i18next.t('Pages.data.All_Classes'));
  const [All_Students] = useState(i18next.t('Pages.data.All_Students'));
  const [Please_upload_the_photo] = useState(i18next.t('Pages.data.Please_upload_the_photo'));
  const [Please_select_gender] = useState(i18next.t('Pages.data.Please_select_gender'));
  const [Male] = useState(i18next.t('Pages.data.Male'));
  const [Female] = useState(i18next.t('Pages.data.Female'));
  const [Select] = useState(i18next.t('Pages.data.Select'));
  const [Student] = useState(i18next.t('Pages.data.Student'));

  const [Student_List] = useState(i18next.t('Pages.student_data.Student_List'));
  const [Advanced_Search] = useState(i18next.t('Pages.student_data.Advanced_Search'));
  const [Search_name_of_Father_Mother_Guardian] = useState(i18next.t('Pages.student_data.Search_name_of_Father_Mother_Guardian'));
  const [Enter_student_name_or_number] = useState(i18next.t('Pages.student_data.Enter_student_name_or_number'));
  const [Student_Filter] = useState(i18next.t('Pages.student_data.Student_Filter'));
  const [Created_After] = useState(i18next.t('Pages.student_data.Created_After'));
  const [Student_created_successfully] = useState(i18next.t('Pages.student_data.Student_created_successfully'));
  const [Student_deleted_successfully] = useState(i18next.t('Pages.student_data.Student_deleted_successfully'));
  const [Only_Active_Students] = useState(i18next.t('Pages.student_data.Only_Active_Students'));
  const [Recently_Added] = useState(i18next.t('Pages.student_data.Recently_Added'));
  const [Student_Biodata] = useState(i18next.t('Pages.student_data.Student_Biodata'));
  const [Age_must_be_3_or_older] = useState(i18next.t('Pages.student_data.Age_must_be_3_or_older'));
  const [Searching_For_Students] = useState(i18next.t('Pages.student_data.Searching_For_Students'));
  const [Are_you_sure_you_want_to_delete_the_data_of_student] = useState(i18next.t('Pages.student_data.Are_you_sure_you_want_to_delete_the_data_of_student'));
  const [All_invoice_data_payments_schedules_and_attendance_records_will_be_deleted_also] = useState(i18next.t('Pages.student_data.All_invoice_data_payments_schedules_and_attendance_records_will_be_deleted_also'));
  const [If_you_want_to_delete_the_student_fill_in_the_name_below] = useState(i18next.t('Pages.student_data.If_you_want_to_delete_the_student_fill_in_the_name_below'));

  //card
  let cardEle;

  //data
  let [data, setData] = useState(null);
  let [studentMeta, setStudentMeta] = useState(null);
  let [dataTeacher, setDataTeacher] = useState(null);
  let [dataSubject, setDataSubject] = useState(null);
  let [numberOfPage, setNumberOfPage, numberOfPageRef] = useStateRef(numStudent);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogAddStudent = useRef(false);
  let dialogSearching = useRef(false);
  let dialogDeleteStudent = useRef(false);

  //grid setting
  const grid = useRef();
  const editSettings = { allowEditing: permission.AdminPermission() ? true : false, allowAdding: true, allowDeleting: true, mode: 'Normal' };
  const filterOptions = { type: 'Menu' };
  const setting = { mode: 'Both' };

  //delete student setting
  const [selectedData, setSelectedData] = useState(null);
  const [deleteWord, setDeleteWord] = useState(null);

  //upload image
  let my_form2 = useRef(null);
  let uploadObj = useRef(null);
  const [image, setImage] = useState(link.default_image);
  const asyncSettings = { saveUrl: link.saveUrl, removeUrl: link.removeUrl };

  //other component setting
  const gender = [{ id: "Male", name: Male }, { id: "Female", name: Female }]
  const student_filter = [{ id: 0, name: All_Students }, { id: 1, name: Only_Active_Students }, { id: 2, name: Recently_Added }];
  let [view, setView] = useState(localStorage.getItem('view') || 'Card');

  //set state add student
  const [state, setState] = useState({
    name: null,
    nickname: null,
    place_of_birth: null,
    date_of_birth: null,
    gender: null,
    image: null
  });

  //set state filter 
  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    teacher: 0,
    subject_class: 0,
    contacts: null,
    created_after: null,
    student_filter: 0,
    page: 1,
    student: null
  });

  //set error in add student dialog
  const [error, setError, errorRef] = useStateRef({
    name: false,
    gender: false,
    date_of_birth: false,
    image: false
  });

  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getTeacher();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "searching") {
        dialogSearching.current.visible = false;
        setDataButton(null);
      }

      if (dataButtonRef.current === "delete_student") {
        dialogDeleteStudent.current.visible = false;
        setDeleteWord('');
        EventEmitter.emit('alert_toast', { content: Student_deleted_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  // get list of teachers
  // sampling for fields
  const getTeacher = async () => {
    try {
      var result = await directus.getItems('teachers',
        {
          sort: "nickname",
          filter: {
            status: 'published'
          },
          limit: 5, fields: 'id, nickname, name'
        }
      );
      console.log("result12121", result)

      var newData = result.data;
      if (result.data.length > 0) {
        var oldData = [{ id: 0, "nickname": All_Teachers }];
        newData = newData ? oldData.concat(newData) : oldData;
        setDataTeacher(newData);
      }
      getSubjectClass();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of subject class
  const getSubjectClass = async () => {
    try {
      var result = await directus.getItems('subjects', { sort: "name" });
      var newData = result.data;

      if (result.data.length > 0) {
        var oldData = [{ id: 0, "name": All_Classes }];
        newData = oldData.concat(newData);
      }
      setDataSubject(newData);
      setLoadingButton(false);
      getStudent();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of students
  const getStudent = async () => {
    try {
      var teacher = { "nnull": null };
      var subject_class = { "nnull": null };
      var student = { "nnull": null };
      var nickname = { "nnull": null };
      var student_subject_class = { "nnull": null };
      var contacts_field = 'contact';
      var contacts_data = {};

      /* eslint-disable no-mixed-operators*/
      if ((stateFilteringRef.current.teacher !== null && stateFilteringRef.current.teacher !== "" && stateFilteringRef.current.teacher !== 0) ||
        stateFilteringRef.current.subject_class !== null && stateFilteringRef.current.subject_class !== "" && stateFilteringRef.current.subject_class !== 0 ||
        stateFilteringRef.current.student_filter === 1) {
        /* eslint-enable no-mixed-operators*/

        if (stateFilteringRef.current.teacher !== null && stateFilteringRef.current.teacher !== "" && stateFilteringRef.current.teacher !== 0) {
          teacher = { "in": stateFilteringRef.current.teacher };
        }

        if (stateFilteringRef.current.subject_class !== null && stateFilteringRef.current.subject_class !== "" && stateFilteringRef.current.subject_class !== 0) {
          subject_class = { "in": stateFilteringRef.current.subject_class };
        }

        var result = await directus.getItems('students_subject_classes', {
          fields: '*.*.*.*',
          filter: {
            'subject_classes_id.meetings.meetings_id.teacher': teacher,
            'subject_classes_id.subjects.id': subject_class
          }
        });

        if (result.data.length > 0) {
          var student_subject_class_id = result.data;
          student_subject_class_id = linq.from(student_subject_class_id)
            .select(data => (
              data.students_id.id
            ))
            .toArray();

          student_subject_class = { "in": student_subject_class_id };
        } else {
          student_subject_class = { "in": "null" };
        }

      }

      if (stateFilteringRef.current.contacts !== null && stateFilteringRef.current.contacts !== "") {
        contacts_field = 'contact.contacts_id.name';
        contacts_data = { "contains": stateFilteringRef.current.contacts };
      }

      var created_after = { "nnull": null };
      if (stateFilteringRef.current.created_after !== null && stateFilteringRef.current.created_after !== "") {
        created_after = { "gte": moment(new Date(stateFilteringRef.current.created_after)).format("YYYY-MM-DD") };
      }

      var new_student = { "nnull": null };
      if (stateFilteringRef.current.student_filter === 2) {
        new_student = { "gte": moment().clone().startOf('month').subtract(2, 'months').format("YYYY-MM-DD") };
      }

      if (stateFilteringRef.current.student !== null && stateFilteringRef.current.student !== '') {
        student = { "contains": stateFilteringRef.current.student }
        nickname = { 'logical': 'or', "contains": stateFilteringRef.current.student }
      }

      var result_students = await directus.getItems('students', {
        fields: '*.*.*',
        filter: {
          status: 'published',
          name: student,
          nickname: nickname,
          id: student_subject_class,
          [contacts_field]: contacts_data,
          created_on: created_after,
          //eslint-disable-next-line no-dupe-keys
          created_on: new_student
        },
        sort: 'name',
        limit: numberOfPageRef.current,
        meta: "*",
        page: stateFilteringRef.current.page ? stateFilteringRef.current.page : 1
      });

      setData(result_students.data);
      setStudentMeta(result_students.meta);
      setLoading(false);
      cardRendering(result_students.data);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Change View (Card or Grid) ========================================== */ }

  const changeView = (selected_view) => {
    setView(selected_view);
    localStorage.setItem("view", selected_view);
  }

  // {/* ================== Card Render ========================================== */ }

  const cardRendering = async (data) => {
    if (data.length > 0) {
      data.forEach((data, index) => {
        cardEle = document.getElementById('card_student_' + (++index));
        if (cardEle) {
          ReactDOM.render(<CardRender data={data} navigate={navigate} />, cardEle);
        }
      }
      );
    }
  }

  // {/* ================== Filtering ========================================== */ }
  //search teacher by nickname
  const onFilteringTeacher = (e) => {
    let query = new Query();
    // //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('nickname', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    try {
      if (e.text !== '') {
        window.setTimeout(async () => {
          var result = await directus.getItems('teachers',
            {
              fields: '*.*',
              filter: {
                name: { "contains": e.text },
                nickname: { "contains": e.text },
                status: "published"
              },
              limit: 5,
              page: 1
            }
          );

          e.updateData(result.data, query);
        }, 500)
      }
      else {
        window.setTimeout(async () => {
          var result = await directus.getItems('teachers',
            {
              fields: '*.*',
              filter: {
                status: "published"
              },
              limit: 5,
              page: 1
            }
          );
          e.updateData(result.data, query);
        }, 500)
      }


    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  };

  //search subject class by name
  const onFilteringClass = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataSubject, query);
  };

  // {/* ================== Handle Upload File ========================================== */ }

  const uploadFile = async (args) => {
    if (args.operation === "upload") {
      try {
        const data = new FormData(my_form2.current);
        var result = await directus.uploadFiles(data, onUploadProgress);
        const imageId = result.data.id;
        setImage(result.data.data.full_url);
        setState({ ...state, image: imageId })
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      }
    } else if (args.operation === "remove") {
      cancelFile();
    }
  }


  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + "% Done");
  }

  const cancelFile = () => {
    setState({ ...state, image: null })
    setImage(link.default_image);
    setError({ ...errorRef.current, image: false })
  }

  // {/* ================== Handle Students ========================================== */ }

  const handleOpenDialogAddStudent = () => {
    dialogAddStudent.current.visible = true;
  }

  const handleCloseDialogAddStudent = () => {
    setState({ name: null, nickname: null, place_of_birth: null, date_of_birth: null, gender: null, image: null });
    setError({ name: false, nickname: false, gender: false, date_of_birth: false, image: false })
    uploadObj.current.clearAll();
    dialogAddStudent.current.visible = false;
  }

  const handleChangeAddStudent = (event, type) => {
    var value = null;
    if (type === "gender") {
      value = event.itemData.id;
    } else {
      value = event.value;
    }

    if (type === "name" || type === "nickname" || type === "gender") {
      if (value) {
        setError({ ...errorRef.current, [type]: false })
      } else {
        setError({ ...errorRef.current, [type]: true })
      }
    }

    setState({ ...state, [type]: value })
  }

  const addStudent = async () => {
    if (uploadObj.current.filesData.length > 0) {
      if (!state.image) {
        setError({ ...errorRef.current, image: true })
      } else {
        setError({ ...errorRef.current, image: false })
      }
    }

    if (!errorRef.current.name && !errorRef.current.nickname && !errorRef.current.gender && !errorRef.current.image) {
      try {
        setError({ name: false, nickname: false, gender: false, date_of_birth: false, image: false })
        //check the age of students 
        if (state.date_of_birth) {
          const birthdate = moment(new Date(state.date_of_birth), 'MM-YYYY');
          const now = moment();
          var date = moment.duration(now.diff(birthdate));
          if (date.years() < 3) {
            setError({ ...errorRef.current, date_of_birth: true })
          }
        }

        if (state.name && state.gender && state.nickname) {
          setLoadingButton(true);

          const newDate = state.date_of_birth ? moment(state.date_of_birth).format("YYYY-MM-DD") : null;
          state.date_of_birth = newDate;
          state.status = "published";

          var result = await directus.createItem('students', state);

          const body_activity = {
            action: "Add",
            category: 2,
            description: state.name,
            status: "published"
          }
          var result_activity = await directus.createItem('activity_log', body_activity);

          if (!result_activity.error) {
            setLoadingButton(false);
            dialogAddStudent.current.visible = false;
            EventEmitter.emit('alert_toast', { content: Student_created_successfully, type: "success" });
            return navigate('/student/profile/' + result.data.id);
          }

        } else {
          if (!state.name) { setError({ ...errorRef.current, name: true }) }
          if (!state.nickname) { setError({ ...errorRef.current, nickname: true }) }
          if (!state.gender) { setError({ ...errorRef.current, gender: true }) }
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        setLoadingButton(false);
      }
    }
  }

  // {/* ================== Handle Dialog Delete Student ========================================== */ }

  const handleOpenDialogDeleteStudent = (props) => {
    dialogDeleteStudent.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteStudent = () => {
    dialogDeleteStudent.current.visible = false;
    setDeleteWord('');
  }

  const handleChangeWord = (e) => {
    setDeleteWord(e.value);
  }

  const deleteStudent = async () => {
    if (deleteWord === selectedData.name) {
      setLoadingButton(true);
      //set teacher status from published to draft
      var result = await directus.updateItem('students', selectedData.id, { status: 'draft' });

      if (!result.error) {
        const body_activity = {
          action: "Delete",
          category: 2,
          description: selectedData.name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);
        getStudent();
        setDataButton("delete_student");
      }

    } else if (deleteWord !== selectedData.name) {
      EventEmitter.emit('alert_toast', { content: Please_match_the_requested_format, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Handle Searching For Students ========================================== */ }

  const handleOpenSearchingDialog = () => {
    dialogSearching.current.visible = true;
  }

  const handleCloseDialogSearching = () => {
    dialogSearching.current.visible = false;
  }

  const clearDialogState = () => {
    setStateFiltering({ ...stateFilteringRef.current, teacher: 0, subject_class: 0, contacts: null, created_after: null, student_filter: 0 })
  }

  const handleChangeSearching = (event, type) => {
    var value = null;
    if (type === "teacher" || type === "subject_class" || type === "student_filter") {
      value = event.itemData.id;
    } else {
      value = event.value
    }
    setStateFiltering({ ...stateFilteringRef.current, [type]: value })
  }

  const handleChangeFilter = (event, type) => {
    var value = null;
    if (type === "student") { value = event.value }
    setStateFiltering({ ...stateFilteringRef.current, [type]: value })
    if (event.syntheticEvent.type === 'change') {
      getStudent();
    }
  }

  const searchStudent = () => { getStudent(); }

  const searching = async () => {
    setLoadingButton(true);
    getStudent();
    setDataButton("searching");
  }

  // {/* ================== Data Grid Template ========================================== */ }

  const nameTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <span className="image" style={{ width: "25px", height: "25px" }}>
          <img
            style={{
              maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
              objectFit: "cover", borderRadius: "50%", position: "center",
            }}
            className="image"
            src={props.image ? props.image.data.full_url : link.default_image}
            alt={props.name}
          />
        </span>
        <span style={{ paddingLeft: "20px", textAlign: "left" }}>{props.name}</span>
      </div>
    )
  };

  const lastRowTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        {permission.AdminPermission() ?
          <div className="action_hover" style={{ display: "flex" }}>
            <TooltipComponent content={Preview} position="BottomCenter" >
              <span>
                <Link to={{ pathname: `/student/profile/${props.id}` }} style={{ textDecoration: "none" }}>
                  <span className="e-icons icon-MT_Preview"
                    style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
                  />
                </Link>
              </span>
            </TooltipComponent>


            <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px", }} >
              <span className="e-icons icon-delete-05"
                style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => handleOpenDialogDeleteStudent(props)}
              />
            </TooltipComponent>
          </div>
          : null}
      </div>
    )
  };

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const lastRowEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter" >
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }


  // {/* ================== Data Grid Action (edit) ========================================== */ }

  const actionGrid = async (event) => {
    try {
      if (event.action === "edit" && event.requestType === "save") {
        if (cancelFlag === false) {
          const data = event.data;
          const body = {
            name: data.name,
            nickname: data.nickname,
            place_of_birth: data.place_of_birth,
            date_of_birth: data.date_of_birth
          }

          body.date_of_birth = body.date_of_birth ? moment(new Date(body.date_of_birth)).format("YYYY-MM-DD") : null;

          var result = await directus.updateItem('students', data.id, body);

          if (!result.error) {
            var description = data.name;
            if (data.name !== event.previousData.name) {
              description = event.previousData.name + " --> " + data.name;
            }

            const body_activity = {
              action: "Update",
              category: 2,
              description: description,
              status: "published"
            }
            await directus.createItem('activity_log', body_activity);
            getStudent();
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          }
        } else {
          grid.current.editModule.closeEdit()
        }
        cancelFlag = false;

      } else if (event.requestType === "beginEdit") {
        cancelFlag = true;
      }

    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const changeNumOfData = (e) => {
    var value = e.value ? e.value : 1;
    setNumberOfPage(value);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      numOfPage.student = value;
      window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
      setStateFiltering({ ...stateFilteringRef.current, page: 1 })
      getStudent();
    }, 1000)
  }

  // {/* ================== Pagination ========================================== */ }
  const pageTemplate = () => {
    var list = [];
    if (studentMeta) {
      for (var i = 0; i < studentMeta.page_count; i++) {
        const page = i + 1;
        var class_name = "e-link e-numericitem e-spacing e-pager-default";
        if (studentMeta.page === page) {
          class_name = "e-link e-numericitem e-spacing e-currentitem e-active"
        }
        var display = "none";
        if (page <= 3) { display = ""; }
        list.push(<div key={"page-" + page}><span className={class_name} id={"page-" + page} role="link" tabIndex="-1" aria-label={'Goto Page ' + page} onClick={(e) => clickPage(page, "num", e)} name={'Goto Page ' + page} aria-owns="grid_940258773_0_content_table" style={{ display: display }} >{page}</span></div>)
      }
    }

    //check page 
    var check_page = [];
    if (studentMeta.page_count <= 3) {
      for (var j = 0; j < studentMeta.page_count; j++) { check_page.push(j + 1) }
    } else {
      if (studentMeta.page <= 2) {
        for (var x = 0; x < 3; x++) { check_page.push(x + 1) }
      } else if (studentMeta.page >= studentMeta.page_count) {
        for (var y = studentMeta.page_count; y > studentMeta.page_count - 3; y--) { check_page.push(y) }
      } else {
        var list_page = [studentMeta.page - 1, studentMeta.page, studentMeta.page + 1];
        list_page.forEach((value) => {
          check_page.push(value)
        })
      }
    }

    var select_page2 = document.querySelectorAll('.e-numericitem');
    if (select_page2.length > 0) {
      if (select_page2.length > 0) {
        for (var ind = 0; ind < select_page2.length; ind++) {
          select_page2[ind].style.display = "none";
        }
      }

      if (check_page) {
        //eslint-disable-next-line array-callback-return
        check_page.map((value) => {
          var select_page = document.querySelectorAll(`[id=page-${value}]`);
          if (select_page.length > 0) {
            for (var index2 = 0; index2 < select_page.length; index2++) {
              select_page[index2].style.display = "";
            }
          }
        })
      }
    }

    return (
      <div style={{ height: "35px", textAlign: "center" }}>
        <div className="e-gridpager e-control e-pager e-lib" style={{ display: "flex", width: "100%" }} >
          <div style={{ width: "32.5%", background: "#E1E7EB", borderRadius: "17.5px 5px 5px 17.5px" }}>
            <div className="e-pagercontainer" role="navigation" >
              <div className={studentMeta.page === 1 ? "e-first e-icons e-icon-first e-firstpagedisabled e-disable" : "e-first e-icons e-icon-first e-firstpage e-pager-default"} onClick={() => clickPage(1, "first")} title="Go to first page" aria-label="Go to first page" tabIndex="-1" index="1" aria-owns="grid_940258773_0_content_table"></div>
              <div className={studentMeta.page === 1 ? "e-prev e-icons e-icon-prev e-prevpagedisabled e-disable" : "e-prev e-icons e-icon-prev e-prevpage e-pager-default"} onClick={() => clickPage(studentMeta.page - 1, "prev")} title="Go to previous page" aria-label="Go to previous page" tabIndex="-1" index="0" aria-owns="grid_940258773_0_content_table"></div>
              <div className="e-numericcontainer">{list}</div>
              <div className={studentMeta.page === studentMeta.page_count ? "e-next e-icons e-icon-next e-nextpagedisabled e-disable" : "e-next e-icons e-icon-next e-nextpage e-pager-default"} onClick={() => clickPage(studentMeta.page + 1, "next")} title="Go to next page" aria-label="Go to next page" tabIndex="-1" index="2" aria-owns="grid_940258773_0_content_table"></div>
              <div className={studentMeta.page === studentMeta.page_count ? "e-last e-icons e-icon-last e-lastpagedisabled e-disable" : "e-last e-icons e-icon-last e-lastpage e-pager-default"} onClick={() => clickPage(studentMeta.page_count, "last")} title="Go to last page" aria-label="Go to last page" tabIndex="-1" index="2" aria-owns="grid_940258773_0_content_table"></div>
            </div>
          </div>
          <div style={{ width: "35%", background: "#E1E7EB", marginLeft: "2px", marginRight: "2px", borderRadius: "5px" }}>
            <div className="e-pagerconstant">
              <span className="e-constant" style={{ display: "flex" }}>
                <span style={{ marginRight: "12px" }}>
                  <span style={{ display: "flex", flexDirection: "column", position: "absolute", marginTop: "7px" }}>
                    <span className="e-icons icon-arrowhead-up"
                      style={{ cursor: "pointer", color: '#516573', fontSize: "10px" }}
                      onClick={() => handleNumberOfPage("+")}
                    />
                    <span className="e-icons icon-arrowhead-down"
                      style={{ cursor: "pointer", color: '#516573', fontSize: "10px" }}
                      onClick={() => handleNumberOfPage("-")}
                    />
                  </span>
                </span>
                {/* <span className="nt_custom" style={{ fontSize: "17px", fontWeight: "800", color: "#516573", width: "25px", marginRight: "6px", marginLeft: "6px" }}> */}
                <span className="nt_custom" style={{ fontSize: "17px", fontWeight: "800", color: "#516573", width: "35px", marginRight: "6px", marginLeft: "6px" }}>
                  <NumericTextBoxComponent style={{ textAlign: "center", padding: "0px" }} step={5} format='####' value={numberOfPageRef.current} showSpinButton={false} max={999} min={1} onChange={(e) => changeNumOfData(e)} />
                </span>
                <span style={{ color: "#516573" }}>
                  {Items_per_page} (total {studentMeta ? studentMeta.filter_count : 0} records)
                </span>
              </span>
            </div>
          </div>
          <div className="e-parentmsgbar" style={{ width: "32.5%", background: "#E1E7EB", borderRadius: "5px 17.5px 17.5px 5px" }}>
            <span className="e-pagenomsg">{studentMeta ? <span>{studentMeta.page} {of} {studentMeta.page_count} {pages} </span> : null}</span>
          </div>
        </div>
      </div>
    )
  }

  const handleNumberOfPage = (props) => {
    if (numberOfPageRef.current - 1 > 0) {
      var value = numberOfPageRef.current;
      if (props === "+") {
        value = numberOfPageRef.current + 5;
      } else {
        if (numberOfPageRef.current > 5) {
          value = numberOfPageRef.current - 5;
        } else {
          value = 1;
        }
      }
      setNumberOfPage(value);
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        numOfPage.student = value;
        window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
        getStudent();
      }, 1000)
    }
  }

  const clickPage = (page, type) => {
    var status = false;
    if (type === "num") {
      if (studentMeta.page !== page) { status = true; }
    } else if (type === "first" || type === "prev") {
      if (studentMeta.page !== 1) { status = true; }
    } else if (type === "next" || type === "last") {
      if (studentMeta.page !== studentMeta.page_count) { status = true; }
    }

    if (status) {
      setStateFiltering({ ...stateFilteringRef.current, page: page })
      getStudent();
    }
  }


  const onColumnMenuOpen = (args) => {
    for (const item of args.items) {
      if (item.text === 'Autofit all columns' || item.text === 'Autofit this column') {
        item.hide = true;
      }
    }
  }
  // {/* ================== Footer Template ========================================== */}

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  const onDataBound = () => {
    // console.log(grid.current)
  }
  // {/* ================== Main Content ========================================== */}
  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      cancelFlag = false;
      grid.current.endEdit();
    }
  }

  return loading ?
    <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
    :
    <div>
      <div className="header">
        <div className="content-header">{Student_List}</div>
        <div className="content-searching hide-content">
          <div style={{ display: 'flex', width: "100%" }}>
            <div style={{ width: "250px", marginRight: "10px" }}>
              <TextBoxComponent
                cssClass="e-outline searching-text"
                className="searching-input"
                id="searching-input"
                placeholder={Enter_student_name_or_number}
                onChange={(e) => handleChangeFilter(e, "student")}
                onBlur={(e) => searchStudent(e)}
                showClearButton="true"
              />
            </div>
            <div style={{ marginRight: "20px" }}>
              <ButtonComponent
                className="btn-custom"
                iconCss='e-icons icon-MT_search'
                onClick={handleOpenSearchingDialog}
              >
                <span className="btn-text-custom">{Advanced_Search}</span>
              </ButtonComponent>
            </div>
            <div>
              <ButtonComponent
                iconCss="e2-list"
                style={{ width: "30px", height: "30px", color: view === 'Card' ? '#516573' : 'white', backgroundColor: view === 'Card' ? '#FFFFFF' : '#5F99E9', borderRadius: "5px 0px 0px 5px" }}
                onClick={() => changeView('Grid')} ></ButtonComponent>

              <ButtonComponent
                iconCss="e2-th-large"
                style={{ width: "30px", height: "30px", color: view === 'Grid' ? '#516573' : 'white', backgroundColor: view === 'Grid' ? '#FFFFFF' : '#5F99E9', borderRadius: "0px 5px 5px 0px" }}
                onClick={() => changeView('Card')} ></ButtonComponent>
            </div>
            {permission.AdminPermission() ?
              <div style={{ marginLeft: "20px" }}>
                <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddStudent}>
                  <span className="btn-text-custom">{Student}</span>
                </ButtonComponent>
              </div>
              : null}
          </div>
        </div>
      </div>

      <div className="content-main">
        {/* ================== Card View ========================================== */}
        {data ? data.length > 0 ?
          <div className="control-menu">
            <div style={{ display: view === 'Card' ? 'block' : 'none', marginTop: "30px", marginRight: "-15px" }}>
              <div className="control-section card-control-section tile_layout">
                <div className='row e-card-layout' style={{ textAlign: 'center' }}>
                  {/* <div className='card-list' style={{display: "flex", flexWrap: "wrap", height: getHeight()+"px", overflow: "auto"}}> */}
                  <div className='card-list' style={{ display: "flex", flexWrap: "wrap", overflow: "auto", alignItems: "flex-start", alignContent: "flex-start", justifyContent: "center" }}>
                    {data ? data.length > 0 ? data.map((value, index) => (
                      <div style={{ width: "auto", marginLeft: "30px", height: "220px", marginBottom: "30px" }} key={index}>
                        <div id={'card_student_' + (index + 1)} className='card_sample' style={{ width: "300px" }}></div>
                      </div>
                    )) : null : null}
                  </div>
                </div>
              </div>
            </div>


            {/* ================== Grid View ========================================== */}
            {/* <div style={{ display: view === 'Grid' ? 'block' : 'none', marginTop: "25px", paddingBottom: "20px", marginLeft: "25px", marginRight: "-5px" }}> */}
            <div style={{ display: view === 'Grid' ? 'block' : 'none', marginTop: "25px", paddingBottom: "20px", marginLeft: "30px" }}>
              <div className="grid-container">
                <GridComponent
                  // width="100%"
                  className="grid-list"
                  dataSource={data}
                  dataBound={onDataBound}
                  editSettings={editSettings}
                  filterSettings={filterOptions}
                  allowFiltering={true}
                  ref={grid}
                  allowTextWrap={true}
                  textWrapSettings={{ wrapMode: "Content" }}
                  allowResizing={true}
                  gridLines='Vertical'
                  showColumnMenu={true}
                  columnMenuOpen={onColumnMenuOpen}
                  actionBegin={actionGrid}
                  selectionSettings={setting}
                  clipMode="EllipsisWithTooltip"
                  keyPressed={onKeyPressed}
                >
                  <ColumnsDirective>
                    <ColumnDirective customAttributes={{ id: 'first' }} width="272px" field='name' headerText={Name} template={nameTemplate} validationRules={{ required: true }}></ColumnDirective>
                    <ColumnDirective width="177px" field='nickname' headerText={Nickname} validationRules={{ required: true }}></ColumnDirective>
                    <ColumnDirective width="177px" field='place_of_birth' headerText={Place_of_Birth} ></ColumnDirective>
                    <ColumnDirective width="177px" field='date_of_birth' headerText={Date_of_Birth} editType='datepickeredit' type='date' format="dd MMMM yyyy" edit={{ params: { format: 'dd MMMM yyyy' } }} ></ColumnDirective>
                    <ColumnDirective clipMode="Ellipsis" customAttributes={{ id: 'last' }} width="177px" template={lastRowTemplate} editTemplate={lastRowEditTemplate}></ColumnDirective>
                  </ColumnsDirective>
                  <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, DetailRow, Resize]} />
                </GridComponent>
                {/* <div id="grid-space"></div> */}
              </div>
            </div>
          </div>
          : <div style={{ textAlign: "center", marginTop: "30px" }}>{No_data_found}</div>
          : <div style={{ textAlign: "center", marginTop: "30px" }}>{No_data_found}</div>
        }
        {/* ================== Dialog ========================================== */}

        {/* add student  */}
        <DialogComponent
          id="dialogAddStudent"
          width="30%"
          showCloseIcon={!loadingButton ? true : false}
          visible={false}
          ref={dialogAddStudent}
          header={Student_Biodata}
          isModal={true}
          footerTemplate={() => footerTemplate([
            { name: Update, func: addStudent, icon: "icon-check-mark-01", status: "e-success" },
            { name: Cancel, func: handleCloseDialogAddStudent, icon: "icon-treeview", status: "" }
          ])}
          close={handleCloseDialogAddStudent}
        >
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="image" style={{ width: "150px", height: "150px" }}>
                <img
                  style={{
                    maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
                    objectFit: "cover", position: "center",
                  }}
                  src={image}
                  alt="student_photo"
                />
              </div>
            </div>
            <form ref={my_form2}>
              <UploaderComponent
                id='previewfileupload'
                type='file'
                ref={uploadObj}
                asyncSettings={asyncSettings}
                success={uploadFile}
                multiple={false}
                autoUpload={false}
                clearing={cancelFile}
                removing={cancelFile}
              >
              </UploaderComponent>
              {errorRef.current.image === true ? <span style={{ color: 'red' }}>{Please_upload_the_photo}</span> : ''}
            </form>

            <br />
            <div>
              <b>*{Name}</b>
              <TextBoxComponent
                name="name"
                cssClass="e-outline"
                change={(e) => handleChangeAddStudent(e, "name")}
                value={state.name}
              />
              {errorRef.current.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
              <br /><br />
              <b>*{Nickname}</b>
              <TextBoxComponent
                name="nickname"
                cssClass="e-outline"
                change={(e) => handleChangeAddStudent(e, "nickname")}
                value={state.nickname}
              />
              {errorRef.current.nickname === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
              <br /><br />
              <b>{Place_of_Birth}</b>
              <TextBoxComponent
                name="place_of_birth"
                cssClass="e-outline"
                change={(e) => handleChangeAddStudent(e, "place_of_birth")}
                value={state.place_of_birth}
              />
              <br /><br />
              <b>{Date_of_Birth}</b>
              <DatePickerComponent
                name="date_of_birth"
                cssClass="e-outline"
                format="dd-MMM-yyyy"
                change={(e) => handleChangeAddStudent(e, "date_of_birth")}
                value={state.date_of_birth}
              />
              {errorRef.current.date_of_birth === true ? <span style={{ color: 'red' }}>{Age_must_be_3_or_older}</span> : ''}
              <br /><br />
              <b>*{Gender}</b>
              <DropDownListComponent
                name="gender"
                cssClass="e-outline"
                placeholder={`--${Select}--`}
                dataSource={gender}
                fields={{ text: 'name', value: 'id' }}
                select={(e) => handleChangeAddStudent(e, "gender")}
                value={state.gender}
              />
              {errorRef.current.gender === true ? <span style={{ color: 'red' }}>{Please_select_gender}</span> : ''}
            </div>
          </div>
        </DialogComponent>


        {/* dialog delete student  */}
        <DialogComponent
          id="dialogDeleteStudent"
          width='35%'
          showCloseIcon={!loadingButton ? true : false}
          visible={false}
          ref={dialogDeleteStudent}
          header={Delete_Confirmation}
          // style={{ fontWeight: "bold" }}
          isModal={true}
          footerTemplate={() => footerTemplate([
            { name: Delete, func: deleteStudent, icon: "icon-delete-05", status: "e-warning" },
            { name: Cancel, func: handleCloseDialogDeleteStudent, icon: "icon-treeview", status: "" }
          ])}
          close={handleCloseDialogDeleteStudent}
        >
          <div>
            {selectedData ?
              <div style={{ fontSize: "14px" }}>
                {Are_you_sure_you_want_to_delete_the_data_of_student} ({selectedData.name})?
                <br /><br />
                <p style={{ color: "red" }}>
                  {All_invoice_data_payments_schedules_and_attendance_records_will_be_deleted_also}! <br />
                  {If_you_want_to_delete_the_student_fill_in_the_name_below}:
                </p>
                <TextBoxComponent
                  name="delete_teacher"
                  cssClass="e-outline"
                  change={(e) => handleChangeWord(e)}
                  value={deleteWord}
                ></TextBoxComponent>
              </div>
              : ''}
          </div>
        </DialogComponent>

        {/* searching for student  */}
        <DialogComponent
          id="dialogSearching"
          width="30%"
          showCloseIcon={!loadingButton ? true : false}
          visible={false}
          ref={dialogSearching}
          header={Searching_For_Students}
          isModal={true}
          footerTemplate={() => footerTemplate([
            { name: Search, func: searching, icon: "icon-MT_search", status: "e-info" },
            { name: Cancel, func: handleCloseDialogSearching, icon: "icon-treeview", status: "" },
            { name: Clear, func: clearDialogState, icon: "e2-clear", status: "" }
          ])}
          close={handleCloseDialogSearching}
        >
          <div>
            <div>
              <b>{Select_Teacher}</b>
              <DropDownListComponent
                dataSource={dataTeacher}
                name="teacher"
                cssClass="e-outline"
                placeholder={All_Teachers}
                fields={{ text: 'nickname', value: 'id' }}
                select={(e) => handleChangeSearching(e, "teacher")}
                filtering={onFilteringTeacher}
                noRecordsTemplate={No_data_found}
                allowFiltering={true}
                value={stateFilteringRef.current.teacher}
              />
              <br /><br />
              <b>{Select_Subject}</b>
              <DropDownListComponent
                dataSource={dataSubject}
                name="subject_class"
                cssClass="e-outline"
                placeholder={All_Classes}
                fields={{ text: 'name', value: 'id' }}
                select={(e) => handleChangeSearching(e, "subject_class")}
                filtering={onFilteringClass}
                noRecordsTemplate={No_data_found}
                allowFiltering={true}
                value={stateFilteringRef.current.subject_class}
              />
              <br /><br />
              <b>{Search_name_of_Father_Mother_Guardian}</b>
              <TextBoxComponent
                name="contacts"
                cssClass="e-outline"
                change={(e) => handleChangeSearching(e, "contacts")}
                value={stateFilteringRef.current.contacts}
              />
              <br /><br />
              <b>{Student_Filter}</b>
              <DropDownListComponent
                dataSource={student_filter}
                name="student_filtering"
                cssClass="e-outline"
                fields={{ text: 'name', value: 'id' }}
                change={(e) => handleChangeSearching(e, "student_filter")}
                value={stateFilteringRef.current.student_filter}
              />
              <br /><br />
              <b>{Created_After}</b>
              <DatePickerComponent
                name="created_after"
                cssClass="e-outline"
                format="dd-MMM-yyyy"
                change={(e) => handleChangeSearching(e, "created_after")}
                value={stateFilteringRef.current.created_after}
              />
            </div>
          </div>
        </DialogComponent>
      </div>
      <div className="content-pagination">
        <div className="content-pagination-inside">
          {studentMeta ? studentMeta.filter_count > 0 ? pageTemplate() : null : null}
        </div>
      </div>
    </div>
}

export default StudentList;