import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Query } from '@syncfusion/ej2-data';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { RecurrenceEditorComponent } from '@syncfusion/ej2-react-schedule';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";

import directus from 'src/services/directus';

import EventEmitter from 'src/utils/EventEmitter';

import * as subject_class from 'src/CRUD/subject_classes';

import Schedule from './schedule';
import Result from './result';
import * as permission from 'src/utils/Permission';

const SubjectClassDetail = () => {
  /* eslint-disable no-unused-vars*/
  var { id } = useParams();
  const [classId, setClassId] = useState(id);
  const navigate = useNavigate();

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Edit] = useState(i18next.t('Pages.button.Edit'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));

  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));

  // const [Created_by] = useState(i18next.t('Pages.data.Created_by'));
  const [Sunday] = useState(i18next.t('Pages.data.Sunday'));
  const [Monday] = useState(i18next.t('Pages.data.Monday'));
  const [Tuesday] = useState(i18next.t('Pages.data.Tuesday'));
  const [Wednesday] = useState(i18next.t('Pages.data.Wednesday'));
  const [Thursday] = useState(i18next.t('Pages.data.Thursday'));
  const [Friday] = useState(i18next.t('Pages.data.Friday'));
  const [Saturday] = useState(i18next.t('Pages.data.Saturday'));

  const [Name] = useState(i18next.t('Pages.data.Name'));
  const [Select_Subject] = useState(i18next.t('Pages.data.Select_Subject'));
  const [Select_Teacher] = useState(i18next.t('Pages.data.Select_Teacher'));
  const [Select_Day] = useState(i18next.t('Pages.data.Select_Day'));
  const [Select_Room] = useState(i18next.t('Pages.data.Select_Room'));

  const [Start_Date] = useState(i18next.t('Pages.schedule_data.Start_Date'));
  const [End] = useState(i18next.t('Pages.schedule_data.End'));
  const [Time] = useState(i18next.t('Pages.schedule_data.Time'));
  const [Duration] = useState(i18next.t('Pages.schedule_data.Duration'));
  const [Date_2] = useState(i18next.t('Pages.schedule_data.Date'));

  const [Class] = useState(i18next.t('Pages.subject_class_data.Class'));
  const [Meetings] = useState(i18next.t('Pages.subject_class_data.Meetings'));
  const [Add_Class] = useState(i18next.t('Pages.subject_class_data.Add_Class'));
  const [Subject] = useState(i18next.t('Pages.subject_class_data.Subject'));
  const [Meeting_List] = useState(i18next.t('Pages.subject_class_data.Meeting_List'));
  const [Day] = useState(i18next.t('Pages.subject_class_data.Day'));
  const [Teacher] = useState(i18next.t('Pages.subject_class_data.Teacher'));
  const [Room] = useState(i18next.t('Pages.subject_class_data.Room'));
  const [Class_created_successfully] = useState(i18next.t('Pages.subject_class_data.Class_created_successfully'));
  const [Class_deleted_successfully] = useState(i18next.t('Pages.subject_class_data.Class_deleted_successfully'));
  const [Number_of_Meetings] = useState(i18next.t('Pages.subject_class_data.Number_of_Meetings'));
  const [The_number_of_meetings_cannot_be_empty] = useState(i18next.t('Pages.subject_class_data.The_number_of_meetings_cannot_be_empty'));
  const [Meeting] = useState(i18next.t('Pages.subject_class_data.Meeting'));
  const [Duplicate] = useState(i18next.t('Pages.subject_class_data.Duplicate'));
  const [Add_Schedule] = useState(i18next.t('Pages.subject_class_data.Add_Schedule'));
  const [Edit_Class] = useState(i18next.t('Pages.subject_class_data.Edit_Class'));
  const [Are_you_sure_you_want_to_delete_the_data_of_class] = useState(i18next.t('Pages.subject_class_data.Are_you_sure_you_want_to_delete_the_data_of_class'));
  const [New_schedule_created_successfully] = useState(i18next.t('Pages.subject_class_data.New_schedule_created_successfully'));
  const [Minutes] = useState(i18next.t('Pages.subject_class_data.Minutes'));
  const [Registration_Fee] = useState(i18next.t('Pages.subject_class_data.Registration_Fee'));
  const [Monthly_Fee] = useState(i18next.t('Pages.subject_class_data.Monthly_Fee'));
  const [Lesson_Fee] = useState(i18next.t('Pages.subject_class_data.Lesson_Fee'));
  const [Schedule_2] = useState(i18next.t('Pages.subject_class_data.Schedule'));

  const [Are_you_sure_you_want_to_change_the_start_date] = useState(i18next.t('Pages.subject_class_data.Are_you_sure_you_want_to_change_the_start_date'));
  const [All_schedules_will_be_rescheduled] = useState(i18next.t('Pages.subject_class_data.All_schedules_will_be_rescheduled'));

  const [Results] = useState(i18next.t('Pages.result_data.Results'));

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData, dataRef] = useStateRef(null);
  const [dataTeacher, setDataTeacher, dataTeacherRef] = useStateRef(null);
  const [dataSubject, setDataSubject, dataSubjectRef] = useStateRef(null);
  const [dataRoom, setDataRoom, dataRoomRef] = useStateRef(null);
  const [dataStudentSubjectClass, setDataStudentSubjectClass, dataStudentSubjectClassRef] = useStateRef(null);
  const [dataSchedule, setDataSchedule, dataScheduleRef] = useStateRef(null);
  const [open, setOpen, openRef] = useStateRef(false);
  const [openAddClass, setOpenAddClass, openAddClassRef] = useStateRef(false);

  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  const [prevStartDate, setPrevStartDate, prevStartDateRef] = useStateRef(null);

  const headerText = [{ text: Schedule_2 }, { text: Results }];
  let recObject = useRef(false);

  let dialogAddSchedule = useRef(false);
  let dialogDeleteClass = useRef(false);

  const [stateSchedule, setStateSchedule, stateScheduleRef] = useStateRef({
    teachers_id: null,
    subject_classes_id: id,
    date: null,
    duration: null,
    room: null
  })

  const [stateSubjectClass, setStateSubjectClass, stateSubjectClassRef] = useStateRef({
    code: null,
    start_date: null,
    subjects: null,
    meetings: null
  });

  const [stateRowDefault, setStateRowDefault, stateRowDefaultRef] = useStateRef({
    rows: [{}]
  })

  const [stateRow, setStateRow, stateRowRef] = useStateRef({
    rows: [{}]
  })

  const [error, setError, errorRef] = useStateRef({
    student: false, date: false, time: false, duration: false, name: false
  });

  const weeks = [{ id: "Sunday", name: Sunday }, { id: "Monday", name: Monday }, { id: "Tuesday", name: Tuesday }, { id: "Wednesday", name: Wednesday }, { id: "Thursday", name: Thursday }, { id: "Friday", name: Friday }, { id: "Saturday", name: Saturday }];
  const fields = { text: 'name', value: 'id' };

  const [dataNumberOfMeetings, setDataNumberOfMeetings] = useState(false);
  /* eslint-enable no-unused-vars*/

  useEffect(() => {
    const duplicateData = (props) => {
      if (props.update === true) {
        setClassId(props.new_id);
        setInitialized(false);
      }
    }
    const listener = EventEmitter.addListener('duplicate_data', duplicateData);
    return () => { listener.remove(); }
  })

  useEffect(() => {
    const duplicateData = (props) => {
      if (props.update === true) {
        getData();
      }
    }
    const listener = EventEmitter.addListener('update_schedule', duplicateData);
    return () => { listener.remove(); }
  })

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "add_schedule") {
        dialogAddSchedule.current.visible = false;
        EventEmitter.emit('alert_toast', { content: New_schedule_created_successfully, type: "success" });
        EventEmitter.emit('update_schedule', { update: true });
        setDataButton(null);
      }
    }
  })

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true)
      getData();
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get all subject class data by specific id
  const getData = async () => {
    try {
      var result = await directus.getItems('subject_classes',
        {
          fields: '*, subjects.*.*.*, meetings.meetings_id.*.*, students.students_id.*',
          filter: { id: classId }
        }
      );

      if (!result.error) {
        var result2 = result.data[0];

        var meetings = [];
        /* eslint-disable array-callback-return*/
        result2.meetings.map((value) => {
          var meetingData = {
            id: value.meetings_id.id,
            weekday: value.meetings_id.weekday,
            time: moment(value.meetings_id.time, "HH:mm:ss").format('HH:mm'),
            teacher: value.meetings_id.teacher.id,
            room: value.meetings_id.room.id
          }
          meetings.push(meetingData);
        })

        setStateRow({ rows: meetings });
        setStateRowDefault({ rows: meetings });

        setData(result2);
        getSchedule();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of schedules of the subject class sort by date
  const getSchedule = async () => {
    try {
      var result = await directus.getItems('schedules', {
        fields: 'id,date',
        filter: {
          subject_classes_id: id
        },
        sort: '-date',
        limit: 1
      });

      if (!result.error) {
        setDataSchedule(result.data);
        getSubject();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of subjects
  const getSubject = async () => {
    try {
      var result = await directus.getItems('subjects', { fields: '*' });
      if (!result.error) {
        setDataSubject(result.data);
        getTeacher();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of teachers
  const getTeacher = async () => {
    try {
      var result = await directus.getItems('teachers', { fields: 'id,name,nickname', limit: -1 });
      if (!result.error) {
        setDataTeacher(result.data);
        getRoom();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of rooms
  const getRoom = async () => {
    try {
      var result = await directus.getItems('rooms');
      if (!result.error) {
        setDataRoom(result.data);
        getStudentSubjectClass();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of subject class
  const getStudentSubjectClass = async () => {
    try {
      var result = await directus.getItems('students_subject_classes', {
        filter: {
          subject_classes_id: classId
        }
      });
      if (!result.error) {
        setDataStudentSubjectClass(result.data);
        setLoading(false);
        setLoadingButton(false);
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Handle Subject Class ========================================== */ }
  //----------delete subject class ----------//
  //open dialog delete subject class
  const handleOpenDialogDeleteClass = () => {
    dialogDeleteClass.current.visible = true;
  }

  const handleCloseDialogDeleteClass = () => {
    dialogDeleteClass.current.visible = false;
  }

  //delete subject class
  const deleteClass = async () => {
    setLoadingButton(true);
    try {
      await subject_class.deleteSubjectClass(classId);
      const body_activity = {
        action: "Delete",
        category: 8,
        description: data.name,
        status: "published"
      }
      await directus.createItem('activity_log', body_activity);
      EventEmitter.emit('alert_toast', { content: Class_deleted_successfully, type: "success" });
      return navigate('/subject_class/list');
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Filtering ========================================== */ }
  //search teacher by name
  const onFilteringTeacher = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataTeacher, query);
  };

  //search room by name
  const onFilteringRoom = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataRoom, query);
  };


  //get week translation
  const getWeek = (props) => {
    var week_2 = props ? props : null;
    if (weeks) {
      weeks.forEach((value) => {
        if (value.id === week_2) {
          week_2 = value.name
        }
      })
    }
    return "" + week_2
  }

  // {/* ================== Handle Add Schedule ========================================== */ }

  //open dialog add meeting
  const handleOpenDialogAddSchedule = () => {
    dialogAddSchedule.current.visible = true;
    setStateSchedule({
      date: new Date(),
      duration: data.duration,
      room: data.meetings[0].meetings_id.room.id,
      teachers_id: data.meetings[0].meetings_id.teacher.id,
      time: moment(data.meetings[0].meetings_id.time, "HH:mm:ss").format("HH:mm")
    })
  }

  const handleCloseDialogAddSchedule = () => {
    setError({ ...error, date: false, time: false, duration: false });
    dialogAddSchedule.current.visible = false;
  }

  //handle change of add new schedule dialog
  const handleChangeAddSchedule = (event, type) => {
    var value = "";
    //get schedule data value and put into state
    if (type === "teachers_id" || type === "room") {
      value = event.itemData.id;
    } else if (type === "time") {
      if (event.value) {
        value = moment(event.value).format('HH:mm');
      }
    } else {
      value = event.value
    }

    if (type === "date" || type === "duration" || type === "time") {
      if (value) {
        setError({ ...errorRef.current, [type]: false })
      } else {
        setError({ ...errorRef.current, [type]: true })
      }
    }

    setStateSchedule({ ...stateSchedule, [type]: value })
  }

  //add new schedule
  const addSchedule = async () => {
    if (!error.date && !error.time && !error.duration) {
      setError({ ...error, date: false, time: false, duration: false });

      //get max date of schedule data
      var max_date = linq.from(dataScheduleRef.current).maxBy(data => data.date);

      var dataUpdate = stateScheduleRef.current;
      if (dataUpdate.date && dataUpdate.time && dataUpdate.duration && dataUpdate.teachers_id && dataUpdate.room) {
        setLoadingButton(true);

        var body = {
          subject_classes_id: classId,
          date: moment(dataUpdate.date).format("YYYY-MM-DD"),
          time: dataUpdate.time + ":00",
          duration: dataUpdate.duration,
          teachers_id: dataUpdate.teachers_id,
          room: dataUpdate.room,
          status: "published"
        }

        //check if new date is greater than the max date
        if (moment(new Date(body.date)).isAfter(new Date(max_date.date)) === true) {
          //if true, change the subject class end date
          await directus.updateItem('subject_classes', classId, { end_date: moment(body.date).format("YYYY-MM-DD") });
        }

        // add new schedule to database
        try {
          var result_schedule = await directus.createItem('schedules', body);
          if (!result_schedule.error) {
            if (dataStudentSubjectClassRef.current.length > 0) {

              var schedule_id = result_schedule.data.id;
              var attendances = [];
              dataStudentSubjectClass.map((value) => {
                const body = {
                  schedules_id: schedule_id,
                  students_subject_classes_id: value.id
                }
                attendances.push(body);
              })

              await directus.createItems('attendances', attendances);
            }
            getData();
            setDataButton("add_schedule");
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          setLoadingButton(false);
        }
      } else {
        if (!dataUpdate.date) { setError({ ...errorRef.current, date: true }) }
        if (!dataUpdate.time) { setError({ ...errorRef.current, time: true }) }
        if (!dataUpdate.duration) { setError({ ...errorRef.current, duration: true }) }
      }
    }
  }

  //display list of meetings data
  function getMeetings(props) {
    const list = [];


    props.forEach((value, index) => {
      var data = value.meetings_id;
      var time = moment(data.time, "hh:mm:ss").format("HH:mm");

      list.push(<div key={index}>{getWeek(data.weekday)} @ {time} - {data.teacher.nickname} ({data.room.name}) </div>);
    })

    return (
      <div>{list}</div>
    )
  }

  // {/* ================== Handle Update Subject Class ========================================== */ }

  //set open close dialog
  const handleToggleClassEditDialog = () => {
    setStateSubjectClass({
      ...stateSubjectClassRef.current,
      subjects: data.subjects,
      name: data.name,
      start_date: data.start_date,
      end_date: data.end_date,
      duration: data.duration,
      registration_fee: data.registration_fee,
      monthly_fee: data.monthly_fee,
      lesson_fee: data.lesson_fee,
      meettings: data.meetings
    });

    setPrevStartDate(data.start_date);
    setError({ ...error, name: false, start_date: false })
    setOpen(!openRef.current);
  };


  //handle change add class dialog
  const handleChange = (event, type) => {
    var value = null;
    if (type === "subject_edit") {
      setStateSubjectClass({ ...stateSubjectClassRef.current, "subjects": event.itemData })
    } else {
      if (type === "subjects") {
        if (event.itemData.id) {
          value = event.itemData.id;
          setStateSubjectClass({ ...stateSubjectClass, name: event.itemData.name })
        }
      } else {
        value = event.value;
      }

      if (type === "subject" || type === "start_date" || type === "name" || type === "number_of_meetings") {
        if (value) {
          setError({ ...errorRef.current, [type]: false })
        } else {
          setError({ ...errorRef.current, [type]: true })
        }
      }

      if (type === "subjects") {
        setDataNumberOfMeetings(true);
        var numOfMeetings = event.itemData.group.default_meetings ? event.itemData.group.default_meetings : 24;
        if(event.itemData.subject_schedule){
          if (event.itemData.subject_schedule.length > 0) {
            numOfMeetings = event.itemData.subject_schedule.length;
          }
        }
        
        setStateSubjectClass({ ...stateSubjectClassRef.current, number_of_meetings: numOfMeetings });
      }
      //set all the data into the state
      setStateSubjectClass({ ...stateSubjectClassRef.current, [type]: value })
    }

  }

  const updateClass = async () => {
    let checkEmptyTime = stateRowRef.current.rows.find(o => o.time === '' || o.time === null || o.time === 'Invalid date');

    if (!error.name && !error.start_date) {

      setError({ ...errorRef.current, name: false, start_date: false, meetings: false });
      setLoadingButton(true);
      var update_data = stateSubjectClassRef.current;

      if (update_data.name && update_data.start_date && !checkEmptyTime) {
        try {
          let body = {
            name: update_data.name,
            registration_fee: update_data.registration_fee,
            monthly_fee: update_data.monthly_fee,
            lesson_fee: update_data.lesson_fee,
            subjects: update_data.subjects ? update_data.subjects.id : update_data.subjects,
            start_date: update_data.start_date ? moment(update_data.start_date).format("YYYY-MM-DD") : null,
            duration: update_data.duration
          }

          var new_date = new Date(update_data.start_date);
          var old_date = new Date(prevStartDateRef.current);

          if (moment(new_date).format("YYYY-MM-DD") !== moment(old_date).format("YYYY-MM-DD")) {
            var differentDate = window.confirm(`${Are_you_sure_you_want_to_change_the_start_date}? ${All_schedules_will_be_rescheduled}.`)
            if (differentDate === false) {
              setLoadingButton(false);
              return
            }

            var schedule_data = await directus.getItems('schedules', {
              fields: 'id,date',
              filter: {
                subject_classes_id: classId
              },
              sort: 'date',
            });

            let weekName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            let weekName_2 = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

            //combine list of week in meeting data
            var week = '';
            stateRowRef.current.rows.map((value, index) => {
              week += weekName_2[weekName.indexOf(value.weekday)];

              if (index + 1 !== stateRowRef.current.rows.length) {
                week += ',';
              }
            });

            //add the week list and number of meetings into recurrence editor filter
            let filter = 'FREQ=WEEKLY;BYDAY=' + week + ';INTERVAL=1;COUNT=' + schedule_data.data.length + ';';

            //get all recurrence dates
            let dates = recObject.current.getRecurrenceDates(new_date, filter, null, null, null);

            for (let index = 0; index < dates.length; index++) {
              schedule_data.data[index].date = moment(new Date(dates[index])).format("YYYY-MM-DD");
              schedule_data.data[index].duration = parseInt(update_data.duration);
              var select_meeting = linq.from(stateRowRef.current.rows)
                .where(w => w.weekday === weeks[moment(new Date(dates[index])).day()].id)
                .toArray();
              schedule_data.data[index].teachers_id = select_meeting[0].teacher;
              schedule_data.data[index].time = select_meeting[0].time ? select_meeting[0].time + ':00' : null;
              schedule_data.data[index].room = select_meeting[0].room;
            }
            
            body['end_date'] = moment(new Date(dates[dates.length-1])).format("YYYY-MM-DD");

            await directus.updateItems('schedules', schedule_data.data);

          }

          var meeting_data = [];
          stateRowRef.current.rows.map((value) => {
            meeting_data.push({
              id: value.id,
              weekday: value.weekday,
              time: value.time ? value.time + ':00' : null,
              teacher: value.teacher,
              room: value.room
            })
          })

          await directus.updateItems('meetings', meeting_data);


          var result = await directus.updateItem('subject_classes', classId, body);
          if (!result.error) {

            var description = update_data.name;
            if (update_data.name !== data.name) {
              description = data.name + " --> " + update_data.name;
            }
            const body_activity = {
              action: "Update",
              category: 8,
              description: description,
              status: "published"
            }
            await directus.createItem('activity_log', body_activity);

            setOpen(false);
            getData();
            setStateSubjectClass({ name: null, start_date: null, subjects: null, meetings: null });
            EventEmitter.emit('update_schedule', { update: true });
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
          setLoadingButton(false);
        }
      } else {
        if (!update_data.name) { setError({ ...errorRef.current, name: true }); }
        if (!update_data.start_date) { setError({ ...errorRef.current, start_date: true }); }
        if (checkEmptyTime) { setError({ ...errorRef.current, meetings: true }); }
      }
    }
  }

  // {/* ================== Handle Duplicate Subject Classes ========================================== */ }
  const handleToggleAddClassDialog = () => {
    setOpenAddClass(!openAddClassRef.current);
    if (openAddClassRef.current === true) {

      setStateSubjectClass({
        ...stateSubjectClassRef.current,
        subjects: data.subjects,
        name: data.name,
        start_date: data.start_date,
        end_date: data.end_date,
        duration: data.duration,
        registration_fee: data.registration_fee,
        monthly_fee: data.monthly_fee,
        lesson_fee: data.lesson_fee,
        number_of_meetings: data.subjects.group.default_meetings
      });
    }
    setError({ subject: false, name: false, start_date: false, meetings: false });
    setStateRow({ rows: stateRowDefaultRef.current.rows })
  };

  const addClass = async () => {
    var status_update = false;
    if (!stateRow.rows.length > 0) { setError({ ...errorRef.current, meetings: true }); }
    if (!error.subject && !error.name && !error.start_date) {
      try {
        setError({ subject: false, name: false, start_date: false, meetings: false });
        setLoadingButton(true);
        let data_meeting = [];
        var weekdays = [];

        var status = false;
        var data_subject = null;
        if (stateSubjectClassRef.current.subjects) {
          //get duration, fee from subjects
          data_subject = await directus.getItems('subjects',
            {
              fields: '*.*.*',
              filter: {
                id: stateSubjectClassRef.current.subjects ? stateSubjectClassRef.current.subjects.id ? stateSubjectClassRef.current.subjects.id : stateSubjectClassRef.current.subjects : null
              }
            }
          );

          //get meeting data and check if there is an empty data
          if (stateRow.rows.length > 0) {

            //get all row state and save into array with formatted time
            stateRow.rows.map((value) => {
              let time = null;
              if (value.weekday && value.time && value.time !== "Invalid date" && value.teacher && value.room) {
                if (value.time) {
                  time = value.time + ':00'
                }

                const result = {
                  weekday: value.weekday,
                  time: time,
                  teacher: value.teacher,
                  room: value.room,
                  duration: data_subject.data[0].meeting_duration,
                  status: "published"
                }

                data_meeting.push(result);
                weekdays.push(result.weekday);

              } else {
                status = true;
                setError({ ...errorRef.current, meetings: true });
              }
            })
          }
        }

        //get number of meetings
        var numberOfMeetings = stateSubjectClassRef.current.number_of_meetings;

        if (status === false && data_meeting.length > 0 && stateSubjectClassRef.current.subjects && stateSubjectClassRef.current.name && stateSubjectClassRef.current.start_date && stateSubjectClassRef.current.start_date !== "Invalid date" && numberOfMeetings !== 0) {
          var meetings_id = null;
          var subject_classes_id = null;

          //save meeting data to database
          var result = await directus.createItems('meetings', data_meeting);
          meetings_id = result.data.map(item => item['id'])

          if (meetings_id) {
            //get recurrence date with abbreviated days
            let weekName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            let weekName_2 = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

            //combine list of week in meeting data
            var week = '';
            weekdays.map((value, index) => {
              week += weekName_2[weekName.indexOf(value)];
              if (index + 1 !== weekdays.length) {
                week += ',';
              }
            });

            //add the week list and number of meetings into recurrence editor filter
            let filter = 'FREQ=WEEKLY;BYDAY=' + week + ';INTERVAL=1;COUNT=' + numberOfMeetings + ';';

            //get all recurrence dates
            let dates = recObject.current.getRecurrenceDates(new Date(stateSubjectClassRef.current.start_date), filter, null, null, null);
            let new_dates = [];
            for (let index = 0; index < dates.length; index++) {
              new_dates.push(new Date(dates[index]));
            }

            //get new subject class data and save into object with formatted date
            const dataSubjectClass = {
              subjects: stateSubjectClassRef.current.subjects,
              name: stateSubjectClassRef.current.name,
              start_date: moment(stateSubjectClassRef.current.start_date).format("YYYY-MM-DD"),
              end_date: moment(new Date(Math.max.apply(null, new_dates))).format("YYYY-MM-DD"),
              duration: stateSubjectClassRef.current.duration,
              registration_fee: stateSubjectClassRef.current.registration_fee,
              monthly_fee: stateSubjectClassRef.current.monthly_fee,
              lesson_fee: stateSubjectClassRef.current.lesson_fee,
              status: "published"
            }

            //save subject class data into database and get the subject classes id id
            var result2 = await directus.createItem('subject_classes', dataSubjectClass);
            subject_classes_id = result2.data.id;

            var dataSubjectMeeting = [];
            //get subject classes id and all of the meetings id and save into array
            meetings_id.map((value) => {
              var body = {
                meetings_id: value,
                subject_classes_id: subject_classes_id
              }

              dataSubjectMeeting.push(body);
            })

            //save subject class data into database
            await directus.createItems('subject_classes_meetings', dataSubjectMeeting);


            //save all reccurence date into schedule
            let dataSchedule = [];
            if (data_subject.data[0].group.id) {
              //get all topic in subject class
              var topic = data_subject.data[0].subject_schedule ? data_subject.data[0].subject_schedule : null;
              new_dates.map((date, index_date) => {
                var num = new Date(date).getDay();
                var select_meeting = linq.from(data_meeting)
                  .where(w => w.weekday === weeks[num].id)
                  .toArray();

                //check if there is a topic, add the topic into the schedule
                var select_topic = null
                if (topic.length > 0) {
                  select_topic = linq.from(topic)
                    .where(w => w.subject_schedule_id.sort === index_date + 1)
                    .toArray();
                }

                let body = {
                  teachers_id: select_meeting[0].teacher,
                  subject_classes_id: subject_classes_id,
                  date: moment(date).format("YYYY-MM-DD"),
                  time: select_meeting[0].time,
                  duration: stateSubjectClassRef.current.duration,
                  room: select_meeting[0].room,
                  status: "published",
                  topic: select_topic ? select_topic.length > 0 ? select_topic[0].subject_schedule_id.topic_description : null : null,
                  test: select_topic ? select_topic.length > 0 ? select_topic[0].subject_schedule_id.test : false : false,
                  review: select_topic ? select_topic.length > 0 ? select_topic[0].subject_schedule_id.review : false : false
                }
                dataSchedule.push(body)
              })
            }

            //save schedule data into database and re-render the data
            var result_schedule = await directus.createItems('schedules', dataSchedule);

            //save student data into new class
            if (data.students) {
              if (data.students.length > 0) {
                var students_subject_classes = [];
                data.students.map((value) => {
                  var body = {
                    subject_classes_id: subject_classes_id,
                    students_id: value.students_id.id,
                    status: "published"
                  }

                  students_subject_classes.push(body);
                })

                var students_subject_classes_result = await directus.createItem('students_subject_classes', students_subject_classes);

                if (!students_subject_classes_result.error) {
                  var attendances = [];
                  result_schedule.data.map((value) => {
                    students_subject_classes_result.data.map((value2) => {
                      var body = {
                        schedules_id: value.id,
                        students_subject_classes_id: value2.id,
                        status: "published"
                      }
                      attendances.push(body);
                    })
                  })

                  //save student into new attendance
                  await directus.createItems('attendances', attendances);

                  var student_result = [];
                  data.students.map((value) => {
                    var body = {
                      student: value.students_id.id,
                      subject_class: subject_classes_id,
                      status: "published"
                    }
                    student_result.push(body);
                  })

                  //save student into new results
                  await directus.createItems('results', student_result);
                }
              }
              status_update = true;
            } else {
              status_update = true;
            }

            const body_activity = {
              action: "Add",
              category: 8,
              description: stateSubjectClassRef.current.name,
              status: "published"
            }
            await directus.createItem('activity_log', body_activity);
          }
        } else {
          if (stateSubjectClassRef.current.subjects && numberOfMeetings === 0) { setError({ ...errorRef.current, number_of_meetings: true }); }
          if (!stateSubjectClassRef.current.subjects) { setError({ ...errorRef.current, subject: true }) }
          if (!stateSubjectClassRef.current.name) { setError({ ...errorRef.current, name: true }) }
          if (!stateSubjectClassRef.current.start_date || stateSubjectClassRef.current.start_date === "Invalid date") { setError({ ...errorRef.current, start_date: true }) }
          if (data_meeting.length === 0) { setError({ ...errorRef.current, meetings: true }) }
          setLoadingButton(false);
        }
      } catch (error) {
        EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        setLoadingButton(false);
      }

      if (status_update === true) {
        handleToggleAddClassDialog();
        setStateSubjectClass({ name: null, start_date: null, subjects: null, meetings: null });
        setStateRow({ rows: [{}] })
        EventEmitter.emit('duplicate_data', { update: true, new_id: subject_classes_id })
        EventEmitter.emit('alert_toast', { content: Class_created_successfully, type: "success" });
        return navigate('/subject_class/detail/' + subject_classes_id);
      }
    }

  };
  /* eslint-enable array-callback-return*/
  // {/* ================== Handle Meetings Table ========================================== */ }

  //handle onclick add row button
  const handleAddRow = () => {
    const item = {
      weekday: null,
      time: null,
      teacher: null,
      room: null
    };

    setStateRow({ rows: [...stateRow.rows, item] });
  };

  //handle row changed
  const handleChangeRow = (idx, type) => (e) => {

    var value = "";
    if (type === "weekday") {
      value = e.itemData.id;
    } else if (type === "teacher" || type === "room") {
      value = e.itemData.id
    } else if (type === "time") {
      value = e.value ? moment(e.value).format('HH:mm') : null
    }

    const rows = [...stateRowRef.current.rows];

    //set new row changed data to row state
    rows[idx] = {
      ...stateRowRef.current.rows[idx],
      [type]: value
    };

    setStateRow({ rows })

  };


  //handle remove specific row by id
  function handleRemoveSpecificRow(idx) {
    const rows = [...stateRowRef.current.rows];
    rows.splice(idx, 1);
    setStateRow({ rows });
  }


  // {/* ================== Add Meeting Display ========================================== */ }

  //render meeting table
  function renderMeetingTable() {
    const list = [];

    list.push(
      <div key={'meeting-table'}>
        <table>
          <thead>
            <tr>
              <th className="text-center"> *{Day} </th>
              <th className="text-center"> *{Time} </th>
              <th className="text-center"> *{Teacher} </th>
              <th className="text-center"> *{Room} </th>
              <th />
            </tr>
          </thead>
          <tbody>

            {/* get all the current row state */}
            {stateRowRef.current.rows.map((item, idx) => (
              <tr id="meeting-row" key={idx}>
                <td>
                  {/* get list of weekdays  */}
                  <DropDownListComponent
                    dataSource={weeks}
                    name="weekday"
                    cssClass="e-outline"
                    placeholder={`--${Select_Day}--`}
                    fields={fields}
                    select={handleChangeRow(idx, "weekday")}
                    value={item.weekday}
                    className="form-control"
                  />
                </td>

                <td>
                  {/* display time with format HH:mm ex: 14:30 */}
                  <TimePickerComponent
                    name="time"
                    cssClass="e-outline"
                    step={30}
                    format={'HH:mm'}
                    showClearButton={false}
                    onChange={handleChangeRow(idx, "time")}
                    value={item.time}
                    className="form-control"
                  />
                </td>
                <td>
                  {/* get list of teachers  */}
                  <DropDownListComponent
                    dataSource={dataTeacher}
                    name="teacher"
                    cssClass="e-outline"
                    placeholder={`--${Select_Teacher}--`}
                    fields={{ text: 'nickname', value: 'id' }}
                    select={handleChangeRow(idx, "teacher")}
                    value={item.teacher}
                    className="form-control"
                  />
                </td>
                <td>
                  {/* get list of rooms  */}
                  <DropDownListComponent
                    dataSource={dataRoom}
                    name="room"
                    cssClass="e-outline"
                    placeholder={`--${Select_Room}--`}
                    fields={fields}
                    select={handleChangeRow(idx, "room")}
                    value={stateRowRef.current.rows[idx].room}
                    className="form-control"
                  />
                </td>
                <td>
                  {/* display remove meeting button with specific id */}
                  <span
                    style={{ padding: "10px", color: "grey", cursor: "not-allowed" }}
                    className="e-icons icon-delete-05">
                    <input type="hidden" value={idx} />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {errorRef.current.meetings === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
      </div>

    )
    return (
      <div>{list}</div>
    )
  }


  function renderMeetingTableAdd() {
    const list = [];

    list.push(
      <div key={'meeting-table'}>
        <table>
          <thead>
            <tr>
              <th className="text-center"> *{Day}</th>
              <th className="text-center"> *{Time}</th>
              <th className="text-center"> *{Teacher} </th>
              <th className="text-center"> *{Room}</th>
              <th />
            </tr>
          </thead>
          <tbody>

            {/* get all the current row state */}
            {stateRowRef.current.rows.map((item, idx) => (
              <tr id="meeting-row" key={idx}>
                <td>
                  {/* get list of weekdays  */}
                  <DropDownListComponent
                    dataSource={weeks}
                    name="weekday"
                    cssClass="e-outline"
                    placeholder={`--${Select_Day}--`}
                    fields={fields}
                    select={handleChangeRow(idx, "weekday")}
                    value={item.weekday}
                    className="form-control"
                  />
                </td>

                <td>
                  {/* display time with format HH:mm ex: 14:30 */}
                  <TimePickerComponent
                    name="time"
                    cssClass="e-outline"
                    step={30}
                    format={'HH:mm'}
                    onChange={handleChangeRow(idx, "time")}
                    value={item.time}
                    className="form-control"
                  />
                </td>
                <td>
                  {/* get list of teachers  */}
                  <DropDownListComponent
                    dataSource={dataTeacher}
                    name="teacher"
                    cssClass="e-outline"
                    placeholder={`--${Select_Teacher}--`}
                    fields={{ text: 'nickname', value: 'id' }}
                    select={handleChangeRow(idx, "teacher")}
                    value={item.teacher}
                    className="form-control"
                  />
                </td>
                <td>
                  {/* get list of rooms  */}
                  <DropDownListComponent
                    dataSource={dataRoom}
                    name="room"
                    cssClass="e-outline"
                    placeholder={`--${Select_Room}--`}
                    fields={fields}
                    select={handleChangeRow(idx, "room")}
                    value={stateRowRef.current.rows[idx].room}
                    className="form-control"
                  />
                </td>
                <td>
                  {/* display remove meeting button with specific id */}
                  <span
                    style={{ padding: "10px", cursor: "pointer", color: "#3c8dbc" }}
                    onClick={() => handleRemoveSpecificRow(idx)}
                    className="e-icons icon-delete-05">
                    <input type="hidden" value={idx} />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {error.meetings === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
      </div>

    )
    return (
      <div>{list}</div>
    )
  }

  // {/* ================== Schedule Content ========================================== */ }
  const contentSchedule = (class_name) => {
    // return (<div style={{ backgroundColor: "#fff", minHeight: "100%" }}><Schedule dataSchedule={dataScheduleRef.current} data={dataRef.current} dataTeacher={dataTeacherRef.current} /></div>)
    return (<div><Schedule class_name={class_name} data={dataRef.current} dataTeacher={dataTeacherRef.current} dataSubject={dataSubjectRef.current} dataRoom={dataRoomRef.current} /></div>)
  }

  const contentResult = () => {
    return (<div><Result /></div>)
  }

  // {/* ================== Footer Template ========================================== */ }
  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }


  // {/* ================== Main Content ========================================== */ }

  return (
    <div>
      {loading ?
        <div style={{ position: "relative" }}>
          <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        </div>
        :
        dataStudentSubjectClassRef.current ?
          <div>
            <div className="header">
              {/* <span style={{ float: "right", textAlign: "right", color: "gray" }}>{Created_by} : {data.owner.first_name} {data.owner.last_name}<br />
              {moment(data.created_on).format("DD-MMM-YYYY")}</span> */}
              <div>

                <div className="content-header">{Class}</div>
              </div>
            </div>
            {/* check if state return all dates in schedule */}

            <div className="content-main">
              <div style={{ color: "#333", marginTop: "15px", paddingBottom: "10px" }}>
                <h3>{data.name}</h3>

                {permission.AdminPermission() ?
                  <div style={{ textAlign: "right" }}>
                    {/* button for open dialog add new meeting */}
                    <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddSchedule} ><span className="btn-text-custom">{Meeting}</span></ButtonComponent>
                    <ButtonComponent className="btn-custom" iconCss='e-icons icon-edit-icon1' onClick={handleToggleClassEditDialog} ><span className="btn-text-custom">{Edit}</span></ButtonComponent>
                    <ButtonComponent className="btn-custom" iconCss='e-icons icon-M_PV_Thumbnail' onClick={handleToggleAddClassDialog} ><span className="btn-text-custom">{Duplicate}</span></ButtonComponent>
                    <ButtonComponent className="btn-custom delete" iconCss='e-icons icon-delete-05' onClick={handleOpenDialogDeleteClass} ><span className="btn-text-custom delete" style={{ color: "red" }}>{Delete}</span></ButtonComponent>
                  </div>
                  : null}
                <div id="class_summary">

                  {/* display subject class data */}
                  <table>
                    <tbody>
                      <tr>
                        <th style={{ padding: "5px" }}>{Start_Date} :</th>
                        <td style={{ padding: "5px" }}>{data.start_date ? moment(data.start_date).format("DD-MMM-YYYY") : '-'}</td>
                        <th style={{ padding: "5px" }}>{End} :</th>
                        <td style={{ padding: "5px" }}>{data.end_date ? moment(data.end_date).format("DD-MMM-YYYY") : '-'}</td>
                      </tr>
                      <tr>
                        <th style={{ padding: "5px", verticalAlign: "top" }}>{Meetings} :</th>
                        <td colSpan="3">
                          {/* display all meeting data  */}
                          {getMeetings(data.meetings)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
              </div>
              <div style={{ backgroundColor: "#fff" }}>
                <TabComponent className="e-fill" heightAdjustMode='Content' enablePersistence={false}>
                  <TabItemsDirective>
                    <TabItemDirective header={headerText[0]} content={() => contentSchedule(data.name)} />
                    <TabItemDirective header={headerText[1]} content={contentResult} />
                  </TabItemsDirective>
                </TabComponent>

                {/* ================== Add New Schedule/Meeting Dialog ========================================== */}

                <DialogComponent
                  id="dialogAddSchedule"
                  width="40%"
                  showCloseIcon={!loadingButton ? true : false}
                  visible={false}
                  ref={dialogAddSchedule}
                  header={Add_Schedule}
                  isModal={true}
                  footerTemplate={() => footerTemplate([
                    { name: Update, func: addSchedule, icon: "icon-check-mark-01", status: "e-success" },
                    { name: Cancel, func: handleCloseDialogAddSchedule, icon: "icon-treeview", status: "" }
                  ])}
                  close={handleCloseDialogAddSchedule}
                >
                  <div>
                    <div>
                      <b>{Class}</b>
                      <DropDownListComponent
                        dataSource={dataSubject}
                        name="class"
                        cssClass="e-outline"
                        enabled={false}
                        fields={fields}
                        value={data.subjects.id}
                      />
                      <br /><br />
                      <b>*{Teacher}</b>
                      <DropDownListComponent
                        dataSource={dataTeacher}
                        name="teacher"
                        cssClass="e-outline"
                        placeholder={`--${Select_Teacher}--`}
                        filtering={onFilteringTeacher}
                        allowFiltering={true}
                        fields={{ text: 'nickname', value: 'id' }}
                        select={(e) => handleChangeAddSchedule(e, "teachers_id")}
                        value={stateScheduleRef.current.teachers_id}
                      />
                      <br /><br />
                      <b>*{Room}</b>
                      <DropDownListComponent
                        dataSource={dataRoom}
                        name="room"
                        cssClass="e-outline"
                        placeholder={`--${Select_Room}--`}
                        filtering={onFilteringRoom}
                        allowFiltering={true}
                        fields={fields}
                        select={(e) => handleChangeAddSchedule(e, "room")}
                        value={stateScheduleRef.current.room}
                      />
                      <br /><br />
                      <b>*{Date_2}</b>
                      <DatePickerComponent
                        name="date"
                        cssClass="e-outline"
                        format="dd-MMM-yyyy"
                        change={(e) => handleChangeAddSchedule(e, "date")}
                        value={stateScheduleRef.current.date}
                      >
                      </DatePickerComponent>
                      {error.date === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                      <br /><br />
                      <b>*{Time}</b>
                      <TimePickerComponent
                        name="time"
                        cssClass="e-outline"
                        step={30}
                        format={'HH:mm'}
                        change={(e) => handleChangeAddSchedule(e, "time")}
                        value={stateScheduleRef.current.time}
                      />
                      {error.time === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                      <br /><br />
                      <b>*{Duration}</b>
                      <TextBoxComponent
                        name="duration"
                        cssClass="e-outline"
                        change={(e) => handleChangeAddSchedule(e, "duration")}
                        value={stateScheduleRef.current.duration ? stateScheduleRef.current.duration : 60}
                      />
                      {error.duration === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                    </div>
                  </div>
                </DialogComponent>


                {/* Delete Subject Class  */}
                <DialogComponent
                  id="dialogDeleteClass"
                  width='25%'
                  showCloseIcon={!loadingButton ? true : false}
                  visible={false}
                  ref={dialogDeleteClass}
                  header={Delete_Confirmation}
                  isModal={true}
                  footerTemplate={() => footerTemplate([
                    { name: Delete, func: deleteClass, icon: "icon-delete-05", status: "e-warning" },
                    { name: Cancel, func: handleCloseDialogDeleteClass, icon: "icon-treeview", status: "" }
                  ])}
                  close={handleCloseDialogDeleteClass}
                >
                  <div>{Are_you_sure_you_want_to_delete_the_data_of_class} ({data.name})?</div>
                </DialogComponent>


                {/* ================== Edit Subject Class Dialog ========================================== */}

                <div>
                  <Dialog id="dialogEditClass" open={open} >
                    <DialogTitle><div style={{ fontSize: '18px' }}>{Edit_Class}</div></DialogTitle>
                    <DialogContent>
                      <div>
                        <div>
                          <div>
                            <b>*{Subject}</b>
                            <DropDownListComponent
                              dataSource={dataSubject}
                              name="subject"
                              cssClass="e-outline"
                              placeholder={`--${Select_Subject}--`}
                              fields={fields}
                              change={(e) => handleChange(e, "subject_edit")}
                              value={stateSubjectClassRef.current.subjects ? stateSubjectClassRef.current.subjects.id : null}
                            />
                            <br /><br />
                            <b>*{Name}</b>
                            <TextBoxComponent
                              name="name"
                              cssClass="e-outline"
                              change={(e) => handleChange(e, "name")}
                              value={stateSubjectClassRef.current.name}
                            />
                            {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                            <br /><br />
                            <b>{Start_Date}</b>
                            <DatePickerComponent
                              name="start_date"
                              cssClass="e-outline"
                              format="dd-MMM-yyyy"
                              showClearButton={false}
                              change={(e) => handleChange(e, "start_date")}
                              value={stateSubjectClassRef.current.start_date ? new Date(stateSubjectClassRef.current.start_date) : null}
                            />
                            {error.start_date === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                            <br /><br />
                            <b>{Duration} ({Minutes})</b>
                            <TextBoxComponent
                              name="duration"
                              cssClass="e-outline"
                              change={(e) => handleChange(e, "duration")}
                              value={stateSubjectClassRef.current.duration}
                            />
                            <br /><br />
                            <b>{Registration_Fee}</b>
                            <NumericTextBoxComponent
                              cssClass="e-outline"
                              name="registration_fee"
                              placeholder='IDR 0'
                              format='IDR #########'
                              min={0}
                              change={(e) => handleChange(e, "registration_fee")}
                              value={stateSubjectClassRef.current.registration_fee ? stateSubjectClassRef.current.registration_fee : 0}
                            />
                            <br /><br />
                            <b>{Monthly_Fee}</b>
                            <NumericTextBoxComponent
                              cssClass="e-outline"
                              name="monthly_fee"
                              placeholder='IDR 0'
                              format='IDR #########'
                              min={0}
                              change={(e) => handleChange(e, "monthly_fee")}
                              value={stateSubjectClassRef.current.monthly_fee ? stateSubjectClassRef.current.monthly_fee : 0}
                            />
                            <br /><br />
                            <b>{Lesson_Fee}</b>
                            <NumericTextBoxComponent
                              cssClass="e-outline"
                              name="lesson_fee"
                              placeholder='IDR 0'
                              format='IDR #########'
                              min={0}
                              change={(e) => handleChange(e, "lesson_fee")}
                              value={stateSubjectClassRef.current.lesson_fee ? stateSubjectClassRef.current.lesson_fee : 0}
                            />
                            <br /><br />
                            <b>{Meeting_List}</b>
                            <br /><br />
                            {renderMeetingTable()}
                          </div>
                          <br />
                          <br />
                        </div>
                      </div>
                    </DialogContent>
                    <DialogActions>{
                      loadingButtonRef.current === false ?
                        <div>
                          <ButtonComponent
                            id="btn-update"
                            iconCss='e-icons icon-check-mark-01'
                            style={{ color: '#4d841d', boxShadow: 'none' }}
                            onClick={() => updateClass()}
                          >
                            <b>{Update}</b>
                          </ButtonComponent>

                          <ButtonComponent
                            id='btn-cancel'
                            iconCss='e-icons icon-treeview'
                            style={{ color: '#474747', boxShadow: 'none' }}
                            onClick={handleToggleClassEditDialog}
                          >
                            <b>{Cancel}</b>
                          </ButtonComponent>
                        </div>
                        :
                        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
                    }

                    </DialogActions>
                  </Dialog>
                </div>

                <div>
                  <Dialog id="dialogAddClass" open={openAddClass}>
                    <DialogTitle><div style={{ fontSize: '18px' }}>{Add_Class}</div></DialogTitle>
                    <DialogContent>
                      <div>
                        <div>
                          <b>*{Subject}</b>
                          <DropDownListComponent
                            dataSource={dataSubject}
                            name="subject"
                            cssClass="e-outline"
                            placeholder={`--${Select_Subject}--`}
                            fields={fields}
                            select={(e) => handleChange(e, "subjects")}
                            value={stateSubjectClassRef.current.subjects ? stateSubjectClassRef.current.subjects.id : null}
                          />
                          {error.subject === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                          <br /><br />
                          <b>*{Name}</b>
                          <TextBoxComponent
                            name="name"
                            cssClass="e-outline"
                            change={(e) => handleChange(e, "name")}
                            value={stateSubjectClassRef.current.name}
                          />
                          {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                          <br /><br />
                          <b>*{Start_Date}</b>
                          <DatePickerComponent
                            name="start_date"
                            cssClass="e-outline"
                            format="dd-MMM-yyyy"
                            change={(e) => handleChange(e, "start_date")}
                            value={stateSubjectClassRef.current.start_date ? new Date(stateSubjectClassRef.current.start_date) : null}
                          />
                          {error.start_date === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                          <br /><br />
                          <b>*{Number_of_Meetings}</b>
                          <NumericTextBoxComponent
                            cssClass="e-outline"
                            name="number_of_meetings"
                            format='#########'
                            min={0}
                            change={(e) => handleChange(e, "number_of_meetings")}
                            value={stateSubjectClassRef.current.number_of_meetings ? stateSubjectClassRef.current.number_of_meetings : 0}
                          />
                          {error.number_of_meetings === true ? <span style={{ color: 'red' }}>{The_number_of_meetings_cannot_be_empty}</span> : ''}
                          <br /><br />
                          <b>{Meeting_List}</b>
                          <br /><br />
                          {renderMeetingTableAdd()}
                          <br /><br />
                        </div>
                        <ButtonComponent iconCss='e-icons icon-plus' onClick={() => handleAddRow()} className='e-link' id='btnMeeting' ><b>Meetings</b></ButtonComponent>
                        <br />
                        <br />
                      </div>
                    </DialogContent>
                    <DialogActions>
                      {loadingButtonRef.current === false ?
                        <div>
                          <ButtonComponent
                            id="btn-update"
                            iconCss='e-icons icon-check-mark-01'
                            style={{ color: '#4d841d', boxShadow: 'none' }}
                            onClick={addClass}
                          >
                            <b>{Update}</b>
                          </ButtonComponent>

                          <ButtonComponent
                            id='btn-cancel'
                            iconCss='e-icons icon-treeview'
                            style={{ color: '#474747', boxShadow: 'none' }}
                            onClick={handleToggleAddClassDialog}
                          >
                            <b>{Cancel}</b>
                          </ButtonComponent>
                        </div>
                        :
                        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
                      }
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
            <div className='RecurrenceEditor' style={{ display: 'none' }}>
              <RecurrenceEditorComponent id='RecurrenceEditor' ref={recObject}></RecurrenceEditorComponent>
            </div>
          </div>
          : <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />}
    </div >
  )
}

export default SubjectClassDetail;