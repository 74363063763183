import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { Query } from '@syncfusion/ej2-data'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DropDownList } from "@syncfusion/ej2-dropdowns";

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";
// import Enumerable from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as link from 'src/constants/link';
import * as permission from 'src/utils/Permission';

let cancelFlag = false;
let selectedType = null;
const ActivityList = () => {
  /* eslint-disable no-unused-vars*/
  const navigate = useNavigate();

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Delete] = useState(i18next.t('Pages.button.Delete'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));
  const [Preview] = useState(i18next.t('Pages.button.Preview'));
  const [Duplicate] = useState(i18next.t('Pages.button.Duplicate'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [This_data_is_required] = useState(i18next.t('Pages.general.This_data_is_required'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));
  const [Delete_Confirmation] = useState(i18next.t('Pages.general.Delete_Confirmation'));

  const [All_Types] = useState(i18next.t('Pages.data.All_Types'));
  const [Select_Type] = useState(i18next.t('Pages.data.Select_Type'));
  const [Please_select_activity] = useState(i18next.t('Pages.data.Please_select_activity'));

  const [Activities] = useState(i18next.t('Pages.activity_data.Activities'));
  const [Activity] = useState(i18next.t('Pages.activity_data.Activity'));
  // const [Photo] = useState(i18next.t('Pages.activity_data.Photo'));
  const [Type] = useState(i18next.t('Pages.activity_data.Type'));
  const [Name] = useState(i18next.t('Pages.activity_data.Name'));
  const [Date_2] = useState(i18next.t('Pages.activity_data.Date'));
  const [Start_Time] = useState(i18next.t('Pages.activity_data.Start_Time'));
  const [End_Time] = useState(i18next.t('Pages.activity_data.End_Time'));
  const [Student_Participants] = useState(i18next.t('Pages.activity_data.Student_Participants'));
  const [Teacher_Participants] = useState(i18next.t('Pages.activity_data.Teacher_Participants'));
  // const [Action] = useState(i18next.t('Pages.activity_data.Action'));
  const [Activity_Type] = useState(i18next.t('Pages.activity_data.Activity_Type'));
  const [Activity_Name] = useState(i18next.t('Pages.activity_data.Activity_Name'));
  const [Description] = useState(i18next.t('Pages.activity_data.Description'));
  const [Transportation_Fee] = useState(i18next.t('Pages.activity_data.Transportation_Fee'));
  const [Parent_involvement] = useState(i18next.t('Pages.activity_data.Parent_involvement'));
  const [Enter_activity_name] = useState(i18next.t('Pages.activity_data.Enter_activity_name'));
  const [Activity_created_successfully] = useState(i18next.t('Pages.activity_data.Activity_created_successfully'));
  const [Activity_deleted_successfully] = useState(i18next.t('Pages.activity_data.Activity_deleted_successfully'));
  const [Are_you_sure_you_want_to_delete_the_data_of_activity] = useState(i18next.t('Pages.activity_data.Are_you_sure_you_want_to_delete_the_data_of_activity'));

  //data
  let [data, setData] = useState(null);
  const [dataActivityType, setDataActivityType] = useState(null);
  const [dataActivityType2, setDataActivityType2] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogAddActivity = useRef(false);
  let dialogDeleteActivity = useRef(false);
  let dialogDuplicateActivity = useRef(false);
  let tooltip = useRef(false);

  //grid component setting
  const grid = useRef();
  const filterOptions = { type: 'Menu' };
  const setting = { mode: 'Both' };
  const editSettings = { allowEditing: permission.AdminPermission() ? true : false, allowAdding: true, allowDeleting: true, mode: 'Normal' };
  const validationRule = { required: true };

  //set state add activity
  const [state, setState, stateRef] = useStateRef({
    status: "published",
    name: null,
    type: null,
    date: null,
    start_time: null,
    end_time: null,
    description: null,
    transportation_fee: 0,
    parent_involvement: 0
  });


  //set state filtering
  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    name: null,
    type: 0
  });

  //set error
  const [error, setError, errorRef] = useStateRef({
    type: false,
    name: false,
    date: false,
    start_time: false,
    end_time: false
  });

  const [stateType, setStateType, stateTypeRef] = useStateRef(null);

  let groupObj = useRef();
  let groupElem = useRef();

  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }
    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "delete_activity") {
        dialogDeleteActivity.current.visible = false;
        EventEmitter.emit('alert_toast', { content: Activity_deleted_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })

  // {/* ================== Get All Data ========================================== */ }
  //get all activities
  const getData = async () => {
    try {
      var name = { "nnull": null };
      var type = { "nnull": null };

      if (stateFiltering.name !== null && stateFiltering.name !== "") {
        name = { "contains": stateFilteringRef.current.name };
      }

      if (stateFilteringRef.current.type !== 0 && stateFilteringRef.current.type !== null) {
        type = { "in": stateFilteringRef.current.type };
      } else {
        type = { "nnull": null };
      }

      var result = await directus.getItems('activities',
        {
          fields: "*.*",
          filter: {
            name: name,
            status: "published",
            "type.id": type
          },
          sort: "-date"
        });

      setData(result.data);
      getType();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get all activities type
  const getType = async () => {
    try {
      var result = await directus.getItems('activity_type',
        {
          fields: "*.*",
          sort: "name",
          filter: {
            status: "published"
          }
        });

      setDataActivityType(result.data);

      var newData = result.data;
      if (result.data.length > 0) {
        var oldData = [{ id: 0, name: All_Types }]
        newData = oldData.concat(newData);
      }

      setDataActivityType2(newData);
      setLoading(false);
      setLoadingButton(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Data Grid Template ========================================== */ }
  const nameTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <span className="image" style={{ width: "25px", height: "25px" }}>
          <img
            style={{
              maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
              objectFit: "cover", borderRadius: "50%", position: "center",
            }}
            className="image"
            src={props.image ? props.image.data.full_url : link.no_activity_image}
            alt={props.name}
          />
        </span>
        <span style={{ paddingLeft: "20px", textAlign: "left" }}>{props.name}</span>
      </div>
    )
  };

  // const imageEditTemplate = props => {
  //   return (
  //     <div className="image" style={{ width: "50px", height: "50px" }}>
  //       <img
  //         style={{
  //           maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
  //           objectFit: "fill", border: props.image ? null : "3px solid #d2d6de", borderRadius: props.image ? null : "50%", position: "center",
  //         }}
  //         src={props.image ? props.image.data.full_url : link.no_activity_image}
  //         alt={props.name}
  //       />
  //     </div>
  //   )
  // };

  const dateTemplate = props => {
    return (
      <div>{props.date ? moment(props.date).format('DD-MMM-yyyy') : null}</div>
    )
  };

  const startTimeTemplate = props => {
    return (
      <div>{props.start_time ? moment(props.start_time, "HH:mm:ss").format('HH:mm') : null}</div>
    )
  };

  const startTimeEditTemplate = props => {
    return (
      <TimePickerComponent
        name="start_time"
        step={30}
        format={'HH:mm'}
        value={props.start_time ? moment(props.start_time, "HH:mm:ss").format('HH:mm') : null}
      />
    )
  };

  const endTimeTemplate = props => {
    return (
      <div>{props.end_time ? moment(props.end_time, "HH:mm:ss").format('HH:mm') : null}</div>
    )
  };

  const endTimeEditTemplate = props => {
    return (
      <TimePickerComponent
        name="end_time"
        step={30}
        format={'HH:mm'}
        value={props.end_time ? moment(props.end_time, "HH:mm:ss").format('HH:mm') : null}
      />
    )
  };

  const buttonTemplate = props => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_hover" style={{ display: "flex" }}>
          <TooltipComponent content={Preview} position="BottomCenter" >
            <span>
              <Link to={{ pathname: `/activity/detail/${props.id}` }} style={{ textDecoration: "none" }}>
                <span className="e-icons icon-MT_Preview"
                  style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
                />
              </Link>
            </span>
          </TooltipComponent>
          {permission.AdminPermission() ?
            <TooltipComponent content={Duplicate} position="BottomCenter" style={{ marginLeft: "10px", }} >
              <span className="e-icons icon-MT_Copy"
                style={{ marginLeft: "15px", cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => handleOpenDialogDuplicateActivity(props)}
              />
            </TooltipComponent>
            : ""}
          {permission.AdminPermission() ?
            <TooltipComponent content={Delete} position="BottomCenter" style={{ marginLeft: "10px", }} >
              <span className="e-icons icon-delete-05"
                style={{ marginLeft: "15px", cursor: "pointer", color: '#CD5542', fontSize: "16px", lineHeight: "40px" }}
                onClick={() => handleOpenDialogDeleteActivity(props)}
              />
            </TooltipComponent>
            : ""}
        </div>
      </div>
    )
  }

  const saveGridData = () => {
    cancelFlag = false;
    grid.current.endEdit();
  }

  const buttonEditTemplate = () => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        <div className="action_edit" style={{ display: "flex" }}>
          <TooltipComponent content={Update} position="BottomCenter">
            <span className="e-icons icon-MT_Save"
              style={{ cursor: "pointer", color: '#5F99E9', fontSize: "16px", lineHeight: "40px" }}
              onClick={() => saveGridData()}
            />
          </TooltipComponent>

          <TooltipComponent content={Cancel} position="BottomCenter" style={{ marginLeft: "10px" }} >
            <span className="e-icons icon-circle-close-2"
              style={{ marginLeft: "15px", cursor: "pointer", color: '#516573', fontSize: "18px", lineHeight: "40px" }}
              onClick={() => grid.current.editModule.closeEdit()}
            />
          </TooltipComponent>
        </div>
      </div>
    )
  }

  const typeParams = {
    create: () => {
      groupElem = document.createElement("input");
      return groupElem;
    },
    destroy: () => {
      // groupObj.destroy();
    },
    read: (args) => {
      return args.value;
    },
    write: (args) => {
      groupObj = new DropDownList({
        dataSource: dataActivityType,
        fields: { text: 'name', value: 'id' },
        text: args.rowData[args.column.field],
        floatLabelType: "Never",
        change: (e) => {
          selectedType = e.itemData;
        },
        value: args.rowData.type ? args.rowData.type.id : null
      });
      groupObj.appendTo(groupElem);
    }
  }
  // {/* ================== Data Grid Action (add, edit) ========================================== */ }

  const actionGrid = async (event) => {
    if (event.action === "edit" && event.requestType === "save") {
      if (cancelFlag === false) {
        const select_data = event.data;
        try {
          const body = {
            status: "published",
            name: select_data.name,
            type: selectedType ? selectedType.id : select_data.type ? select_data.type.id : select_data.type,
            date: select_data.date ? moment(select_data.date).format("YYYY-MM-DD") : null,
            start_time: select_data.start_time ? moment(select_data.start_time).format("HH:mm:ss") : null,
            end_time: select_data.end_time ? moment(select_data.end_time).format("HH:mm:ss") : null,
            description: select_data.description,
            transportation_fee: select_data.transportation_fee,
            parent_involvement: select_data.parent_involvement
          }

          var result = await directus.updateItem('activities', select_data.id, body);

          var description = select_data.name;
          if (select_data.name !== event.previousData.name) {
            description = event.previousData.name + " --> " + select_data.name;
          }
          const body_activity = {
            action: "Update",
            category: 4,
            description: description,
            status: "published"
          }
          await directus.createItem('activity_log', body_activity);

          if (!result.error) {
            getData();
            EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
            selectedType = null;
          }
        } catch (error) {
          EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
        }
      } else {
        grid.current.editModule.closeEdit()
      }
      cancelFlag = false;

    } else if (event.requestType === "beginEdit") {
      cancelFlag = true;
      selectedType = null;
    }
  }

  // {/* ================== Filtering ========================================== */ }

  //search activity type by name
  const onFilteringActivityType = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataActivityType, query);
  };

  const handleChangeFilter = (event, type) => {
    var value = "";
    if (type === "name") {
      value = event.value
    }
    setStateFiltering({ ...stateFiltering, [type]: value })

    if (event.syntheticEvent.type === 'change') {
      getData();
    }
  }

  const handleFilter = async (e, type) => {
    var value = "";
    if (type === "type") {
      value = e.itemData.id;
    }

    setStateFiltering({ ...stateFiltering, [type]: value })
    getData();
  }

  const searchActivity = () => {
    getData();
  }

  // {/* ================== Handle Add Acitivity ========================================== */ }
  const handleOpenDialogAddActivity = () => {
    setState({ status: "published", name: null, type: null, date: null, start_time: null, end_time: null, description: null, transportation_fee: 0, parent_involvement: 0 });
    dialogAddActivity.current.visible = true;
  }

  const handleCloseDialogAddActivity = () => {
    setError({ type: false, name: false, date: false, start_time: false, end_time: false })
    setState({ status: "published", name: null, type: null, date: null, start_time: null, end_time: null, description: null, transportation_fee: 0, parent_involvement: 0 });
    dialogAddActivity.current.visible = false;
  }

  const handleChange = (event, type) => {
    var value = "";
    if (type === "type") {
      value = event.itemData.id;
    } else if (type === "transportation_fee" || type === "parent_involvement") {
      value = event.checked;
    } else {
      value = event.value;
    }
    if (type === "type" || type === "name" || type === "date" || type === "start_time" || type === "end_time") {
      if (value) {
        setError({ ...errorRef.current, [type]: false })
      }
    }
    setState({ ...stateRef.current, [type]: value })
  }

  const addActivity = async () => {
    try {
      setError({ type: false, name: false, date: false, start_time: false, end_time: false })
      if (state.type && state.name && state.date && state.start_time && state.end_time) {
        setLoadingButton(true);
        const body = {
          type: state.type,
          name: state.name,
          date: moment(state.date).format("YYYY-MM-DD"),
          start_time: moment(state.start_time).format("HH:mm:ss"),
          end_time: moment(state.end_time).format("HH:mm:ss"),
          description: state.description,
          transportation_fee: state.transportation_fee,
          parent_involvement: state.parent_involvement,
          status: "published"
        }
        var result = await directus.createItem('activities', body);

        const body_activity = {
          action: "Add",
          category: 4,
          description: state.name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          getData();
          dialogAddActivity.current.visible = false;
          setState({ status: "published", name: null, type: null, date: null, start_time: null, end_time: null, description: null, transportation_fee: 0, parent_involvement: 0 });
          EventEmitter.emit('alert_toast', { content: Activity_created_successfully, type: "success" });
          return navigate('/activity/detail/' + result.data.id);
        }
      } else {
        if (!state.type) { setError({ ...errorRef.current, type: true }) }
        if (!state.name) { setError({ ...errorRef.current, name: true }) }
        if (!state.date) { setError({ ...errorRef.current, date: true }) }
        if (!state.start_time) { setError({ ...errorRef.current, start_time: true }) }
        if (!state.end_time) { setError({ ...errorRef.current, end_time: true }) }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Handle Dialog Duplicate Activity ========================================== */ }

  const handleOpenDialogDuplicateActivity = (props) => {
    dialogDuplicateActivity.current.visible = true;
    setSelectedData(props);
    setState({ ...stateRef.current, name: props.name, type: props.type.id, description: props.description })
  }

  const handleCloseDialogDuplicateActivity = () => {
    dialogDuplicateActivity.current.visible = false;
  }

  const duplicateActivity = async () => {
    try {
      if (state.name && state.date) {
        setLoadingButton(true);
        const body = {
          type: selectedData.type.id,
          name: state.name,
          date: moment(state.date).format("YYYY-MM-DD"),
          start_time: selectedData.start_time,
          end_time: selectedData.end_time,
          description: state.description,
          transportation_fee: selectedData.transportation_fee,
          parent_involvement: selectedData.parent_involvement,
          image: selectedData.image.id,
          status: "published"
        }

        var result = await directus.createItem('activities', body);

        if (!result.error) {
          //get all selected activities teachers and save into new data
          if (selectedData.teachers.length > 0) {
            let activity_teacher_data = [];
            //eslint-disable-next-line array-callback-return
            selectedData.teachers.map((item) => {
              activity_teacher_data.push({
                teachers_id: item.teachers_id,
                activities_id: result.data.id,
                coordinator: item.coordinator,
                registration_date: moment().format("YYYY-MM-DD"),
                status: "published"
              })
            })

            await directus.createItems('activities_teachers', activity_teacher_data);
          }

          //get all selected activities students and save into new data
          if (selectedData.students.length > 0) {
            let activity_student_data = [];
            //eslint-disable-next-line array-callback-return
            selectedData.students.map((item) => {
              activity_student_data.push({
                students_id: item.students_id,
                activities_id: result.data.id,
                registration_date: moment().format("YYYY-MM-DD"),
                status: "published"
              })
            })

            await directus.createItems('activities_students', activity_student_data);
          }

          //get all selected activities rooms and save into new data
          if (selectedData.rooms.length > 0) {
            let activity_room_data = [];
            //eslint-disable-next-line array-callback-return
            selectedData.rooms.map((item) => {
              activity_room_data.push({
                rooms_id: item.rooms_id,
                activities_id: result.data.id,
                status: "published"
              })
            })

            await directus.createItems('activities_rooms', activity_room_data);
          }
        }

        const body_activity = {
          action: "Add",
          category: 4,
          description: state.name,
          status: "published"
        }
        await directus.createItem('activity_log', body_activity);

        if (!result.error) {
          getData();
          dialogDuplicateActivity.current.visible = false;
          EventEmitter.emit('alert_toast', { content: Activity_created_successfully, type: "success" });
          return navigate('/activity/detail/' + result.data.id);
        }

      } else {
        if (!state.name) { setError({ ...errorRef.current, name: true }) }
        if (!state.date) { setError({ ...errorRef.current, date: true }) }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }


  // {/* ================== Handle Dialog Remove Activity Type ========================================== */ }

  const handleOpenDialogDeleteActivity = (props) => {
    dialogDeleteActivity.current.visible = true;
    setSelectedData(props);
  }

  const handleCloseDialogDeleteActivity = () => {
    dialogDeleteActivity.current.visible = false;
  }

  const deleteActivity = async () => {
    try {
      setLoadingButton(true);
      //get all selected activities teachers and delete
      var result = await directus.getItems('activities_teachers',
        {
          filter: { activities_id: selectedData.id }
        });
      var activities_teachers = result.data;

      if (activities_teachers.length > 0) {
        activities_teachers = linq.from(activities_teachers)
          .select(x => x.id)
          .toArray();

        await directus.deleteItems('activities_teachers', activities_teachers);
      }

      //get all selected activities students and delete
      var result_student = await directus.getItems('activities_students',
        {
          filter: { activities_id: selectedData.id }
        });

      var activities_students = result_student.data;

      if (activities_students.length > 0) {
        activities_students = linq.from(activities_students)
          .select(x => x.id)
          .toArray();
        await directus.deleteItems('activities_students', activities_students);
      }

      //get all selected activities rooms and delete
      var result_room = await directus.getItems('activities_rooms',
        {
          filter: { activities_id: selectedData.id }
        });

      var activities_rooms = result_room.data;
      if (activities_rooms.length > 0) {
        activities_rooms = linq.from(activities_rooms)
          .select(x => x.id)
          .toArray();

        await directus.deleteItems('activities_rooms', activities_rooms);
      }

      //delete activity data
      var result_activity = await directus.deleteItem('activities', selectedData.id);

      const body_activity = {
        action: "Delete",
        category: 4,
        description: selectedData.name,
        status: "published"
      }
      await directus.createItem('activity_log', body_activity);

      if (!result_activity.error) {
        getData();
        setDataButton("delete_activity");
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  const onColumnMenuOpen = (args) => {
    for (const item of args.items) {
      if (item.text === 'Autofit all columns' || item.text === 'Autofit this column') {
        item.hide = true;
      }
    }
  }

  // {/* ================== Footer Template ========================================== */}

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }

  // {/* ================== Main Content ========================================== */ }
  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      cancelFlag = false;
      grid.current.endEdit();
    }
  }

  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Activities}</div>
            <div className="content-searching hide-content">
              <div style={{ display: 'flex', width: "100%" }}>
                <div style={{ marginRight: "10px" }}>
                  <TextBoxComponent
                    name="name"
                    cssClass="e-outline searching-text"
                    className="searching-input"
                    id="searching-input"
                    placeholder={Enter_activity_name}
                    onChange={(e) => handleChangeFilter(e, "name")}
                    onBlur={(e) => searchActivity(e)}
                    showClearButton="true"
                    value={stateFiltering.name}
                  />
                </div>
                <div className="ddl_custom_header">
                  <DropDownListComponent
                    id="ddl_custom"
                    dataSource={dataActivityType2}
                    name="type_filter"
                    cssClass="e-outline"
                    placeholder={`--${Select_Type}--`}
                    fields={{ text: 'name', value: 'id' }}
                    select={(e) => handleFilter(e, "type")}
                    value={stateFiltering.type}
                    filtering={onFilteringActivityType}
                    allowFiltering={true}
                  />
                </div>
                {permission.AdminPermission() ?
                  <div style={{ marginLeft: "10px" }}>
                    <ButtonComponent className="btn-custom" iconCss='e-icons icon-plus' onClick={handleOpenDialogAddActivity}>
                      <span className="btn-text-custom">{Activity}</span>
                    </ButtonComponent>
                  </div>
                  : null}
              </div>
              {/* <br />
            {data ? 'Total : ' + data.length: ''} */}
            </div>
          </div>
          <div className="content-main" style={{ marginBottom: "30px" }}>
            {data ? data.length > 0 ?
              <div style={{ marginTop: "25px" }}>
                {/* <TooltipComponent ref={t => tooltip = t} target='.e-headercell' beforeRender={beforeRender} position="TopCenter"> */}
                <GridComponent
                  width="100%"
                  dataSource={data}
                  editSettings={editSettings}
                  allowSorting={true}
                  filterSettings={filterOptions}
                  allowFiltering={true}
                  ref={grid}
                  allowTextWrap={true}
                  textWrapSettings={{ wrapMode: "Content" }}
                  allowResizing={true}
                  gridLines='Vertical'
                  showColumnMenu={true}
                  columnMenuOpen={onColumnMenuOpen}
                  actionBegin={actionGrid}
                  selectionSettings={setting}
                  allowReordering={true}
                  clipMode="EllipsisWithTooltip"
                  keyPressed={onKeyPressed}
                >
                  <ColumnsDirective>
                    {/* <ColumnDirective customAttributes={{ id: 'first' }} headerText={Photo} textAlign='Center' editTemplate={imageEditTemplate} template={imageEditTemplate}></ColumnDirective> */}
                    <ColumnDirective customAttributes={{ id: 'first' }} width="22%" field='name' headerText={Name} template={nameTemplate}></ColumnDirective>
                    {/* <ColumnDirective width="12%" field='type' headerText={Type} editType='dropdownedit' editTemplate={typeEditTemplate} template={typeTemplate} validationRules={validationRule}></ColumnDirective> */}
                    <ColumnDirective width="12%" field='type.name' headerText={Type} editType="dropdownedit" edit={typeParams}></ColumnDirective>
                    <ColumnDirective width="12%" field='date' headerText={Date_2} editType='datepickeredit' template={dateTemplate} edit={{ params: { format: 'dd-MMM-yyyy' } }} ></ColumnDirective>
                    <ColumnDirective width="12%" field='start_time' headerText={Start_Time} template={startTimeTemplate} editTemplate={startTimeEditTemplate}></ColumnDirective>
                    <ColumnDirective width="12%" field='end_time' headerText={End_Time} template={endTimeTemplate} editTemplate={endTimeEditTemplate}></ColumnDirective>
                    <ColumnDirective width="10%" field='students.length' allowEditing={false} headerText={Student_Participants}></ColumnDirective>
                    <ColumnDirective width="10%" field='teachers.length' allowEditing={false} headerText={Teacher_Participants}></ColumnDirective>
                    {/* <ColumnDirective field='total_paid' headerText='Total Paid'></ColumnDirective> */}
                    <ColumnDirective clipMode="Ellipsis" width="10%" customAttributes={{ id: 'last' }} template={buttonTemplate} editTemplate={buttonEditTemplate} ></ColumnDirective>
                  </ColumnsDirective>
                  <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                </GridComponent>
                {/* </TooltipComponent> */}
              </div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>
            }
          </div>

          <DialogComponent
            id="dialogAddActivity"
            width="30%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogAddActivity}
            header={Activity}
            isModal={true}
            footerTemplate={() => footerTemplate([
              { name: Update, func: addActivity, icon: "icon-check-mark-01", status: "e-success" },
              { name: Cancel, func: handleCloseDialogAddActivity, icon: "icon-treeview", status: "" }
            ])}
            close={handleCloseDialogAddActivity}
          >
            <div>
              <b>*{Activity_Type}</b>
              <DropDownListComponent
                dataSource={dataActivityType}
                query={new Query()}
                name="type"
                cssClass="e-outline"
                placeholder={`--${Select_Type}--`}
                fields={{ text: 'name', value: 'id' }}
                select={(e) => handleChange(e, "type")}
                filtering={onFilteringActivityType}
                allowFiltering={true}
                value={stateRef.current.type}
              />
              {error.type === true ? <span style={{ color: 'red' }}>{Please_select_activity}</span> : ''}
              <br /><br />
              <b>*{Activity_Name}</b>
              <TextBoxComponent
                name="name"
                cssClass='e-outline'
                change={(e) => handleChange(e, "name")}
                value={stateRef.current.name}
              />
              {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
              <br /><br />
              <b>*{Date_2}</b>
              <DatePickerComponent
                name="date"
                cssClass="e-outline"
                format='dd-MMM-yyyy'
                change={(e) => handleChange(e, "date")}
                value={stateRef.current.date}
              />
              {error.date === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
              <br /><br />
              <b>*{Start_Time}</b>
              <TimePickerComponent
                name="start_time"
                cssClass="e-outline"
                step={30}
                format={'HH:mm'}
                onChange={(e) => handleChange(e, "start_time")}
                value={stateRef.current.start_time}
              />
              {error.start_time === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
              <br /><br />
              <b>*{End_Time}</b>
              <TimePickerComponent
                name="end_time"
                cssClass="e-outline"
                step={30}
                format={'HH:mm'}
                onChange={(e) => handleChange(e, "end_time")}
                value={stateRef.current.end_time}
              />
              {error.end_time === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
              <br /><br />
              <b>{Description}</b>
              <TextBoxComponent
                name="description"
                cssClass='e-outline'
                multiline={true}
                change={(e) => handleChange(e, "description")}
                value={stateRef.current.description}
              />
              <br /><br />
              <CheckBoxComponent
                checked={parseInt(stateRef.current.transportation_fee) === 1 ? true : false}
                label={Transportation_Fee}
                change={(e) => handleChange(e, "transportation_fee")}
              />
              <br /><br />
              <CheckBoxComponent
                checked={parseInt(stateRef.current.parent_involvement) === 1 ? true : false}
                label={Parent_involvement}
                change={(e) => handleChange(e, "parent_involvement")}
              />
            </div>
          </DialogComponent>

          <DialogComponent
            id="dialogDuplicateActivity"
            width="30%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogDuplicateActivity}
            header="Add"
            isModal={true}
            footerTemplate={() => footerTemplate([
              { name: Update, func: duplicateActivity, icon: "icon-check-mark-01", status: "e-success" },
              { name: Cancel, func: handleCloseDialogDuplicateActivity, icon: "icon-treeview", status: "" }
            ])}
            close={handleCloseDialogDuplicateActivity}
          >
            <div>
              {selectedData ?
                <div style={{ fontSize: "14px" }}>
                  <b>*{Activity_Type}</b>
                  <DropDownListComponent
                    dataSource={dataActivityType}
                    query={new Query()}
                    name="type"
                    id="type"
                    cssClass="e-outline"
                    placeholder={`--${Select_Type}--`}
                    fields={{ text: 'name', value: 'id' }}
                    select={(e) => handleChange(e, "type")}
                    filtering={onFilteringActivityType}
                    allowFiltering={true}
                    enabled={false}
                    value={stateRef.current.type}
                  />
                  <br /><br />
                  <b>*{Activity_Name}</b>
                  <TextBoxComponent
                    name="name"
                    cssClass='e-outline'
                    change={(e) => handleChange(e, "name")}
                    value={stateRef.current.name}
                  />
                  {error.name === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <b>*{Date_2}</b>
                  <DatePickerComponent
                    name="date"
                    cssClass="e-outline"
                    format='dd-MMM-yyyy'
                    change={(e) => handleChange(e, "date")}
                    value={stateRef.current.date ? new Date(stateRef.current.date) : null}
                  />
                  {error.date === true ? <span style={{ color: 'red' }}>{This_data_is_required}</span> : ''}
                  <br /><br />
                  <b>{Description}</b>
                  <TextBoxComponent
                    name="description"
                    cssClass='e-outline'
                    multiline={true}
                    change={(e) => handleChange(e, "description")}
                    value={stateRef.current.description}
                  />
                </div>
                : ''}
            </div>
          </DialogComponent>

          <DialogComponent
            id="dialogDeleteActivity"
            width="30%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogDeleteActivity}
            header={Delete_Confirmation}
            isModal={true}
            footerTemplate={() => footerTemplate([
              { name: Delete, func: deleteActivity, icon: "icon-delete-05", status: "e-warning" },
              { name: Cancel, func: handleCloseDialogDeleteActivity, icon: "icon-treeview", status: "" }
            ])}
            close={handleCloseDialogDeleteActivity}
          >
            <div>
              {selectedData ?
                <div style={{ fontSize: "14px" }}>
                  {Are_you_sure_you_want_to_delete_the_data_of_activity} ({selectedData.name})?
                </div>
                : ''}
            </div>
          </DialogComponent>
        </div>
      }
    </div>
  );
}

export default ActivityList;