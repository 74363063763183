import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, TextField, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { changeProject } from 'src/services/directus';
// import * as sessions from 'src/utils/sessions';
import jwt_decode from "jwt-decode";
// import ResetPassword from './ResetPassword';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ResetPassword = () => {
  const classes = useStyles();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [decoded, setDecoded] = useState(null);
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');
  // var decoded

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);

      if (token) {
        setDecoded(jwt_decode(token));
      } else { window.location.href = '/sign-in'; }
    }
  }, [isInitialized, isBusy]);
  /* eslint-enable react-hooks/exhaustive-deps*/

  return (
    <Page className={classes.root} title="Reset Password">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: '',
              passwordVer: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .max(255)
                .required('Password is required')
            })}
            onSubmit={async form => {
              setIsBusy(true);
              if (form.password !== form.passwordVer) {
                window.alert('Password is not the same');
                setIsBusy(false);
              }
              let directus = changeProject(decoded.project);
              await directus.resetPassword(form.password, token)
                .then(() => {
                  window.localStorage.clear();
                  window.location.href = '/sign-in';
                })
                .catch(err => {
                  window.localStorage.clear();
                  window.alert(err.message);
                })
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Reset the Password
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password Verification"
                  margin="normal"
                  name="passwordVer"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.passwordVer}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button color="secondary" disabled={isBusy} fullWidth size="large" type="submit" variant="contained">
                    Reset Password
                  </Button>
                </Box>
                <Button color="primary" onClick={() => window.location.href = '/sign-in'}>
                  I remember now!
                </Button>
                {/* <ResetPassword/> */}
                {/* <Typography color="textSecondary" variant="body1">
                  Don&apos;t have an account?{' '}
                  <Link component={RouterLink} to="/register" variant="h6">
                    Sign up
                  </Link>
                </Typography> */}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default ResetPassword;
