import React, { useState, useEffect, useRef } from 'react';
import useStateRef from "react-usestateref";

import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { GridComponent, ColumnsDirective, ColumnDirective, ColumnMenu, Edit, Toolbar, Page, Inject, Sort, Filter, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';
import i18next from "i18next";
import linq from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';

const ActivityLog = () => {
  /* eslint-disable no-unused-vars*/
  var searchTimeout = null;

  var numOfPage = JSON.parse(window.localStorage.getItem('number_of_page'));
  var numActivity = numOfPage ? numOfPage.activity_log ? numOfPage.activity_log : 15 : 15;
  if (!numOfPage) {
    window.localStorage.setItem('number_of_page', JSON.stringify({ activity_log: 15 }));
  } else {
    if (!numOfPage.activity_log) {
      numOfPage.activity_log = 15;
      window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
    }
  }

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Items_per_page] = useState(i18next.t('Pages.general.Items_per_page'));
  const [of] = useState(i18next.t('Pages.general.of'));
  const [pages] = useState(i18next.t('Pages.general.pages'));

  const [Activity_Log] = useState(i18next.t('Pages.activity_log_data.Activity_Log'));
  const [Category] = useState(i18next.t('Pages.activity_log_data.Category'));
  const [Description] = useState(i18next.t('Pages.activity_log_data.Description'));
  const [User] = useState(i18next.t('Pages.activity_log_data.User'));
  const [Date_2] = useState(i18next.t('Pages.activity_log_data.Date'));
  const [Action] = useState(i18next.t('Pages.activity_log_data.Action'));

  const [Activity] = useState(i18next.t('Pages.activity_log_data.Activity'));
  const [Activity_Type] = useState(i18next.t('Pages.activity_log_data.Activity_Type'));
  const [Class] = useState(i18next.t('Pages.activity_log_data.Class'));
  const [Parent_Guardian] = useState(i18next.t('Pages.activity_log_data.Parent_Guardian'));
  const [Room] = useState(i18next.t('Pages.activity_log_data.Room'));
  const [Student] = useState(i18next.t('Pages.activity_log_data.Student'));
  const [Subject] = useState(i18next.t('Pages.activity_log_data.Subject'));
  const [Subject_Group] = useState(i18next.t('Pages.activity_log_data.Subject_Group'));
  const [Teacher] = useState(i18next.t('Pages.activity_log_data.Teacher'));
  const [Teacher_Training] = useState(i18next.t('Pages.activity_log_data.Teacher_Training'));
  const [User_Profile] = useState(i18next.t('Pages.activity_log_data.User_Profile'));
  const [Staff] = useState(i18next.t('Pages.activity_log_data.Staff'));
  const [All_Categories] = useState(i18next.t('Pages.activity_log_data.All_Categories'));
  const [All_Actions] = useState(i18next.t('Pages.activity_log_data.All_Actions'));
  const [Enter_user_email_or_description] = useState(i18next.t('Pages.activity_log_data.Enter_user_email_or_description'));
  const [Add] = useState(i18next.t('Pages.activity_log_data.Add'));
  const [Update] = useState(i18next.t('Pages.activity_log_data.Update'));
  const [Delete] = useState(i18next.t('Pages.activity_log_data.Delete'));

  //data
  const [data, setData] = useState(null);
  const [dataMeta, setDataMeta] = useState(null);
  let [numberOfPage, setNumberOfPage, numberOfPageRef] = useStateRef(numActivity);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);

  //grid setting
  const grid = useRef();
  const setting = { mode: 'Both' };

  //other component setting
  const dataAction = [{ id: "All_Actions", name: All_Actions }, { id: "Add", name: Add }, { id: "Update", name: Update }, { id: "Delete", name: Delete }]
  const dataCategory = linq.from([
    { id: 0, name: All_Categories },
    { id: 4, name: Activity },
    { id: 13, name: Activity_Type },
    { id: 8, name: Class },
    { id: 1, name: "Login" },
    { id: 10, name: Parent_Guardian },
    { id: 9, name: Room },
    { id: 2, name: Student },
    { id: 7, name: Subject },
    { id: 12, name: Subject_Group },
    { id: 5, name: Teacher },
    { id: 6, name: Teacher_Training },
    { id: 11, name: User_Profile },
    { id: 14, name: Staff },
  ]).orderBy(item => item.name).toArray();

  //set state filtering
  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    created_on: new Date(),
    category: 0,
    description: null,
    action: "All_Actions",
    page: 1
  });
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getData();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  // {/* ================== Get All Data ========================================== */ }
  //get list of activity logs
  const getData = async () => {
    try {
      var range = [moment(stateFilteringRef.current.created_on).format("YYYY-MM-DD"), moment(stateFilteringRef.current.created_on).add(1, 'days').format("YYYY-MM-DD")];

      var category = { "nnull": null };
      var description = { "nnull": null };
      var action = { "nnull": null };
      //eslint-disable-next-line no-unused-vars
      var user = { "nnull": null };

      if (stateFilteringRef.current.category !== 0) { category = { "in": stateFilteringRef.current.category }; }

      if (stateFilteringRef.current.description !== null && stateFilteringRef.current.description !== "") {
        description = { "contains": stateFilteringRef.current.description };
        user = { "logical": "or", "contains": stateFilteringRef.current.description };
      }

      if (stateFilteringRef.current.action !== "All_Actions") { action = { "in": stateFilteringRef.current.action }; }

      var result = await directus.getItems('activity_log',
        {
          fields: "*.*",
          filter: {
            description: description,
            action: action,
            category: category,
            status: "published",
            created_on: {
              'between': range
            },
          },
          sort: "-created_on",
          limit: numberOfPageRef.current,
          meta: "*",
          page: stateFilteringRef.current.page ? stateFilteringRef.current.page : 1
        });

      setData(result.data);
      setDataMeta(result.meta);
      setLoading(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }
  // {/* ================== Filtering ========================================== */ }

  const handleFilter = async (e, type) => {
    var value = "";

    if (type === "category" || type === "action") {
      value = e.itemData.id;
    } else {
      value = e.value;
    }

    setStateFiltering({ ...stateFiltering, [type]: value, page: 1 })
    if (type === "description") {
      if (e.syntheticEvent.type === 'change') {
        getData();
      }
    } else {
      getData();
    }

  }

  const searchDescription = () => { getData(); }

  const changeNumOfData = (e) => {
    var value = e.value ? e.value : 1;
    setNumberOfPage(value);
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      numOfPage.activity_log = value;
      window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
      getData();
    }, 1000)
  }

  // {/* ================== Pagination ========================================== */ }
  const pageTemplate = () => {
    var list = [];
    if (dataMeta) {
      for (var i = 0; i < dataMeta.page_count; i++) {
        const page = i + 1;
        var class_name = "e-link e-numericitem e-spacing e-pager-default";
        if (dataMeta.page === page) {
          class_name = "e-link e-numericitem e-spacing e-currentitem e-active"
        }
        var display = "none";
        if (page <= 5) { display = ""; }
        list.push(<div key={"page-" + page}><span className={class_name} id={"page-" + page} role="link" tabIndex="-1" aria-label={'Goto Page ' + page} onClick={() => clickPage(page, "num")} name={'Goto Page ' + page} aria-owns="grid_940258773_0_content_table" style={{ display: display }} >{page}</span></div>)
      }
    }

    //check page 
    var check_page = [];
    if (dataMeta.page_count <= 5) {
      for (var j = 0; j < dataMeta.page_count; j++) { check_page.push(j + 1) }
    } else {
      if (dataMeta.page <= 3) {
        for (var k = 0; k < 5; k++) { check_page.push(k + 1) }
      } else if (dataMeta.page >= dataMeta.page_count - 2) {
        for (var l = dataMeta.page_count; l > dataMeta.page_count - 5; l--) { check_page.push(l) }
      } else {
        var list_page = [dataMeta.page - 2, dataMeta.page - 1, dataMeta.page, dataMeta.page + 1, dataMeta.page + 2];
        list_page.forEach((value) => {
          check_page.push(value)
        })
      }
    }

    var select_page2 = document.querySelectorAll('.e-numericitem');
    if (select_page2.length > 0) {
      if (select_page2.length > 0) {
        for (var index = 0; index < select_page2.length; index++) {
          select_page2[index].style.display = "none";
        }
      }

      if (check_page.length > 0) {
        //eslint-disable-next-line array-callback-return
        check_page.map((value) => {
          var select_page = document.querySelectorAll(`[id=page-${value}]`);
          if (select_page.length > 0) {
            for (var index2 = 0; index2 < select_page.length; index2++) {
              select_page[index2].style.display = "";
            }
          }
        })
      }
    }

    return (
      <div style={{ height: "35px", textAlign: "center" }}>
        <div className="e-gridpager e-control e-pager e-lib" style={{ display: "flex", width: "100%" }} >
          <div style={{ width: "32.5%", background: "#E1E7EB", borderRadius: "17.5px 5px 5px 17.5px" }}>
            <div className="e-pagercontainer" role="navigation" >
              <div className={dataMeta.page === 1 ? "e-first e-icons e-icon-first e-firstpagedisabled e-disable" : "e-first e-icons e-icon-first e-firstpage e-pager-default"} onClick={() => clickPage(1, "first")} title="Go to first page" aria-label="Go to first page" tabIndex="-1" index="1" aria-owns="grid_940258773_0_content_table"></div>
              <div className={dataMeta.page === 1 ? "e-prev e-icons e-icon-prev e-prevpagedisabled e-disable" : "e-prev e-icons e-icon-prev e-prevpage e-pager-default"} onClick={() => clickPage(dataMeta.page - 1, "prev")} title="Go to previous page" aria-label="Go to previous page" tabIndex="-1" index="0" aria-owns="grid_940258773_0_content_table"></div>
              <div className="e-numericcontainer">{list}</div>
              <div className={dataMeta.page === dataMeta.page_count ? "e-next e-icons e-icon-next e-nextpagedisabled e-disable" : "e-next e-icons e-icon-next e-nextpage e-pager-default"} onClick={() => clickPage(dataMeta.page + 1, "next")} title="Go to next page" aria-label="Go to next page" tabIndex="-1" index="2" aria-owns="grid_940258773_0_content_table"></div>
              <div className={dataMeta.page === dataMeta.page_count ? "e-last e-icons e-icon-last e-lastpagedisabled e-disable" : "e-last e-icons e-icon-last e-lastpage e-pager-default"} onClick={() => clickPage(dataMeta.page_count, "last")} title="Go to last page" aria-label="Go to last page" tabIndex="-1" index="2" aria-owns="grid_940258773_0_content_table"></div>
            </div>
          </div>
          <div style={{ width: "35%", background: "#E1E7EB", marginLeft: "2px", marginRight: "2px", borderRadius: "5px" }}>
            <div className="e-pagerconstant">
              <span className="e-constant" style={{ display: "flex" }}>
                <span style={{ marginRight: "6px" }}>
                  <span style={{ display: "flex", flexDirection: "column", position: "absolute", marginTop: "7px" }}>
                    <span className="e-icons icon-arrowhead-up"
                      style={{ cursor: "pointer", color: '#516573', fontSize: "10px" }}
                      onClick={() => handleNumberOfPage("+")}
                    />
                    <span className="e-icons icon-arrowhead-down"
                      style={{ cursor: "pointer", color: '#516573', fontSize: "10px" }}
                      onClick={() => handleNumberOfPage("-")}
                    />
                  </span>
                </span>
                <span className="nt_custom" style={{ fontSize: "17px", fontWeight: "800", color: "#516573", width: "35px", marginRight: "6px", marginLeft: "6px" }}>
                  <NumericTextBoxComponent style={{ textAlign: "center", padding: "0px" }} step={5} format='####' value={numberOfPageRef.current} showSpinButton={false} max={999} min={1} onChange={(e) => changeNumOfData(e)} />
                </span>
                <span style={{ color: "#516573" }}>
                  {Items_per_page} (total {dataMeta ? dataMeta.filter_count : 0} records)
                </span>
              </span>
            </div>
          </div>
          <div className="e-parentmsgbar" style={{ width: "32.5%", background: "#E1E7EB", borderRadius: "5px 17.5px 17.5px 5px" }}>
            <span className="e-pagenomsg">{dataMeta ? <span>{dataMeta.page} {of} {dataMeta.page_count} {pages} </span> : null}</span>
          </div>
        </div>
      </div>
    )
  }

  const handleNumberOfPage = (props) => {
    if (numberOfPageRef.current - 1 > 0) {
      var value = numberOfPageRef.current;
      if (props === "+") {
        value = numberOfPageRef.current + 1;
      } else {
        value = numberOfPageRef.current - 1;
      }
      setNumberOfPage(value);
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        numOfPage.activity_log = value;
        window.localStorage.setItem('number_of_page', JSON.stringify(numOfPage));
        getData();
      }, 1000)
    }
  }

  const clickPage = (page, type) => {
    var status = false;
    if (type === "num") {
      if (dataMeta.page !== page) { status = true; }
    } else if (type === "first" || type === "prev") {
      if (dataMeta.page !== 1) { status = true; }
    } else if (type === "next" || type === "last") {
      if (dataMeta.page !== dataMeta.page_count) { status = true; }
    }

    if (status) {
      setStateFiltering({ ...stateFilteringRef.current, page: page })
      getData();
    }
  }


  // {/* ================== Data Grid Template ========================================== */}
  const categoryTemplate = props => {
    return (<div>{getTranslation(props.category.id, dataCategory)}</div>)
  }

  const actionTemplate = props => {
    return (<div>{getTranslation(props.action, dataAction)}</div>)
  }


  const getTranslation = (props, name) => {
    var oldData = props ? props : null;

    if (name) {
      name.forEach((value) => {
        if (value.id === oldData) {
          oldData = value.name;
        }
      })
    }

    return "" + oldData
  }

  // {/* ================== Main Content ========================================== */}
  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Activity_Log}</div>
          </div>
          <div className="content-main">
            <div style={{ marginTop: "30px", display: 'flex', width: "100%" }}>
              <div style={{ paddingRight: "20px", width: "28%" }}>
                <TextBoxComponent
                  name="description"
                  cssClass="e-outline searching-text"
                  className="searching-input"
                  id="searching-input"
                  placeholder={Enter_user_email_or_description}
                  onChange={(e) => handleFilter(e, "description")}
                  onBlur={(e) => searchDescription(e)}
                  showClearButton="true"
                />
              </div>
              <div style={{ paddingRight: "20px", width: "24%" }}>
                <DatePickerComponent
                  showClearButton={false}
                  id="ddl_custom"
                  cssClass="e-outline"
                  name="created_on"
                  format='dd-MMM-yyyy'
                  value={stateFilteringRef.current.created_on}
                  onChange={(e) => handleFilter(e, "created_on")}
                />
              </div>
              <div style={{ paddingRight: "20px", width: "24%" }}>
                <DropDownListComponent
                  id="ddl_custom"
                  dataSource={dataCategory}
                  name="category"
                  cssClass="e-outline"
                  placeholder={All_Categories}
                  fields={{ text: 'name', value: 'id' }}
                  select={(e) => handleFilter(e, "category")}
                  value={stateFilteringRef.current.category}
                  allowFiltering={true}
                />
              </div>
              <div style={{ paddingRight: "20px", width: "24%" }}>
                <DropDownListComponent
                  id="ddl_custom"
                  dataSource={dataAction}
                  name="action"
                  cssClass="e-outline"
                  fields={{ text: 'name', value: 'id' }}
                  placeholder={All_Actions}
                  select={(e) => handleFilter(e, "action")}
                  value={stateFilteringRef.current.action}
                />
              </div>

            </div>
            {/* <br />
            {dataMeta ? dataMeta.filter_count > 0 ? 'Total : ' + dataMeta.filter_count : <br /> : ''}
            {dataMeta ? dataMeta.filter_count > 15 ? pageTemplate() : null : null} */}
            {data ? data.length > 0 ?
              <div style={{ marginTop: "25px", marginBottom: "20px" }}>
                <div className="grid-container-2">
                  <GridComponent
                    width="100%"
                    className="grid-list"
                    dataSource={data}
                    allowEditing={false}
                    ref={grid}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: "Content" }}
                    allowResizing={true}
                    gridLines='Both'
                    selectionSettings={setting}
                    clipMode="EllipsisWithTooltip"
                  >
                    <ColumnsDirective>
                      <ColumnDirective customAttributes={{ id: 'first' }} width="17%" field='owner.email' headerText={User}></ColumnDirective>
                      <ColumnDirective width="12%" field='created_on' headerText={Date_2} type="dateTime" format="dd-MMM-yyyy HH:mm"></ColumnDirective>
                      <ColumnDirective width="15%" field='category.name' headerText={Category} template={categoryTemplate}></ColumnDirective>
                      <ColumnDirective width="12%" field='action' headerText={Action} template={actionTemplate}></ColumnDirective>
                      <ColumnDirective customAttributes={{ id: 'last' }} field='description' headerText={Description}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Toolbar, Edit, Sort, Filter, ColumnMenu, Reorder, Resize]} />
                  </GridComponent>
                </div>
              </div>
              : <div style={{ textAlign: "center", marginTop: "30px" }}>{No_data_found}</div>
              : <div style={{ textAlign: "center", marginTop: "30px" }}>{No_data_found}</div>
            }

          </div>
          <div className="content-pagination">
            <div className="content-pagination-inside">
              {dataMeta ? dataMeta.filter_count > 0 ? pageTemplate() : null : null}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default ActivityLog;