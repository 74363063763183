import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { GridComponent, ColumnsDirective, ColumnDirective, Edit, Toolbar, Page, Inject, Sort, Resize } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent, TooltipComponent } from '@syncfusion/ej2-react-popups';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Query } from '@syncfusion/ej2-data';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as permission from 'src/utils/Permission';

const Attendance = () => {
  /* eslint-disable no-unused-vars*/
  // const navigate = useNavigate();

  const [Update] = useState(i18next.t('Pages.button.Update'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));
  const [Search] = useState(i18next.t('Pages.button.Search'));

  const [No_data_found] = useState(i18next.t('Pages.general.No_data_found'));
  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));
  const [Data_updated_successfully] = useState(i18next.t('Pages.general.Data_updated_successfully'));

  const [Student] = useState(i18next.t('Pages.data.Student'));
  const [All_Teachers] = useState(i18next.t('Pages.data.All_Teachers'));
  const [All_Classes] = useState(i18next.t('Pages.data.All_Classes'));
  const [Sunday] = useState(i18next.t('Pages.data.Sunday'));
  const [Monday] = useState(i18next.t('Pages.data.Monday'));
  const [Tuesday] = useState(i18next.t('Pages.data.Tuesday'));
  const [Wednesday] = useState(i18next.t('Pages.data.Wednesday'));
  const [Thursday] = useState(i18next.t('Pages.data.Thursday'));
  const [Friday] = useState(i18next.t('Pages.data.Friday'));
  const [Saturday] = useState(i18next.t('Pages.data.Saturday'));
  const [Select_Teacher] = useState(i18next.t('Pages.data.Select_Teacher'));

  const [Date_Time] = useState(i18next.t('Pages.schedule_data.Date_Time'));
  const [Teacher_Attendance] = useState(i18next.t('Pages.schedule_data.Teacher_Attendance'));
  const [Teacher] = useState(i18next.t('Pages.schedule_data.Teacher'));
  const [Student_Attendance] = useState(i18next.t('Pages.schedule_data.Student_Attendance'));
  const [Start] = useState(i18next.t('Pages.schedule_data.Start'));
  const [End] = useState(i18next.t('Pages.schedule_data.End'));
  const [Attendances] = useState(i18next.t('Pages.schedule_data.Attendances'));
  const [Lesson_schedule_changed] = useState(i18next.t('Pages.schedule_data.Lesson_schedule_changed'));
  const [Beginning] = useState(i18next.t('Pages.schedule_data.Beginning'));
  const [Beginning_Teacher] = useState(i18next.t('Pages.schedule_data.Beginning_Teacher'));
  const [Unfilled] = useState(i18next.t('Pages.schedule_data.Unfilled'));
  const [All_Present] = useState(i18next.t('Pages.schedule_data.All_Present'));
  const [Change_Schedule] = useState(i18next.t('Pages.schedule_data.Change_Schedule'));
  const [Change_Teacher] = useState(i18next.t('Pages.schedule_data.Change_Teacher'));
  const [Reason_for_Changing_the_Teacher] = useState(i18next.t('Pages.schedule_data.Reason_for_Changing_the_Teacher'));
  const [Initial_Date] = useState(i18next.t('Pages.schedule_data.Initial_Date'));
  const [Initial_Time] = useState(i18next.t('Pages.schedule_data.Initial_Time'));
  const [Reason_for_Changing_the_Schedule] = useState(i18next.t('Pages.schedule_data.Reason_for_Changing_the_Schedule'));
  const [Edit_Schedule] = useState(i18next.t('Pages.subject_class_data.Edit_Schedule'));

  const [Date_2] = useState(i18next.t('Pages.schedule_data.Date'));
  const [Time] = useState(i18next.t('Pages.schedule_data.Time'));
  const [Present] = useState(i18next.t('Pages.schedule_data.Present'));
  const [Excused] = useState(i18next.t('Pages.schedule_data.Excused'));
  const [Personal_Leave] = useState(i18next.t('Pages.schedule_data.Personal_Leave'));
  const [Absent] = useState(i18next.t('Pages.schedule_data.Absent'));

  const [Early_Teacher] = useState(i18next.t('Pages.subject_class_data.Early_Teacher'));

  //data
  const [data, setData, dataRef] = useStateRef(null);
  const teacherStatusData = [{ id: "---", name: "---" }, { id: "Present", name: Present }, { id: "Absent", name: Absent }];
  const studentStatusData = [{ id: "---", name: "---" }, { id: "Present", name: Present }, { id: "Excused", name: Excused }, { id: "Personal Leave", name: Personal_Leave }, { id: "Absent", name: Absent }];
  const [stateTeacher, setStateTeacher, stateTeacherRef] = useStateRef(null);
  const [dataTeacher, setDataTeacher] = useState(null);
  const [dataTeacher2, setDataTeacher2] = useState(null);
  const [dataSubject, setDataSubject] = useState(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(null);

  //dialog
  let dialogChangeTeacher = useRef(false);
  let dialogChangeSchedule = useRef(false);

  //set data filter
  const [dataFilter, setDataFilter, dataFilterRef] = useStateRef({
    start_date: moment().subtract(2, 'days').format(),
    end_date: moment().format(),
    teacher: 0,
    subject_class: 0,
    ck_filled: true
  })

  //set state schedule change
  const [stateScheduleChange, setStateScheduleChange, stateScheduleChangeRef] = useStateRef(null);

  //grid component setting
  const grid = useRef();
  const weeks = [{ id: "Sunday", name: Sunday }, { id: "Monday", name: Monday }, { id: "Tuesday", name: Tuesday }, { id: "Wednesday", name: Wednesday }, { id: "Thursday", name: Thursday }, { id: "Friday", name: Friday }, { id: "Saturday", name: Saturday }]
  const user_setting = JSON.parse(localStorage.user_setting);
  const [loadingSearching, setLoadingSearching, loadingSearchingRef] = useStateRef(false)

  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(async () => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getTeacher();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "change_schedule") {
        dialogChangeSchedule.current.visible = false;
        setStateScheduleChange(null);
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }

      if (dataButtonRef.current === "change_teacher") {
        dialogChangeTeacher.current.visible = false;
        setStateTeacher(null);
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        setDataButton(null);
      }
    }
  })

  // {/* ================== Get All Data ========================================== */ }
  //get all teachers data
  const getTeacher = async () => {
    try {
      var result = await directus.getItems('teachers', { fields: "id, name, nickname", limit: -1 });
      var newData = result.data;
      setDataTeacher(newData);

      if (result.data.length > 0) {
        var oldData = [{ id: 0, nickname: All_Teachers }];
        newData = oldData.concat(newData);
      }

      setDataTeacher2(newData);
      getSubject();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }

  }

  //get all subject data
  const getSubject = async () => {
    try {
      var result = await directus.getItems('subjects', { fields: "id, name", limit: -1 });
      var newData = result.data;

      if (result.data.length > 0) {
        var oldData = [{ id: 0, name: All_Classes }];
        newData = oldData.concat(newData);
      }

      setDataSubject(newData);
      getSchedule();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  /* eslint-disable array-callback-return*/
  //get all schedule data
  const getSchedule = async () => {
    try {
      var teacher = { "nnull": null };
      var subject_class = { "nnull": null };

      //filter class
      if (dataFilterRef.current.teacher !== 0) {
        teacher = { "in": dataFilterRef.current.teacher };
      }

      //filter subject class
      if (dataFilterRef.current.subject_class !== 0) {
        subject_class = { "in": dataFilterRef.current.subject_class };
      }

      //get all attendance data with specific date range
      var range = [moment(dataFilterRef.current.start_date).format("YYYY-MM-DD"), moment(dataFilterRef.current.end_date).format("YYYY-MM-DD")];

      var result_attendance = await directus.getItems('attendances', {
        fields: '*,schedules_id.*,schedules_id.subject_classes_id.*,schedules_id.teachers_id.*,schedules_id.attendances.*.*,students_subject_classes_id.*.*',
        filter: {
          'schedules_id.date': {
            'between': range
          },
          'schedules_id.teachers_id.id': permission.AdminPermission() ? teacher : permission.TeacherPermission ? user_setting.data_id : null,
          'schedules_id.subject_classes_id.subjects.id': subject_class
        },
        limit: -1
      });

      var dataAttendances = [];
      var data = result_attendance.data;

      //group all attendance data based on subject class
      data = linq.from(data)
        .groupBy(x => x.students_subject_classes_id.subject_classes_id.id)
        .toArray();
      //and then group by date
      data.map((value) => {
        var data2 = linq.from(value)
          .groupBy(x => x.schedules_id.date)
          .toArray();

        data2.map((value) => {
          dataAttendances.push(value.getSource());
        })
      })

      //save the data attendance and order by time and date
      dataAttendances = linq.from(dataAttendances)
        .select(data => ({
          date: data[0].schedules_id.date,
          time: moment(data[0].schedules_id.time, "HH:mm:ss").format('HH:mm'),
          previous_date: data[0].schedules_id.previous_date ? data[0].schedules_id.previous_date : null,
          previous_time: data[0].schedules_id.previous_time ? moment(data[0].schedules_id.previous_time, "HH:mm:ss").format('HH:mm') : null,
          subject_class: data[0].schedules_id.subject_classes_id,
          teacher: data[0].schedules_id.teachers_id,
          previous_teacher: data[0].schedules_id.previous_teacher,
          reason: data[0].schedules_id.reason,
          teacher_reason: data[0].schedules_id.teacher_reason,
          data: data
        }))
        .orderBy(x => x.data[0].schedules_id.time)
        .orderBy(x => x.data[0].schedules_id.date)
        .toArray();

      var dataAttendances_filled = [];
      var dataAttendances_notfilled = [];

      //check if the data attendance is filled or not
      const promises = dataAttendances.map((value) => {
        const student_data = value.data;
        if (dataFilterRef.current.ck_filled === true) {
          //check if student and teacher attendance is empty (null or ---)
          const check = obj => obj.student_attd === "---";
          const check2 = obj => obj.student_attd === null;

          //if there is attendance data that has not been filled in, then save the data into notfilled data
          if (student_data.some(check) || student_data.some(check2) || value.data[0].schedules_id.teacher_attd === "---" || value.data[0].schedules_id.teacher_attd === null) {
            dataAttendances_notfilled.push(value);
          }
        } else if (dataFilterRef.current.ck_filled === false) {
          const check = obj => obj.student_attd === "---";
          const check2 = obj => obj.student_attd === null;

          //if there is attendance data that has not been filled in, then save the data in the data that has not been filled in
          if (student_data.some(check) || student_data.some(check2) || value.data[0].schedules_id.teacher_attd === "---" || value.data[0].schedules_id.teacher_attd === null) {
            dataAttendances_notfilled.push(value);
            //then the rest save in the data that has been filled in
          } else {
            dataAttendances_filled.push(value);
          }
        }

      })
      await Promise.all(promises)

      //sort the data from filled data and then notfilled data
      var dataAttendances_filter = dataAttendances_filled.concat(dataAttendances_notfilled);
      setData(dataAttendances_filter);
      setLoading(false);
      setLoadingButton(false);
      setLoadingSearching(false);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Filtering ========================================== */ }

  //search teacher by name
  const onFilteringTeacher = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataTeacher, query);
  };

  //search subject class by name
  const onFilteringClass = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataSubject, query);
  };

  const handleFilter = async (e, type) => {
    var value = "";
    if (type === "teacher" || type === "subject_class") {
      value = e.itemData.id;
    } else if (type === "start_date" || type === "end_date") {
      value = e.value;
    }

    if (type === "ck_filled") {
      value = e.checked;
    }

    setDataFilter({ ...dataFilterRef.current, [type]: value });
    // getSchedule();
  }

  const handleClickSearch = () => {
    setLoadingSearching(true);
    getSchedule();
  }


  // {/* ================== Handle Change Schedule ========================================== */ }

  const handleOpenDialogChangeSchedule = (data) => {
    dialogChangeSchedule.current.visible = true;
    setStateScheduleChange(data);
  }

  const handleCloseDialogChangeSchedule = () => {
    dialogChangeSchedule.current.visible = false;
    setStateScheduleChange(null);
  }

  const handleChangeSchedule = (event, type) => {
    var value = "";
    if (type === "date") {
      value = moment(event.value).format("YYYY-MM-DD");
    } else if (type === "time") {
      value = moment(event.value).format('HH:mm') + ":00";
    } else {
      value = event.value;
    }
    setStateScheduleChange({ ...stateScheduleChange, [type]: value });
  }

  const changeSchedule = async () => {
    try {
      setLoadingButton(true);
      const id = stateScheduleChange.data[0].schedules_id.id;

      if (stateScheduleChange.time_change) {
        stateScheduleChange.time_change = moment(stateScheduleChange.time_change).format("HH:mm:ss");
      }
      if (stateScheduleChange.date_change) {
        stateScheduleChange.date_change = moment(new Date(stateScheduleChange.date_change)).format("YYYY-MM-DD");
      }

      const body = {
        date: stateScheduleChange.date_change ? stateScheduleChange.date_change : stateScheduleChange.data[0].schedules_id.date,
        time: stateScheduleChange.time_change ? stateScheduleChange.time_change : stateScheduleChange.data[0].schedules_id.time,
        previous_date: stateScheduleChange.data[0].schedules_id.previous_date ? stateScheduleChange.data[0].schedules_id.previous_date : stateScheduleChange.data[0].schedules_id.date,
        previous_time: stateScheduleChange.data[0].schedules_id.previous_time ? stateScheduleChange.data[0].schedules_id.previous_time : stateScheduleChange.data[0].schedules_id.time,
        reason: stateScheduleChange.reason
      }

      var result = await directus.updateItem('schedules', id, body);

      if (!result.error) {
        getSchedule();
        setDataButton("change_schedule");
      }

      dialogChangeSchedule.current.visible = false;
      setStateScheduleChange(null);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }

  // {/* ================== Handle Change Teacher ========================================== */ }
  const handleOpenDialogChangeTeacher = async (item) => {
    const id = item.data[0].schedules_id.id;

    const body = {
      id: id,
      teachers_id: item.teacher.id,
      previous_teacher: item.previous_teacher ? item.previous_teacher.id : null,
      teacher_reason: item.teacher_reason
    }

    setStateTeacher(body);
    dialogChangeTeacher.current.visible = true;
  }

  const handleCloseDialogChangeTeacher = () => {
    dialogChangeTeacher.current.visible = false;
    setStateTeacher(null);
  }

  const handleChangeTeacher = (e, type) => {
    var value = null;
    if (type === "teacher_change") {
      value = e.itemData.id
    } else {
      value = e.value;
    }

    setStateTeacher({ ...stateTeacher, [type]: value })
  }

  const changeTeacher = async () => {
    try {
      setLoadingButton(true);
      var newTeacher = stateTeacher.teachers_id;
      var oldTeacher = null;
      if (stateTeacher.teacher_change) {
        newTeacher = stateTeacher.teacher_change;
        oldTeacher = stateTeacher.teachers_id;
      }

      const body = {
        id: stateTeacher.id,
        teachers_id: newTeacher,
        previous_teacher: stateTeacher.previous_teacher ? stateTeacher.previous_teacher : oldTeacher,
        teacher_reason: stateTeacher.teacher_reason
      }
      var result = await directus.updateItem('schedules', body.id, body);

      if (!result.error) {
        getSchedule();
        setDataButton("change_teacher");
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
      setLoadingButton(false);
    }
  }


  // {/* ================== Handle Teacher and Student Attendance ========================================== */ }
  const teacherAttdChange = async (e, item, is_present) => {
    try {
      const id = item.data[0].schedules_id.id;
      var result = await directus.updateItem('schedules', id, { teacher_attd: is_present ? "Present" : e.itemData.id });
      if (!result.error) {
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        getSchedule();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const studentAttdChange = async (e, item) => {
    try {
      var teacher_attd = item.schedules_id.teacher_attd;
      const id = item.id;
      var result = await directus.updateItem('attendances', id, { student_attd: e.itemData.id });
      if (!result.error) {
        if (teacher_attd !== "Present" && e.itemData.id === "Present") {
          await directus.updateItem('schedules', item.schedules_id.id, { teacher_attd: "Present" });
        }
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        getSchedule();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const studentPresent = async (item) => {
    try {
      var teacher_attd = item.schedules_id.teacher_attd;
      var result = await directus.updateItem('attendances', item.id, { student_attd: "Present" });
      if (!result.error) {
        if (teacher_attd !== "Present") {
          await directus.updateItem('schedules', item.schedules_id.id, { teacher_attd: "Present" });
        }
        EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
        getSchedule();
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const studentAllPresent = async (e, item) => {
    try {
      if (e.checked) {
        var listStudent = linq.from(item)
          .select(data => ({ id: data.id, student_attd: "Present" }))
          .toArray();

        var result = await directus.updateItems('attendances', listStudent);
        if (!result.error) {
          EventEmitter.emit('alert_toast', { content: Data_updated_successfully, type: "success" });
          getSchedule();
        }
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Navigation ========================================== */ }
  // function btnClickStudent(props) {
  //   const id = props.students_subject_classes_id.students_id.id;
  //   return navigate('/student/profile/' + id);
  // }

  // {/* ================== Data Grid Template ========================================== */ }
  const dateTimeTemplate = (props) => {
    return (
      <div>
        <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
          <div>
            {props.previous_date || props.previous_time ?
              <TooltipComponent
                content={
                  Lesson_schedule_changed + ', ' + Beginning + ': ' + (props.previous_date === null || props.previous_date === "" ?
                    (getWeek(moment(props.date).format("dddd")) + ", " + moment(props.date).format("DD-MMM-YYYY"))
                    :
                    (getWeek(moment(props.previous_date).format("dddd")) + ", " + moment(props.previous_date).format("DD-MMM-YYYY"))
                  )
                  + ' ' + (props.previous_time ? props.previous_time : props.time)} opensOn='Hover' style={{ fontSize: "inherit" }
                  }
              >
                <span style={{ color: "green" }}>{getWeek(moment(props.date).format("dddd"))}, {moment(props.date).format("DD-MMM-YYYY")} {props.time}</span>
              </TooltipComponent>
              :
              <span>{getWeek(moment(props.date).format("dddd"))}, {moment(props.date).format("DD-MMM-YYYY")} {moment(props.time, "HH:mm:ss").format("HH:mm")}</span>
            }
          </div>
          {permission.AdminPermission() ?
            <TooltipComponent content={Edit_Schedule} position="BottomCenter" style={{ marginLeft: "10px" }}>
              <div className="e-icons icon-MT_month"
                style={{ cursor: "pointer", color: "#3c8dbc" }}
                onClick={() => handleOpenDialogChangeSchedule(props)}
              ></div>
            </TooltipComponent>
            : null}
        </div>
      </div>
    )
  }

  const teacherAttendanceTemplate = (props) => {
    return (
      <div style={{ display: "flex" }}>
        <DropDownListComponent
          id="ddl_custom"
          dataSource={teacherStatusData}
          fields={{ text: 'name', value: 'id' }}
          name="teacher_attd"
          placeholder="---"
          cssClass="e-outline"
          style={{
            backgroundColor: props.data[0].schedules_id.teacher_attd === 'Present' ? 'lightgreen' :
              props.data[0].schedules_id.teacher_attd === 'Absent' ? 'pink' : ''
          }}
          value={props.data[0].schedules_id.teacher_attd}
          select={(e) => teacherAttdChange(e, props)}
        />
        <div style={{ marginTop: "6px", marginLeft: "10px", width: "15px" }}>
          {props.data[0].schedules_id.teacher_attd === null || props.data[0].schedules_id.teacher_attd === '---' ?
            <TooltipComponent content={Present} position="BottomCenter">
              <span style={{ cursor: "pointer", color: "#3c8dbc" }} onClick={() => teacherAttdChange(null, props, true)} className="e-icons icon-check-mark-01"></span>
            </TooltipComponent>
            : ''}
        </div>
      </div>
    )
  }

  const teacherTemplate = (props) => {
    return (
      <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
        {permission.AdminPermission() ? props.data[0].schedules_id.teacher_attd === null || props.data[0].schedules_id.teacher_attd === '---' ?
          <TooltipComponent content={Change_Teacher} position="BottomCenter">
            <div style={{ float: "left", marginRight: "5px", cursor: "pointer", color: "#3c8dbc", display: "flex", alignItems: "center" }} onClick={() => handleOpenDialogChangeTeacher(props)} className="e-icons icon-MT_recurrence" />
          </TooltipComponent>
          : '' : ''}
        {props.previous_teacher ?
          <TooltipComponent content={Beginning_Teacher + ": " + (props.previous_teacher.nickname ? props.previous_teacher.nickname : props.previous_teacher.name)} opensOn='Hover' style={{ fontSize: "inherit" }}>
            <div style={{ color: "green" }}>{props.teacher.nickname}</div>
          </TooltipComponent>
          :
          <div>{props.teacher.nickname ? props.teacher.nickname : props.teacher.name}</div>
        }
      </div>
    )
  }

  const studentAttendanceTemplate = (props) => {
    return (
      <div>
        {checkStudentStatus(props.data)}
        {props.data.map((value, index) => (
          <div key={index} style={{ marginTop: "5px" }}>
            <DropDownListComponent
              id="ddl_custom"
              key={index}
              dataSource={studentStatusData}
              fields={{ text: 'name', value: 'id' }}
              name="student_attd"
              placeholder="---"
              cssClass="e-outline"
              style={{
                height: "10px",
                backgroundColor: value.student_attd === 'Present' ? 'lightgreen' :
                  value.student_attd === 'Absent' ? 'pink' :
                    value.student_attd === 'Excused' ? 'lightblue' :
                      value.student_attd === 'Personal Leave' ? 'orange' : '',

              }}
              value={value.student_attd}
              select={(e) => studentAttdChange(e, value)}
            />
          </div>
        ))}
      </div>
    )
  }

  //check student status and add checkbox all present
  const checkStudentStatus = (item) => {
    const list = [];
    var status = false;
    item.map((value) => {
      if (value.student_attd !== "Present") {
        status = true;
      }
    })
    if (status === true) {
      list.push(<CheckBoxComponent key="ck-false" disabled={false} checked={false} label={All_Present} change={(e) => studentAllPresent(e, item)} ></CheckBoxComponent>)
    } else {
      list.push(<CheckBoxComponent key="ck-true" checked={true} disabled={true} label={All_Present} change={(e) => studentAllPresent(e, item)} ></CheckBoxComponent>)
    }
    return (<div>{list}</div>)
  }

  const studentTemplate = (props) => {
    return (
      <div>
        {props.data.map((value, index) => (
          <div key={index} style={{ display: 'flex', width: "100%", alignItems: "center" }}>
            {value.student_attd === null || value.student_attd === '---' ?
              <TooltipComponent content={Present} position="BottomCenter">
                <span style={{ cursor: "pointer", color: "#3c8dbc" }} onClick={() => studentPresent(value)} className="e-icons icon-check-mark-01"></span>
              </TooltipComponent>
              : ''}

            <Link to={{ pathname: `/student/profile/${value.students_subject_classes_id.students_id.id}` }} style={{ textDecoration: "none" }}>
              <ButtonComponent cssClass='e-link' style={{ color: "#3c8dbc", height: "35px" }}
              >
                {value.students_subject_classes_id.students_id.nickname ? value.students_subject_classes_id.students_id.nickname : value.students_subject_classes_id.students_id.name}
              </ButtonComponent>
            </Link>

          </div>
        ))}
      </div>
    )
  }

  // {/* ================== Translate Weekname ========================================== */ }
  const getWeek = (props) => {
    var week2 = props ? props : null;
    if (weeks) {
      weeks.forEach((value) => {
        if (value.id === week2) {
          week2 = value.name
        }
      })
    }
    return "" + week2
  }

  // {/* ================== Footer Template ========================================== */}

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }
  /* eslint-enable array-callback-return*/

  // {/* ================== Main Content ========================================== */ }
  return (
    <div>
      {loading ?
        <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} />
        :
        <div>
          <div className="header">
            <div className="content-header">{Attendances}</div>
          </div>
          <div className="content-main" style={{ marginTop: "30px", marginBottom: "30px" }}>
            <div style={{ display: 'flex', width: "100%" }}>
              <div style={{ paddingRight: "20px", lineHeight: "30px" }}>{Start}</div>
              <div style={{ paddingRight: "20px" }}>
                <DatePickerComponent
                  id="dp_custom"
                  showClearButton={false}
                  cssClass="e-outline"
                  name="start_date"
                  format='dd-MMM-yyyy'
                  openOnFocus={true}
                  value={dataFilterRef.current.start_date}
                  onChange={(e) => handleFilter(e, "start_date")}
                />
              </div>
              <div style={{ paddingRight: "20px", lineHeight: "30px" }}>{End}</div>
              <div style={{ paddingRight: "20px" }}>
                <DatePickerComponent
                  id="dp_custom"
                  showClearButton={false}
                  cssClass="e-outline"
                  name="end_date"
                  format='dd-MMM-yyyy'
                  openOnFocus={true}
                  value={dataFilterRef.current.end_date}
                  onChange={(e) => handleFilter(e, "end_date")}
                />
              </div>
              {permission.AdminPermission() ?
                <div style={{ paddingRight: "20px" }}>
                  <DropDownListComponent
                    id="ddl_custom"
                    dataSource={dataTeacher2}
                    name="teacher"
                    cssClass="e-outline"
                    placeholder={All_Teachers}
                    fields={{ text: 'nickname', value: 'id' }}
                    select={(e) => handleFilter(e, "teacher")}
                    value={dataFilterRef.current.teacher}
                    // search teacher by name 
                    filtering={onFilteringTeacher}
                    allowFiltering={true}
                  />
                </div>
                : null}
              {/* <div style={{ paddingRight: "20px" }}> */}
              <div style={{ paddingRight: "40px", width: "40%" }}>
                <DropDownListComponent
                  id="ddl_custom"
                  dataSource={dataSubject}
                  name="subject_class"
                  cssClass="e-outline"
                  placeholder={All_Classes}
                  fields={{ text: 'name', value: 'id' }}
                  select={(e) => handleFilter(e, "subject_class")}
                  value={dataFilterRef.current.subject_class}
                  filtering={onFilteringClass}
                  allowFiltering={true}
                />
              </div>
              <div style={{ lineHeight: "30px", paddingRight: "20px" }}><CheckBoxComponent
                disabled={false}
                checked={true}
                label={Unfilled}
                change={(e) => handleFilter(e, "ck_filled")}
              /></div>
              <div>
                {loadingSearchingRef.current === false ?
                  <ButtonComponent
                    className="btn-custom"
                    iconCss='e-icons icon-MT_search'
                    onClick={handleClickSearch}
                  >
                    <span className="btn-text-custom">
                      {Search}
                    </span>
                  </ButtonComponent>
                  :
                  <CircularProgress disableShrink style={{ color: "#3c8dbc", width: "25px", height: "25px", marginTop: "2.5px" }} />
                }
              </div>


            </div>
            {dataRef.current ? dataRef.current.length > 0 ? 'Total : ' + dataRef.current.length : '' : ''}

            {dataRef.current ? dataRef.current.length > 0 ?
              <div style={{ marginTop: "25px", paddingBottom: "20px", marginRight: "-5px" }}>
                <GridComponent
                  dataSource={dataRef.current}
                  ref={grid}
                  allowTextWrap={true}
                  textWrapSettings={{ wrapMode: "Content" }}
                  allowResizing={true}
                  gridLines='Horizontal'
                  allowEditing={false}
                  width="100%"
                  selectionSettings={{ mode: 'Both' }}
                >
                  <ColumnsDirective>
                    <ColumnDirective customAttributes={{ id: 'first' }} width="23%" headerText={Date_Time} template={dateTimeTemplate} ></ColumnDirective>
                    <ColumnDirective width="17%" field="subject_class.name" headerText="Class/Grade"  ></ColumnDirective>
                    <ColumnDirective width="16%" headerText={Teacher_Attendance} template={teacherAttendanceTemplate} ></ColumnDirective>
                    <ColumnDirective width="10%" headerText={Teacher} template={teacherTemplate} ></ColumnDirective>
                    <ColumnDirective width="16%" headerText={Student_Attendance} template={studentAttendanceTemplate} ></ColumnDirective>
                    <ColumnDirective customAttributes={{ id: 'last' }} width="18%" headerText={Student} template={studentTemplate} ></ColumnDirective>
                  </ColumnsDirective>
                  <Inject services={[Page, Toolbar, Edit, Sort, Resize]} />
                </GridComponent>
              </div>
              : <div style={{ textAlign: "center" }}><br />{No_data_found}</div> : <div style={{ textAlign: "center" }}><br />{No_data_found}</div>}
          </div>

          {/* ================== Change Schedule Dialog ========================================== */}

          <DialogComponent
            id="dialogChangeSchedule"
            width="40%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogChangeSchedule}
            header={Change_Schedule}
            isModal={true}
            footerTemplate={() => footerTemplate([
              { name: Update, func: changeSchedule, icon: "icon-check-mark-01", status: "e-success" },
              { name: Cancel, func: handleCloseDialogChangeSchedule, icon: "icon-treeview", status: "" }
            ])}
            close={handleCloseDialogChangeSchedule}
          >
            <div>
              {stateScheduleChangeRef.current ?
                <div>
                  <b>{Initial_Date}</b>
                  <DatePickerComponent
                    cssClass="e-outline"
                    format="dd-MMM-yyyy"
                    enabled={false}
                    value={stateScheduleChangeRef.current.previous_date ? new Date(stateScheduleChangeRef.current.previous_date) : new Date(stateScheduleChangeRef.current.date)}
                  >
                  </DatePickerComponent>
                  <br /><br />
                  <b>{Initial_Time}</b>
                  <TimePickerComponent
                    cssClass="e-outline"
                    step={30}
                    format={'HH:mm'}
                    enabled={false}
                    value={stateScheduleChangeRef.current.previous_time ? stateScheduleChangeRef.current.previous_time : stateScheduleChangeRef.current.time}
                  />
                  <br /><br />
                  <b>{Date_2}</b>
                  <DatePickerComponent
                    cssClass="e-outline"
                    format="dd-MMM-yyyy"
                    change={(e) => handleChangeSchedule(e, "date_change")}
                    value={stateScheduleChangeRef.current.date ? new Date(stateScheduleChangeRef.current.date) : null}
                  >
                  </DatePickerComponent>
                  <br /><br />
                  <b>{Time}</b>
                  <TimePickerComponent
                    cssClass="e-outline"
                    step={30}
                    format={'HH:mm'}
                    change={(e) => handleChangeSchedule(e, "time_change")}
                    value={stateScheduleChangeRef.current.time}
                  />
                  <br /><br />
                  <b>{Reason_for_Changing_the_Schedule}</b>
                  <TextBoxComponent
                    cssClass="e-outline"
                    change={(e) => handleChangeSchedule(e, "reason")}
                    value={stateScheduleChangeRef.current.reason}
                  />

                </div>
                : ''}
            </div>
          </DialogComponent>

          {/* ================== Change Teacher Dialog ========================================== */}

          <DialogComponent
            id="dialogChangeTeacher"
            width="40%"
            showCloseIcon={!loadingButton ? true : false}
            visible={false}
            ref={dialogChangeTeacher}
            header={Change_Teacher}
            isModal={true}
            footerTemplate={() => footerTemplate([
              { name: Update, func: changeTeacher, icon: "icon-check-mark-01", status: "e-success" },
              { name: Cancel, func: handleCloseDialogChangeTeacher, icon: "icon-treeview", status: "" }
            ])}
            close={handleCloseDialogChangeTeacher}
          >
            <div>
              {stateTeacherRef.current ?
                <div>
                  <b>{Early_Teacher}</b>
                  <DropDownListComponent
                    dataSource={dataTeacher}
                    name="teacher"
                    cssClass="e-outline"
                    fields={{ text: 'nickname', value: 'id' }}
                    value={stateTeacherRef.current.previous_teacher ? stateTeacherRef.current.previous_teacher : stateTeacherRef.current.teachers_id}
                    enabled={false}
                    allowFiltering={true}
                  />
                  <br /><br />
                  <b>{Teacher}</b>
                  <DropDownListComponent
                    dataSource={dataTeacher}
                    name="teacher_change"
                    cssClass="e-outline"
                    placeholder={`--${Select_Teacher}--`}
                    fields={{ text: 'nickname', value: 'id' }}
                    select={(e) => handleChangeTeacher(e, "teacher_change")}
                    value={stateTeacherRef.current.teacher_change ? stateTeacherRef.current.teacher_change : stateTeacherRef.current.teachers_id}
                    allowFiltering={true}
                  />
                  <br /><br />
                  <b>{Reason_for_Changing_the_Teacher}</b>
                  <TextBoxComponent
                    name="teacher_reason"
                    cssClass="e-outline"
                    value={stateTeacherRef.current.teacher_reason}
                    change={(e) => handleChangeTeacher(e, "teacher_reason")}
                  />
                </div>
                : ''}
            </div>
          </DialogComponent>

        </div>
      }
    </div >
  );
}

export default Attendance;