import React, { useState, useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import useStateRef from "react-usestateref";

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Print, Day, Week, WorkWeek, Month, ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, Inject } from '@syncfusion/ej2-react-schedule';

import i18next from "i18next";
import moment from 'moment';
import linq from "linq";
import Enumerable from "linq";

import directus from 'src/services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import * as link from 'src/constants/link';
import './schedule.css';
import * as permission from 'src/utils/Permission';

const ClassSchedule = () => {
  /* eslint-disable no-unused-vars*/
  // const navigate = useNavigate();

  const [Search] = useState(i18next.t('Pages.button.Search'));
  const [Cancel] = useState(i18next.t('Pages.button.Cancel'));
  const [Clear] = useState(i18next.t('Pages.button.Clear'));
  const [Print_2] = useState(i18next.t('Pages.button.Print'));

  const [Problem_encountered_please_try_again] = useState(i18next.t('Pages.general.Problem_encountered_please_try_again'));

  const [Select_Group] = useState(i18next.t('Pages.data.Select_Group'));
  const [Select_Class] = useState(i18next.t('Pages.data.Select_Class'));
  const [Select_Teacher] = useState(i18next.t('Pages.data.Select_Teacher'));
  const [Select_Student] = useState(i18next.t('Pages.data.Select_Student'));
  const [All_Groups] = useState(i18next.t('Pages.data.All_Groups'));
  const [All_Classes] = useState(i18next.t('Pages.data.All_Classes'));
  const [All_Teachers] = useState(i18next.t('Pages.data.All_Teachers'));
  const [Select_a_teacher] = useState(i18next.t('Pages.data.Select_a_teacher'));
  const [All_Students] = useState(i18next.t('Pages.data.All_Students'));

  const [Weekly_Schedule] = useState(i18next.t('Pages.schedule_data.Weekly_Schedule'));

  //data
  const [dataSubject, setDataSubject] = useState(null);
  const [dataSubjectGroup, setDataSubjectGroup] = useState(null);
  const [dataSchedule, setDataSchedule, dataScheduleRef] = useStateRef([]);
  const [dataTeacherFilter, setDataTeacherFilter, dataTeacherFilterRef] = useStateRef([]);
  const [dataTeacher, setDataTeacher] = useState([]);
  const [dataStudent, setDataStudent] = useState([]);
  const [dataAvailableTime, setDataAvailableTime, dataAvailableTimeRef] = useStateRef(null);

  //initialized and loading
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton, loadingButtonRef] = useStateRef(false);
  const [dataButton, setDataButton, dataButtonRef] = useStateRef(false);

  const [selectedTeacher, setSelectedTeacher, selectedTeacherRef] = useStateRef(false);

  //dialog
  let dialogSearching = useRef(false);

  //schedule setting
  let scheduleRef = useRef(false);
  let teacherDropdown = useRef(false);
  const resources = ["Teachers"];
  const [currentView, setCurrentView, currentViewRef] = useStateRef("Day");
  let weekName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  //set state filtering
  const [stateFiltering, setStateFiltering, stateFilteringRef] = useStateRef({
    // start_date: moment().clone().startOf('week').format(),
    // end_date: moment().clone().endOf('week').format(),
    date: new Date(),
    start_date: new Date(),
    end_date: new Date(),
    subject_group: 0,
    subject_class: 0,
    student: 0,
    teacher: 0
  });

  //set range time
  const [rangeTime, setRangeTime, rangeTimeRef] = useStateRef({
    start_time: "09:00",
    end_time: "21:00"
  })
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setLoading(true);
      getSubjectGroup();
    }

    return function cleanup() {
      setInitialized(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  useEffect(() => {
    if (loadingButtonRef.current === false) {
      if (dataButtonRef.current === "searching") {
        dialogSearching.current.visible = false;
        setDataButton(null);
      }
    }
  })

  // {/* ================== Get All Data ========================================== */ }
  //get list of subject group
  /* eslint-disable array-callback-return*/
  const getSubjectGroup = async () => {
    try {
      var result = await directus.getItems('subject_group', { sort: "name" });
      var newData = result.data;

      if (result.data.length > 0) {
        var oldData = [{ id: 0, name: All_Groups }];
        newData = oldData.concat(newData);
      }

      setDataSubjectGroup(newData);
      getSubjectClass();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of subject class
  const getSubjectClass = async () => {
    try {
      var result = await directus.getItems('subjects', { sort: "name" });
      var newData = result.data;

      if (result.data.length > 0) {
        var oldData = [{ id: 0, name: All_Classes }];
        newData = oldData.concat(newData);
      }

      setDataSubject(newData);
      getStudent();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of students
  const getStudent = async () => {
    try {
      var result = await directus.getItems('students',
        {
          sort: "nickname",
          limit: 5,
          page: 1
        }
      );

      var newData = result.data;
      if (result.data.length > 0) {
        var oldData = [{ id: 0, nickname: All_Students }];
        newData = oldData.concat(newData);
      }

      setDataStudent(newData);
      getSchedule();
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get list of schedules
  const getSchedule = async () => {
    try {
      //statusFiltering => if current view is day then go to schedule filtering (statusFiltering = true)
      //if current view is week and month and have filter then go to schedule filtering (statusFiltering = true)
      //if current view is week and month and have no filter then skip the schedule filtering (statusFiltering = false)
      var statusFiltering = true;
      if (currentViewRef.current) {
        if (currentViewRef.current === "Week" || currentViewRef.current === "Month") {
          if (stateFilteringRef.current.teacher === null || stateFilteringRef.current.teacher === 0) {
            statusFiltering = false;
          }
        }
      }

      if (!permission.AdminPermission()) {
        statusFiltering = true;
      }

      let result = null;
      //if there is a filter
      if (statusFiltering) {
        var subject_class = { "nnull": null };
        var subject_group = { "nnull": null };
        var teacher = { "nnull": null };
        var student = { "nnull": null };

        //setup date range
        var range = [moment(stateFilteringRef.current.start_date).format("YYYY-MM-DD"), moment(stateFilteringRef.current.end_date).format("YYYY-MM-DD")];

        //filter subject group
        if (stateFilteringRef.current.subject_group !== null && stateFilteringRef.current.subject_group !== "" && stateFilteringRef.current.subject_group !== 0) {
          subject_group = { "eq": stateFilteringRef.current.subject_group };
        }

        //filter subject class
        if (stateFilteringRef.current.subject_class !== null && stateFilteringRef.current.subject_class !== "" && stateFilteringRef.current.subject_class !== 0) {
          subject_class = { "eq": stateFilteringRef.current.subject_class };
        }

        //filter teacher
        if (permission.AdminPermission()) {
          if (stateFilteringRef.current.teacher !== null && stateFilteringRef.current.teacher !== "" && stateFilteringRef.current.teacher !== 0) {
            teacher = { "eq": stateFilteringRef.current.teacher };
          }
        } else if (permission.TeacherPermission()) {
          teacher = { "eq": JSON.parse(localStorage.user_setting).data_id };
        }

        //check if there is a student filter
        if (stateFilteringRef.current.student !== null && stateFilteringRef.current.student !== "" && stateFilteringRef.current.student !== 0) {
          student = { "eq": stateFilteringRef.current.student };

          result = await directus.getItems('schedules',
            {
              fields: "*.*.*.*",
              filter: {
                'date': {
                  'between': range
                },
                'subject_classes_id.subjects.id': subject_class,
                'subject_classes_id.subjects.group.id': subject_group,
                'teachers_id.id': teacher,
                'attendances.students_subject_classes_id.students_id.id': student
              }
            })

        } else {
          result = await directus.getItems('schedules',
            {
              fields: "*.*.*.*",
              filter: {
                'date': {
                  'between': range
                },
                'subject_classes_id.subjects.id': subject_class,
                'subject_classes_id.subjects.group.id': subject_group,
                'teachers_id.id': teacher,
              }
            })
        }
      }

      var data_schedule = [];
      if (result) {
        //setup the schedule data into schedule component
        result.data.map((value) => {
          //start_time = combine schedule date and time
          var start_time = moment(value.date + "T" + value.time).format();

          //end time = start_time add with given specific duration 
          var end_time = moment(start_time).add(moment.duration({ 'minutes': value.duration ? value.duration : 60 })).format();

          const body = {
            id: value.id,
            subject_class_name: value.subject_classes_id.name,
            start_time: start_time,
            end_time: end_time,
            IsAllDay: false,
            teachers_id: value.teachers_id.id,
            color: value.subject_classes_id.subjects.group.color,
            data: value
          }

          data_schedule.push(body);
        })
      }

      //check if there is a schedule
      if (data_schedule.length > 0) {
        //get start time and end time
        const minMaxTime = linq.from(data_schedule)
          .select(data => ({
            id: data.id,
            start_time: new Date(data.start_time),
            end_time: new Date(data.end_time)
          }))
          .toArray();

        //get min time
        const minTime = linq.from(minMaxTime)
          .minBy(s => moment(s.start_time).format("HH:mm"));

        //get max time
        const maxTime = linq.from(minMaxTime)
          .maxBy(s => moment(s.end_time).format("HH:mm"));

        var new_start_time = moment(minTime.start_time).hours();
        var new_end_time = moment(maxTime.end_time).hours();

        var calDuration = 1;
        if (new_end_time - new_start_time < 2) {
          calDuration = 2;
        }

        var calDurationStart = calDuration;
        var calDurationEnd = calDuration;
        if (new_start_time - calDuration < 0) {
          if (new_start_time !== 0) {
            calDurationStart = new_start_time;
          } else {
            calDurationStart = 0;
          }
          calDurationEnd = 4 - calDurationStart;
        }

        setRangeTime({
          start_time: (moment(minTime.start_time).hours() - calDurationStart) + ":00",
          end_time: (moment(maxTime.end_time).hours() + calDurationEnd) + ":00"
        })

      } else {
        //if no schedule then add default time
        setRangeTime({ start_time: "09:00", end_time: "21:00" })
      }

      setDataSchedule(data_schedule);

      //get teacher data
      var teachers_id = linq.from(data_schedule)
        .select(data => data.teachers_id)
        .distinct()
        .toArray();

      getTeacher(teachers_id);

    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  const getTeacher = async (teacher) => {
    try {
      //get all teachers data
      var result = await directus.getItems('teachers',
        {
          fields: '*.*.*',
          limit: -1,
          sort: "nickname",
          status: "published"
        }
      );

      var data_teacher = result.data;

      //check if there is a teacher filter
      if (stateFilteringRef.current.teacher !== 0 && stateFilteringRef.current.teacher !== null) {
        //if true then get selected teacher
        data_teacher = linq.from(data_teacher)
          .where(x => x.id === stateFilteringRef.current.teacher)
          .toArray();
      } else {
        data_teacher = Enumerable.from(data_teacher)
          .where(x => Enumerable.from(teacher).contains(x.id))
          .toArray();
      }

      setDataTeacher(data_teacher);

      var newData = result.data;
      //change teacher dropdown value if the current view is day
      if (result.data.length > 0) {
        var oldData = null;
        if (currentViewRef.current === "Day") {
          oldData = [{ id: 0, nickname: All_Teachers }];
        } else {
          oldData = [{ id: 0, nickname: Select_a_teacher }];
        }
        newData = oldData.concat(newData);
      }

      setDataTeacherFilter(newData);
      getAvailableTime(teacher);
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  //get all available time
  const getAvailableTime = async (teacher) => {
    try {
      var teachers_id = { "nnull": null };
      if (teacher.length > 0) {
        teachers_id = { "in": teacher };
      }

      var result = await directus.getItems('available_time',
        {
          fields: '*.*.*',
          limit: -1,
          status: "published",
          filter: {
            'teacher': teachers_id

          }
        }
      );

      setDataAvailableTime(result.data);
      setLoading(false);
      setLoadingButton(false);
    }
    catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  }

  // {/* ================== Filtering ========================================== */ }
  //search subject group by name
  const onFilteringGroup = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataSubjectGroup, query);
  };

  //search subject class by name
  const onFilteringClass = (e) => {
    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;
    //pass the filter data source, filter query to updateData method.
    e.updateData(dataSubject, query);
  };

  //search student by nickname
  const onFilteringStudent = (e) => {
    let query = new Query();
    // //frame the query based on search string with filter type.
    query = (e.text !== '') ? query.where('nickname', 'contains', e.text, true) : query;
    // e.updateData(dataStudentRef.current, query);
    //pass the filter data source, filter query to updateData method.
    try {
      if (e.text !== '') {
        window.setTimeout(async () => {
          var result = await directus.getItems('students',
            {
              fields: '*.*',
              filter: {
                nickname: { "contains": e.text },
                status: "published"
              },
              limit: 5,
              page: 1
            }
          );
          e.updateData(result.data, query);
        }, 500)
      }
      else {
        window.setTimeout(async () => {
          var result = await directus.getItems('students',
            {
              fields: '*.*',
              filter: {
                status: "published"
              },
              limit: 5,
              page: 1
            }
          );
          e.updateData(result.data, query);
        }, 500)
      }
    } catch (error) {
      EventEmitter.emit('alert_toast', { content: Problem_encountered_please_try_again, type: "danger" });
    }
  };

  // {/* ================== Handle Filtering ========================================== */ }

  const handleOpenSearchingDialog = () => {
    dialogSearching.current.visible = true;
  }

  const clearDialogState = () => {
    setStateFiltering({ ...stateFilteringRef.current, subject_group: 0, subject_class: 0, student: 0, teacher: 0 })
  }

  const handleCloseDialogSearching = () => {
    dialogSearching.current.visible = false;
  }

  const handleChangeSearching = (event, type) => {
    var value = "";
    if (type === "teacher" || type === "subject_class" || type === "subject_group" || type === "student") {
      value = event.itemData.id;
    } else {
      value = event.value
    }

    setStateFiltering({ ...stateFilteringRef.current, [type]: value })
  }

  const handleChangeTeacher = (event, type) => {
    var value = "";
    if (type === "teacher" || type === "subject_class" || type === "subject_group" || type === "student") {
      if (type === "teacher") {
        setSelectedTeacher({ itemData: event.itemData, item: event.item });
      }

      value = event.itemData.id;
    } else {
      value = event.value
    }

    setStateFiltering({ ...stateFilteringRef.current, [type]: value })
    getSchedule();
  }

  const searching = async () => {
    setLoadingButton(true);
    getSchedule();
    setDataButton("searching");
  }

  // {/* ================== Navigation ========================================== */ }
  // function btnClickStudent(props) {
  //   return navigate('/student/profile/' + props.students_subject_classes_id.students_id.id);
  // }

  // function btnClickSubjectClass(props) {
  //   window.open('/subject_class/detail/' + props.data.subject_classes_id.id)
  //   // return navigate('/subject_class/detail/' + props.data.subject_classes_id.id);
  // }

  // {/* ================== Schedule Action Begin and Complete ========================================== */ }
  const onActionBegin = (args) => {
    //create new space for teacher dropdown in toolbar item
    if (permission.AdminPermission()) {
      if (args.requestType === 'toolbarItemRendering') {
        let teacherDropDownItem = {
          align: 'Left', suffixIcon: 'e-icon-down-arrow', text: Select_Teacher, overflow: "Show", cssClass: 'e-schedule-teacher'
        };
        args.items.push(teacherDropDownItem);
      }
    }
  }

  const onActionComplete = (e) => {
    var placeholder_text = Select_a_teacher;
    if (currentViewRef.current === "Day") {
      placeholder_text = All_Teachers;
    }

    if (permission.AdminPermission()) {
      if (e.requestType === 'toolBarItemRendered') {
        let teacher = document.querySelector('.e-schedule-teacher');
        //render teacher dropdown
        ReactDOM.render(
          <DropDownListComponent
            ref={teacherDropdown}
            dataSource={dataTeacherFilterRef.current}
            name="teacher"
            style={{ borderBottom: "0px solid" }}
            placeholder={`${placeholder_text}`}
            fields={{ text: 'nickname', value: 'id' }}
            select={(e) => handleChangeTeacher(e, "teacher")}
            allowFiltering={true}
          // value={stateFilteringRef.current.teacher}
          />
          , teacher);
      }
    }
    //save current view (Day, Week, Month)
    if (e.requestType === "viewNavigate") {
      if (permission.AdminPermission()) {
        if (teacherDropdown.current) {
          teacherDropdown.current.dataSource = dataTeacherFilterRef.current;

          setCurrentView(scheduleRef.current.currentView);
          var placeholder_text2 = Select_a_teacher;
          if (scheduleRef.current.currentView === "Day") {
            placeholder_text2 = All_Teachers;
          }
          teacherDropdown.current.placeholder = placeholder_text2;
          if (selectedTeacherRef.current) {
            // teacherDropdown.current.itemData = selectedTeacherRef.current.itemData;
            // teacherDropdown.current.item = selectedTeacherRef.current.item;
            document.querySelector('.e-schedule-teacher input').value = selectedTeacherRef.current.itemData.nickname;
          }
        }
      }
    }

    if (e.requestType === "dateNavigate" || e.requestType === "viewNavigate") {
      var date = scheduleRef.current.selectedDate;

      if (scheduleRef.current.currentView === "Day" || scheduleRef.current.currentView === "Week" || scheduleRef.current.currentView === "Month") {
        //set date range for day view
        if (scheduleRef.current.currentView === "Day") {
          setStateFiltering({
            ...stateFilteringRef.current,
            start_date: moment(date).format(),
            end_date: moment(date).format()
          })
        }
        //set date range for week view
        if (scheduleRef.current.currentView === "Week") {
          setStateFiltering({
            ...stateFilteringRef.current,
            start_date: moment(date).clone().startOf('week').format(),
            end_date: moment(date).clone().endOf('week').format()
          })
        }
        //set date range for month view
        if (scheduleRef.current.currentView === "Month") {
          setStateFiltering({
            ...stateFilteringRef.current,
            start_date: moment(date).clone().startOf('month').format(),
            end_date: moment(date).clone().endOf('month').format()
          })
        }
        getSchedule();
      }
    }
  }

  // {/* ================== Data Binding ========================================== */ }

  const onDataBinding = () => {
    if (scheduleRef.current.currentView === "Month") {
      scheduleRef.current.element.children[2].children[0].children[0].children[0].children[1].children[0].children[0].style.height = "auto";
      scheduleRef.current.element.children[2].children[0].children[0].children[0].children[1].children[0].children[0].style.overflowY = "clip";
    }

    if (scheduleRef.current.group.resources.length === 0 && (scheduleRef.current.resourceCollection[0].dataSource).length > 0) {
      scheduleRef.current.group.resources = resources;
    }
    if ((scheduleRef.current.resourceCollection[0].dataSource).length === 0 && scheduleRef.current.group.resources.length > 0) {
      // Render default scheduler to handle above case.
      scheduleRef.current.group.resources = [];
    }
    if (permission.AdminPermission()) {
      teacherDropdown.current.dataSource = dataTeacherFilterRef.current;
      if (selectedTeacherRef.current) {
        document.querySelector('.e-schedule-teacher input').value = selectedTeacherRef.current.itemData.nickname;
      }

    }
  }


  // {/* ================== Render Cell ========================================== */ }

  const onRenderCell = (props) => {
    if (props.elementType === "workCells" || props.elementType === "majorSlot" || props.elementType === "minorSlot") {
      props.element.style.height = "20px";
      if (props.elementType === "majorSlot") {
        props.element.style.borderLeft = "1px solid #c7c7c7";
        props.element.style.borderRight = "1px solid #c7c7c7";
      }
    }

    if (new Date(props.date).getMinutes() === 30) {
      props.element.style.borderBottom = "1px solid #c7c7c7";
    }

    if (props.elementType === "workCells" || props.elementType === "resourceHeader" || props.elementType === "dateHeader") {
      if (props.elementType === "resourceHeader") {
        props.element.style.borderRight = "1px solid #c7c7c7";
        props.element.style.borderBottom = "1px solid #c7c7c7";
      }

      if (props.elementType === "workCells" || props.elementType === "dateHeader") {
        if (scheduleRef.current.currentView === "Day") {
          props.element.style.borderRight = "1px solid #c7c7c7";
        } else {
          if (scheduleRef.current.currentView === "Week") {
            if (moment(props.date).day() === 6) {
              props.element.style.borderRight = "1px solid #c7c7c7";
            }
          }
        }

      }
    }

    if (props.elementType === "workCells" || props.elementType === "majorSlot" || props.elementType === "minorSlot") {
      if (props.elementType === "workCells") {
        props.element.className += " stripe-4";
      }


      if (dataScheduleRef.current.length > 0) {
        var currentTime2 = moment(props.date).format("HH:mm");
        var new_group = [];
        dataScheduleRef.current.map((value) => {
          var startTime = moment(new Date(value.start_time)).format("HH:mm");
          var endTime = moment(new Date(value.end_time)).format("HH:mm");

          if (currentTime2 >= startTime && currentTime2 < endTime) {
            props.element.style.backgroundColor = "#edfeff";
            // props.element.style.setProperty("background", "#edfeff", "important");
          }
        })

        var teachers_id = linq.from(dataScheduleRef.current)
          .orderBy(o => o.data.teachers_id.name)
          .select(data => data.teachers_id)
          .distinct()
          .toArray();

        dataAvailableTimeRef.current.map((value2) => {
          teachers_id.map((value, index) => {
            if (value === value2.teacher.id) {
              if (new_group.indexOf(index) === -1) {
                new_group.push({
                  index: index, teacher: value2.teacher.id
                }
                );
              }
            }
          })
        })

        new_group.map((value) => {
          if (value.index === props.groupIndex) {
            var available_time_teacher = linq.from(dataAvailableTimeRef.current)
              .where(w => w.teacher.id === value.teacher)
              .toArray();

            var getDay = new Date(props.date).getDay();
            available_time_teacher.map((value_at) => {
              if (weekName[getDay] === value_at.weekday) {

                var newCurrentTime = moment(currentTime2, "HH:mm").format();
                var start_time = moment(value_at.start_time, "HH:mm:ss").format();
                var end_time = moment(value_at.end_time, "HH:mm:ss").format();
                if (newCurrentTime >= start_time && newCurrentTime < end_time) {
                  props.element.style.setProperty("background", "white", "important");
                }
              }
            })

          }
        })

      } else {
        if (scheduleRef.current.currentView === "Week") {
          if (stateFilteringRef.current.teacher !== 0 || stateFilteringRef.current.teacher !== null) {
            var available_time_teacher = linq.from(dataAvailableTimeRef.current)
              .where(w => w.teacher.id === stateFilteringRef.current.teacher)
              .toArray();

            var getDay = new Date(props.date).getDay();
            available_time_teacher.map((value_at) => {
              if (weekName[getDay] === value_at.weekday) {

                var newCurrentTime = moment(currentTime2, "HH:mm").format();
                var start_time = moment(value_at.start_time, "HH:mm:ss").format();
                var end_time = moment(value_at.end_time, "HH:mm:ss").format();
                if (newCurrentTime >= start_time && newCurrentTime < end_time) {
                  props.element.style.setProperty("background", "white", "important");
                }
              }
            })
          }
        }
      }
    }
  }

  // {/* ================== Resource Header Template ========================================== */ }
  const resourceHeaderTemplate = (props) => {
    return (<div className="template-wrap">
      <div style={{ float: "left" }} className={"resource-image " + getTeacherImage(props)}>
        <div className="image" style={{ width: "50px", height: "50px" }}>
          <img
            style={{
              maxWidth: "100%", minWidth: "100%", maxHeight: "100%", minHeight: "100%",
              objectFit: "cover", border: "3px solid #d2d6de", borderRadius: "50%", position: "center",
            }}
            className="image"
            src={props.resourceData.image ? props.resourceData.image.data.full_url : link.default_image}
            alt={props.name}
          />
        </div>
      </div>
      <div className="resource-details">
        <div className="resource-name" style={{ paddingLeft: "20px" }}>{props.resourceData.nickname}</div>
      </div>
    </div>);
  }

  const getTeacherName = (value) => {
    return ((value.resourceData) ?
      value.resourceData[value.resource.textField] :
      value.resourceName);
  }

  const getTeacherImage = (value) => {
    let resourceName = getTeacherName(value);
    return resourceName.replace(' ', '-').toLowerCase();
  }

  // {/* ================== Event Content, Header Template ========================================== */ }

  const content = (args) => {
    if (args.elementType === "event") {
      return (
        <div style={{ fontSize: "15px", paddingTop: "5px" }} key="content-1">
          <span style={{ paddingRight: "10px" }} className='e-icons icon-MT_DateTime'></span>
          {moment(args.data.date).format("dddd, DD-MMM-yyyy")} {moment(args.start_time).format("HH:mm")}-{moment(args.end_time).format("HH:mm")}
          <br />
          <span style={{ paddingRight: "10px" }} className='e-icons icon-MT_Location'></span>
          {args.data.room.name}
          <br />
          <span style={{ paddingRight: "10px" }} className='e-icons icon-MT_Resource'></span>
          {args.data.teachers_id.nickname ? args.data.teachers_id.nickname : args.data.teachers_id.name}
          <br />
          <br />
          Students:
          {args.data.attendances.length > 0 ?
            <div style={{ fontSize: "13px" }}>
              {getStudents(args)}
            </div>
            : ' -'
          }
        </div>
      )
    }
  }

  // const header = (args) => {
  //   console.log(args)
  //   if (args.elementType == "event") {
  //     return (
  //       <div>
  //         <div className="e-header-icon-wrapper">
  //           {/* <button className="e-edit e-icons e-control e-btn e-lib e-flat e-round e-small e-icon-btn" title="Edit" disabled="" data-ripple="true">
  //           <span className="e-btn-icon e-icons e-edit-icon"></span>
  //         </button>
  //         <button className="e-delete e-icons e-control e-btn e-lib e-flat e-round e-small e-icon-btn" title="Delete" disabled="" data-ripple="true">
  //           <span className="e-btn-icon e-icons e-delete-icon"></span>
  //         </button> */}
  //           <button className="e-close e-control e-btn e-lib e-flat e-round e-small e-icon-btn" title="Close" data-ripple="true">
  //             <span className="e-btn-icon e-icons e-close-icon"></span>
  //           </button>
  //         </div>
  //         <div className="e-subject-wrap">
  //           <div className="e-subject e-text-ellipsis" title={args.subject_class_name}><span style={{cursor: "pointer"}} onClick={()=>btnClickSubjectClass(args)}>{args.subject_class_name}</span></div>
  //         </div>
  //       </div>
  //     )
  //   }
  // }

  function getStudents(props) {
    const list = [];

    props.data.attendances.map((value, index) => {
      list.push(
        <div key={index + 1}>
          {index + 1}.

          <Link to={{ pathname: `/student/profile/${value.students_subject_classes_id.students_id.id}` }} style={{ textDecoration: "none" }}>
            <span
              style={{ paddingLeft: "5px", cursor: "pointer", color: "#3c8dbc" }}
            >
              <b>{value.students_subject_classes_id.students_id.nickname ? value.students_subject_classes_id.students_id.nickname : value.students_subject_classes_id.students_id.name}</b>
            </span>
          </Link>

        </div>
      )
    })
    return (<div>{list}</div>)
  }

  // {/* ================== Day and Month Event Template ========================================== */ }
  const dayEventTemplate = (props) => {
    return (
      <div className="day_event" style={{ padding: "5px", width: "100%", height: "100%", textAlign: "center", verticalAlign: "middle", borderRadius: "20px" }}>
        <div style={{ fontSize: "15px" }}>{props.subject_class_name}</div>
        {/* <div>{moment(props.start_time).format("HH:mm")}-{moment(props.end_time).format("HH:mm")}</div> */}
        {/* <div>{props.data.room.name}</div> */}
      </div>
    );
  }

  const monthEventTemplate = (props) => {
    if (stateFilteringRef.current.teacher !== null && stateFilteringRef.current.teacher !== "" && stateFilteringRef.current.teacher !== 0) {
      return (<div className="subject" style={{
        width: "100%",
        overflowWrap: "anywhere", fontSize: "1em", textAlign: "center", top: "50%", left: "50%",
        position: "absolute", transform: "translate(-50%, -50%)"
      }}>
        {props.subject_class_name}
      </div>);
    } else {
      return ""
    }
  }

  // {/* ================== Event Rendered ========================================== */ }

  const onEventRendered = (args) => {
    args.element.style.borderRadius = "10px";
    args.element.style.setProperty("background", args.data.color, "important");
    args.element.style.whiteSpace = "wrap";
    args.element.style.border = "2px solid " + args.data.color;

    // var top = Number(args.element.style.top.replace(/px$/, ''));

    var getTime = linq.from(dataScheduleRef.current)
      .select(data => ({ start_time: moment(data.start_time).format("HH:mm") }))
      .toArray();

    var orderTime = linq.from(getTime)
      .distinct()
      .orderBy(o => o.start_time)
      .toArray();

    //eslint-disable-next-line no-unused-vars
    var position = 0;
    orderTime.map((value, index) => {
      var startTime = new Date(args.data.start_time).getHours() + ":" + new Date(args.data.start_time).getMinutes()
      if (startTime >= value.start_time) {
        position = index + 1;
      }
    })
  }

  // {/* ================== Print Schedule ========================================== */}
  const onPrintClick = () => {
    scheduleRef.current.print();
  }

  // {/* ================== Footer Template ========================================== */}

  const footerTemplate = (props) => {
    return (
      loadingButtonRef.current === false ?
        props.map((value, index) =>
          <button key={index} type="button" className={`e-control e-btn e-lib ${value.status} e-flat`} data-ripple="true" onClick={() => value.func()}>
            <span className={`e-btn-icon e-icons ${value.icon} e-icon-left`}></span>{value.name}
          </button>
        )
        :
        <CircularProgress disableShrink style={{ color: "#3c8dbc" }} />
    )
  }
  /* eslint-enable array-callback-return*/

  // {/* ================== Main Content ========================================== */}
  return (
    <div>
      {loading ? <CircularProgress disableShrink style={{ color: "#3c8dbc", position: "absolute", alignItems: "center", marginTop: "25%", marginLeft: "50%" }} /> :
        <div>
          <div className="header">
            <div className="content-header">{Weekly_Schedule}</div>

            <div className="content-searching">
              <div style={{ display: 'flex', width: "100%" }}>
                <div style={{ marginRight: "10px" }} id="no-print">
                  <ButtonComponent className="btn-custom" iconCss='e-icons icon-MT_search' onClick={handleOpenSearchingDialog}>
                    <span className="btn-text-custom">{Search}</span>
                  </ButtonComponent>

                  <span>
                    <ButtonComponent className="btn-custom" iconCss='e-icons icon-MT_Print' onClick={onPrintClick}>
                      <span className="btn-text-custom">{Print_2}</span>
                    </ButtonComponent>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="content-main" style={{ marginTop: "30px", marginBottom: "30px" }}>
            {dataScheduleRef.current ?
              <div>
                <div className='schedule-control-section'>
                  <div className='col-lg-12 control-section'>
                    <div className='control-wrapper' style={{ paddingBottom: "20px" }}>
                      <ScheduleComponent
                        ref={scheduleRef}
                        id="scheduleRef"
                        cssClass='group-editing'
                        readOnly={true}
                        height="auto"
                        actionBegin={onActionBegin}
                        actionComplete={onActionComplete}
                        rowAutoHeight={true}
                        // selectedDate={selectedDate}
                        currentView='Day'
                        // showWeekend={false}
                        resourceHeaderTemplate={resourceHeaderTemplate}
                        renderCell={onRenderCell}
                        // workDays={[0, 1, 3, 5]}
                        showTimeIndicator={false}
                        style={{ WebkitPrintColorAdjust: "exact", colorAdjust: "exact" }}
                        quickInfoTemplates={{
                          // header: header,
                          content: content,
                        }}
                        eventRendered={onEventRendered}
                        startHour={rangeTimeRef.current.start_time} endHour={rangeTimeRef.current.end_time}
                        timeScale={{ interval: 30, slotCount: 1 }}
                        timeFormat="HH:mm"
                        eventSettings={{
                          dataSource: dataScheduleRef.current,
                          fields: {
                            subject: { title: 'Class Name', name: 'subject_class_name' },
                            // description: { title: 'Summary', name: 'Description' },
                            startTime: { title: 'From', name: 'start_time' },
                            endTime: { title: 'To', name: 'end_time' }
                          }
                        }}
                        group={{ allowGroupEdit: true, resources: resources }}
                        dataBinding={onDataBinding}
                      >
                        <ResourcesDirective>
                          <ResourceDirective
                            field='teachers_id'
                            title='Attendees'
                            name='Teachers'
                            allowMultiple={true}
                            dataSource={dataTeacher}
                            textField='nickname'
                            idField='id'
                            colorField='color'
                          >
                          </ResourceDirective>
                        </ResourcesDirective>
                        <ViewsDirective>
                          <ViewDirective option='Day'
                            readonly={true}
                            eventTemplate={dayEventTemplate} />
                          <ViewDirective option='Week'
                            readonly={true}
                            eventTemplate={monthEventTemplate} />
                          {/* <ViewDirective option='WorkWeek' eventTemplate={workWeekEventTemplate} /> */}
                          <ViewDirective option='Month' readonly={true}
                            style={{ height: "auto" }}
                            eventTemplate={monthEventTemplate} />
                          {/* <ViewDirective option='TimelineWeek' readonly={true} /> */}
                        </ViewsDirective>
                        <Inject services={[Day, Week, WorkWeek, Month, Print
                          // TimelineViews
                        ]} />
                        {/* <Inject services={[Day, WorkWeek, Month, TimelineViews, Resize, DragAndDrop]}/> */}
                      </ScheduleComponent>
                    </div>
                  </div>
                  <br /><br />
                </div>



                <DialogComponent
                  id="dialogSearching"
                  width="30%"
                  showCloseIcon={!loadingButton ? true : false}
                  visible={false}
                  ref={dialogSearching}
                  header={Search}
                  isModal={true}
                  footerTemplate={() => footerTemplate([
                    { name: Search, func: searching, icon: "icon-MT_search", status: "e-info" },
                    { name: Cancel, func: handleCloseDialogSearching, icon: "icon-treeview", status: "" },
                    { name: Clear, func: clearDialogState, icon: "e2-clear", status: "" }
                  ])}
                  close={handleCloseDialogSearching}
                >
                  <div>
                    <b>{Select_Group}</b>
                    <DropDownListComponent
                      dataSource={dataSubjectGroup}
                      name="subject_group"
                      cssClass="e-outline"
                      placeholder={All_Groups}
                      fields={{ text: 'name', value: 'id' }}
                      select={(e) => handleChangeSearching(e, "subject_group")}
                      value={stateFilteringRef.current.subject_group}
                      filtering={onFilteringGroup}
                      allowFiltering={true}
                    />
                    <br /><br />
                    <b>{Select_Class}</b>
                    <DropDownListComponent
                      dataSource={dataSubject}
                      name="subject_class"
                      cssClass="e-outline"
                      placeholder={All_Classes}
                      fields={{ text: 'name', value: 'id' }}
                      select={(e) => handleChangeSearching(e, "subject_class")}
                      filtering={onFilteringClass}
                      allowFiltering={true}
                      value={stateFilteringRef.current.subject_class}
                    />
                    <br /><br />
                    <b>{Select_Student}</b>
                    <DropDownListComponent
                      dataSource={dataStudent}
                      name="student"
                      cssClass="e-outline"
                      placeholder={All_Students}
                      fields={{ text: 'nickname', value: 'id' }}
                      select={(e) => handleChangeSearching(e, "student")}
                      filtering={onFilteringStudent}
                      allowFiltering={true}
                      value={stateFilteringRef.current.student}
                    />
                  </div>
                </DialogComponent>


              </div>
              : ''}
          </div>
        </div>
      }
    </div >
  );
}

export default ClassSchedule;