import React from 'react';

const LogoIcon = (props) => {
  return (
    <img
      alt="LogoIcon"
      src="/static/images/akademix_icon.svg"
      {...props}
    />
  );
};

export default LogoIcon;
